import React from 'react';
import PropTypes from 'prop-types';
import { mapObjIndexed, values, chain, uniq, prop, none } from 'ramda';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import withWorkingScope from '../../modules/scope/withWorkingScope';
import { capabilities } from '../../helpers/capabilities';
import PageContentBlock from '../page/PageContentBlock';

const InfoContent = ({ infoContent: { links, description }, hasCapability }) => {
	if (none(hasCapability, chain(prop('capability'), links))) return null;

	return (
		<div>
			<p>{description}</p>
			<ul>
				{links
					.filter(({ capability }) => (
						!none(hasCapability, Array.isArray(capability) ? capability : [capability])
					))
					.map(({ link, title }, i) => (
						<li key={i}>
							<a href={link}>{title}</a>
						</li>
					))}
			</ul>
		</div>
	);
};

InfoContent.propTypes = {
	infoContent: PropTypes.object.isRequired,
	hasCapability: PropTypes.func.isRequired,
};

const content = {
	nl: {
		description: 'De officiële handleidingen, raadpleeg deze altijd eerst.',
		links: [
            {
                title: 'Handleiding Business Developers / Operation Managers',
                link: 'https://pallasathenagroup.notion.site/Informatiecentrum-Business-Developers-Operations-Managers-b471ec6ab7b54691b0f7ea85436cffde?pvs=4',
                capability: capabilities.SeeEmployeeFinance,
            },
			{
				title: 'Handleiding Marketing',
				link: 'https://academy.pallasathenagroup.nl/athenastudies-mm',
				capability: capabilities.SeeScopeCapability,
			},
			{
				title: 'Handleiding Content Managers',
				link: 'https://pallasathenagroup.notion.site/Informatiecentrum-Content-Managers-2bd248e12c8b44cd9226792ed445bac5',
				capability: capabilities.SeeScopeCapability,
			},
            {
                title: 'Handleiding AthenaSummary Content Managers',
                link: 'https://pallasathenagroup.notion.site/Informatiecentrum-Content-Managers-dd2a7f7d900e4b08a4b7404fe90c9dac?pvs=4',
                capability: capabilities.ScopeAlterCapability,
            },
			{
				title: 'Handleiding Docenten',
				link: 'https://pallasathenagroup.notion.site/Informatiecentrum-Inhoud-Docenten-79f95a0137eb4c408f3a2a290f89ae75',
				capability: [capabilities.SeeScopeCapability, capabilities.CanTutorCapability],
			},
		],
	},
	en: {
		description: 'The official guides, always consult these first.',
		links: [
            {
                title: 'Guide Business Developers / Operations managers',
                link: 'https://pallasathenagroup.notion.site/Informatiecentrum-Business-Developers-Operations-Managers-b471ec6ab7b54691b0f7ea85436cffde?pvs=4',
                capability: capabilities.SeeEmployeeFinance,
            },
			{
				title: 'Guide Marketing',
				link: 'https://drive.google.com/drive/folders/1DncI3UgWUXhBA_3I6YRql2MK1_GQv_Ex',
				capability: capabilities.SeeScopeCapability,
			},
			{
				title: 'Guide Content Managers',
				link: 'https://pallasathenagroup.notion.site/Information-Centre-Content-Managers-35b3739bad9543d29b6e7510b122f6da?pvs=4',
				capability: capabilities.SeeScopeCapability,
			},
            {
                title: 'Guide AthenaSummary Content Managers',
                link: 'https://pallasathenagroup.notion.site/Information-Centre-Content-Managers-16f67a276e9a4220a2d9745b840bf10d?pvs=4',
                capability: capabilities.ScopeAlterCapability,
            },
            {
                title: 'Guide Teachers',
                link: 'https://pallasathenagroup.notion.site/Information-Centre-Content-Tutors-db9f1dd681a2476a805619e9e80e994f?pvs=4',
                capability: [capabilities.SeeScopeCapability, capabilities.CanTutorCapability],
            },
		],
	},
	de: {
		description: 'Unsere offiziellen Handbücher. Verwende sie immer als erste Anlaufstelle für Fragen!',
		links: [
			{
				title: 'Leitfaden Tutor:innen ',
				link: 'https://drive.google.com/drive/folders/14Zen9du4mv1tV_QNmyR1KYIDE1YYd1co',
				capability: [capabilities.SeeScopeCapability, capabilities.CanTutorCapability],
			},
			{
				title: 'Wichtige Dokumente',
				link: 'https://drive.google.com/drive/folders/1ZFFXArH98P32ghOa_35euif_zOoZQmQd',
				capability: capabilities.SeeScopeCapability,
			},
			{
				title: 'Leitfaden Marketing',
				link: 'https://drive.google.com/drive/folders/1oKRDwzhXuhxRRIRYeA_BJKXZrD9g85oj',
				capability: capabilities.SeeScopeCapability,
			},
			{
				title: 'Leitfaden Content',
				link: 'https://drive.google.com/drive/folders/12HTtzOBTKGM7-M5a65x1oVJPROe9g3q6',
				capability: capabilities.SeeScopeCapability,
			},
		],
	},
};

const totalPermissions = uniq(chain((v) => v, values(mapObjIndexed(
	(lang) => chain(prop('capability'), lang.links),
	content,
))));

const DashboardInformation = ({ hasCapability, topLevelScope }) => {
	const { t } = useTranslation();
	if (none(hasCapability, totalPermissions)) return null;

	return (
		<PageContentBlock
			title={t('dashboard.information.title')}
			description={t('dashboard.information.description')}
			icon="local_library"
		>
			<PageContentBlock.Content contentPadding>
				{topLevelScope && topLevelScope.abbreviation === 'ASTU-DE' ? (
					<div className="info-dialog-markdown">
						<Typography variant="h5">Google Drive</Typography>
						<InfoContent infoContent={content.de} hasCapability={hasCapability} />
						<Typography variant="subtitle2">The Dutch guides</Typography>
						<InfoContent infoContent={content.nl} hasCapability={hasCapability} />
						<InfoContent infoContent={content.en} hasCapability={hasCapability} />
					</div>
				) : (
					<div className="">
						<Typography variant="h5">Handleidingen en Documenten</Typography>
						<InfoContent infoContent={content.nl} hasCapability={hasCapability} />
						<InfoContent infoContent={content.en} hasCapability={hasCapability} />
						<Typography variant="subtitle2">The German guides</Typography>
						<InfoContent infoContent={content.de} hasCapability={hasCapability} />
					</div>
				)}
			</PageContentBlock.Content>
		</PageContentBlock>
	);
};

DashboardInformation.propTypes = {
	hasCapability: PropTypes.func.isRequired,
};

export default withWorkingScope(DashboardInformation);
