import { MenuItem, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PaymentStrategyKeys = {
	// t('manage.company.functions.paymentStrategies.compensationBatch')
	COMPENSATION_BATCH: 'compensationBatch',
	// t('manage.company.functions.paymentStrategies.salarySlip')
	SALARY_SLIP: 'salarySlip',
	// t('manage.company.functions.paymentStrategies.none')
	NONE: 'none',
};

export const PaymentStrategies = Object.keys(PaymentStrategyKeys);

function PaymentStrategySelect({ name, value, onChangeDirect, ...rest }) {
	const { t } = useTranslation();

	const handleChange = useCallback((e) => {
		onChangeDirect(name, e.target.value);
	}, [onChangeDirect, name]);

	return (
		<TextField
			select
			value={value}
			onChange={handleChange}
			{...rest}
		>
			{PaymentStrategies.map((strategy) => (
				<MenuItem key={strategy} value={strategy}>
					{t(`manage.company.functions.paymentStrategies.${PaymentStrategyKeys[strategy]}`)}
				</MenuItem>
			))}
		</TextField>
	);
}

PaymentStrategySelect.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChangeDirect: PropTypes.func.isRequired,
};

export default PaymentStrategySelect;
