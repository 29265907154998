import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import PageHeaderNavigation from '../page/PageHeaderNavigation'
import PageContentBlock from '../page/PageContentBlock'
import FinancesReportCourse from '../financial/course/FinancesReportCourse'

const CourseFinancialPage = ({ course }) => {
	return (
		<Fragment>
			<PageHeader>
				<PageHeaderNavigation breadcrumbs={[
					{ to: `/portal/planner/`, label: 'Planner' },
					{ to: `/portal/planner/course/${course.id}`, label: course.name },
					{ label: 'Financial overview' },
				]} />
				<PageHeaderTitle headline="Financial overview"
								 subHeading={course.name} />
			</PageHeader>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageContentBlock contentPadding>
						<FinancesReportCourse scopeId={course.id} />
					</PageContentBlock>
				</Grid>
			</Grid>
		</Fragment>
	);
};

CourseFinancialPage.propTypes = {
	course: PropTypes.object,
};

export default CourseFinancialPage
