import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import webstoreLocales from './locales/WebstoreLocales';

const CompanyCaptionSelector = ({ handleSelectChange, translations }) => {
	const { t } = useTranslation();
	const captionsArray = Object.keys(webstoreLocales).map(key => ({
		key,
		value: webstoreLocales[key],
		override: translations?.[key],
	}));

	const filterOptions = createFilterOptions({
		stringify: (option) => `${option.value} ${option.key}`,
		trim: true,
	});

	return (
		<Autocomplete
			sx={{ width: 300 }}
			options={captionsArray}
			autoHighlight
			onChange={handleSelectChange}
			getOptionLabel={option => option.key}
			isOptionEqualToValue={(option) => option.key}
			placeholder={t('manage.company.captionDialog.addNewCaptionOverride')}
			filterOptions={filterOptions}
			renderOption={(props, option) => (
				<li {...props} key={option.key}>
					<Box>
						{/* eslint-disable-next-line react/prop-types */}
						<Typography>{option.key}</Typography>
						<Typography variant="subtitle2">
							{/* eslint-disable-next-line react/prop-types */}
							{option.value}
						</Typography>
					</Box>
				</li>
			)}
			getOptionDisabled={(option) => !!option.override}
			renderInput={(params) => (
				<TextField
					{...params}
					label={t('manage.company.captionDialog.searchCaption')}
					inputProps={{
						...params.inputProps,
						autoComplete: 'new-password', // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
};

CompanyCaptionSelector.propTypes = {
	handleSelectChange: PropTypes.func.isRequired,
	translations: PropTypes.object,
};

export default CompanyCaptionSelector;
