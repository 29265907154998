import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { propertyLabel } from '../../helpers/properties';
import TranslatableStringEditor from '../util/TranslatableStringEditor';

function PropertyEditorLabel({ property, handleAddLabel, editMode }) {
	const [open, setOpen] = useState(false);
	const { t, i18n } = useTranslation();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirm = (translatableString) => () => {
		handleAddLabel(translatableString);
		handleClose();
	};

	const handleDelete = () => {
		handleAddLabel(null);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{open && (
				<TranslatableStringEditor
					handleConfirm={handleConfirm}
					name={property.key}
					translatableString={property.options?.label}
					onClose={handleClose}
					onDelete={handleDelete}
				/>
			)}
			{propertyLabel(property, i18n.language) || (<i>{t('properties.edit.fillInKey')}</i>)}
			{editMode && (
				<IconButton onClick={handleOpen} style={{ marginLeft: '1rem' }} disabled={property?.key.length === 0}>
					<Icon>edit</Icon>
				</IconButton>
			)}
		</div>
	);
}

PropertyEditorLabel.propTypes = {
	property: PropTypes.object.isRequired,
	handleAddLabel: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
};

export default PropertyEditorLabel;
