import { CALL_API } from './schemas'

export const EVALUATION = 'EVALUATION'

export const getScopeEvaluationQuestionTranslations = () => ({
	[CALL_API]: {
		type: EVALUATION,
		endpoint: `evaluation-form/question-translations`,
	},
})

export const getScopeEvaluation = (scopeId, teacherId) => ({
	[CALL_API]: {
		type: EVALUATION,
		endpoint: `scope/${scopeId}/aggregated-evaluation-forms${teacherId && teacherId !== 'all' ? `?teacherId=${teacherId}` : ''}`,
	},
})

export const getSubScopeEvaluations = (scopeId, teacherId) => ({
	[CALL_API]: {
		type: EVALUATION,
		endpoint: `scope/${scopeId}/aggregated-evaluation-forms/sub-scopes${teacherId && teacherId !== 'all' ? `?teacherId=${teacherId}` : ''}`,
	},
})


export const getScopeEvaluationDetails = (scopeId, teacherId) => ({
	[CALL_API]: {
		type: EVALUATION,
		endpoint: `scope/${scopeId}/evaluation-forms${teacherId && teacherId !== 'all' ? `?teacherId=${teacherId}` : ''}`,
	},
})
