import { Link, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getClaimFormsInScope } from '../../../actions/declarationsActions';
import declarationStatuses from '../../../constants/DeclarationStatuses';
import withFetchData from '../../../modules/fetchData/withFetchData';
import withWorkingScope from '../../../modules/scope/withWorkingScope';
import ModalItem from '../../modal/ModalItem';
import ModalManager from '../../modal/ModalManager';
import PageContentBlock from '../../page/PageContentBlock';
import PageHeader from '../../page/PageHeader';
import PageHeaderTitle from '../../page/PageHeaderTitle';
import Alert from '../../util/alert/Alert';
import FilterValuesPicker from '../../util/FilterValuesPicker';
import DeclarationContextProvider, { DeclarationContexts } from './DeclarationContext';
import DeclarationsTable from './DeclarationsTable';
import DeclarationModal from './DeclarationModal';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';

const { PROFILE, SCOPE } = DeclarationContexts;
const { CREATED, WITHDRAWN, SUBMITTED } = declarationStatuses;

/*
* t('declarations.status.SUBMITTED')
* t('declarations.status.DECLINED')
* t('declarations.status.APPROVED')
* t('declarations.status.REIMBURSED')
* */

function DeclarationsPage({
	scope,
	load,
	data: declarations,
	error,
	loading,
	pagination,
	onPaginationChangePage,
	onPaginationChangePageSize,
}) {
	const { t } = useTranslation();
	const location = useLocation();
	const [statuses, setStatuses] = useState([SUBMITTED]);
	const scopeName = scope ? scope.name : '';

	const declarationStatusesArr = useMemo(() => Object.values(declarationStatuses)
		.filter(status => ![CREATED, WITHDRAWN].includes(status)), []);

	useEffect(() => {
		if (scope?.id) {
			load(getClaimFormsInScope(scope.id, statuses));
		}
	}, [load, scope, pagination.page, pagination.pageSize, statuses]);

	if (!scope) return (
		<Alert type={Alert.TYPE_INFO}>{t('general.selectScopeFirst')}</Alert>
	);

	return (
		<DeclarationContextProvider value={SCOPE}>
			<ModalManager>
				<ModalItem state="editDeclarations" component={DeclarationModal} location={location} />
			</ModalManager>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderTitle
							headline={t(`declarations.scope.headline`, { scope: scopeName })}
							subHeading={(
								<Typography paragraph>
									<Trans i18nKey="declarations.scope.subHeading" values={{ scope: scopeName }}>
										Here you will find a list of the declarations connected to
										{' '}
										{scopeName}
										{' '}
										and their statuses.
										{' '}
										<Link to="/portal/employees">Check the employee page</Link>
										{' '}
										to check all declarations filed by a specific employee.
									</Trans>
								</Typography>
							)}
						/>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '1rem' }}>
								<FilterValuesPicker
									options={declarationStatusesArr}
									selected={statuses}
									onChange={(selectedStatuses) => setStatuses(selectedStatuses)}
									i18nKey="declarations.status"
									loading={loading}
								/>
							</Grid>
						</Grid>
					</PageHeader>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageContentBlock>
						{ loading && (
							<Loader />
						)}
						{ error && (
							<ApiError error={error} />
						)}
						{ Array.isArray(declarations) && !loading && (
							<DeclarationsTable
								declarations={declarations}
								location={location}
								pagination={pagination}
								onPaginationChangePage={onPaginationChangePage}
								onPaginationChangePageSize={onPaginationChangePageSize}
							/>
						)}
					</PageContentBlock>
				</Grid>
			</Grid>
		</DeclarationContextProvider>
	);
}

export default withWorkingScope(withFetchData(null, {
	customId: () => 'EmployeeDeclarations',
	pagination: true,
	paginationPageSize: 25,
	keepData: true,
})(DeclarationsPage));
