import { Schemas, CALL_API } from '../../actions/schemas'

export const TIME_SLOTS = 'TIME_SLOTS';

export const getGroupTimeslots = (groupId) => ({
	[CALL_API]: {
		type: TIME_SLOTS,
		endpoint: `athenastudies-course-group/${groupId}/timeslots`,
		schema: [Schemas.TIME_SLOT],
	},
	groupId: groupId,
});
