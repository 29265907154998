import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFunctionCapabilities } from '../../../../actions/functionsActions';
import withFetchData from '../../../../modules/fetchData/withFetchData';
import ModalManager from '../../../modal/ModalManager';
import PageContentBlock from '../../../page/PageContentBlock';
import Loader from '../../../util/loader/Loader';

const CompanyFunctionCapabilities = ({ functionId, loading, data }) => {
	const { t } = useTranslation();
	const shownCapabilities = useMemo(() => {
		if (data?.capabilities) {
			return Object.keys(data.capabilities)
				.filter(cap => !!data.capabilities[cap]);
		}
		return [];
	}, [data]);
	if (loading) {
		return <Loader sheet />;
	}
	return (
		<PageContentBlock
			title={t('manage.company.function.capabilities.title')}
			description={t('manage.company.function.capabilities.description')}
			icon="lock"
			contentPadding
			links={[{
				label: 'Edit capabilities',
				to: `/portal/company/function/${functionId}`,
				state: {
					modal: 'functionCapabilitiesEdit',
					id: functionId,
					capabilities: data?.capabilities || {},
				},
			}]}
		>
			{shownCapabilities.join(', ')}
			{shownCapabilities.length === 0 && 'No capabilities'}
		</PageContentBlock>
	);
};

CompanyFunctionCapabilities.propTypes = {
	functionId: PropTypes.string.isRequired,
};

export default withFetchData(props => getFunctionCapabilities(props.functionId), {
	mapEntities: 'functionCapabilities',
})(CompanyFunctionCapabilities);
