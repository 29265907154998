import React, { Component, Fragment } from 'react'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import withFetchData from '../../modules/fetchData/withFetchData'
import { getApplications } from '../../actions/employeeActions'
import { Link } from 'react-router-dom'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'

const rowsOptions = [5, 10, 25, 50];

function *scopeParents(scope) {
	if (scope.parent) yield *scopeParents(scope.parent)
	yield scope;
}

class EmployeeApplications extends Component {
	state = {
		payoutSummary: [],
		rowsPerPage: 5,
		page : 0
	};

	render() {
		const { loading, error, data } = this.props;
		const applications = data || [];


		if (loading) {
			return <Loader/>
		}

		if (error) return (
			<Alert>Error! Sorry, we could not load the data.</Alert>
		);

		return (
			<Fragment>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell size="small">Function</TableCell>
							<TableCell size="small">Scope</TableCell>
							<TableCell size="small">Status</TableCell>
							<TableCell padding="checkbox"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{ applications && applications.length ?
							applications
								.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage)
								.map((application) => (
								<TableRow key={application.id}>
									<TableCell size="small">
										{application.applyingAs.name}
									</TableCell>
									<TableCell size="small">
										{Array.from(scopeParents(application.applyingIn)).map((scope) => scope.abbreviation).join(' / ')}
									</TableCell>
									<TableCell size="small" style={{textTransform: 'capitalize', whiteSpace: 'nowrap'}}>
										{(application.status || "").replace('_', ' ').toLowerCase()}
									</TableCell>
									<TableCell padding="checkbox" align="right">
										<IconButton component={Link} to="/apply" query={{ hash: application.hash }}>
											<Icon>keyboard_arrow_right</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							))
							:
							<TableRow>
								<TableCell>No results</TableCell>
							</TableRow>
						}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={4}
								count={applications.length}
								rowsPerPage={this.state.rowsPerPage}
								rowsPerPageOptions={rowsOptions}
								page={this.state.page}
								onPageChange={(event, page) => {
									if (this.state.page !== page) {
										this.setState({ page: page })
									}
								}}
								onRowsPerPageChange={(event) => this.setState({ rowsPerPage: event.target.value })}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Fragment>
		)
	}

}

export default withFetchData((props) => {
	return getApplications(props.params.employeeId)
})(EmployeeApplications)
