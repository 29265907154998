import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ScopeAthenaStudiesCourseForm from '../scope/edit/ScopeAthenaStudiesCourseForm';

const dialogContentStyle = {
	minHeight: 100,
};

class CourseEditModal extends Component {
	bindSave = null;

	handleBind = (func) => {
		this.bindSave = func;
		this.forceUpdate(); // So the buttons render
	}

	render() {
		const { course, handleClose } = this.props;

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth>
				<DialogTitle>
					Edit
					{' '}
					{course.name}
				</DialogTitle>
				<DialogContent style={dialogContentStyle}>
					<ScopeAthenaStudiesCourseForm
						scopeId={course.id}
						bindSave={this.handleBind}
						onSaved={handleClose}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					{this.bindSave && (
						<Button
							onClick={this.bindSave}
							color="primary"
							variant="contained"
						>
							Save course
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

CourseEditModal.propTypes = {
	course: PropTypes.object,
	handleClose: PropTypes.func,
};

export default CourseEditModal;
