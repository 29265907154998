import { tableCellClasses, tableRowClasses } from '@mui/material';
import React, { Component, Fragment } from 'react'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import MUITableCell from '@mui/material/TableCell'
import MUITableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { getFinanceScopeReportDetails } from '../../../actions/financeActions'
import { GROUP_STATE_ADMINISTRATION_COMPLETE } from '../../../helpers/groupState'
import { formatMoneyAuto } from '../../../helpers/formatter'
import FetchDataSwitch from '../../util/FetchDataSwitch'
import FinancesGroupApproval from './FinancesGroupApproval'
import FinancesReportClickInfo from './FinancesReportClickInfo'

const PREFIX = 'FinancesReportCourse';

const classes = {
	root: `${PREFIX}-root`,
    emptyRowDouble: `${PREFIX}-emptyRowDouble`,
    emptyRow: `${PREFIX}-emptyRow`,
    emptyRowHalf: `${PREFIX}-emptyRowHalf`,
    costsCell: `${PREFIX}-costsCell`,
    revenueCell: `${PREFIX}-revenueCell`,
    headRow: `${PREFIX}-headRow`,
    totalNet: `${PREFIX}-totalNet`,
    totalNetProfit: `${PREFIX}-totalNetProfit`,
    bottomBorder: `${PREFIX}-bottomBorder`,
    thickBottomBorder: `${PREFIX}-thickBottomBorder`,
    acceptTerms: `${PREFIX}-acceptTerms`,
    maxWidthNull: `${PREFIX}-maxWidthNull`,
    paddingNull: `${PREFIX}-paddingNull`
};

const StyledTable = styled(Table)(({ theme }) => ({
	[`&.${classes.root}`]: {
		[`& .${classes.emptyRowDouble}`]: {
			height: theme.spacing(2),
		},

		[`& .${classes.emptyRow}`]: {
			height: theme.spacing(1),
		},

		[`& .${classes.emptyRowHalf}`]: {
			height: theme.spacing(0.5),
		},

		[`& .${classes.costsCell}`]: {
			color: theme.palette.error.main,
		},

		[`& .${classes.revenueCell}`]: {
			color: theme.palette.success.main,
		},

		[`& .${classes.headRow}`]: {
			height: theme.spacing(8),
		},

		[`& .${classes.totalNet}`]: {
			color: theme.palette.primary.contrastText,
			fontWeight: theme.typography.fontWeightBold,
			height: theme.spacing(8),
			verticalAlign: 'middle',
			fontSize: '1.5rem',
			backgroundColor: theme.palette.error.main,
		},

		[`& .${classes.totalNetProfit}`]: {
			backgroundColor: theme.palette.success.main,
		},

		[`& .${classes.bottomBorder}`]: {
			borderBottom: '1px solid grey',
		},

		[`& .${classes.thickBottomBorder}`]: {
			borderBottom: '5px double grey',
		},

		[`& .${classes.acceptTerms}`]: {
			paddingTop: theme.spacing(2),
		},

		[`& .${classes.maxWidthNull}`]: {
			maxWidth: 0,
		},

		[`& .${classes.paddingNull}`]: {
			padding: 0,
		}
	},
}));

const TableCell = styled(MUITableCell)(({ theme }) => ({
	[`&.${tableCellClasses.root}`]: {
		borderBottom: 0,
		fontWeight: theme.typography.fontWeightBold,
		verticalAlign: 'bottom',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(3),
		fontSize: '0.9rem',
	},
}));

const TableRow = styled(MUITableRow)(({ theme }) => ({
	[`&.${tableRowClasses.root}`]: {
		height: theme.spacing(4),
	},
}));

const TableEndCell = styled(MUITableCell)({
	[`&.${tableCellClasses.root}`]: {
		paddingLeft: 0,
		paddingRight: 0,
		minWidth: 0,
		borderBottom: 0,
		'&:last-child': {
			paddingRight: 0,
		},
	},
});

const CourseGroupLedgerType = {
	GROSS_REVENUE: 'GROSS_REVENUE',
	TEACHER_SALARY: 'TEACHER_SALARY',
	EMPLOYEE_COMMISSION: 'EMPLOYEE_COMMISSION',
	BOOKLET_WRITER_EXPENSE: 'BOOKLET_WRITER_EXPENSE',
	LOCATION_EXPENSE: 'LOCATION_EXPENSE',
	OTHER_EXPENSE: 'OTHER_EXPENSE',
	BOOKLET_PRINTING_EXPENSE: 'BOOKLET_PRINTING_EXPENSE',
	TRANSACTION_COSTS: 'TRANSACTION_COSTS',
	PASSED_TRANSACTION_COSTS: 'PASSED_TRANSACTION_COSTS',
	REFUND_EXPENSE: 'REFUND_EXPENSE',
	DISCOUNT: 'DISCOUNT',
	PARTNER_DISCOUNT: 'PARTNER_DISCOUNT',
	PARTNER_COMMISSION: 'PARTNER_COMMISSION',
};

const AggregatedCourseGroupLedgerEntryType = {
	NET_REVENUE: 'NET_REVENUE',
	DIRECT_COSTS: 'DIRECT_COSTS',
	GROSS_PROFIT: 'GROSS_PROFIT',
};

const DATE_FORMAT = 'DD-MM-YYYY';

class FinancesReportCourse extends Component {
	colSpan = 6;

	render() {
		const { data, loading, error } = this.props;

		return (
			<FetchDataSwitch {...{data: [data], loading, error}}>
				{this.renderData()}
			</FetchDataSwitch>
		);
	}

	renderData() {
		const { data } = this.props;

		if (!data) return null;

		const { scope, entries, subtotals, totals } = data;

		const profitable = totals[AggregatedCourseGroupLedgerEntryType.GROSS_PROFIT].amount >= 0;

		const teacherSalaryEntries = entries && entries[CourseGroupLedgerType.TEACHER_SALARY];
		const employeeCommissionEntries = entries && entries[CourseGroupLedgerType.EMPLOYEE_COMMISSION];
		const bookletWriterEntries = entries && entries[CourseGroupLedgerType.BOOKLET_WRITER_EXPENSE];
		const locationEntries = entries && entries[CourseGroupLedgerType.LOCATION_EXPENSE];
		const otherExpenseEntries = entries && entries[CourseGroupLedgerType.OTHER_EXPENSE];

		return (
            <StyledTable className={classes.root} size="small">
				<TableHead />
				<TableBody>
					<TableRow>
						<TableCell
                            colSpan={7}
                            align={'center'}
                            className={classNames(classes.totalNet, {
								[classes.totalNetProfit]: profitable,
							})}
                            >
							{`${profitable ? 'Profit' : 'Loss'} ${formatMoneyAuto(totals[AggregatedCourseGroupLedgerEntryType.GROSS_PROFIT])}`}
						</TableCell>
						<TableEndCell />
					</TableRow>
				</TableBody>

				{/**
				 * TEACHERS
				 */}
				<TableHead>
					<TableRow className={classes.headRow}>
						<TableCell>
							<Typography variant={'h6'}>Teachers</Typography>
						</TableCell>
						<TableCell>Group</TableCell>
						<TableCell>Evaluation</TableCell>
						<TableCell>Hourly Rate</TableCell>
						<TableCell>Hours</TableCell>
						<TableCell
                            align={'right'}
                            >Amount</TableCell>
						<TableEndCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{teacherSalaryEntries && teacherSalaryEntries.length ? teacherSalaryEntries.map(teacherEntry =>
						<TableRow
                            key={teacherEntry.rowNumber}
                        >
							<TableCell>
								<Link to={`/portal/employees/${teacherEntry.contract.employee.id}/view`}>
									{teacherEntry.contract.employee.fullName}
								</Link>
							</TableCell>
							<TableCell>
								<Link to={`/portal/planner/course/${teacherEntry.courseGroup.parent.id}/group/${teacherEntry.courseGroup.id}`}>
									{teacherEntry.courseGroup.name}
								</Link>
							</TableCell>
							<TableCell>{teacherEntry.courseGroup.evaluation ? teacherEntry.courseGroup.evaluation : '-'}</TableCell>
							<TableCell>{`${formatMoneyAuto(teacherEntry.contract.baseSalary)} / ${formatMoneyAuto(teacherEntry.contract.bonusSalary)}`}</TableCell>
							<TableCell>{moment.duration(teacherEntry.duration).humanize()}</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(teacherEntry.amount)}
							</TableCell>
							<TableEndCell>
								{teacherEntry.owningCourseGroup && (
									<FinancesReportClickInfo>
										{`${formatMoneyAuto(teacherEntry.amountOnOtherCourseGroup)} is allocated on `}
										<Link to={`/portal/planner/course/${teacherEntry.owningCourseGroup.parent.id}/group/${teacherEntry.owningCourseGroup.id}`}>
											{`${teacherEntry.owningCourseGroup.parent.name} - ${teacherEntry.owningCourseGroup.name}`}
										</Link>
									</FinancesReportClickInfo>
								)}
							</TableEndCell>
						</TableRow>
					) : (
						<TableRow>
							<TableCell
                                colSpan={this.colSpan}
                                >
								<Typography component={'em'}>
									No teacher expenses are allocated to this course
								</Typography>
							</TableCell>
						</TableRow>
					)}

				</TableBody>

				{/**
				 * MANAGERS
				 */}
				<TableHead>
					<TableRow className={classes.headRow}>
						<TableCell>
							<Typography variant={'h6'}>Managers</Typography>
						</TableCell>
						<TableCell>Role / Group</TableCell>
						<TableCell>Contract (from / to)</TableCell>
						<TableCell>Allocated revenue</TableCell>
						<TableCell>Percentage</TableCell>
						<TableCell
                            align={'right'}
                            >Amount</TableCell>
						<TableEndCell>
							<FinancesReportClickInfo>
								Please note that revenue commissions are only earned over orders placed within the contract term,<br/>for courses that took place during the contract term.
							</FinancesReportClickInfo>
						</TableEndCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{employeeCommissionEntries && employeeCommissionEntries.length ? employeeCommissionEntries.map(employeeCommissionEntry =>
						<TableRow
                            key={employeeCommissionEntry.rowNumber}
                        >
							<TableCell>
								<Link to={`/portal/employees/${employeeCommissionEntry.contract.employee.id}/view`}>
									{employeeCommissionEntry.contract.employee.fullName}
								</Link>
							</TableCell>
							<TableCell>{employeeCommissionEntry.contract.function.name}</TableCell>
							<TableCell>{`${moment(employeeCommissionEntry.contract.startDate).format(DATE_FORMAT)} - ${employeeCommissionEntry.contract.endDate ? moment(employeeCommissionEntry.contract.endDate).format(DATE_FORMAT) : 'present'}`}</TableCell>
							<TableCell>
								{employeeCommissionEntry.revenue && formatMoneyAuto(employeeCommissionEntry.revenue)}
							</TableCell>
							<TableCell>{`${employeeCommissionEntry.contract.turnoverPercentage}% / ${employeeCommissionEntry.contract.bonusTurnoverPercentage}%`}</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(employeeCommissionEntry.amount)}
							</TableCell>
							<TableCell />
						</TableRow>
					) : (
						<TableRow>
							<TableCell
                                colSpan={this.colSpan}
                                >
								<Typography component={'em'}>
									No revenue commissions are allocated to this course
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>

				{/**
				 * WRITERS
				 */}
				<TableHead>
					<TableRow className={classes.headRow}>
						<TableCell>
							<Typography variant={'h6'}>Booklet Writers</Typography>
						</TableCell>
						<TableCell>Due date</TableCell>
						<TableCell>Contract (from / to)</TableCell>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                            align={'right'}
                            >Amount</TableCell>
						<TableEndCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{bookletWriterEntries && bookletWriterEntries.length ? bookletWriterEntries.map(bookletWriterEntry => (
						<TableRow
                            key={bookletWriterEntry.rowNumber}
                        >
							<TableCell>
								<Link to={`/portal/employees/${bookletWriterEntry.contract.employee.id}/view`}>
									{bookletWriterEntry.contract.employee.fullName}
								</Link>
							</TableCell>
							<TableCell>{moment(bookletWriterEntry.contract.dueDate).format('D MMMM YYYY')}</TableCell>
							<TableCell>{`${moment(bookletWriterEntry.contract.startDate).format(DATE_FORMAT)} - ${bookletWriterEntry.contract.endDate ? moment(bookletWriterEntry.contract.endDate).format(DATE_FORMAT) : 'present'}`}</TableCell>
							<TableCell />
							<TableCell />
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >{formatMoneyAuto(bookletWriterEntry.amount)}</TableCell>
							<TableEndCell />
						</TableRow>
					)) : (
						<TableRow>
							<TableCell
                                colSpan={this.colSpan}
                                >
								<Typography component={'em'}>
									No booklet writer expenses are allocated to this course
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>

				{/**
				 * LOCATIONS
				 */}
				<TableHead>
					<TableRow className={classes.headRow}>
						<TableCell>
							<Typography variant={'h6'}>Location</Typography>
						</TableCell>
						<TableCell>Room</TableCell>
						<TableCell>Group</TableCell>
						<TableCell>Rate</TableCell>
						<TableCell>Hours</TableCell>
						<TableCell
                            align={'right'}
                            >Amount</TableCell>
						<TableEndCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{locationEntries && locationEntries.length ? locationEntries.map(locationEntry => (
						<TableRow
                            key={locationEntry.rowNumber}
                        >
							<TableCell>
								<Link to={`/portal/location/${locationEntry.classRoom.location.id}`}>
									{locationEntry.classRoom.location.name}
								</Link>
							</TableCell>
							<TableCell>{locationEntry.classRoom.name}</TableCell>
							<TableCell>
								<Link to={`/portal/planner/course/${locationEntry.courseGroup.parent.id}/group/${locationEntry.courseGroup.id}`}>
									{locationEntry.courseGroup.name}
								</Link>
							</TableCell>
							<TableCell>{`${formatMoneyAuto(locationEntry.classRoom.price)} ${locationEntry.classRoom.paymentUnit.replace('_', ' ').toLowerCase()}`}</TableCell>
							<TableCell>{moment.duration(locationEntry.duration).humanize()}</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(locationEntry.amount)}
							</TableCell>
							<TableEndCell>
								{locationEntry.owningCourseGroup && (
									<FinancesReportClickInfo>
										{`${formatMoneyAuto(locationEntry.amountOnOtherCourseGroup)} is allocated on `}
										<Link to={`/portal/planner/course/${locationEntry.owningCourseGroup.parent.id}/group/${locationEntry.owningCourseGroup.id}`}>
											{`${locationEntry.owningCourseGroup.parent.name} - ${locationEntry.owningCourseGroup.name}`}
										</Link>
									</FinancesReportClickInfo>
								)}
							</TableEndCell>
						</TableRow>
					)) : (
						<TableRow>
							<TableCell
                                colSpan={this.colSpan}
                                >
								<Typography component={'em'}>
									No classroom expenses are allocated to this course
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>

				{/**
				 * ADDITIONAL
				 */}
				<TableHead>
					<TableRow className={classes.headRow}>
						<TableCell
                            colSpan={2}
                            >
							<Typography variant={'h6'}>Additional expenses</Typography>
						</TableCell>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                            align={'right'}
                            >Amount</TableCell>
						<TableEndCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{subtotals[CourseGroupLedgerType.BOOKLET_PRINTING_EXPENSE] && subtotals[CourseGroupLedgerType.BOOKLET_PRINTING_EXPENSE] !== 0 ? (
						<TableRow>
							<TableCell
                                colSpan={2}
                                >Booklet</TableCell>
							<TableCell
                                colSpan={3}
                                >Printing expenses</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(subtotals[CourseGroupLedgerType.BOOKLET_PRINTING_EXPENSE])}
							</TableCell>
							<TableEndCell />
						</TableRow>
					) : undefined}

					{subtotals[CourseGroupLedgerType.PARTNER_COMMISSION] && subtotals[CourseGroupLedgerType.PARTNER_COMMISSION] !== 0 ? (
						<TableRow>
							<TableCell
                                colSpan={2}
                                >Partner</TableCell>
							<TableCell
                                colSpan={3}
                                >Commsission paid</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(subtotals[CourseGroupLedgerType.PARTNER_COMMISSION])}
							</TableCell>
							<TableEndCell />
						</TableRow>
					) : undefined}

					{subtotals[CourseGroupLedgerType.TRANSACTION_COSTS] && subtotals[CourseGroupLedgerType.TRANSACTION_COSTS] !== 0 ? (
						<TableRow>
							<TableCell
                                colSpan={2}
                                >Payments</TableCell>
							<TableCell
                                colSpan={3}
                                >Transaction costs</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(subtotals[CourseGroupLedgerType.TRANSACTION_COSTS])}
							</TableCell>
							<TableEndCell />
						</TableRow>
					) : undefined}

				 	{subtotals[CourseGroupLedgerType.REFUND_EXPENSE] && subtotals[CourseGroupLedgerType.REFUND_EXPENSE] !== 0 ? (
						<TableRow>
							<TableCell
                                colSpan={2}
                                >Payments</TableCell>
							<TableCell
                                colSpan={3}
                                >Refund expenses</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(subtotals[CourseGroupLedgerType.REFUND_EXPENSE])}
							</TableCell>
							<TableEndCell />
						</TableRow>
					) : undefined}

					{otherExpenseEntries && otherExpenseEntries.length ? otherExpenseEntries.map(otherExpenseEntry => (
						<TableRow
                            key={otherExpenseEntry.rowNumber}
                        >
							<TableCell
                                colSpan={2}
                                >{otherExpenseEntry.costItem.account.description}</TableCell>
							<TableCell
                                colSpan={3}
                                >{otherExpenseEntry.costItem.description}</TableCell>
							<TableCell
                                align={'right'}
                                className={classes.costsCell}
                                >
								{formatMoneyAuto(otherExpenseEntry.amount)}
							</TableCell>
							<TableEndCell />
						</TableRow>
					)) : (
						<TableRow>
							<TableCell
                                colSpan={this.colSpan}
                                >
								<Typography component={'em'}>
									No other additional expenses are allocated to this course
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>

				<TableBody>
					<TableRow
                        className={classes.emptyRow}
                        >
						<TableCell
                            className={classes.thickBottomBorder}
                            colSpan={this.colSpan}
                             />
					</TableRow>
					<TableRow
                        className={classes.emptyRow}
                        >
						<TableCell
                            colSpan={this.colSpan}
                             />
					</TableRow>
				</TableBody>

				{/**
				 * TOTALS
				 */}
				<TableBody>
					<TableRow>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                             />
						<TableCell>Total expenses</TableCell>
						<TableCell
                             />
						<TableCell
                            align={'right'}
                            className={classes.costsCell}
                            >
							{formatMoneyAuto(totals[AggregatedCourseGroupLedgerEntryType.DIRECT_COSTS])}
						</TableCell>
						<TableEndCell />
					</TableRow>
					<TableRow
                        className={classes.emptyRowHalf}
                        >
						<TableCell
                            colSpan={3}
                             />
						<TableCell
                            colSpan={3}
                            className={classes.bottomBorder}
                             />
					</TableRow>
					<TableRow
                        className={classes.emptyRowHalf}
                         />
					<TableRow>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                             />
						<TableCell>Sold</TableCell>
						<TableCell
                            align={'center'}
                            >
							{scope._class === 'AthenaStudiesCourseGroup' && `${scope.soldUnits} x ${formatMoneyAuto(scope.price)}`}
						</TableCell>
						<TableCell
                            align={'right'}
                            className={classes.revenueCell}
                            >
							{formatMoneyAuto(subtotals[CourseGroupLedgerType.GROSS_REVENUE])}
						</TableCell>
						<TableEndCell />
					</TableRow>
					<TableRow>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                             />
						<TableCell>Discount</TableCell>
						<TableCell
                             />
						<TableCell
                            align={'right'}
                            className={classes.costsCell}
                            >
							{formatMoneyAuto(subtotals[CourseGroupLedgerType.DISCOUNT])}
						</TableCell>
						<TableEndCell />
					</TableRow>
					<TableRow>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                             />
						<TableCell>Partner Discount</TableCell>
						<TableCell
                             />
						<TableCell
                            align={'right'}
                            className={classes.costsCell}
                            >
							{formatMoneyAuto(subtotals[CourseGroupLedgerType.PARTNER_DISCOUNT])}
						</TableCell>
						<TableEndCell />
					</TableRow>
					<TableRow
                        className={classes.emptyRowHalf}
                        >
						<TableCell
                            colSpan={3}
                             />
						<TableCell
                            colSpan={3}
                            className={classes.bottomBorder}
                             />
					</TableRow>
					<TableRow
                        className={classes.emptyRowHalf}
                         />
					<TableRow>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                             />
						<TableCell>Net revenue</TableCell>
						<TableCell
                             />
						<TableCell
                            align={'right'}
                            className={classes.revenueCell}
                            >
							{formatMoneyAuto(totals[AggregatedCourseGroupLedgerEntryType.NET_REVENUE])}
						</TableCell>
						<TableEndCell />
					</TableRow>
					<TableRow
                        className={classes.emptyRow}
                         />
					<TableRow>
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                             />
						<TableCell
                            colSpan={3}
                            align={'center'}
                            className={classNames(classes.totalNet, {
								[classes.totalNetProfit]: profitable,
							})}
                            >
							{`${profitable ? 'Profit' : 'Loss'} ${formatMoneyAuto(totals[AggregatedCourseGroupLedgerEntryType.GROSS_PROFIT])}`}
						</TableCell>
						<TableEndCell />
					</TableRow>
				</TableBody>

				{scope._class === 'AthenaStudiesCourseGroup' && (
					<TableBody>
						<TableRow
                            className={classes.emptyRowDouble}
                         />
						<TableRow
                            className={classes.emptyRowDouble}
                         />
						<TableRow>
							<TableCell
                                colSpan={3}
                                className={classes.maxWidthNull}
                                >
								{scope.state === GROUP_STATE_ADMINISTRATION_COMPLETE && (
									<Fragment>
										<Typography className={classes.acceptTerms}>
											<sup>1</sup> After approving the group finances shown in this page, you
											will not be able to alter any group details (such as teachers or locations).
										</Typography>
										<Typography className={classes.acceptTerms}>
											<sup>2</sup> In case of the financial details are not as expected, the
											administration of the course is incorrect. The only way to alter this
											page is to change the financial details (such as timeslots, locations,
											teacher contracts or FM contracts)
										</Typography>
									</Fragment>
								)}
							</TableCell>
							<TableCell
                                colSpan={3}
                                align={'center'}
                                className={classes.paddingNull}
                                >
								<FinancesGroupApproval
									state={scope.state}
									id={scope.id}
								/>
							</TableCell>
							<TableEndCell />
						</TableRow>
					</TableBody>
				)}

			</StyledTable>
        );
	}
}

export default withFetchData(props => getFinanceScopeReportDetails(props.scopeId))((FinancesReportCourse))
