import React from 'react'
import { styled } from '@mui/material/styles';
import classNames from 'classnames'
import { PropTypesMoney, moneyMinus } from '../../../helpers/money'
import { formatLiveStatsBigMoney } from '../liveStatsBlock/LiveStatsBlock'

const PREFIX = 'LiveWhisker';

const classes = {
    root: `${PREFIX}-root`,
    bar: `${PREFIX}-bar`,
    number: `${PREFIX}-number`,
    lineMiddle: `${PREFIX}-lineMiddle`,
    lineBase: `${PREFIX}-lineBase`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		position: 'relative',
		width: 100,
		height: 20,
		marginRight: theme.spacing(1),
	},

    [`& .${classes.bar}`]: {
		position: 'absolute',
		left: 'calc(50% + 2px)',
		height: 16,
		top: 2,
		paddingLeft: theme.spacing(1),
		'&.positive': {
			backgroundColor: theme.palette.success.main,
		},
		'&.negative': {
			backgroundColor: theme.palette.error.main,
		},
	},

    [`& .${classes.number}`]: {
		position: 'absolute',
		right: '50%',
		height: 24,
		marginTop: -2,
		top: 2,
		textAlign: 'right',
		fontSize: '1.0em',
		fontWeight: theme.typography.fontWeightMedium,
		paddingRight: theme.spacing(1),
	},

    [`& .${classes.lineMiddle}`]: {
		position: 'absolute',
		left: '50%',
		top: 0,
		bottom: 0,
		borderLeft: `2px solid ${theme.palette.text.primary}`,
	},

    [`& .${classes.lineBase}`]: {
		position: 'absolute',
		left: `calc(50% + ${barWidthMax + 2}px)`,
		top: 0,
		bottom: 0,
		borderLeft: `1px dashed ${theme.palette.divider}`,
	}
}));

const barWidthMax = 50;

const LiveWhisker = styled(({ left, right, }) => {
	let factorWidth = ((left.amount / right.amount - 1) * barWidthMax) || 0;

	if(factorWidth === Infinity) factorWidth = 0;

	return (
		<Root className={classes.root}>
			<div className={classes.lineMiddle} />
			<div className={classNames(classes.bar, {
				positive: factorWidth > 0,
				negative: factorWidth < 0,
			})} style={{width: Math.abs(factorWidth)}} />
			<div className={classes.number}>
				{formatLiveStatsBigMoney(moneyMinus(left, right))}
			</div>
			<div className={classes.lineBase} />
		</Root>
	);
})(({ theme }) => ({
    [`&.${classes.root}`]: {
		position: 'relative',
		width: 100,
		height: 20,
		marginRight: theme.spacing(1),
	},

    [`& .${classes.bar}`]: {
		position: 'absolute',
		left: 'calc(50% + 2px)',
		height: 16,
		top: 2,
		paddingLeft: theme.spacing(1),
		'&.positive': {
			backgroundColor: theme.palette.success.main,
		},
		'&.negative': {
			backgroundColor: theme.palette.error.main,
		},
	},

    [`& .${classes.number}`]: {
		position: 'absolute',
		right: '50%',
		height: 24,
		marginTop: -2,
		top: 2,
		textAlign: 'right',
		fontSize: '1.0em',
		fontWeight: theme.typography.fontWeightMedium,
		paddingRight: theme.spacing(1),
	},

    [`& .${classes.lineMiddle}`]: {
		position: 'absolute',
		left: '50%',
		top: 0,
		bottom: 0,
		borderLeft: `2px solid ${theme.palette.text.primary}`,
	},

    [`& .${classes.lineBase}`]: {
		position: 'absolute',
		left: `calc(50% + ${barWidthMax + 2}px)`,
		top: 0,
		bottom: 0,
		borderLeft: `1px dashed ${theme.palette.divider}`,
	}
}))

LiveWhisker.propTypes = {
	left: PropTypesMoney.isRequired,
	right: PropTypesMoney.isRequired,
};

export default LiveWhisker
