import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { values } from 'ramda'
import getDisplayName from 'react-display-name'
import hoistNonReactStatics from 'hoist-non-react-statics'

// TODO: probably combine with withSubScopes (and add no loading as a option, just need to make sure fetchData is not wrapped for performance reasons)

const cachedSubScopesList = {};
const getSubScopes = (scope, state) => {
	const cachedSubScopes = cachedSubScopesList[scope.id] || {};
	if(scope.subScopes !== cachedSubScopes.input){
		cachedSubScopes.input = scope.subScopes;
		cachedSubScopes.output = scope.subScopes && scope.subScopes.map(
			id => state.entities.scopes[id]
		);
	}else{
		const test = scope.subScopes && scope.subScopes.map(
			id => state.entities.scopes[id]
		);

		if(test){
			test.forEach((scope, i) => {
				if(scope !== cachedSubScopes.output[i]){
					cachedSubScopes.output = test;
				}
			});
		}
	}

	cachedSubScopesList[scope.id] = cachedSubScopes;

	return cachedSubScopes.output;
}

const withSubScopesNoLoading = (WrappedComponent) => {
	const Enhanced = connect(
		(state, props) => {
			const scope = props.scope;
			let subScopes = undefined;

			if(scope._virtual){
				subScopes = values(state.entities.scopes).filter(
					s => s.parent === scope.id
				);
			}else{
				subScopes = getSubScopes(scope, state);
			}

			return {
				subScopes: subScopes,
			};
		}
	)(class SubScopesNoLoading extends PureComponent {
		static displayName = `subScopesNoLoading(${getDisplayName(WrappedComponent) || 'Unknown'})`;

		static propTypes = {
			scope: PropTypes.object.isRequired
		};

		render(){
			const { scope, subScopes, ...rest } = this.props;

			return (
				<WrappedComponent
					{...rest}
					scope={scope}
					subScopes={subScopes}
				/>
			);
		}
	});

	return hoistNonReactStatics(Enhanced, WrappedComponent);
}

export default withSubScopesNoLoading
