export const GroupMailTemplateVariables = {
    GROUP_NAME: 'GROUP_NAME',
    START_DATE: 'START_DATE',
    START_DATE_DAY: 'START_DATE_DAY',
    START_TIME: 'START_TIME',
    START_DAY: 'START_DAY',
    COURSE_NAME: 'COURSE_NAME',
    OWN_NAME: 'OWN_NAME',
    OWN_SURNAME: 'OWN_SURNAME',
    OWN_FULLNAME: 'OWN_FULLNAME',
    OWN_NUMBER: 'OWN_NUMBER',
    OWN_EMAIL: 'OWN_EMAIL',
    TUTOR_NAME: 'TUTOR_NAME',
    TUTOR_SURNAME: 'TUTOR_SURNAME',
    TUTOR_FULLNAME: 'TUTOR_FULLNAME',
    TUTOR_NUMBER: 'TUTOR_NUMBER',
    TUTOR_EMAIL: 'TUTOR_EMAIL',
};

export const CourseDescriptionTemplateVariables = {
    INSTITUTE_NAME: 'INSTITUTE_NAME',
    STUDY_NAME: 'STUDY_NAME',
    COURSE_NAME: 'COURSE_NAME',
};

export const CourseDescriptionTemplateSections = [
    'STUDY',
    'SUMMARY',
    'TRAINING',
];
