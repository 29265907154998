import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import { Schemas } from '../../actions/schemas'
import withFormData from '../../modules/formData/withFormData'
import { CALL_API } from '../../setup/api'
import ApiError from '../util/ApiError'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import ZoomMeeting from './zoomMeeting/ZoomMeeting'

const PREFIX = 'CourseGroupTimeSlotsZoomMeetingModal';

const classes = {
    mainText: `${PREFIX}-mainText`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.mainText}`]: {
		marginBottom: theme.spacing(2),
	}
}));

const ApiAction = (timeSlotId) => ({
	[CALL_API]: {
		type: 'ZOOM',
		endpoint: `course-timeslot/${timeSlotId}/zoom-us-meeting`,
		schema: Schemas.TIME_SLOT,
		body: {},
	},
});

const TeacherStateInfo = ({ teacher }) => {
	if(!teacher.zoomAccountId) return (
		<Alert>
			The teacher ({teacher.fullName}) for this time slot does not have an account yet on our online class system on <a href="https://athenastudies.zoom.us" target="_blank">athenastudies.zoom.us</a>. Before you can continue, the teacher will have to create his account through the email he will receive. Make sure the teacher knows an invitation will be send when clicking the button below.
		</Alert>
	);

	if(!teacher.zoomAccountActivated) return (
		<Alert>
			The teacher got an invitation to create their account on <a href="https://athenastudies.zoom.us" target="_blank">athenastudies.zoom.us</a>, but did not yet activate/create it. You may test if the account is ready by clicking the button below.
		</Alert>
	);

	return null;
};

const getCreateMeetingButtonText = (teacher) => {
	if(!teacher.zoomAccountId) return 'Send an invitation to the teacher';
	if(!teacher.zoomAccountActivated) return 'Test the teacher account and attempt to plan an online class';

	return 'Plan an online class for this time slot';
};

const CourseGroupTimeSlotsZoomMeetingModal = ({  modal, timeSlots, handleClose, saving, error, success, watchSubmit }) => {
	const timeSlot = timeSlots.find(slot => slot.id === modal.timeSlotId);

	const handleCreateMeeting = () => {
		watchSubmit(ApiAction(timeSlot.id));
	}

	return (
        <StyledDialog open={true} onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle>
				Online class
			</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.mainText}>
					Online classes are digital meetings using the Zoom software. Consult our official manuals in the Information Centre before using Zoom.
				</DialogContentText>
				{saving && (
					<Loader />
				)}
				<ApiError error={error} />
				{!timeSlot ? (
					<Alert type={Alert.TYPE_WARNING}>
						For some reason this time slot was not loaded. Try again by refreshing the page.
					</Alert>
				) : (
					timeSlot.teacher ? (
						timeSlot.zoomMeetingId ? (
							<ZoomMeeting timeSlot={timeSlot} />
						) : (
							<div>
								<TeacherStateInfo teacher={timeSlot.teacher} />
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleCreateMeeting}
									disabled={saving}
								>
									{getCreateMeetingButtonText(timeSlot.teacher)}
								</Button>
							</div>
						)
					) : (
						<Alert type={Alert.TYPE_WARNING}>
							This time slot does not have a teacher. Therefor it is not possible to create an online class. The reason for this is that for planning the online class, the host (teacher) needs to be known.
						</Alert>
					)
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>
					Close
				</Button>
			</DialogActions>
		</StyledDialog>
    );
}

CourseGroupTimeSlotsZoomMeetingModal.propTypes = {
	group: PropTypes.object.isRequired,
	timeSlots: PropTypes.array,
}

export default (withFormData()(CourseGroupTimeSlotsZoomMeetingModal))
