import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { keys, pick } from 'ramda'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm'
import CountryField from './CountryField'
import Loader from './loader/Loader'
import Alert from './alert/Alert'
import ApiError from './ApiError'

class AddressForm extends Component {
	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSaved){
			this.props.onSaved();
		}
	}

	handleSubmit = () => {
		const { watchSubmit, action, fields, validationFields, validationAll, handleFormError } = this.props;

		validationAll(fields, (hasError) => {
			if(hasError) return handleFormError('Not all fields were filled correctly.');

			watchSubmit(action(pick(keys(validationFields), fields)));
		});
	}

	handleCountryChange = (country) => {
		const { onChangeFieldDirect, validationField } = this.props;

		onChangeFieldDirect('country', country);
		validationField('country', country);
	}

	render(){
		const { successText, validation, validationBlur, fields, onChangeField, saving, success, error } = this.props;

		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				{successText || 'Address was successfully updated and saved.'}
			</Alert>
		);

		return (
			<Fragment>
				{ saving && <Loader sheet /> }
				<ApiError error={error} showDetails hideErrorOnDetails />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField
							name="street"
							value={fields.street || ''}
							onChange={onChangeField}
							label="Street"
							error={!!validation['street']}
							helperText={validation['street']}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<TextField
							name="houseNumber"
							value={fields.houseNumber || ''}
							onChange={onChangeField}
							label="House number"
							error={!!validation['houseNumber']}
							helperText={validation['houseNumber']}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<TextField
							name="houseNumberExtension"
							value={fields.houseNumberExtension || ''}
							onChange={onChangeField}
							label="Extension"
							error={!!validation['houseNumberExtension']}
							helperText={validation['houseNumberExtension']}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={2}>
						<TextField
							name="zipcode"
							value={fields.zipcode || ''}
							onChange={onChangeField}
							label="Zipcode"
							error={!!validation['zipcode']}
							helperText={validation['zipcode']}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							name="city"
							value={fields.city || ''}
							onChange={onChangeField}
							label="City"
							error={!!validation['city']}
							helperText={validation['city']}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CountryField
							name="country"
							value={fields.country}
							onChange={this.handleCountryChange}
							label="Country"
							error={!!validation['country']}
							helperText={validation['country']}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Fragment>
		)
	}
}

AddressForm.propTypes = {
	inputData: PropTypes.object,
	action: PropTypes.func.isRequired,
	bindSave: PropTypes.func.isRequired,
	onSaved: PropTypes.func,
	successText: PropTypes.string,
};

export default withCompleteBasicForm(AddressForm, {
	street: Joi.string(),
	houseNumber: Joi.string(),
	houseNumberExtension: Joi.string().allow('', null),
	zipcode: Joi.string(),
	city: Joi.string(),
	country: Joi.string(),
})
