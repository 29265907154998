import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import PageHeaderNavigation from '../page/PageHeaderNavigation'
import PageContentBlock from '../page/PageContentBlock'
import FinancesReportCourse from '../financial/course/FinancesReportCourse'

const CourseGroupFinancialPage = ({ course, group }) => {
	return (
		<Fragment>
			<PageHeader>
				<PageHeaderNavigation breadcrumbs={[
					{ to: `/portal/planner/`, label: 'Planner' },
					{ to: `/portal/planner/course/${course.id}`, label: course.name },
					{ to: `/portal/planner/course/${course.id}/group/${group.id}`, label: group.name },
					{ label: 'Financial overview' },
				]} />
				<PageHeaderTitle headline="Financial overview"
								 subHeading={`${course.name} - ${group.name}`} />
			</PageHeader>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageContentBlock contentPadding>
						<FinancesReportCourse scopeId={group.id}/>
					</PageContentBlock>
				</Grid>
			</Grid>
		</Fragment>
	);
};

CourseGroupFinancialPage.propTypes = {
	course: PropTypes.object,
	group: PropTypes.object,
};

export default CourseGroupFinancialPage
