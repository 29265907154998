import { createSelectorCreator, defaultMemoize } from 'reselect'

export const isArrayShallowEqual = (a, b) => {
	if(Array.isArray(a) && Array.isArray(b)){
		// If lengths are different, their content is different by definition
		if(a.length !== b.length) return false;

		// All items of a should be equal to all items of b at the same position
		return a.filter((item, i) => !b[i] || item !== b[i]).length === 0;
	}else{
		// Fall back to the default shallow equal check
		return a === b;
	}
};

export const createArrayItemsEqualSelector = createSelectorCreator(
	defaultMemoize,
	isArrayShallowEqual
);
