const webstoreLocales = {
    'account.courses.course_dates': 'Course dates',
    'account.courses.evaluate_button': 'Evaluate this course',
    'account.courses.my_courses': 'My courses',
    'account.courses.no_course_dates': 'No course dates known yet.',
    'account.courses.no_courses': 'You did not order any courses yet.',
    'account.courses.slot.at': 'at',
    'account.courses.slot.given_by': 'Given by',
    'account.courses.slot.goto_online_classroom': 'Go to the online class room (Zoom)',
    'account.courses.slot.online_classroom_no_url': 'Online course',
    'account.edit': 'Edit',
    'account.edit.address_form.button_cancel': 'Cancel',
    'account.edit.address_form.button_save': 'Save',
    'account.edit.address_form.button_save_and_confirm': 'Save and confirm',
    'account.edit.address_form.city': 'City',
    'account.edit.address_form.country': 'Country',
    'account.edit.address_form.country_not_found': 'Country not found',
    'account.edit.address_form.description': 'Your address is used to send the necessary materials for courses which are given online. Always enter a valid postal address.',
    'account.edit.address_form.error_with_fields': 'Not all fields were filled correctly.',
    'account.edit.address_form.go_back': 'Go back',
    'account.edit.address_form.house_number': 'House number',
    'account.edit.address_form.house_number_extension': 'Extension',
    'account.edit.address_form.loading': 'Saving',
    'account.edit.address_form.street': 'Street',
    'account.edit.address_form.success_message': 'Your address was saved successfully.',
    'account.edit.address_form.zipcode': 'Zipcode',
    'account.edit.form.agree_marketing': 'I agree to receive relevant emails about upcoming courses.',
    'account.edit.form.button_cancel': 'Cancel',
    'account.edit.form.button_save': 'Save',
    'account.edit.form.email': 'E-mail',
    'account.edit.form.email_changes_username': 'Mind you! Editing your e-mail will also change the username you login with.',
    'account.edit.form.email_not_the_same': 'E-mails do not match.',
    'account.edit.form.email_repeat': 'Repeat e-mail',
    'account.edit.form.loading': 'Saving',
    'account.edit.form.mobile': 'Mobile',
    'account.edit.form.name': 'First name',
    'account.edit.form.surname': 'Last name',
    'account.edit.my_address': 'My address',
    'account.edit.my_athenastudies': 'My AthenaStudies',
    'account.my_athenastudies': 'My AthenaStudies',
    'account.overview.edit': 'edit',
    'account.overview.email': 'E-mail address',
    'account.overview.mobile': 'Mobile',
    'account.overview.my_details': 'My details',
    'account.overview.name': 'Name',
    'account.overview.postal_address': 'Postal address',
    'basket.side.bullets.1': 'Excellent tutors',
    'basket.side.bullets.2': 'Great course materials',
    'basket.side.bullets.3': 'Personal support',
    'basket.side.bullets.4': 'Passing rate: 91%',
    'basket.side.bullets.title': 'Always...',
    'basket.side.empty': 'Shopping basket is empty',
    'basket.side.help.text': 'Do you need help picking a course that suits you the best? Or do you have any other questions? Let us know :)',
    'basket.side.help.title': 'Help wanted?',
    'basket.side.selected_course': 'Selected course',
    'basket.side.selected_courses': 'Selected courses',
    'basket.side.slider.close': 'Close / continue browsing',
    'basket.side.to_checkout': 'Continue to checkout',
    'basket.side.total': 'Total',
    'checkout.basket.discount.bundle': 'Bundle: {bundleTitle}',
    'checkout.basket.discount.button_add': 'Add',
    'checkout.basket.discount.discount_code': 'Promo code',
    'checkout.basket.discount.error_not_applicable': 'This promo code is not applicable to these courses.',
    'checkout.basket.discount.error_not_found': 'This promo code does not exist.',
    'checkout.basket.entry.bundle_discount': 'bundle discount',
    'checkout.basket.total': 'Subtotal:',
    'checkout.block.postal_address': 'Postal address',
    'checkout.button_register': 'Register',
    'checkout.button_register_label': 'No account yet?',
    'checkout.edit': 'edit',
    'checkout.empty_basket': 'Your cart is empty',
    'checkout.free.button_confirm': 'Confirm',
    'checkout.free.confirm_text': 'This order is free. Accept our {terms} by confirming your order.',
    'checkout.free.terms': 'Terms and Conditions',
    'checkout.logged_in_as': 'Signed in as',
    'checkout.open_order.cancel': 'Cancel',
    'checkout.open_order.see_and_pay': 'View and pay',
    'checkout.open_order.text': 'There is a recent unpaid order. Please decide what to do with this order.',
    'checkout.or': 'or',
    'checkout.pay': 'Checkout',
    'checkout.pay_login': 'Checkout',
    'checkout.pay_text_address': 'Confirm your postal address first. This is necessary to send lesson material of (one of) the courses by post.',
    'checkout.pay_text_login': 'Login or register an account to pay your order.',
    'checkout.pay_text_pay': 'Pay your course by clicking one of the payment options below.',
    'checkout.pay_with': 'Pay with',
    'checkout.postal_address': 'Postal address',
    'checkout.reservation_expires_in': 'Your reservation will expire in {time}',
    'checkout.shopping_basket': 'Cart',
    'contact.address_info': 'Address details',
    'contact.complaints': 'Complaints',
    'contact.complaints_text': 'Are you not content or do you have a complaint? Please contact us via:',
    'contact.contact_info': 'Contact details',
    'contact.info': 'Details',
    'contact.info_question1': 'Which university is your question related to?',
    'contact.info_question2': 'Which programme?',
    'contact.info_question3': 'What is the name of the course?',
    'contact.info_text': 'For all general questions and/or unclarities, mail to {email}. Please mention the following:',
    'contact.social_media': 'Social media',
    'contact.terms': 'Terms and Conditions',
    'contact.title_contact': 'Contact',
    'contact.vacancies': 'Vacancies',
    'contact.vacancies.link': 'vacancies',
    'contact.vacancies.text': 'Are you interested in working with us? View our {vacancies} or contact us via: {email}',
    'content.back': 'Back',
    'course.added_to_basket': 'Added to your basket',
    'course.bundle.button': 'Pick groups',
    'course.bundle.select.add_to_basket': 'Add this bundle to your basket',
    'course.bundle.select.bundle': 'Bundle: {bundleTitle}',
    'course.bundle.select.bundle_added': 'Bundle has been added!',
    'course.bundle.select.pick_groups_first': 'First select a group for each course',
    'course.bundle.select.title': 'Pick your groups',
    'course.bundle.select.visit_shopping_basket': 'View your basket',
    'course.bundle.text': 'Combine with: {courseList}',
    'course.bundle.title': '{percentage} bundle discount - {bundleTitle}',
    'course.course_not_found': 'Could not find the requested course. Perhaps it already ended?',
    'course.description.about_this_course': 'About this course',
    'course.description.in_short': 'In short',
    'course.description.see_less': 'Show less',
    'course.description.see_more': 'Read more',
    'course.filters': 'Filters',
    'course.group.not_available': 'Not available',
    'course.group.pick_course': 'Add',
    'course.group.slots.not_available_anymore': 'No longer available',
    'course.group.slots.not_available_yet': 'No timeslots available yet',
    'course.group.starts_in': 'Starts in {time}',
    'course.group.status.almost_full': 'Last spots',
    'course.group.status.finished': 'Finished',
    'course.group.status.full': 'Full',
    'course.group.status.ongoing': 'Started',
    'course.group.status.sufficient_places': 'Sufficient spots',
    'course.hours.total': '{count, plural,=1 {# hour}other {# hours}}',
    'course.in_cart': 'In cart',
    'course.noResults': 'No results found for the current search criteria. <click>Click to reset the filter</click>.',
    'course.no_groups': 'No groups',
    'course.no_of_groups': '{number} of groups',
    'course.none.alert_request_sign_up': 'Sign up for course updates of this programme by entering your e-mail below. Stay updated!',
    'course.none.email': 'E-mail',
    'course.none.error_email_not_correct': 'E-mail address was not correct, please use a valid address.',
    'course.none.notify_no_courses': 'There are no courses available at this instance. The courses for this programme will be posted briefly!',
    'course.none.sign_up': 'Sign up',
    'course.none.sign_up_success': 'You have successfully signed up {email} for updates of the programme {scope_name}.',
    'course.none.signing_up': 'Signing up',
    'course.per_hour': '{hourPrice} per hour',
    'course.pick_your_group': 'Pick your group',
    'course.price.free': 'Free',
    'course.price.per_hour': '{hourPrice} p/h',
    'course.price.total': '{price} total',
    'course.properties.no': 'No',
    'course.properties.yes': 'Yes',
    'course.resetAll': 'Reset all',
    'course.results': '{count, plural,=1 {1 result}other {# results}}',
    'course.search': 'Search',
    'course.searchPlaceholder': 'Search for...',
    'course.seo_description': 'Take a look at the groups we offer for this course of {course} at {study}. Our top tutors give you the support you need. Combined with our high-quality study material, you will be optimally prepared for your exam.',
    'course.seo_description_prefix': 'Courses and exam training of AthenaStudies at the {institute}.',
    'course.showResults': '{count, plural,=0 {No results}=1 {Show one result}other {Show # results}}',
    'course.sortBy': 'Sort by',
    'course.sortBy.date': 'Date',
    'course.sortBy.name': 'Name',
    'course.status.almost_full': 'Almost full',
    'course.status.full': 'Full',
    'course.status.sufficient_places': 'Open',
    'course.time_till_start': 'Next starts in {time}',
    'course.total': '{totalPrice} total',
    'course.type.base': 'Regular course',
    'course.type.quick': 'Crash course',
    'courses.close_basket_modal': 'Back',
    'courses.filtered_count': '{filteredCount} out of {total} courses are currently visible through the filter.',
    'courses.go_to_basket': 'To your shopping basket',
    'courses.og_course_title': 'Groups for {name}',
    'courses.og_title': 'Courses for {name}',
    'courses.questions_get_in_contact': 'Questions? Get in touch:',
    'courses.seo_description': 'Take a look at the courses we offer for the subjects of {study}. Our top tutors give you the support you need. Combined with our high-quality study material, you will be optimally prepared for your exam. If your subject is not listed, please let us know!',
    'courses.seo_description_prefix': 'Courses and exam training of AthenaStudies at the {institute}.',
    'courses.status.courses': '{count, plural, =1 {1 group} other {# groups}}',
    'courses.study_not_found': 'The chosen programme was not found.',
    'courses.tags.clear': 'Reset',
    'courses.university_not_found': 'The chosen university was not found.',
    'evaluation.evaluation_form': 'Evaluation form',
    'evaluation.fill_all_required_fields': 'Fill in all required questions to submit the evaluation form.',
    'evaluation.link_error': 'Unable to load the requested evaluation form. An incorrect link may have been used.',
    'evaluation.page_title': 'Course evaluation',
    'evaluation.question': 'Question {number}',
    'evaluation.send_evaluation': 'Submit evaluation',
    'evaluation.text': 'In order to improve the quality of our courses and to keep them at a high level, we ask you to fill in this evaluation form. Answer the questions below with a grade on a 1 to 10 scale, where a 1 is the lowest and 10 is the highest grade. The results obtained from the questions below will also lead to the assessment of the tutors. The evaluation form is anonymous.',
    'evaluation.thank_you_message': 'Thank you for filling out the evaluation form. We will take this feedback into account when organizing future courses.',
    'evaluation.to_the_site': 'Go to the website',
    'faq.1.text': 'Yes, these will be posted before the start of the period in which the resit will take place.',
    'faq.1.title': 'Will there also be courses available for the resits?',
    'faq.2.text': 'We create our own study materials. This contains of relevant theory and practice questions, customized for your exam. You will receive this material upon the start of the first lesson.',
    'faq.2.title': 'What kind of study materials are included in the course?',
    'faq.3.text': 'Unfortunately, this is not possible. We will do everything within our power to prepare you as personal for the exam as possible during the regular lessons.',
    'faq.3.title': 'Could I also receive personal/individual tutoring?',
    'faq.4.text': 'Attempt to catch up on the missed materials before the start of the next lesson as well as possible. Often courses build on previously learned theory, so make sure you fall behind as little as possible.',
    'faq.4.title': 'Is it a problem if I miss a lesson?',
    'faq.5.text': 'No, this is unfortunately not possible. As we attempt to provide perfect tutoring, we have a limited number of groups.',
    'faq.5.title': 'Can I be put on a reserve list if all groups are full?',
    'faq.6.text': 'Yes, send an e-mail to {email} to enquire whether there is still a spot available within this course. You will still have to pay the full amount, regardless of when you partake in the course.',
    'faq.6.title': 'Can I subscribe for a course that has already started',
    'faq.7.text': 'This is unfortunately not possible. You may, however, pass on your registration to another person. For additional details, please read our Terms and Conditions.',
    'faq.7.title': 'For how long before the start of the course can I cancel my registration?',
    'faq.8.text': 'You may do so by sending an e-mail to {email}. We will try our best to respond to your complaint as soon as possible.',
    'faq.8.title': 'Where can I file a complaint?',
    'faq.complaints': 'Complaints',
    'faq.courses': 'Courses',
    'faq.subscribe': 'Subscribe/unsubscribe',
    'faq.title': 'Frequently Asked Questions',
    'footer.about_us': 'About us',
    'footer.contact': 'Contact',
    'footer.follow_us': 'Follow us',
    'footer.privacy': 'Privacy',
    'footer.terms': 'Terms and Conditions',
    'header.locale.save.no': 'No',
    'header.locale.save.save': 'Save',
    'header.locale.save.saved': 'Language was saved.',
    'header.locale.save.title': 'Set <strong>{language}</strong> as the default language of your account',
    'header.menu.contact': 'Contact',
    'header.menu.courses': 'Courses',
    'header.menu.faq': 'FAQ',
    'header.menu.home': 'Home',
    'header.my.athenastudies': 'My AthenaStudies',
    'header.my.courses': 'Courses',
    'header.my.login_register': 'Sign in / register',
    'header.my.logout': 'Sign out',
    'header.my.profile': 'My Profile',
    'home.block.text': 'Would you like to attain higher grades in an as efficient way as possible? Our team of strictly selected tutors will help you in the best and most personal way!',
    'home.block.title': 'Online study assistance',
    'home.percentage': 'Passing rate:',
    'home.picker.faculty.not_found': 'No faculty found',
    'home.picker.faculty.select': 'Select your faculty',
    'home.picker.faculty.select_uni_first': 'Pick your university first',
    'home.picker.pick_courses': 'Pick courses',
    'home.picker.study.not_found': 'No programme found',
    'home.picker.study.select': 'Select your programme',
    'home.picker.study.select_faculty_first': 'Pick your faculty first',
    'home.picker.study.select_uni_first': 'Select your university first',
    'home.picker.uni.not_found': 'No university has been found',
    'home.picker.uni.select': 'Select your university',
    'home.widgets.character_limit': 'max. 1500 characters',
    'home.widgets.email_incorrect': 'The e-mail address is filled in incorrectly.',
    'home.widgets.empty_field': 'This required field has not been filled in.',
    'home.widgets.error_with_fields': 'Not all fields have been filled in correctly.',
    'home.widgets.form_mail': 'E-mail address',
    'home.widgets.form_mail_placeholder': 'Your e-mail address',
    'home.widgets.form_name': 'Name',
    'home.widgets.form_name_placeholder': 'Your name',
    'home.widgets.form_name_school': 'School name',
    'home.widgets.form_name_school_placeholder': 'Name of your school',
    'home.widgets.form_question': 'Request',
    'home.widgets.form_question_placeholder': 'Your request',
    'home.widgets.form_send': 'Send message',
    'home.widgets.form_tel': 'Phone number',
    'home.widgets.form_tel_placeholder': 'Your phone number',
    'home.widgets.phone_no_incorrect': 'The phone number was filled in incorrectly.',
    'home.widgets.read_more': 'Read more',
    'home.widgets.required': 'required',
    'home.widgets.tabs_label': 'Tabs',
    'login.form.button_login': 'Sign in',
    'login.form.forgot': 'Forgotten?',
    'login.form.loading': 'Signing in',
    'login.form.no_account': 'No account yet?',
    'login.form.password': 'Password',
    'login.form.register': 'Register',
    'login.form.username_or_email': 'Username or E-mail',
    'login.title': 'Sign in',
    'not_found_page.general': 'General',
    'not_found_page.header': 'Page not found',
    'not_found_page.header_sub': 'Our apologies, the page you are looking for could not be found',
    'not_found_page.text': 'This can be a mistake, try accessing the page again. If this problem persists, please contact us through one of the methods below.',
    'password.forgot.error_email_not_exist': 'The given email address is not known to us. Try another email address or register a new account.',
    'password.forgot.form.button_request': 'Request password',
    'password.forgot.form.email': 'E-mail',
    'password.forgot.form.loading': 'Requesting',
    'password.forgot.form.success_text': 'If this e-mail address is known by us, you will briefly receive an e-mail with instructions on how to reset your password.',
    'password.forgot.form.text': 'Send your e-mail address to reset your password.',
    'password.forgot.title': 'Forgot password',
    'password.reset.form.button_set': 'Reset password',
    'password.reset.form.loading': 'Processing',
    'password.reset.form.login_directly': 'Sign in directly',
    'password.reset.form.no_code_found_text': 'No reset code found. Please use the button in the e-mail.',
    'password.reset.form.password': 'Password',
    'password.reset.form.password_not_the_same': 'Passwords do not match.',
    'password.reset.form.password_repeat': 'Repeat password',
    'password.reset.form.success_text': 'Your password has been reset. {login_directly} to use your account.',
    'password.reset.title': 'Reset password',
    'pay.error.already_paid': 'This order has already been paid. If you have not received an confirmation within one hour, please contact us.',
    'pay.error.log_in': 'Sign in to proceed with the checkout.',
    'pay.error.no_order': 'No order found. Please pick your courses.',
    'pay.error.try_again': 'An error has occurred in the payment process. Please try again.',
    'pay.order_info.date': 'Date',
    'pay.order_info.date_at': 'at',
    'pay.order_info.email': 'E-mail',
    'pay.order_info.items': 'Items',
    'pay.order_info.order_number': 'Order number',
    'pay.order_info.price': 'Price',
    'pay.redirecting': 'Redirecting',
    'pay.success_order_text': 'Hell yeah! The confirmation email will arrive at the speed of light.',
    'pay.success_order_title': 'Congrats!',
    'pay.title.pay': 'Check out',
    'pay.title.pay_of_order_number': 'Pay of',
    'pay.title.thanks': 'Thank you!',
    'pay.title.welcome_back': 'Welcome back!',
    'property.reset': 'Reset',
    'register.form.account_already': 'Do you already have an account?',
    'register.form.agree_marketing': 'I agree to receive relevant emails about upcoming courses.',
    'register.form.email': 'E-mail',
    'register.form.email_not_the_same': 'E-mails do not match.',
    'register.form.email_repeat': 'Repeat e-mail',
    'register.form.loading': 'Registering',
    'register.form.login': 'Sign in',
    'register.form.mobile': 'Mobile',
    'register.form.name': 'First name',
    'register.form.password': 'Password',
    'register.form.password_not_the_same': 'Passwords do not match.',
    'register.form.password_repeat': 'Repeat password',
    'register.form.privacy': 'privacy statement',
    'register.form.register': 'Register',
    'register.form.surname': 'Last name',
    'register.form.terms': 'Terms and Conditions',
    'register.form.terms_text': 'Accept our {terms} and {privacy} to register an account.',
    'register.title': 'Register',
    'root.og_description': 'Courses through which you pass the exam, for students by top students. Success rate: 91%',
    'root.og_title': 'Study assistance for Students',
    'root.seo_description': 'Exam training for students by top students. Do you want higher grades in the most efficient way possible? Our top senior students know exactly how to pass exams. AthenaStudies offers self-written teaching material during the course so you are perfectly prepared for your exam!',
    'root.title': 'Pass your exam',
    'root_error_page.general': 'General',
    'root_error_page.header': 'This site is configured wrongly',
    'root_error_page.header_sub': 'Our apologies, there is something wrong with the settings of this site',
    'root_error_page.ict': 'IT department',
    'root_error_page.text': 'This is of course a problem we would like to solve. We are probably already working on it, but we would be glad if you could still contact us.',
    'scope.institute.exam_training': 'Exam training',
    'scope.institute.info.read_more': 'Read more',
    'scope.institute.no_studies_found': 'No studies available for this university at the time. Please try again later.',
    'scope.institute.select_study': 'Select your programme',
    'scope.institute.seo_description': 'Take exam training courses from AthenaStudies at {institute}. Find your course and pass your exam with our top tutors. You will pass your exam with high-quality study material and support from the best tutors. If your study or subject is not listed, please let us know!',
    'scope.institute.university_not_found': 'The chosen university could not be found.',
    'select.faculty.exam_training': 'Exam training',
    'select.faculty.faculty_not_found': 'Could not find the selected faculty.',
    'select.faculty.no_studies_found': 'No studies available for this faculty at this moment. Please try again later.',
    'select.faculty.select_study': 'Select your study',
    'select.faculty.seo_description': 'Follow exam trainings from AthenaStudies at {faculty}. Find your course and pass your exam with our top tutors. You will pass your exam with high-quality study material and support from the best tutors. If your study or subject is not listed, please let us know!',
    'select.institute.courses': 'Courses for your exam',
    'select.institute.none_found': 'No university found with the given search.',
    'select.institute.og_title': 'Courses',
    'select.institute.seo_description': 'Courses through which you pass the exam, for students by top students. Find out where AthenaStudies is active.',
    'select.institute.title': 'Select your university.',
    'select.scope.search': 'Search...',
    'select.study.exam_training': 'Courses and exam training',
    'select.study.no_studies_found': 'No studies available for this university at this time. Please try again later.',
    'select.study.select_study': 'Select your study',
    'select.study.seo_description': 'Take a look at the exam training we offer for {institute}. Our top tutors give you the support you need. Combined with our high-quality study material, you will be optimally prepared for your exam. If your study or subject is not listed, let us know!',
    'select.study.university_not_found': 'Could not find the chosen university.',
    'vacancies.seo_description': 'Working at AthenaStudies. See our vacancies for all our positions and possibilities.',
    'vacancies.seo_title': 'Vacancies',
    'vacancies.teacher': 'Tutor',
    'vacancies.title': 'Working at AthenaStudies',
    'widget.home_block.click_here': 'Click here',
};

export default webstoreLocales;
