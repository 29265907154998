import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import CourseGroupCostsAddForm from './CourseGroupCostsAddForm'

class CourseGroupCostsAddModal extends Component {
	saveBinder = null;

	state = {
		saved: false
	}

	render(){
		const { group, handleClose } = this.props;
		const { saved } = this.state;

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth>
				<DialogTitle>Add costs to {group.name}</DialogTitle>
				<DialogContent>
					<CourseGroupCostsAddForm
						group={group}
						bindSave={(func) => this.saveBinder = func}
						onSaved={this.handleSaved}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						{saved ? 'Close' : 'Cancel'}
					</Button>
					{
						!saved &&
						<Button onClick={() => {if(this.saveBinder) this.saveBinder()}}
								color="primary"
								variant="contained">
							Add
						</Button>
					}
				</DialogActions>
			</Dialog>
		)
	}

	handleSaved = () => {
		this.setState({
			saved: true
		});
	}
}

export default CourseGroupCostsAddModal;
