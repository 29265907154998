import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PartnerListPage from './PartnerListPage'
import PartnerViewPage from './PartnerViewPage'

const PartnerPage = () => {
	return (
		<Routes>
			<Route path="/" index element={<PartnerListPage />} />
			<Route path="edit/:partnerid" element={<PartnerViewPage />} />
		</Routes>
	);
}

export default PartnerPage
