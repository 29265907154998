import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Autocomplete, createFilterOptions, autocompleteClasses, paperClasses } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Countries from '../../constants/Countries';

// Sort for searching, making sure longer found prefixes take presence
const sortedCountries = Countries.sort((a, b) => b.prefix.length - a.prefix.length);

function splitPhone(number) {
    if (!number) return ['', ''];

    const item = sortedCountries.find(i => number.indexOf(i.prefix) === 0);

    return [item?.prefix || '', number.replace(item?.prefix, '')];
}

function mockTargetEvent(name, value) {
    return {
        target: {
            name, value,
        },
    };
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

function getOptionLabel(item) {
    if (typeof item === 'string') return item;

    return item ? `${item.prefix}` : 'prefix';
}

const filterOptions = createFilterOptions({
    stringify: o => `${o.code} ${o.name} ${o.nameLocal} ${o.prefix}`,
});

const PREFIX = 'PhoneField';

const classes = {
    option: `${PREFIX}-option`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${paperClasses.root}`]: {
        width: 200,
    },

    [`& .${classes.option}`]: {
        '& > span': {
            display: 'inline-block',
            marginRight: theme.spacing(1),
        },
    },
}));

function PrefixAdornment({ value, onChange }) {
    const handleChange = useCallback((e, item) => {
        onChange(item.prefix);
    }, [onChange]);

    return (
        <InputAdornment position="start">
            <Autocomplete
                onChange={handleChange}
                value={value || null}
                options={Countries}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={(option, val) => option.prefix === val}
                renderOption={(props, option) => (
                    <li {...props} key={option.code}>
                        <span>{countryToFlag(option.code)}</span>
                        {' '}
                        {`${option.code} ${option.prefix}`}
                    </li>
                )}
                PaperComponent={StyledPaper}
                classes={{
                    option: classes.option,
                }}
                filterOptions={filterOptions}
                disableClearable
                sx={{ width: 90 }}
                renderInput={(params) => (
                    <TextField {...params} placeholder="prefix" />
                )}
            />
        </InputAdornment>
    );
}

PrefixAdornment.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

function PhoneField({ name, value, onChange, ...rest }) {
    const [prefix, number] = splitPhone(value);

    const handleChange = useCallback((e) => {
        onChange(mockTargetEvent(name, `${prefix}${e.target.value}`));
    }, [onChange, name, prefix]);

    const handleChangePrefix = useCallback((code) => {
        onChange(mockTargetEvent(name, `${code}${number}`));
    }, [onChange, name, number]);

    return (
        <TextField
            {...rest}
            name={name}
            value={number}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <PrefixAdornment value={prefix} onChange={handleChangePrefix} />
                ),
            }}
        />
    );
}

PhoneField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default PhoneField;
