import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { languages, translationByLanguage } from '../../helpers/localization'
import PageContentBlock from '../page/PageContentBlock'

const PREFIX = 'CourseGroupLocationMailFooter';

const classes = {
    block: `${PREFIX}-block`,
    tabs: `${PREFIX}-tabs`,
    content: `${PREFIX}-content`
};

const StyledPageContentBlock = styled(PageContentBlock)(({ theme }) => ({
    [`&.${classes.block}`]: {
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.tabs}`]: {
		backgroundColor: theme.palette.background.default,
		borderBottom: `1px solid ${theme.palette.divider}`,
	},

    [`& .${classes.content}`]: {
		padding: theme.spacing(2),
	}
}));

const indexedLanguages = Object.keys(languages);

class CourseGroupLocationMailFooter extends Component {
	state = {
		tabIndex : 0
	}

	setTabIndexOnChange = (event, value) => {
		this.setState({
			tabIndex: value
		})
	};

	render() {
		const { url, state, footer } = this.props;
		const { tabIndex } = this.state;

		const translation = translationByLanguage(footer)(indexedLanguages[tabIndex]);

		return (
            <StyledPageContentBlock title="Group location mail footer"
							  description="This is an additional text which will appear at the end of each group location mail whenever this location is used in one of the timeslots for the course."
							  icon="mail"
							  links={[
								  { label: 'Edit texts', icon: 'edit', to: url, state, basic: true }
							  ]}
							  className={classes.block}>
				<Tabs value={tabIndex}
					  onChange={this.setTabIndexOnChange}
					  className={classes.tabs} indicatorColor="primary" textColor="inherit">
					{indexedLanguages.map((languageKey) => (
						<Tab label={languages[languageKey]} key={languageKey}/>
					))}
				</Tabs>
				<div className={classes.content}>
					{
						translation && translation.length > 0
						&& <Typography dangerouslySetInnerHTML={{__html: translation}} />
						|| <Typography><em>No translation set for this language.</em></Typography>
					}
				</div>
			</StyledPageContentBlock>
        );
	}
}

CourseGroupLocationMailFooter.propTypes = {
	url: PropTypes.string.isRequired,
	state: PropTypes.object,
	footer: PropTypes.object.isRequired,
};

export default (CourseGroupLocationMailFooter)
