import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen';
import ProfileEditPropertiesForm from './ProfileEditPropertiesForm';

class ProfileEditPropertiesModal extends Component {
	saveBinder = null;

	render() {
		const { user, properties, handleClose, fullScreen, patchPropertiesAction, t, context } = this.props;

		return (
			<Dialog
				open={true}
				onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)}
				maxWidth="md"
				fullWidth
				fullScreen={fullScreen}
			>
				<DialogTitle>{t('profile.editEmployeeProperties')}</DialogTitle>
				<DialogContent>
					<ProfileEditPropertiesForm
						inputData={user}
						properties={properties}
						patchProfileProperties={patchPropertiesAction}
						context={context}
						/* eslint-disable-next-line no-return-assign */
						bindSave={(func) => this.saveBinder = func}
						onSave={handleClose}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						{t('general.cancel')}
					</Button>
					<Button
						onClick={() => { if (this.saveBinder) this.saveBinder(); }}
						color="primary"
						variant="contained"
					>
						{t('general.save')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

ProfileEditPropertiesModal.propTypes = {
	user: PropTypes.object,
	properties: PropTypes.object,
	patchPropertiesAction: PropTypes.func,
	context: PropTypes.string.isRequired,
	fullScreen: PropTypes.bool,
};

export default withTranslation()(withDialogShouldBeFullscreen()(ProfileEditPropertiesModal));
