import Box from '@mui/material/Box';
import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import withWorkingScope from '../../../modules/scope/withWorkingScope'

const PREFIX = 'ScopeIndicator';

const classes = {
    scopeItem: `${PREFIX}-scopeItem`
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`&.${classes.scopeItem}`]: {
		display: 'inline-block',
		marginRight: theme.spacing(0.5),
		[`&+.${classes.scopeItem}`]: {
			'&:before': {
				content: "'chevron_right'",
				fontFamily: 'Material Icons',
				verticalAlign: 'middle',
				display: 'inline-block',
				marginRight: theme.spacing(0.5),
			},
		},
	}
}));

const ScopeIndicator = ({ levels, fullNames, hideTillDepth, workingScopes, }) => {
	// No scope selected yet (only happens with multiple companies)
	if(!workingScopes || workingScopes.length === 0){
		return (
            <Box sx={{ fontStyle: 'italic' }}>
				No scope selected
			</Box>
        );
	}

	// Only show if certain depth is reached
	if(hideTillDepth && workingScopes.length <= hideTillDepth){
		return null;
	}

	// Determine the start
	let workingScopeStart = levels ? workingScopes.length - levels : 0;
	if(workingScopeStart < 0) workingScopeStart = 0;

	// Get the visible scope to show
	const visibleScopes = workingScopes.slice(workingScopeStart);

    return (
        <div>
			{visibleScopes.map((scope, i) => (
				<StyledDiv key={scope.id} className={classes.scopeItem}>
					{
						(fullNames || i === visibleScopes.length - 1)
							? scope.name
							: scope.abbreviation
					}
				</StyledDiv>
			))}
		</div>
    );
};

ScopeIndicator.propTypes = {
	levels: PropTypes.number,
	fullNames: PropTypes.bool,
	hideTillDepth: PropTypes.number
};

export default (withWorkingScope(ScopeIndicator))
