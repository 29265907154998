import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'
const PREFIX = 'ApplicationFormBlock';

const classes = {
    root: `${PREFIX}-root`,
    label: `${PREFIX}-label`,
    content: `${PREFIX}-content`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		position: 'relative',
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		border: `1px solid ${theme.palette.primary.light}`,
		borderRadius: theme.shape.borderRadius,
	},

    [`& .${classes.label}`]: {
		position: 'absolute',
		top: 0,
		left: theme.spacing(1),
		transform: 'translateY(-50%)',
		padding: theme.spacing(1),
		display: 'inline-block',
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.primary.main,
		fontSize: '1.1em',
	},

    [`& .${classes.content}`]: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(3),
	}
}));

const ApplicationFormBlock =  styled(({ className, label, children, ...props }) => (
	<Root className={classNames(classes.root, className)} {...props}>
		<Typography variant="h6" className={classes.label}>
			{label}
		</Typography>
		<div className={classes.content}>
			{children}
		</div>
	</Root>
))(({ theme }) => ({
	[`&.${classes.root}`]: {
		position: 'relative',
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		border: `1px solid ${theme.palette.primary.light}`,
		borderRadius: theme.shape.borderRadius,
	},

	[`& .${classes.label}`]: {
		position: 'absolute',
		top: 0,
		left: theme.spacing(1),
		transform: 'translateY(-50%)',
		padding: theme.spacing(1),
		display: 'inline-block',
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.primary.main,
		fontSize: '1.1em',
	},

	[`& .${classes.content}`]: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(3),
	}
}));

ApplicationFormBlock.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default ApplicationFormBlock
