import { TableCell } from '@mui/material';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllClientProperties } from '../../actions/clientActions';
import { getAllEmployeeProperties } from '../../actions/employeeActions';
import { getAllScopeProperties } from '../../actions/scopeDataActions';
import withFetchData from '../../modules/fetchData/withFetchData';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';
import PropertyEditorRow from './PropertyEditorRow';

const PropertyEditorForm = ({ load, data, context, loading, error }) => {
	const [addNew, setAddNew] = useState(false);
	const { t } = useTranslation();

	const defaultState = useMemo(() => ({
		key: '',
		type: 'boolean',
		options: {},
	}), [t]);

	useEffect(() => {
		setAddNew(false);
	}, [data]);

	useEffect(() => {
		let getAction;
		switch (context) {
			case 'EmployeeListFilters':
				getAction = getAllEmployeeProperties;
				break;
			case 'ScopeFilter':
				getAction = getAllScopeProperties;
				break;
			case 'ClientFilter':
				getAction = getAllClientProperties;
				break;
			default:
		}
		load(getAction());
	}, [context, load]);

	if (error) return (
		<ApiError error={error} />
	);

	if (loading) return (
		<Loader />
	);

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Key</TableCell>
					<TableCell>Type</TableCell>
					<TableCell>Label</TableCell>
					<TableCell>Option keys</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{Array.isArray(data) && data.map(property => (
					<PropertyEditorRow initialState={property} context={context} key={property.key} />
				))}
				{addNew ? (
					<PropertyEditorRow initialState={defaultState} context={context} newProperty deleteNewProperty={() => setAddNew(false)} />
				) : (
					<TableRow>
						<TableCell colSpan={10}>
							<Button startIcon={<Icon>add</Icon>} onClick={() => setAddNew(!addNew)}>
								Add property
							</Button>
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

PropertyEditorForm.propTypes = {
	context: PropTypes.string.isRequired,
};

export default withFetchData(null, {
	customId: () => 'PropertyEditor',
})(PropertyEditorForm);
