import { getApiTypeSuccess } from '../helpers/types';
import { fetchDataAppend, fetchDataDelete } from '../modules/fetchData/fetchDataActions';
import { notificationShow } from '../modules/notification/notificationActions';
import { CALL_API } from '../setup/api';
import { Schemas } from './schemas';

const FUNCTIONS = 'FUNCTIONS';
const FUNCTION = 'FUNCTION';
const CONTRACT_TEMPLATES = 'CONTRACT_TEMPLATES';
const CONTRACT_TEMPLATE = 'CONTRACT_TEMPLATE';

export const CompanyFunctions = 'CompanyFunctions';
export const CompanyFunction = 'CompanyFunction';

export const getFunctions = scopeId => ({
	[CALL_API]: {
		type: FUNCTIONS,
		endpoint: `company/${scopeId}/functions`,
	},
});

export const createFunction = (companyId, data) => (dispatch) => {
	if (!companyId || !data) return false;

	return dispatch({
		[CALL_API]: {
			type: FUNCTION,
			method: 'POST',
			endpoint: `company/${companyId}/functions`,
			body: data,
		},
	}).then(res => {
		if (res.type === getApiTypeSuccess(FUNCTION)) {
			const { result } = res.response;
			dispatch(fetchDataAppend(CompanyFunctions, result));
		}
		return res;
	});
};

export const getFunction = functionId => ({
	[CALL_API]: {
		type: FUNCTION,
		endpoint: `function/${functionId}`,
		schema: Schemas.COMPANYFUNCTION,
	},
});

export const putFunction = (functionId, data) => {
	if (!functionId || !data) return false;

	return {
		[CALL_API]: {
			type: FUNCTION,
			endpoint: `function/${functionId}`,
			method: 'PUT',
			body: data,
			schema: Schemas.COMPANYFUNCTION,
		},
	};
};

export const getFunctionCapabilities = functionId => ({
	[CALL_API]: {
		type: FUNCTION,
		endpoint: `function/${functionId}/capabilities`,
		schema: Schemas.FUNCTIONCAPABILITIES(functionId),
	},
});

export const putFunctionCapabilities = (functionId, data) => {
	if (!functionId || !data) return false;

	return {
		[CALL_API]: {
			type: FUNCTION,
			method: 'PUT',
			endpoint: `function/${functionId}/capabilities`,
			body: data,
			schema: Schemas.FUNCTIONCAPABILITIES(functionId),
		},
	};
};

export const getContractTemplates = (functionId) => ({
	[CALL_API]: {
		type: CONTRACT_TEMPLATES,
		endpoint: `function/${functionId}/contract-templates`,
		schema: [Schemas.CONTRACT_TEMPLATE],
	},
});

export const getActiveContractTemplates = (functionId) => ({
	[CALL_API]: {
		type: CONTRACT_TEMPLATES,
		endpoint: `function/${functionId}/contract-templates/active`,
		schema: [Schemas.CONTRACT_TEMPLATE],
	},
});

export const postContractTemplate = (functionId, data) => dispatch => {
	if (!functionId || !data) return false;

	return dispatch({
		[CALL_API]: {
			type: CONTRACT_TEMPLATE,
			method: 'POST',
			endpoint: `function/${functionId}/contract-template`,
			body: data,
			schema: Schemas.CONTRACT_TEMPLATE,
			settings: {
				multipart: true,
			},
		},
	}).then(res => {
		if (res.type === getApiTypeSuccess(CONTRACT_TEMPLATE)) {
			dispatch(fetchDataAppend('ContractTemplates', res.response.result));
		}
		return res;
	});
};

export const putContractTemplate = (templateId, data) => {
	if (!templateId || !data) return false;

	return {
		[CALL_API]: {
			type: CONTRACT_TEMPLATE,
			method: 'PUT',
			endpoint: `contract-template/${templateId}`,
			body: data,
			schema: Schemas.CONTRACT_TEMPLATE,
			settings: {
				multipart: true,
			},
		},
	};
};

export const deleteContractTemplate = (templateId) => dispatch => dispatch({
	[CALL_API]: {
		type: CONTRACT_TEMPLATE,
		method: 'DELETE',
		endpoint: `contract-template/${templateId}`,
		schema: Schemas.CONTRACT_TEMPLATE,
	},
}).then((res) => {
	if (res.type === getApiTypeSuccess(CONTRACT_TEMPLATE)) {
		dispatch(fetchDataDelete('ContractTemplates', templateId));
		dispatch(notificationShow('Deleted contract template', 'info'));
	}
});
