import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import LiveSelectPage from './components/live/LiveSelectPage';
import ErrorBoundary from './components/util/ErrorBoundary';
import GtmProvider from './components/util/GtmProvider';
import NotFoundPage from './components/util/NotFoundPage';
import LoginPage from './components/login/LoginPage';
import LogoutPage from './components/login/LogoutPage';
import LostPasswordPage from './components/login/LostPasswordPage';
import ResetPasswordPage from './components/login/ResetPasswordPage';
import store from './store';
import LivePage from './components/live/LivePage';
import PortalPage from './PortalPage';
import MuiProvider from './MuiProvider';
import ApplicationPage from './components/applications/signForm/ApplicationPage';

// Main app and routes
function App() {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <MuiProvider>
                        <Routes>
                            <Route path="/" index element={<LoginPage />} />
                            <Route path="login" element={<LoginPage />} />
                            <Route path="logout" element={<LogoutPage />} />

                            <Route path="lostpassword" element={<LostPasswordPage />} />
                            <Route path="wachtwoordvergeten/:token?" element={<ResetPasswordPage />} />

                            <Route path="portal/*" element={<PortalPage />} />

                            <Route path="apply" element={<ApplicationPage />} />

                            <Route path="live/select" element={<LiveSelectPage />} />
                            <Route path="live/:rootScopeId" element={<LivePage />} />
                            <Route path="live" element={<LivePage />} />

                            <Route element={<NotFoundPage />} />
                        </Routes>
                    </MuiProvider>
                    <GtmProvider />
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
