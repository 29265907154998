import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next';
import withSubScopes from '../../../modules/scope/withSubScopes'
import { sortScopesOnName } from '../../../helpers/scope'
import ScopeSelect from './ScopeSelect'

const ScopeSelectorWorking = ({ scope, position, selected, subScopes, loading, onSelect, onClear }) => {
	const { t } = useTranslation();
	const options = subScopes && sortScopesOnName(subScopes);

	const handleChange = (value, { action }) => {
		switch(action){
			case 'select-option':
				onSelect(value.id, position);
				break;
			case 'pop-value':
			case 'clear':
				onClear(position);
				break;
		}
	}

	const disabled = Boolean(scope._virtual && options && options.length === 1 && selected);

	return (
		<Grid item xl={2} md xs={12}>
			<ScopeSelect options={options}
						 onChange={handleChange}
						 value={selected}
						 placeholder={t('scope.viewingAll')}
						 autoFocus
						 isDisabled={disabled}
						 isLoading={loading} />
		</Grid>
	)
}

ScopeSelectorWorking.propTypes = {
	scope: PropTypes.object.isRequired,
	position: PropTypes.number.isRequired,
	onSelect: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
	selected: PropTypes.object,
}

export default withSubScopes(ScopeSelectorWorking, (props) => `scopeSelector-${props.position}`)
