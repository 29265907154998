import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setPaymentStatus } from '../../actions/payment'
import withRootCapabilities from '../../modules/scope/withRootCapabilities'
import { notificationShow } from '../../modules/notification/notificationActions'
import { paymentStatus } from '../../helpers/payment'
import { capabilities } from '../../helpers/capabilities'
import EmployeePaymentStatusManipulationIcon from './EmployeePaymentStatusManipulationIcon'

const PREFIX = 'EmployeePaymentStatusManipulation';

const classes = {
    batchCardRightCentering: `${PREFIX}-batchCardRightCentering`,
    iconApproved: `${PREFIX}-iconApproved`,
    iconCancelled: `${PREFIX}-iconCancelled`,
    iconPaid: `${PREFIX}-iconPaid`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.batchCardRightCentering}`]: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},

    [`& .${classes.iconApproved}`]: {
		color: theme.palette.success.main + ' !important',
	},

    [`& .${classes.iconCancelled}`]: {
		color: theme.palette.error.main + ' !important'
	},

    [`& .${classes.iconPaid}`]: {
		color: theme.palette.primary.main + ' !important'
	}
}));

class EmployeePaymentStatusManipulation extends Component {

	static propTypes = {
		payment: PropTypes.object.isRequired,
	};

	approvePayment = (payment) => {
		const { setPaymentStatus, notificationShow } = this.props

		setPaymentStatus(payment.id, paymentStatus.APPROVED).then(() => {
			notificationShow(`Approved payment ${payment.employee.fullName ? ` of ${payment.employee.fullName}` : ''}`)
		})
	}

	setPaymentToPaid = (payment) => {
		const { setPaymentStatus, notificationShow } = this.props

		setPaymentStatus(payment.id, paymentStatus.PAID).then(() => {
			notificationShow(`Set payment ${payment.employee.fullName ? ` of ${payment.employee.fullName}` : ''} as paid`)
		})
	}

	_isDisabled = () => {
		return !this.props.payment.statusSettable;
	}

	_isPositive = () => {
		const status = this.props.payment && this.props.payment.status;
		return status === paymentStatus.APPROVED || status === paymentStatus.PAID || status === paymentStatus.IN_TRANSFER
	}

	render() {
		const { payment,  hasCapability } = this.props
		if (!hasCapability(capabilities.AuthorizeEmployeePaymentCapability)) return null
		return (
            <Root className={classes.batchCardRightCentering}>
				<div>
					<EmployeePaymentStatusManipulationIcon
						type="accept"
						className={this._isPositive() ? classes.iconApproved : ''}
						tooltip={payment.status === paymentStatus.APPROVED ? 'Approved' : 'Approve payment'}
						disabled={this._isDisabled()}
						onClick={() => this.approvePayment(payment)}
					>
						check
					</EmployeePaymentStatusManipulationIcon>
					<EmployeePaymentStatusManipulationIcon
						type="reject"
						className={payment.status === paymentStatus.CANCELLED || payment.status === paymentStatus.DISAPPROVED ? classes.iconCancelled : ''}
						tooltip={payment.status === paymentStatus.CANCELLED ? 'Cancelled out' :
							payment.status === paymentStatus.DISAPPROVED ? 'Disapproved' : 'Disapprove payment'}
						disabled={this._isDisabled()}
						to={location.pathname}
						state={{
							modal: 'reject-payment',
							payment,
						}}
					>
						clear
					</EmployeePaymentStatusManipulationIcon>
					{hasCapability(capabilities.AdministratorOverride) && (
						<EmployeePaymentStatusManipulationIcon
							type="mark-paid"
							className={payment.status !== paymentStatus.PAID ? classes.iconPaid : ''}
							tooltip={'Mark as paid'}
							disabled={this._isDisabled()}
							onClick={() => this.setPaymentToPaid(payment)}
						>
							arrow_forward
						</EmployeePaymentStatusManipulationIcon>
					)}
				</div>
			</Root>
        );
	}
}

const WithRootCapabilities = withRootCapabilities(EmployeePaymentStatusManipulation)
const WithReduxConnection = connect(null, { setPaymentStatus, notificationShow })(WithRootCapabilities)
export default (WithReduxConnection)
