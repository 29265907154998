import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import { canHaveEvaluations } from '../../../helpers/groupState'
import withScopeAndSubScopes from '../../../modules/scope/withScopeAndSubScopes'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { CALL_API } from '../../../setup/api'

const ApiAction = (courseId) => ({
	[CALL_API]: {
		type: 'COURSE_TEACHERS',
		endpoint: `athenastudies-course/${courseId}/teachers`,
	},
});

const styleControl = {
	minWidth: 160,
	marginRight: 24,
};

const CourseEvaluationDropDowns = ({ course, groupView, teacherView, handleDropDownChange, subScopes, loading, error, data }) => (
	<Fragment>

		<FormControl style={styleControl}>
			<InputLabel htmlFor="group-select">Group</InputLabel>
			<Select
				value={groupView}
				onChange={handleDropDownChange}
				inputProps={{
					name: 'groupView',
					id: 'group-select',
				}}
			>
				<MenuItem value={'all'}>
					<em>All</em>
				</MenuItem>
				{subScopes.map(subScope => (
					<MenuItem
						key={subScope.id}
						value={subScope.id}
						disabled={!canHaveEvaluations(subScope.state)}
					>
						{subScope.name} {!canHaveEvaluations(subScope.state) && `(${subScope.state.toLowerCase()})`}
					</MenuItem>
				))}
			</Select>
		</FormControl>

		<FormControl style={styleControl} disabled={loading} error={Boolean(error)}>
			<InputLabel htmlFor="teacher-select">Teacher</InputLabel>
			<Select
				value={teacherView}
				onChange={handleDropDownChange}
				inputProps={{
					name: 'teacherView',
					id: 'teacher-select',
				}}
			>
				<MenuItem value="all">
					<em>All</em>
				</MenuItem>
				{data && data.map(teacher => (
					<MenuItem key={teacher.id} value={teacher.id}>
						{teacher.fullName}
					</MenuItem>
				))}
			</Select>
			{error && (
				<FormHelperText>{error}</FormHelperText>
			)}
		</FormControl>

	</Fragment>
)

CourseEvaluationDropDowns.propTypes = {
	course: PropTypes.object.isRequired,
	groupView: PropTypes.any,
	teacherView: PropTypes.any,
	handleDropDownChange: PropTypes.func.isRequired,
};

export default withScopeAndSubScopes(
	withFetchData((props) => ApiAction(props.course.id), {
		customId: ({ course }) => `CourseEvaluationDropDowns-${course.id}`,
	})(CourseEvaluationDropDowns),
	(props) => props.course.id
)
