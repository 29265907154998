import React, {Component, Fragment} from 'react'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import {CALL_API} from "../../setup/api";
import { formatMoneyAuto } from "../../helpers/formatter";
import withFetchData from "../../modules/fetchData/withFetchData";
import {Link} from 'react-router-dom';
import Loader from "../util/loader/Loader";
import Alert from "../util/alert/Alert";

const rowsOptions = [5, 10, 25, 50];

class EmployeeLedgerSummary extends Component {

	state = {
		payoutSummary: [],
		rowsPerPage: 5,
		page : 0
	};

	constructor(props) {
		super(props)
	}

	render() {
		const { loading, error, data } = this.props;
		const payoutSummary = data || [];

		if (loading) {
			return <Loader/>
		}

		if (error) return (
			<Alert>{error.errorCode === 403 ?
				'You do not have access to the financial data, missing SeeEmployeeFinance permission.':
				'Error! Sorry, we could not load the data.'
			}</Alert>
		);

		return (
			<Fragment>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell size="small">Month</TableCell>
							<TableCell size="small" align="right">Paid amount</TableCell>
							<TableCell size="small" align="right">Provision</TableCell>
							<TableCell padding="none" align="right"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{ payoutSummary && payoutSummary.length ?
							payoutSummary
								.slice(this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage)
								.map((summaryItem, i) => (
								<TableRow key={`${summaryItem.computedOver}-${i}`}>
									<TableCell size="small">
										{summaryItem.computedOver}
										</TableCell>
									<TableCell size="small" align="right">
										{summaryItem.paidAmount == null ? '-' : formatMoneyAuto(summaryItem.paidAmount)}
									</TableCell>
									<TableCell size="small" align="right" style={{ fontStyle: 'italic'}}>
										{summaryItem.computedAmount == null ? '-' : formatMoneyAuto(summaryItem.computedAmount)}
									</TableCell>
									<TableCell padding="checkbox" align="right">
										<IconButton component={Link} to={`/portal/employees/${this.props.params.employeeId}/finance/${summaryItem.computedOver}`}>
											<Icon>keyboard_arrow_right</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							))
							:
							<TableRow>
								<TableCell>No results</TableCell>
							</TableRow>
						}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={4}
								count={payoutSummary.length}
								rowsPerPage={this.state.rowsPerPage}
								rowsPerPageOptions={rowsOptions}
								page={this.state.page}
								onPageChange={(event, page) => {
									if (this.state.page !== page) {
										this.setState({ page: page })
									}
								}}
								onRowsPerPageChange={(event) => this.setState({ rowsPerPage: event.target.value })}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</Fragment>
		)
	}

}

const getPayoutLedgerSummary = (employeeId) => ({
	[CALL_API] : {
		type: 'LEDGER_SUMMARY',
		endpoint: `employee/${employeeId}/payout-summary`
	}
});

export default withFetchData((props) => {
	return getPayoutLedgerSummary(props.params.employeeId)
})(EmployeeLedgerSummary)
