import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment'
import Icon from '@mui/material/Icon'
import TextField from '@mui/material/TextField'

const propTypes = {
	onChange: PropTypes.func.isRequired,
}

const debounce = onChange => {
	let timer
	return function () {
		clearTimeout(timer)
		timer = setTimeout(() => onChange.apply(this, arguments), 250)
	}
}

class ClientListSearchField extends Component {

	constructor(props) {
		super(props)

		this.debounce = debounce(props.onChange)
	}

	handleChange = e => {
		this.debounce(e.target.value)
	}

	render() {
		return (
			<TextField
				fullWidth
				autoFocus
				placeholder={'Search'}
				onChange={this.handleChange}
				InputProps={{
					startAdornment: (
						<InputAdornment position={'start'}>
							<Icon color={'disabled'}>{'search'}</Icon>
						</InputAdornment>
					),
				}}
			/>
		)
	}
}

ClientListSearchField.propTypes = propTypes

export default ClientListSearchField
