import { Schemas, CALL_API } from './schemas'
import { GROUP_STATE_CANCELLED } from '../helpers/groupState'

export const COURSE_GROUP = 'COURSE_GROUP';

export const evaluateGroup = (data) => {
	if(!data.id || !data.evaluation) return null;

	return {
		[CALL_API]: {
			type: COURSE_GROUP,
			endpoint: `athenastudies-course-group/${data.id}/evaluation`,
			schema: Schemas.SCOPE,
			body: {
				evaluation: data.evaluation
			}
		}
	}
};

export const cancelGroup = (data) => {
	if(!data.id) return null;

	return {
		[CALL_API]: {
			type: COURSE_GROUP,
			endpoint: `athenastudies-course-group/${data.id}`,
			schema: Schemas.SCOPE,
			method: 'PATCH',
			body: {
				state: GROUP_STATE_CANCELLED
			}
		}
	}
};

export const getGroupOrders = (groupId) => ({
	[CALL_API]: {
		type: COURSE_GROUP,
		endpoint: `athenastudies-course-group/${groupId}/orders`
	}
});

export const searchCourseGroups = (searchTerm, excludedStates = ['CANCELLED', 'CLOSED']) => {
	if(!excludedStates) excludedStates = [];

	return ({
		[CALL_API]: {
			type: COURSE_GROUP,
			endpoint: `athenastudies-course-group/
			?searchTerm=${searchTerm}
			&${excludedStates.map(state => `excludedStates=${state}`).join('&')}`
		}
	});
}

export const getGroupMail = (scopeId) => ({
	[CALL_API]: {
		type: COURSE_GROUP,
		endpoint: `scope/${scopeId}/group-email`
	}
});

export const sendGroupMail = (groupId, data) => {
	if(!groupId || !data) return;

	return {
		[CALL_API]: {
			type: COURSE_GROUP,
			endpoint: `scope/${groupId}/group-email`,
			body: data
		}
	}
};

export const getScopeTeachers = (scopeId) => ({
	[CALL_API]: {
		type: COURSE_GROUP,
		endpoint: `scope/${scopeId}/teachers`,
		pagination: {
			page: 0,
			pageSize: 200,
		},
	}
});
