import { useMediaQuery, useTheme } from '@mui/material'
import hoistNonReactStatics from 'hoist-non-react-statics'
import React from 'react'
import getDisplayName from 'react-display-name'

// TODO: this is a replacement for the deprecated `withMobileDialog` from material-ui
//  most component that used this cannot hooks as they are no functional components yet
//  when they are rewritten, they should stop making use of this HOC

const withDialogShouldBeFullscreen = () => (ComposedComponent) => {
	const Enhanced = (props) => {
		const theme = useTheme();
		const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

		return (
			<ComposedComponent fullScreen={fullScreen} {...props} />
		);
	};

	Enhanced.displayName = `withDialogShouldBeFullscreen(${getDisplayName(ComposedComponent) || 'Unknown'})`;

	return hoistNonReactStatics(Enhanced, ComposedComponent);
}

export default withDialogShouldBeFullscreen
