import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme, darken, alpha, lighten } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, deDE, enUS, nlNL } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import globalStyling from './style/globalStyling';

const athenaPrimaryPalette = {
	light: '#8A358E',
	main: '#5E2163',
	dark: '#471c4c',
	contrastText: '#fff',
};

// Re-use mui v4 secondary palette
const athenaSecondaryPalette = {
	light: '#e33371',
	main: '#dc004e',
	dark: '#9a0036',
	contrastText: '#fff',
};

const defaultTheme = createTheme();

export const themeSource = {
	palette: {
		primary: athenaPrimaryPalette,
		secondary: athenaSecondaryPalette,
		warning: { main: '#ECAC24' },
		error: { main: '#dc3545' }, // New naming by material-ui
		success: { main: '#4DC685' },
		info: { main: '#4aa9cc' },
		link: { main: '#418FA5' }, // Not used by material-ui
		default: { main: defaultTheme.palette.grey[300] },
	},
	typography: {
		fontFamily: '"Open Sans", sans-serif',
		htmlFontSize: 14,
		fontSize: 13,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 600,
		fontWeightBold: 700,
		button: {
			textTransform: 'none',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: globalStyling,
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontSize: '1rem',
				},
				body: {
					fontSize: '1rem',
				},
				sizeSmall: {
					padding: defaultTheme.spacing(1.5, 2, 1.5, 1.5),
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: defaultTheme.spacing(2),
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
				},
			},
			variants: [
				{
					props: {
						variant: 'contained',
						color: 'default',
					},
					style: {
						color: defaultTheme.palette.getContrastText(defaultTheme.palette.grey[300]),
						backgroundColor: defaultTheme.palette.grey[300],
						'&.Mui-disabled': {
							color: defaultTheme.palette.grey[600],
						},
					},
				},
				{
					props: {
						variant: 'outlined',
						color: 'default',
					},
					style: {
						color: defaultTheme.palette.text.primary,
						borderColor: defaultTheme.palette.grey[400],
						'&.Mui-disabled': {
							border: `1px solid ${defaultTheme.palette.action.disabledBackground}`,
						},
						'&:hover': {
							borderColor: defaultTheme.palette.grey[400],
							backgroundColor: alpha(defaultTheme.palette.text.primary, defaultTheme.palette.action.hoverOpacity),
						},
					},
				},
				{
					props: {
						variant: 'contained',
						color: 'primary',
					},
					style: {
						color: defaultTheme.palette.common.white,
					},
				},
			],
		},
		MuiListItem: {
			styleOverrides: {
				dense: {
					paddingTop: defaultTheme.spacing(0.75),
					paddingBottom: defaultTheme.spacing(0.75),
				},
			},
		},
		MuiIcon: {
			styleOverrides: {
				root: {
					fontSize: 24,
				},
			},
		},
	},
	drawerWidth: 240,
	borderStyle: `1px solid ${defaultTheme.palette.mode === 'light' ? lighten(alpha(defaultTheme.palette.divider, 1), 0.88) : darken(alpha(defaultTheme.palette.divider, 1), 0.68)}`,
	athenaPrimaryPalette,
};

const locales = {
	en: enUS,
	nl: nlNL,
	de: deDE,
};

function MuiProvider({ children }) {
	const { i18n } = useTranslation();
	const themeWithLocale = useMemo(() => createTheme(themeSource, locales[i18n.language]), [i18n.language]);

	return (
		<ThemeProvider theme={themeWithLocale}>
			<StyledEngineProvider injectFirst>
				<CssBaseline />
				<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
					{children}
				</LocalizationProvider>
			</StyledEngineProvider>
		</ThemeProvider>
	);
}

MuiProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default MuiProvider;
