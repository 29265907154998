/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useDidUpdateEffect } from '../../helpers/hooks';
import withWorkingScope from '../../modules/scope/withWorkingScope';

export const EnvRunTagManager = process.env.NODE_ENV === 'production';

const gtmId = 'GTM-M34BB7H';

const userSelector = ({ auth: { user } }) => user;

const GtmProvider = ({ topLevelScope }) => {
	if (typeof window === 'undefined' || !EnvRunTagManager) return null;

	const user = useSelector(userSelector);

	useEffect(() => {
		TagManager.initialize({
			gtmId,
			dataLayer: {
				userId: user?.id,
				company: topLevelScope ? `${process.env.NODE_ENV === 'production' ? '' : 'DEV-'}${topLevelScope.abbreviation}` : undefined,
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDidUpdateEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'userChange',
				userId: user?.id,
			},
		});
	}, [user]);

	useDidUpdateEffect(() => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'companyChange',
				company: topLevelScope ? `${process.env.NODE_ENV === 'production' ? '' : 'DEV-'}${topLevelScope.abbreviation}` : undefined,
			},
		});
	}, [topLevelScope]);

	return null;
};

export default withWorkingScope(GtmProvider);
