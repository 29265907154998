export function listsFromState(state, listName, entityMapping = null){
	const list = state.lists[listName];

	return entityMapping && list
		? list.map(id => state.entities[entityMapping][id])
		: list;
}

export function listsActionComposer(actionObj, listName){
	return {
		...actionObj,
		forList: listName,
	};
}
