import { concat, forEachObjIndexed, uniq, equals, omit } from 'ramda'
import { getApiTypeSuccess } from '../../helpers/types'
import { capabilities } from '../../helpers/capabilities'
import { SCOPE_ADD } from '../../actions/scopeEditActions'
import { ROOT, SCOPE_GROUP, SCOPE } from './scopeActions'

export const scopeMiddleware = store => next => action => {
	if(action.type === getApiTypeSuccess(ROOT) && action.response.entities){
		const result = action.response.result;
		const scopes = action.response.entities.scopes;

		result.map(id => {
			// Mark the root scopes as _root - if scope has the SeeScopeCapability
			if(scopes[id].capabilities && scopes[id].capabilities.indexOf(capabilities.SeeScopeCapability) > -1){
				scopes[id]._root = true;
			}
		});

		result.map(id => {
			// All other possible loaded scopes (the parents)
			// ... are virtual unless one of the parents is a root scope
			if(!gotRootAsParent(id, scopes)){
				markParentsAsVirtual(id, scopes);
			}

			// Merge capabilities down
			mergeCapabilitiesDownThroughParents(id, scopes);
		});
	}

	// Listen to scope add action and add as subScopes, also merge capabilities
	if(action.type === getApiTypeSuccess(SCOPE_ADD) && action.response.entities){
		const result = action.response.result;
		const currentScopes = store.getState().entities.scopes;
		const newScopes = action.response.entities.scopes;

		const parentId = newScopes[result].parent;
		const currentParent = currentScopes[parentId];

		// Parent exists and is not virtual (which means it is a root or lower)
		if(currentParent && !currentParent._virtual){
			const newParent = newScopes[parentId];

			newParent.subScopes = (currentParent.subScopes || []).concat(result);
		}

		// Also merge some capabilities
		const root = findRootParentOrSelf(result, {...newScopes, ...currentScopes});
		if(root && root.capabilities){
			mergeCapabilitiesDownThroughSubScopes(result, root.capabilities, newScopes, currentScopes);
		}
	}

	// Listen to scope group changes to set that groups were called
	if(action.type === getApiTypeSuccess(SCOPE_GROUP) && action.response.entities){
		const id = action.response.result;
		const scopes = action.response.entities.scopes;

		markScopeAndSubScopesAsGroupFetched(id, scopes);
		setParentIdForAllScopes(scopes);
	}

	// Listen to scope actions and merge capabilities down
	if((action.type === getApiTypeSuccess(SCOPE) || action.type === getApiTypeSuccess(SCOPE_GROUP)) && action.response.entities){
		const result = action.response.result;
		const currentScopes = store.getState().entities.scopes;
		const newScopes = action.response.entities.scopes;

		setParentIdInSubScopes(result, newScopes);

		const root = findRootParentOrSelf(result, {...newScopes, ...currentScopes});
		if(root && root.capabilities){
			mergeCapabilitiesDownThroughSubScopes(result, root.capabilities, newScopes, currentScopes);
		}
	}

	// Listen to all scope updates, deep equal check to stop store change pollution
	if(action.response && action.response.entities && action.response.entities.scopes){
		const currentScopes = store.getState().entities.scopes;
		const newScopes = action.response.entities.scopes;

		// Check all newScopes for deep changes on high level classes (they tend to not change)
		// Remove from the income response data if equal with current store data
		const checkClasses = ['Company', 'Institute', 'Faculty', 'Course'];
		forEachObjIndexed((scope, key) => {
			if(!scope || checkClasses.indexOf(scope._class) === -1) return false;

			// Find current scope, if not present it should not be removed of course
			const current = currentScopes[key];
			if(!current) return false;

			// Now compare, if equal, this key should be removed
			const ignore = ['_root', '_groups', '_virtual', 'subScopes', 'capabilities', 'functions'];
			if(equals(omit(ignore, scope), omit(ignore, current))){
				// Custom check some properties which are possibly manipulated by this middleware
				// These properties only appear in certain responses or middleware changes
				// ... so only compare if they are actually present e.g. one way check only
				if(ignore.filter(prop => (
					scope[prop] && !equals(scope[prop], current[prop])
				)).length > 0){
					return false;
				}

				// Removal will happen on the object, so not immutable
				// ... this is no problem because it is in the middleware and before store change in the reducer
				delete newScopes[key];
			}
		}, newScopes);
	}

	return next(action);
};


// ====================
// Internal helpers
// ====================

function gotRootAsParent(id, scopes){
	const parent = scopes[id].parent;

	if(!parent || !scopes[parent]) return false;
	if(scopes[parent]._root) return true;

	return gotRootAsParent(parent, scopes);
}

function findRootParentOrSelf(id, scopes){
	const current = scopes[id];
	if(!current) return false;

	// Current is a root, return it
	if(current._root) return current;

	// Check the parent
	return findRootParentOrSelf(current.parent, scopes);
}

function markParentsAsVirtual(id, scopes){
	const parent = scopes[id].parent;
	if(!parent || !scopes[parent]) return;

	scopes[parent]._virtual = true;
	markParentsAsVirtual(parent, scopes);
}

function markScopeAndSubScopesAsGroupFetched(id, scopes){
	const scope = scopes[id];
	if(!scope) return;

	scope._groups = true;
	(scope.subScopes || []).forEach(id => {
		markScopeAndSubScopesAsGroupFetched(id, scopes)
	});
}

function mergeCapabilitiesDownThroughParents(id, scopes){
	forEachObjIndexed((scope) => {
		// We are looking for children of the given id
		if(scope.parent !== id) return;

		// Merge the capabilities down
		scope.capabilities = uniq(concat(scope.capabilities || [], scopes[id].capabilities || []));

		// Go for the next depth
		mergeCapabilitiesDownThroughParents(scope.id, scopes);
	}, scopes);
}

function mergeCapabilitiesDownThroughSubScopes(id, capabilities, newScopes, currentScopes){
	const current = newScopes[id];
	if(!current) return;

	// Take capabilities from existing scopes into account
	const currentCapabilities = currentScopes[id]?.capabilities || [];

	// Set the capabilities of the current scope
	current.capabilities = uniq([].concat(current.capabilities || [], capabilities || [], currentCapabilities));

	// Do the same for all subScopes
	if(current.subScopes){
		current.subScopes.forEach(id => {
			mergeCapabilitiesDownThroughSubScopes(id, current.capabilities, newScopes, currentScopes);
		});
	}
}

function setParentIdInSubScopes(id, scopes){
	const scope = scopes[id];
	if(!scope) return;

	(scope.subScopes || []).forEach(subId => {
		scopes[subId].parent = id;
	});
}

function setParentIdForAllScopes(scopes){
	forEachObjIndexed((scope) => {
		if(!scope.subScopes) return;

		scope.subScopes.forEach(subScopeId => {
			if(!scopes[subScopeId]) return;

			scopes[subScopeId].parent = scope.id;
		});
	}, scopes);
}
