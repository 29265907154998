import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import withFormData from '../../modules/formData/withFormData';
import { CALL_API } from '../../setup/api';
import { Schemas } from '../../actions/schemas';
import Loader from '../util/loader/Loader';
import ApiError from '../util/ApiError';
import Alert from '../util/alert/Alert';
import ScopePickerCourseList from '../scope/picker/ScopePickerCourseList';

const ApiActionAssociate = (bundleId, courseId) => ({
	[CALL_API]: {
		type: 'BUNDLE_ASSOCIATE',
		endpoint: `athenastudies-course-bundle/${bundleId}/athenastudies-course/${courseId}`,
		schema: Schemas.COURSE_BUNDLE,
		method: 'POST',
	},
});

const courseFilter = (courses) => (course) => (
	courses.filter(c => c.id === course.id).length === 0
);

const BundleAddCourseDialog = ({ onClose, study, bundle, watchSubmit, saving, error, success }) => {
	useEffect(() => {
		if (success) onClose();
	}, [onClose, success]);

	const handleSelectCourse = (course) => {
		watchSubmit(ApiActionAssociate(bundle.id, course.id));
	};

	return (
		<Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth scroll="body">
			{saving && (
				<Loader sheet />
			)}
			<DialogTitle>
				Add a course to this bundle
			</DialogTitle>
			<DialogContent>
				{error && (
					<ApiError error={error} />
				)}
				{success ? (
					<Alert type={Alert.TYPE_SUCCESS}>
						Course was successfully added to the bundle.
					</Alert>
				) : (
					<ScopePickerCourseList
						study={study}
						onSelectCourse={handleSelectCourse}
						filterCourse={courseFilter(bundle.applicableInCourses)}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					variant="outlined"
					color="secondary"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

BundleAddCourseDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	study: PropTypes.object.isRequired,
	bundle: PropTypes.object.isRequired,
};

export default withFormData()(BundleAddCourseDialog);
