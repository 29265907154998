import { TableCell } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CompanyFunctions, getFunctions } from '../../../../actions/functionsActions';
import { formatMoneyAuto } from '../../../../helpers/formatter';
import withFetchData from '../../../../modules/fetchData/withFetchData';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import ModalItem from '../../../modal/ModalItem';
import ModalManager from '../../../modal/ModalManager';
import PageContentBlock from '../../../page/PageContentBlock';
import PageHeader from '../../../page/PageHeader';
import PageHeaderMenu from '../../../page/PageHeaderMenu';
import PageHeaderTitle from '../../../page/PageHeaderTitle';
import Alert from '../../../util/alert/Alert';
import ApiError from '../../../util/ApiError';
import Loader from '../../../util/loader/Loader';
import EditFunctionModal from './EditFunctionModal';

function CompanyFunctionsPage({ topLevelScope, loading, data: functions, error, refresh }) {
	const { t } = useTranslation();

	useEffect(() => {
		if (topLevelScope) {
			refresh();
		}
	}, [refresh, topLevelScope]);

	if (!topLevelScope) {
		return (
			<Alert>
				First select a scope in the scope selector at the top of the page.
			</Alert>
		);
	}

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return <ApiError error={error} />;
	}

	return (
		<>
			<ModalManager>
				<ModalItem state="functionEdit" component={EditFunctionModal} />
			</ModalManager>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderMenu items={[{
							name: t('manage.company.functions.newFunction'),
							to: '/portal/company/functions',
							state: {
								modal: 'functionEdit',
								id: null,
							},
						}]}
						/>

						<PageHeaderTitle
							headline={t('manage.company.functions.editFunctions')}
							subHeading={(
								<Typography paragraph>
									<Trans i18nKey="manage.company.functions.editFunctionsDesc" values={{ scopeName: topLevelScope.name }}>
										Functions for
										{' '}
										{topLevelScope.name}
									</Trans>
								</Typography>
							)}
						/>
					</PageHeader>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageContentBlock>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>{t('manage.company.functions.names')}</TableCell>
									<TableCell>{t('manage.company.functions.reimbursementType')}</TableCell>
									<TableCell>{t('manage.company.functions.turnoverPercentage')}</TableCell>
									<TableCell>{t('manage.company.functions.bonusTurnoverPercentage')}</TableCell>
									<TableCell>{t('manage.company.functions.hourlyRate')}</TableCell>
									<TableCell>{t('manage.company.functions.bonusRate')}</TableCell>
									<TableCell>{t('manage.company.functions.profitPercentage')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{functions?.length === 0 && (
									<TableRow>
										<TableCell colSpan={100}>
											{t('manage.company.functions.noFunctions')}
										</TableCell>
									</TableRow>
								)}
								{functions?.map(functionEl => (
									<TableRow key={functionEl.id}>
										<TableCell>
											<Link to={`/portal/company/function/${functionEl.id}`}>
												{functionEl.name}
											</Link>
										</TableCell>
										<TableCell>
											<i>{functionEl.reimbursementType}</i>
										</TableCell>
										<TableCell>
											{functionEl.turnoverPercentage && `${functionEl.turnoverPercentage}%`}
										</TableCell>
										<TableCell>
											{functionEl.bonusTurnoverPercentage && `${functionEl.bonusTurnoverPercentage}%`}
										</TableCell>
										<TableCell>
											{functionEl.baseSalary && formatMoneyAuto(functionEl.baseSalary)}
										</TableCell>
										<TableCell>
											{functionEl.bonusSalary && formatMoneyAuto(functionEl.bonusSalary)}
										</TableCell>
										<TableCell>
											{functionEl.profitPercentage && `${functionEl.profitPercentage}%`}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</PageContentBlock>
				</Grid>
			</Grid>
		</>
	);
}

export default withWorkingScope(withFetchData(props => props.topLevelScope ? getFunctions(props.topLevelScope.id) : undefined, {
	customId: () => CompanyFunctions,
})(CompanyFunctionsPage));
