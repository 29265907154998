import withFormData from '../modules/formData/withFormData'
import withValidation from './withValidation'
import withSimpleState from './withSimpleState'

const withCompleteBasicForm = (ComposedComponent, fields) => {
	return withFormData({
		customId: () => ComposedComponent.displayName || ComposedComponent.name,
	})(
		withValidation(
			withSimpleState(
				ComposedComponent
			), fields
		)
	)
};

// Re-export Joi for easy use
withCompleteBasicForm.Joi = withValidation.Joi;
export const Joi = withValidation.Joi;

export default withCompleteBasicForm
