import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { getNumberFormatSeparators } from '../../helpers/formatter';

const AmountInputField = React.forwardRef((props, ref) => {
	const { onChange, ...other } = props;
	const { i18n } = useTranslation();
	const numberSeparators = useMemo(() => getNumberFormatSeparators(i18n.language), [i18n.language]);

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={({ floatValue }) => {
				onChange({
					target: {
						name: props.name,
						value: floatValue,
					},
				});
			}}
			decimalScale={2}
			allowNegative={false}
			isNumericString
			{...numberSeparators}
		/>
	);
});

AmountInputField.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default AmountInputField;
