import { CALL_API } from './schemas';

export const ME = 'ME';

export const getContracts = () => ({
	[CALL_API]: {
		type: ME,
		endpoint: 'me/contracts',
	},
});

export const getTeachingCourseGroups = () => ({
	[CALL_API]: {
		type: ME,
		endpoint: 'me/teaching-course-groups',
	},
});

export const getUserToken = (uri) => ({
	[CALL_API]: {
		type: ME,
		endpoint: 'me/token',
		method: 'POST',
		body: {
			uri,
		},
	},
});
