import React, { PureComponent } from 'react'
import { createSelector } from 'reselect'
import getDisplayName from 'react-display-name'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { getSubScopes } from './scopeActions'
import withFetchData from '../fetchData/withFetchData'

const getScope = (getScopeId) => (state, props) =>
		state.entities.scopes[getScopeId(props)];

const getScopeEntities = (state) => state.entities.scopes;

const makeGetSubScopes = (getScopeId) => {
	return createSelector(
		[getScope(getScopeId), getScopeEntities],
		(scope, entities) => {
			return scope && scope.subScopes && scope.subScopes.map(
				id => entities[id]
			);
		}
	);
}

// WARNING
// This is build for quite specific use cases
// Pay extra attention if using, especially when rendering multiple instances
const withScopeAndSubScopes = (WrappedComponent, getScopeId = (props) => props.id) => {
	const Enhanced = withFetchData(null, {
		customId: () => WrappedComponent.displayName || WrappedComponent.name || 'withScopeAndSubScopesNoName',
		makeMapStateToProps: (state, props) => {
			const getSubScopes = makeGetSubScopes(getScopeId);
			return (state, props) => {
				const id = getScopeId(props);
				if(!id) return {};

				const scope = state.entities.scopes[id];
				if(!scope || scope._virtual) return {};

				return {
					scope: scope,
					subScopes: getSubScopes(state, props),
					parentScope: scope.parent && state.entities.scopes[scope.parent],
				};
			}
		},
	})(class ScopeAndSubScopes extends PureComponent {
		static displayName = `withScopeAndSubScopes(${getDisplayName(WrappedComponent) || 'Unknown'})`;

		static propTypes = {};

		render(){
			const { scope, subScopes, parentScope } = this.props;

			return (
				<WrappedComponent {...this.props}
								  scope={scope}
								  subScopes={subScopes}
								  parentScope={parentScope} />
			);
		}

		componentDidMount(){
			const { scope, load } = this.props;

			if(!scope || !scope.subScopes){
				const id = getScopeId(this.props);
				if(id) load(getSubScopes(id));
			}
		}

		componentDidUpdate(prevProps){
			const { scope, load } = this.props;
			const id = getScopeId(this.props);

			if((!scope || !scope.subScopes) && id !== getScopeId(prevProps)){
				if(id) load(getSubScopes(id));
			}
		}
	});

	return hoistNonReactStatics(Enhanced, WrappedComponent);
}

export default withScopeAndSubScopes
