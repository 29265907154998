import { capitalize } from '@mui/material/utils'
import { Chip, chipClasses, styled } from '@mui/material'

export const parseStatus = status => capitalize(status.toLowerCase().replace(/_/g, ' '))

/**
 * This is the order in which Client Orders are shown when sorted on Status
 * Corporates statusOrd and {@exported} sortByStatus
 * @type {string[]}
 */
const orderStatusList = [
	'NEW',
	'PRE_PAYMENT',
	'PENDING',
	'VERIFICATION',
	'EXPIRED',
	'CANCELED',
	'REFUNDED',
	'FULL_DISCOUNT',
	'FREE',
	'PAID',
]

const statusOrd = status => orderStatusList.indexOf(status)
export const sortByStatus = key => (a, b) => statusOrd(a[key]) - statusOrd(b[key])

export const
	PENDING = 'statusPending',
	FAILED = 'statusFailed',
	SUCCESS = 'statusSuccess',
	REFUNDED = 'statusRefunded'

const orderStatus = {
	NEW: 				PENDING,
	PRE_PAYMENT: 		PENDING,
	PENDING: 			PENDING,
	VERIFICATION: 		PENDING,
	EXPIRED: 			FAILED,
	CANCELED: 			FAILED,
	REFUNDED: 			REFUNDED,
	FULL_DISCOUNT: 		SUCCESS,
	FREE: 				SUCCESS,
	PAID: 				SUCCESS,
}

export const transactionStatusStyle = {
	UNKNOWN: 			orderStatus.CANCELED,
	CANCEL: 			orderStatus.CANCELED,
	PARTIAL_REFUND: 	orderStatus.PAID,
	REFUND: 			orderStatus.REFUNDED,
	EXPIRED: 			orderStatus.EXPIRED,
	CHARGEBACK_1: 		orderStatus.CANCELED,
	CHARGEBACK_2: 		orderStatus.CANCELED,
	MANUALLY_DECLINED: 	orderStatus.CANCELED,
	DENIED: 			orderStatus.CANCELED,
	CANCEL_2: 			orderStatus.CANCELED,
	PAID_CHECKAMOUNT: 	orderStatus.CANCELED,
	WAIT: 				orderStatus.PRE_PAYMENT,
	PENDING_1: 			orderStatus.PENDING,
	PENDING_2: 			orderStatus.PENDING,
	PENDING_3: 			orderStatus.PENDING,
	OPEN: 				orderStatus.PENDING,
	CONFIRMED_1: 		orderStatus.PENDING,
	CONFIRMED_2: 		orderStatus.PENDING,
	PARTIAL_PAYMENT: 	orderStatus.PAID,
	VERIFY: 			orderStatus.VERIFICATION,
	PENDING_4: 			orderStatus.VERIFICATION,
	AUTHORIZE: 			orderStatus.VERIFICATION,
	PENDING_5: 			orderStatus.VERIFICATION,
	PAID: 				orderStatus.PAID,
}

export const orderStatusStyle = orderStatus

export const StatusChip = styled(Chip)(({ theme }) => ({
	[`&.${chipClasses.root}`]: {
		borderRadius: theme.spacing(1),
		[`&.${PENDING}`]: {
			backgroundColor: theme.palette.warning.main,
		},
		[`&.${FAILED}`]: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.primary.contrastText,
		},
		[`&.${REFUNDED}`]: {
			backgroundColor: theme.palette.link.main,
			color: theme.palette.primary.contrastText,
		},
		[`&.${SUCCESS}`]: {
			backgroundColor: theme.palette.success.main,
			color: theme.palette.primary.contrastText,
		},
	},
}));
