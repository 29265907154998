import { inputLabelClasses, textFieldClasses } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { mapObjIndexed, omit, pick, keys, pickBy } from 'ramda';
import moment from 'moment';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import withValidation, { Joi } from '../../../hoc/withValidation';
import withFormData from '../../../modules/formData/withFormData';
import { CALL_API } from '../../../setup/api';
import Alert from '../../util/alert/Alert';
import ApiError from '../../util/ApiError';
import CountryField from '../../util/CountryField';
import Loader from '../../util/loader/Loader';
import PhoneField from '../../util/PhoneField';
import ApplicationFormBlock from './ApplicationFormBlock';

const ApiAction = (hash, data) => ({
	[CALL_API]: {
		type: 'APPLY',
		endpoint: `apply/continue?hash=${hash}`,
		body: data,
	},
});

const CustomField =  styled(({ name, data, bindState, validation, validationBlur, validationFields, helperText, ...props }) => (
	<TextField
		fullWidth
		required={validationFields[name]._flags.presence === 'required' || validationFields[name]?._baseType?._flags?.presence === 'required'}
		name={name}
		value={data[name] || ''}
		onBlur={validationBlur}
		onChange={bindState(name)}
		error={Boolean(validation[name])}
		helperText={validation[name] || helperText}
		{...props}
	/>
))(({ theme }) => ({
	[`&.${textFieldClasses.root}`]: {
		marginBottom: theme.spacing(1),
	},

	[`& .${inputLabelClasses.root}`]: {
		fontWeight: 700,
	}
}));

const minDate = new Date();
const maxDate = new Date();

minDate.setFullYear(minDate.getFullYear() - 70);
minDate.setHours(0, 0, 0, 0);
maxDate.setFullYear(maxDate.getFullYear() - 15);
maxDate.setHours(0, 0, 0, 0);

const momentMinDate = moment(minDate);
const momentMaxDate = moment(maxDate);

const BirthdayDateFormat = (dateTime) => (
	moment(dateTime).format('YYYY-MM-DD')
);

const getInitialData = (application, validationFields) => ({
	...mapObjIndexed(
		field => field._flags.default || '',
		validationFields,
	),
	...pickBy((val) => val !== null, application),
});

function getScopeTopLocale(scope) {
	if (!scope) return null;

	return scope.parent
		? getScopeTopLocale(scope.parent)
		: scope.locale;
}

const ApplicationFormInfo = (
	{
		application, onSuccess, validation, validationBlur, validationField, validationFields, validationAll,
		saving, error, success, handleFormError, watchSubmit,
	},
) => {
	const { t } = useTranslation();
	const [data, setData] = useState(getInitialData(application, validationFields));

	// When the form was successfully saved
	useEffect(() => {
		if (success) onSuccess(success);
	}, [success, onSuccess]);

	const locale = getScopeTopLocale(application.applyingIn);

	const bindState = (field) => (e) => {
		const val = e.target.value;
		setData(newData => ({
			...newData,
			[field]: val,
		}));
	};

	const bindStateDirect = (field) => (val) => {
		setData(newData => ({
			...newData,
			[field]: val,
		}));
	};

	const changeBirthday = (date) => {
		setData(newData => ({
			...newData,
			birthday: date.format(),
		}));

		validationField('birthday', date.format());
	};

	const submitForm = (e) => {
		e.preventDefault();
		window.scrollTo(0, 0);

		validationAll(data, (localError) => {
			if (localError) {
				handleFormError(t('application.signForm.error.validation'));
				return;
			}

			const values = omit(['passwordRepeat'], pick(keys(validation), data));

			values.birthday = BirthdayDateFormat(values.birthday);
			values.IBAN = values.IBAN.replace(/[ \-_]/g, '');

			watchSubmit(ApiAction(application.hash, values));
		});
	};

	const fieldProps = { data, bindState, validation, validationBlur, validationFields };

	return (
        (<>
            {application.hiredEmployee && (
				<Alert type={Alert.TYPE_INFO} icon="info_outlined">
					{t('application.signForm.helperText.hiredEmployee')}
				</Alert>
			)}
            {saving && (
				<Loader sheet />
			)}
            <ApiError error={error} />
            <ApplicationFormBlock label={t('application.signForm.block.general')}>
				<Grid container spacing={2}>
					<Grid item md={2} sm={4} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="initials"
                            label={t('employee.fields.initials')}
                        />
					</Grid>
					<Grid item md={4} sm={8} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="name"
                            label={t('employee.fields.name')}
                            autoComplete="given-name"
                        />
					</Grid>
					<Grid item md={2} sm={4} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="middleName"
                            label={t('employee.fields.middleName')}
                            autoComplete="additional-name"
                        />
					</Grid>
					<Grid item md={4} sm={8} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="surname"
                            label={t('employee.fields.surname')}
                            autoComplete="family-name"
                        />
					</Grid>
				</Grid>
			</ApplicationFormBlock>
            <ApplicationFormBlock label={t('application.signForm.block.contactInformation')}>
				<Grid container spacing={2}>
					<Grid item md={4} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="email"
                            label={t('employee.fields.email')}
                            disabled
                            helperText={t('application.signForm.helperText.email')}
                        />
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<PhoneField
							name="mobilePhone"
							label={t('employee.fields.mobilePhone')}
							value={data.mobilePhone}
							onChange={bindState('mobilePhone')}
							error={!!validation.mobilePhone}
							helperText={validation.mobilePhone}
							onBlur={validationBlur}
							fullWidth
							required
							autoComplete="tel"
						/>
					</Grid>
					<Grid item md={4} sm={12} xs={12}>
						<MobileDatePicker
							openTo="year"
							value={data.birthday}
							inputFormat="DD MMM YYYY"
							disableMaskedInput
							minDate={momentMinDate}
							maxDate={momentMaxDate}
							onChange={changeBirthday}
							closeOnSelect
							componentsProps={{
								actionBar: {
									actions: ['cancel'],
								},
							}}
							showToolbar={false}
							renderInput={(props) => (
								<TextField
									{...props}
									error={Boolean(validation.birthday)}
									helperText={validation.birthday || t('application.signForm.helperText.birthday')}
									label={t('employee.fields.birthday')}
									fullWidth
									required
								/>
							)}
						/>
					</Grid>
				</Grid>
			</ApplicationFormBlock>
            <ApplicationFormBlock label={t('application.signForm.block.address')}>
				<Grid container spacing={2}>
					<Grid item md={6} sm={8} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="street"
                            label={t('employee.fields.street')}
                            autoComplete="street-address"
                        />
					</Grid>
					<Grid item md={3} sm={4} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="houseNumber"
                            label={t('employee.fields.houseNumber')}
                        />
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="zipcode"
                            label={t('employee.fields.zipcode')}
                            autoComplete="postal-code"
                        />
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="city"
                            label={t('employee.fields.city')}
                            autoComplete="address-level2"
                        />
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<CountryField
							fullWidth
							required
							name="country"
							label={t('employee.fields.country')}
							value={data.country}
							onChange={bindStateDirect('country')}
							error={Boolean(validation.country)}
							helperText={validation.country}
							onBlur={validationBlur}
							suggestions={locale ? [locale.slice(3)] : undefined}
						/>
					</Grid>
				</Grid>
			</ApplicationFormBlock>
            <ApplicationFormBlock label={t('application.signForm.block.personalIdentification')}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="body2" paragraph>
							<em>{t('application.signForm.helperText.personalIdentification')}</em>
						</Typography>
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="identificationNumber"
                            label={t('employee.fields.identificationNumber')}
                            onBlur={(e) => validationField('identificationNumber', {
								identificationNumber: e.target.value,
								vatId: data.vatId,
							})}
                        />
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="vatId"
                            label={t('employee.fields.vatId')}
                            helperText={t('application.signForm.helperText.vatId')}
                        />
					</Grid>
				</Grid>
			</ApplicationFormBlock>
            <ApplicationFormBlock label={t('application.signForm.block.bankDetails')}>
				<Grid container spacing={2}>
					<Grid item md={4} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="IBAN"
                            label={t('employee.fields.IBAN')}
                        />
					</Grid>
					<Grid item md={4} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="BIC"
                            label={t('employee.fields.BIC')}
                        />
					</Grid>
					<Grid item md={4} sm={12} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="bankPayee"
                            label={t('employee.fields.bankPayee')}
                        />
					</Grid>
				</Grid>
			</ApplicationFormBlock>
            <ApplicationFormBlock label={t('application.signForm.block.password')}>
				{application.hiredEmployee && (
					<Alert type={Alert.TYPE_WARNING}>
						{t('application.signForm.warning.hiredEmployeePassword')}
					</Alert>
				)}

				<Grid container spacing={2}>
					<Grid item md={6} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="password"
                            label={t('employee.fields.password')}
                            type="password"
                        />
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<CustomField
                            {...fieldProps}
                            name="passwordRepeat"
                            label={t('employee.fields.passwordRepeat')}
                            type="password"
                            onBlur={(e) => validationField('passwordRepeat', {
								password: data.password,
								passwordRepeat: e.target.value,
							})}
                        />
					</Grid>
				</Grid>
			</ApplicationFormBlock>
            <Button
				variant="contained"
				color="primary"
				fullWidth
				onClick={submitForm}
				disabled={saving}
			>
				{saving ? t('application.signForm.saving') : t('application.signForm.saveAndVerify')}
			</Button>
        </>)
    );
};

ApplicationFormInfo.propTypes = {
	application: PropTypes.object.isRequired,
	onSuccess: PropTypes.func.isRequired,
};

export default withFormData()(withValidation(ApplicationFormInfo, {
	initials: Joi.string().required().label('Initials'),
	name: Joi.string().required().label('First name'),
	middleName: Joi.string().empty('').allow(null).label('Middle name'),
	surname: Joi.string().required().label('Surname'),

	email: Joi.string().email().required().label('E-mail'),
	mobilePhone: Joi.string().required().label('Mobile phone'),
	birthday: Joi.date().required().label('Date of birth'),

	street: Joi.string().required().label('Street'),
	houseNumber: Joi.string().regex(/^[0-9]+.*/).required().label('House number')
		.options({
			language: {
				string: {
					regex: {
						base: 'should start with a number',
					},
				},
			},
		}),
	city: Joi.string().required().label('City'),

	zipcode: Joi.string().required().label('ZIP code'),
	country: Joi.string().required().label('Country'),

	identificationNumber: Joi.string().required().label('Citizen service number').default('')
		.when('vatId', {
			is: Joi.string().valid('', null), then: Joi.string().required(), otherwise: Joi.string().allow(''),
		}),
	vatId: Joi.string().label('BTW nummer').allow(''),

	IBAN: Joi.string().required().label('IBAN'),
	BIC: Joi.string().required().label('BIC'),
	bankPayee: Joi.string().required().label('In the name of'),

	password: Joi.string().required().min(6).max(100)
		.label('Password'),
	passwordRepeat: Joi.string().valid(Joi.ref('password')).required().label('Verify password')
		.options({ language: { any: { allowOnly: 'is not the same as your password' } } }),
}));
