import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { styled } from '@mui/material/styles';
import { ButtonGroup, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import {
    $getSelection,
    $isRangeSelection,
    $isTextNode,
    FORMAT_TEXT_COMMAND,
    REDO_COMMAND,
    UNDO_COMMAND,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
} from 'lexical';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { $isListNode, ListNode } from '@lexical/list';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { $isHeadingNode } from '@lexical/rich-text';
import { mergeRegister, $getNearestNodeOfType, $getNearestBlockElementAncestorOrThrow } from '@lexical/utils';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $selectAll } from '@lexical/selection';
import { useTranslation } from 'react-i18next';
import BlockOptionsDropdownList from '../components/BlockOptionsDropdownList';
import FloatingLinkEditor from '../components/FloatingLinkEditor';
import getSelectedNode from '../helpers/selection';

const PREFIX = 'ToolbarPlugin';

const classes = {
    toolbar: `${PREFIX}-toolbar`,
    btn: `${PREFIX}-btn`,
    activeBtn: `${PREFIX}-activeBtn`,
    divider: `${PREFIX}-divider`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.toolbar}`]: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        '& .MuiButtonGroup-root': {
            marginBottom: theme.spacing(1),
        },
        '& button': {
            border: theme.borderStyle,
        },
    },

    [`& .${classes.btn}`]: {
        border: theme.borderStyle,
        '&:hover, &:focus': {
            zIndex: 1,
        },
    },

    [`& .${classes.activeBtn}`]: {
        background: theme.palette.grey[200],
    },

    [`& .${classes.divider}`]: {
        margin: `0 ${theme.spacing(1)} ${theme.spacing(1)}`,
    },
}));

const blockTypeToBlockName = {
    h1: 'Large Heading',
    h2: 'Small Heading',
    h3: 'Heading',
    h4: 'Heading',
    h5: 'Heading',
    ol: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
    ul: 'Bulleted List',
};

function ToolbarPlugin({ allowedBlockTypes, children }) {
    const [editor] = useLexicalComposerContext();
    const { t } = useTranslation();
    const toolbarRef = useRef(null);
    const anchorEl = useRef(null);
    const [canUndo, setCanUndo] = React.useState(false);
    const [canRedo, setCanRedo] = React.useState(false);
    const [showBlockOptions, setShowBlockOptions] = React.useState(false);
    const [blockType, setBlockType] = React.useState('paragraph');
    const [isBold, setIsBold] = React.useState(false);
    const [isItalic, setIsItalic] = React.useState(false);
    const [isStrikethrough, setIsStrikethrough] = React.useState(false);
    const [isUnderline, setIsUnderline] = React.useState(false);
    const [isLink, setIsLink] = React.useState(false);

    const handleBlockTypeToggle = () => {
        setShowBlockOptions(!showBlockOptions);
    };

    const updateToolbar = React.useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =	anchorNode.getKey() === 'root'
                ? anchorNode
                : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                }
            }
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setIsUnderline(selection.hasFormat('underline'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    const clearFormatting = useCallback(() => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $selectAll(selection);
                selection.getNodes().forEach((node) => {
                    if ($isTextNode(node)) {
                        node.setFormat(0);
                        node.setStyle('');
                        $getNearestBlockElementAncestorOrThrow(node).setFormat('');
                    }
                    if ($isDecoratorBlockNode(node)) {
                        node.setFormat('');
                    }
                });
            }
        });
    }, [editor]);

    React.useEffect(() => mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                updateToolbar();
            });
        }),
        editor.registerCommand(
            CAN_UNDO_COMMAND,
            (payload) => {
                setCanUndo(payload);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        ),
        editor.registerCommand(
            CAN_REDO_COMMAND,
            (payload) => {
                setCanRedo(payload);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        ),
    ), [updateToolbar, editor]);

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    return (
        <Root className={classes.toolbar}>
            <ButtonGroup color="default">
                <Button
                    onClick={() => {
                        editor.dispatchCommand(UNDO_COMMAND, 'undo');
                    }}
                    className={classes.btn}
                    aria-label={t('editor.undo')}
                    disabled={!canUndo}
                    size="small"
                >
                    <Icon>rotate_left</Icon>
                </Button>
                <Button
                    onClick={() => {
                        editor.dispatchCommand(REDO_COMMAND, 'redo');
                    }}
                    className={classes.btn}
                    aria-label={t('editor.redo')}
                    disabled={!canRedo}
                    size="small"
                >
                    <Icon>rotate_right</Icon>
                </Button>
            </ButtonGroup>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <ButtonGroup ref={toolbarRef} color="default">
                <Button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                    }}
                    className={`${classes.btn} ${isBold && classes.activeBtn}`}
                    aria-label={t('editor.format.bold')}
                    size="small"
                >
                    <Icon>format_bold</Icon>
                </Button>
                <Button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                    }}
                    className={`${classes.btn} ${isItalic && classes.activeBtn}`}
                    aria-label={t('editor.format.italic')}
                    size="small"
                >
                    <Icon>format_italic</Icon>
                </Button>
                <Button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                    }}
                    className={`${classes.btn} ${isUnderline && classes.activeBtn}`}
                    aria-label={t('editor.format.underline')}
                    size="small"
                >
                    <Icon>format_underline</Icon>
                </Button>
                <Button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
                    }}
                    className={`${classes.btn} ${isStrikethrough && classes.activeBtn}`}
                    aria-label={t('editor.format.strikethrough')}
                    size="small"
                >
                    <Icon>format_strikethrough</Icon>
                </Button>
                <Button
                    onClick={clearFormatting}
                    className={classes.btn}
                    aria-label={t('editor.format.clearTextFormatting')}
                    size="small"
                >
                    <Icon>format_clear</Icon>
                </Button>
                <Button
                    onClick={insertLink}
                    className={`${classes.btn} ${isLink && classes.activeBtn}`}
                    aria-label={t('editor.insertLink')}
                    size="small"
                >
                    <Icon>insert_link</Icon>
                </Button>
            </ButtonGroup>
            {isLink && <FloatingLinkEditor editor={editor} anchorEl={anchorEl} />}
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <ButtonGroup style={{ position: 'relative' }} color="default">
                <Button
                    onClick={handleBlockTypeToggle}
                    ref={anchorEl}
                    aria-label={t('editor.format.options')}
                    className={classes.btn}
                    endIcon={<Icon>arrow_drop_down</Icon>}
                    size="medium"
                >
                    {blockTypeToBlockName[blockType] || 'Normal'}
                </Button>
            </ButtonGroup>
            {showBlockOptions && (
                <BlockOptionsDropdownList
                    editor={editor}
                    blockType={blockType}
                    toolbarRef={toolbarRef}
                    anchorEl={anchorEl}
                    setShowBlockOptions={setShowBlockOptions}
                    allowedBlockTypes={allowedBlockTypes}
                    blockTypeToBlockName={blockTypeToBlockName}
                />
            )}
            {!!children && (
                <Divider orientation="vertical" flexItem className={classes.divider} />
            )}
            {children}
        </Root>
    );
}

ToolbarPlugin.propTypes = {
    allowedBlockTypes: PropTypes.array,
    children: PropTypes.node,
};

export default ToolbarPlugin;
