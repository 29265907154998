import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {pick, keys, clone, mergeRight} from 'ramda'
import moment from 'moment'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import withValidation, { Joi } from '../../hoc/withValidation'
import { getISO } from '../../helpers/datetime'

class PartnerForm extends Component {
	static propTypes = {
		partner: PropTypes.object,
		disabled: PropTypes.bool,
		onValidationError: PropTypes.func,
		onSave: PropTypes.func,
		onCancelRequest: PropTypes.func
	}

	constructor(props){
		super(props);

		props.bindSubmit && props.bindSubmit(this.onSubmit);

		if(props.partner){
			var partner = clone(props.partner);

			if(partner.discountPercentage !== undefined){
				partner.discountPercentage *= 100;
			}else{
				partner.discountPercentage = 0;
			}

			partner.revenuePercentage *= 100;
			partner.allowedIps = partner.allowedIps || [];

			this.state = partner;
			return;
		}

		this.state = {
			name: '',
			slug: '',
			global: true,
			discountPercentage: 0,
			revenuePercentage: 0,
			expires: moment().add(1, 'y').toISOString(),
			allowedIps: [],
			visuals: {}
		};
	}

	render() {
		const { validation, validationBlur } = this.props;
		const expireDateObject = moment(this.state.expires, moment.ISO_8601).toDate();

		return (
			<form onSubmit={this.onSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormControl error={!!validation['name']} fullWidth={true}>
									<InputLabel>Partner name</InputLabel>
									<Input name="name"
										   value={this.state.name}
										   onChange={this.bindState('name')}
										   onBlur={validationBlur}/>
									<FormHelperText>
										{validation['name']}
									</FormHelperText>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl error={!!validation['slug']} fullWidth={true}>
									<InputLabel>Slug</InputLabel>
									<Input name="slug"
										   value={this.state.slug}
										   onChange={this.bindState('slug')}
										   onBlur={validationBlur}/>
									<FormHelperText>
										{validation['slug']}
									</FormHelperText>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<div>
									<FormControlLabel
										label="Global partner with own URL"
										margin={"normal"}
										control={
											<Switch checked={(this.state.global)} color={"primary"}
													onChange={this.bindStateSwitch('global', [true, false])} />
										}
									/>
									<FormHelperText style={{fontStyle: 'italic'}}>
										A global partner has a custom AthenaStudies sub-domain and no standard customer discount.
									</FormHelperText>
								</div>
							</Grid>

							<Grid item xs={12}>
								{this.state.global && (
									<FormControl error={!!validation['discountPercentage']} fullWidth={true}>
										<InputLabel>Discount percentage members</InputLabel>
										<Input name="discountPercentage"
											   value={this.state.discountPercentage}
											   onChange={this.bindState('discountPercentage')}
											   onBlur={validationBlur}/>
										<FormHelperText>
											{validation['discountPercentage']}
										</FormHelperText>
									</FormControl>
								)}
							</Grid>

							<Grid item xs={12}>
								<FormControl error={!!validation['revenuePercentage']} fullWidth={true}>
									<InputLabel>Revenue percentage partner</InputLabel>
									<Input name="revenuePercentage"
										   value={this.state.revenuePercentage}
										   onChange={this.bindState('revenuePercentage')}
										   onBlur={validationBlur}/>
									<FormHelperText>
										{validation['revenuePercentage']}
									</FormHelperText>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<MobileDatePicker
									value={expireDateObject}
									inputFormat="dd D MMMM YYYY"
									onChange={this.changeExpireDate}
									okLabel={'Choose'}
									cancelLabel={'Cancel'}
									mode="landscape"
									showToolbar={false}
									closeOnSelect
									componentsProps={{
										actionBar: {
											actions: ['cancel'],
										},
									}}
									renderInput={(props) => (
										<TextField
											{...props}
											label="Partner tot en met"
											fullWidth
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={6}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormControl error={!!validation['visuals']} fullWidth={true}>
									<InputLabel>Stylesheet URL</InputLabel>
									<Input name="visualsStyleUrl"
										   value={this.state.visuals.styleUrl || ''}
										   onChange={this.bindStateSub('visuals', 'styleUrl')}
										   onBlur={validationBlur}/>
									<FormHelperText>
										{validation['visuals']}
									</FormHelperText>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl error={!!validation['visuals']} fullWidth={true}>
									<InputLabel>Background URL</InputLabel>
									<Input name="visualsBackgroundUrl"
										   value={this.state.visuals.backgroundUrl || ''}
										   onChange={this.bindStateSub('visuals', 'backgroundUrl')}
										   onBlur={validationBlur}/>
									<FormHelperText>
										{validation['visuals']}
									</FormHelperText>
								</FormControl>
							</Grid>

							{this.state.global && (
								<>
									<Grid item xs={12}>
										<FormControl error={!!validation['visuals']} fullWidth={true}>
											<InputLabel>Billing details</InputLabel>
											<Input name="visualsInformation"
												   value={this.state.visuals.information || ''}
												   onChange={this.bindStateSub('visuals', 'information')}
												   onBlur={validationBlur}/>
											<FormHelperText>
												{validation['visuals']}
											</FormHelperText>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<FormControl error={!!validation['allowedIps']} fullWidth={true}>
											<InputLabel>Allowed IP addresses</InputLabel>
											<Input name="allowedIps"
												   minRows={3}
												   value={this.state.allowedIps.join('\n')}
												   onChange={this.bindStateTransform('allowedIps', (i) => i.split('\n'))}
												   onBlur={validationBlur}
												   multiline/>
											<FormHelperText>
												{validation['allowedIps']}
											</FormHelperText>
										</FormControl>
										<FormHelperText style={{fontStyle: 'italic'}}>
											Let a software developer fill in these details.
										</FormHelperText>
									</Grid>
								</>
							)}
						</Grid>



					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						{this.props.onCancelRequest && (
							<Button variant="contained" color="default" onClick={this.onCancel} fullWidth>
								{this.state.id ? 'Cancel editing' : 'Cancel'}
							</Button>
						)}
					</Grid>
					<Grid item xs={6}>
						{!this.state.id && (
							<Button variant="contained" onClick={this.onSubmit} fullWidth color="primary">
								{this.state.id ? 'Edit partner' : 'Save partner'}
							</Button>
						)}
					</Grid>
				</Grid>
			</form>
		);
	}

	onCancel = () => {
		if(this.props.onCancelRequest){
			this.props.onCancelRequest();
		}
	}

	changeExpireDate = (date) => {
		this.setState({
			expires: getISO(moment(date))
		});
	}

	onSubmit = (e) => {
		e.preventDefault();

		this.props.validationAll(this.state, (error, errors) => {
			if(error){
				if(this.props.onValidationError){
					this.props.onValidationError(errors);
				}
				return;
			}

			let data = pick(keys(this.props.validation), this.state);

			if(data.discountPercentage){
				data.discountPercentage /= 100;
			}

			data.revenuePercentage /= 100;
			data.slug = data.slug.toLowerCase();
			data.visuals = JSON.stringify(data.visuals);

			if(this.props.onSave){
				this.props.onSave(data);
			}
		});
	}

	bindState = (property) => {
		return (event) => {
			this.setState({
				[property]: event.target.value
			});
		}
	}

	bindStateSwitch = (property, values) => {
		return (event) => {
			this.setState({
				[property]: (event.target.checked ? values[0] : values[1])
			}, () => {
				this.props.validationAll(this.state);
			});
		};
	}

	bindStateSub = (property, subprop) => {
		return (event) => {
			this.setState({
				[property]: mergeRight(
					this.state[property], {
						[subprop]: event.target.value
					}
				)
			});
		}
	}

	bindStateTransform = (property, transform) => {
		return (event) => {
			this.setState({
				[property]: transform(event.target.value)
			});
		}
	}
}

export default withValidation(PartnerForm, {
	name: Joi.string().required(),
	slug: Joi.string().required(),
	global: Joi.boolean(),
	discountPercentage: Joi.number().integer().min(0).max(100).allow(null).required(),
	revenuePercentage: Joi.number().integer().min(0).max(100).required(),
	expires: Joi.string().required(),
	allowedIps: Joi.array(),
	visuals: Joi.object(),
})
