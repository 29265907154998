/* eslint-disable max-len, import/no-unresolved */
import { find, values } from 'ramda';
import infoCourseDescription from './infoCourseDescription.md?raw';
import infoCourseName from './infoCourseName.md?raw';
import infoCoursePrice from './infoCoursePrice.md?raw';

const info = {
    2: {
        id: 2,
        slug: 'course_description',
        title: 'Course description',
        data: infoCourseDescription,
    },
    3: {
        id: 3,
        slug: 'course_price',
        title: 'Uitleg prijs bepalen',
        data: infoCoursePrice,
    },
    4: {
        id: 4,
        slug: 'course_type',
        title: 'Uitleg cursus type',
        data: 'Let op: Zorg dat er nadruk gelegd wordt op de basiscursus, aangezien dit via mond-tot-mond reclame goede promotie biedt voor de stoomcursus. Het is aan te raden om de stoomcursus pas online te zetten/te promoten nadat het eerste cursusmoment van de basiscursus geweest is.\n\n##  Basiscursus\n\nDe basiscursus is bedoeld om de stof op een rustiger tempo te behandelen. De basiscursus is vaak 12 uur lang en kan verspreid worden over bijvoorbeeld 4 weken lang 3 uur les per week, tot in de week voor het tentamen 3 dagen achter elkaar 4 uur per dag. Een basiscursus kan ook 9 uur duren, maar duurt over het algemeen langer dan onderstaande cursussen. Totale cursustijd varieert tussen 9 en 15 uur. Een goede basiscursus zorgt voor goede promotie voor de stoomcursus.\n\n##  Stoomcursus\n\nDit is de verkorte versie van de basiscursus. Dit kun je bijvoorbeeld het weekend voor het tentamen doen. De tijdsindeling is normaliter 1 dag 8 uur, 2 dagen 4 uur of 3 dagen 3 uur. Tijd varieert tussen 6 en 9 uur. De stoomcursus kan inhoudelijk soortgelijk zijn aan de basiscursus, maar door een nuanceverschil in de promotie kun je de mensen uit de basiscursus ook de stoomcursus laten volgen door te melden “in de stoomcursus wordt nog extra geoefend en ligt de nadruk helemaal op het tentamen”.',
    },
    5: {
        id: 5,
        slug: 'group_email',
        title: 'Groep email',
        data: 'De mail wordt verstuurd naar alle al aangemelde cursisten. Deze zijn rechts in de bezettingslijst te vinden. De laatst verstuurde email wordt nagestuurd aan nieuwe bestellingen. Zij zullen dus ook alle informatie meekrijgen.\n\n##  Verstuur vanaf email\n\nVul in dit veld je eigen AthenaStudies email in, bijvoorbeeld die van de faculteit. Wanneer een cursist dan reageert op een groepmail, zal jij het bericht ontvangen. Zo kun je direct antwoord geven.',
    },
    6: {
        id: 6,
        slug: 'function_auteur',
        title: 'Bedrag schrijven boekje',
        data: 'Zorg dat bedragen vooraf vastgesteld zijn met jouw universiteitsmanager of contactpersoon op het hoofdkantoor.\n\n**€ 25** - bij een erg makkelijk/kort boekje  \n**€ 50** - bij een makkelijk/kort boekje  \n**€ 75** - bij een gemiddeld maar niet heel uitgebreid/moeilijk boekje  \n**€ 100** - bij een gemiddeld maar uitgebreid/moeilijk boekje  \n**€ 125** - bij een erg uitgebreid én moeilijk boekje  \n**€ 150** - bij een extreem uitgebreid én moeilijk boekje (uitzonderlijke gevallen)\n\nLet hierbij extra op:\n\n - Bruikbare informatie van andere steden en/of faculteiten\n - Bruikbare informatie via andere bronnen\n - Bewerkelijkheid van de theorie en/of oefenopgaven en de verhouding hiervan\n - Aantal studiepunten\n - Aantal studie-uren\n - Voorgeschreven literatuur\n - Toepassen van de bovengenoemde richtlijnen',
    },
    7: {
        id: 7,
        slug: 'course_name',
        title: 'Cursusnaam',
        data: infoCourseName,
    },
};

// eslint-disable-next-line default-param-last
function infoReducerFinal(state = {
    open: false,
    title: null,
    content: null,
}, action) {
    let item;
    switch (action.type) {
        case 'INFO_SHOW_ID':
            item = info[action.id];

            return {
                open: true,
                title: item ? item.title : null,
                content: item ? item.data : null,
            };
        case 'INFO_SHOW_SLUG':
            item = find(curr => curr.slug === action.slug)(values(info));

            return {
                open: true,
                title: item ? item.title : null,
                content: item ? item.data : null,
            };
        case 'INFO_SHOW':
            return {
                open: true,
                title: action.title,
                content: action.content,
            };
        case 'INFO_HIDE':
            return {
                ...state,
                open: false,
            };
        default:
            break;
    }

    return state;
}

const infoReducer = {
    info: infoReducerFinal,
};

export default infoReducer;
