import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Alert from '../util/alert/Alert'
import OrderOptionsSwap from './OrderOptionsSwap'
import OrderOptionsRefund from './OrderOptionsRefund'
import OrderOptionsApplyDiscount from './OrderOptionsApplyDiscount'
import { capabilities, hasScopeCapability } from '../../helpers/capabilities'

const PREFIX = 'OrderOptions';

const classes = {
    line: `${PREFIX}-line`,
    actionTitle: `${PREFIX}-actionTitle`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.line}`]: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.actionTitle}`]: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
	}
}));

const orderStatusesForOptions = ['PAID', 'FREE', 'FULL_DISCOUNT'];

const OrderOptions = ({ scope, groups, courseId, client, orderEntryId, orderStatus, }) => {
	// This check should never pass, capabilities should always be present by the store
	if(!scope.capabilities) return null;

	const canRefund = hasScopeCapability(scope, capabilities.RefundCapability);
	const canSwap = hasScopeCapability(scope, capabilities.ProductSwapCapability);

	if(!canRefund && !canSwap) return null;

	return (
        <Root>
			<Typography variant="h6" className={classes.actionTitle}>
				Actions with this order
			</Typography>
			{!orderStatusesForOptions.includes(orderStatus) ? (
				<Alert type={Alert.TYPE_INFO}>
					No actions are possible on this order because it has not yet been full completed or is already cancelled/refunded.
				</Alert>
			) : (
				<Grid container spacing={2}>
					{canSwap && (
						<Grid item xs={12}>
							<OrderOptionsSwap
								client={client}
								scope={scope}
								groups={groups}
							/>
							<Divider className={classes.line} />
						</Grid>
					)}
					{canRefund && (
						<Grid item xs={12}>
							<OrderOptionsRefund
								orderEntryId={orderEntryId}
							/>
							<Divider className={classes.line} />
						</Grid>
					)}
					{canRefund && (
						<Grid item xs={12}>
							<OrderOptionsApplyDiscount
								courseId={courseId}
								orderEntryId={orderEntryId}
								orderStatus={orderStatus}
							/>
							<Divider className={classes.line} />
						</Grid>
					)}
				</Grid>
			)}
		</Root>
    );
}

OrderOptions.propTypes = {
	scope: PropTypes.object.isRequired,
	groups: PropTypes.array,
	courseId: PropTypes.number.isRequired,
	client: PropTypes.object.isRequired,
	orderEntryId: PropTypes.number.isRequired,
	orderStatus: PropTypes.string.isRequired,
};

export default (OrderOptions)
