import { accordionClasses, accordionDetailsClasses, accordionSummaryClasses } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { niceDateTime } from '../../../helpers/datetime'
import { GROUP_STATE_CANCELLED, GROUP_STATE_IN_PROGRESS, isAfterInProgress } from '../../../helpers/groupState'
import { scopeParentsNameString } from '../../../helpers/scope'
import TeacherAgendaTimeSlotRow from './TeacherAgendaTimeSlotRow'

const PREFIX = 'TeacherAgendaCourseRow';

const classes = {
    root: `${PREFIX}-root`,
    expanded: `${PREFIX}-expanded`,
    rounded: `${PREFIX}-rounded`,
    root2: `${PREFIX}-root2`,
    content: `${PREFIX}-content`,
    root3: `${PREFIX}-root3`,
    nameTitle: `${PREFIX}-nameTitle`,
    upcoming: `${PREFIX}-upcoming`,
    finished: `${PREFIX}-finished`,
    fullWidth: `${PREFIX}-fullWidth`,
    capableLink: `${PREFIX}-capableLink`,
    evaluation: `${PREFIX}-evaluation`
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
	[`&.${accordionClasses.expanded}`]: {
		'&:first-of-type': {
			marginTop: theme.spacing(2),
		},
	},
	[`&.${accordionClasses.rounded}`]: {
		'&:first-of-type': {
			borderRadius: 0,
		},
	},
    [`& .${classes.nameTitle}`]: {
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.link.main,
	},

    [`& .${classes.upcoming}`]: {
		fontWeight: theme.typography.fontWeightBold,
	},

    [`& .${classes.finished}`]: {
		color: theme.palette.text.disabled,
	},

    [`& .${classes.fullWidth}`]: {
		width: '100%',
	},

    [`& .${classes.capableLink}`]: {
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline',
		},
	},

    [`& .${classes.evaluation}`]: {
		color: theme.palette.text.disabled,
		paddingLeft: '1em',
		fontWeight: theme.typography.fontWeightMedium,
	}
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) =>({
	[`&.${accordionSummaryClasses.root}`]: {
		paddingLeft: '2em',
		paddingRight: '2em',
	},
	[`& .${accordionSummaryClasses.content}`]: {
		margin: `${parseInt(theme.spacing(2), 10) - 2}px 0 ${theme.spacing(2)}`,
	},
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	[`&.${accordionDetailsClasses.root}`]: {
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: '1em',
		paddingRight: '1em',
		marginLeft: '2em',
		marginRight: '2em',
		borderBlockStart: theme.borderStyle,
	},
}));

const ParseEvaluation = ({ evaluation, state }) => {
	if (state === GROUP_STATE_CANCELLED) return 'Cancelled';
	if (!isAfterInProgress(state)) return '';
	if (evaluation) return `Evaluation: ${evaluation}`;

	return 'No evaluation yet';
}

const TeacherAgendaCourseRow = ({ group, hasLink, }) => {
	const { t, i18n } = useTranslation();
	const inProgress = group.state === GROUP_STATE_IN_PROGRESS;
	const afterInProgress = isAfterInProgress(group.state);

	return (
        <StyledAccordion>
			<StyledAccordionSummary expandIcon={<Icon>expand_more</Icon>}>
				<Grid container alignItems="center">
					<Grid item sm={8} xs={12}>
						<Typography variant="subtitle1" className={classes.nameTitle}>
							{hasLink ? (
								<Link
									className={classes.capableLink}
									to={`/portal/planner/course/${group.parent.id}/group/${group.id}`}
								>
									{group.parent.name} - {group.name}
								</Link>
							) : group.name}
							<span className={classes.evaluation}>
								<ParseEvaluation evaluation={group.evaluation} state={group.state} />
							</span>
						</Typography>
						<Typography>
							{scopeParentsNameString(group.parent.parent, 'Company')}
						</Typography>
					</Grid>
					<Grid item sm={4} xs={12}>
						<Typography className={afterInProgress ? classes.finished : classes.upcoming}>
							{inProgress ? t('dashboard.teacherAgenda.ongoing') : niceDateTime(group.courseStart, i18n.language, t)}
						</Typography>
					</Grid>
				</Grid>
			</StyledAccordionSummary>
			<StyledAccordionDetails>
				<Grid container direction="column">
					{group.timeslots.map((timeSlot) => (
						<TeacherAgendaTimeSlotRow
							key={timeSlot.id}
							timeSlot={timeSlot}
						/>
					))}
				</Grid>
			</StyledAccordionDetails>
		</StyledAccordion>
    );
}

TeacherAgendaCourseRow.propTypes = {
	group: PropTypes.object.isRequired,
	hasLink: PropTypes.bool,
};

export default (TeacherAgendaCourseRow)
