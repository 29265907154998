import { Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const EditFunctionSwitch = ({ field, fields, onChangeFieldDirect, disabled }) => (
	<Switch
		checked={fields[field] || false}
		onChange={() => onChangeFieldDirect(field, !fields[field])}
		name={field}
		disabled={disabled}
	/>
);

EditFunctionSwitch.propTypes = {
	field: PropTypes.string.isRequired,
	fields: PropTypes.object.isRequired,
	onChangeFieldDirect: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default EditFunctionSwitch;
