import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { findIndex, propEq, remove } from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LocationItemCard from './LocationItemCard';
import LocationSelectLocationCreate from './LocationSelectLocationCreate';

const PREFIX = 'LocationSelectLocation';

const classes = {
    header: `${PREFIX}-header`,
    search: `${PREFIX}-search`,
    icon: `${PREFIX}-icon`,
    topItem: `${PREFIX}-topItem`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.header}`]: {
		display: 'flex',
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.search}`]: {
		flex: 1,
	},

    [`& .${classes.icon}`]: {
		marginRight: theme.spacing(1),
	},

    [`& .${classes.topItem}`]: {
		...theme.typography.body2,
		marginBottom: theme.spacing(3),
		'& > div': {
			marginTop: theme.spacing(0.5),
		},
	}
}));

const Address = ({ location }) => (
	<>
		{`${location.street} ${location.houseNumber}${location.houseNumberExtension}`}
		<br />
		{`${location.zipcode}, ${location.city}`}
	</>
);

Address.propTypes = {
	location: PropTypes.object.isRequired,
};

const makeMeta = (location) => [...(location.rooms ? [{
	label: 'Rooms',
	value: location.rooms.length,
	shaded: true,
}] : [])];

const sortByZoom = (list) => {
	const zoomIndex = findIndex(propEq('name', 'Zoom'))(list);

	return zoomIndex === -1 ? list : [
		list[zoomIndex],
		...remove(zoomIndex, 1, list),
	];
};

const createIsLocationInFilter = (q) => (location) => {
	const lq = q.toLowerCase();

	return location.name.toLowerCase().indexOf(lq) > -1
		|| location.street.toLowerCase().indexOf(lq) > -1
		|| location.zipcode.toLowerCase().indexOf(lq) > -1;
};

function LocationSelectLocation({ scope, locations, selected, onSelect }) {

	const [q, setQ] = useState('');
	const [editing, setEditing] = useState(false);

	const isSelectedInList = Boolean(selected && locations.find(location => location.id === selected.id));

	const handleChangeSearch = useCallback((e) => {
		setQ(e.target.value);
	}, [setQ]);

	const handleClearSearch = useCallback(() => {
		setQ('');
	}, [setQ]);

	const handleSelect = useCallback((location) => {
		onSelect(location);
	}, [onSelect]);

	const handleCreate = useCallback(() => {
		setEditing(true);
	}, [setEditing]);

	const handleClose = useCallback(() => {
		setEditing(false);
	}, [setEditing]);

	const sortedLocations = useMemo(() => sortByZoom(locations), [locations]);

	const filteredLocations = useMemo(() => (
		(!q || q.length === 0) ? sortedLocations : sortedLocations.filter(createIsLocationInFilter(q))
	), [sortedLocations, q]);

	return (
        <Root>
			<Dialog open={editing} maxWidth="sm" fullWidth onClose={handleClose} scroll="body">
				<DialogTitle>Create new location</DialogTitle>
				<DialogContent>
					<LocationSelectLocationCreate
						scope={scope}
						onClose={handleClose}
						onSelect={onSelect}
					/>
				</DialogContent>
			</Dialog>
			<div className={classes.header}>
				<div className={classes.search}>
					<TextField
						variant="outlined"
						size="small"
						placeholder="Search location"
						value={q}
						onChange={handleChangeSearch}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleClearSearch} edge="end" disabled={q.length === 0}>
										<Icon>close</Icon>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
				<Button onClick={handleCreate}>
					<Icon className={classes.icon}>add</Icon>
					add new
				</Button>
			</div>
			<div>
				{Boolean(selected) && !isSelectedInList && (
					<div className={classes.topItem}>
						Currently selected item is not in this city
						<LocationItemCard
							title={selected.name}
							description={<Address location={selected} />}
							selected
							item={selected}
							meta={makeMeta(selected)}
						/>
					</div>
				)}
				{filteredLocations.map(location => (
					<LocationItemCard
						key={location.id}
						title={location.name}
						description={<Address location={location} />}
						selected={location.id === selected?.id}
						item={location}
						onClick={handleSelect}
						meta={makeMeta(location)}
					/>
				))}
			</div>
		</Root>
    );
}

LocationSelectLocation.propTypes = {
	scope: PropTypes.object.isRequired,
	locations: PropTypes.array.isRequired,
	selected: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
};

export default LocationSelectLocation;
