import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import PropertiesTable from '../util/PropertiesTable'
import Alert from '../util/alert/Alert'

const PREFIX = 'PageHeaderInfo';

const classes = {
    info: `${PREFIX}-info`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.info}`]: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	}
}));

const PageHeaderInfo = ({ items, info, warning, }) => {
	if(items.length === 0) return null;

	const middle = Math.ceil(items.length / 2);

	return (
        <Root className={classes.info}>
			{
				(warning || info) &&
				<Alert type={warning ? Alert.TYPE_WARNING : Alert.TYPE_INFO}>
					{warning || info}
				</Alert>
			}
			<Grid container spacing={2} sx={{ display: { xs: 'none', sm: 'flex' } }}>
				<Grid item xs={6}>
					<PropertiesTable items={items.slice(0, middle)}/>
				</Grid>
				<Grid item xs={6}>
					<PropertiesTable items={items.slice(middle)}/>
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ display: { xs: 'flex', sm: 'none' } }}>
				<Grid item xs={12}>
					<PropertiesTable items={items}/>
				</Grid>
			</Grid>
		</Root>
    );
}

PageHeaderInfo.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			content: PropTypes.node
		})
	).isRequired,
	info: PropTypes.any,
	warning: PropTypes.any,
};

export default (PageHeaderInfo)
