import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import Grid from '@mui/material/Grid'
import Alert from '../util/alert/Alert'
import CostAccountSelector from '../util/CostAccountSelector';
import Loader from '../util/loader/Loader'
import withFormData from '../../modules/formData/withFormData'
import { postCost } from '../../actions/costActions'
import ApiError from '../util/ApiError'

class CourseGroupCostsAddForm extends Component {
	constructor(props){
		super(props);

		this.state = {
			amount: '',
			description: '',
			accountId: -1,
		};
	}

	render(){
		const { saving, error, success } = this.props;
		const { accountId, amount, description } = this.state;

    	if(success) return (
    		<Alert type={Alert.TYPE_SUCCESS}>
		    	Course costs "{this.state.description}" have successfully been saved.
			</Alert>
		);

    	return (
    		<Grid container spacing={2}>
			    <Grid item xs={12}>
				    <p>Ensure that all costs are added under the right category.</p>
					{this.state.error ? (
						<Alert type={Alert.TYPE_WARNING}>
							{this.state.error ? this.state.error.error : error}
						</Alert>
					) : (error && (
						<ApiError error={error} />
					))}
				</Grid>

			    <Grid item md={4} sm={12}>
					<FormControl fullWidth>
						<InputLabel>Cost type/grootboek</InputLabel>
				    	<CostAccountSelector value={accountId} onChange={this.changeAccountId} />
					</FormControl>
				</Grid>
				<Grid item md={4} sm={12}>
				    <TextField name="amount"
				               label="Costs (in euro)"
				               value={amount}
				               onChange={this.changeAmount}
							   fullWidth />
				</Grid>
				<Grid item md={4} sm={12}>
				    <TextField name="description"
				               label="Description"
				               value={description}
				               onChange={this.changeDescription}
							   fullWidth />
				</Grid>
				<Grid item xs={12}>
				    <FormControl fullWidth>
						<InputLabel>Betaald door kantoor</InputLabel>
					</FormControl>
					<Input disabled />
					<FormHelperText id="component-error-text">
						Betaald door kantoor, neem contact op met je verantwoordelijke voor declaraties
					</FormHelperText>
				</Grid>
				{
					saving &&
					<Grid item xs={12}>
						<Loader />
					</Grid>
				}
			</Grid>
	    );
	}

	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSaved){
			this.props.onSaved();
		}
	}

	handleSubmit = () => {
		if(this.props.saving) return;

		if(!this.state.accountId || !this.state.amount || !this.state.description){
			this.setState({
				error: {
					error: 'Vul alle velden in.'
				}
			});
			return;
		}

		this.setState({
			error: false
		});

		this.props.watchSubmit(postCost(
			this.props.group.id,
			this.state.accountId,
			{
				amount: {
					amount: this.state.amount,
					currency: this.props.group.currencyUnit,
				},
				description: this.state.description,
				paidTo: null,
			},
			'courseGroupCostsPage'
		));
	}

	changeAmount = (e) => {
		this.setState({
			amount: e.target.value.replace(',', '.')
		});
	}

	changeDescription = (e) => {
		this.setState({
			description: e.target.value
		});
	}

	changeAccountId = (e) => {
		this.setState({
			accountId: e.target.value
		});
	}
}

CourseGroupCostsAddForm.propTypes = {
	group: PropTypes.object,
};

export default withFormData()(CourseGroupCostsAddForm)
