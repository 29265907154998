import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { fetchClient } from '../../actions/clientActions';
import { formatAddress } from '../../helpers/formatter';
import withRouter from '../../hoc/withRouter';
import withFetchData from '../../modules/fetchData/withFetchData';
import { getKey, Schemas } from '../../actions/schemas';
import withRootCapabilities from '../../modules/scope/withRootCapabilities';
import { capabilities } from '../../helpers/capabilities';
import PropertyPageHeaderInfo from '../properties/PropertyPageHeaderInfo';
import PageHeader from '../page/PageHeader';
import PageHeaderNavigation from '../page/PageHeaderNavigation';
import PageHeaderMenu from '../page/PageHeaderMenu';
import PageHeaderTitle from '../page/PageHeaderTitle';
import PageHeaderInfo from '../page/PageHeaderInfo';
import PageNoteBlock from '../page/PageNoteBlock';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';
import ModalManager from '../modal/ModalManager';
import ModalItem from '../modal/ModalItem';
import MobilePhoneActions from '../util/MobilePhoneActions';
import ClientEditDetailsModal from './clientEdit/ClientEditDetailsModal';
import ClientEditEmailModal from './clientEdit/ClientEditEmailModal';
import ClientEditAddressModal from './clientEdit/ClientEditAddressModal';
import ClientOverviewFetchDataLayer from './ClientOverviewFetchDataLayer';

function ClientOverviewPage({ loading, error, data: client, hasCapability }) {
	const { t } = useTranslation();
	const location = useLocation();

	if (error) return (
		<Alert type={Alert.TYPE_WARNING}>
			{error.errorCode === 403
				? 'Sorry, you may not view the details of this customer.'
				: 'Error! Sorry, we could not load the data.'}
		</Alert>
	);

	if (loading || !client) return <Loader />;

	const address = formatAddress(client);

	const menuItems = hasCapability(capabilities.EditClientDetailsCapability) ? [
		{ name: 'Edit details', to: location.pathname, state: { modal: 'editDetails' }, icon: 'person' },
		{ name: 'Edit email', to: location.pathname, state: { modal: 'editEmail' }, icon: 'email' },
		{ name: 'Edit address', to: location.pathname, state: { modal: 'editAddress' }, icon: 'location_on' },
	] : [];

	return (
		<>

			<ModalManager>
				<ModalItem state="editDetails" component={ClientEditDetailsModal} client={client} />
				<ModalItem state="editEmail" component={ClientEditEmailModal} client={client} />
				<ModalItem state="editAddress" component={ClientEditAddressModal} client={client} />
			</ModalManager>

			<PageHeader>
				<PageHeaderNavigation
					breadcrumbs={[
						{ label: 'Customers', to: '/portal/customers' },
						{ label: client.name },
					]}
				/>
				<PageHeaderMenu items={menuItems} />
				<PageHeaderTitle headline={client.fullName} />
				<PageHeaderInfo
					items={[
						{ label: 'Name', content: client.fullName },
						{ label: 'Mobile', content: (<MobilePhoneActions client={client} />) },
						{ label: 'Email', content: client.email },
						{ label: 'Address', content: address },
						{ label: 'Marketing', content: client.marketingOptIn ? 'Opted in' : 'Denied' },
						{ label: 'Webstore language', content: client.locale ? client.locale.split('_')[0] : <em>default</em> },
					]}
				/>
				<div>
					<Button
						variant="contained"
						color="primary"
						component={Link}
						to={location.pathname}
						state={{ modal: 'editProperties' }}
						style={{ float: 'right', color: 'white' }}
					>
						Edit properties
					</Button>
					<Typography variant="subtitle1">{t('properties.title')}</Typography>
				</div>
				<PropertyPageHeaderInfo
					user={client}
					context="client"
				/>
			</PageHeader>

			<Grid container spacing={2}>
				<ClientOverviewFetchDataLayer {...{ location, client }} />
				<Grid item md={6} xs={12}>
					<PageNoteBlock context="client" contextId={client.id} />
				</Grid>
			</Grid>
		</>
	);
}

export default withRootCapabilities(withFetchData(props => fetchClient(props.params.clientId), {
	mapEntities: getKey(Schemas.CLIENT),
})(ClientOverviewPage));
