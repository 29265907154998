import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFoundPage from '../util/NotFoundPage';
import LocationListPage from './LocationListPage';
import LocationOverviewPage from './LocationOverviewPage';

function LocationPage() {
    return (
        <Routes>
            <Route path="/" index element={<LocationListPage />} />
            <Route path=":locationId" element={<LocationOverviewPage />} />
            <Route element={<NotFoundPage />} />
        </Routes>
    );
}

export default LocationPage;
