import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListNode, ListItemNode } from '@lexical/list';
import { $generateHtmlFromNodes } from '@lexical/html';
import { TRANSFORMERS, $convertFromMarkdownString, $convertToMarkdownString } from '@lexical/markdown';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { useTranslation } from 'react-i18next';
import isJSON from '../../../helpers/contentType';
import ErrorBoundary from '../ErrorBoundary';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CustomAutoFocusPlugin from './plugins/CustomAutoFocusPlugin';
import UpdateReadOnlyEditorPlugin from './plugins/UpdateReadOnlyEditorPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import editorBaseStyleCreator from './styles/editorBaseStyleCreator';

const PREFIX = 'LexicalEditor';

const classes = {
    inner: `${PREFIX}-inner`,
    input: `${PREFIX}-input`,
    readOnly: `${PREFIX}-readOnly`,
    placeholder: `${PREFIX}-placeholder`,
};

const StyledInnerDiv = styled('div')(({ theme, minHeight }) => ({
    [`&.${classes.inner}`]: {
        position: 'relative',
        marginBottom: theme.spacing(1),
    },

    [`& .${classes.input}`]: {
        padding: theme.spacing(1),
        outline: 'none',
        minHeight,
        [`&:not(.${classes.readOnly})`]: {
            border: theme.borderStyle,
            background: '#f7f7f7',
        },
        ...editorBaseStyleCreator(theme),
    },

    [`& .${classes.readOnly}`]: {
        minHeight: 'unset',
    },
}));

const editorTheme = {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
    quote: 'editor-quote',
    heading: {
        h1: 'editor-heading-h1',
        h2: 'editor-heading-h2',
        h3: 'editor-heading-h3',
    },
    list: {
        nested: {
            listitem: 'editor-nested-listitem',
        },
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
        listitem: 'editor-listitem',
    },
    image: 'editor-image',
    link: 'editor-link',
    text: {
        bold: 'editor-text-bold',
        italic: 'editor-text-italic',
        overflowed: 'editor-text-overflowed',
        hashtag: 'editor-text-hashtag',
        underline: 'editor-text-underline',
        strikethrough: 'editor-text-strikethrough',
        underlineStrikethrough: 'editor-text-underlineStrikethrough',
    },
};

// When the editor changes, you can get notified via the LexicalOnChangePlugin
const createOnChangeHandler = (onChange, output) => (editorState, editor) => {
    editor.update(() => {
        const htmlString = output === 'html' ? $generateHtmlFromNodes(editor, null) : undefined;
        const markdownString = output === 'markdown' ? $convertToMarkdownString(TRANSFORMERS) : undefined;
        if (onChange) onChange(JSON.stringify(editorState.toJSON()), htmlString || markdownString);
    });
};

function onError(error) {
    console.error(error);
}

const Placeholder = styled(({ className }) => {
    const { t } = useTranslation();
    return (
        <div className={classnames(className, classes.placeholder)}>
            {t('editor.placeholder')}
        </div>
    );
})(({ theme }) => ({
    [`&.${classes.placeholder}`]: {
        color: '#999',
        overflow: 'hidden',
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
        userSelect: 'none',
        pointerEvents: 'none',
    },
}));

/**
 * @typedef {Object} EditorApi
 * @property {(newState: string) => void} setEditorState
 * @property {() => array} getNodes
 * @property {() => void} focus
 */

/**
 * @param {(api: EditorApi|null) => void} bindApi
 * @param {Array} nodes
 * @returns {null}
 * @constructor
 */
function IntegrationPlugin({ bindApi, nodes }) {
    const [editor] = useLexicalComposerContext();

    const editorRef = useRef(editor);
    editorRef.current = editor;

    const nodesRef = useRef(nodes);
    nodesRef.current = nodes;

    useEffect(() => {
        bindApi?.({
            setEditorState: (newState) => {
                const e = editorRef.current;
                e.setEditorState(e.parseEditorState(newState));
            },
            getNodes: () => nodesRef.current,
            focus: () => editorRef.current.focus(),
        });

        return () => {
            bindApi?.(null);
        };
    }, [bindApi]);

    return null;
}

IntegrationPlugin.propTypes = {
    bindApi: PropTypes.func,
    nodes: PropTypes.array,
};

function LexicalEditor({ onChange, content, isReadyOnly, allowedBlockTypes, minHeight, output, disableAutoFocus, bindEditorApi, toolbarComponents }) {
    const initialState = useMemo(() => {
        if (typeof content !== 'string' || isJSON(content)) {
            return content;
        }
        // Set empty initialState when content is HTML
        if (/<\/?[a-z][\s\S]*>/i.test(content)) {
            return null;
        }
        return () => {
            $convertFromMarkdownString(content, TRANSFORMERS);
        };
    }, [content]);

    const hasHeading = !allowedBlockTypes
		|| (allowedBlockTypes.includes('h1') || allowedBlockTypes.includes('h2') || allowedBlockTypes.includes('h3'));

    const nodes = useMemo(() => (
        [AutoLinkNode, LinkNode, ListNode, ListItemNode]
            .concat(!allowedBlockTypes || allowedBlockTypes.includes('quote') ? [QuoteNode] : [])
            .concat(hasHeading ? [HeadingNode] : [])
    ), [allowedBlockTypes, hasHeading]);

    const handleChange = useCallback(createOnChangeHandler(onChange, output), [onChange, output]);

    const initialConfig = {
        namespace: 'AthenaLexicalEditor',
        theme: editorTheme,
        onError,
        nodes,
        readOnly: !!isReadyOnly,
        editorState: initialState,
    };

    return (
        <ErrorBoundary hideStack>
            <LexicalComposer initialConfig={initialConfig}>
                {isReadyOnly ? (
                    <UpdateReadOnlyEditorPlugin content={content} />
                ) : (
                    <>
                        <ToolbarPlugin allowedBlockTypes={allowedBlockTypes}>
                            {toolbarComponents}
                        </ToolbarPlugin>
                        <OnChangePlugin ignoreSelectionChange onChange={handleChange} />
                        <HistoryPlugin />
                        {!disableAutoFocus && (
                            <CustomAutoFocusPlugin />
                        )}
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <ListPlugin />
                        <IntegrationPlugin bindApi={bindEditorApi} nodes={nodes} />
                    </>
                )}
                <StyledInnerDiv className={classes.inner} minHeight={minHeight}>
                    <RichTextPlugin
                        contentEditable={(
                            <ContentEditable
                                className={classnames(classes.input, {
                                    [classes.readOnly]: isReadyOnly,
                                })}
                                readOnly={isReadyOnly}
                            />
                        )}
                        placeholder={(
                            <Placeholder />
                        )}
                    />
                </StyledInnerDiv>
            </LexicalComposer>
        </ErrorBoundary>
    );
}

LexicalEditor.propTypes = {
    onChange: PropTypes.func,
    content: PropTypes.string,
    isReadyOnly: PropTypes.bool,
    // TODO: make this prop more generic so it can also control inline types and other editor toolbar options
    allowedBlockTypes: PropTypes.array,
    minHeight: PropTypes.number,
    // TODO: change name of prop as this adds an extra param, it does not change the default output
    output: PropTypes.oneOf(['html', 'markdown']),
    disableAutoFocus: PropTypes.bool,
    bindEditorApi: PropTypes.func,
    toolbarComponents: PropTypes.node,
};

LexicalEditor.defaultProps = {
    disableAutoFocus: false,
};

export default LexicalEditor;
