import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { mapObjIndexed } from 'ramda'
import { useLocation } from 'react-router-dom';
import { moneySumList } from '../../../helpers/money'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { CALL_API } from '../../../setup/api'
import { niceTime } from '../../../helpers/datetime'
import { getCollegeYearStart } from '../liveUtils'
import LiveSplashMil from '../splash/LiveSplashMil'
import LiveStatsBlock, { formatLiveStatsBigMoney } from './LiveStatsBlock'

const apiAction = (rootScopeId) => ({
	[CALL_API]: {
		type: 'LIVE_STATS',
		endpoint: `scope/${rootScopeId}/aggregated-scope-report?begin=${getCollegeYearStart().subtract(1, 'year').format('YYYY-MM')}`,
	},
});

const partyValue = 1000000;

const LiveStatsYearRevenue = ({ loading, data, fetchCache }) => {
	const location = useLocation();

	data = !data ? false : Object.values(mapObjIndexed((obj, key) => ({
		...obj,
		date: moment(`${key}-01`),
	}), data));

	let valueTotal = false;
	let value = false;
	let valueComp = false;

	if(data){
		const thisYearStart = getCollegeYearStart();
		const todayLastYearSameMonth = moment().subtract(1, 'year').endOf('month');
		const endOfThisMonth = moment().endOf('month');

		const thisYearTotal = data.filter(item =>
			item.date.isSameOrAfter(thisYearStart)
		);

		const thisYear = thisYearTotal.filter(item =>
			item.date.isSameOrBefore(endOfThisMonth)
		);

		const lastYear = data.filter(item =>
			item.date.isSameOrBefore(todayLastYearSameMonth)
		);

		valueTotal = moneySumList(thisYearTotal.map(obj => obj.netRevenue));
		value = moneySumList(thisYear.map(obj => obj.netRevenue));
		valueComp = moneySumList(lastYear.map(obj => obj.netRevenue));
	}

	return (
		<Fragment>
			<LiveStatsBlock
				label="College year revenue"
				value={value}
				comparisonLabel="year before"
				comparisonValue={valueComp}
				formatValue={formatLiveStatsBigMoney}
				comparisonOnlyShow
				title={`${formatLiveStatsBigMoney(valueTotal)} - last updated: ${fetchCache ? niceTime(fetchCache.date) : 'unknown'}`}
			/>
			{valueTotal.amount >= partyValue && valueTotal.amount < (partyValue + 1000) && location.search.indexOf('mil=false') === -1 && (
				<LiveSplashMil value={valueTotal} />
			)}
			{location.search.indexOf('millitest=true') > -1 && (
				<LiveSplashMil value={valueTotal} />
			)}
		</Fragment>
	);
};

LiveStatsYearRevenue.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default withFetchData((props) => apiAction(props.rootScopeId), {
	autoRefreshSeconds: 60,
})(LiveStatsYearRevenue)
