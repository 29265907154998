import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

const FinancesReportClickInfo = ({ children }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Fragment>
			<IconButton aria-label="Expand" onClick={handleClick} size="small" style={{padding: 0}}>
				<Icon fontSize="small">
					info
				</Icon>
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
			>
				<Typography style={{margin: '1em'}}>
					{children}
				</Typography>
			</Popover>
		</Fragment>
	)
}

FinancesReportClickInfo.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FinancesReportClickInfo
