import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import i18nServices from './helpers/i18nServices';

i18nServices.initI18n(i18nServices.getLanguageFromCache());

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
