import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withDialogShouldBeFullscreen from '../../../hoc/withDialogShouldBeFullscreen'
import ProfileEditDetailsForm from './ClientEditDetailsForm'

const propTypes = {
	client: PropTypes.object.isRequired
}

class ClientEditDetailsModal extends Component {
	saveBinder = null;

	render(){
		const { client, handleClose, fullScreen } = this.props;

		return (
			<Dialog
				open={true}
				onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)}
				maxWidth="md"
				fullWidth
				fullScreen={fullScreen}
			>
				<DialogTitle>Edit {client.name}'s details</DialogTitle>
				<DialogContent>
					<ProfileEditDetailsForm
						inputData={client}
						bindSave={(func) => this.saveBinder = func}
						onSave={handleClose}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button
						onClick={() => {if(this.saveBinder) this.saveBinder()}}
						color="primary"
						variant="contained"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

ClientEditDetailsModal.propTypes = propTypes

export default withDialogShouldBeFullscreen()(ClientEditDetailsModal)
