import {
	buttonBaseClasses,
	Checkbox, listClasses,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	popoverClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { propertyLabel } from '../../helpers/properties';

const PREFIX = 'ColumnPicker';

const classes = {
    menu: `${PREFIX}-menu`
};

const StyledMenu = styled(Menu)(({ theme }) => ({
    [`&.${classes.menu}`]: {
		[`& .${listClasses.root}`]: {
			padding: theme.spacing(0.5, 0),
		},
		[`& .${popoverClasses.paper}`]: {
			border: '1px solid #ccc',
			minWidth: '200px',
		},
		[`& .${buttonBaseClasses.root}`]: {
			padding: theme.spacing(0.75, 1.5),
		},
	}
}));

export function getInitialColumns(context) {
	const cachedColumns = localStorage.getItem(context);
	return cachedColumns ? JSON.parse(cachedColumns).columns || [] : [];
}

const ColumnPicker = ({ columns, selected, setColumns, context }) => {

	const { t, i18n } = useTranslation();
	const [anchorEl, setAnchorEl] = useState();

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleItemClick = useCallback((item) => () => {
		const newColumnsArr = [...selected];
		const index = selected.indexOf(item.key);
		if (index === -1) {
			newColumnsArr.push(item.key);
		} else {
			newColumnsArr.splice(index, 1);
		}
		if (newColumnsArr.length) {
			localStorage.setItem(context, JSON.stringify({ columns: newColumnsArr }));
		} else {
			localStorage.removeItem(context);
		}

		setColumns(newColumnsArr);
	}, [context, selected, setColumns]);

	const sortedColumns = useMemo(
		() => columns
			.sort((a, b) => propertyLabel(a, i18n.language).toLowerCase() > propertyLabel(b, i18n.language).toLowerCase() ? 1 : -1),
		[columns, i18n.language],
	);

	if (!columns) {
		return null;
	}

	return (
        <>
            <Button
				startIcon={<Icon>view_column</Icon>}
				endIcon={<Icon>{`keyboard_arrow_${anchorEl ? 'up' : 'down'}`}</Icon>}
				onClick={handleOpen}
				variant="outlined"
				color="default"
				sx={{ float: 'right', background: 'white' }}
			>
				{t('general.pickColumns')}
			</Button>
            <StyledMenu
				className={classes.menu}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				keepMounted
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handleClose}
			>
				<List dense>
					{sortedColumns.map(item => (
						<ListItem
							dense
							button
							onClick={handleItemClick(item)}
							key={item.key}
						>
							<ListItemIcon sx={{ minWidth: '35px' }}>
								<Checkbox
									edge="start"
									checked={(selected || []).includes(item.key)}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': item.key }}
									size="small"
									color="secondary"
								/>
							</ListItemIcon>
							<ListItemText id={item.key} primary={propertyLabel(item, i18n.language)} />
						</ListItem>
					))}
				</List>
			</StyledMenu>
        </>
    );
};

ColumnPicker.propTypes = {
	columns: PropTypes.array,
	selected: PropTypes.array,
	context: PropTypes.string.isRequired,
	setColumns: PropTypes.func.isRequired,
};

export default ColumnPicker;
