import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table'

const PREFIX = 'ResponsiveTable';

const classes = {
    wrapper: `${PREFIX}-wrapper`
};

const Root = styled('div')({
	[`&.${classes.wrapper}`]: {
		width: '100%',
		overflowX: 'auto',
	},
});

const ResponsiveTable = ({  ...rest }) => (
	<Root className={classes.wrapper}>
		<Table size="small" {...rest} />
	</Root>
);

export default (ResponsiveTable)
