import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withFormData from '../../modules/formData/withFormData'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import { cancelGroup } from '../../actions/courseGroupActions'
import { canCancel } from '../../helpers/groupState'

const CourseGroupCancelModal = ({ group, handleClose, handleSubmit, saving, error, success }) => {
	const disabled = !canCancel(group.state);

	return (
		<Dialog open={true} onClose={handleClose}>
			<form onSubmit={handleSubmit(() => ({
				id: group.id
			}), cancelGroup)}>
				<DialogTitle>Cancel {group.name}</DialogTitle>
				{success ? (
					<DialogContent>
						<Alert type={Alert.TYPE_SUCCESS}>Group has been cancelled. You might need to reload the page.</Alert>
					</DialogContent>
				) : (disabled ? (
					<DialogContent>
						<Alert type={Alert.TYPE_INFO}>This group cannot be cancelled anymore.</Alert>
					</DialogContent>
				) : (
					<DialogContent>
						{saving && <Loader sheet />}
						{
							error &&
							<Alert type={Alert.TYPE_WARNING}>
								{error.error}
							</Alert>
						}
						<DialogContentText>
							Confirm you want to cancel this group. This might not be reversible, so use with care.
						</DialogContentText>
					</DialogContent>
				))}
				<DialogActions>
					<Button onClick={handleClose} color="secondary" disabled={saving}>
						Close
					</Button>
					{
						(!success && !disabled) &&
						<Button type="submit" color="primary" disabled={saving}>
							Confirm cancellation
						</Button>
					}
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default withFormData()(CourseGroupCancelModal)
