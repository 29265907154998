import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { CALL_API } from '../../../setup/api'
import { subtractExtraDaysFromThisYearToFitBeginOfTheWeekLastYear } from '../liveUtils'
import LiveStatsBlock, { formatLiveStatsBigMoney } from './LiveStatsBlock'

const apiAction = (rootScopeId) => ({
	[CALL_API]: {
		type: 'LIVE_STATS',
		endpoint: `scope/${rootScopeId}/income-forecast?begin=${moment().subtract(1, 'year').subtract(10, 'day').format('YYYY-MM-DD')}`,
	},
});

const LiveStatsIncomeToday = ({ loading, data }) => {
	const value = !data ? false : (() => {
		const day = data.find(item => {
			return moment(item.orderDateTime).isSame(moment(), 'day');
		});

		// TODO: realisedRevenue === income, key will be corrected later
		return day ? day.realisedRevenue || 0 : 0;
	})();

	const lastYear = moment()
		.subtract(1, 'year')
		.subtract(subtractExtraDaysFromThisYearToFitBeginOfTheWeekLastYear, 'day');

	const valueLastYear = !data ? false : (() => {
		const day = data.find(item => {
			return moment(item.orderDateTime).isSame(lastYear, 'day');
		});

		return day ? day.realisedRevenue || 0 : 0;
	})();

	return (
		<LiveStatsBlock
			label="Income today"
			value={value}
			comparisonLabel="last year"
			comparisonValue={valueLastYear}
			formatValue={formatLiveStatsBigMoney}
			comparisonOnlyShow
			comparisonCrown
		/>
	);
};

LiveStatsIncomeToday.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default withFetchData((props) => apiAction(props.rootScopeId), {
	autoRefreshSeconds: 60,
})(LiveStatsIncomeToday)
