import moment from 'moment'

// Version
export const liveVersion = '1.1';

// Debug mode, so extra fake stuff shows up etc
export const liveDebugMode = false;

// Controls the speed of everything, in seconds
// This is how long the main stats window will be shown
export const liveCoreInterval = liveDebugMode ? 10 : 300;

// Root scope to get the stats from, generally company level
export const liveRootScopeId = 1;

// Correct for the beginning of the week in comparing this year with last year
export const subtractExtraDaysFromThisYearToFitBeginOfTheWeekLastYear = -2;

export const getCollegeYearStart = () => {
	const now = moment();

	// Note, now.month() starts at 0
	return moment(`${now.year() - (now.month() < 8 ? 1 : 0)}-09-01`);
}
