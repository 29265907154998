import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { CALL_API } from '../../../setup/api'
import LiveStatsBlock, { formatLiveStatsBigMoney } from './LiveStatsBlock'

const apiAction = (rootScopeId) => {
	const begin = moment().subtract(1, 'year').format('YYYY-MM');
	const end = moment().add(1, 'month').format('YYYY-MM');

	// TODO: look at begin and end and determine if really needed
	// ... render will fetch correct dates already anyways
	// ... given them might give wrong results as filter seems to be on something else then allocation
	// Note: end is exclusive
	return ({
		[CALL_API]: {
			type: 'LIVE_STATS',
			endpoint: `scope/${rootScopeId}/aggregated-scope-report?begin1=${begin}&end1=${end}`,
		},
	});
}

const LiveStatsMonthRevenue = ({ loading, data }) => {
	const values = !data ? false : (() => {
		const begin = moment().subtract(1, 'year').format('YYYY-MM');
		const end = moment().format('YYYY-MM');

		return {
			current: data[end] ? data[end].netRevenue || 0 : 0,
			last: data[begin] ? data[begin].netRevenue || 0 : 0,
		}
	})();

	return (
		<LiveStatsBlock
			label="Revenue this month"
			value={values ? values.current : false}
			comparisonLabel="last year"
			comparisonValue={values ? values.last : false}
			formatValue={formatLiveStatsBigMoney}
			comparisonOnlyShow
			comparisonCrown
		/>
	);
};

LiveStatsMonthRevenue.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default withFetchData((props) => apiAction(props.rootScopeId), {
	autoRefreshSeconds: 60,
})(LiveStatsMonthRevenue)
