import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import { fetchClients, fetchSearchClients, getAllClientProperties } from '../../actions/clientActions';
import { useFetchData } from '../../helpers/hooks';
import { propertyLabel, propertyValue } from '../../helpers/properties';
import withFetchData from '../../modules/fetchData/withFetchData';
import withWorkingScope from '../../modules/scope/withWorkingScope';
import PageHeader from '../page/PageHeader';
import PageHeaderTitle from '../page/PageHeaderTitle';
import PropertyFiltersMenu from '../properties/PropertyFiltersMenu';
import PropertyTags from '../properties/PropertyTags';
import Alert from '../util/alert/Alert';
import ColumnPicker, { getInitialColumns } from '../util/ColumnPicker';
import FetchDataSwitch from '../util/FetchDataSwitch';
import ResponsiveTable from '../util/ResponsiveTable';
import ClientListSearchField from './ClientListSearchField';

function ClientListPage({ scope, pagination, load, data, error, loading, onPaginationChangePage, onPaginationChangePageSize }) {
	const { i18n } = useTranslation();
	const [search, setSearch] = useState('');
	const [properties, setProperties] = useState({});
	const [columns, setColumns] = useState(getInitialColumns('ClientListPage'));
	const { data: clientProperties } = useFetchData('ClientPropertiesDefinition', getAllClientProperties);

	const fetchClientsList = useCallback(() => {
		if (!scope) return;

		load(search || Object.entries(properties).length ? fetchSearchClients(scope, properties, search) : fetchClients(scope), true);
	}, [load, properties, scope, search]);

	useEffect(() => {
		if (scope) fetchClientsList();
	}, [scope, pagination.page, pagination.pageSize, search, fetchClientsList]);

	const renderPropertyValue = useCallback((propKey, propValue) => {
		const property = clientProperties?.find(prop => prop.key === propKey);
		if (!property) {
			return null;
		}
		return propertyValue(property, propValue, i18n.language);
	}, [clientProperties, i18n.language]);

	const renderPropertyLabel = useCallback((propKey) => {
		const property = clientProperties?.find(prop => prop.key === propKey);
		if (!property) {
			return null;
		}
		return propertyLabel(property, i18n.language);
	}, [clientProperties, i18n.language]);

	const handleSearchFieldChange = searchText => {
		setSearch(searchText);
	};

	const handleProperty = (prop, value) => {
		let clone = { ...properties };
		if (!value) {
			delete clone[prop];
		} else {
			clone = { ...properties, [prop]: value };
		}
		setProperties(clone);
	};

	if (!scope) return (
		<Alert>
			First select a scope in the scope selector at the top of the page.
		</Alert>
	);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PageHeader>
					<PageHeaderTitle
						headline="Customers"
						subHeading={`The customers within ${scope.name}`}
					/>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12}>
							<ClientListSearchField onChange={handleSearchFieldChange} />
						</Grid>
						<Grid item xs={12}>
							<PropertyFiltersMenu
								handleProperty={handleProperty}
								propertiesDefinition={clientProperties}
								propertiesState={properties}
							/>
							<PropertyTags actionId="ClientPropertiesDefinition" properties={properties} />
						</Grid>
					</Grid>
				</PageHeader>
			</Grid>
			<Grid item xs={12}>
				{(!loading && data) && (
					<ColumnPicker
						columns={clientProperties}
						selected={columns}
						setColumns={setColumns}
						context="ClientListPage"
					/>
				)}
			</Grid>
			<Grid item xs={12}>
				<FetchDataSwitch
					{...{ data, loading, error }}
					ifEmptyData={(
						<Alert type={Alert.TYPE_INFO}>
							There are no customers found within
							{' '}
							<strong>{scope.name}</strong>
							{search && (
								<span>
									{', while searching for: '}
									&quot;
									<strong>{search}</strong>
									&quot;
								</span>
							)}
						</Alert>
					)}
					ifError={(
						<Alert type={Alert.TYPE_WARNING}>
							{error && error.errorCode === 403 && (
								'You do not have permission to view the customers for the select scope.'
							)}
							{error && error.errorCode !== 403 && error.error && (
								'There was some error.'
							)}
						</Alert>
					)}
				>
					{data && (
						<Paper>
							<ResponsiveTable>
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Email</TableCell>
										<TableCell>Phone</TableCell>
										{columns.map(column => (
											<TableCell size="small" style={{ whiteSpace: 'nowrap' }} key={column}>
												{renderPropertyLabel(column)}
											</TableCell>
										))}
										<TableCell style={{ width: '10%' }} />
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map(({ id, fullName, email, mobilePhone, properties }) => {
										const clientLink = `/portal/customers/${id}`;
										return (
											<TableRow hover key={id}>
												<TableCell>
													<Link to={clientLink}>{fullName}</Link>
												</TableCell>
												<TableCell>{email}</TableCell>
												<TableCell>{mobilePhone}</TableCell>
												{columns.map(column => (
													<TableCell size="small" key={column}>
														{renderPropertyValue(column, properties?.[column])}
													</TableCell>
												))}
												<TableCell align="right" style={{ padding: 0 }}>
													<IconButton component={Link} to={clientLink}>
														<Icon>keyboard_arrow_right</Icon>
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
								{pagination && (
									<TableFooter>
										<TableRow>
											<TablePagination
												colSpan={4 + columns.length}
												count={pagination.total}
												rowsPerPage={pagination.pageSize}
												page={pagination.page}
												onPageChange={onPaginationChangePage}
												onRowsPerPageChange={onPaginationChangePageSize}
												rowsPerPageOptions={[10, 25, 50, 100]}
											/>
										</TableRow>
									</TableFooter>
								)}
							</ResponsiveTable>
						</Paper>
					)}
				</FetchDataSwitch>
			</Grid>
		</Grid>
	);
}

export default withWorkingScope(withFetchData(null, {
	pagination: true,
	customId: () => 'ClientListPage',
})(ClientListPage));
