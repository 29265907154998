import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

const propTypes = {
	handleSort: PropTypes.func.isRequired,
	sortDisplay: PropTypes.string.isRequired,
	sortDesc: PropTypes.bool.isRequired,
}

const headerItems = [
	{ key: 'orderId', name: 'Order ID' },
	{ key: 'courseName', name: 'Course' },
	{ key: 'groupName', name: 'Group' },
	{ key: 'startDate', name: 'Start Date' },
	{ key: 'evaluation', name: 'Evaluation' },
	{ key: 'totalAmount', name: 'Total Amount' },
	{ key: 'orderStatus', name: 'Order Status' },
	{ key: 'shippingStatus', name: 'Booklet' },
]

const ClientCourseTableHeader = ({ handleSort, sortDisplay, sortDesc }) => (
	<TableRow>
		{headerItems.map(item => (
			<TableCell
				key={item.key}
				align={item.key === 'totalAmount' ? 'right' : undefined}
			>
				<TableSortLabel
					onClick={handleSort(item.key)}
					active={sortDisplay === item.key}
					direction={sortDesc ? 'desc' : 'asc'}
				>
					{item.name}
				</TableSortLabel>
			</TableCell>
		))}
	</TableRow>
)

ClientCourseTableHeader.propTypes = propTypes

export default ClientCourseTableHeader
