import React, { Component, Fragment } from 'react'
import { styled } from '@mui/material/styles';
import moment from 'moment'
import { startOfMonth } from 'date-fns'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, Filler, TimeScale, RadialLinearScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import withFetchData from '../../../modules/fetchData/withFetchData'
import withWorkingScope from '../../../modules/scope/withWorkingScope'
import InfoTooltip from '../../../modules/info/InfoTooltip'
import { capabilities, hasScopeCapability } from '../../../helpers/capabilities'
import { workingScopesNameString } from '../../../helpers/scope'
import { getAggregatedScopeReport } from '../../../actions/dashboardActions'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'
import PageHeader from '../../page/PageHeader'
import PageHeaderTitle from '../../page/PageHeaderTitle'
import YearMonthPicker from '../batchCheck/YearMonthPicker'
import CustomerRetentionMetrics from './CustomerRetentionMetrics'
import IncomePrognosis from './IncomePrognosis'
import CostBreakdown from './CostBreakdown'
import RevenueChart from './RevenueChart'
import TrendLines from './TrendLines'

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
	PointElement,
	RadialLinearScale,
	LineElement,
	Filler,
	TimeScale,
    annotationPlugin,
);

const PREFIX = 'FinancialDashboardPage';

const classes = {
    paper: `${PREFIX}-paper`,
    paperAndMargin: `${PREFIX}-paperAndMargin`,
    infoTooltip: `${PREFIX}-infoTooltip`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.paper}`]: {
		padding: 10,
	},

    [`& .${classes.paperAndMargin}`]: {
		padding: 10,
		marginTop: 20,
	},

    [`& .${classes.infoTooltip}`]: {
		marginTop: `-${theme.spacing(1)}`,
		marginRight: `-${theme.spacing(1)}`,
	}
}));

function parseDate(date) {
	return moment(date, 'YYYY-MM');
}

class FinancialDashboardPage extends Component {
	state = {
		selectedDate: startOfMonth(new Date())
	};

	componentDidMount() {
		if (this.props.scope) this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.scope) return;
		let updateRequired = !prevProps.scope;
		updateRequired |=  prevProps.scope && prevProps.scope.id !== this.props.scope.id;
		updateRequired |= prevState.selectedDate !== this.state.selectedDate;
		if (updateRequired) this.fetchData();
	}

	fetchData() {
		this.props.load(getAggregatedScopeReport(
			this.props.scope.id, this.state.selectedDate
		));
	}

	returnMinDate() {
		const { data: aggregatedScopeReport = {} } = this.props;
		const arr = Object.keys(aggregatedScopeReport);
		if (arr.length) {
			return parseDate(arr[0]);
		}
		return undefined;
	}

	returnMaxDate() {
		const { data: aggregatedScopeReport = {} } = this.props;
		const arr = Object.keys(aggregatedScopeReport);
		if (arr.length) {
			return parseDate(arr[arr.length - 1]);
		}
		return undefined;
	}

	render() {
		const { scope, error, loading, workingScopes } = this.props;
		return (
            <Fragment>
				<StyledGrid container spacing={2}>
					<Grid item xs={12}>
						<PageHeader>
							<div style={{float: 'right'}}>
								<YearMonthPicker
									selectedDate={this.state.selectedDate}
									onChange={(selectedDate) => this.setState({selectedDate})}
									minDate={this.returnMinDate()}
									maxDate={this.returnMaxDate()}
								/>
							</div>
							<PageHeaderTitle
								headline={
									<span>
										Dashboard
										{ loading && <Loader inline />}
									</span>
								}
								subHeading={workingScopesNameString(workingScopes)}/>
						</PageHeader>
					</Grid>
				</StyledGrid>
				<StyledGrid container spacing={2}>
					{error ? (
						<Grid item xs={12}>
							<Alert type={Alert.TYPE_WARNING}>{error.error}</Alert>
						</Grid>
					) : (
						!scope ? (
							<Grid item xs={12}>
								<Alert type={Alert.TYPE_INFO}>Please specify a scope to load the data for.</Alert>
							</Grid>
						) : (
							<Fragment>
								{this.renderPrimaryMetrics()}
								{this.renderSecondaryMetrics()}
							</Fragment>
						)
					)}
				</StyledGrid>
			</Fragment>
        );
	}

	renderPrimaryMetrics() {
		const {  workingScope, data: aggregatedScopeReport } = this.props;
		const currency = workingScope.currencyUnit;

		return (
			<Grid item md={8} sm={12} xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography variant="subtitle1" gutterBottom>
								Gross Profit & Net Revenue
							</Typography>
							<RevenueChart
								aggregatedScopeReport={aggregatedScopeReport}
								selectedDate={this.state.selectedDate}
								currency={currency}
							/>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<CustomerRetentionMetrics selectedDate={this.state.selectedDate}/>
					</Grid>
					{hasScopeCapability(workingScope, capabilities.FinancialReportingCapability) && (
						<Grid item xs={12}>
							<IncomePrognosis currency={currency} />
						</Grid>)
					}
				</Grid>
			</Grid>
		)
	}

	renderSecondaryMetrics() {
		const {  data: aggregatedScopeReport, workingScope, loading } = this.props;
		const month = this.state.selectedDate && moment(this.state.selectedDate).format("MMMM YYYY");
		const currency = workingScope.currencyUnit;

		const hasFinancialReportingCapability = hasScopeCapability(workingScope, capabilities.FinancialReportingCapability);

		return (
			<Grid item md={4} sm={12} xs={12}>
				<Grid container>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography variant="subtitle1" gutterBottom>
								{`Trends ${month}`}
							</Typography>
							<TrendLines
								aggregatedScopeReport={aggregatedScopeReport}
								selectedDate={this.state.selectedDate}
								hasFinancialReportingCapability={hasFinancialReportingCapability}
								loading={loading}
							/>
						</Paper>
					</Grid>
					{hasFinancialReportingCapability && (
						<Grid item xs={12}>
							<Paper className={classes.paperAndMargin}>
								<InfoTooltip
									infoTitle="Financial - cost breakdown"
									infoContent={'All costs together (thus without the gross profit) sum up to 100% of the costs that month.\n\n##Gross profit\n\nEnabling this will show horizontal profit lines per month. These represent the percentage of profit relative to the costs.\n\n##Use case\n\nUse this graph to see how much you relatively spend on different cost factors. Compare this with the previous years to see if there is room for cost reduction. Use the gross profit to see which months had a relative low profit to find a possible connection with a cost factor.'}
									className={classes.infoTooltip}
									floatRight
								/>
								<Typography variant="subtitle1" gutterBottom>
									Cost Breakdown
								</Typography>
								<CostBreakdown
									aggregatedScopeReport={aggregatedScopeReport}
									currency={currency}
								/>
							</Paper>
						</Grid>
					)}
				</Grid>
			</Grid>
		)
	}
}

export default (withWorkingScope(
	withFetchData(null)(FinancialDashboardPage)
))
