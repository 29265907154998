import { ListItemButton } from '@mui/material';
import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Icon from '@mui/material/Icon';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { niceDateDay } from '../../helpers/datetime';

const PREFIX = 'CourseGroupTimeSlotsLinkedModal';

const classes = {
    list: `${PREFIX}-list`,
    nestedItem: `${PREFIX}-nestedItem`,
    subHeader: `${PREFIX}-subHeader`,
    noLinked: `${PREFIX}-noLinked`,
    chip: `${PREFIX}-chip`,
    closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.list}`]: {
        width: '100%',
    },

    [`& .${classes.nestedItem}`]: {
        paddingLeft: theme.spacing(4),
    },

    [`& .${classes.subHeader}`]: {
        color: theme.palette.text.primary,

    },

    [`& .${classes.noLinked}`]: {
        fontStyle: 'italic',
    },

    [`& .${classes.chip}`]: {
        marginLeft: theme.spacing(2),
        fontSize: '0.8em',
        height: theme.spacing(3),
    },

    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
}));

function CourseGroupTimeSlotsLinkedModal({ handleClose, group, timeSlots }) {
    const { t } = useTranslation();

    return (
        <StyledDialog open={true} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Groups linked to the time slots
                <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                    <Icon>close</Icon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Linked time slots are used to make sure teachers, for example, will not be paid twice by accident.
                    {' '}
                    This especially holds if the same time slot (combination of date, time, teacher and/or location) is used for multiple groups.
                    {' '}
                    This overview shows which groups make use of the same time slot. The owning time slot is where the salaries will be allocated.
                </DialogContentText>
                <List className={classes.list}>
                    {timeSlots.map(timeSlot => {
                        const groups = timeSlot.referencingCourseGroups ? timeSlot.referencingCourseGroups.filter(rGroup => (
                            rGroup.id !== group.id
                        )) : [];

                        const isOwner = group.id === timeSlot.owningCourseGroup.id;

                        return (
                            <Fragment key={timeSlot.id}>
                                <ListSubheader
                                    key={`header-${timeSlot.id}`}
                                    className={classes.subHeader}
                                    disableSticky
                                >
                                    {niceDateDay(timeSlot.startDateTime)}
                                    {groups.length > 0 && isOwner && (
                                        <Chip label="Time slot owner" className={classes.chip} variant="outlined" color="primary" />
                                    )}
                                </ListSubheader>
                                {groups.length > 0 ? groups.map(refGroup => {
                                    const isRefOwner = refGroup.id === timeSlot.owningCourseGroup.id;

                                    return (
                                        <ListItemButton
                                            key={`group-${refGroup.id}`}
                                            className={classes.nestedItem}
                                            component={Link}
                                            to={`/portal/planner/course/${refGroup.parent.id}/group/${refGroup.id}`}
                                        >
                                            <ListItemText
                                                primary={(
                                                    <>
                                                        {`${refGroup.parent.name} - ${refGroup.name}`}
                                                        {isRefOwner && (
                                                            <Chip
                                                                label="Time slot owner"
                                                                className={classes.chip}
                                                                variant="outlined"
                                                                color="primary"
                                                            />
                                                        )}
                                                    </>
                                                )}
                                                secondary={`${t('general.occupancy')}: ${refGroup.soldUnits}/${refGroup.availableUnits}`}
                                            />
                                            <Icon>chevron_right</Icon>
                                        </ListItemButton>
                                    );
                                }) : (
                                    <ListItem
                                        key={timeSlot.id}
                                        className={classes.nestedItem}
                                    >
                                        <ListItemText
                                            primary="No linked groups."
                                            primaryTypographyProps={{ color: 'textSecondary' }}
                                            className={classes.noLinked}
                                        />
                                    </ListItem>
                                )}
                                <Divider />
                            </Fragment>
                        );
                    })}
                </List>
            </DialogContent>
        </StyledDialog>
    );
}

CourseGroupTimeSlotsLinkedModal.propTypes = {
    group: PropTypes.object.isRequired,
    timeSlots: PropTypes.array.isRequired,
};

export default (CourseGroupTimeSlotsLinkedModal);
