import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Typography, IconButton, Icon, styled, lighten } from '@mui/material';
import Alert from '../../components/util/alert/Alert';
import Loader from '../../components/util/loader/Loader';
import { infoHide } from './infoActions';

const MarkdownViewer = lazy(() => import('../../components/util/markdown/MarkdownViewer'));

const PREFIX = 'DialogTitleWithClose';
const classes = {
    closeButton: `${PREFIX}-closeButton`,
};

const StyledDialogTitleWithClose = styled(({ children, onClose, ...rest }) => (
    <DialogTitle {...rest}>
        <Typography variant="h6" component="span">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                <Icon>close</Icon>
            </IconButton>
        ) : null}
    </DialogTitle>
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(0, 1),
    padding: theme.spacing(2),
    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: theme.spacing(1.5),
        top: theme.spacing(1.5),
        color: theme.palette.grey[500],
    },
}));

const DIALOG_PREFIX = 'DIALOG_PREFIX';
const dialogClasses = {
    dialog: `${DIALOG_PREFIX}-dialog`,
    markdown: `${DIALOG_PREFIX}-markdown`,
    content: `${DIALOG_PREFIX}-content`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`&.${dialogClasses.dialog}`]: {
        zIndex: 1700,
    },
    [`& .${dialogClasses.markdown}`]: {
        '& h2': {
            margin: theme.spacing(2, -3),
            padding: theme.spacing(1, 3),
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
        '& p': {
            margin: theme.spacing(0.5, 0),
        },
        '& ul': {
            paddingLeft: `${parseInt(theme.spacing(2), 10) + 2}px`,
            margin: theme.spacing(0, 0, 1, 0),
            '& p': {
                margin: 0,
            },
        },
        '& table': {
            marginTop: theme.spacing(2),
            width: '100%',
            '& td, & th': {
                paddingLeft: 4,
                paddingRight: 4,
            },
            '& thead tr': {
                background: lighten(theme.palette.primary.light, 0.5),
                '& th': {
                    fontWeight: theme.typography.fontWeightBold,
                },
            },
            '& tbody tr:nth-of-type(even)': {
                background: lighten(theme.palette.primary.light, 0.7),
            },
        },
    },
    [`& .${dialogClasses.content}`]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

function InfoDialog({ info: { open, title, content }, infoHide: dispatchInfoHide }) {
    const { t } = useTranslation();
    return (
        <StyledDialog
            open={open}
            onClose={dispatchInfoHide}
            className={dialogClasses.dialog}
            maxWidth="sm"
            fullWidth
        >
            <StyledDialogTitleWithClose onClose={dispatchInfoHide}>
                {title || t('modules.info.noInfoFound')}
            </StyledDialogTitleWithClose>
            <DialogContent className={dialogClasses.content}>
                {content ? (
                    <Suspense fallback={<Loader />}>
                        <MarkdownViewer className={dialogClasses.markdown}>
                            {content}
                        </MarkdownViewer>
                    </Suspense>
                ) : (
                    <Alert type={Alert.TYPE_INFO}>
                        {t('modules.info.noContent')}
                    </Alert>
                )}
            </DialogContent>
        </StyledDialog>
    );
}

InfoDialog.propTypes = {
    info: PropTypes.shape({
        open: PropTypes.bool,
        title: PropTypes.string,
        content: PropTypes.string,
    }),
    infoHide: PropTypes.func,
};

export default connect(state => ({
    info: state.info,
}), {
    infoHide,
})(InfoDialog);
