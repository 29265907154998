import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import isJSON from '../../../../helpers/contentType';

function UpdateReadOnlyEditorPlugin({ content }) {
	const [editor] = useLexicalComposerContext();
	const shouldUpdate = useRef(false);

	useEffect(() => {
		if (shouldUpdate.current && isJSON(content)) {
			const newEditorState = editor.parseEditorState(content);
			editor.setEditorState(newEditorState);
		}
		shouldUpdate.current = true;
	}, [content, editor]);

	return null;
}

UpdateReadOnlyEditorPlugin.propTypes = {
	content: PropTypes.string,
};

export default UpdateReadOnlyEditorPlugin;
