import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Fade from '@mui/material/Fade'
import LiveStatsBlock from './liveStatsBlock/LiveStatsBlock'
import LiveStatsYearRevenue from './liveStatsBlock/LiveStatsYearRevenue'
import LiveStatsCustomersHelped from './liveStatsBlock/LiveStatsCustomersHelped'
import LiveStatsIncomeToday from './liveStatsBlock/LiveStatsIncomeToday'
import LiveStatsMonthRevenue from './liveStatsBlock/LiveStatsMonthRevenue'
import LiveTime from './LiveTime'
import card_green from './media/card_green.png';
import card_blue from './media/card_blue.png';
import card_orange from './media/card_orange.png';

const PREFIX = 'LiveCoreStats';

const classes = {
    root: `${PREFIX}-root`,
    item: `${PREFIX}-item`,
    card: `${PREFIX}-card`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
		[theme.breakpoints.down('md')]: {
			marginBottom: theme.spacing(2),
		},
	},

    [`& .${classes.item}`]: {
		display: 'flex',
	},

    [`& .${classes.card}`]: {
		display: 'flex',
		position: 'relative',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		'&.green': {
			backgroundImage: `url(${card_green})`,
		},
		'&.blue': {
			backgroundImage: `url(${card_blue})`,
		},
		'&.orange': {
			backgroundImage: `url(${card_orange})`,
		},
		[theme.breakpoints.down('md')]: {
			minHeight: 100,
		},
	}
}));

const LiveCoreStats = ({  rootScopeId }) => {
	return (
        <StyledGrid container spacing={2} alignContent="stretch" justifyContent="center" className={classes.root}>
			<Grid item md={4} xs={12} className={classes.item}>
				<Fade in={true} timeout={500}>
					<Paper className={`${classes.card} orange`}>
						<LiveStatsYearRevenue rootScopeId={rootScopeId} />
					</Paper>
				</Fade>
			</Grid>
			<Grid item md={4} xs={12} className={classes.item}>
				<Fade in={true} timeout={500}>
					<Paper className={`${classes.card} green`}>
						<LiveStatsMonthRevenue rootScopeId={rootScopeId} />
					</Paper>
				</Fade>
			</Grid>
			<Grid item md={4} xs={12} className={classes.item}>
				<Fade in={true} timeout={500}>
					<Paper className={`${classes.card} blue`}>
						<LiveStatsIncomeToday rootScopeId={rootScopeId} />
					</Paper>
				</Fade>
			</Grid>
			<Grid item md={4} xs={12} className={classes.item}>
				<Fade in={true} timeout={500}>
					<Paper className={`${classes.card} orange`}>
						<LiveTime />
					</Paper>
				</Fade>
			</Grid>
			<Grid item md={4} xs={12} className={classes.item}>
				<Fade in={true} timeout={500}>
					<Paper className={`${classes.card} green`}>
						<LiveStatsCustomersHelped rootScopeId={rootScopeId} />
					</Paper>
				</Fade>
			</Grid>
			<Grid item md={4} xs={12} className={classes.item}>
				<Fade in={true} timeout={500}>
					<Paper className={`${classes.card} blue`}>
						<LiveStatsBlock label="Courses today" value={1337} />
					</Paper>
				</Fade>
			</Grid>
		</StyledGrid>
    );
}

LiveCoreStats.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default (LiveCoreStats)
