import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Line as LineChart } from 'react-chartjs-2';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { mergeDeepRight } from 'ramda';
import { FINANCIAL_CHART } from './financialDashboardUtils';

const TRENDLINE_DATASET_DEFAULTS = {
    ...FINANCIAL_CHART.line.special,
    fill: true,
};

const TRENDLINE_CHART_OPTIONS = {
    spanGaps: true,
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        },
    },
    elements: {
        line: {
            tension: 0, // disables bezier curves
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: (value) => value.parsed.y.toLocaleString(),
            },
            mode: 'x',
            position: 'nearest',
        },
    },

};

const styleCaption = (gotValue) => ({
    lineHeight: gotValue ? '50px' : '12px',
    textAlign: 'right',
    fontWeight: 'bold',
    paddingTop: gotValue ? 0 : 30,
});

function TrendLine({
    label,
    data,
    loading,
    currentValue,
    valueExtractor = i => i,
    formatter = i => typeof i === 'number' ? (i).toLocaleString() : i,
    options = {},
}) {
    const value = currentValue ? valueExtractor(currentValue) : null;
    return (
        <>
            <Typography variant="subtitle2" gutterBottom>
                {label}
            </Typography>
            <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={8}>
                    {data && (
                        <LineChart
                            data={{
                                labels: Object.keys(data),
                                datasets: [{
                                    label,
                                    data: Object.values(data).map(valueExtractor),
                                    ...TRENDLINE_DATASET_DEFAULTS,
                                }],
                            }}
                            options={{
                                ...TRENDLINE_CHART_OPTIONS,
                                ...options,
                                plugins: {
                                    ...TRENDLINE_CHART_OPTIONS.plugins,
                                    ...options.plugins,
                                },
                            }}
                            height={75}
                        />
                    )}
                </Grid>
                <Grid item xs={4}>
                    {!loading && (
                        <Typography variant="h4" style={styleCaption(!!value)}>
                            {value
                                ? formatter(value)
                                : (
                                    <em style={{ fontSize: 12 }}>
                                        No value for
                                        <br />
                                        selected month
                                    </em>
                                )}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

TrendLine.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.object,
    loading: PropTypes.bool,
    currentValue: PropTypes.object,
    valueExtractor: PropTypes.func,
    formatter: PropTypes.func,
    options: PropTypes.object,
};

TrendLine.defaultProps = {
    valueExtractor: i => i,
    formatter: i => typeof i === 'number' ? (i).toLocaleString() : i,
    options: {},
};

export default TrendLine;
