import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Fade from '@mui/material/Fade'
import Grow from '@mui/material/Grow'
import { liveCoreInterval } from './liveUtils'
import LiveMainFeed from './LiveMainFeed'
import LiveCoreStats from './LiveCoreStats'
import LiveDailyIncomeGraph from './LiveDailyIncomeGraph'
import LiveTopStudiesTable from './LiveTopStudiesTable'
import LiveSalesTrendPunchCard from './LiveSalesTrendPunchCard'
import background from './media/background.png'

const PREFIX = 'LiveSheetMain';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    subItem: `${PREFIX}-subItem`,
    gridMainLeft: `${PREFIX}-gridMainLeft`,
    gridMainRightItem: `${PREFIX}-gridMainRightItem`,
    gridTop: `${PREFIX}-gridTop`,
    gridBottom: `${PREFIX}-gridBottom`,
    paper: `${PREFIX}-paper`
};

const StyledWrapperDiv = styled('div')(({ theme }) => ({
    [`&.${classes.wrapper}`]: {
		flex: 1,
		display: 'flex',
		background: '#FFFFFF',
	},

    [`& .${classes.root}`]: {
		flex: 1,
		display: 'flex',
		padding: theme.spacing(2),
		backgroundImage: `url(${background})`,
		// backgroundColor: `rgba(255, 255, 255, 0.4)`,
		// backgroundBlendMode: 'lighten',
		backgroundSize: 'cover',
		margin: theme.spacing(2),
		boxShadow: theme.shadows[2],
		borderRadius: 6,
        [theme.breakpoints.down('md')]: {
            overflowY: 'scroll',
            margin: 0,
        },
	},

    [`& .${classes.container}`]: {
		flex: 1,
		'& > div': {
			display: 'flex',
		},
	},

    [`& .${classes.subItem}`]: {
		display: 'flex',
		height: '100%',
	},

    [`& .${classes.gridMainLeft}`]: {
		// backgroundColor: 'rgba(208, 78, 212, 0.3)',
		// backgroundColor: 'rgba(138, 53, 142, 0.5)',
		backgroundImage: 'linear-gradient(to left, rgba(208, 78, 212, 0.2), rgba(71, 28, 76, 0.8))',
		// backgroundColor: 'rgba(255, 255, 255, 0.3)',
		// backgroundImage: `url(${require('./media/background_p2.png')})`,
		// backgroundSize: 'cover',
		boxShadow: `1px 1px 10px rgba(255, 255, 255, 0.3)`,
		overflow: 'hidden',
		borderRadius: '9px 0 0 9px',
		height: '100%',
        [theme.breakpoints.down('md')]: {
            height: '40%',
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
	},

    [`& .${classes.gridMainRightItem}`]: {
        [theme.breakpoints.up('md')]: {
            marginLeft: `-${theme.spacing(1)}`,
        },
	},

    [`& .${classes.gridTop}`]: {
		height: '55%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            minHeight: '50%',
        },
	},

    [`& .${classes.gridBottom}`]: {
		height: '45%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        },
	},

    [`& .${classes.paper}`]: {
		position: 'relative',
		overflow: 'hidden',
		flex: 1,
		overflowY: 'auto',
		scrollbarWidth: 'none',
		'-ms-overflow-style': 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	}
}));

const interval = liveCoreInterval / 4;

class LiveSheetMain extends Component {
	state = {
		page: 1,
		open: false,
	};

	render() {
		const {  rootScopeId } = this.props;
		const { open } = this.state;

		return(
			<StyledWrapperDiv className={classes.wrapper}>
				<div className={classes.root}>
					<Grid container spacing={2}>
						<Grid item md={3} xs={12} className={classes.gridMainLeft}>
							<LiveMainFeed rootScopeId={rootScopeId} />
						</Grid>
						<Grid item md={9} xs={12} className={classes.subItem}>
							<Grid container spacing={2} alignItems="stretch" className={classNames(classes.container, classes.gridMainRightItem)}>

								<Grid item md={4} xs={12} className={classes.gridTop}>
									<Grow in timeout={500}>
										<Paper className={classes.paper}>
											{this.renderPage(1)}
										</Paper>
									</Grow>
								</Grid>
								<Grid item md={4} xs={12} className={classes.gridTop}>
									<Grow in timeout={500}>
										<Paper className={classes.paper}>
											{this.renderPage(3)}
										</Paper>
									</Grow>
								</Grid>
								<Grid item md={4} xs={12} className={classes.gridTop}>
									<Fade in timeout={500}>
										<Paper className={classes.paper}>
											{this.renderPage(2)}
										</Paper>
									</Fade>
								</Grid>

								<Grid item xs={12} className={classes.gridBottom}>
									<LiveCoreStats rootScopeId={rootScopeId} />
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</div>
			</StyledWrapperDiv>
		);
	}

	renderPage(overwritePage){
		const { rootScopeId } = this.props;
		let { page } = this.state;

		if(overwritePage) page = overwritePage;

		// if(page === 1) return <TestGraph />;
		if(page === 1) return <LiveTopStudiesTable rootScopeId={rootScopeId} />;
		if(page === 2) return <LiveDailyIncomeGraph rootScopeId={rootScopeId} />;
		if(page === 3) return <LiveSalesTrendPunchCard rootScopeId={rootScopeId} />;

		return null;
	}

	componentDidMount(){
		this.timeout = setTimeout(this.onInterval, interval * 1000);
		this.setState({open: true});
	}

	componentWillUnmount(){
		clearTimeout(this.timeout);
	}

	onInterval = () => {
		this.setState({
			open: false
		});

		this.timeout = setTimeout(this.openFade, 500);
	}

	openFade = () => {
		this.setState({
			open: true,
			page: this.state.page === 1 ? 2 : 1
		});

		this.timeout = setTimeout(this.onInterval, interval * 1000);
	}
}

LiveSheetMain.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default (LiveSheetMain)
