import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
const PREFIX = 'PageHeader';

const classes = {
    pageHeader: `${PREFIX}-pageHeader`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.pageHeader}`]: {
		background: '#ffffff',
		marginTop: `-${theme.spacing(1)}`,
		marginLeft: `-${theme.spacing(1)}`,
		marginRight: `-${theme.spacing(1)}`,
		marginBottom: theme.spacing(2),
		padding: theme.spacing(3),
	}
}));

const PageHeader = ({  children }) => {
    return (
        <Root className={classes.pageHeader}>
			{children}
		</Root>
    );
};

PageHeader.propTypes = {
	children: PropTypes.node
};

export default (PageHeader)
