import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllClientProperties, getClientProperties, patchClientProperties } from '../../actions/clientActions';
import { getAllEmployeeProperties, getEmployeeProperties, patchEmployeeProfileProperties } from '../../actions/employeeActions';
import { useFetchData } from '../../helpers/hooks';
import { getPropertyByKey, propertyLabel, propertyValue } from '../../helpers/properties';
import withFetchData from '../../modules/fetchData/withFetchData';
import ModalItem from '../modal/ModalItem';
import ModalManager from '../modal/ModalManager';
import PageHeaderInfo from '../page/PageHeaderInfo';
import ProfileEditPropertiesModal from '../profile/ProfileEditPropertiesModal';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';

const contextActions = {
	client: {
		getPropertiesAction: getClientProperties,
		patchPropertiesAction: patchClientProperties,
		propertiesDefinitionActionId: 'ClientPropertiesDefinition',
		propertiesDefinitionAction: getAllClientProperties,
	},
	employee: {
		getPropertiesAction: getEmployeeProperties,
		patchPropertiesAction: patchEmployeeProfileProperties,
		propertiesDefinitionActionId: 'EmployeePropertiesDefinition',
		propertiesDefinitionAction: getAllEmployeeProperties,
	},
};

function PropertyPageHeaderInfo({ user, context, loading, error, data }) {
	const { t, i18n } = useTranslation();
	const inputProperties = useMemo(() => (
		user.properties === undefined ? data : (user.properties || {})
	), [user.properties, data]);
	const propertyArr = useMemo(() => inputProperties ? Object.entries(inputProperties) : [], [inputProperties]);
	const {
		propertiesDefinitionActionId,
		propertiesDefinitionAction,
		patchPropertiesAction,
	} = contextActions[context];
	const { data: propertiesDefinition, loading: loadingDefinition } = useFetchData(propertiesDefinitionActionId, propertiesDefinitionAction);

	const propertyItems = useMemo(
		() => propertyArr ? propertyArr
			.sort((a, b) => (a.key > b.key ? 1 : -1))
			.reduce((result, item) => {
				const property = getPropertyByKey(item[0], propertiesDefinition);
				if (property && item[1] !== null && typeof item[1] !== 'undefined') {
					result.push({
						label: propertyLabel(property, i18n.language),
						content: propertyValue(property, item[1], i18n.language),
					});
				}

				return result;
			}, []) : [],
		[propertyArr, propertiesDefinition, i18n.language],
	);

	const properties = useMemo(() => {
		if (inputProperties && propertiesDefinition) {
			const filteredProps = { ...inputProperties };
			Object.keys(filteredProps).forEach(prop => {
				if (!propertiesDefinition.find(entry => entry.key === prop)) {
					delete filteredProps[prop];
				}
			});
			return filteredProps;
		}
		return undefined;
	}, [inputProperties, propertiesDefinition]);

	if (error) {
		return <Alert type={Alert.TYPE_WARNING}>{t('errors.dataLoadError')}</Alert>;
	}

	if (loading || loadingDefinition || !propertiesDefinition || !data) {
		return (
			<Loader />
		);
	}

	return (
		<>
			<ModalManager>
				<ModalItem
					state="editProperties"
					component={ProfileEditPropertiesModal}
					user={user}
					properties={properties}
					context={context}
					patchPropertiesAction={patchPropertiesAction}
				/>
			</ModalManager>
			<PageHeaderInfo items={propertyItems} />
			{ propertyItems.length === 0 && (
				<div style={{ margin: '1rem 0 0', fontStyle: 'italic', }}>No properties set.</div>
			)}
		</>
	);
}

PropertyPageHeaderInfo.propTypes = {
	user: PropTypes.object.isRequired,
	context: PropTypes.oneOf(Object.keys(contextActions)).isRequired,
};

export default withFetchData(
	(props) => props.user.properties === undefined
		? contextActions[props.context].getPropertiesAction(props.user.id)
		: undefined,
	{ customId: () => 'ProfileProperties' },
)(PropertyPageHeaderInfo);
