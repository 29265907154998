import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Icon from '@mui/material/Icon'
import { niceDateTime } from '../../helpers/datetime'
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen'
import GroupOccupancy from '../util/GroupOccupancy'
import GroupVisibility from '../util/GroupVisibility'
import GroupProblemIndicator from '../util/GroupProblemIndicator'
import ResponsiveTable from '../util/ResponsiveTable'

const PREFIX = 'CourseGroupTable';

const classes = {
    row: `${PREFIX}-row`,
    cellRight: `${PREFIX}-cellRight`,
    noWrap: `${PREFIX}-noWrap`
};

const StyledResponsiveTable = styled(ResponsiveTable)(({ theme }) => ({
    [`& .${classes.row}`]: {
		cursor: 'pointer',
	},

    [`& .${classes.cellRight}`]: {
		textAlign: 'right',
	},

    [`& .${classes.noWrap}`]: {
		whiteSpace: 'nowrap',
	}
}));

const CourseGroupTable = ({ courseId, groups, fullScreen }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
    return (
        <StyledResponsiveTable>
			<TableHead>
				<TableRow>
					<TableCell>{t('general.name')}</TableCell>
					<TableCell>{t('general.occupancy')}{fullScreen && ' / start'}</TableCell>
					{!fullScreen && (
						<TableCell>{t('scope.course.start')}</TableCell>
					)}
					<TableCell>{t('scope.course.slots')}</TableCell>
					<TableCell>{t('scope.course.status')}</TableCell>
					{!fullScreen && (
						<TableCell>{t('scope.course.visibility')}</TableCell>
					)}
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{groups.map(group => (
					<TableRow key={group.id} hover
							  className={classes.row}
							  onClick={() => navigate(`/portal/planner/course/${courseId}/group/${group.id}`)}>
						<TableCell>
							<Link to={`/portal/planner/course/${courseId}/group/${group.id}`} onClick={(e) => e.preventDefault()}>
								{group.name}
							</Link>
							<GroupProblemIndicator group={group} />
						</TableCell>
						<TableCell>
							<GroupOccupancy group={group} showManipulated />
							{fullScreen && (
								<div className={classes.noWrap}>{niceDateTime(group.courseStart)}</div>
							)}
						</TableCell>
						{!fullScreen && (
							<TableCell>
								<div className={classes.noWrap}>{niceDateTime(group.courseStart)}</div>
							</TableCell>
						)}
						<TableCell>{group.numTimeSlots}</TableCell>
						<TableCell>
							{t(`groupStates.${group.state}.name`)}
							{fullScreen && (
								<GroupVisibility group={group} />
							)}
						</TableCell>
						{!fullScreen && (
							<TableCell><GroupVisibility group={group} /></TableCell>
						)}
						<TableCell className={classes.cellRight}>
							<Icon>chevron_right</Icon>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</StyledResponsiveTable>
    );
};

CourseGroupTable.propTypes = {
	courseId: PropTypes.number.isRequired,
	groups: PropTypes.array.isRequired,
};

export default withDialogShouldBeFullscreen()(CourseGroupTable);
