import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { getApiTypeSuccess } from '../../helpers/types'
import { fetchDataReplaceBy } from '../../modules/fetchData/fetchDataActions'
import { CALL_API } from '../../setup/api'
import AddressForm from '../util/AddressForm'

const GetApiAction = (orderId) => (address) => (dispatch) => {
	return dispatch({
		[CALL_API]: {
			type: 'SHIPPING_ADDRESS',
			endpoint: `order/${orderId}/shipping-address`,
			method: 'PATCH',
			body: address,
		},
	}).then(res => {
		// Yes I know, this is not the best most elegant way to handle updating the list
		// .. but there is not a much better way at the moment considering this data is reloaded a lot anyways
		if(res.type === getApiTypeSuccess('SHIPPING_ADDRESS')){
			const data = res.response.result;
			dispatch(fetchDataReplaceBy('CourseGroupOccupancy', 'orderId', {
				...data,
				orderId: data.id, // need the orderId for correct findByField reference
			}, 'shippingAddress', ''));
		}

		return res;
	});
}

const CourseGroupOccupancyClientAddressDialog = ({ client, orderId, address, onCancel }) => {
	const saveBind = useRef();
	const [saved, setSaved] = useState(false);

	const bindSave = (cb) => {
		saveBind.current = cb;
	};

	const handleSave = () => {
		saveBind.current && saveBind.current();
	};

	const handleSaved = () => {
		setSaved(true);
	};

    return (
		<Dialog open={true} maxWidth="md" fullWidth>
			<DialogTitle>
				Shipping address for {client.fullName} for this order
			</DialogTitle>
			<DialogContent>
				<AddressForm
					inputData={address}
					action={GetApiAction(orderId)}
					bindSave={bindSave}
					onSaved={handleSaved}
					successText="Address was successfully updated and saved."
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="secondary">
					{saved ? 'Close' : 'Cancel'}
				</Button>
				{!saved && (
					<Button
						onClick={handleSave}
						color="primary"
						variant="contained"
					>
						Save
					</Button>
				)}
			</DialogActions>
		</Dialog>
    );
};

CourseGroupOccupancyClientAddressDialog.propTypes = {
	client: PropTypes.object.isRequired,
	orderId: PropTypes.number.isRequired,
	address: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
};

export default CourseGroupOccupancyClientAddressDialog
