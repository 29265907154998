import { tooltipClasses } from '@mui/material';
import Popper from '@mui/material/Popper';
import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import EmployeeTooltipCard from './EmployeeTooltipCard';

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`&.${tooltipClasses.popper}`]: {
        opacity: 1,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: theme.palette.text.primary,
        maxWidth: 450,
        minWidth: 350,
        boxShadow: theme.shadows[2],
        padding: 0,
        fontSize: '0.9rem',
    },
}));

function EmployeeTooltip({ employeeId, ...rest }) {
    return (
        <Tooltip
            enterDelay={500}
            leaveDelay={300}
            disableTouchListener
            disableFocusListener
            placement="top-start"
            title={<EmployeeTooltipCard employeeId={employeeId} />}
            PopperComponent={StyledPopper}
            {...rest}
        />
    );
}

EmployeeTooltip.propTypes = {
    employeeId: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};

export default EmployeeTooltip;
