import * as queryString from 'query-string';
import { getApiTypeSuccess } from '../helpers/types';
import { fetchDataAppend, fetchDataDelete, fetchDataReplaceBy } from '../modules/fetchData/fetchDataActions';
import { CALL_API } from '../setup/api';

const CLAIM_FORMS_BY_EMPLOYEE = 'CLAIM_FORMS_BY_EMPLOYEE';
const CLAIM_FORMS_IN_SCOPE = 'CLAIM_FORMS_IN_SCOPE';
const CLAIM_FORM = 'CLAIM_FORM';

export const getClaimFormsByEmployee = (employeeId, statuses) => {
	const query = queryString.stringify({
		status: statuses,
	});
	return {
		[CALL_API]: {
			type: CLAIM_FORMS_BY_EMPLOYEE,
			endpoint: `employee/${employeeId}/claim-forms?${query}`,
		},
	};
};

export const getClaimFormsInScope = (scopeId, statuses) => {
	const query = queryString.stringify({
		status: statuses,
	});
	return {
		[CALL_API]: {
			type: CLAIM_FORMS_IN_SCOPE,
			endpoint: `scope/${scopeId}/claim-forms?${query}`,
		},
	};
};

export const CREATE_CLAIM_FORM = 'CREATE_CLAIM_FORM';
export const createClaimForm = (body) => (dispatch) => dispatch({
	[CALL_API]: {
		type: CREATE_CLAIM_FORM,
		endpoint: 'claim-form',
		method: 'POST',
		body,
		settings: {
			multipart: true,
		},
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(CREATE_CLAIM_FORM)) {
		dispatch(fetchDataAppend('EmployeeDeclarations', res?.response?.result));
	}
	return res;
});

export const editClaimForm = (body, claimFormId) => (dispatch) => dispatch({
	[CALL_API]: {
		type: CLAIM_FORM,
		endpoint: `claim-form/${claimFormId}`,
		method: 'PUT',
		body,
		settings: {
			multipart: true,
		},
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(CLAIM_FORM)) {
		const data = res.response.result;
		dispatch(fetchDataReplaceBy('EmployeeDeclarations', 'id', data));
	}

	return res;
});

export const approveDeclineClaimForm = (body, claimFormId) => (dispatch) => dispatch({
	[CALL_API]: {
		type: CLAIM_FORM,
		endpoint: `claim-form/${claimFormId}/approve-decline`,
		method: 'PUT',
		body,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(CLAIM_FORM)) {
		const data = res.response.result;
		dispatch(fetchDataReplaceBy('EmployeeDeclarations', 'id', data));
	}

	return res;
});

const CLAIM_FORM_ENTRIES = 'CLAIM_FORM_ENTRIES';
export const getClaimFormEntries = (claimFormId) => ({
	[CALL_API]: {
		type: CLAIM_FORM_ENTRIES,
		endpoint: `claim-form/${claimFormId}/entries`,
	},
});

export const deleteClaimFormEntry = (entry) => (dispatch) => dispatch({
	[CALL_API]: {
		type: CLAIM_FORM,
		endpoint: `claim-form-entry/${entry.id}`,
		method: 'DELETE',
	},
}).then(res => {
	dispatch(fetchDataDelete('EmployeeDeclarationEntries', entry));

	return res;
});

const CLAIM_FORM_ENTRY_ATTACHMENTS = 'CLAIM_FORM_ENTRY_ATTACHMENTS';
export const getClaimFormEntryAttachments = (claimFormEntryId) => ({
	[CALL_API]: {
		type: CLAIM_FORM_ENTRY_ATTACHMENTS,
		endpoint: `claim-form-entry/${claimFormEntryId}/attachments`,
	},
});

const DELETE_ENTRY_ATTACHMENT = 'DELETE_ENTRY_ATTACHMENT';
export const deleteEntryAttachment = (attachment) => (dispatch) => dispatch({
	[CALL_API]: {
		type: DELETE_ENTRY_ATTACHMENT,
		endpoint: `claim-form-attachment/${attachment.id}`,
		method: 'DELETE',
	},
}).then(res => {
	dispatch(fetchDataDelete('EmployeeDeclarationEntryAttachments', attachment));
	return res;
});
