import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import Alert from '../../../util/alert/Alert';
import CompanyFAQMarkdownEditor from './CompanyFAQMarkdownEditor';

const CompanyFAQItemModal = ({ topLevelScope, handleClose, modal }) => {
	const { t } = useTranslation();

	if (!topLevelScope) {
		return (
			<Alert>
				First select a scope in the scope selector at the top of the page.
			</Alert>
		);
	}

	return (
		<Dialog
			open={true}
			onClose={handleClose}
			maxWidth="md"
			fullWidth
			scroll="body"
		>
			<DialogTitle>
				{t('manage.company.faq.title', { name: topLevelScope.name })}
			</DialogTitle>
			<DialogContent>
				<CompanyFAQMarkdownEditor selectedItem={modal.selectedItem} handleClose={handleClose} />
			</DialogContent>
		</Dialog>
	);
};

export default withWorkingScope(CompanyFAQItemModal);
