import React from 'react'
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material'
const PREFIX = 'ColoredGrade';

const classes = {
    danger: `${PREFIX}-danger`,
    warning: `${PREFIX}-warning`
};

const StyledTypography = styled(Typography)(({ theme }) => ({
    [`&.${classes.danger}`]: { color: theme.palette.error.main },
    [`&.${classes.warning}`]: { color: theme.palette.warning.main }
}));

export const questionOrder = [
	'teacherConveyedSubject',
	'knowledgeForExam',
	'teacherSeenAsTop',
	'goodFeelingAboutCourse',
	'courseOrganisationExcellence',
]

export const getGradeColorClass = (grade) => {
	if(grade < 5.5) return 'danger';
	if(grade >= 8.0) return '';

	return 'warning';
}

export const ColoredGrade = styled(({ grade }) => {
	switch (true) {
		case grade < 5.5:
			return (
                <StyledTypography className={classes.danger}>
					{grade.toFixed(1)}
				</StyledTypography>
            );

		case grade >= 5.5 && grade < 8.0:
			return (
				<StyledTypography className={classes.warning}>
					{grade.toFixed(1)}
				</StyledTypography>
			)

		default:
			return (
				<StyledTypography>
					{grade.toFixed(1)}
				</StyledTypography>
			)
	}
})(({ theme }) => ({
	[`&.${classes.danger}`]: { color: theme.palette.error.main },
	[`& .${classes.warning}`]: { color: theme.palette.warning.main }
}))
