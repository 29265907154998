import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { formatMoney } from '../../helpers/formatter'
import { PropTypesMoney } from '../../helpers/money'

const PREFIX = 'PriceLabel';

const classes = {
    priceLabel: `${PREFIX}-priceLabel`
};

const Root = styled('span')(({ theme }) => ({
    [`&.${classes.priceLabel}`]: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		borderRadius: '8px',
		padding: `0 6px`,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		'&.smaller': {
			fontSize: '0.7em',
			verticalAlign: '0.15em',
		},
		'&.bad': {
			backgroundColor: theme.palette.error.main,
		},
		'&.good': {
			backgroundColor: theme.palette.success.main,
		},
	}
}));

const PriceLabel = ({ children, price, smaller, bad, good, }) => {
	return (
        <Root className={classNames(classes.priceLabel, {
			smaller: smaller,
			bad: bad,
			good: good,
		})}>
			{price ? formatMoney(price) : children}
		</Root>
    );
}

PriceLabel.propTypes = {
	price: PropTypesMoney,
	children: PropTypes.node,
	smaller: PropTypes.bool,
	bad: PropTypes.bool,
	good: PropTypes.bool,
};

export default (PriceLabel)
