/* eslint-disable react/prop-types, react/static-property-placement, consistent-return, no-nested-ternary, no-shadow, camelcase, react/sort-comp */
import { pickBy } from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as queryString from 'query-string';
import { denormalize } from 'normalizr';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { createSelector } from 'reselect';
import { updateRouteWithSearch } from '../../helpers/routing';
import { createArrayItemsEqualSelector } from '../../helpers/selectors';
import withRouter from '../../hoc/withRouter';
import { CALL_API } from '../../setup/api';
import { fetchDataInternalActions } from './fetchDataActions';

const defaultOptions = {
	customId: undefined,
	keepData: false,
	autoRefreshSeconds: false,
	mapEntities: false,
	mapStateToProps: () => ({}),
	makeMapStateToProps: false,
	pagination: false,
	paginationPageSize: 25,
	denormalize: undefined,
};

const selectorMapEntities = createArrayItemsEqualSelector(
	(success, selectedEntity) => success
		? Array.isArray(success)
			? success.map(id => selectedEntity[id])
			: selectedEntity[success]
		: success,
	(data) => data,
);

const selectorDenormalize = createArrayItemsEqualSelector(
	(success, denormalizeOptions, entities) => success && denormalize(success, denormalizeOptions, entities),
	(data) => data,
);

const counter = {};

/**
 * fetchData HOC, provides tools to fetch
 * Injects: {loading, fetchError, data, refresh, load, pagination, onPaginationChangePage, onPaginationChangePageSize}
 *
 * IMPORTANT: since Vite, a customId is required to prevent any bugs in production
 *
 * @class withFetchData
 * @param action func(props)
 * @param extraOptions object {
 * 	customId: func,
 * 	keepData bool,
 * 	autoRefreshSeconds int,
 * 	mapEntities string|false,
 * 	mapStateToProps func,
 * 	pagination bool,paginationPageSize int,
 * 	denormalize schema
 * }
 * @returns func(Component):Component
 */
const withFetchData = (action = null, extraOptions = {}) => ComposedComponent => {
	const options = { ...defaultOptions, ...extraOptions };

	const fetchDataId = options.customId
		? options.customId
		: () => (getDisplayName(ComposedComponent));

	const defaultPagination = {
		page: 0,
		pageSize: options.paginationPageSize,
		count: 0,
	};

	const pageFromLocationSelector = createSelector(
		(props) => props.location ? props.location.search : false,
		(props) => props.fetchPagination?.total,
		(search, total) => {
			if (search === false) {
				throw new Error('When using FetchData with paginationFromSearch, the HOC should be on a <Route> location or wrapped with withRouter');
			}

			const { page, pageSize } = queryString.parse(search, {
				parseNumbers: true,
			});

			// Combine default pagination and search pagination if set
			return {
				...defaultPagination,
				...pickBy((val) => val !== undefined, {
					page: page ? page - 1 : undefined,
					pageSize,
				}),
				total,
			};
		},
	);

	function actionWithPagination(actionObj, page, pageSize) {
		// Check if it is an object indeed
		if (!actionObj || typeof actionObj !== 'object' || !actionObj[CALL_API]) {
			throw new Error('When using pagination property with FetchData, the action can only be an object with CALL_API key.');
		}

		return {
			...actionObj,
			[CALL_API]: {
				...actionObj[CALL_API],
				pagination: {
					page: page || 0,
					pageSize: pageSize || options.paginationPageSize,
				},
			},
		};
	}

	const ConnectedFetchData = connect((state, props) => {
		const mapStateToProps = options.makeMapStateToProps && options.makeMapStateToProps(state, props);

		return (state, props) => {
			const generatedId = fetchDataId(props);
			const fetchData = state.fetchData[generatedId];

			return {
				...options.mapStateToProps(state, props),
				...(mapStateToProps ? mapStateToProps(state, props) : {}),
				...(fetchData ? {
					fetchLoading: fetchData.loading,
					fetchError: fetchData.error,
					fetchSuccess: fetchData.success,
					fetchPagination: fetchData.pagination,
					fetchCache: fetchData.cache,
					...(options.mapEntities ? {
						fetchSuccess: selectorMapEntities(fetchData.success, state.entities[options.mapEntities]),
					} : {}),
					...(options.denormalize ? {
						fetchSuccess: selectorDenormalize(fetchData.success, options.denormalize, state.entities),
					} : {}),
				} : {}),
			};
		};
	})(withRouter(class FetchData extends Component {
		static displayName = `withFetchData(${getDisplayName(ComposedComponent) || 'Unknown'})`;

		render() {
			const { fetchLoading, fetchError, fetchSuccess, fetchPagination, fetchCache, dispatch, ...rest } = this.props;
			const searchPagination = options.paginationFromSearch && pageFromLocationSelector(this.props);

			return (
				<ComposedComponent
					{...rest}
					loading={fetchLoading}
					error={fetchError}
					fetchError={fetchError}
					data={fetchSuccess}
					pagination={searchPagination || fetchPagination || defaultPagination}
					fetchCache={fetchCache}
					onPaginationChangePage={this.onPaginationChangePage}
					onPaginationChangePageSize={this.onPaginationChangePageSize}
					refresh={this.loadData}
					load={this.loadData}
				/>
			);
		}

		componentDidMount() {
			// Only call when there is an action already
			if (action) this.loadData();

			if (options.autoRefreshSeconds) {
				this.timer = setTimeout(
					this.refreshTimer, options.autoRefreshSeconds * 1000,
				);
			}
		}

		componentWillUnmount() {
			const { timer, props } = this;

			// Make sure to clear the data
			if (!options.keepData) {
				props.dispatch(fetchDataInternalActions.fetchDataClear(fetchDataId(props)));
			}

			if (timer) {
				clearTimeout(timer);
			}
		}

		loadData = (customAction = undefined, preventDataOverwrite = false, resetPage = false) => {
			const generatedId = fetchDataId(this.props);

			// Set the id for this request
			counter[generatedId] = (counter[generatedId] || 0) + 1;
			const counterId = counter[generatedId];

			let finalAction = customAction || (action ? action(this.props) : null);
			if (!finalAction) {
				if (!customAction && !action) {
					throw new Error(`No action defined for fetchData with id ${generatedId}`);
				}
				return false;
			}

			const { fetchPagination, dispatch, navigate } = this.props;
			const searchPagination = options.paginationFromSearch && pageFromLocationSelector(this.props);
			const finalPagination = {
				...(searchPagination || fetchPagination),
				...(resetPage ? {
					page: 0,
				} : {}),
			};

			if (options.paginationFromSearch && resetPage) {
				updateRouteWithSearch(navigate, { page: 1 });
			}

			// Inject the pagination options
			if (options.pagination) {
				finalAction = actionWithPagination(
					finalAction,
					finalPagination?.page,
					finalPagination?.pageSize,
				);
			}

			const promise = dispatch(finalAction);
			if (!promise) return false;

			dispatch(fetchDataInternalActions.fetchDataLoading(generatedId));

			return promise.then((res) => {
				// When preventing overwrite and counter did change due to multiple requests, ignore results
				if (preventDataOverwrite && counter[generatedId] !== counterId) return res;

				// Handle the error
				if (res && res.errorCode) {
					// Exclude 401 error and let other systems handle it
					if (res.errorCode !== 401) {
						return dispatch(fetchDataInternalActions.fetchDataError(generatedId, res));
					}
				} else {
					const pagination = res && res.response && res.response.pagination;

					// Potentially use pagination.total change to force a search page reset too
					// Update the page if the endpoint results did not agree with the requested page
					if (options.paginationFromSearch && navigate && finalPagination.page !== pagination.page) {
						updateRouteWithSearch(navigate, { page: pagination.page + 1 });
					}

					const result = res && res.response && res.response.result;
					const cache = res && res.response && res.response.cache;
					return dispatch(fetchDataInternalActions.fetchDataSuccess(generatedId, result || true, pagination || false, cache || false));
				}
			});
		};

		refreshTimer = () => {
			this.loadData();

			if (options.autoRefreshSeconds) {
				this.timer = setTimeout(
					this.refreshTimer, options.autoRefreshSeconds * 1000,
				);
			}
		};

		onPaginationChangePage = (e, page) => {
			const { navigate, dispatch } = this.props;
			if (options.paginationFromSearch && navigate) {
				return updateRouteWithSearch(navigate, { page: page + 1 });
			}
			return dispatch(fetchDataInternalActions.fetchDataPage(fetchDataId(this.props), page));
		};

		onPaginationChangePageSize = (e) => {
			const { navigate, dispatch } = this.props;
			if (options.paginationFromSearch && navigate) {
				return updateRouteWithSearch(navigate, { pageSize: e.target.value, page: 1 });
			}
			return dispatch(fetchDataInternalActions.fetchDataPageSize(fetchDataId(this.props), e.target.value));
		};
	}));

	return hoistNonReactStatics(ConnectedFetchData, ComposedComponent);
};

export default withFetchData;
