import React, { Fragment } from 'react'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

const ScopeTooltipScopeDefault = ({}) => {
	return (
		<Fragment>
			<CardContent>
				<Typography variant="body1" color="textSecondary">
					No quick summary available for this scope.
				</Typography>
			</CardContent>
		</Fragment>
	);
}

export default ScopeTooltipScopeDefault
