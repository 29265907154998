import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFromApi } from '../../setup/api';

const ButtonWrapper = React.forwardRef((props, ref) => {
	const { hasDownloadableContract, children, ...other } = props;
	if (hasDownloadableContract) {
		return (
			<span style={{ marginRight: 'auto' }} ref={ref} {...other}>{children}</span>
		);
	}
	return (
		<Badge badgeContent="!" color="secondary" sx={{ marginRight: 'auto' }} ref={ref} {...other}>
			{children}
		</Badge>
	);
});

ButtonWrapper.propTypes = {
	hasDownloadableContract: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
};

function ContractViewDownloadButton({ contract }) {
	const { t } = useTranslation();
	// Contracts signed before April 6th do not have a documentId
	const hasDownloadableContract = contract.documentId || moment(contract.signatureDate).isBefore(moment('2022-04-06', 'YYYY-MM-DD'));

	return (
		<Tooltip
			title={
				hasDownloadableContract
					? null
					: t('contract.view.employeeWithoutSignedContract')
			}
			placement="top"
		>
			<ButtonWrapper hasDownloadableContract={hasDownloadableContract}>
				<Button
					onClick={() => downloadFromApi(`contracts/${contract.id}/contract.pdf`)}
					disabled={!hasDownloadableContract}
				>
					<Icon>cloud_download</Icon>&nbsp;
					{t('contract.view.downloadContract')}
				</Button>
			</ButtonWrapper>
		</Tooltip>
	);
}

ContractViewDownloadButton.propTypes = {
	contract: PropTypes.object.isRequired,
};

export default ContractViewDownloadButton;
