import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCourseSkuReferences } from '../../../actions/skuReferencesActions';
import { useFetchData } from '../../../helpers/hooks';
import { fetchDataInternalActions } from '../../../modules/fetchData/fetchDataActions';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';
import CourseSkuReferencesTable from './CourseSkuReferencesTable';

function CourseSkuReferences({ course }) {
    const { data, loading, error } = useFetchData('CourseSkuReferences', () => getCourseSkuReferences(course.id));
    const dispatch = useDispatch();

    useEffect(() => () => {
        dispatch(fetchDataInternalActions.fetchDataClear('CourseSkuReferences'));
    }, [dispatch]);

    if (error) {
        return <ApiError error={error} />;
    }
    if (loading) {
        return <Loader sheet />;
    }
    return <CourseSkuReferencesTable values={data} />;
}

CourseSkuReferences.propTypes = {
    course: PropTypes.object.isRequired,
};

export default CourseSkuReferences;
