import { chipClasses, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContractTemplateStatuses from '../../../../constants/ContractTemplateStatuses';

const StyledChip = styled(Chip)(({ theme }) => ({
	color: 'white',
	margin: theme.spacing(1),
	height: '100%',
	display: 'inline-flex',
	flexDirection: 'column',
	[`& .${chipClasses.label}`]: {
		overflowWrap: 'break-word',
		whiteSpace: 'normal',
		textOverflow: 'clip',
		padding: theme.spacing(1, 2),
		textAlign: 'center',
	},
}));

const EditFunctionTemplateStatusChip = ({ template }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const {
		NEW,
		MISSING_REQUIRED_PROPERTIES_IN_DOCUMENT,
		MISSING_SIGNATURE_PLACEHOLDER,
		VALIDATED,
	} = ContractTemplateStatuses;
	let color;
	let text = t(`manage.company.functionTemplateStatus.${template.validationStatus}`);
	switch (template.validationStatus) {
		case MISSING_REQUIRED_PROPERTIES_IN_DOCUMENT:
		case MISSING_SIGNATURE_PLACEHOLDER:
			color = theme.palette.error.main;
			break;
		case NEW:
			color = theme.palette.warning.main;
			break;
		case VALIDATED:
			color = theme.palette.success.main;
			break;
		default:
			color = theme.palette.grey.A200;
			text = t('manage.company.functionTemplateStatus.unknown');
			break;
	}
	return (
		<StyledChip
			sx={{
				backgroundColor: color,
			}}
			label={text}
		/>
	);
};

EditFunctionTemplateStatusChip.propTypes = {
	template: PropTypes.object.isRequired,
};

export default EditFunctionTemplateStatusChip;
