import Box from '@mui/material/Box';
import moment from 'moment/moment';
import React, { Fragment } from 'react'
import { compose, groupBy } from 'ramda'
import {startOfMonth, subMonths} from 'date-fns'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import { paymentStatus } from '../../../helpers/payment';
import withFetchData from '../../../modules/fetchData/withFetchData'
import withWorkingScope from '../../../modules/scope/withWorkingScope'
import { workingScopesNameString } from '../../../helpers/scope'
import { capabilities, hasScopeCapability } from '../../../helpers/capabilities'
import { getPayments } from '../../../actions/payment'
import { Schemas } from '../../../actions/schemas'
import TablePaginationActions, { StyledTablePagination } from '../../table/TablePaginationActions';
import FilterValuesPicker from '../../util/FilterValuesPicker';
import Loader from '../../util/loader/Loader'
import Alert from '../../util/alert/Alert'
import PageHeader from '../../page/PageHeader'
import PageHeaderTitle from '../../page/PageHeaderTitle'
import ModalManager from '../../modal/ModalManager'
import ModalItem from '../../modal/ModalItem'
import YearMonthPicker from './YearMonthPicker'
import RejectPaymentForm from './RejectPaymentForm'
import BatchCard from './BatchCard'

let byMonthYear = groupBy((payment) => {
	return payment.computedOver;
});

const RejectPaymentModal = ({handleClose, modal, onCrudUpdate}) => {
	return <RejectPaymentForm payment={modal.payment} handleClose={handleClose} onCrudUpdate={onCrudUpdate}/>
};

const MonthHeader = ({ children }) => (
	<Box sx={{ mb: 1 }}>
		<Typography variant="h6" color="textSecondary">
			{children}
		</Typography>
	</Box>
);

const minDate = moment('2015-01', 'YYYY-MM');
const maxDate = moment(startOfMonth(new Date())).add(1, 'years');

const paymentStatuses = Object.values(paymentStatus);

/*
* t('financial.batchCheck.status.RECKONING')
* t('financial.batchCheck.status.APPROVED')
* t('financial.batchCheck.status.IN_TRANSFER')
* t('financial.batchCheck.status.PAID')
* t('financial.batchCheck.status.CANCELLED')
* t('financial.batchCheck.status.DISAPPROVED')
* */

class BatchCheck extends React.Component {
	state = {
		selectedDate: subMonths(startOfMonth(new Date()), 1),
		selectedStatuses: [],
	};

	componentDidUpdate(prevProps, prevState) {
		const { workingScope, pagination } = this.props;
		const { selectedDate, selectedStatuses } = this.state;

		if (!workingScope) return;
		let updateRequired = !prevProps.workingScope;
		updateRequired = updateRequired || (prevProps.workingScope && prevProps.workingScope.id !== workingScope.id);
		updateRequired = updateRequired || (prevProps.pagination.page !== pagination.page);
		updateRequired = updateRequired || (prevProps.pagination.pageSize !== pagination.pageSize);
		updateRequired = updateRequired || (prevState.selectedDate !== selectedDate);
		updateRequired = updateRequired || (prevState.selectedStatuses !== selectedStatuses);

		const resetPage = (prevProps.workingScope && prevProps.workingScope.id !== workingScope.id)
			|| prevState.selectedDate !== selectedDate
			|| prevState.selectedStatuses !== selectedStatuses;

		if (updateRequired) this.fetchData(resetPage);
	}

	fetchData(resetPage = true) {
		const { workingScope, load } = this.props;
		const { selectedDate, selectedStatuses } = this.state;

		if (!workingScope) return;

		load(getPayments(workingScope.id, selectedDate, selectedStatuses), false, resetPage);
	}

	render() {
		const { workingScopes, loading } = this.props;

		return (
			<Fragment>
				<ModalManager>
					<ModalItem state="reject-payment" component={RejectPaymentModal}/>
				</ModalManager>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PageHeader>
							<PageHeaderTitle
								headline="Check payout batch"
								subHeading={workingScopesNameString(workingScopes)}
							/>
							<Grid container spacing={2}>
								<Grid item xs={12} style={{ display: 'flex', alignItems: 'flex-end', marginTop: '1rem' }}>
									<YearMonthPicker
										selectedDate={this.state.selectedDate}
										onChange={(selectedDate) => this.setState({ selectedDate })}
										minDate={minDate}
										maxDate={maxDate}
									/>
									<FilterValuesPicker
										options={paymentStatuses}
										selected={this.state.selectedStatuses}
										onChange={(selectedStatuses) => this.setState({ selectedStatuses })}
										i18nKey="financial.batchCheck.status"
										loading={loading}
										sx={{ ml: 2 }}
									/>
								</Grid>
							</Grid>
						</PageHeader>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{this.renderTable()}
					</Grid>
				</Grid>
			</Fragment>
		);
	}

	renderTable() {
		const { loading, error, data, pagination, onPaginationChangePage, onPaginationChangePageSize, workingScope } = this.props;
		const payments = byMonthYear(data || []);

		if(loading) return (
			<Loader/>
		);

		if(error) return (
			<Alert type={Alert.TYPE_WARNING}>{error.errorCode === 403 ?
				'Sorry, you may not view the batch of this scope.' :
				'Sorry, we could not load the data. Try refreshing the page.'
			}</Alert>
		);

		if(!data || data.length === 0) return (
			<Alert type={Alert.TYPE_INFO}>No payments found within this scope, period and/or payment status.</Alert>
		);

		return (
			<Fragment>
				{Object.keys(payments).sort().map(entry => (
					<Fragment key={entry}>
						<MonthHeader>{entry}</MonthHeader>
						{payments[entry].map(payment => (
							<BatchCard
								data={payment}
								key={payment.id}
								canMarkAsPaid={hasScopeCapability(workingScope, capabilities.AdministratorOverride)}
							/>
						))}
					</Fragment>
				))}
				{pagination && pagination.total && <Table>
					<TableFooter>
						<TableRow>
							<StyledTablePagination
								count={pagination.total}
								rowsPerPage={pagination.pageSize}
								page={pagination.page}
								onPageChange={onPaginationChangePage}
								onRowsPerPageChange={onPaginationChangePageSize}
								rowsPerPageOptions={[10, 25, 50, 100, 250]}
								ActionsComponent={props => <TablePaginationActions onPaginationChangePage={onPaginationChangePage} {...props} />}
							/>
						</TableRow>
					</TableFooter>
				</Table>}
			</Fragment>
		)
	}
}

export default compose(
	withWorkingScope,
	withFetchData(null, {
		denormalize: Schemas.EMPLOYEE_PAYMENT_LIST,
		pagination: true,
		paginationPageSize: 100,
		paginationFromSearch: true,
	}),
)(BatchCheck);
