import { badgeClasses, Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PREFIX = 'FilterValuesPicker';

const classes = {
    button: `${PREFIX}-button`,
    filterMenu: `${PREFIX}-filterMenu`
};

const StyledMenu = styled(Menu)(({ theme }) => ({
    [`&.${classes.filterMenu}`]: {
		'& .MuiPopover-paper': {
			border: '1px solid #ccc',
			minWidth: '200px',
		},
		'& .MuiListItemIcon-root': {
			minWidth: '35px',
		},
		'& .MuiListItem-root': {
			padding: 0,
		},
		'& .MuiButtonBase-root': {
			padding: theme.spacing(0.5, 1.5),
		},
	}
}));

const StyledBadge = styled(Badge)({
	[`& .${badgeClasses.badge}`]: {
		top: '5px',
		right: '5px',
		height: '16px',
		minWidth: '16px',
		fontSize: '0.65rem',
	},
});

function FilterValuesPicker({ onChange, options, selected, i18nKey, loading, ...rest }) {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState();


	const handleFilterToggle = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (optionParam) => () => {
		const newArr = [...selected];
		if (newArr.includes(optionParam)) {
			newArr.splice(newArr.indexOf(optionParam), 1);
		} else {
			newArr.push(optionParam);
		}
		onChange(newArr);
	};
	const allSelected = selected.length === options.length;
	const handleSelectAll = () => {
		if (allSelected) {
			return onChange([]);
		}
		return onChange(options);
	};

	return (
        (<>
            <Button
				startIcon={(
					<StyledBadge
						badgeContent={selected.length}
						color="secondary"
					>
						<Icon sx={{ mr: 1 }}>filter_list</Icon>
					</StyledBadge>
				)}
				endIcon={<Icon>{`keyboard_arrow_${anchorEl ? 'up' : 'down'}`}</Icon>}
				onClick={handleFilterToggle}
				variant="outlined"
				{...rest}
			>
				{t(`${i18nKey}Filter`)}
			</Button>
            <StyledMenu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				keepMounted
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				className={classes.filterMenu}
				onClose={handleClose}
			>
				<List dense disablePadding>
					<ListItem disablePadding>
						<ListItemButton dense onClick={handleSelectAll} disabled={loading}>
							<ListItemIcon>
								<Icon>{allSelected ? 'clear' : 'select_all'}</Icon>
							</ListItemIcon>
							<ListItemText
								primary={allSelected ? t('general.clear_all') : t('general.select_all')}
							/>
						</ListItemButton>
					</ListItem>
					{options
						.map(option => (
							<ListItem disablePadding key={option}>
								<ListItemButton dense onClick={handleClick(option)} disabled={loading}>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={selected.includes(option)}
											tabIndex={-1}
											disableRipple
											inputProps={{ 'aria-labelledby': option }}
											size="small"
										/>
									</ListItemIcon>
									<ListItemText id={option} primary={t(`${i18nKey}.${option}`)} />
								</ListItemButton>
							</ListItem>
						))}
				</List>
			</StyledMenu>
        </>)
    );
}

FilterValuesPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.string),
	selected: PropTypes.array.isRequired,
	i18nKey: PropTypes.string.isRequired,
	loading: PropTypes.bool,
};
export default FilterValuesPicker;
