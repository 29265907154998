import { Schemas, CALL_API } from '../../actions/schemas'

export const AUTH = 'AUTH';
const postAuth = (data) => ({
	[CALL_API]: {
		type: AUTH,
		endpoint: 'auth',
		body: {
			username: data.username,
			password: data.password
		}
	}
});

export const doLogin = (data) => {
	if(!data.username || !data.password) return null;
	return postAuth(data);
};

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const doLogout = () => ({
	type: AUTH_LOGOUT,
	manualLogout: true
});

export const AUTH_ME = 'AUTH_ME'
export const getMe = () => {
	return {
		[CALL_API]: {
			type: AUTH_ME,
			endpoint: 'me'
		}
	};
};

export const AUTH_LOST = 'AUTH_LOST';
export const requestLostPassword = (data) => {
	if(!data.email) return;

	return {
		[CALL_API]: {
			type: AUTH_LOST,
			endpoint: `employee/lost-password`,
			body: data
		}
	}
}

export const resetLostPassword = (data) => {
	if(!data.resetCode || !data.password) return;

	return {
		[CALL_API]: {
			type: AUTH_LOST,
			endpoint: `employee/reset-password?resetCode=${data.resetCode}`,
			body: {
				password: data.password
			}
		}
	}
}
