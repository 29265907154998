import PropTypes from 'prop-types'
import { groupBy, values } from 'ramda'
import { formatMoneyAuto } from './formatter'

export const PropTypesMoney = PropTypes.shape({
	amount: PropTypes.number.isRequired,
	currency: PropTypes.string.isRequired,
});

export const currencyFromList = (moneyList, defaultCurrency = 'EUR') => {
	if(!moneyList) return defaultCurrency;

	const filtered = moneyList.filter(m => m && m.currency);
	if(filtered.length === 0) return defaultCurrency;

	return filtered[0].currency;
}

export const moneySumList = (moneyList, defaultCurrency = 'EUR') => {
	if(moneyList.length === 0) return {
		amount: 0,
		currency: defaultCurrency,
	};

	return {
		amount: moneyList.reduce((tot, m) => tot + (m ? m.amount || 0 : 0), 0),
		currency: currencyFromList(moneyList),
	};
}

export const formatMoneySum = (moneyList, formatter = formatMoneyAuto) => {
	if(moneyList.length === 0) return 0;

	return formatter(moneySumList(moneyList));
}

export const formatMoneySumCurrencyGrouped = (moneyList, formatter = formatMoneyAuto) => {
	if(moneyList.length === 0) return 0;

	const grouped = groupBy((m) => m.currency)(moneyList);

	return values(grouped).map(list => formatter(moneySumList(list))).join(' / ');
}

export const moneyMinus = (a, b) => {
	return {
		amount: (a ? a.amount || 0 : 0) - (b ? b.amount || 0 : 0),
		currency: a ? a.currency : b.currency,
	};
}

export const moneyZero = {
	amount: 0,
	currency: '',
}
