import PropTypes from 'prop-types';
import * as React from 'react';
import { NavLink as BaseNavLink } from 'react-router-dom';

const NavLink = React.forwardRef(
    ({ activeClassName, activeStyle, ...props }, ref) => (
        <BaseNavLink
            ref={ref}
            {...props}
            className={({ isActive }) => [
                props.className,
                isActive ? activeClassName : null,
            ]
                .filter(Boolean)
                .join(' ')}
            style={({ isActive }) => ({
                ...props.style,
                ...(isActive ? activeStyle : null),
            })}
        />
    ),
);

NavLink.propTypes = {
    activeClassName: PropTypes.string,
    activeStyle: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default NavLink;
