import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { capabilities, hasScopeCapability } from '../../helpers/capabilities';
import { scopeHasAbbreviation, scopeParentsFromStoreFull } from '../../helpers/scope';
import { formatMoney } from '../../helpers/formatter';
import { createArrayItemsEqualSelector } from '../../helpers/selectors';
import Alert from '../util/alert/Alert';
import PropertiesTable from '../util/PropertiesTable';
import ScopePickerCourseList from '../scope/picker/ScopePickerCourseList';
import MiniScopeSelect from './MiniScopeSelect';

const PREFIX = 'OrderOptionsSwapCourseModal';

const classes = {
    root: `${PREFIX}-root`,
    alert: `${PREFIX}-alert`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.alert}`]: {
		margin: theme.spacing(5, 0),
	}
}));

const BreadcrumbSpan = styled(({ ...props }) => (
	<span className={classes.root} {...props} />
))(({ theme }) => ({
    [`&.${classes.root}`]: {
		'&:not(:last-child)::after': {
			display: 'inline-block',
			content: '">"',
			margin: theme.spacing(0, 1),
		},
	}
}))

function createPickerList(scopeList, onChange, t) {
	return scopeList.map((scope, i) => {
		const selected = scopeList[i + 1];
		const canPick = scope._virtual || hasScopeCapability(scope, capabilities.SeeScopeCapability);

		if (canPick && (scope._class === 'Institute' || scope._class === 'Faculty')) return (
            <BreadcrumbSpan
                key={scope.id}
                classes={{
                    root: classes.root
                }}>
				<MiniScopeSelect scope={scope} selected={selected} onChange={onChange} index={i} />
			</BreadcrumbSpan>
        );

		if (!selected && scope._class === 'Course') return (
            <BreadcrumbSpan
                key={scope.id}
                classes={{
                    root: classes.root
                }}>
				{t('order.swap.picker.pickGroupBelow')}
			</BreadcrumbSpan>
        );

		if (!selected) return null;

		return (
            <BreadcrumbSpan
                key={scope.id}
                classes={{
                    root: classes.root
                }}>
				{scopeHasAbbreviation(selected) ? selected.abbreviation : selected.name}
			</BreadcrumbSpan>
        );
	});
}

const getList = (state, props) => props;
const getScopeEntities = (state) => state.entities.scopes;
const scopeListSelector = createArrayItemsEqualSelector(createSelector(
	[getList, getScopeEntities],
	(list, scopes) => (
		list.map(id => scopes[id])
	),
), data => data);

function OrderOptionsSwapCourseModal({ group, client, handleClose, onSelectGroup }) {
	const { t } = useTranslation();

	const parents = scopeParentsFromStoreFull(group.id);
	const [pickerList, setPickerList] = useState(parents.map(scope => scope.id));

	const scopeList = useSelector((state) => scopeListSelector(state, pickerList));

	const course = parents[4];
	const study = scopeList[3];

	const handleChange = useCallback((scope, i) => {
		setPickerList(list => [...list.slice(0, i + 1), scope.id]);
	}, [setPickerList]);

	const properties = [
		{
			label: t('order.swap.properties.currentGroup'),
			content: createPickerList(scopeList, handleChange, t),
		},
		{
			label: t('order.swap.properties.coursePrice'),
			content: formatMoney(course.price),
		},
	];

	return (
        <StyledDialog open={true} onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle>
				{t('order.swap.dialogTitle', {
					fullName: client.fullName,
				})}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{t('order.swap.dialogDescription')}
				</DialogContentText>
				<PropertiesTable items={properties} />
				{study ? (
					<ScopePickerCourseList study={study} onSelectGroup={onSelectGroup} />
				) : (
					<Alert className={classes.alert}>
						{t('order.swap.selectStudyFirst')}
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					{t('order.swap.close')}
				</Button>
			</DialogActions>
		</StyledDialog>
    );
}

OrderOptionsSwapCourseModal.propTypes = {
	handleClose: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	onSelectGroup: PropTypes.func.isRequired,
};

export default OrderOptionsSwapCourseModal;
