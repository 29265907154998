import { getApiTypeSuccess } from '../helpers/types';
import { fetchDataInternalActions } from '../modules/fetchData/fetchDataActions';
import { CALL_API } from '../setup/api';

const GET_COURSE_SKU_REFS = 'GET_COURSE_SKU_REFS';

export const getCourseSkuReferences = scopeId => dispatch => dispatch({
	[CALL_API]: {
		type: GET_COURSE_SKU_REFS,
		endpoint: `athenastudies-course/${scopeId}/magento-sku`,
		method: 'GET',
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(GET_COURSE_SKU_REFS)) {
		const data = res.response.result;
		dispatch(fetchDataInternalActions.fetchDataSuccess('CourseSkuReferences', data));
	}

	return res;
});


const POST_COURSE_SKU_REFS = 'POST_COURSE_SKU_REFS';

export const postCourseSkuReferences = (scopeId, list) => (dispatch) => dispatch({
	[CALL_API]: {
		type: POST_COURSE_SKU_REFS,
		endpoint: `athenastudies-course/${scopeId}/magento-sku`,
		method: 'POST',
		body: list,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(POST_COURSE_SKU_REFS)) {
		const data = res.response.result;
		dispatch(fetchDataInternalActions.fetchDataSuccess('CourseSkuReferences', data));
	}

	return res;
});
