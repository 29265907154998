import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import { redirectFromApi } from '../../../setup/api'

const PREFIX = 'ZoomMeetingRedirectHostStart';

const classes = {
    icon: `${PREFIX}-icon`
};

const StyledButton = styled(Button)(({ theme }) => ({
    [`& .${classes.icon}`]: {
		marginRight: theme.spacing(1),
	}
}));

const redirectToZoomStart = (timeSlotId, token) => {
	redirectFromApi(`course-timeslot/${timeSlotId}/zoom-us-meeting/start?token=${token}`)
};

const ZoomMeetingRedirectHostStart = ({  timeSlot, label, auth }) => {
    return (
        <StyledButton
			variant="outlined"
			onClick={() => redirectToZoomStart(timeSlot.id, auth.tokenValue)}
		>
			<Icon className={classes.icon}>videocam</Icon>
			{label || 'Go to meeting'}
		</StyledButton>
    );
};

ZoomMeetingRedirectHostStart.propTypes = {
	timeSlot: PropTypes.object.isRequired,
	label: PropTypes.string,
};

export default (connect((state) => ({
	auth: state.auth,
}))(ZoomMeetingRedirectHostStart))
