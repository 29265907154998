import { getUserToken } from '../actions/meActions';
import { notificationShow } from '../modules/notification/notificationActions';
import store from '../store';

const calendarServices = {
	getLiveICalendarUrl(iCalUrl) {
		if (!navigator.clipboard) {
			alert('Your browser doesn\'t support copying to clipboard. Please update your browser.');
			return;
		}

		store.dispatch(getUserToken(`/${iCalUrl}`))
			.then((res) => {
				const { response } = res;
				if (response?.result) {
					// Note that this way of accessing the API url might not be stable - in the future this might have to change
					navigator.clipboard.writeText(`${window.location.origin}/api${response.result.uri}?token=${response.result.id}`);
					store.dispatch(
						notificationShow('iCal URL successfully copied to clipboard', 'success'),
					);
				} else {
					store.dispatch(
						notificationShow('Error creating url token. Please contact support.', 'error'),
					);
				}
			});
	},
};

export default calendarServices;
