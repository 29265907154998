import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '@mui/material/Icon'
const PREFIX = 'ScopeInvisibilityIndicator';

const classes = {
    icon: `${PREFIX}-icon`
};

const StyledIcon = styled(Icon)(({ theme }) => ({
    [`&.${classes.icon}`]: {
		fontSize: '120%',
		color: theme.palette.error.main,
		'&.right': {
			float: 'right',
			marginRight: theme.spacing(1),
			marginTop: 5,
		},
		'&.big': {
			fontSize: '160%',
			marginTop: 0,
		},
		'&.middle': {
			marginLeft: theme.spacing(1),
			fontSize: '1em',
			verticalAlign: '-0.2em',
		},
	}
}));

const ScopeInvisibilityIndicator = ({ scope, right, big, middle, }) => {
	const visible = scope.published === 'published';

	if(visible) return null;

	const iconClasses = classNames(classes.icon, {
		right: Boolean(right),
		big: Boolean(big),
		middle: Boolean(middle),
	})

    return (
        <StyledIcon className={iconClasses}>
			visibility_off
		</StyledIcon>
    );
};

ScopeInvisibilityIndicator.propTypes = {
	scope: PropTypes.object.isRequired,
	right: PropTypes.bool,
	big: PropTypes.bool,
	middle: PropTypes.bool,
};

export default (ScopeInvisibilityIndicator)
