import React, { useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '../util/alert/Alert'
import BundleAddForm from './BundleAddForm'

const BundleAddModal = ({ modal, handleClose }) => {
	const form = useRef(null);
	const [ savable, setSavable ] = useState(false);

	return (
		<Dialog open onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle>
				Add product bundle
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					A product bundle is a combination of different courses within one study. When bought together, the customer will get the specified discount. These bundles will be extra visible and are easy to buy for the customer with one click. You must pick at least two courses now. Later you can add more courses or remove some. Note that product bundles will only be visible for customers if all their courses are visible with at least one group which is not full.
				</DialogContentText>
				<br/>
				{modal.id ? (
					<BundleAddForm id={modal.id} courseId={modal.courseId} refFormSubmit={form} setSavable={setSavable} />
				) : (
					<Alert type={Alert.TYPE_DANGER}>
						For some reason there was no scope id provided. Contact a developer.
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					variant="outlined"
					color="secondary"
				>
					{savable ? 'Cancel' : 'Close' }
				</Button>
				{savable && (
					<Button
						onClick={() => {
							form.current && form.current();
						}}
						variant="contained"
						color="primary"
					>
						Add product bundle
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default BundleAddModal
