import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { returnDefaultValue } from '../../helpers/properties';
import withFormData from '../../modules/formData/withFormData';
import PropertySelectorTable from '../properties/PropertySelectorTable';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';

function ProfileEditPropertiesForm({
	bindSave,
	watchSubmit,
	inputData,
	success,
	onSave,
	properties,
	patchProfileProperties,
	context,
	loading,
	saving,
	formError,
}) {
	const [newProps, setNewProps] = useState(properties);

	const handlePropChange = useCallback(propKey => (event, directValue) => {
		const { value, checked } = event.target;
		let valueToUse = value;
		if (event.target.type === 'checkbox') {
			valueToUse = checked;
		}
		if (Array.isArray(directValue)) {
			valueToUse = directValue;
		}
		if (event.target.type === 'number') {
			valueToUse = parseFloat(value);
		}
		setNewProps({
			...newProps,
			[propKey]: valueToUse,
		});
	}, [newProps]);

	const handlePropAdd = prop => () => {
		setNewProps({
			...newProps,
			[prop.key]: returnDefaultValue(prop),
		});
	};

	const handlePropDelete = propKey => () => {
		setNewProps({ ...newProps, [propKey]: null });
	};

	useEffect(() => {
		bindSave(() => {
			watchSubmit(patchProfileProperties(inputData.id, newProps));
		});
	}, [bindSave, inputData.id, newProps, watchSubmit]);

	useEffect(() => {
		if (success && onSave) {
			onSave();
		}
	}, [onSave, success]);

	return (
		<>
			{(loading || saving) && (
				<Loader sheet />
			)}
			{formError && (
				<ApiError error={formError} />
			)}
			<PropertySelectorTable
				handlePropAdd={handlePropAdd}
				handlePropChange={handlePropChange}
				handlePropDelete={handlePropDelete}
				properties={newProps}
				context={context}
			/>
		</>
	);
}

ProfileEditPropertiesForm.propTypes = {
	inputData: PropTypes.object,
	properties: PropTypes.object,
	patchProfileProperties: PropTypes.func,
	context: PropTypes.string.isRequired,
};

export default compose(
	withFormData(),
)(ProfileEditPropertiesForm);
