import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { withTranslation } from 'react-i18next';
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen'
import ProfileEditBankForm from './ProfileEditBankForm'

class ProfileEditBankModal extends Component {
	saveBinder = null;

	render(){
		const { user, handleClose, fullScreen, t } = this.props;

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth fullScreen={fullScreen}>
				<DialogTitle>{t('profile.editBankAndId')}</DialogTitle>
				<DialogContent>
					<ProfileEditBankForm inputData={user}
										 bindSave={(func) => this.saveBinder = func}
										 onSave={handleClose} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						{t('general.cancel')}
					</Button>
					<Button onClick={() => {if(this.saveBinder) this.saveBinder()}}
							color="primary"
							variant="contained">
						{t('general.save')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withTranslation()(withDialogShouldBeFullscreen()(ProfileEditBankModal))
