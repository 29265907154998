import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import withWorkingScope from '../../../modules/scope/withWorkingScope'
import { hasScopeCapability, capabilities } from '../../../helpers/capabilities'
import ScopeSelectorWorking from './ScopeSelectorWorking'
import ScopeSelectorTopLevel from './ScopeSelectorTopLevel'

const deepestScopeClass = 'AthenaStudiesCourse';

const ScopeSelector = ({ workingScopes, topLevelScopes, topLevelScope, workingScopeSelect, workingScopeClear, topLevelScopeSelect, topLevelScopeClear }) => {
	const selectors = [];

	// Show top level scope selector if multiple available
	// ... and none or one working scope is selected
	// ... removed && workingScopes.length < 2 because it blocked resetting the company if company is not a root
	if(topLevelScopes && topLevelScopes.length > 1){
		selectors.push(
			<ScopeSelectorTopLevel key="root"
								   options={topLevelScopes}
								   selected={topLevelScope}
								   onSelect={topLevelScopeSelect}
								   onClear={topLevelScopeClear} />
		)
	}

	for(let i = 0; i < workingScopes.length; i++){
		const scope = workingScopes[i];

		if(scope._class === deepestScopeClass) break;

		let selected = null;
		if((i + 1) < workingScopes.length){
			selected = workingScopes[i + 1];
		}

		if(!scope._virtual && !hasScopeCapability(scope, capabilities.SeeScopeCapability)) break;

		selectors.push(
			<ScopeSelectorWorking key={scope.id}
								  scope={scope}
								  position={i + 1}
								  selected={selected}
								  onSelect={workingScopeSelect}
								  onClear={workingScopeClear} />
		);
	}

	for(let j = 0; j < (4 - workingScopes.length); j++){
		selectors.push(<Grid key={`extra-${j}`} item xs />);
	}

	return (
		<Grid container>
			{selectors}
		</Grid>
	)
}

ScopeSelector.propTypes = {
	small: PropTypes.bool,
};

export default withWorkingScope(ScopeSelector)
