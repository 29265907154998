export default {
	CANCELED: 'Canceled',
	EXPIRED: 'Expired',
	FREE: 'Free',
	FULL_DISCOUNT: 'Full discount',
	NEW: 'New',
	PAID: 'Paid',
	PENDING: 'Pending',
	PRE_PAYMENT: 'Pre-payment',
	REFUNDED: 'Refunded',
	VERIFICATION: 'Verification',
};
