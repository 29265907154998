import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import SelectValueMenu from '../../../util/SelectValueMenu';
import AmountInputField from '../../../util/AmountInputField';

const EditFunctionCurrencyAmountInput = ({ field, fields, onChangeFieldDirect, disabled, topLevelScope, validation }) => {
	const { currencyUnit = 'EUR' } = (topLevelScope || {});

	useEffect(() => {
		if (fields[field]?.amount && !fields[field]?.currency) {
			onChangeFieldDirect(
				field,
				{
					...(fields[field] || {}),
					currency: currencyUnit,
				},
			);
		}
	}, [currencyUnit, field, fields, onChangeFieldDirect]);

	const handleChangeAmount = (event) => {
		const validValue = !Number.isNaN(event.target.value) && event.target.value !== undefined;
		onChangeFieldDirect(
			field,
			validValue ? {
				...(fields[field] || {}),
				amount: parseFloat(event.target.value),
			} : null,
		);
	};

	return (
		<>
			<SelectValueMenu
				value={fields[field]?.currency || ''}
				options={['EUR', 'GBP']}
				onChange={(event) => onChangeFieldDirect(
					field,
					{
						...(fields[field] || {}),
						currency: event.target.value,
					},
				)}
				name={field}
				disabled={disabled}
				sx={{ width: 60 }}
			/>
			<TextField
				InputLabelProps={{
					shrink: true,
				}}
				InputProps={{
					inputComponent: AmountInputField,
				}}
				value={fields[field]?.amount || ''}
				onChange={handleChangeAmount}
				name={field}
				disabled={disabled}
				sx={{ width: 90 }}
				error={!!validation?.[field]}
				helperText={validation?.[field]}
			/>
		</>
	);
};

EditFunctionCurrencyAmountInput.propTypes = {
	field: PropTypes.string.isRequired,
	fields: PropTypes.object.isRequired,
	onChangeFieldDirect: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	validation: PropTypes.object,
};

export default withWorkingScope(EditFunctionCurrencyAmountInput);
