import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

class EmployeePaymentStatusManipulationIcon extends React.Component {
	static propTypes = {
		type: PropTypes.string,
		to: PropTypes.string,
		state: PropTypes.object,
		tooltip: PropTypes.string,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		type: 'generic',
		tooltip: '',
	};

	render() {
		if (this.props.tooltip.length <= 0 || this.props.disabled) {
			return this.renderWithLink();
		}

		return (
			<Tooltip title={this.props.tooltip} placement="top" >
				{this.renderWithLink()}
			</Tooltip>
		)
	}

	renderWithLink() {
		if (!this.props.to || this.props.disabled) {
			return this.renderIconButton();
		}

		return (
			<Link to={this.props.to} state={this.props.state}>
				{this.renderIconButton()}
			</Link>
		)
	}

	renderIconButton() {
		return (
			<IconButton style={{width: 36, height: 36}} {...this.props}>
				<Icon style={{fontSize: 22}}>{this.props.children}</Icon>
			</IconButton>
		)
	}
}

export default EmployeePaymentStatusManipulationIcon;
