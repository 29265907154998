import { styled } from '@mui/material/styles';
import { compose } from 'ramda';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { fetchEmployees } from '../../actions/employeeListActions';
import { propertyLabel, propertyValue } from '../../helpers/properties';
import withEmployeeProperties from '../../hoc/withEmployeeProperties';
import withRouter from '../../hoc/withRouter';
import withWorkingScope from '../../modules/scope/withWorkingScope';
import withFetchData from '../../modules/fetchData/withFetchData';
import PropertyTags from '../properties/PropertyTags';
import TablePaginationActions, { StyledTablePagination } from '../table/TablePaginationActions';
import Loader from '../util/loader/Loader';
import Alert from '../util/alert/Alert';
import ResponsiveTable from '../util/ResponsiveTable';
import PageHeader from '../page/PageHeader';
import PageHeaderMenu from '../page/PageHeaderMenu';
import PageHeaderTitle from '../page/PageHeaderTitle';
import ColumnPicker, { getInitialColumns } from '../util/ColumnPicker';
import EmployeeListFilters from './EmployeeListFilters';
import EmployeeListSearchField from './EmployeeListSearchField';

const PREFIX = 'EmployeeListPage';

const classes = {
    root: `${PREFIX}-root`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
		display: 'inline-block',
		borderRadius: 3,
		padding: '1px 5px',
		fontSize: '0.8em',
		marginLeft: theme.spacing(1),
		verticalAlign: 'text-bottom',
		color: '#333333',
		background: '#ff9999',
		borderColor: '#ff7777',
	},

}));

const Tag = styled(({ children }) => (
	<div className={classes.root}>
		{children}
	</div>
))(({ theme }) => ({
	[`&.${classes.root}`]: {
		display: 'inline-block',
		borderRadius: 3,
		padding: '1px 5px',
		fontSize: '0.8em',
		marginLeft: theme.spacing(1),
		verticalAlign: 'text-bottom',
		color: '#333333',
		background: '#ff9999',
		borderColor: '#ff7777',
	}
}));

class EmployeeListPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			traverseUp: false,
			traverseDown: true,
			includeInactive: false,
			properties: {},
			searchTerm: '',
			columns: getInitialColumns('EmployeeListPage'),
		};
	}

	componentDidMount() {
		const { workingScope } = this.props;
		if (workingScope) this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		const { workingScope, pagination } = this.props;
		const { searchTerm } = this.state;
		if (!workingScope) return;
		let updateRequired = !prevProps.workingScope;
		updateRequired = updateRequired || (prevProps.workingScope && prevProps.workingScope.id !== workingScope.id);
		updateRequired = updateRequired || (prevProps.pagination.page !== pagination.page);
		updateRequired = updateRequired || (prevProps.pagination.pageSize !== pagination.pageSize);
		updateRequired = updateRequired || (prevState.searchTerm !== searchTerm);

		const resetPage = (prevState.searchTerm !== searchTerm)
			|| (prevProps.workingScope && prevProps.workingScope.id !== workingScope.id);

		if (updateRequired) this.fetchData(resetPage);
	}

	handleSetting = prop => event => this.setState({
		[prop]: event.target.checked,
	}, () => this.fetchData(true))

	handleProperty = (prop, value) => {
		const { properties } = this.state;
		let clone = { ...properties };
		if (!value) {
			delete clone[prop];
		} else {
			clone = { ...properties, [prop]: value };
		}
		this.setState({
			properties: clone,
		}, this.fetchData);
	}

	handleSearchFieldChange = searchTerm => {
		this.setState({ searchTerm });
	}

	setColumns = (columns) => {
		this.setState({
			columns,
		});
	}

	fetchData(resetPage = false) {
		const { workingScope, load } = this.props;
		if (!workingScope) return;

		const { traverseUp, traverseDown, includeInactive, properties, searchTerm } = this.state;

		load(fetchEmployees(
			workingScope, traverseUp,
			traverseDown, includeInactive,
			properties,
			searchTerm,
		), true, resetPage);
	}

	renderErrorOrLoader() {
		const { error } = this.props;
		return error
			? null
			: <Loader />;
	}

	renderScopeAlertOrFallback() {
		const { workingScope } = this.props;
		return workingScope
			? this.renderErrorOrLoader()
			: <Alert>Select a scope first.</Alert>;
	}

	renderPropertyLabel(propertyKey) {
		const { properties, i18n } = this.props;
		const property = properties?.find(prop => prop.key === propertyKey);
		if (!property) {
			return null;
		}
		return propertyLabel(property, i18n.language);
	}

	renderPropertyValue(propKey, propValue) {
		const { properties, i18n } = this.props;
		const property = properties?.find(prop => prop.key === propKey);
		if (!property) {
			return null;
		}
		return propertyValue(property, propValue, i18n.language);
	}

	renderEmployeeTableRow() {
		const { data } = this.props;
		const { columns } = this.state;
		return data.map(employee => (
            <TableRow key={employee.id} hover>
                <TableCell size="small">
                    <Link
                        to={`/portal/employees/${employee.id}/view`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {employee.fullName}
                    </Link>
                    {!employee.active && <Tag
                        classes={{
                            root: classes.root
                        }}>Inactive</Tag> }
                </TableCell>
                <TableCell
                    size="small"
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                    {employee.email}
                </TableCell>
                <TableCell size="small">{employee.mobilePhone}</TableCell>
                <TableCell size="small">{employee.function}</TableCell>
                {columns.map(column => (
                    <TableCell size="small" key={column}>
                        {this.renderPropertyValue(column, employee.properties?.[column])}
                    </TableCell>
                ))}
                <TableCell size="small" align="right" style={{ padding: 0 }}>
                    <IconButton
                        component={Link}
                        to={`/portal/employees/${employee.id}/view`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                </TableCell>
            </TableRow>
        ));
	}

	render() {
		const {
			loading, error, data, workingScope, pagination,
			onPaginationChangePage, onPaginationChangePageSize,
			properties: employeeProperties,
		} = this.props;
		const { traverseDown, traverseUp, includeInactive, properties, columns } = this.state;

		return (
            <StyledGrid container spacing={2}>
				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderMenu items={[
							{ name: 'Applications', to: '/portal/applications', icon: 'assignment' },
						]}
						/>
						<PageHeaderTitle headline="Employees" subHeading={`The employees within ${workingScope ? workingScope.name : ''}`} />
						<Grid container spacing={2}>
							<Grid item md={6} xs={12}>
								<EmployeeListSearchField onChange={this.handleSearchFieldChange} />
							</Grid>
							<Grid item md={6} xs={12}>
								{/* TODO: Make this capability specific */}
								<FormControlLabel
									control={
										<Switch checked={traverseDown} onChange={this.handleSetting('traverseDown')} color="primary" />
									}
									label="Include subscopes"
								/>
								<FormControlLabel
									control={
										<Switch checked={traverseUp} onChange={this.handleSetting('traverseUp')} color="primary" />
									}
									label="Include parent scopes"
								/>
								<FormControlLabel
									control={(
										<Switch
											checked={includeInactive}
											onChange={this.handleSetting('includeInactive')}
											color="primary"
										/>
									)}
									label="Include inactive employees"
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<EmployeeListFilters
									propertiesState={properties}
									handleProperty={this.handleProperty}
								/>
								<PropertyTags actionId="EmployeePropertiesDefinition" properties={properties} />
							</Grid>
						</Grid>
					</PageHeader>
				</Grid>
				<Grid item xs={12}>
					{(!loading && data) && (
						<ColumnPicker
							columns={employeeProperties}
							selected={columns}
							setColumns={this.setColumns}
							context="EmployeeListPage"
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					<Paper>
						{
							error && workingScope
							&& <Alert type={Alert.TYPE_WARNING}>{error.error}</Alert>
						}
						{(loading || !data)
							? this.renderScopeAlertOrFallback()
							: (
								<ResponsiveTable>
									<TableHead>
										<TableRow>
											<TableCell size="small" style={{ width: '30%' }}>Name</TableCell>
											<TableCell size="small" style={{ width: '30%' }}>Email</TableCell>
											<TableCell size="small" style={{ width: '15%' }}>Phone</TableCell>
											<TableCell size="small" style={{ width: '15%' }}>Function</TableCell>
											{columns.map(column => (
												<TableCell size="small" style={{ whiteSpace: 'nowrap' }} key={column}>
													{this.renderPropertyLabel(column)}
												</TableCell>
											))}
											<TableCell size="small" style={{ width: '10%' }} />
										</TableRow>
									</TableHead>
									<TableBody>
										{(data && data.length > 0)
											? this.renderEmployeeTableRow()
											: (
												<TableRow>
													<TableCell size="small" colSpan={5}>
														<em>No employees found in this scope with given settings</em>
													</TableCell>
												</TableRow>
											)}
									</TableBody>
									{pagination && (
										<TableFooter>
											<TableRow>
												<StyledTablePagination
													colSpan={100}
													count={pagination.total}
													rowsPerPage={pagination.pageSize}
													page={pagination.page}
													onPageChange={onPaginationChangePage}
													onRowsPerPageChange={onPaginationChangePageSize}
													rowsPerPageOptions={[10, 25, 50, 100]}
													ActionsComponent={
														props => <TablePaginationActions onPaginationChangePage={onPaginationChangePage} {...props} />
													}
												/>
											</TableRow>
										</TableFooter>
									)}
								</ResponsiveTable>
							)}
					</Paper>
				</Grid>
			</StyledGrid>
        );
	}
}

export default compose(
	withWorkingScope,
	withEmployeeProperties,
	withTranslation(),
	withFetchData(null, {
		pagination: true,
		paginationFromSearch: true,
		keepData: true,
		customId: () => 'EmployeeListPage',
	}),
)(EmployeeListPage);
