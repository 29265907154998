import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'
import { niceDateTime, } from '../../../helpers/datetime'
import Alert from '../../util/alert/Alert'

const ScopeTooltipScopeCourseGroups = ({ groups }) => {
	const { i18n, t } = useTranslation();
	if(groups.length === 0) return (
		<CardContent>
			<Alert type={Alert.TYPE_INFO}>No groups yet.</Alert>
		</CardContent>
	);

	return (
		<List disablePadding>
			{groups.map((group) => (
				<ListItem
					key={group.id}
					divider
					button
					component={Link}
					to={`/portal/planner/course/${group.parent}/group/${group.id}`}
				>
					<ListItemText
						primary={group.name}
						secondary={niceDateTime(group.startDateTime, i18n.language, t)}
					/>
					<Icon>chevron_right</Icon>
				</ListItem>
			))}
		</List>
	);
}

ScopeTooltipScopeCourseGroups.propTypes = {
	groups: PropTypes.array.isRequired,
};

export default ScopeTooltipScopeCourseGroups
