import { Grow, ListItem, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { $getSelection, $isRangeSelection, $createParagraphNode } from 'lexical';
import { $wrapLeafNodesInElements } from '@lexical/selection';
import { $createQuoteNode, $createHeadingNode } from '@lexical/rich-text';
import {
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	REMOVE_LIST_COMMAND,
} from '@lexical/list';

const PREFIX = 'BlockOptionsDropdownList';

const classes = {
    dropdown: `${PREFIX}-dropdown`
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`&.${classes.dropdown}`]: {
		position: 'absolute',
		background: theme.palette.common.white,
		zIndex: 10000,
	}
}));

function BlockOptionsDropdownList({
	editor,
	blockType,
	anchorEl,
	toolbarRef,
	setShowBlockOptions,
	blockTypeToBlockName,
	allowedBlockTypes,
}) {
	const dropDownRef = useRef(null);


	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const dropDown = dropDownRef.current;
		const toolbar = toolbarRef.current;

		if (dropDown !== null && toolbar !== null) {
			const handle = (event) => {
				const { target } = event;

				if (!dropDown.contains(target) && !toolbar.contains(target)) {
					setShowBlockOptions(false);
				}
			};
			document.addEventListener('click', handle);

			return () => {
				document.removeEventListener('click', handle);
			};
		}
	}, [dropDownRef, setShowBlockOptions, toolbarRef]);

	const handleClickAway = () => {
		setShowBlockOptions(false);
	};

	const toolbarItems = useMemo(() => {
		const formatParagraph = () => {
			if (blockType !== 'paragraph') {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapLeafNodesInElements(selection, () => $createParagraphNode());
					}
				});
			}
			setShowBlockOptions(false);
		};

		const formatHeading1 = () => {
			if (blockType !== 'h1') {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapLeafNodesInElements(selection, () => $createHeadingNode('h1'));
					}
				});
			}
			setShowBlockOptions(false);
		};

		const formatHeading2 = () => {
			if (blockType !== 'h2') {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapLeafNodesInElements(selection, () => $createHeadingNode('h2'));
					}
				});
			}
			setShowBlockOptions(false);
		};

		const formatHeading3 = () => {
			if (blockType !== 'h3') {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapLeafNodesInElements(selection, () => $createHeadingNode('h3'));
					}
				});
			}
			setShowBlockOptions(false);
		};

		const formatBulletList = () => {
			if (blockType !== 'ul') {
				editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
			} else {
				editor.dispatchCommand(REMOVE_LIST_COMMAND);
			}
			setShowBlockOptions(false);
		};

		const formatNumberedList = () => {
			if (blockType !== 'ol') {
				editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
			} else {
				editor.dispatchCommand(REMOVE_LIST_COMMAND);
			}
			setShowBlockOptions(false);
		};

		const formatQuote = () => {
			if (blockType !== 'quote') {
				editor.update(() => {
					const selection = $getSelection();

					if ($isRangeSelection(selection)) {
						$wrapLeafNodesInElements(selection, () => $createQuoteNode());
					}
				});
			}
			setShowBlockOptions(false);
		};

		return [{
			onClick: formatParagraph,
			blockType: 'paragraph',
			icon: 'subject',
			label: blockTypeToBlockName.paragraph,
		}, {
			onClick: formatHeading1,
			blockType: 'h1',
			icon: 'title',
			label: blockTypeToBlockName.h1,
		}, {
			onClick: formatHeading2,
			blockType: 'h2',
			icon: 'title',
			label: blockTypeToBlockName.h2,
		}, {
			onClick: formatHeading3,
			blockType: 'h3',
			icon: 'title',
			label: blockTypeToBlockName.h3,
		}, {
			onClick: formatBulletList,
			blockType: 'ul',
			icon: 'format_list_bulleted',
			label: blockTypeToBlockName.ul,
		}, {
			onClick: formatNumberedList,
			blockType: 'ol',
			icon: 'format_list_numbered',
			label: blockTypeToBlockName.ol,
		}, {
			onClick: formatQuote,
			blockType: 'quote',
			icon: 'format_quote',
			label: blockTypeToBlockName.quote,
		}];
	}, [blockType, blockTypeToBlockName, editor, setShowBlockOptions]);

	return (
        <StyledPopper
			open={true}
			anchorEl={anchorEl.current}
			transition
			disablePortal
			placement="bottom-start"
			className={classes.dropdown}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					id="page-menu-sub"
					style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
				>
					<Paper>
						<ClickAwayListener onClickAway={handleClickAway}>
							<List dense>
								{toolbarItems
									.filter(item => !allowedBlockTypes || allowedBlockTypes.includes(item.blockType))
									.map(item => (
										<ListItem
											dense
											button
											onClick={item.onClick}
											selected={blockType === item.blockType}
											key={item.blockType}
										>
											<ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
											{item.label}
										</ListItem>
									))}
							</List>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</StyledPopper>
    );
}

BlockOptionsDropdownList.propTypes = {
	editor: PropTypes.object.isRequired,
	blockType: PropTypes.string.isRequired,
	toolbarRef: PropTypes.object.isRequired,
	anchorEl: PropTypes.object.isRequired,
	setShowBlockOptions: PropTypes.func.isRequired,
	blockTypeToBlockName: PropTypes.object.isRequired,
	allowedBlockTypes: PropTypes.array,
};

export default BlockOptionsDropdownList;
