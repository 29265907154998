import Grid from '@mui/material/Grid';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFunction } from '../../../../actions/functionsActions';
import { formatMoney } from '../../../../helpers/formatter';
import withRouter from '../../../../hoc/withRouter';
import withFetchData from '../../../../modules/fetchData/withFetchData';
import ModalItem from '../../../modal/ModalItem';
import ModalManager from '../../../modal/ModalManager';
import PageHeader from '../../../page/PageHeader';
import PageHeaderInfo from '../../../page/PageHeaderInfo';
import PageHeaderMenu from '../../../page/PageHeaderMenu';
import PageHeaderTitle from '../../../page/PageHeaderTitle';
import Loader from '../../../util/loader/Loader';
import CompanyFunctionCapabilities from './CompanyFunctionCapabilities';
import EditFunctionCapabilitiesModal from './EditFunctionCapabilitiesModal';
import CompanyFunctionContractTemplates from './CompanyFunctionContractTemplates';
import EditFunctionModal from './EditFunctionModal';

const headerFunctionProperties = [
	'monthlySalary',
	'baseSalary',
	'bonusSalary',
	'turnoverPercentage',
	'bonusTurnoverPercentage',
	'profitPercentage',
	'bonusEvaluationThreshold',
	'rank',
	'maximumFee',
	'reimbursementType',
];

const CompanyFunctionOverviewPage = ({ loading, data, params }) => {
	const { t } = useTranslation();
	const items = useMemo(() => data
		? headerFunctionProperties
			.filter(prop => !!data[prop])
			.map(prop => ({
				label: t(`manage.company.functions.fields.${prop}`),
				content: typeof data[prop] === 'object' ? formatMoney(data[prop]) : data[prop],
			})) : [],
	[data, t]);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<ModalManager>
				<ModalItem state="functionEdit" component={EditFunctionModal} functionData={data} />
				<ModalItem state="functionCapabilitiesEdit" component={EditFunctionCapabilitiesModal} />
			</ModalManager>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderMenu items={[{
							name: t('general.edit'),
							to: `/portal/company/function/${params.id}`,
							state: {
								modal: 'functionEdit',
								id: params.id,
							},
						}]} />

						<PageHeaderTitle headline={data?.name} />
						{data && (
							<PageHeaderInfo
								items={items}
							/>
						)}
					</PageHeader>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<CompanyFunctionCapabilities functionId={params.id} />
				</Grid>
				<Grid item xs={12} md={6}>
					<CompanyFunctionContractTemplates functionId={params.id} />
				</Grid>
			</Grid>
		</>
	);
};

export default withRouter(withFetchData(props => getFunction(props.params.id), {
	mapEntities: 'companyFunctions',
})(CompanyFunctionOverviewPage));
