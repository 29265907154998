import { styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm';
import { resetLostPassword } from '../../modules/auth/authActions';
import Loader from '../util/loader/Loader';
import Alert from '../util/alert/Alert';

const Root = styled('div')(({ theme }) => ({
	margin: theme.spacing(4, 0),
	background: '#fefefe',
	border: '1px solid #f6f6f6',
	boxShadow: theme.shadows[2],
	padding: theme.spacing(3),

	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
	},

	'& > h1': {
		color: theme.palette.primary.main,
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},
}));

const ResetPasswordForm = ({ resetCode, fields, onChangeField, handleSubmit, saving, error, success, validation, validationBlur, validationAll }) => {
	if (success) return (
		<Root>
			<Alert type={Alert.TYPE_SUCCESS}>
				Your password was successfully changed. Go back to the <Link to="/login">login page</Link>.
			</Alert>
		</Root>
	);

	return (
		<Root>
			{ saving && <Loader sheet /> }
			{
				error &&
				<Alert type={Alert.TYPE_WARNING}>
					ERROR {error.error}
				</Alert>
			}
			<form onSubmit={handleSubmit(() => ({
				resetCode: resetCode,
				password: fields.password,
				passwordRepeat: fields.passwordRepeat,
			}), resetLostPassword, (data) => {
				if(data.password !== data.passwordRepeat)
					return 'Given passwords are not the same.';
				return validationAll(data);
			})}>
				<p>Provide a new password and confirm the change.</p>

				<Grid container spacing={2}>
					<Grid item md={6} sm={12}>
						<TextField label="New password"
								   name="password"
								   type="password"
								   fullWidth
								   value={fields.password}
								   onChange={onChangeField}
								   error={!!validation['password']}
								   helperText={validation['password']}
								   onBlur={validationBlur} />
					</Grid>
					<Grid item md={6} sm={12}>
						<TextField label="Repeat password"
								   name="passwordRepeat"
								   type="password"
								   fullWidth
								   value={fields.passwordRepeat}
								   onChange={onChangeField}
								   error={!!validation['passwordRepeat']}
								   helperText={validation['passwordRepeat']}
								   onBlur={validationBlur} />
					</Grid>
				</Grid>

				<br/><br/>
				<Button variant="contained"
						type="submit"
						color="primary"
						fullWidth
						disabled={saving}>
					{saving ? 'Saving...' : 'Set new password'}
				</Button>
			</form>
		</Root>
	);
}

ResetPasswordForm.propTypes = {
	resetCode: PropTypes.string.isRequired,
}

export default withCompleteBasicForm(ResetPasswordForm, {
	password: Joi.string().min(6).required(),
	passwordRepeat: Joi.string().min(6).required(),
})
