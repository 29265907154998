import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withFormData from '../../../modules/formData/withFormData';
import { CALL_API } from '../../../setup/api';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';
import ApplicationFormBlock from './ApplicationFormBlock';
import ApplicationFormContractSummary from './ApplicationFormContractSummary';

const PREFIX = 'ApplicationFormContract';

const classes = {
    root: `${PREFIX}-root`,
    contract: `${PREFIX}-contract`,
    redirecting: `${PREFIX}-redirecting`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		padding: theme.spacing(2),
	},

    [`& .${classes.contract}`]: {
		marginTop: theme.spacing(4),
	},

    [`& .${classes.redirecting}`]: {
		display: 'inline-block',
		padding: theme.spacing(2),
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '1.1rem',
		fontStyle: 'normal',
		color: theme.palette.text.primary,
		maxWidth: 400,
	}
}));

const getReturnRootUrl = () => `${window.location.protocol}//${window.location.host}`;

const ApiAction = (hash, data) => ({
	[CALL_API]: {
		type: 'APPLY_CONFIRM',
		endpoint: `apply/confirm?hash=${hash}`,
		body: data,
	},
});

const ApplicationFormContract = ({ application, onBack, onSuccess, companyName, saving, error, success, watchSubmit }) => {
	const { t } = useTranslation();

    // When the form was successfully signed
    useEffect(() => {
        if (success) {
            if (success.contractSignUrl) {
                document.location.href = success.contractSignUrl;
            } else {
                // TODO: for now we assume the contract is signed if there is no url but success
                onSuccess(success);
            }
        }
        // eslint-disable-next-line
	}, [ success ]);

    const submitForm = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);

        watchSubmit(ApiAction(application.hash, {
            finishUrl: `${getReturnRootUrl()}/apply`,
        }));
    };

	return (
        <Root className={classes.root}>
			{saving && (
				<Loader sheet>
					<span className={classes.redirecting}>{t('application.signForm.sign.redirecting')}</span>
				</Loader>
			)}
			<ApiError error={error} />

            {!error && (
                <Typography paragraph variant="subtitle1">
                    <strong>{t('application.signForm.sign.check')}</strong>
                </Typography>
            )}

            <ApplicationFormContractSummary application={application} />

            <Grid container spacing={2}>

                {application.contractTemplates.length === 0 ? (
                    <Grid item xs={12} className={classes.contract}>
                        <p><em>{t('application.signForm.sign.noFunctionContract')}</em></p>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item md={8} sm={10} xs={12}>
                                <ApplicationFormBlock label={t('application.signForm.sign.templatesTitle')}>
                                    <p>{t('application.signForm.sign.templatesInfo')}</p>
                                    {application.contractTemplates.map(template => (
                                        <List key={template.id}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={`${template?.function?.name || 'Unknown function'} - ${template.name}`}
                                                />
                                            </ListItem>
                                        </List>
                                    ))}
                                    <p><em>{t('application.signForm.sign.templatesMobileNote')}</em></p>
                                </ApplicationFormBlock>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

				<Grid item xs={4}>
					<Button
						fullWidth
						variant="outlined"
						color="secondary"
						onClick={onBack}
					>
						{t('application.signForm.back')}
					</Button>
				</Grid>
				<Grid item xs={8}>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={submitForm}
						disabled={saving}
					>
						{t('application.signForm.toSigning')}
					</Button>
				</Grid>
			</Grid>
		</Root>
    );
};

ApplicationFormContract.propTypes = {
    application: PropTypes.object.isRequired,
    onBack: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default withFormData()(ApplicationFormContract);
