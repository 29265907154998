import { tableCellClasses, tableRowClasses } from '@mui/material';
import Table from '@mui/material/Table'
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { capabilities } from '../../../helpers/capabilities'
import { copyTextToClipboard } from '../../../helpers/clipboard'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { notificationShow } from '../../../modules/notification/notificationActions'
import withRootCapabilities from '../../../modules/scope/withRootCapabilities'
import { CALL_API } from '../../../setup/api'
import Loader from '../../util/loader/Loader'
import ApiError from '../../util/ApiError'

const PREFIX = 'EmployeeTooltipCard';

const classes = {
    card: `${PREFIX}-card`,
    loader: `${PREFIX}-loader`,
    cellRight: `${PREFIX}-cellRight`,
    copyButton: `${PREFIX}-copyButton`
};

const StyledCard = styled(Card)(({ theme }) => ({
    [`&.${classes.card}`]: {
		paddingBottom: theme.spacing(2),
	},

    [`& .${classes.loader}`]: {
		height: 130,
	},

    [`& .${classes.cellRight}`]: {
		display: 'flex',
		alignItems: 'center',
		'& > span': {
			flex: 1,
		},
	},

    [`& .${classes.copyButton}`]: {
		marginLeft: theme.spacing(1),
	}
}));

const apiAction = (employeeId) => ({
	[CALL_API]: {
		type: 'EMPLOYEE_TOOLTIP',
		endpoint: `employee/${employeeId}`,
	},
});

const TableTitleCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.root}`]: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	[`&.${tableCellClasses.sizeSmall}`]: {
		padding: theme.spacing(0.25, 2),
	},
}));

const TableDenseCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.sizeSmall}`]: {
		padding: theme.spacing(0.25, 2),
	},
}));

const TableDenseRow = styled(TableRow)(({ theme }) => ({
	[`&.${tableRowClasses.root}`]: {
		height: theme.spacing(5),
	},
}));

function stopPropagation(e){
	e.stopPropagation();
	e.preventDefault();

	return false;
}

const EmployeeTooltipCard = ({ employeeId, loading, error, data, hasCapability }) => {

	const dispatch = useDispatch();

	const handleCopyEmail = useCallback(() => {
		copyTextToClipboard(data.email, () => {
			dispatch(notificationShow('Email copied to clipboard', 'success'))
		});
	}, [data, dispatch]);

	const handleCopyMobile = useCallback(() => {
		copyTextToClipboard(data.mobilePhone, () => {
			dispatch(notificationShow('Mobile copied to clipboard', 'success'))
		});
	}, [data, dispatch]);

	if(error) return (
		<ApiError error={error} />
	);

	if(loading || !data) return (
		<Loader className={classes.loader} />
	);

	return (
        <StyledCard className={classes.card} onClick={stopPropagation}>
			<CardHeader
				title={data.fullName}
				action={hasCapability(capabilities.SeeEmployeeCapability) ? (
					<IconButton component={Link} to={`/portal/employees/${employeeId}/view`}>
						<Icon>arrow_forward</Icon>
					</IconButton>
				) : undefined}
				subheaderTypographyProps={{
					variant: 'body2',
				}}
			/>

			<Table size="small">
				<TableBody>
					<TableDenseRow>
						<TableTitleCell size="small">Email</TableTitleCell>
						<TableDenseCell size="small" className={classes.cellRight}>
							<span>{data.email}</span>
							<Tooltip title="Copy email to clipboard">
								<IconButton onClick={handleCopyEmail} className={classes.copyButton}>
									<Icon>file_copy</Icon>
								</IconButton>
							</Tooltip>
						</TableDenseCell>
					</TableDenseRow>
					<TableDenseRow>
						<TableTitleCell size="small">Mobile</TableTitleCell>
						<TableDenseCell size="small" className={classes.cellRight}>
							<span>{data.mobilePhone}</span>
							<Tooltip title="Copy mobile to clipboard">
								<IconButton onClick={handleCopyMobile} className={classes.copyButton}>
									<Icon>file_copy</Icon>
								</IconButton>
							</Tooltip>
						</TableDenseCell>
					</TableDenseRow>
					<TableDenseRow>
						<TableTitleCell size="small">Zoom</TableTitleCell>
						<TableDenseCell size="small">
							{data.zoomAccountActivated ? (
								<a href={`https://athenastudies.zoom.us/user/${data.zoomAccountId}/profile`} target="_blank">Zoom profile</a>
							) : (
								<em>Not activated</em>
							)}
						</TableDenseCell>
					</TableDenseRow>
				</TableBody>
			</Table>

		</StyledCard>
    );
}

EmployeeTooltipCard.propTypes = {
	employeeId: PropTypes.number.isRequired,
};

export default withRootCapabilities(withFetchData(props => apiAction(props.employeeId), {
	customId: (props) => `EmployeeTooltip-${props.employeeId}`,
})(EmployeeTooltipCard))
