import React from 'react'
import { mergeDeepRight } from 'ramda'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { themeSource } from '../MuiProvider'

const STAGE = process.env.STAGE === 'stage';

const darkTheme = createTheme(mergeDeepRight(themeSource, {
	palette: {
		type: 'dark',
		text: {
			primary: '#FFFFFF',
			secondary: '#dddddd',
		},
		background: {
			paper: STAGE ? '#3d4172' : '#250D25',
			default: '#250D25',
		},
	},
}));

const withDarkTheme = (WrappedComponent) => (props) => {
    return (
		<ThemeProvider theme={darkTheme}>
			<WrappedComponent {...props} />
		</ThemeProvider>
    );
};

export default withDarkTheme
