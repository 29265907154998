import React from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import withRouter from '../../hoc/withRouter';
import withFetchData from '../../modules/fetchData/withFetchData'
import { CALL_API } from '../../setup/api'
import Alert from '../util/alert/Alert'
import ApiError from '../util/ApiError'
import Icon from '@mui/material/Icon'
import Paper from '@mui/material/Paper'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import Grid from '@mui/material/Grid'
import PageHeader from '../page/PageHeader'
import PageHeaderMenu from '../page/PageHeaderMenu'
import PageHeaderTitle from '../page/PageHeaderTitle'
import Loader from '../util/loader/Loader'
import { formatPercentage } from '../../helpers/formatter'
import { niceDateYear } from '../../helpers/datetime'
import PartnerCreateModal from './PartnerCreateModal'

const Row = ({ partner }) => (
	<TableRow hover>
		<TableCell>{partner.name}</TableCell>
		<TableCell>
			{partner.global ?
				(<a href={"https://" + partner.slug + ".athenastudies.nl/"} target="_blank">
					{partner.slug}
				</a>)
				:
				partner.slug
			}
		</TableCell>
		<TableCell>{(partner.global ? formatPercentage(partner.discountPercentage) : '--') + '/' + formatPercentage(partner.revenuePercentage)}</TableCell>
		<TableCell>{niceDateYear(partner.expires)}</TableCell>
		<TableCell >{partner.scopes ?
			partner.scopes.map(function(scope){
				return(
					<div key={scope.id}>{scope.name}</div>
				);
			})
			:
			''
		}</TableCell>
		<TableCell style={{textAlign: 'right'}}>
			<Link to={"/portal/promotion/partners/edit/" + partner.id}>
				<IconButton>
					<Icon>edit</Icon>
				</IconButton>
			</Link>
		</TableCell>
	</TableRow>
);

const apiAction = () => ({
	[CALL_API]: {
		type: 'PARTNER',
		endpoint: `partner`,
	},
});

const PartnerListPage = ({ loading, error, data, location }) => {
    if(error) return (
    	<ApiError error={error} />
	);

    if(loading || !data) return (
    	<Loader />
	);

    if(data.length === 0) return (
    	<Alert type={Alert.TYPE_INFO}>
			No partners found...
		</Alert>
	);

	return(
		<>
			<ModalManager>
				<ModalItem state={"add-partner"} component={PartnerCreateModal}/>
			</ModalManager>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderMenu items={[
							{ name: 'New Partner', to: location.pathname, state: { modal : 'add-partner' }, icon: 'add' },
						]}/>
						<PageHeaderTitle headline={"Partners"}/>
					</PageHeader>
					<Grid item xs={12}>
						<Paper>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Slug</TableCell>
										<TableCell>Discount / Revenue</TableCell>
										<TableCell>Expiry date</TableCell>
										<TableCell>Scopes</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((partner) => (
										<Row partner={partner} key={partner.id} />
									))}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default withRouter(withFetchData(() => apiAction())(PartnerListPage));
