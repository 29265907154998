import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import TableCell from '@mui/material/TableCell'
import withRouter from '../../hoc/withRouter';
import withFetchData from '../../modules/fetchData/withFetchData'
import { formatMoney } from '../../helpers/formatter'
import { Schemas } from '../../actions/schemas'
import { getCourseLocation } from '../../actions/locationActions'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import CalendarViewModal from '../calendar/CalendarViewModal';
import PageHeader from '../page/PageHeader'
import PageHeaderMenu from '../page/PageHeaderMenu'
import PageHeaderTitle from '../page/PageHeaderTitle'
import PageHeaderNavigation from '../page/PageHeaderNavigation'
import PageHeaderInfo from '../page/PageHeaderInfo'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import CourseGroupLocationMailFooter from './CourseGroupLocationMailFooter'
import PageContentBlock from '../page/PageContentBlock'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import LocationEditModal from './LocationEditModal'
import RoomEditModal from './RoomEditModal'
import RoomDeleteModal from './RoomDeleteModal'
import LocationFooterEditModal from './LocationFooterEditModal'

const getRoomPrice = (room) => {
	if(room.paymentUnit === 'PER_HOUR'){
		return `${formatMoney(room.price)} per hour`;
	}

	if(room.paymentUnit === 'PER_DAY'){
		return `${formatMoney(room.price)} per day`;
	}

	if(room.paymentUnit === 'FREE'){
		return `Free`;
	}

	return formatMoney(room.price);
}

const LocationOverviewPage = ({ loading, error, data: courseLocation, location, params, workingScope }) => {
	if(error) return (
		<Alert type={Alert.TYPE_WARNING}>{error.error}</Alert>
	);

	if(loading || courseLocation === undefined) return (
		<Loader />
	);

	// TODO: this assumes that the working scope currency is actually related to this location, which is not true
	//  however, only in a few cases this will go wrong, so for now we will fix it this way
	//  and hopefully the API will block the wrong cases
	const currencyUnit = workingScope.currencyUnit;

	return (
		<Fragment>
			<ModalManager>
				<ModalItem state="editLocation" component={LocationEditModal} location={courseLocation} />
				<ModalItem state="editLocationFooter" component={LocationFooterEditModal} location={courseLocation} />
				<ModalItem state="editRoom" component={RoomEditModal} rooms={courseLocation.rooms} currencyUnit={currencyUnit} />
				<ModalItem state="deleteRoom" component={RoomDeleteModal} rooms={courseLocation.rooms} />
				<ModalItem state="showCalendar" component={CalendarViewModal} />
			</ModalManager>
			<PageHeader>
				<PageHeaderNavigation breadcrumbs={[
					{ label: 'Locations', to: '/portal/location' },
					{ label: `${courseLocation.name}`}
				]} />
				<PageHeaderMenu items={[
					{
						name: 'Calendar',
						to: location.pathname,
						state: {
							modal: 'showCalendar',
							iCalUrl: `course-location/${params.locationId}/timeslots.ics`,
						},
						icon: 'today',
						variant: 'outlined',
					},
					{
						name: 'Edit',
						to: location.pathname,
						state: { modal: 'editLocation' },
						icon: 'edit',
					}
				]} />
				<PageHeaderTitle headline={courseLocation.name} />
				<PageHeaderInfo items={[
					{ label: 'Address', content: `${courseLocation.street} ${courseLocation.houseNumber}, ${courseLocation.zipcode} ${courseLocation.city}`},
					{ label: 'Country', content: `${courseLocation.country}`}
				]} />
			</PageHeader>

			<CourseGroupLocationMailFooter
				url={location.pathname}
				state={{ modal: 'editLocationFooter' }}
				footer={courseLocation.courseGroupLocationMailFooter}
			/>

			<PageContentBlock title="Classrooms"
							  icon="location_on"
							  description="List of the classrooms known to this location. Adding a classrooms can be done when selecting a classroom for a timeslot.">
				<Table>
					<TableHead>
						<TableRow>
							<TableCell size="small">Name</TableCell>
							<TableCell size="small">Seats</TableCell>
							<TableCell size="small">Price</TableCell>
							<TableCell size="small" />
						</TableRow>
					</TableHead>
					<TableBody>
						{courseLocation.rooms && courseLocation.rooms.length > 0
							? courseLocation.rooms
								.sort((a, b) => (a.name || '').localeCompare(b.name))
								.map(room => (
									<TableRow key={room.id}>
										<TableCell size="small">{room.name}</TableCell>
										<TableCell size="small">{room.seats}</TableCell>
										<TableCell size="small">{getRoomPrice(room)}</TableCell>
										<TableCell size="small" align="right">
											<IconButton
												component={Link}
												to={location.pathname}
												state={{ modal: 'showCalendar', iCalUrl: `class-room/${room.id}/timeslots.ics` }}
											>
												<Icon color="primary">today</Icon>
											</IconButton>
											<IconButton
												component={Link}
												to={location.pathname}
												state={{ modal: 'editRoom', roomId: room.id }}
											>
												<Icon color="primary">edit</Icon>
											</IconButton>
											<IconButton
												component={Link}
												to={location.pathname}
												state={{ modal: 'deleteRoom', roomId: room.id }}
											>
												<Icon color="secondary">delete</Icon>
											</IconButton>
										</TableCell>
									</TableRow>
								))
							:
							<TableRow>
								<TableCell colSpan={5}>
									<em>No content to show</em>
								</TableCell>
							</TableRow>
						}
					</TableBody>
				</Table>
			</PageContentBlock>
		</Fragment>
	)
}

export default withWorkingScope(withRouter(withFetchData((props) => {
	return getCourseLocation(props.params.locationId)
}, {
	denormalize: Schemas.COURSE_LOCATION
})(LocationOverviewPage)));
