import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen'
import LocationFooterEditForm from './LocationFooterEditForm'

class LocationFooterEditModal extends Component {
	saveBinder = null;

	render(){
		const { location, handleClose, fullScreen } = this.props;

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth fullScreen={fullScreen}>
				<DialogTitle>Edit location mail footer of {location.name}</DialogTitle>
				<DialogContent>
					<LocationFooterEditForm inputData={location}
											bindSave={(func) => this.saveBinder = func}
											onSave={handleClose} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={() => {if(this.saveBinder) this.saveBinder()}}
							color="primary"
							variant="contained">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withDialogShouldBeFullscreen()(LocationFooterEditModal)
