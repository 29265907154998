import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import PageHeaderMenuSub from './PageHeaderMenuSub'

const PREFIX = 'PageHeaderMenu';

const classes = {
    menu: `${PREFIX}-menu`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.menu}`]: {
		float: 'right',
	},

    [`& .${classes.button}`]: {
		marginLeft: theme.spacing(2),
		boxShadow: 'none',
		'&.MuiButton-outlinedPrimary': {
			color: `${theme.palette.primary.main} !important`, // TODO: remove link styling from scss
		},
		'&.MuiButton-containedPrimary': {
			color: `${theme.palette.primary.contrastText} !important`, // TODO: remove link styling from scss
		}
	},

    [`& .${classes.icon}`]: {
		marginRight: theme.spacing(1),
	}
}));

const PageHeaderMenu = ({  items }) => {
	const theme = useTheme();
	const subOnly = useMediaQuery(theme.breakpoints.down('md'));
	if(!items) return null;

	items = items.filter(Boolean);
	if(items.length === 0) return null;

	const buttons = subOnly ? [] : items.slice(0, 2);
	const sub = items.slice(subOnly ? 0 : 2);

    return (
        <Root className={classes.menu}>
			{buttons.map(item => (
				<Button
					key={item.name + item.to}
					variant={item.variant || "contained"}
					color="primary"
					className={classes.button}
					component={Link}
					to={item.to}
					state={item.state}
					disabled={item.disabled}
				>
					{item.icon && (
						<Icon className={classes.icon}>
							{item.icon}
						</Icon>
					)}
					{item.name}
				</Button>
			))}
			<PageHeaderMenuSub items={sub} />
		</Root>
    );
};

PageHeaderMenu.propTypes = {
	items: PropTypes.array
};

export default (PageHeaderMenu)
