import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { keys, pick } from 'ramda';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { patchEmployeeProfile } from '../../actions/employeeActions';
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm';
import FormDataError from '../../modules/formData/FormDataError';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';
import PhoneField from '../util/PhoneField';

const minDate = new Date();
const maxDate = new Date();

minDate.setFullYear(minDate.getFullYear() - 70);
minDate.setHours(0, 0, 0, 0);
maxDate.setFullYear(maxDate.getFullYear() - 15);
maxDate.setHours(0, 0, 0, 0);

const momentMinDate = moment(minDate);
const momentMaxDate = moment(maxDate);

class ProfileEditDetailsForm extends Component {
	componentDidMount() {
		const { bindSave } = this.props;

		if (bindSave) {
			bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps) {
		const { success, onSave } = this.props;

		if (success && !prevProps.success && onSave) {
			onSave();
		}
	}

	handleChangeBirthday = (date) => {
		const { validationField, onChangeFieldDirect } = this.props;

		onChangeFieldDirect('birthday', date?.format());
		validationField('birthday', date?.format());
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields, validationFields, validationAll, handleFormError } = this.props;

		validationAll(fields, (hasError) => {
			if (hasError) return handleFormError('Not all fields were filled correctly.');

			return watchSubmit(patchEmployeeProfile(
				inputData.id, pick(keys(validationFields), fields),
			));
		});
	}

	render() {
		const { validation, validationBlur, fields, onChangeField, saving, success, error, t } = this.props;

		if (success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Updated details saved.
			</Alert>
		);

		return (
			<div>
				{ saving && <Loader sheet /> }
				<FormDataError error={error} showFieldErrors />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField
							name="initials"
							value={fields.initials}
							onChange={onChangeField}
							label={t('employee.fields.initials')}
							error={!!validation.initials}
							helperText={validation.initials}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="name"
							value={fields.name}
							onChange={onChangeField}
							label={t('employee.fields.name')}
							error={!!validation.name}
							helperText={validation.name}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="middleName"
							value={fields.middleName || ''}
							onChange={onChangeField}
							label={t('employee.fields.middleName')}
							error={!!validation.middleName}
							helperText={validation.middleName}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="surname"
							value={fields.surname}
							onChange={onChangeField}
							label={t('employee.fields.surname')}
							error={!!validation.surname}
							helperText={validation.surname}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<PhoneField
							name="mobilePhone"
							value={fields.mobilePhone}
							onChange={onChangeField}
							label={t('employee.fields.mobilePhone')}
							error={!!validation.mobilePhone}
							helperText={validation.mobilePhone}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<DatePicker
							value={fields.birthday}
							inputFormat="DD-MM-YYYY"
							minDate={momentMinDate}
							maxDate={momentMaxDate}
							onChange={this.handleChangeBirthday}
							showToolbar={false}
							openTo="year"
							renderInput={(props) => (
								<TextField
									{...props}
									helperText={validation.birthday}
									error={!!validation.birthday}
									label={t('employee.fields.birthday')}
									required
									fullWidth
								/>
							)}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

ProfileEditDetailsForm.propTypes = {
	inputData: PropTypes.object.isRequired,
};

export default withTranslation()(withCompleteBasicForm(ProfileEditDetailsForm, {
	initials: Joi.string().required(),
	name: Joi.string().required(),
	middleName: Joi.string().empty('').allow(null),
	surname: Joi.string().required(),
	mobilePhone: Joi.string().required(),
	birthday: Joi.date().required(),
}));
