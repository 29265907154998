import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { niceDateYear } from '../../helpers/datetime';
import { useDidUpdateEffect } from '../../helpers/hooks'
import withFetchData from '../../modules/fetchData/withFetchData'
import { CALL_API } from '../../setup/api'
import PageHeader from '../page/PageHeader'
import PageHeaderMenu from '../page/PageHeaderMenu'
import PageHeaderTitle from '../page/PageHeaderTitle'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { formatFullName } from '../../helpers/formatter'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'

const ApiAction = (scopeId) => ({
	[CALL_API]: {
		type: 'APPLY_LIST',
		endpoint: `scope/${scopeId}/apply`,
	},
});

const ApplicationRow = ({application}) => {
	return (
		<TableRow>
			<TableCell>
				{formatFullName(application)}
			</TableCell>
			<TableCell>{application.email}</TableCell>
			<TableCell>{application.applyingAs.name}</TableCell>
			<TableCell>{niceDateYear(application.expires)}</TableCell>
			<TableCell>{application.status}</TableCell>
			<TableCell padding="checkbox">
				<Link to={`/apply?hash=${application.hash}`}>
					<Icon>arrow_forward</Icon>
				</Link>
			</TableCell>
		</TableRow>
	)
};

const ApplicationsPage = ({ scope, loading, error, data, load }) => {
	useDidUpdateEffect(() => {
		load();
	}, [scope]);

	const base = (children) => (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PageHeader>
					<PageHeaderMenu
						items={[
							{ name: 'Employees', icon: 'group', to: '/portal/employees' },
							{ name: 'New Application', icon: 'add', to:  '/portal/applications/create' },
						]}
					/>
					<PageHeaderTitle
						headline="Applications"
						subHeading="Please be aware that currently only the direct applications of this scope are shown. Specify your scope further if need be."
					/>
				</PageHeader>
			</Grid>
			<Grid item xs={12}>
				{children}
			</Grid>
		</Grid>
	);

	if(!scope) return base(
		<Alert>Select a scope first.</Alert>
	);

	if(error){
		if(error.errorCode === 403) return base(
			<Alert type={Alert.TYPE_DANGER}>
				You do not have the privileges to view the applications of this scope. Please specify another scope.
			</Alert>
		);

		return base(
			<ApiError error={error} />
		);
	}

	if(!data) return base(
		<Loader />
	);

	if(data.length === 0) return base(
		<Alert>No applications found in this scope.</Alert>
	);

	return base(
		<Paper>
			{loading && (
				<Loader sheet />
			)}
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>E-mail</TableCell>
						<TableCell>Function</TableCell>
						<TableCell>Expires</TableCell>
						<TableCell>Status</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map(application => (
						<ApplicationRow key={application.id} application={application} />
					))}
				</TableBody>
			</Table>
		</Paper>
	);
}

export default withWorkingScope(withFetchData((props) => props.scope && ApiAction(props.scope.id), {
	customId: () => 'ApplicationsPage',
})(ApplicationsPage));
