import React, { Component } from 'react'
import Grid from '@mui/material/Grid'
import { Routes, Route } from 'react-router-dom'
import { sortScopesOnName } from '../../helpers/scope'
import withRouter from '../../hoc/withRouter';
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import CourseIndexPage from './CourseIndexPage'
import withScopeAndSubScopes from '../../modules/scope/withScopeAndSubScopes'
import CourseFinancialPage from './CourseFinancialPage'
import CourseEvaluationsPage from './evaluations/CourseEvaluationsPage'

class CoursePage extends Component {
	render(){
		const { loading, error, scope, subScopes, parentScope } = this.props;

		if(error) return (
			<Alert type={Alert.TYPE_DANGER}>Cannot load this scope. {error.error}</Alert>
		);

		if(loading || !scope || !subScopes) return (
			<Loader />
		);

		const props = {
			course: scope,
			groups: sortScopesOnName(subScopes),
			parentScope: parentScope,
		};

		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Routes>
						<Route path="/" index element={<CourseIndexPage {...props} />} />
						<Route path="financial" element={<CourseFinancialPage {...props} />} />
						<Route path="evaluations" element={<CourseEvaluationsPage {...props} />} />
					</Routes>
				</Grid>
			</Grid>
		);
	}
}

CoursePage.propTypes = {};

export default withRouter(withScopeAndSubScopes(CoursePage, (props) => {
	return props.params && props.params.id;
}))
