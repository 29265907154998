import React from 'react'
import PropTypes from 'prop-types'

const ModalItem = ({ component: Component, ...props }) => {
	if(props.onClose){
		const _handleClose = props.handleClose;
		props = {
			...props,
			handleClose: () => {
				_handleClose();
				props.onClose();
			},
		};
	}

	return <Component {...props} />;
}

ModalItem.propTypes = {
	state: PropTypes.string.isRequired,
	component: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.element,
		PropTypes.object,
	]).isRequired,
	onClose: PropTypes.func,
};

export default ModalItem
