import { styled } from '@mui/material/styles';
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { CALL_API } from '../../setup/api'
import { niceDateTime } from '../../helpers/datetime'
import withFetchData from '../../modules/fetchData/withFetchData'
import editorBaseStyleCreator from '../util/editor/styles/editorBaseStyleCreator';
import PropertiesTable from '../util/PropertiesTable'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import { scopeParentsNameString } from '../../helpers/scope'

const PREFIX = 'CourseGroupOccupancyMailListModal';

const classes = {
    editor: `${PREFIX}-editor`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.editor}`]: {
		marginBottom: theme.spacing(2),
		...editorBaseStyleCreator(theme),
	}
}));

const ApiAction = (groupEmailId) => ({
	[CALL_API]: {
		type: 'GROUP_EMAIL',
		endpoint: `group-email/${groupEmailId}`,
	},
});

const CourseGroupOccupancyMailModal = ({ loading, fetchError, data: email, handleClose }) => {


	return (
        <StyledDialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
			{email && (
				<DialogTitle>
					{email.subject}
				</DialogTitle>
			)}
			<DialogContent>
				{fetchError ? (
					<ApiError error={fetchError} />
				) : (loading || !email) ? (
					<Loader />
				) : (
					<>
						<PropertiesTable items={[
							{ label: 'Send at', content: niceDateTime(email.messageCreationDateTime) },
							{ label: 'Reply to', content: email.replyTo },
							{
								label: 'Send by',
								content: email.sender ? (
									<Link to={`/portal/employees/${email.sender.id}/view`}>{email.sender.fullName}</Link>
								) : 'unknown'
							},
							{ label: 'Group', content: scopeParentsNameString(email.athenaStudiesCourseGroup, 'Company') },
						]} />
						<br />
						<div
							dangerouslySetInnerHTML={{ __html: email.body }}
							className={classes.editor}
						/>
					</>
				)}
			</DialogContent>
		</StyledDialog>
    );
}

CourseGroupOccupancyMailModal.propTypes = {
	groupEmailId: PropTypes.number.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default withFetchData(
	props => ApiAction(props.groupEmailId)
)(CourseGroupOccupancyMailModal)
