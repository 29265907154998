import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { values } from 'ramda'
import { createSelector } from 'reselect'
import getDisplayName from 'react-display-name'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { createArrayItemsEqualSelector } from '../../helpers/selectors'
import { getSubScopes } from './scopeActions'
import withFetchData from '../fetchData/withFetchData'

const getScope = (state, props) => props.scope;
const getScopeEntities = (state) => state.entities.scopes;

const makeGetSubScopes = () => {
	return createArrayItemsEqualSelector(createSelector(
		[getScope, getScopeEntities],
		(scope, entities) => {
			if(scope._virtual){
				return values(entities).filter(
					s => s.parent === scope.id
				);
			}else{
				return scope.subScopes && scope.subScopes.map(
					id => entities[id]
				);
			}
		}
	), data => data);
}

// WARNING
// This is build for quite specific use cases
// Pay extra attention if using, especially when rendering multiple instances
const withSubScopes = (WrappedComponent, customFetchDataId = (props) => 'subScopes') => {
	const Enhanced = withFetchData((props) => {
		if(props.scope && !props.scope._virtual && !props.subScopes) {
			return getSubScopes(props.scope.id)
		}

		return undefined;
	}, {
		customId: customFetchDataId,
		makeMapStateToProps: (state, props) => {
			const getSubScopes = makeGetSubScopes();
			return (state, props) => {
				return ({
					subScopes: getSubScopes(state, props),
				});
			}
		},
	})(class SubScopes extends PureComponent {
		static displayName = `withSubScopes(${getDisplayName(WrappedComponent) || 'Unknown'})`;

		static propTypes = {
			scope: PropTypes.object.isRequired
		};

		render(){
			const { scope, subScopes, ...rest } = this.props;

			return (
				<WrappedComponent
					{...rest}
					scope={scope}
					subScopes={subScopes}
				/>
			);
		}
	});

	return hoistNonReactStatics(Enhanced, WrappedComponent);
}

export default withSubScopes
