import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys, mapObjIndexed, pick, values } from 'ramda'
import moment from 'moment'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import withFormData from '../../modules/formData/withFormData'
import withValidation, { Joi } from '../../hoc/withValidation'
import { createCustomDiscountCodes, createDiscountCodes } from '../../actions/discountCodeActions'
import { getISO } from '../../helpers/datetime'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Line from '../util/Line'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import ScopeIndicator from '../scope/indicator/ScopeIndicator'

import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import Switch from '@mui/material/Switch'

class DiscountCodesForm extends Component {

	state = {
		customCode: '',
		custom: false,
		timesApplicable: 1,
		discountPercentage: 10,
		n: 1,
		expires: moment().add(2, 'w').toISOString(),
		incremental: true,
	}

	onCreateSave = () => {
		const finalSubmit = (route, data) => {
			delete data.custom
			this.props.watchSubmit(route(this.props.scope.id, data))
		}

		this.props.validationAll(this.state, (containsError, errors) => {
			if (containsError) {
				if (this.props.onValidationError) this.props.onValidationError(errors)

				this.props.handleFormError('Not all fields were filled correctly.')
				return
			}

			const data = pick(keys(this.props.validation), this.state)

			if (data.discountPercentage) data.discountPercentage /= 100

			if (data.custom) {
				finalSubmit(createCustomDiscountCodes, data)
			} else {
				delete data.customCode
				finalSubmit(createDiscountCodes, data)
			}
		})
	}

	bindState = property => event => {
		this.setState({
			[property]: event.target.value,
		})
	}

	bindStateSwitch = (property, values) => event => {
		this.setState({
			[property]: event.target.checked ? values[0] : values[1],
		}, () => this.props.validationAll(this.state))
	}

	changeExpireDate = date => this.setState({
		expires: getISO(moment(date)),
	})

	componentDidUpdate() {
		if (this.props.success) {
			this.props.handleClose()
		}
	}

	FormItem = ({ name, title }) => {
		const valid = this.props.validation[name]
		return (
			<FormControl error={!!valid} fullWidth={true}>
				<InputLabel>{title}</InputLabel>
				<Input
					name={name}
					defaultValue={this.state[name]}
					onChange={this.bindState(name)}
					onBlur={this.props.validationBlur}
				/>
				<FormHelperText>{valid}</FormHelperText>
			</FormControl>
		)
	}

	render() {
		const { saving, error } = this.props;
		const FormItem = this.FormItem;

		return (
			<Dialog open={true} onClose={this.props.handleClose}>
				<DialogTitle>
					Create new discount
				</DialogTitle>
				<DialogContent>
					<ScopeIndicator fullNames />
				</DialogContent>
				<DialogContent>
					{saving && <Loader sheet/>}
					{error && <Alert type={Alert.TYPE_WARNING}>
						{error.requestErrors
							? <div>{values(mapObjIndexed((v, i) => <div
								key={i}>{i} {v}</div>, error.requestErrors))}</div>
							: error.error}
					</Alert>}
					<form onSubmit={this.onCreateSave}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<FormControlLabel
											label={'Custom Discount Code'}
											margin={'small'}
											control={(
												<Switch
													checked={this.state.custom} color={'primary'}
													onChange={this.bindStateSwitch('custom', [true, false])}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{this.state.custom &&
										<FormItem title={'Custom Discount Code'} name={'customCode'}/>}
									</Grid>
								</Grid>
								<Line />
							</Grid>
							<Grid item xs={6}>
								<FormItem title={'Discount Percentage'} name={'discountPercentage'}/>
								<MobileDatePicker
									inputFormat={'dd D MMMM YYYY'}
									okLabel={'Choose'}
									cancelLabel={'Cancel'}
									mode={'landscape'}
									showToolbar={false}
									value={moment(this.state.expires, moment.ISO_8601).toDate()}
									minDate={moment()}
									onChange={this.changeExpireDate}
									closeOnSelect
									componentsProps={{
										actionBar: {
											actions: ['cancel'],
										},
									}}
									renderInput={(props) => (
										<TextField
											{...props}
											label={'Code applicable until (inclusive)'}
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormItem title={'Times applicable'} name={'timesApplicable'}/>
								<FormItem title={'Number of Codes'} name={'n'}/>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.handleClose}>Cancel</Button>
					<Button color="primary" onClick={this.onCreateSave}>Create codes</Button>
				</DialogActions>
			</Dialog>
		)
	}

}

DiscountCodesForm.propTypes = {
	handleClose: PropTypes.func.isRequired,
}

const withArgs = {
	id: 'CreateDiscountCode',
	validators: {
		custom: Joi.boolean().required(),
		discountPercentage: Joi.number().integer().min(1).max(100).required(),
		timesApplicable: Joi.number().integer().min(1).max(500).required(),
		n: Joi.number().integer().min(1).max(100).required(),
		expires: Joi.string().required(),
		customCode: Joi.string().min(2).max(30).when('custom', { is: false, then: Joi.allow('') }),
	},
}
const withWithValidation = withValidation(DiscountCodesForm, withArgs.validators)
export default withWorkingScope(withFormData({
	customId: () => withArgs.id,
})(withWithValidation))
