import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import EditFunctionForm from './EditFunctionForm';

function EditFunctionModal({ modal, functionData, handleClose, topLevelScope }) {
	const { t } = useTranslation();
	const form = useRef(null);

	if (!topLevelScope) {
		return null;
	}

	return (
		<Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle>
				{t('manage.company.functions.editModal.title')}
			</DialogTitle>
			<DialogContent>
				<EditFunctionForm
					refFormSubmit={form}
					inputData={functionData}
					functionId={modal.id}
					companyId={topLevelScope.id}
					handleClose={handleClose}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{t('general.close')}</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						// eslint-disable-next-line no-unused-expressions
						form.current && form.current();
					}}
				>
					{t('general.save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

EditFunctionModal.propTypes = {
	functionData: PropTypes.object,
};

export default withWorkingScope(EditFunctionModal);
