import TablePagination from '@mui/material/TablePagination';
import React from 'react'
import { styled } from '@mui/material/styles';
import { tablePaginationClasses, toolbarClasses, useMediaQuery, useTheme } from '@mui/material';
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Pagination from '@mui/material/Pagination';

const PREFIX = 'TablePaginationActions';

const classes = {
    customPaginationActions: `${PREFIX}-customPaginationActions`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.customPaginationActions}`]: {
		display: 'flex',
		marginLeft: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
			width: '100%',
			justifyContent: 'center',
		},
		'& > .MuiPagination-root': {
			padding: theme.spacing(1),
			'& .MuiPagination-ul': {
				flexWrap: 'nowrap',
			},
		},
	}
}));

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
	[`& > .${toolbarClasses.root}`]: {
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			paddingLeft: 0,
			paddingRight: 0,
			maxWidth: 'calc(100vw - ' + parseInt(theme.spacing(2), 10) + 'px)',
		},
		[theme.breakpoints.up('xs')]: {
			marginLeft: theme.spacing(2),
		},
	},
}));

function TablePaginationActions(props) {
	const { count, rowsPerPage, onPaginationChangePage, page } = props;

	const theme = useTheme();

	const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));

	const numberOfPages = Math.ceil(count / rowsPerPage);

	const handleBackButtonClick = () => {
		onPaginationChangePage(null, page - 1);
	}

	const handleNextButtonClick = () => {
		onPaginationChangePage(null, page + 1);
	}

	const onPageChange = (event, page) => {
		onPaginationChangePage(null, page - 1);
	}

	if (numberOfPages < 2) {
		return null;
	}

	return (
        <Root className={classes.customPaginationActions}>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="Previous Page"
				size={isMobileWidth ? 'small' : 'medium'}
			>
				<Icon>chevron_left</Icon>
			</IconButton>
			<Pagination
				count={numberOfPages}
				page={page + 1}
				size={isMobileWidth ? 'medium' : 'small'}
				onChange={onPageChange}
				siblingCount={2}
				hideNextButton
				hidePrevButton
			/>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="Next Page"
				size={isMobileWidth ? 'small' : 'medium'}
			>
				<Icon>chevron_right</Icon>
			</IconButton>
		</Root>
    );
}

export default TablePaginationActions;
