import { Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { Suspense, useState, lazy } from 'react';
import { compose } from 'ramda';
import calendarServices from '../../helpers/calendarServices';
import withFetchData from '../../modules/fetchData/withFetchData';
import withFormData from '../../modules/formData/withFormData';
import { CALL_API, downloadFromApi } from '../../setup/api';
import Loader from '../util/loader/Loader';

const CalendarView = lazy(() => import('./CalendarView'));

export const getCalendarEvents = (iCalUrl) => ({
	[CALL_API]: {
		type: 'ICS_CALENDAR',
		endpoint: iCalUrl,
		settings: {
			headers: {
				Accept: 'application/json; charset=utf-8',
			},
		},
	},
});

const CalendarViewModal = ({ modal, data, loading, handleClose }) => {
	const [icsModal, toggleIcsModal] = useState(false);

	const handleICSModalClose = () => {
		toggleIcsModal(false);
	};

	const handleICalDownload = () => {
		downloadFromApi(modal.iCalUrl, 'timeslots.ics');
	};

	const handleICalCopyToClipboard = () => {
		calendarServices.getLiveICalendarUrl(modal.iCalUrl);
	};

	return (
        <>
            <Dialog open={true} maxWidth="md" fullWidth onClose={handleClose} scroll="body">
				<DialogContent>
					{ !Array.isArray(data) || loading
						? <Loader />
						: <Suspense fallback="loading"><CalendarView data={data} toggleIcsModal={toggleIcsModal} /></Suspense>
					}
				</DialogContent>
				<Dialog open={icsModal} maxWidth="xs" fullWidth onClose={handleICSModalClose}>
					<DialogContent>
						<Typography variant="h5">Subscribe</Typography>
						<p>
							Copy the iCalendar URL to your clipboard. Subscribe to this calendar by importing/adding the calendar from web/URL.
						</p>
						<Button onClick={handleICalCopyToClipboard} color="primary" variant="outlined">
							Copy iCalendar URL to clipboard
						</Button>
						<Divider style={{ margin: '20px 0 10px 0' }} />
						<Typography variant="h5">Download</Typography>
						<p>
							Download the iCalendar file (.ics) of this calendar. Your can import the .ics-file in your mail client.
							<br />
							<i>Note: this calendar is not automatically updated.</i>
						</p>
						<Button onClick={handleICalDownload} color="primary" variant="outlined">
							Download calendar (.ics)
						</Button>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleICSModalClose} color="primary" variant="contained">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Dialog>
        </>
    );
};

export default compose(
	withFormData(),
	withFetchData(props => getCalendarEvents(props.modal.iCalUrl)),
)(CalendarViewModal);
