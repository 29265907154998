import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { matchSorter } from 'match-sorter';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { debounce } from '../../../helpers/search';
import { fetchDataInternalActions } from '../../../modules/fetchData/fetchDataActions';
import withFetchData from '../../../modules/fetchData/withFetchData';
import { CALL_API } from '../../../setup/api';
import ApiError from '../../util/ApiError';

const ApiAction = (searchTerm) => ({
    [CALL_API]: {
        type: 'SKU_REFERENCES_SEARCH',
        endpoint: `magento/products?
		name=${searchTerm || ''}&limit=20`,
    },
});

const filterOptions = (options, { inputValue }) => matchSorter(
    options,
    inputValue,
    { keys: ['sku', 'courseName', 'productName', 'summaryType']	},
);

function CourseSkuReferencesSelector({ onChange, list, fetchError, data, load }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        setSearch(event.target.value);
    };

    const handleChange = (event, values, reason) => {
        if (!(reason === 'removeOption' && event.key === 'Backspace')) {
            onChange(values);
        }
    };

    useEffect(() => {
        dispatch(fetchDataInternalActions.fetchDataClear('SkuReferencesSearch'));
        if (search?.length > 1) {
            setLoading(true);
            debounce(load(ApiAction(search)).then(() => setLoading(false)), 250);
        }
    }, [dispatch, load, search]);

    return (
        <>
            <ApiError error={fetchError} />
            <Autocomplete
                options={(Array.isArray(data) ? data : []).concat(list)}
                autoHighlight
                open={open}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionLabel={option => option.sku}
                multiple
                loading={loading}
                filterOptions={filterOptions}
                filterSelectedOptions
                disableClearable
                value={list}
                renderTags={() => null}
                clearOnBlur={false}
                renderOption={(props, option) => (
                    <li {...props} key={option.sku}>
                        <Box>
                            {/* eslint-disable-next-line react/prop-types */}
                            <Typography>{option.sku}</Typography>
                            <Typography variant="subtitle2">
                                {/* eslint-disable-next-line react/prop-types */}
                                {option.courseName}
                                {' '}
                                |
                                {option.productName}
                                {' '}
                                |
                                {option.summaryType}
                            </Typography>
                        </Box>
                    </li>
                )}
                noOptionsText={search?.length < 2 ? t('scope.course.sku.selector.placeholder') : t('scope.course.sku.selector.noOptions')}
                getOptionDisabled={(option) => !!list.find(item => item.sku === option.sku)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={t('scope.course.sku.selector.search')}
                        autoComplete="off"
                    />
                )}
            />
        </>
    );
}

CourseSkuReferencesSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    list: PropTypes.array,
};

export default withFetchData(undefined, {
    customId: () => 'SkuReferencesSearch',
})(CourseSkuReferencesSelector);
