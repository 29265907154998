import { keys, reduce } from 'ramda';

export const languages = {
	nl: 'Nederlands',
	en: 'English',
	de: 'Deutsch',
};

export const languageFromLocale = (locale) => (
	locale.replace('_', '-').split('-')[0].toLowerCase()
);

export const translationByLanguage = alternatives => language => alternatives[keys(alternatives).find(
	locale => locale.indexOf(language) === 0,
)];

const languageKeyToBasic = lang => lang.indexOf('_') > 1 ? lang.substr(0, lang.indexOf('_')) : lang;

export const standardizeKeys = input => reduce((obj, l) => ({
	...obj,
	[languageKeyToBasic(l)]: input[l],
}), {}, keys(input));
