import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { capabilities } from '../../helpers/capabilities';
import withRootCapabilities from '../../modules/scope/withRootCapabilities';
import PageHeader from '../page/PageHeader';
import PageHeaderMenu from '../page/PageHeaderMenu';
import PageHeaderTitle from '../page/PageHeaderTitle';
import PageHeaderInfo from '../page/PageHeaderInfo';
import DashboardTeachingContainer from './teacherAgenda/TeacherAgendaContainer';
import DashboardContracts from './DashboardContracts';
import DashboardInformation from './DashboardInformation';

const userSelector = ({ auth: { user } }) => user;

const DashboardPage = ({ hasCapability }) => {
	const { t } = useTranslation();
	const user = useSelector(userSelector);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PageHeader>
					<PageHeaderMenu
						items={[
							{ name: t('dashboard.headerInfo.viewFullProfile'), to: '/portal/profile', icon: 'pageview' },
							...(hasCapability(capabilities.CreateClaimForm) ? [
								{ name: t('dashboard.headerInfo.myDeclarations'), to: '/portal/profile/declarations', icon: 'receipt' },
							] : []),
						]}
					/>
					<PageHeaderTitle
						headline={`${t('dashboard.headerInfo.welcome')} ${user.name}`}
						subHeading={t('dashboard.headerInfo.description')}
					/>
					<PageHeaderInfo
						items={[
							{ label: t('employee.fields.fullName'), content: user.fullName },
							{ label: t('employee.fields.mobilePhone'), content: user.mobilePhone },
							{ label: t('employee.fields.email'), content: user.email },
							{ label: t('employee.fields.iban'), content: `${user.IBAN} (${user.bankPayee})` },
						]}
					/>
				</PageHeader>
			</Grid>
			<Grid item sm={6} xs={12}>
				<DashboardTeachingContainer />
			</Grid>
			<Grid item sm={6} xs={12}>
				<DashboardInformation hasCapability={hasCapability} />
				<DashboardContracts />
			</Grid>
		</Grid>
	);
};

export default withRootCapabilities(DashboardPage);
