import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import PercentageInputField from '../../../util/PercentageInputField';

const EditFunctionPercentageTextField = ({ field, fields, onChangeField }) => (
	<TextField
		InputLabelProps={{
			shrink: true,
		}}
		value={fields[field] || ''}
		name={field}
		InputProps={{
			inputComponent: PercentageInputField,
		}}
		onChange={onChangeField}
		style={{ width: '150px' }}
	/>
);

EditFunctionPercentageTextField.propTypes = {
	field: PropTypes.string.isRequired,
	fields: PropTypes.object.isRequired,
	onChangeField: PropTypes.func.isRequired,
};

export default EditFunctionPercentageTextField;
