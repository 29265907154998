import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getClaimFormsByEmployee } from '../../../actions/declarationsActions';
import declarationStatuses from '../../../constants/DeclarationStatuses';
import useAuthUser from '../../../modules/auth/useAuthUser';
import withFetchData from '../../../modules/fetchData/withFetchData';
import ModalItem from '../../modal/ModalItem';
import ModalManager from '../../modal/ModalManager';
import PageContentBlock from '../../page/PageContentBlock';
import PageHeader from '../../page/PageHeader';
import PageHeaderMenu from '../../page/PageHeaderMenu';
import PageHeaderTitle from '../../page/PageHeaderTitle';
import Alert from '../../util/alert/Alert';
import FilterValuesPicker from '../../util/FilterValuesPicker';
import DeclarationContextProvider, { DeclarationContexts } from './DeclarationContext';
import DeclarationsTable from './DeclarationsTable';
import DeclarationModal from './DeclarationModal';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';

const { PROFILE } = DeclarationContexts;
const { CREATED } = declarationStatuses;

/*
* t('declarations.status.CREATED')
* t('declarations.status.SUBMITTED')
* t('declarations.status.WITHDRAWN')
* t('declarations.status.DECLINED')
* t('declarations.status.APPROVED')
* t('declarations.status.REIMBURSED')
* */

function DeclarationsPage({
	load,
	data: declarations,
	error,
	loading,
	pagination,
	onPaginationChangePage,
	onPaginationChangePageSize,
}) {
	const user = useAuthUser();
	const { t } = useTranslation();
	const location = useLocation();
	const [statuses, setStatuses] = useState([]);

	const declarationStatusesArr = useMemo(() => Object.values(declarationStatuses), []);

	useEffect(() => {
		if (user?.id) {
			load(getClaimFormsByEmployee(user.id, statuses));
		}
	}, [load, pagination.page, pagination.pageSize, user.id, statuses]);

	const menuItems = useMemo(() => [{
		name: t('declarations.page-block.newDeclaration'),
		to: location.pathname,
		state: { modal: 'editDeclarations' },
		icon: 'receipt',
	}], [t, location]);

	const showAlert = useMemo(() => declarations?.some(declaration => declaration.status === CREATED), [declarations]);

	return (
		<DeclarationContextProvider value={PROFILE}>
			<ModalManager>
				<ModalItem state="editDeclarations" component={DeclarationModal} location={location} />
			</ModalManager>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderMenu items={menuItems} />
						<PageHeaderTitle
							headline={t('declarations.profile.headline')}
							subHeading={(
								<Typography paragraph>
									{t('declarations.profile.subHeading')}
								</Typography>
							)}
						/>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-end', marginTop: '1rem' }}>
								<FilterValuesPicker
									options={declarationStatusesArr}
									selected={statuses}
									onChange={(selectedStatuses) => setStatuses(selectedStatuses)}
									i18nKey="declarations.status"
									loading={loading}
								/>
							</Grid>
						</Grid>
					</PageHeader>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{showAlert && (
						<Alert type={Alert.TYPE_WARNING}>
							{t('declarations.profile.notSubmittedWarning')}
						</Alert>
					)}
					<PageContentBlock>
						{ loading && (
							<Loader />
						)}
						{ error && (
							<ApiError error={error} />
						)}
						{ Array.isArray(declarations) && !loading && (
							<DeclarationsTable
								declarations={declarations}
								location={location}
								pagination={pagination}
								onPaginationChangePage={onPaginationChangePage}
								onPaginationChangePageSize={onPaginationChangePageSize}
							/>
						)}
					</PageContentBlock>
				</Grid>
			</Grid>
		</DeclarationContextProvider>
	);
}

export default withFetchData(null, {
	customId: () => 'EmployeeDeclarations',
	pagination: true,
	paginationPageSize: 25,
	keepData: true,
})(DeclarationsPage);
