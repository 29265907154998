import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose, reduce } from 'ramda';
import withRouter from '../../hoc/withRouter';
import withTimeSlots from '../../modules/timeSlots/withTimeSlots';
import PageContentBlock from '../page/PageContentBlock';
import ModalManager from '../modal/ModalManager';
import ModalItem from '../modal/ModalItem';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';
import ScopeTimeSlotForm from '../scope/edit/ScopeTimeSlotForm';
import CourseGroupTimeSlotsList from './CourseGroupTimeSlotsList';
import CourseGroupTimeSlotsAddModal from './CourseGroupTimeSlotsAddModal';
import CourseGroupTimeSlotsDeleteModal from './CourseGroupTimeSlotsDeleteModal';
import CourseGroupTimeSlotsLinkedModal from './CourseGroupTimeSlotsLinkedModal';
import CourseGroupTimeSlotsZoomMeetingModal from './CourseGroupTimeSlotsZoomMeetingModal';
import CourseGroupTimeSlotsAttendanceModal from './CourseGroupTimeSlotsAttendanceModal';

class CourseGroupTimeSlots extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editTimeSlot: null,
		};
	}

	handleTimeSlotClick = (timeSlot) => {
		this.setState({
			editTimeSlot: timeSlot,
		});
	}

	handleTimeSlotStopEdit = () => {
		this.setState({
			editTimeSlot: null,
		});
	}

	handleTimeSlotDeleteClose = () => {
		this.setState({
			editTimeSlot: null,
		});
	}

	handleTimeSlotEditDelete = () => {
		const { navigate, location } = this.props;

		navigate(location.pathname, { state: { modal: 'deleteTimeSlot' } });
	}

	handleTimeSlotZoomMeeting = (timeSlot) => {
		const { navigate, location } = this.props;

		navigate(location.pathname, {
			state: {
				modal: 'zoomMeetingTimeSlot',
				timeSlotId: timeSlot.id,
			},
		});
	}

	handleTimeSlotAttendance = (timeSlot) => {
		const { navigate, location } = this.props;

		navigate(location.pathname, {
			state: {
				modal: 'attendanceTimeSlot',
				timeSlotId: timeSlot.id,
			},
		});
	}

	renderContent() {
		const { group, loading, error, timeSlots } = this.props;
		const { editTimeSlot } = this.state;

		if (error) return (
			<ApiError error={error} />
		);

		if (loading || !timeSlots) return (
			<Loader />
		);

		return (
			<>
				<ModalManager>
					<ModalItem
						state="addTimeSlot"
						component={CourseGroupTimeSlotsAddModal}
						group={group}
						timeSlots={timeSlots}
					/>
					<ModalItem
						state="deleteTimeSlot"
						component={CourseGroupTimeSlotsDeleteModal}
						group={group}
						timeSlot={editTimeSlot}
						onClose={this.handleTimeSlotDeleteClose}
					/>
					<ModalItem
						state="viewLinkedTimeSlots"
						component={CourseGroupTimeSlotsLinkedModal}
						group={group}
						timeSlots={timeSlots}
					/>
					<ModalItem
						state="zoomMeetingTimeSlot"
						component={CourseGroupTimeSlotsZoomMeetingModal}
						group={group}
						timeSlots={timeSlots}
					/>
					<ModalItem
						state="attendanceTimeSlot"
						component={CourseGroupTimeSlotsAttendanceModal}
						group={group}
						timeSlots={timeSlots}
					/>
				</ModalManager>
				{editTimeSlot ? (
					<ScopeTimeSlotForm
						key={editTimeSlot.id}
						scope={group}
						timeSlot={editTimeSlot}
						onSave={this.handleTimeSlotStopEdit}
						onCancel={this.handleTimeSlotStopEdit}
						onDelete={this.handleTimeSlotEditDelete}
					/>
				) : (
					<CourseGroupTimeSlotsList
						timeSlots={timeSlots}
						group={group}
						onTimeSlotClick={this.handleTimeSlotClick}
						onTimeSlotZoomMeeting={this.handleTimeSlotZoomMeeting}
						onTimeSlotAttendance={this.handleTimeSlotAttendance}
					/>
				)}
			</>
		);
	}

	render() {
		const { location, timeSlots, t } = this.props;
		const { editTimeSlot } = this.state;

		const contentBlockLinks = [];

		const linkedCount = reduce(
			(tot, slot) => tot + (slot.referencingCourseGroups ? slot.referencingCourseGroups.length - 1 : 0),
			0, timeSlots || [],
		);

		if (linkedCount > 0) contentBlockLinks.push({
			to: location.pathname,
			state: { modal: 'viewLinkedTimeSlots' },
			label: t('scope.course.timeslots.viewLinked'),
			icon: 'link',
			basic: true,
		});

		contentBlockLinks.push({
			to: location.pathname,
			state: { modal: 'addTimeSlot' },
			label: t('scope.course.timeslots.addTimeslots'),
			icon: 'playlist_add',
		});

		return (
			<PageContentBlock
				title={t('general.timeslots')}
				icon="date_range"
				links={editTimeSlot ? null : contentBlockLinks}
				contentPadding={!!editTimeSlot}
				linksInMenuBreakpoint="lg"
			>
				{this.renderContent()}
			</PageContentBlock>
		);
	}
}

CourseGroupTimeSlots.propTypes = {
	group: PropTypes.object.isRequired,
	timeSlots: PropTypes.array,
};

export default compose(
	withTranslation(),
	withRouter,
	withTimeSlots(props => props.group.id, false),
)(CourseGroupTimeSlots);
