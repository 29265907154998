import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
const PREFIX = 'MobilePhoneActions';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		display: 'flex',
		'& > span': {
			flex: 1,
		},
		[theme.breakpoints.down('md')]: {
			display: 'block',
			'& > span': {
				display: 'block',
			},
		},
	}
}));

const MobilePhoneActions = ({ client, onlyActions, }) => {
	const { mobilePhone } = client;

	if (!mobilePhone) return null;

	let cleanPhone = mobilePhone
		.replace(/ /g, '')
		.replace('+', '')
		.replace(/^0/, '');

	if (cleanPhone && cleanPhone.indexOf('31') !== 0 && cleanPhone.length <= 9) cleanPhone = `31${cleanPhone}`;

	const actions = (
		<i>
			<a href={`https://wa.me/${cleanPhone}`} target="_blank" rel="noreferrer">WhatsApp</a>
			{', '}
			<a href={`https://web.whatsapp.com/send?phone=${cleanPhone}`} target="_blank" rel="noreferrer">web</a>
			{' or '}
			<a href={`tel:${mobilePhone}`} target="_blank" rel="noreferrer">call</a>
		</i>
	);

	if (onlyActions) return actions;

	return (
        <Root className={classes.root}>
			<span>{mobilePhone}</span>
			{actions}
		</Root>
    );
};

MobilePhoneActions.propTypes = {
	client: PropTypes.object,
	onlyActions: PropTypes.bool,
	isEmployee: PropTypes.bool, // eslint-disable-line
};

MobilePhoneActions.defaultProps = {
	onlyActions: false,
	isEmployee: false,
};

export default (MobilePhoneActions);
