import React from 'react'
import { mergeDeepRight } from 'ramda'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { themeSource } from '../../MuiProvider'

const defaultTheme = createTheme();

const liveTheme = createTheme(mergeDeepRight(themeSource, {
	palette: {
		background: {
			// default: '#5E2163',
			default: defaultTheme.palette.grey[100],
		},
	},
	shape: {
		borderRadius: 9,
	}
}));

const withLiveTheme = (WrappedComponent) => (props) => {
	return (
		<ThemeProvider theme={liveTheme}>
			<WrappedComponent {...props} />
		</ThemeProvider>
	);
};

export default withLiveTheme
