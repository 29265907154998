import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import TableCell from '@mui/material/TableCell'
import withRouter from '../../hoc/withRouter';
import withFetchData from '../../modules/fetchData/withFetchData'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { getCourseLocationsList } from '../../actions/locationActions'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'

class LocationListPage extends Component {

	render() {
		const { loading, error, data: locations, location } = this.props;

		return (
			<Fragment>
				<PageHeader>
					<PageHeaderTitle headline={`Course locations`} />
				</PageHeader>
				{
					error && <Alert type={Alert.TYPE_WARNING}>
						{error.error}
					</Alert>
				}
				{loading && <Loader/> ||
					<Paper>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell size="small">Name</TableCell>
									<TableCell size="small">Address</TableCell>
									<TableCell size="small"># Classrooms</TableCell>
									<TableCell size="small"/>
								</TableRow>
							</TableHead>
							<TableBody>
								{locations
									? locations
										.sort((a,b) => (a.name||'').localeCompare(b.name))
										.map(item => (
											<TableRow key={item.id}>
												<TableCell size="small">{item.name}</TableCell>
												<TableCell size="small">{item.street} {item.houseNumber}, {item.zipcode} {item.city}</TableCell>
												<TableCell size="small">{item.rooms.length}</TableCell>
												<TableCell size="small" align="right">
													<IconButton component={Link} to={`${location.pathname}/${item.id}`}>
														<Icon>keyboard_arrow_right</Icon>
													</IconButton>
												</TableCell>
											</TableRow>
										))
									:
									<TableRow>
										<TableCell colSpan={5}>
											<em>No content to show</em>
										</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
					</Paper>
				}
			</Fragment>
		)
	}

	componentDidUpdate(prevProps) {
		const { scope } = this.props;

		if (!scope) return;

		if (!prevProps.scope || prevProps.scope.id !== scope.id){
			this.props.refresh();
		}
	}
}

export default withRouter(withWorkingScope(withFetchData((props) => {
	return props.scope ? getCourseLocationsList(props.scope.id) : undefined
})(LocationListPage)));
