import { chipClasses } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
import classNames from 'classnames'
import Chip from '@mui/material/Chip'
import { PropTypesMoney } from '../../../helpers/money'
import { formatLiveStatsBigMoney } from '../liveStatsBlock/LiveStatsBlock'

const StyledChip = styled(Chip)(({ theme }) => ({
    [`&.${chipClasses.root}`]: {
		height: 28,
		'&.positive': {
			borderColor: theme.palette.success.main,
			backgroundColor: theme.palette.success.light,
			color: theme.palette.text.main,
		},
		'&.negative': {
			borderColor: theme.palette.error.main,
			backgroundColor: theme.palette.error.light,
			color: theme.palette.text.main,
		},
	},

    [`& .${chipClasses.label}`]: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	}
}));

const LiveMoneyChip = ({ money, }) => {
	return (
		<StyledChip
			label={formatLiveStatsBigMoney(money)}
			className={classNames({
				positive: Boolean(money.amount > 0),
				negative: Boolean(money.amount < 0),
			})}
			variant="outlined"
		/>
	);
};

LiveMoneyChip.propTypes = {
	money: PropTypesMoney.isRequired,
};

export default LiveMoneyChip
