import { styled } from '@mui/material';
import React, { PureComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import DeclarationsPage from './components/financial/declarations/DeclarationsPage';
import ProfileDeclarationsPage from './components/financial/declarations/ProfileDeclarationsPage';
import CompanyCaptionsPage from './components/manage/company/CompanyCaptionsPage';
import CompanyFunctionsPage from './components/manage/company/functions/CompanyFunctionsPage';
import CompanyFunctionOverviewPage from './components/manage/company/functions/CompanyFunctionOverviewPage';
import CompanyFAQPage from './components/manage/company/faq/CompanyFAQPage';
import CompanyTemplatesCourseDescriptionPage from './components/manage/company/templates/CompanyTemplatesCourseDescriptionPage';
import CompanyTemplatesMailPage from './components/manage/company/templates/CompanyTemplatesMailPage';
import PartnerPage from './components/partner/PartnerPage';
import PropertyEditorPage from './components/properties/PropertyEditorPage';
import ErrorBoundary from './components/util/ErrorBoundary';
import withRouter from './hoc/withRouter';
import InfoHandler from './modules/info/InfoHandler';
import Header from './components/header/Header';
import ModalManager from './components/modal/ModalManager';
import ModalItem from './components/modal/ModalItem';
import ScopeDeleteModal from './components/scope/ScopeDeleteModal';
import withAuthProvider from './modules/auth/withAuthProvider';
import HeaderMenu from './components/header/HeaderMenu';
import DashboardPage from './components/dashboard/DashboardPage';
import DiscountCodesPage from './components/discountCodes/DiscountCodesPage';
import PlannerPage from './components/scope/planner/PlannerPage';
import CourseGroupPage from './components/course/CourseGroupPage';
import CoursePage from './components/course/CoursePage';
import EmployeeListPage from './components/employees/EmployeeListPage';
import EmployeeOverviewPage from './components/employees/EmployeeOverviewPage';
import EmployeeFinancePage from './components/employees/EmployeeFinancePage';
import ClientListPage from './components/clients/ClientListPage';
import ClientOverviewPage from './components/clients/ClientOverviewPage';
import ApplicationsPage from './components/applications/ApplicationsPage';
import ApplicationsCreatePage from './components/applications/ApplicationsCreatePage';
import BatchCheck from './components/financial/batchCheck/BatchCheck';
import FinancialDashboardPage from './components/financial/dashboard/FinancialDashboardPage';
import FeedPage from './components/feed/FeedPage';
import NotFoundPage from './components/util/NotFoundPage';
import NotificationHandler from './modules/notification/NotificationHandler';
import LocationPage from './components/location/LocationPage';
import ProfilePage from './components/profile/ProfilePage';
import CompanyDashboardPage from './components/manage/company/CompanyDashboardPage';
import CourseDashboardPage from './components/manage/CourseDashboardPage';
import TeacherDashboardPage from './components/manage/TeacherDashboardPage';
import StudiesOverviewPage from './components/manage/StudiesOverviewPage';
import EmailOverviewPage from './components/manage/EmailOverviewPage';
import TaskListPage from './components/taskList/TaskListPage';
import { getSavedWorkingScopes } from './modules/scope/scopeUtil';
import { workingScopeSelectList } from './modules/scope/scopeActions';
import withRootProvider from './modules/scope/withRootProvider';
import BatchesPage from './components/financial/batches/BatchesPage';
import BundlePage from './components/bundle/BundlePage';

let documentReady = false;
document.addEventListener('DOMContentLoaded', () => {
    documentReady = true;
});

export function disabledPortalBackground(disabled) {
    if (!documentReady) {
        return setTimeout(disabledPortalBackground, 100, disabled);
    }

    const body = document.getElementsByTagName('body')[0];

    if (disabled) {
        // Disabled background, means adding the 'portal' class
        body.classList.add('portal');
    } else {
        body.classList.remove('portal');
    }

    return true;
}

const PREFIX = 'PortalPage';

const classes = {
    root: `${PREFIX}-root`,
    spacer: `${PREFIX}-spacer`,
    main: `${PREFIX}-main`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'block',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            position: 'relative',
        },
    },
    [`& .${classes.spacer}`]: {
        ...theme.mixins.toolbar,
    },
    [`& .${classes.main}`]: {
        flex: '1 1 auto',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
}));

class PortalPage extends PureComponent {
    componentDidMount() {
        // eslint-disable-next-line no-shadow
        const { workingScopeSelectList } = this.props;

        // This makes sure the background image is removed
        disabledPortalBackground(true);

        // Pre-set the scope selector
        const scopeList = getSavedWorkingScopes();
        if (scopeList.length > 0) {
            workingScopeSelectList(scopeList);
        }
    }

    componentWillUnmount() {
        disabledPortalBackground(false);
    }

    render() {
        const { rootCapabilities } = this.props;

        return (
            <Root className={classes.root}>
                <ModalManager>
                    <ModalItem state="scopeDelete" component={ScopeDeleteModal} />
                </ModalManager>
                <Header>
                    <HeaderMenu rootCapabilities={rootCapabilities} />
                </Header>
                <main className={classes.main}>
                    <div className={classes.spacer} />

                    <ErrorBoundary>
                        <Routes>
                            <Route index element={<DashboardPage />} />

                            <Route path="planner" element={<PlannerPage />} />
                            <Route path="planner/course/:id/group/:groupId/*" element={<CourseGroupPage />} />
                            <Route path="planner/course/:id/*" element={<CoursePage />} />

                            <Route path="tasks" element={<TaskListPage />} />

                            <Route path="employees" element={<EmployeeListPage />} />
                            <Route path="employees/:employeeId/view" element={<EmployeeOverviewPage />} />
                            <Route path="employees/:employeeId/finance/:month" element={<EmployeeFinancePage />} />

                            <Route path="customers" element={<ClientListPage />} />
                            <Route path="customers/:clientId" element={<ClientOverviewPage />} />

                            <Route path="applications" element={<ApplicationsPage />} />
                            <Route path="applications/create" element={<ApplicationsCreatePage />} />

                            <Route path="promotion/discount" element={<DiscountCodesPage />} />
                            <Route path="promotion/partners/*" element={<PartnerPage />} />
                            <Route path="promotion/bundle" element={<BundlePage />} />

                            <Route path="financial/batch" element={<BatchCheck />} />
                            <Route path="financial/dashboard" element={<FinancialDashboardPage />} />
                            <Route path="financial/batches/*" element={<BatchesPage />} />
                            <Route path="financial/declarations" element={<DeclarationsPage />} />
                            <Route path="company/dashboard" element={<CompanyDashboardPage />} />
                            <Route path="company/faq" element={<CompanyFAQPage />} />
                            <Route path="company/captions" element={<CompanyCaptionsPage />} />
                            <Route path="company/functions" element={<CompanyFunctionsPage />} />
                            <Route path="company/function/:id" element={<CompanyFunctionOverviewPage />} />
                            <Route path="company/templates/mail" element={<CompanyTemplatesMailPage />} />
                            <Route path="company/templates/course" element={<CompanyTemplatesCourseDescriptionPage />} />
                            <Route path="course/dashboard" element={<CourseDashboardPage />} />
                            <Route path="teacher/dashboard" element={<TeacherDashboardPage />} />
                            <Route path="studies/overview" element={<StudiesOverviewPage />} />
                            <Route path="email/overview/:id?" element={<EmailOverviewPage />} />
                            <Route path="location/*" element={<LocationPage />} />
                            <Route path="properties" element={<PropertyEditorPage />} />
                            <Route path="feed" element={<FeedPage />} />

                            <Route path="profile/*" element={<ProfilePage />} />
                            <Route path="profile/declarations" element={<ProfileDeclarationsPage />} />
                            <Route element={<NotFoundPage />} />
                        </Routes>
                    </ErrorBoundary>
                </main>
                <NotificationHandler />
                <InfoHandler />
            </Root>
        );
    }
}
export default withRouter(withAuthProvider(withRootProvider(connect(null, {
    workingScopeSelectList,
})(PortalPage))));
