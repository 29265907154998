import React from 'react'
import PropTypes from 'prop-types'
import withSubScopesNoLoading from '../../../modules/scope/withSubScopesNoLoading'
import ScopeCourse from './ScopeCourse'
import PlannerHeader from './PlannerHeader'

const ScopeFaculty = ({ scope, subScopes, parentList, filter }) => {
	parentList = (parentList || []).concat([scope]);

	if(!subScopes || subScopes.length === 0) return (
		<div>
			<PlannerHeader scopes={parentList} />
			<p style={{margin: '10px 0 40px 0'}}>
				<em>No studies for this faculty.</em>
			</p>
		</div>
	)

	return (
		<div>
			{subScopes && subScopes.map(scope => {
				if(scope._class !== 'Course') return null;

				return (
					<ScopeCourse key={scope.id}
								 scope={scope}
								 parentList={parentList}
								 filter={filter} />
				);
			})}
		</div>
	);
}

ScopeFaculty.propTypes = {
	scope: PropTypes.object.isRequired,
	parentList: PropTypes.array,
	filter: PropTypes.object,
}

export default withSubScopesNoLoading(ScopeFaculty)
