import React from 'react'
import { styled } from '@mui/material/styles';
import { omit } from 'ramda'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next';
import { editScopeTypes } from '../../../actions/scopeEditActions'
import TagEditor from '../../util/TagEditor'
import editScope from './editScope'

const PREFIX = 'ScopeInstituteForm';

const classes = {
    paper: `${PREFIX}-paper`,
};

const Root = styled('form')(({ theme }) => ({
    [`& .${classes.paper}`]: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.default,
	}
}));

const PublishedOptions = [
	{value: 'published', label: 'Published'},
	{value: 'draft', label: 'Draft'}
];

const PublishedValues = PublishedOptions.map(o => o.value);

const ScopeInstituteForm = ({
    saveScope, showButtons, saving, fields, parent,
    bindState, bindStateSwitch, bindStateDirect,
    switchCheckedFromValue,
}) => {
	const { t } = useTranslation();
	return (
        <Root onSubmit={saveScope}>
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<TextField label={t('general.name')}
							   name="name"
							   fullWidth
							   value={fields.name}
							   onChange={bindState('name')} />
				</Grid>

				<Grid item sm={6} xs={12}>
					<TextField label={t('general.abbreviation')}
							   name="abbreviation"
							   fullWidth
							   value={fields.abbreviation}
							   onChange={bindState('abbreviation')} />
				</Grid>

				<Grid item sm={6} xs={12}>
					<TextField label="City"
							   name="city"
							   fullWidth
							   value={fields.city}
							   onChange={bindState('city')} />
				</Grid>

				<Grid item sm={6} xs={12}>
					<FormControlLabel
						label={t('scope.edit.publishedForCustomers')}
						control={
							<Switch checked={switchCheckedFromValue(PublishedValues, fields.published)}
									onChange={bindStateSwitch('published', PublishedValues)} />
						}
					/>
				</Grid>

				<Grid item>
					<Paper className={classes.paper}>
						<p>Add keywords to this university. They are used for searching and to show at the bottom of the page. Make sure these keywords are actually relevant to only the university (and not the given courses for example).</p>
						<TagEditor tags={fields.keywords}
								   onChange={bindStateDirect('keywords')} />
					</Paper>
				</Grid>

				{showButtons && (
					<Grid item>
						<Button variant="contained" color="default"
								disabled={saving}
								onClick={saveScope}>
							{saving
								? t('general.saving')
								: parent
									? 'Add university'
									: 'Edit university'
							}
						</Button>
					</Grid>
				)}
			</Grid>
		</Root>
    );
}

export default (
	editScope(
		editScopeTypes.INSTITUTE,
		{
			fields: {
				name: editScope.Joi.string().required().default(''),
				abbreviation: editScope.Joi.string().required().default(''),
				published: editScope.Joi.string().valid(PublishedValues).default(PublishedValues[0]),
				city: editScope.Joi.string().required().default(''),
				keywords: editScope.Joi.string().default([]),
				properties: editScope.Joi.object().default({}),
			},
			convertBeforeLoad: (scope) => {
				if(!scope) return scope;
				return {
					...scope,
					keywords: !scope.properties || !scope.properties.keywords ? [] : scope.properties.keywords,
				}
			},
			convertBeforeSave: (fields) => {
				return omit(['keywords'], {
					...fields,
					properties: {
						...fields.properties,
						keywords: fields.keywords ? fields.keywords : [],
					}
				})
			},
		}
	)(ScopeInstituteForm)
)
