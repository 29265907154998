import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { languages } from '../../../helpers/localization';

const PREFIX = 'CompanyPropertiesCaptionEditor';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    textField: `${PREFIX}-textField`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.wrapper}`]: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(2, 0),
	},

    [`& .${classes.textField}`]: {
		marginRight: theme.spacing(1),
	}
}));

function CompanyPropertiesCaptionEditor({ caption, setCaption, saving }) {


	const handleChange = useCallback((lang) => (event) => {
		const { value } = event.target;
		let newCaption;
		if (value.length === 0) {
			newCaption = { ...caption };
			delete newCaption[lang];
		} else {
			newCaption = { ...caption, [lang]: event.target.value };
		}
		setCaption(newCaption);
	}, [caption, setCaption]);

	return Object.keys(languages).map(lang => (
		<Root className={classes.wrapper} key={lang}>
			<TextField
				value={caption?.[lang] || ''}
				onChange={handleChange(lang)}
				label={languages[lang]}
				fullWidth
				multiline
				variant="outlined"
				className={classes.textField}
				disabled={saving}
			/>
		</Root>
	));
}

CompanyPropertiesCaptionEditor.propTypes = {
	selectedKey: PropTypes.string.isRequired,
	handleClose: PropTypes.func.isRequired,
};

export default CompanyPropertiesCaptionEditor;
