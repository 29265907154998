import { Schemas, CALL_API } from './schemas'

export const LOCATION = 'LOCATION';

export const getCourseLocationsList = (scopeId) => ({
	[CALL_API]: {
		type: LOCATION,
		endpoint: `scope/${scopeId}/course-location`
	}
});

export const getCourseLocation = (locationId) => ({
	[CALL_API]: {
		type: LOCATION,
		endpoint: `course-location/${locationId}`,
		schema: Schemas.COURSE_LOCATION
	}
});

export const patchCourseLocation = (locationId, data) => ({
	[CALL_API]: {
		type: LOCATION,
		endpoint: `course-location/${locationId}`,
		schema: Schemas.COURSE_LOCATION,
		method: 'PATCH',
		body: data
	}
});

export const patchRoom = (roomId, data) => ({
	[CALL_API]: {
		type: LOCATION,
		endpoint: `class-room/${roomId}`,
		schema: Schemas.ROOM,
		method: 'PATCH',
		body: data
	}
});

export const deleteRoom = (roomId) => ({
	[CALL_API]: {
		type: LOCATION,
		endpoint: `class-room/${roomId}`,
		schema: Schemas.ROOM,
		method: 'DELETE'
	}
});
