import React, { PureComponent } from 'react'
import getDisplayName from 'react-display-name'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { createSelector } from 'reselect'
import Loader from '../../components/util/loader/Loader'
import ApiError from '../../components/util/ApiError'
import { getKey, Schemas } from '../../actions/schemas'
import withFetchData from '../fetchData/withFetchData'
import { getGroupTimeslots } from './timeSlotsActions'

const getTimeSlotMapping = (groupIdFromProps) =>
	(state, props) => state.groupTimeSlotsMapping[groupIdFromProps(props)];
const getTimeSlotEntities =
	(state) => state.entities[getKey(Schemas.TIME_SLOT)];

const makeGetTimeSlots = (groupIdFromProps) => {
	return createSelector(
		[getTimeSlotMapping(groupIdFromProps), getTimeSlotEntities],
		(mapping, entities) => {
			return mapping && mapping.map(id => entities[id]);
		}
	);
}

const withTimeSlots = (groupIdFromProps = (props) => props.groupId, handleFetchData = true) => (ComposedComponent) => {
	const Enhanced = withFetchData(undefined, {
		makeMapStateToProps: (state, props) => {
			const getTimeSlots = makeGetTimeSlots(groupIdFromProps);
			return (state, props) => ({
				timeSlots: getTimeSlots(state, props),
			});
		},
	})(class WithTimeSlots extends PureComponent {
		static displayName = `withTimeSlots(${getDisplayName(ComposedComponent) || 'Unknown'})`;

		render() {
			const { timeSlots, loading, error } = this.props;

			if(handleFetchData){
				if(error) return (
					<ApiError error={error} />
				);

				if(!timeSlots || loading) return (
					<Loader />
				);
			}

			return <ComposedComponent {...this.props} />;
		}

		componentDidMount() {
			const { timeSlots, load } = this.props;

			// Not loaded yet, so load them
			if(!timeSlots){
				 load(getGroupTimeslots(groupIdFromProps(this.props)));
			}
		}

		componentDidUpdate(prevProps){
			const { timeSlots, load } = this.props;

			const newId = groupIdFromProps(this.props);
			const oldId = groupIdFromProps(prevProps);

			// Check if group id changed and no timeSlots present -> load
			if(oldId !== newId && !timeSlots){
				load(getGroupTimeslots(groupIdFromProps(this.props)));
			}
		}
	});

	return hoistNonReactStatics(Enhanced, ComposedComponent);
};

export default withTimeSlots
