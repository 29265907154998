import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { formatMoney } from '../../../helpers/formatter'
import { niceDateTime, timeFromNow } from '../../../helpers/datetime'
import { orderStatusStyle, parseStatus, PENDING, StatusChip } from '../utils'

const propTypes = {
	location: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
}

class ClientOrderTableBody extends Component {
	render() {
		const
			{ location: { pathname }, order } = this.props,
			{ id, totalPrice, status, orderDateTime } = order

		return (
			<TableRow>
				<TableCell>
					<Link to={pathname} state={{ modal: 'viewOrder', orderId: id }}>
						{id}
					</Link>
				</TableCell>
				<TableCell align={'right'}>{formatMoney(totalPrice)}</TableCell>
				<TableCell>
					<StatusChip
						style={{ color: 'white' }}
						component={Link}
						to={pathname}
						state={{ modal: 'viewOrder', orderId: id }}
						label={parseStatus(status)}
						className={orderStatusStyle[status]}
						variant={orderStatusStyle[status] === PENDING ? 'outlined' : 'default'}
					/>
				</TableCell>
				<TableCell>
					<Grid container direction={'column'} justifyContent={'center'}>
						<Grid item>
							{niceDateTime(orderDateTime)}
						</Grid>
						<Grid item>
							{timeFromNow(orderDateTime)}
						</Grid>
					</Grid>
				</TableCell>
				<TableCell>
					<IconButton
						component={Link}
						to={pathname}
						state={{ modal: 'viewOrder', orderId: id }}
					>
						<Icon>keyboard_arrow_right</Icon>
					</IconButton>
				</TableCell>
			</TableRow>
		)
	}
}

ClientOrderTableBody.propTypes = propTypes

export default ClientOrderTableBody
