import React from 'react';
import { Trans } from 'react-i18next';
import propertyTypes from '../constants/PropertyTypes';

export const createSearchParams = (properties) => {
	const params = new URLSearchParams();
	// Creates an array of keys and values. If the value is an array, the URL parameter will render as ?key=1&key=2&etc
	Object.entries(properties)
		.filter(property => !!property[1] || (property[1]?.length > 0 && property[1] !== 'empty'))
		.forEach(property => {
			if (property[1]?.length > 0) {
				property[1].forEach(prop => params.append(property[0], prop));
			} else {
				params.append(property[0], property[1]);
			}
		});
	return params;
};

export const returnDefaultValue = (prop) => {
	let defaultValue;
	if (prop.type === propertyTypes.BOOLEAN) {
		defaultValue = true;
	} else if (prop.type === propertyTypes.MULTI_SELECT || prop.type === propertyTypes.MULTI_TEXT) {
		defaultValue = [];
	} else if (prop.type === propertyTypes.SELECT) {
		defaultValue = prop.options.choices[0]?.key || prop.options.choices[0];
	} else {
		defaultValue = '';
	}
	return defaultValue;
};

export const getPropertyByKey = (key, properties) => properties.find(choice => {
	if (choice?.key) return choice.key === key;
	return choice === key;
});

export const propertyLabel = (property, lang) => {
	if (property.options?.label?.[lang]) {
		return property.options?.label[lang];
	}
	return property.key;
};

export const choiceLabel = (choice, lang) => {
	if (choice?.label) {
		return choice?.label[lang] || choice.key;
	}
	return choice;
};

export const choiceLabelByKey = (key, options, lang) => {
	const item = options?.choices?.find(choice => {
		if (choice?.key) return choice.key === key;
		return choice === key;
	});
	return choiceLabel(item, lang);
};

export const propertyValue = (property, value, lang) => {
	if (property.options?.choices?.length) {
		if (Array.isArray(value)) {
			return value.map(item => choiceLabelByKey(item, property.options, lang)).join(', ');
		}
		return choiceLabelByKey(value, property.options, lang);
	}
	if (Array.isArray(value)) {
		return value.map(item => item).join(', ');
	}
	if (typeof value === 'boolean') {
		return value ? <Trans i18nKey="general.yes" /> : <Trans i18nKey="general.no" />;
	}
	return value;
};
