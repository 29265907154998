import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'

const PREFIX = 'PageHeaderMenuSub';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    button: `${PREFIX}-button`,
    paper: `${PREFIX}-paper`,
    popper: `${PREFIX}-popper`,
    icon: `${PREFIX}-icon`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.wrapper}`]: {
		display: 'inline-block',
	},

    [`& .${classes.button}`]: {
		minWidth: 0,
		marginLeft: theme.spacing(2),
	},

    [`& .${classes.paper}`]: {
		margin: theme.spacing(1),
	},

    [`& .${classes.popper}`]: {
		zIndex: theme.zIndex.appBar,
	},

    [`& .${classes.icon}`]: {
		marginRight: theme.spacing(1),
	}
}));

class PageHeaderMenuSub extends Component {
    state = {
    	open: false
	}

	render(){
    	const { items, } = this.props;
		const { open } = this.state;

    	if(items.length === 0) return null;

		return (
            <Root className={classes.wrapper}>
				<Button onClick={this.toggleMenu}
						aria-owns={open ? 'page-menu-sub' : null}
						aria-haspopup="true"
						className={classes.button}
						ref={node => {
							this.target = node;
						}}>
					<Icon>more_vert</Icon>
				</Button>
				<Popper open={open}
						anchorEl={this.target}
						transition
						disablePortal
						placement="bottom-end"
						className={classes.popper}>
					{({ TransitionProps, placement }) => (
						<Grow {...TransitionProps}
							  id="page-menu-sub"
							  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
							<Paper className={classes.paper}>
								<ClickAwayListener onClickAway={this.handleClose}>
									<MenuList role="menu">
										{items.map(item => (
											<MenuItem
												key={item.name + item.to}
												component={Link}
												to={item.to}
												state={item.state}
											>
												{
													item.icon &&
													<Icon className={classes.icon}>
														{item.icon}
													</Icon>
												}
												{item.name}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Root>
        );
	}

	toggleMenu = () => {
		this.setState({
			open: !this.state.open
		});
	}

	handleClose = (e) => {
		if(this.target.contains(e.target)) return;

		this.setState({
			open: false
		});
	}
}

PageHeaderMenuSub.propTypes = {
	items: PropTypes.array.isRequired
};

export default (PageHeaderMenuSub)
