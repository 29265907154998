import PropTypes from 'prop-types';
import { Chip, chipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeclarationStatuses from '../../../constants/DeclarationStatuses';

const { CREATED, SUBMITTED, DECLINED, APPROVED, REIMBURSED } = DeclarationStatuses;

function statusColor(status, theme) {
    switch (status) {
        case CREATED:
            return theme.palette.warning.main;
        case SUBMITTED:
            return theme.palette.primary.main;
        case DECLINED:
            return theme.palette.error.main;
        case APPROVED:
        case REIMBURSED:
            return theme.palette.success.main;
        default:
            return theme.palette.grey[200];
    }
}

const DeclarationStatusChip = styled(Chip)(({ theme, status }) => ({
    [`&.${chipClasses.root}`]: {
        backgroundColor: statusColor(status, theme),
        color: [SUBMITTED, DECLINED].includes(status) ? theme.palette.common.white : theme.palette.common.black,
    },
}));

DeclarationStatusChip.propTypes = {
    status: PropTypes.string.isRequired,
};

export default DeclarationStatusChip;
