export function listsReducerFinal(state = {}, action){
	if(action.forList && action.response && action.response.result){
		state = {
			...state,
			[action.forList]: action.response.result,
		}
	}

	return state;
}

const listsReducer = {
	lists: listsReducerFinal,
};

export default listsReducer
