import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const rootSelector = state => state.root;

function useRootCapabilities() {
	const { rootCapabilities } = useSelector(rootSelector);

	const hasCapability = useCallback((capability) => (
		rootCapabilities && rootCapabilities.includes(capability)
	), [rootCapabilities]);

	const hasAtLeastOneCapability = useCallback((capabilities) => (
		capabilities.some((capability) => rootCapabilities.includes(capability))
	), [rootCapabilities]);

	return {
		rootCapabilities,
		hasCapability,
		hasAtLeastOneCapability,
	};
}

export default useRootCapabilities;
