import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Divider, ListItemButton } from '@mui/material';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { ShippingStatusCaptions, ShippingStatuses } from '../../constants/ShippingStatuses';
import { equals } from '../../helpers/compare'
import FilterChip from './CourseGroupOccupancyListFilter'
import OrderStatuses from '../../constants/OrderStatuses'
import { getISOFromDate } from '../../helpers/datetime'
import { formatPercentage } from '../../helpers/formatter'
import { notificationShow } from '../../modules/notification/notificationActions'
import { downloadFromApi } from '../../setup/api'
import Alert from '../util/alert/Alert'

const PREFIX = 'CourseGroupOccupancyList';

const classes = {
    listItemTextPrimary: `${PREFIX}-listItemTextPrimary`,
    selectAllLink: `${PREFIX}-selectAllLink`,
    selectList: `${PREFIX}-selectList`,
    orderStatus: `${PREFIX}-orderStatus`,
    errorStatus: `${PREFIX}-errorStatus`,
    addressDownload: `${PREFIX}-addressDownload`,
    list: `${PREFIX}-list`,
    noResults: `${PREFIX}-noResults`,
    chip: `${PREFIX}-chip`
};

const StyledAddressDownloadDiv = styled('div')(({ theme }) => ({
    [`&.${classes.addressDownload}`]: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
}));

const StyledSelectAll = styled('div')(({ theme }) => ({
    [`&.${classes.selectAllLink}`]: {
        display: 'inline-block',
        fontStyle: 'italic',
        fontSize: '90%',
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        cursor: 'pointer',
    },
}));

const StyledSelectListDiv = styled('div')(({ theme }) => ({
    [`&.${classes.selectList}`]: {
        position: 'absolute',
        left: '-99999px',
        top: '-99999px',
    },
}));

const StyledList = styled(List)(({ theme }) => ({
    [`&.${classes.list}`]: {
        paddingTop: 0,
    },

    [`& .${classes.listItemTextPrimary}`]: {
		userSelect: 'text',
	},

	[`& .${classes.orderStatus}`]: {
		marginLeft: theme.spacing(1),
		color: theme.palette.primary.main,
	},

    [`& .${classes.errorStatus}`]: {
		color: theme.palette.secondary.main,
	},

    [`& .${classes.noResults}`]: {
		'& > div': {
			width: '100%',
			marginBottom: 0,
			marginTop: theme.spacing(1),
		}
	},
}));

const defaultFilters = ['PAID', 'FULL_DISCOUNT', 'FREE', 'PENDING', 'VERIFICATION'];
const copyToClipboardStatuses = ['PAID', 'FULL_DISCOUNT', 'FREE', 'PENDING', 'VERIFICATION'];

const getSecondary = (order) => {
	const items = [];

	if(order.quantity && order.quantity > 1){
		items.push(`${order.quantity}x`);
	}

	const { discountCode, shippingStatus } = order;

	if(discountCode && discountCode.discountPercentage){
		items.push(`${formatPercentage(discountCode.discountPercentage)} discount`);
	}

	if(discountCode){
		if(discountCode._class === 'ScopeDiscountCode') items.push(discountCode.code);
		if(discountCode._class === 'AthenaCourseBundleDiscountCode') items.push(`Bundle: ${discountCode.description}`);
	}

	if (shippingStatus && shippingStatus !== ShippingStatuses.NO_SHIPPING) {
		items.push(`Booklet: ${ShippingStatusCaptions[shippingStatus] || shippingStatus}`)
	}

	return items.length === 0 ? null : items.join(', ');
};

class CourseGroupOccupancyList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFilters: false,
			activeFilters: defaultFilters,
		}
	}

	handleChipClick = (filter) => {
		const { activeFilters } = this.state;
		const activeFiltersClone = activeFilters.slice();

		const index = activeFiltersClone.indexOf(filter);
		if (index === -1) {
			activeFiltersClone.push(filter);
		} else {
			activeFiltersClone.splice(index, 1);
		}

		this.setState({ activeFilters: activeFiltersClone });
	}

	handleFilterClick = () => {
		const { showFilters } = this.state;
		this.setState({showFilters: !showFilters});
	}

	handleSelectOrDeselectAll = () => {
		const { activeFilters } = this.state;
		const orderStatusArray = Object.keys(OrderStatuses);
		if (activeFilters.length === orderStatusArray.length) {
			this.setState({activeFilters: []});
		} else {
			this.setState({activeFilters: [...Object.keys(OrderStatuses)]});
		}
	}

	handleResetFilters = () => {
		this.setState({activeFilters: defaultFilters});
	}

	selectList = React.createRef();

	render() {
		const { group, showEmail, createClientTo, orders, t } = this.props;
		const { activeFilters, showFilters } = this.state;
		const orderStatusArray = Object.keys(OrderStatuses);
		const hasActiveFilters = activeFilters.length > 0;
		const allStatusesSelected = orderStatusArray.length === activeFilters.length;

		const filteredAndSortedOrders = orders
			.filter(order => activeFilters.length === 0 || activeFilters.includes(order.orderStatus))
			.sort((a,b) => (a.client.surname).localeCompare(b.client.surname));

		const defaultFiltersActive = equals(activeFilters, defaultFilters);

		return (
            (<>
                <Grid container justifyContent="space-between" sx={{ py: 1, px: 2 }}>
					<Grid item xs>
						<Button
							color={hasActiveFilters ? "primary" : "default"}
							startIcon={<Icon>filter_list</Icon>}
							onClick={this.handleFilterClick}
						>
							{t('general.status', { count: activeFilters.length })}
							{ hasActiveFilters
								? ` (${activeFilters.length})`
								: ''
							}
						</Button>
						{
							showFilters &&
								<>
									<Button
										startIcon={<Icon>{allStatusesSelected ? 'clear' : 'select_all'}</Icon>}
										onClick={this.handleSelectOrDeselectAll}
									>
										{ allStatusesSelected
											? t('scope.course.occupancy.deselectAll')
											: t('scope.course.occupancy.selectAllFilters')
										}
									</Button>
									{ !defaultFiltersActive &&
									<Button
										startIcon={<Icon>rotate_left</Icon>}
										onClick={this.handleResetFilters}
									>
										{t('scope.course.occupancy.resetFilters')}
									</Button>
									}
								</>
						}
					</Grid>
					<Grid item>
						<StyledSelectAll
							onClick={this.handleCopyList}
							className={classes.selectAllLink}
						>
							{t('scope.course.occupancy.copyListToClipboard')}
						</StyledSelectAll>
					</Grid>
				</Grid>
                <Grid container justifyContent="flex-start">
				{
					showFilters && Object.keys(OrderStatuses).map(filter => {
						return (
							<FilterChip
								key={`filter_${filter}`}
								filter={filter}
								activeFilters={activeFilters}
								handleChipClick={this.handleChipClick}
							/>
						)
					})
				}
				</Grid>
                <Divider />
                <StyledList className={classes.list}>
					{ filteredAndSortedOrders.map((order, i) => {
						const isError = ['EXPIRED', 'CANCELED'].includes(order.orderStatus);
						const { pathname, state } = createClientTo(order.id);
						return (
							<ListItem
								key={`${order.client.id}-${i}`}
								divider
								disablePadding
							>
								<ListItemButton component={Link} to={pathname} state={state}>
									<ListItemText
										primary={showEmail ? order.client.email : (
											<>
												{order.client.fullName}
												{order.orderStatus !== 'PAID' && (
													<Typography
														variant="caption"
														color="secondary"
														display="inline"
														className={`${classes.orderStatus} ${isError && classes.errorStatus}`}
													>
														{t('scope.course.occupancy.orderStatus')}: <strong>{OrderStatuses[order.orderStatus]}</strong>
													</Typography>
												)}
											</>
										)}
										secondary={!showEmail && getSecondary(order)}
										classes={{
											primary: classes.listItemTextPrimary
										}}
									/>
									<Icon>chevron_right</Icon>
								</ListItemButton>
							</ListItem>
						);
					})}
					{
						filteredAndSortedOrders.length === 0 && (
							<ListItem className={classes.noResults}>
								<Alert type={Alert.TYPE_INFO}>
									{ defaultFiltersActive ? t('scope.course.occupancy.noOrdersYet') : t('scope.course.occupancy.noOrdersWithinFilters') }
								</Alert>
							</ListItem>
						)
					}
				</StyledList>
                {group.addressMandatory && (
					<StyledAddressDownloadDiv className={classes.addressDownload}>
						<Button
							onClick={this.handleDownloadAddressList}
							variant="outlined"
							color="primary"
							fullWidth
						>
							{t('scope.course.occupancy.downloadAddressList')}
						</Button>
					</StyledAddressDownloadDiv>
				)}
                <StyledSelectListDiv ref={this.selectList} className={classes.selectList}>
					{orders.filter(order => copyToClipboardStatuses.includes(order.orderStatus)).map(({id, client, quantity}, i) => (
						<div key={`${id}-${i}`}>
							{showEmail ? client.email : `${client.fullName}${quantity > 1 ? ` ${quantity}x` : ''}`}
						</div>
					))}
				</StyledSelectListDiv>
            </>)
        );
	}

	handleCopyList = () => {
		const { notificationShow, t } = this.props;
		if(!this.selectList || !this.selectList.current) return;

		if (document.body.createTextRange) {
			const range = document.body.createTextRange();
			range.moveToElementText(ReactDOM.findDOMNode(this.selectList.current));
			range.select();
		} else if (window.getSelection) {
			const selection = window.getSelection();
			const range = document.createRange();
			range.selectNodeContents(ReactDOM.findDOMNode(this.selectList.current));
			selection.removeAllRanges();
			selection.addRange(range);
		}

		try {
			document.execCommand('copy');
			notificationShow(t('scope.course.occupancy.copiedToClipboard'), 'success');
		} catch (err){
			alert(t('scope.course.occupancy.unsupportedBrowser'));
		}
	}

	handleDownloadAddressList = () => {
		const { group, course } = this.props;

		downloadFromApi(
			`athenastudies-course-group/${group.id}/shipping-addresses.xlsx`,
			`Address List - ${course.name} ${group.name} - ${getISOFromDate()}.xlsx`
		);
	}
}

CourseGroupOccupancyList.propTypes = {
	orders: PropTypes.array.isRequired,
	group: PropTypes.object.isRequired,
	course: PropTypes.object.isRequired,
	showEmail: PropTypes.bool.isRequired,
	createClientTo: PropTypes.func.isRequired,
}

export default withTranslation()((connect(null, {
	notificationShow
})(CourseGroupOccupancyList)))
