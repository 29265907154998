import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const SelectValueMenu = React.forwardRef(({ value, options, onChange, name, allowEmpty, disabled, ...rest }, ref) => {
	const { t } = useTranslation();
	return (
		<Select
			value={value}
			name={name}
			onChange={onChange}
			disabled={disabled}
			id={name}
			inputRef={ref}
			{...rest}
		>
			{allowEmpty && (
				<MenuItem value=""><i>{t('general.empty')}</i></MenuItem>
			)}
			{options?.map(option => (
				<MenuItem value={option} key={option}>{option}</MenuItem>
			))}
		</Select>
	);
});

SelectValueMenu.propTypes = {
	value: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	allowEmpty: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default SelectValueMenu;

export function ControlledValuePicker({ options, control, name, allowEmpty, disabled, ...rest }) {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value, ref } }) => (
				<SelectValueMenu
					value={value}
					onChange={onChange}
					disabled={disabled}
					name={name}
					options={options}
					allowEmpty={allowEmpty}
					ref={ref}
					{...rest}
				/>
			)}
		/>
	);
}

ControlledValuePicker.propTypes = {
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	control: PropTypes.object.isRequired,
	allowEmpty: PropTypes.bool,
	disabled: PropTypes.bool,
};
