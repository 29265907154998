export const paymentStatus = {
	'APPROVED': 'APPROVED',
	'DISAPPROVED': 'DISAPPROVED',
	'CANCELLED': 'CANCELLED',
	'RECKONING': 'RECKONING',
	'IN_TRANSFER': 'IN_TRANSFER',
	'PAID': 'PAID',
};

export const paymentTypeText = {
	REVENUE_SHARE: 'Commission',
	PROFIT_SHARE: 'Profit share',
	TEACHER: 'Teacher',
	BOOKLET_WRITER: 'Booklet writer',
};

export const getPaymentTypeText = (payment) => {
	const functionName = payment.contract && payment.contract.function && payment.contract.function.name;

	if(paymentTypeText[payment.type]){
		if(payment.type === 'REVENUE_SHARE' && functionName){
			return functionName;
		}

		return paymentTypeText[payment.type];
	}

	return functionName || 'Unknown payment type';
};

export const paymentTypeIcon = {
	REVENUE_SHARE: 'account_balance',
	PROFIT_SHARE: 'euro_symbol',
	TEACHER: 'school',
	BOOKLET_WRITER: 'local_library',
};

export const getPaymentTypeIcon = (payment) => {
	return paymentTypeIcon[payment.type] || 'person'
};
