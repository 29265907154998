import { createCookieDays, eraseCookie, readCookie } from '../../helpers/cookie'

const workingScopeCookie = 'PORTAL_WORKING_SCOPES';

export function saveWorkingScopes(scopes = []){
	createCookieDays(workingScopeCookie, scopes.join('-'), 2);
}

export function getSavedWorkingScopes(){
	const saved = readCookie(workingScopeCookie);
	return saved ? saved.split('-') : [];
}

export function clearSavedWorkingScopes(){
	eraseCookie(workingScopeCookie);
}
