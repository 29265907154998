import React, { Component, Fragment } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { orderRefund } from '../../../actions/orderActions'
import { PropTypesMoney } from '../../../helpers/money'
import withFormData from '../../../modules/formData/withFormData'
import { formatMoney } from '../../../helpers/formatter'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'

class ClientOrderRefundOptions extends Component {

	render() {
		const { saving, error, success, totalPrice } = this.props
		return (
			<Fragment>
				<Typography style={{ marginBottom: 20 }}>
					<strong>Cancel ALL courses contained within this order</strong> and pay
					back {formatMoney(totalPrice)}. The client will not partake in any of these courses anymore. All
					courses bought within this order will be refunded. In order to refund an individual course, click on
					the group of the course to continue. The client will receive the refund in about 2 business days.
				</Typography>

				{saving && <Loader/>}
				{error && <Alert type={Alert.TYPE_WARNING}>{error.error}</Alert>}

				<Grid container spacing={2}>
					<Grid item xs={12}>
						{success ? (
							success.refund ? (
								<Alert type={Alert.TYPE_SUCCESS}>
									<Typography>
										Action was successful, it might take a few minutes and a page refresh for the changes to be visible.
									</Typography>
									<Typography>
										The client will receive the refund in about 2 business days. Total refunded:
										{formatMoney(success.refund.totalAmount)}.
									</Typography>
								</Alert>
							) : (
								<Alert type={Alert.TYPE_WARNING}>
									A refund was not needed.
								</Alert>
							)
						) : (
							<Button
								variant="contained"
								color="primary"
								fullWidth
								disabled={saving}
								onClick={this.handleRefund}
							>
								Refund order {formatMoney(totalPrice)}
							</Button>
						)}
					</Grid>
				</Grid>
			</Fragment>
		)
	}

	handleRefund = () => {
		const { orderId, watchSubmit } = this.props
		if (!confirm('Are your sure you want to fully refund this order? The client will be removed from the list and paid back. The client will NOT be automatically informed about this.')) return
		watchSubmit(orderRefund({ orderId }), true)
	}
}

ClientOrderRefundOptions.propTypes = {
	totalPrice: PropTypesMoney.isRequired,
};

export default withFormData()(ClientOrderRefundOptions)
