import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import { isActive } from './utils'
import { formatPercentage } from '../../helpers/formatter'
import { niceDateYear } from '../../helpers/datetime'
import Icon from '@mui/material/Icon'

const PREFIX = 'DiscountCodesTable';

const classes = {
    invalidate: `${PREFIX}-invalidate`,
    expired: `${PREFIX}-expired`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.invalidate}`]: {
		color: theme.palette.error.main,
		'&:hover': {
			cursor: 'pointer',
		},
	},

    [`& .${classes.expired}`]: {
		color: theme.palette.text.disabled,
		fontSize: '0.8em',
	}
}));

const DiscountCodesTable = ({ loading, discountCodes, invalidateClicked, transformData, }) => {
	if (loading || discountCodes === undefined) return <Loader/>
	if (!discountCodes || discountCodes.length === 0)
		return <Alert type={Alert.TYPE_INFO}>No discount codes found</Alert>

	return (
        <StyledPaper>
			<Table size="small">
				<TableHead>
					<TableRow>
						{['Code', 'Discount %', 'Used / Available', 'Expires on', 'Actions'].map(name => (
							<TableCell key={name.replace(/[^A-Z0-9]/ig, '')}>{name}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{transformData(discountCodes).map(discountCode => {
						const { id, code, discountPercentage, numberOfApplications, timesApplicable, expires } = discountCode
						return (
							<TableRow key={id} hover>
								<TableCell>{code}</TableCell>
								<TableCell>{formatPercentage(discountPercentage)}</TableCell>
								<TableCell>{numberOfApplications} / {timesApplicable}</TableCell>
								<TableCell className={isActive(expires) ? '' : classes.expired}>
									{niceDateYear(expires)}
								</TableCell>
								<TableCell>
									{isActive(expires) && (
										<Icon onClick={() => invalidateClicked(discountCode)}
											  className={classes.invalidate}>
											cancel
										</Icon>
									)}
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</StyledPaper>
    );
}

DiscountCodesTable.propTypes = {
	loading: PropTypes.bool,
	discountCodes: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.array,
	]),
	invalidateClicked: PropTypes.func.isRequired,
	transformData: PropTypes.func.isRequired,
}

export default (DiscountCodesTable)
