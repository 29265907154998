import { Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { compose, equals } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { capabilities } from '../../helpers/capabilities';
import { niceDateDay, niceTime } from '../../helpers/datetime';
import withFetchData from '../../modules/fetchData/withFetchData';
import withFormData from '../../modules/formData/withFormData';
import { TIME_SLOTS } from '../../modules/timeSlots/timeSlotsActions';
import { CALL_API } from '../../setup/api';
import Alert from '../util/alert/Alert';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';

const PREFIX = 'CourseGroupTimeSlotsAttendanceModal';

const classes = {
    mainText: `${PREFIX}-mainText`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.mainText}`]: {
		marginBottom: theme.spacing(2),
	}
}));

const ApiAction = (timeslotId) => ({
	[CALL_API]: {
		type: TIME_SLOTS,
		endpoint: `course-timeslot/${timeslotId}/client`,
	},
});

const ApiActionPostTimeslotAttendance = (timeslotId, data) => {
	if (!timeslotId || !data) return false;

	return {
		[CALL_API]: {
			type: TIME_SLOTS,
			endpoint: `course-timeslot/${timeslotId}/attendance`,
			body: data,
		},
	};
};

const CourseGroupTimeSlotsAttendanceModal = ({ group, data, load, loading, modal, timeSlots, handleClose, watchSubmit, saving, error, fetchError }) => {
	const timeSlot = timeSlots.find(slot => slot.id === modal.timeSlotId);
	const [selectedIds, setSelectedIds] = useState([]);
	const { t } = useTranslation();

	const manageAttendance = group.capabilities.includes(capabilities.ManageAttendanceCapability);

	useEffect(() => {
		if (data) {
			setSelectedIds(data?.filter?.(item => item.attendance).map(item => item.client.id) || []);
		}
	}, [data]);

	useEffect(() => {
		if (typeof saving === 'undefined' || saving === false) {
			load(ApiAction(modal.timeSlotId));
		}
	}, [load, modal.timeSlotId, saving]);

	const isDirty = useMemo(() => {
		const sortedIds = data?.filter?.(item => item.attendance).map(client => client.client.id).sort() || [];
		return !equals(sortedIds, selectedIds.sort());
	}, [data, selectedIds]);

	const handleRegisterAttendance = () => {
		watchSubmit(ApiActionPostTimeslotAttendance(modal.timeSlotId, selectedIds));
	};

	const handleCloseClick = () => {
		if (!isDirty || window.confirm(t('scope.course.timeslots.attendance.dirtyClose'))) {
			handleClose();
		}
	};

	const handleAttendantClick = useCallback((clientId) => () => {
		const newArray = Array.from(selectedIds);
		if (selectedIds.includes(clientId)) {
			newArray.splice(selectedIds.indexOf(clientId), 1);
		} else {
			newArray.push(clientId);
		}
		setSelectedIds(newArray);
	}, [selectedIds]);

	const allSelected = data?.length === selectedIds.length;
	const handleSelectOrDeselectAll = () => {
		if (allSelected) {
			setSelectedIds([]);
		} else {
			setSelectedIds(data.map(client => client.client.id));
		}
	};

	return (
        <StyledDialog open={true} onClose={handleCloseClick} maxWidth="sm" fullWidth scroll="body">
			<DialogTitle>
				{t('scope.course.timeslots.attendance.title', 'Register attendance')}
			</DialogTitle>
			<DialogContent>
				<Typography>{t('scope.course.timeslots.attendance.description')}</Typography>
				<Divider style={{ margin: '1rem 0' }} />
				<Typography variant="subtitle1">{group.name}</Typography>
				<Typography variant="subtitle2">
					{niceDateDay(timeSlot?.startDateTime)}
					{' '}
					{niceTime(timeSlot?.startDateTime)}
					{' - '}
					{niceTime(timeSlot?.endDateTime)}
				</Typography>
				<Divider style={{ margin: '1rem 0' }} />
				{
					(loading || saving) && <Loader sheet />
				}
				{(!!error || !!fetchError) && (
					<ApiError error={error || fetchError} />
				)}
				{data?.length === 0 && (
					<Alert type={Alert.TYPE_INFO}>{t('scope.course.timeslots.attendance.noClientsRegistered')}</Alert>
				)}
				{data?.length > 0 && (
					<>
						{manageAttendance && (
							<Button
								startIcon={<Icon>{ allSelected ? 'clear' : 'select_all' }</Icon>}
								onClick={handleSelectOrDeselectAll}
								disabled={saving}
								variant="outlined"
							>
								{ allSelected ? t('scope.course.timeslots.attendance.deselectAll') : t('scope.course.timeslots.attendance.selectAll') }
							</Button>
						)}
						{data?.map(client => (
							<div key={client.client.id}>
								<FormControlLabel
									control={(
										<Checkbox
											checked={selectedIds.includes(client.client.id)}
											onClick={handleAttendantClick(client.client.id)}
											disabled={saving || !manageAttendance}
											color="secondary"
										/>
									)}
									label={client.client.fullName}
								/>
							</div>
						))}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseClick}>
					{t('scope.course.timeslots.attendance.close')}
				</Button>
				{manageAttendance && (
					<Button variant="contained" color="primary" onClick={handleRegisterAttendance} disabled={!isDirty || loading || saving}>
						{t('scope.course.timeslots.attendance.register')}
					</Button>
				)}
			</DialogActions>
		</StyledDialog>
    );
};

CourseGroupTimeSlotsAttendanceModal.propTypes = {
	group: PropTypes.object,
	timeSlots: PropTypes.array,
};

export default compose(
	withFetchData(
		undefined, {
			customId: () => 'CourseGroupTimeslotAttendance',
		},
	),
	withFormData(),
)(CourseGroupTimeSlotsAttendanceModal);
