import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/util/loader/Loader';
import { getMe } from './authActions';

const authSelector = state => state.auth;

const withAuthProvider = (ComposedComponent) => {
    const Enhanced = function AuthProvider(props) {
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const auth = useSelector(authSelector);

        useEffect(() => {
            if (!auth.tokenValue) {
                navigate(`/login?from=${encodeURI(window.location.pathname)}`, { replace: true });
            }
        }, [auth, navigate]);

        useEffect(() => {
            if (auth?.user === undefined) {
                dispatch(getMe());
            }
        }, []);

        if (auth.user === undefined) {
            return <Loader />;
        }
        return <ComposedComponent auth={auth} {...props} />;
    };

    return hoistNonReactStatics(Enhanced, ComposedComponent);
};

export default withAuthProvider;
