import { createHeadlessEditor } from '@lexical/headless';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { forEachObjIndexed, uniq } from 'ramda';

export function getTemplatesParams(...contentItems) {
    const matcher = (s) => s.match(/\{[\w_]+}/g) || [];
    return uniq(contentItems.map(item => typeof item === 'string' ? matcher(item) : []).flat()).map(param => (
        param.replace('{', '').replace('}', '')
    )).reduce((obj, param) => ({
        ...obj,
        [param]: undefined,
    }), {});
}

export function replaceTemplateParams(content, params) {
    if (!content) return content;

    let result = content;
    forEachObjIndexed((val, key) => {
        result = result.replaceAll(`{${key}}`, val || `{${key}}`);
    }, params);

    return result;
}

export async function createEditorState(editorApi, content) {
    const editor = createHeadlessEditor({
        nodes: editorApi?.getNodes?.() || [],
    });

    let html = '';
    await editor.update(() => {
        $convertFromMarkdownString(content, TRANSFORMERS);
        html = $generateHtmlFromNodes(editor, null);
    });

    const nodes = JSON.stringify(editor.getEditorState().toJSON());

    return { html, nodes };
}
