import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import withFormData from '../../modules/formData/withFormData'
import withValidation, { Joi } from '../../hoc/withValidation'
import withSimpleState from '../../hoc/withSimpleState'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import { evaluateGroup } from '../../actions/courseGroupActions'
import { canEvaluate } from '../../helpers/groupState'

const CourseGroupEvaluateModal = ({ group, handleClose, fields, onChangeField, handleSubmit, saving, error, success, validation, validationBlur, validationAll }) => {
	const disabled = !canEvaluate(group.state);

	return (
		<Dialog open={true} onClose={handleClose}>
			<form onSubmit={handleSubmit(() => ({
				...fields,
				id: group.id
			}), evaluateGroup, validationAll)}>
				<DialogTitle>Evaluate {group.name}</DialogTitle>
				{success ? (
					<DialogContent>
						<Alert type={Alert.TYPE_SUCCESS}>Evaluation has been saved.</Alert>
					</DialogContent>
				) : (disabled ? (
					<DialogContent>
						<Alert type={Alert.TYPE_INFO}>This group cannot be evaluated yet or anymore. Only when finished and before the payments are approved in the batch this is possible.</Alert>
					</DialogContent>
				) : (
					<DialogContent>
						{saving && <Loader sheet />}
						{
							error &&
							<Alert type={Alert.TYPE_WARNING}>
								{error.error}
							</Alert>
						}
						{
							group.evaluation &&
							<Alert type={Alert.TYPE_INFO}>This group has already been evaluated before with a <strong>{group.evaluation}</strong></Alert>
						}
						<DialogContentText>
							Enter the evaluation mark for the teacher of this group. Only numbers are allowed.
							<br/><br/>
						</DialogContentText>
						<TextField autoFocus fullWidth
								   label="Evaluation"
								   type="text"
								   name="evaluation"
								   value={fields.evaluation}
								   onChange={onChangeField}
								   error={!!validation['evaluation']}
								   helperText={validation['evaluation']}
								   onBlur={validationBlur} />
					</DialogContent>
				))}
				<DialogActions>
					<Button onClick={handleClose} color="secondary" disabled={saving}>
						{success ? 'Close' : 'Cancel'}
					</Button>
					{
						(!success && !disabled) &&
						<Button type="submit" color="primary" disabled={saving}>
							Evaluate
						</Button>
					}
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default withFormData({
	customId: () => 'courseGroupEvaluateModal',
})(
	withValidation(
		withSimpleState(
			CourseGroupEvaluateModal,
			{
				convertOnChange: (field, value) => {
					return value ? value.replace(',', '.') : value
				},
			}
		),
		{
			evaluation: Joi.number().precision(2).max(10).required(),
		}
	)
)
