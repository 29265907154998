import { stepConnectorClasses } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, last } from 'ramda';
import classNames from 'classnames';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';
import Icon from '@mui/material/Icon';
import StepConnector from '@mui/material/StepConnector';
import { Trans, useTranslation } from 'react-i18next';
import { languageFromLocale } from '../../../helpers/localization';
import { scopeParentsList } from '../../../helpers/scope';
import withFetchData from '../../../modules/fetchData/withFetchData';
import { CALL_API } from '../../../setup/api';
import Alert from '../../util/alert/Alert';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';
import ApplicationFormContract from './ApplicationFormContract';
import ApplicationFormFinished from './ApplicationFormFinished';
import ApplicationFormInfo from './ApplicationFormInfo';

const PREFIX = 'ApplicationForm';

const classes = {
    alternativeLabel: `${PREFIX}-alternativeLabel`,
    active: `${PREFIX}-active`,
    completed: `${PREFIX}-completed`,
    line: `${PREFIX}-line`,
    root: `${PREFIX}-root`,
    active2: `${PREFIX}-active2`,
    circle: `${PREFIX}-circle`,
    completed2: `${PREFIX}-completed2`,
    root2: `${PREFIX}-root2`,
    title: `${PREFIX}-title`,
    stepper: `${PREFIX}-stepper`,
    warning: `${PREFIX}-warning`
};

const StyledTitleDiv = styled('div')(({ theme }) => ({
	[`&.${classes.title}`]: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
		textAlign: 'center',
	},
}));

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root2}`]: {
		marginBottom: theme.spacing(4),
	},

    [`& .${classes.stepper}`]: {
		padding: theme.spacing(3, 0),
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.warning}`]: {
		margin: theme.spacing(8, 0, 2, 0),
	}
}));

const ApiAction = (hash) => ({
	[CALL_API]: {
		type: 'APPLY',
		endpoint: `apply?hash=${hash}`,
	},
});

const Connector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		// top: 10,
		top: 22,
		left: 'calc(-50% + 32px)',
		right: 'calc(50% + 32px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			// borderColor: theme.palette.primary.light,
			backgroundImage: `linear-gradient(95deg, ${theme.palette.warning.main} 0%, ${theme.palette.primary.light} 100%)`,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			// borderColor: theme.palette.primary.light,
			backgroundImage: `linear-gradient(95deg, ${theme.palette.warning.main} 0%, ${theme.palette.primary.light} 100%)`,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		// borderColor: theme.palette.grey[300],
		// borderTopWidth: 3,
		border: 0,
		height: 3,
		backgroundColor: theme.palette.grey[300],
		borderRadius: 1,
	},
}));

const StepIcon = styled(({ className, active, completed, icon }) => (
	<div
		className={classNames(className, classes.root, {
			[classes.active]: active,
			[classes.completed]: completed,
		})}
	>
		{completed ? (
			<Icon className={classes.completedIcon}>check</Icon>
		) : (
			<Icon>{icon}</Icon>
		)}
	</div>
))(({ theme }) => ({
    [`&.${classes.root}`]: {
		// color: theme.palette.grey[300],
		backgroundColor: theme.palette.grey[300],
		color: theme.palette.common.white,
		display: 'flex',
		// height: 22,
		width: 50,
		height: 50,
		borderRadius: '50%',
		alignItems: 'center',
		justifyContent: 'center',
		[`&.${classes.active}`]: {
			// color: theme.palette.primary.light,
			backgroundImage: `linear-gradient(136deg, ${theme.palette.warning.main} 0%, ${theme.palette.primary.light} 100%)`,
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		},
		[`&.${classes.completed}`]: {
			backgroundImage: `linear-gradient(136deg, ${theme.palette.warning.main} 0%, ${theme.palette.primary.light} 100%)`,
			// color: theme.palette.primary.light,
			// zIndex: 1,
			// fontSize: 46,
		},
	},
    [`& .${classes.circle}`]: {
		width: 16,
		height: 16,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

const ApplicationForm = ({ loading, error, data, }) => {
	const { t, i18n } = useTranslation();
	const [app, setApp] = useState(null);
	const [step, setStep] = useState(0);

	useEffect(() => {
		setApp(data);

		// Auto advance to the 2nd step when all information has already been filled/confirmed
		if (data?.status === 'IN_PROGRESS') {
			setStep(1);
		}

		// Auto advance to finished if this application is already signed, generally when returning from the signing service
		if (data?.status === 'ACCEPTED') {
			setStep(2);
		}
	}, [data]);

	const company = useMemo(() => (
		scopeParentsList(data?.applyingIn)[0]
	), [data]);

	const locale = useMemo(() => (
		last(scopeParentsList(data?.applyingIn, 'locale').filter(Boolean))
	), [data]);

	useEffect(() => {
		if (locale) {
			i18n.changeLanguage(languageFromLocale(locale));
		}
	}, [locale]);

	const handleInfoSuccess = (data) => {
		setApp(data);
		setStep(step => step + 1);
	};

	const handleContractSuccess = () => {
		setStep(step => step + 1);
	};

	const handleBack = () => {
		setStep(0);
	};

	if (error) {
		if (error.errorCode === 410) return ( // Gone - thus expired
			<Alert type={Alert.TYPE_WARNING} className={classes.warning} icon="alarm_off">
				{t('application.signForm.error.expired')}
			</Alert>
		);

		if (error.errorCode === 404) return ( // Not found - thus probably incorrect link
			<Alert type={Alert.TYPE_WARNING} className={classes.warning} icon="not_interested">
				{t('application.signForm.error.notFound')}
			</Alert>
		);

		return (
			<ApiError error={error} className={classes.warning} />
		);
	}

	if (loading || !data || !app) return (
		<Loader />
	);

	const companyName = company?.name || 'AthenaStudies';

	return (
        <>
            <StyledTitleDiv className={classes.title}>
				<Typography variant="h4">
					<Trans i18nKey="application.signForm.title" values={{ companyName }}>
						Your contract for <strong>{companyName}</strong>
					</Trans>
				</Typography>
				<Typography variant="body1">
					{t('application.signForm.subTitle', 'Follow the steps to complete your contract.')}
				</Typography>
			</StyledTitleDiv>
            <Root className={classes.root}>
				<Stepper alternativeLabel activeStep={step} connector={<Connector
                    classes={{
                        alternativeLabel: classes.alternativeLabel,
                        active: classes.active,
                        completed: classes.completed,
                        line: classes.line
                    }} />} className={classes.stepper}>
					<Step key={0}>
						<StepLabel StepIconComponent={StepIcon} icon="contact_phone">{t('application.signForm.steps.personalInformation')}</StepLabel>
					</Step>
					<Step key={1}>
						<StepLabel StepIconComponent={StepIcon} icon="receipt">{t('application.signForm.steps.contract')}</StepLabel>
					</Step>
					<Step key={2}>
						<StepLabel StepIconComponent={StepIcon} icon="work">{t('application.signForm.steps.finished')}</StepLabel>
					</Step>
				</Stepper>
				{step === 0 && (
					<ApplicationFormInfo application={app} onSuccess={handleInfoSuccess} />
				)}
				{step === 1 && (
					<ApplicationFormContract application={app} onBack={handleBack} onSuccess={handleContractSuccess} companyName={companyName} />
				)}
				{step === 2 && (
					<ApplicationFormFinished application={app} companyName={companyName} />
				)}
			</Root>
        </>
    );
};

ApplicationForm.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	hash: PropTypes.string.isRequired,
};

export default compose(

	withFetchData((props) => ApiAction(props.hash)),
)(ApplicationForm);
