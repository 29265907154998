import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
const PREFIX = 'ScopeTooltipTabsBar';

const classes = {
    root: `${PREFIX}-root`
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    [`&.${classes.root}`]: {
		'&:focus': {
			outline: 'none',
		},
	}
}));

const CustomTab = Tab;

const ScopeTooltipTabsBar = ({ tabs, activeTab, onTabChange }) => {
	return (
        <StyledAppBar position="static" elevation={0} color="default" enableColorOnDark>
			<Tabs
				value={activeTab}
				variant="fullWidth"
				indicatorColor="primary"
				textColor="inherit"
				onChange={onTabChange}
			>
				{tabs.map((label, i) => (
					<CustomTab
                        key={`${i}-${label}`}
                        label={label}
                        id={i}
                        classes={{
                            root: classes.root
                        }} />
				))}
			</Tabs>
		</StyledAppBar>
    );
}

ScopeTooltipTabsBar.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
	activeTab: PropTypes.number,
	onTabChange: PropTypes.func,
};

export default ScopeTooltipTabsBar
