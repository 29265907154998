import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { equals } from 'ramda';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { languages } from '../../helpers/localization';

const TranslatableStringEditor = ({ name, translatableString, handleConfirm, onDelete, onClose }) => {
	const [newTranslatableString, setNewTranslatableString] = useState(
		typeof translatableString === 'string' ? { nl: translatableString } : translatableString,
	);
	const { t } = useTranslation();

	const handleChange = (lang) => (event) => {
		setNewTranslatableString({
			...newTranslatableString,
			[lang]: event.target.value,
		});
	};

	const handleRevert = (event) => {
		event.stopPropagation();
		setNewTranslatableString(typeof translatableString === 'string' ? { nl: translatableString } : translatableString);
	};

	const isDirty = !equals(newTranslatableString, translatableString);

	return (
		<Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>
				{`${t('general.edit')} key '${name}'`}
			</DialogTitle>
			<DialogContent>
				{Object.keys(languages).map(lang => (
					<TextField
						variant="outlined"
						fullWidth
						onClick={(event) => event.stopPropagation()}
						onChange={handleChange(lang)}
						value={newTranslatableString?.[lang] || ''}
						label={languages[lang]}
						style={{ marginBottom: '2rem' }}
						key={lang}
					/>
				))}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					startIcon={<Icon>delete</Icon>}
					color="secondary"
					onClick={onDelete}
				>
					{t('general.delete')}
				</Button>
				<Button
					variant="contained"
					startIcon={<Icon>history</Icon>}
					color="primary"
					disabled={!isDirty}
					onClick={handleRevert}
				>
					{t('general.revert')}
				</Button>
				<Button
					variant="contained"
					startIcon={<Icon>check</Icon>}
					color="primary"
					disabled={!isDirty || newTranslatableString.length === 0}
					onClick={handleConfirm(newTranslatableString)}
				>
					{t('general.confirm')}
				</Button>
				<Button variant="contained" color="default" onClick={onClose}>
					{t('general.close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

TranslatableStringEditor.propTypes = {
	name: PropTypes.string.isRequired,
	translatableString: PropTypes.oneOfType([
		PropTypes.shape({
			en: PropTypes.string,
			nl: PropTypes.string,
			de: PropTypes.string,
		}),
		PropTypes.string,
	]),
	onDelete: PropTypes.func,
	onClose: PropTypes.func,
	handleConfirm: PropTypes.func.isRequired,
};

export default TranslatableStringEditor;
