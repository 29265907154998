const propertyTypes = {
	TEXT: 'text',
	MULTI_TEXT: 'multi-text',
	NUMBER: 'number',
	SELECT: 'select',
	MULTI_SELECT: 'multi-select',
	BOOLEAN: 'boolean',
};

export const propertyTypesWithChoices = [
	propertyTypes.SELECT, propertyTypes.MULTI_SELECT,
];

export default propertyTypes;
