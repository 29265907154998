import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { formatMoney, formatPercentage } from '../../../helpers/formatter'
import { PropTypesMoney } from '../../../helpers/money'

const PREFIX = 'ClientOrderProductsTable';

const classes = {
    root: `${PREFIX}-root`,
    lineThrough: `${PREFIX}-lineThrough`,
    discountShow: `${PREFIX}-discountShow`,
    invisibleHeaderRow: `${PREFIX}-invisibleHeaderRow`,
    tenPercentWidth: `${PREFIX}-tenPercentWidth`,
    totalAmountBorder: `${PREFIX}-totalAmountBorder`,
    fontWeightBold: `${PREFIX}-fontWeightBold`
};

const StyledTable = styled(Table)(({ theme }) => ({
    [`& .${classes.lineThrough}`]: {
		textDecoration: 'line-through',
	},

    [`& .${classes.discountShow}`]: {
		color: theme.palette.grey[500],
	},

    [`& .${classes.invisibleHeaderRow}`]: {
		height: 0,
	},

    [`& .${classes.tenPercentWidth}`]: {
		maxWidth: '10%',
	},

    [`& .${classes.totalAmountBorder}`]: {
		borderTop: theme.borderStyle,
	},

    [`& .${classes.fontWeightBold}`]: {
		fontWeight: theme.typography.fontWeightBold,
	}
}));

const propTypes = {
	orderEntries: PropTypes.arrayOf(PropTypes.shape({
		discountCode: PropTypes.object,
		quantity: PropTypes.number,
		originalPrice: PropTypesMoney,
		paidPrice: PropTypesMoney,
	})).isRequired,
	totalPrice: PropTypesMoney.isRequired,
}

const EntryCell = TableCell

const CourseNameLink = ({ group }) => (
	<Fragment>
		<Link to={`/portal/planner/course/${group.parent.id}`}>
			{group.parent.name}
		</Link>
		<strong>{' — '}</strong>
		<Link to={`/portal/planner/course/${group.parent.id}/group/${group.id}`}>
			{group.name}
		</Link>
	</Fragment>
)

const ClientOrderProductTable = ({ orderEntries, totalPrice, }) => (
	<StyledTable>
		<TableHead>
			<TableRow className={classes.invisibleHeaderRow}>
				<EntryCell
                    classes={{
                        root: classes.root
                    }} />
				<EntryCell
                    className={classes.tenPercentWidth}
                    classes={{
                        root: classes.root
                    }} />
				<EntryCell
                    className={classes.tenPercentWidth}
                    classes={{
                        root: classes.root
                    }} />
			</TableRow>
		</TableHead>
		<TableBody>
			{orderEntries.map(entry => entry.discountCode ? (
				<TableRow key={entry.id}>
					<EntryCell
                        classes={{
                            root: classes.root
                        }}>
						<Grid container direction={'column'} justifyContent={'center'}>
							<Grid item>
								<CourseNameLink group={entry.product}/>
							</Grid>
							<Grid item className={classes.discountShow}>
								{entry.discountCode.partner
									? `${formatPercentage(entry.discountCode.discountPercentage)} discount through partner: ${entry.discountCode.partner.name}`
									: `${formatPercentage(entry.discountCode.discountPercentage)} discount: ${entry.discountCode.code}`}
							</Grid>
						</Grid>
					</EntryCell>
					<EntryCell
                        align={'right'}
                        classes={{
                            root: classes.root
                        }}>{entry.quantity}x</EntryCell>
					<EntryCell
                        align={'right'}
                        className={classes.fontWeightBold}
                        classes={{
                            root: classes.root
                        }}>
						<Grid container direction={'column'} justifyContent={'center'}>
							<Grid item className={classes.lineThrough}>
								{formatMoney(entry.originalPrice)}
							</Grid>
							<Grid item>
								{formatMoney(entry.paidPrice)}
							</Grid>
						</Grid>
					</EntryCell>
				</TableRow>
			) : (
				<TableRow key={entry.id}>
					<EntryCell
                        classes={{
                            root: classes.root
                        }}>
						<CourseNameLink group={entry.product}/>
					</EntryCell>
					<EntryCell
                        align={'right'}
                        classes={{
                            root: classes.root
                        }}>{entry.quantity}x</EntryCell>
					<EntryCell
                        align={'right'}
                        className={classes.fontWeightBold}
                        classes={{
                            root: classes.root
                        }}>
						{formatMoney(entry.paidPrice)}
					</EntryCell>
				</TableRow>
			))}
			<TableRow className={classes.totalAmountBorder}>
				<EntryCell
                    colSpan={2}
                    classes={{
                        root: classes.root
                    }}>
					<Typography align={'right'}>
						Total order amount:
					</Typography>
				</EntryCell>
				<EntryCell
                    classes={{
                        root: classes.root
                    }}>
					<Typography className={classes.fontWeightBold} align={'right'}>
						{formatMoney(totalPrice)}
					</Typography>
				</EntryCell>
			</TableRow>
		</TableBody>
	</StyledTable>
)

ClientOrderProductTable.propTypes = propTypes

export default (ClientOrderProductTable)
