import { chipClasses } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import { formatMoney, formatPercentage } from '../../helpers/formatter'
import { niceDateTime, timeFromNow } from '../../helpers/datetime'

const PREFIX = 'LiveMainFeedItem';

const classes = {
    root: `${PREFIX}-root`,
    label: `${PREFIX}-label`,
    colorSecondary: `${PREFIX}-colorSecondary`,
    item: `${PREFIX}-item`,
    title: `${PREFIX}-title`,
    titlePurple: `${PREFIX}-titlePurple`,
    titleDate: `${PREFIX}-titleDate`,
    titleLink: `${PREFIX}-titleLink`,
    content: `${PREFIX}-content`,
    contentLabels: `${PREFIX}-contentLabels`,
    contentCaption: `${PREFIX}-contentCaption`
};

const localBorderRadius = 4;

const StyledItemDiv = styled('div')(({ theme }) => ({
    [`&.${classes.item}`]: {
		marginBottom: theme.spacing(3),
		fontSize: theme.typography.fontSize,
		[`&.new .${classes.title}`]: {
			backgroundColor: '#8ac654',
		},
	},

    [`& .${classes.title}`]: {
		position: 'relative',
		borderLeftWidth: 0,
		borderRadius: localBorderRadius,
		backgroundColor: theme.palette.common.white,
		transition: 'background-color 1s ease-in-out',
		fontSize: '1rem',
		boxShadow: theme.shadows[2],
	},

    [`& .${classes.titlePurple}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: `${parseInt(theme.spacing(1), 10) - 1}px`,
		// backgroundColor: theme.palette.primary.light,
		backgroundColor: '#faa259',
		borderBottomLeftRadius: localBorderRadius,
		borderTopLeftRadius: localBorderRadius,
	},

    [`& .${classes.titleDate}`]: {
		float: 'right',
		padding: theme.spacing(1),
		marginRight: theme.spacing(1),
		minWidth: '25%',
		textAlign: 'right',
	},

    [`& .${classes.titleLink}`]: {
		display: 'block',
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		color: 'inherit',
		'& a': {
			color: 'inherit',
		},
	},

    [`& .${classes.content}`]: {
		backgroundColor: theme.palette.common.white,
		borderBottomLeftRadius: localBorderRadius,
		borderBottomRightRadius: localBorderRadius,
		marginLeft: theme.spacing(1),
		zIndex: -1,
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(1),
		boxShadow: theme.shadows[1],
	},

    [`& .${classes.contentLabels}`]: {
		float: 'right',
		marginTop: 2,
	},

    [`& .${classes.contentCaption}`]: {
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	}
}));

function getPlaceStyle(place){
	return 1;

	let opacity = null;
	const startAt = 10;

	if(place > startAt) opacity = 0;
	if(place === startAt) opacity = 0.1;
	if(place === startAt - 1) opacity = 0.3;
	if(place === startAt - 2) opacity = 0.5;
	if(place === startAt - 3) opacity = 0.7;

	return opacity === null ? undefined : {
		opacity: opacity,
	};
}

const Label = styled(Chip)(({ theme }) => ({
	[`&.${chipClasses.root}`]: {
		height: 28,
		[`& + .${chipClasses.root}`]: {
			marginLeft: theme.spacing(1),
		},
	},
	[`& .${chipClasses.label}`]: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	[`&.${chipClasses.colorSecondary}`]: {
		backgroundColor: theme.palette.grey[300],
		color: theme.palette.secondary.dark,
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

const LiveMainFeedItem = ({ item, isNew, place, }) => {
	return (
        <Slide timeout={500} in direction="down">
			<StyledItemDiv className={`${classes.item} ${isNew ? 'new' : ''}`} style={getPlaceStyle(place)}>
				<div className={classes.title}>
					<div className={classes.titlePurple} />
					<div className={classes.titleDate} title={niceDateTime(item.orderDateTime)}>
						{timeFromNow(item.orderDateTime)}
					</div>
					<div className={classes.titleLink}>
						<Link to={`/portal/planner/course/${item.courseId}`}>{item.course}</Link>
						{' - '}
						<Link to={`/portal/planner/course/${item.courseId}/group/${item.productId}`}>{item.group}</Link>
					</div>
				</div>
				<div className={classes.content}>
					<div className={classes.contentLabels}>
						<Label
                            label={`${item.soldUnits}/${item.availableUnits}`}
                            color={(item.availableUnits - item.soldUnits) <= 2 ? 'secondary' : undefined}
                       	/>
						<Label
                            label={formatMoney(item.paidPrice)}
                            color={item.paidPrice === 0 ? 'secondary' : undefined}
                        />
						{item.discountPercentage && (
							<Label
                                label={`${formatPercentage(item.discountPercentage)} discount`}
                                color="secondary"
                            />
						)}
					</div>
					<Typography variant="body1" className={classes.contentCaption}>
						{item.institute.split(' ').map(part => part.substr(0, 1)).join('')}
						{`> ${item.study}`}
					</Typography>
				</div>
			</StyledItemDiv>
		</Slide>
    );
};

LiveMainFeedItem.propTypes = {
	item: PropTypes.object.isRequired,
	isNew: PropTypes.bool,
};

LiveMainFeedItem.defaultProps = {
	isNew: false,
};

export default (LiveMainFeedItem)
