import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withRouter from '../../../hoc/withRouter';
import { setPaymentStatus } from '../../../actions/payment';
import { formatMoney, formatMoneyAuto } from '../../../helpers/formatter';
import { niceDateYear } from '../../../helpers/datetime';
import { PropTypesMoney } from '../../../helpers/money';
import { getPaymentTypeIcon, getPaymentTypeText, paymentStatus } from '../../../helpers/payment';
import { scopeParentsNameList } from '../../../helpers/scope';
import { notificationShow } from '../../../modules/notification/notificationActions';
import BatchCardIcon from './BatchCardIcon';

const PREFIX = 'BatchCard';

const classes = {
    root: `${PREFIX}-root`,
    pre: `${PREFIX}-pre`,
    scopeBreadcrumbs: `${PREFIX}-scopeBreadcrumbs`,
    right: `${PREFIX}-right`,
    rightCentering: `${PREFIX}-rightCentering`,
    rightAmount: `${PREFIX}-rightAmount`,
    inlineIconText: `${PREFIX}-inlineIconText`
};

const StyledPaper = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderLeft: `solid 3px ${theme.palette.primary.light}`,

		'&.disabled': {
			opacity: 0.62,
		},
		'&:not(.disabled)': {
			'& .batchIcon-accept:hover': {
				color: theme.palette.success.main,
			},
			'& .batchIcon-reject:hover': {
				color: theme.palette.error.main,
			},
			'& .batchIcon-mark-paid:hover': {
				color: theme.palette.primary.light,
			},
		},
		'&.approved': {
			borderLeftColor: theme.palette.success.main,
			'& .batchIcon-accept': {
				color: theme.palette.success.main,
			},
		},
		'&.rejected': {
			borderLeftColor: theme.palette.error.main,
			'& .batchIcon-reject': {
				color: theme.palette.error.main,
			},
		},
	},

    [`& .${classes.pre}`]: {
		fontFamily: 'Monaco, Courier New, monospace',
	},

    [`& .${classes.scopeBreadcrumbs}`]: {
		listStyle: 'none',
		padding: 0,
		marginTop: 0,
		'& li': {
			padding: 0,
			display: 'inline',
			color: theme.palette.grey[500],
			'&+li::before': {
				content: '"chevron_right"',
				fontFamily: 'Material Icons',
				verticalAlign: '-0.2em',
				padding: '0 5px',
			},
		},
	},

    [`& .${classes.right}`]: {
		height: '100%',
		'& > div': {
			textAlign: 'right',
		},
	},

    [`& .${classes.rightCentering}`]: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},

    [`& .${classes.rightAmount}`]: {
		fontWeight: 700,
		'&.refunded': {
			color: theme.palette.error.main,
		},
	},

    [`& .${classes.inlineIconText}`]: {
		display: 'inline-flex',
		verticalAlign: 'middle',
		'& > .material-icons': {
			fontSize: 22,
		},
		'& .text': {
			marginLeft: theme.spacing(0.5),
		},
		'&.type': {
			color: theme.palette.primary.light,
		},
		'&.showMore': {
			color: theme.palette.grey[500],
		},
	}
}));

class BatchCard extends Component {
	static propTypes = {
		data: PropTypes.shape({
			amount: PropTypesMoney.isRequired,
			approvedByEmployee: PropTypes.object,
			batch: PropTypes.object,
			computedOver: PropTypes.string.isRequired,
			contract: PropTypes.shape({
				baseSalary: PropTypesMoney,
				bonusSalary: PropTypesMoney,
				function: PropTypes.object.isRequired,
				id: PropTypes.number.isRequired,
			}).isRequired,
			created: PropTypes.string.isRequired,
			disapprovalReason: PropTypes.string,
			employee: PropTypes.shape({
				id: PropTypes.number.isRequired,
				IBAN: PropTypes.string.isRequired,
				fullName: PropTypes.string.isRequired,
			}).isRequired,
			id: PropTypes.number.isRequired,
			lastUpdated: PropTypes.string.isRequired,
			paymentStrategy: PropTypes.string.isRequired,
			status: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
			statusSettable: PropTypes.bool.isRequired,
		}).isRequired,
		canMarkAsPaid: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			showMore: false,
		};

		this.toggleShowMore = this.toggleShowMore.bind(this);
		this.renderIcon = this.renderIcon.bind(this);
	}

	approvePayment = (payment) => {
		const { setPaymentStatus, notificationShow } = this.props;

		setPaymentStatus(payment.id, paymentStatus.APPROVED).then(() => {
			notificationShow(`Approved payment${payment.employee.fullName ? ` of ${payment.employee.fullName}` : ''}`);
		})
	};

	setPaymentToPaid = (payment) => {
		const { setPaymentStatus, notificationShow } = this.props;

		setPaymentStatus(payment.id, paymentStatus.PAID).then(() => {
			notificationShow(`Mark payment ${payment.employee.fullName ? ` of ${payment.employee.fullName} ` : ''} as paid`);
		});
	};

	isCardDisabled(){
		return !this.props.data.statusSettable || this.props.data.status === paymentStatus.PAID;
	}

	isCardApproved(){
		return [
			paymentStatus.APPROVED,
			paymentStatus.IN_TRANSFER,
			paymentStatus.PAID
		].indexOf(this.props.data.status) > -1;
	}

	isCardRejected(){
		return [
			paymentStatus.CANCELLED,
			paymentStatus.DISAPPROVED,
		].indexOf(this.props.data.status) > -1;
	}

	render() {
		const { data,  location } = this.props;

		const isDisabled = !data.statusSettable;

		return (
            <StyledPaper className={classnames(classes.root, {
				disabled: this.isCardDisabled(),
				approved: this.isCardApproved(),
				rejected: this.isCardRejected(),
			})}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						<div>
							<Typography variant="body1" color="primary">
								<a href={`/portal/employees/${data.employee.id}/view`} target="_blank">
									{data.employee.fullName}
								</a>
							</Typography>
							<ul className={classes.scopeBreadcrumbs}>
								{scopeParentsNameList(this.props.data.scope, 'Company').map((name, i) => (
									<li key={i}>{name}</li>
								))}
							</ul>
						</div>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Grid container spacing={0} className={classes.right} wrap="nowrap">
							<Grid item sm sx={{ display: { xs: 'none', md: 'flex' } }}>
								<div className={classes.rightCentering}>
									<div className={classnames(classes.inlineIconText, 'type')}>
										{this.renderIcon()}
									</div>
								</div>
							</Grid>
							<Grid item sm sx={{ display: { xs: 'none', md: 'flex' } }}>
								<div className={classes.rightCentering}>
									<div>{data.computedOver}</div>
								</div>
							</Grid>
							<Grid item xs>
								<div className={classes.rightCentering}>
									<div className={classnames(classes.rightAmount, {
										refund: data.amount?.amount < 0,
									})}>
										{formatMoneyAuto(data.amount)}
									</div>
								</div>
							</Grid>
							<Grid item xs>
								<div className={classes.rightCentering}>
									<div>
										<BatchCardIcon
											type="accept"
											tooltip={data.status === paymentStatus.APPROVED ? 'Approved' : 'Approve payment'}
											disabled={isDisabled}
											onClick={() => this.approvePayment(data)}
										>
											check
										</BatchCardIcon>
										<BatchCardIcon
											type="reject"
											tooltip={(
												data.status === paymentStatus.CANCELLED ? 'Cancelled out' : (
													data.status === paymentStatus.DISAPPROVED ? 'Disapproved' : 'Disapprove payment'
												)
											)}
											disabled={isDisabled}
											to={location.pathname}
											state={{
												modal: 'reject-payment',
												payment: data,
											}}
										>
											clear
										</BatchCardIcon>
										{this.props.canMarkAsPaid && (
											<BatchCardIcon
												type="mark-paid"
												tooltip={'Mark as paid'}
												disabled={isDisabled}
												onClick={() => this.setPaymentToPaid(data)}
											>
												arrow_forward
											</BatchCardIcon>
										)}
									</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Button onClick={this.toggleShowMore} size="medium" variant="text">
							<span className={classnames(classes.inlineIconText, 'showMore')}>
								{this.state.showMore &&
								<Icon>keyboard_arrow_up</Icon>
								}
								{!this.state.showMore &&
								<Icon>keyboard_arrow_down</Icon>
								}
								<span>Show more</span>
							</span>
						</Button>
					</Grid>
				</Grid>
				{this.state.showMore &&
				<Fragment>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<hr style={{margin: 0}}/>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<Typography color="primary" variant="body1">Account</Typography>
							<Typography color="primary" className={classes.pre}>{this.props.data.employee.IBAN}</Typography>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<Typography color="primary" variant="body1">Payment</Typography>
							<Typography color="primary">
								{ this.props.data.type === 'REVENUE_SHARE' ?
									`${this.props.data.contract.turnoverPercentage}% - ${this.props.data.contract.bonusTurnoverPercentage}%`  :
								  this.props.data.type === 'TEACHER' ?
									  `${formatMoney(this.props.data.contract.baseSalary)} - ${formatMoney(this.props.data.contract.bonusSalary)}`  : ''
								}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<Typography color="primary" variant="body1">Date</Typography>
							<Typography color="primary">{niceDateYear(this.props.data.created)}</Typography>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<Typography color="primary" variant="body1">Batch ID</Typography>
							<Typography color="primary" className={classes.pre}>{this.props.data.batch ? this.props.data.batch.id : 'No batch ID'}</Typography>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<Typography color="primary" variant="body1">Payment ID</Typography>
							<Typography color="primary" className={classes.pre}>{this.props.data.id}</Typography>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<Typography color="primary" variant="body1">Status</Typography>
							<Typography color="primary" style={{textTransform: 'capitalize'}}>{this.props.data.status.replace("_", " ").toLowerCase()}</Typography>
						</Grid>
					</Grid>
					{this.props.data.disapprovalReason &&
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<Typography color="primary" variant="body1">Disapproved by</Typography>
							<Typography color="primary">{this.props.data.approvedByEmployee.fullName}</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography color="primary" variant="body1">Disapproval reason</Typography>
							<Typography color="primary">{this.props.data.disapprovalReason}</Typography>
						</Grid>
					</Grid>
					}
				</Fragment>
				}
			</StyledPaper>
        );
	}

	toggleShowMore() {
		this.setState({
			showMore: !this.state.showMore,
		})
	}

	renderIcon() {
		const { data: payment } = this.props;

		return (
			<Fragment>
				<Icon>{getPaymentTypeIcon(payment)}</Icon>
				<span className="text">
					{getPaymentTypeText(payment)}
				</span>
			</Fragment>
		)
	}
}

export default withRouter((connect(null, {
	setPaymentStatus,
	notificationShow,
})(BatchCard)));
