import { accordionClasses, accordionDetailsClasses, accordionSummaryClasses } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import moment from 'moment'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import { capitalize } from '@mui/material/utils'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { useTranslation } from 'react-i18next';
import TeacherAgendaCourseRow from './TeacherAgendaCourseRow'

const PREFIX = 'TeacherAgendaWrapper';

const classes = {
    upcoming: `${PREFIX}-upcoming`,
    finished: `${PREFIX}-finished`,
    details: `${PREFIX}-details`
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
	[`&.${accordionClasses.root}`]: {
		boxShadow: 'none',
		padding: 0,
		background: 'none',
	},
	[`& .${classes.upcoming}`]: {
		fontWeight: theme.typography.fontWeightBold,
	},

	[`& .${classes.finished}`]: {
		color: theme.palette.text.disabled,
	},
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	[`&.${accordionSummaryClasses.root}`]: {
		height: '3em',
		minHeight: '0 !important',
		padding: '0 0 0 2em',
		margin: 0,
		boxShadow: theme.shadows[1],
		backgroundColor: theme.palette.common.white,
        [`&.${accordionSummaryClasses.disabled}`]: {
			opacity: 1,
		},
	},
	[`&.${accordionSummaryClasses.expanded}`]: {
		borderRadius: theme.shape.borderRadius,
		height: '3em',
		'&.first': {
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
		},
	},
	[`&.${accordionSummaryClasses.disabled}`]: {
		opacity: 1,
		backgroundColor: theme.palette.common.white,
	},
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme })=> ({
	[`&.${accordionDetailsClasses.root}`]: {
		padding: 0,
		margin: 0,
		backgroundColor: theme.palette.grey[100],
	},

	[`& .${classes.details}`]: {
		flex: 1,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: 1,
	},
}));

const sortGroups = (groups) => {
	return groups.sort(
		(a, b) => moment(a.courseStart).isBefore(b.courseStart) ? -1 : 1
	);
}

const TeacherAgendaWrapper = ({ type, hasLink, groups, }) => {
	const disabled = groups.length === 0;
	const { t } = useTranslation();

	return (
        <StyledAccordion
			key={type}
			defaultExpanded={type === 'upcoming' && !disabled}
			disabled={disabled}
		>
			<CustomAccordionSummary
                expandIcon={disabled ? null : <Icon>expand_more</Icon>}
                className={type === 'upcoming' ? 'first' : undefined}
           	>
				<Grid container alignItems={'center'}>
					<Grid item sm={12} xs={12}>
						<Typography className={classes[type]}>
							{
								disabled
									? t('dashboard.teacherAgenda.noCourses', { type: t(`dashboard.teacherAgenda.${type}`) })
									: `${`${capitalize(t(`dashboard.teacherAgenda.${type}`))} ${t('dashboard.teacherAgenda.courses')}`}`
							}
						</Typography>
					</Grid>
				</Grid>
			</CustomAccordionSummary>
			<CustomAccordionDetails>
				<div className={classes.details}>
					{sortGroups(groups).map(group => (
						<TeacherAgendaCourseRow
							key={group.id}
							group={group}
							hasLink={hasLink}
						/>
					))}
				</div>
			</CustomAccordionDetails>
		</StyledAccordion>
    );
}

TeacherAgendaWrapper.propTypes = {
	type: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	hasLink: PropTypes.bool,
};

export default (TeacherAgendaWrapper)
