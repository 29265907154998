import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import withDialogShouldBeFullscreen from '../../../hoc/withDialogShouldBeFullscreen'
import ProfileEditEmailForm from './ClientEditEmailForm'

const propTypes = {
	client: PropTypes.object.isRequired
}

class ClientEditEmailModal extends Component {
	saveBinder = null;

	render(){
		const { client, handleClose, fullScreen } = this.props;

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth fullScreen={fullScreen}>
				<DialogTitle>Edit {client.name}'s email</DialogTitle>
				<DialogContent>
					<ProfileEditEmailForm
						inputData={client}
						bindSave={(func) => this.saveBinder = func}
						onSave={handleClose}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button
						onClick={() => {if(this.saveBinder) this.saveBinder()}}
						color="primary"
						variant="contained"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

ClientEditEmailModal.propTypes = propTypes

export default withDialogShouldBeFullscreen()(ClientEditEmailModal)
