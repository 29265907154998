const Countries = [
	{ code: 'AD', name: 'Andorra', nameLocal: 'Andorra', prefix: '+376' },
	{ code: 'AF', name: 'Afghanistan', nameLocal: 'د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان', prefix: '+93' },
	{ code: 'AG', name: 'Antigua and Barbuda', nameLocal: 'Antigua and Barbuda', prefix: '+1268' },
	{ code: 'AI', name: 'Anguilla', nameLocal: 'Anguilla', prefix: '+1264' },
	{ code: 'AL', name: 'Albania', nameLocal: 'Shqipëria', prefix: '+355' },
	{ code: 'AM', name: 'Armenia', nameLocal: 'Հայաստան', prefix: '+374' },
	{ code: 'AO', name: 'Angola', nameLocal: 'Angola', prefix: '+244' },
	{ code: 'AQ', name: 'Antarctica', nameLocal: 'Antarctica, Antártico, Antarctique, Антарктике', prefix: '+672' },
	{ code: 'AR', name: 'Argentina', nameLocal: 'Argentina', prefix: '+54' },
	{ code: 'AS', name: 'American Samoa', nameLocal: 'American Samoa', prefix: '+1684' },
	{ code: 'AT', name: 'Austria', nameLocal: 'Österreich', prefix: '+43' },
	{ code: 'AU', name: 'Australia', nameLocal: 'Australia', prefix: '+61' },
	{ code: 'AW', name: 'Aruba', nameLocal: 'Aruba', prefix: '+297' },
	{ code: 'AX', name: 'Åland Islands', nameLocal: 'Åland', prefix: '+358' },
	{ code: 'AZ', name: 'Azerbaijan', nameLocal: 'Azərbaycan', prefix: '+994' },
	{ code: 'BA', name: 'Bosnia and Herzegovina', nameLocal: 'Bosna i Hercegovina', prefix: '+387' },
	{ code: 'BB', name: 'Barbados', nameLocal: 'Barbados', prefix: '+1246' },
	{ code: 'BD', name: 'Bangladesh', nameLocal: 'গণপ্রজাতন্ত্রী বাংলাদেশ', prefix: '+880' },
	{ code: 'BE', name: 'Belgium', nameLocal: 'België, Belgique, Belgien', prefix: '+32' },
	{ code: 'BF', name: 'Burkina Faso', nameLocal: 'Burkina Faso', prefix: '+226' },
	{ code: 'BG', name: 'Bulgaria', nameLocal: 'България', prefix: '+359' },
	{ code: 'BH', name: 'Bahrain', nameLocal: 'البحرين', prefix: '+973' },
	{ code: 'BI', name: 'Burundi', nameLocal: 'Burundi', prefix: '+257' },
	{ code: 'BJ', name: 'Benin', nameLocal: 'Bénin', prefix: '+229' },
	{ code: 'BL', name: 'Saint Barthélemy', nameLocal: 'Saint-Barthélemy', prefix: '+590' },
	{ code: 'BM', name: 'Bermuda', nameLocal: 'Bermuda', prefix: '+1441' },
	{ code: 'BN', name: 'Brunei Darussalam', nameLocal: 'Brunei Darussalam', prefix: '+673' },
	{ code: 'BO', name: 'Bolivia (Plurinational State of)', nameLocal: 'Bolivia, Bulibiya, Volívia, Wuliwya', prefix: '+591' },
	{ code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba', nameLocal: 'Caribisch Nederland', prefix: '+5997' },
	{ code: 'BR', name: 'Brazil', nameLocal: 'Brasil', prefix: '+55' },
	{ code: 'BT', name: 'Bhutan', nameLocal: 'འབྲུག་ཡུལ', prefix: '+975' },
	{ code: 'BV', name: 'Bouvet Island', nameLocal: 'Bouvetøya', prefix: '+47' },
	{ code: 'BW', name: 'Botswana', nameLocal: 'Botswana', prefix: '+267' },
	{ code: 'BY', name: 'Belarus', nameLocal: 'Беларусь', prefix: '+375' },
	{ code: 'BZ', name: 'Belize', nameLocal: 'Belize', prefix: '+501' },
	{ code: 'CA', name: 'Canada', nameLocal: 'Canada', prefix: '+1' },
	{ code: 'CH', name: 'Switzerland', nameLocal: 'Schweiz, Suisse, Svizzera, Svizra', prefix: '+41' },
	{ code: 'CI', name: 'Côte d\'Ivoire', nameLocal: 'Côte d\'Ivoire', prefix: '+225' },
	{ code: 'CL', name: 'Chile', nameLocal: 'Chile', prefix: '+56' },
	{ code: 'CM', name: 'Cameroon', nameLocal: 'Cameroun, Cameroon', prefix: '+237' },
	{ code: 'CN', name: 'China', nameLocal: '中国', prefix: '+86' },
	{ code: 'CO', name: 'Colombia', nameLocal: 'Colombia', prefix: '+57' },
	{ code: 'CR', name: 'Costa Rica', nameLocal: 'Costa Rica', prefix: '+506' },
	{ code: 'CU', name: 'Cuba', nameLocal: 'Cuba', prefix: '+53' },
	{ code: 'CV', name: 'Cabo Verde', nameLocal: 'Cabo Verde', prefix: '+238' },
	{ code: 'CW', name: 'Curaçao', nameLocal: 'Curaçao', prefix: '+599' },
	{ code: 'CX', name: 'Christmas Island', nameLocal: 'Christmas Island', prefix: '+61' },
	{ code: 'CY', name: 'Cyprus', nameLocal: 'Κύπρος, Kibris', prefix: '+357' },
	{ code: 'DE', name: 'Germany', nameLocal: 'Deutschland', prefix: '+49' },
	{ code: 'DJ', name: 'Djibouti', nameLocal: 'Djibouti, جيبوتي, Jabuuti, Gabuutih', prefix: '+253' },
	{ code: 'DK', name: 'Denmark', nameLocal: 'Danmark', prefix: '+45' },
	{ code: 'DM', name: 'Dominica', nameLocal: 'Dominica', prefix: '+767' },
	{ code: 'DZ', name: 'Algeria', nameLocal: 'الجزائر', prefix: '+213' },
	{ code: 'EC', name: 'Ecuador', nameLocal: 'Ecuador', prefix: '+593' },
	{ code: 'EE', name: 'Estonia', nameLocal: 'Eesti', prefix: '+372' },
	{ code: 'EG', name: 'Egypt', nameLocal: 'مصر', prefix: '+20' },
	{ code: 'EH', name: 'Western Sahara', nameLocal: 'Sahara Occidental', prefix: '+212' },
	{ code: 'ER', name: 'Eritrea', nameLocal: 'ኤርትራ, إرتريا, Eritrea', prefix: '+291' },
	{ code: 'ES', name: 'Spain', nameLocal: 'España', prefix: '+34' },
	{ code: 'ET', name: 'Ethiopia', nameLocal: 'ኢትዮጵያ, Itoophiyaa', prefix: '+251' },
	{ code: 'FI', name: 'Finland', nameLocal: 'Suomi', prefix: '+358' },
	{ code: 'FJ', name: 'Fiji', nameLocal: 'Fiji', prefix: '+679' },
	{ code: 'FM', name: 'Micronesia (Federated States of)', nameLocal: 'Micronesia', prefix: '+691' },
	{ code: 'FR', name: 'France', nameLocal: 'France', prefix: '+33' },
	{ code: 'GA', name: 'Gabon', nameLocal: 'Gabon', prefix: '+241' },
	{ code: 'GD', name: 'Grenada', nameLocal: 'Grenada', prefix: '+1473' },
	{ code: 'GE', name: 'Georgia', nameLocal: 'საქართველო', prefix: '+995' },
	{ code: 'GF', name: 'French Guiana', nameLocal: 'Guyane française', prefix: '+594' },
	{ code: 'GG', name: 'Guernsey', nameLocal: 'Guernsey', prefix: '+44' },
	{ code: 'GH', name: 'Ghana', nameLocal: 'Ghana', prefix: '+233' },
	{ code: 'GI', name: 'Gibraltar', nameLocal: 'Gibraltar', prefix: '+350' },
	{ code: 'GL', name: 'Greenland', nameLocal: 'Kalaallit Nunaat, Grønland', prefix: '+299' },
	{ code: 'GN', name: 'Guinea', nameLocal: 'Guinée', prefix: '+224' },
	{ code: 'GP', name: 'Guadeloupe', nameLocal: 'Guadeloupe', prefix: '+590' },
	{ code: 'GQ', name: 'Equatorial Guinea', nameLocal: 'Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial', prefix: '+240' },
	{ code: 'GR', name: 'Greece', nameLocal: 'Ελλάδα', prefix: '+30' },
	{ code: 'GS', name: 'South Georgia and the South Sandwich Islands', nameLocal: 'South Georgia and the South Sandwich Islands', prefix: '+500' },
	{ code: 'GT', name: 'Guatemala', nameLocal: 'Guatemala', prefix: '+502' },
	{ code: 'GU', name: 'Guam', nameLocal: 'Guam, Guåhån', prefix: '+1' },
	{ code: 'GW', name: 'Guinea-Bissau', nameLocal: 'Guiné-Bissau', prefix: '+245' },
	{ code: 'GY', name: 'Guyana', nameLocal: 'Guyana', prefix: '+592' },
	{ code: 'HK', name: 'Hong Kong', nameLocal: '香港, Hong Kong', prefix: '+852' },
	{ code: 'HN', name: 'Honduras', nameLocal: 'Honduras', prefix: '+504' },
	{ code: 'HR', name: 'Croatia', nameLocal: 'Hrvatska', prefix: '+385' },
	{ code: 'HT', name: 'Haiti', nameLocal: 'Haïti, Ayiti', prefix: '+509' },
	{ code: 'HU', name: 'Hungary', nameLocal: 'Magyarország', prefix: '+36' },
	{ code: 'ID', name: 'Indonesia', nameLocal: 'Indonesia', prefix: '+62' },
	{ code: 'IE', name: 'Ireland', nameLocal: 'Ireland, Éire', prefix: '+353' },
	{ code: 'IL', name: 'Israel', nameLocal: 'ישראל', prefix: '+972' },
	{ code: 'IM', name: 'Isle of Man', nameLocal: 'Isle of Man', prefix: '+44' },
	{ code: 'IN', name: 'India', nameLocal: 'भारत, India', prefix: '+91' },
	{ code: 'IQ', name: 'Iraq', nameLocal: 'العراق, Iraq', prefix: '+964' },
	{ code: 'IR', name: 'Iran (Islamic Republic of)', nameLocal: 'ایران', prefix: '+98' },
	{ code: 'IS', name: 'Iceland', nameLocal: 'Ísland', prefix: '+354' },
	{ code: 'IT', name: 'Italy', nameLocal: 'Italia', prefix: '+39' },
	{ code: 'JE', name: 'Jersey', nameLocal: 'Jersey', prefix: '+44' },
	{ code: 'JM', name: 'Jamaica', nameLocal: 'Jamaica', prefix: '+876' },
	{ code: 'JO', name: 'Jordan', nameLocal: 'الأُرْدُن', prefix: '+962' },
	{ code: 'JP', name: 'Japan', nameLocal: '日本', prefix: '+81' },
	{ code: 'KE', name: 'Kenya', nameLocal: 'Kenya', prefix: '+254' },
	{ code: 'KG', name: 'Kyrgyzstan', nameLocal: 'Кыргызстан, Киргизия', prefix: '+996' },
	{ code: 'KH', name: 'Cambodia', nameLocal: 'កម្ពុជា', prefix: '+855' },
	{ code: 'KP', name: 'North Korea', nameLocal: '조선민주주의인민공화국', prefix: '+850' },
	{ code: 'KR', name: 'South Korea', nameLocal: '대한민국', prefix: '+82' },
	{ code: 'KI', name: 'Kiribati', nameLocal: 'Kiribati', prefix: '+686' },
	{ code: 'KN', name: 'Saint Kitts and Nevis', nameLocal: 'Saint Kitts and Nevis', prefix: '+1869' },
	{ code: 'KW', name: 'Kuwait', nameLocal: 'الكويت', prefix: '+965' },
	{ code: 'KZ', name: 'Kazakhstan', nameLocal: 'Қазақстан, Казахстан', prefix: '+7' },
	{ code: 'LB', name: 'Lebanon', nameLocal: 'لبنان, Liban', prefix: '+961' },
	{ code: 'LC', name: 'Saint Lucia', nameLocal: 'Saint Lucia', prefix: '+1758' },
	{ code: 'LI', name: 'Liechtenstein', nameLocal: 'Liechtenstein', prefix: '+423' },
	{ code: 'LK', name: 'Sri Lanka', nameLocal: 'ශ්‍රී ලංකා, இலங்கை', prefix: '+94' },
	{ code: 'LR', name: 'Liberia', nameLocal: 'Liberia', prefix: '+231' },
	{ code: 'LS', name: 'Lesotho', nameLocal: 'Lesotho', prefix: '+266' },
	{ code: 'LT', name: 'Lithuania', nameLocal: 'Lietuva', prefix: '+370' },
	{ code: 'LU', name: 'Luxembourg', nameLocal: 'Lëtzebuerg, Luxembourg, Luxemburg', prefix: '+352' },
	{ code: 'LV', name: 'Latvia', nameLocal: 'Latvija', prefix: '+371' },
	{ code: 'LY', name: 'Libya', nameLocal: 'ليبيا', prefix: '+218' },
	{ code: 'MA', name: 'Morocco', nameLocal: 'Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب', prefix: '+212' },
	{ code: 'MC', name: 'Monaco', nameLocal: 'Monaco', prefix: '+377' },
	{ code: 'ME', name: 'Montenegro', nameLocal: 'Crna Gora, Црна Гора', prefix: '+382' },
	{ code: 'MF', name: 'Saint Martin (French part)', nameLocal: 'Saint-Martin', prefix: '+590' },
	{ code: 'MG', name: 'Madagascar', nameLocal: 'Madagasikara, Madagascar', prefix: '+261' },
	{ code: 'ML', name: 'Mali', nameLocal: 'Mali', prefix: '+223' },
	{ code: 'MM', name: 'Myanmar', nameLocal: 'မြန်မာ', prefix: '+95' },
	{ code: 'MN', name: 'Mongolia', nameLocal: 'Монгол Улс', prefix: '+976' },
	{ code: 'MO', name: 'Macao', nameLocal: '澳門, Macau', prefix: '+853' },
	{ code: 'MQ', name: 'Martinique', nameLocal: 'Martinique', prefix: '+596' },
	{ code: 'MR', name: 'Mauritania', nameLocal: 'موريتانيا, Mauritanie', prefix: '+222' },
	{ code: 'MS', name: 'Montserrat', nameLocal: 'Montserrat', prefix: '+1664' },
	{ code: 'MT', name: 'Malta', nameLocal: 'Malta', prefix: '+356' },
	{ code: 'MU', name: 'Mauritius', nameLocal: 'Maurice, Mauritius', prefix: '+230' },
	{ code: 'MV', name: 'Maldives', nameLocal: '', prefix: '+960' },
	{ code: 'MW', name: 'Malawi', nameLocal: 'Malawi', prefix: '+265' },
	{ code: 'MX', name: 'Mexico', nameLocal: 'México', prefix: '+52' },
	{ code: 'MY', name: 'Malaysia', nameLocal: '', prefix: '+60' },
	{ code: 'MZ', name: 'Mozambique', nameLocal: 'Mozambique', prefix: '+258' },
	{ code: 'NA', name: 'Namibia', nameLocal: 'Namibia', prefix: '+264' },
	{ code: 'NC', name: 'New Caledonia', nameLocal: 'Nouvelle-Calédonie', prefix: '+687' },
	{ code: 'NF', name: 'Norfolk Island', nameLocal: 'Norfolk Island', prefix: '+672' },
	{ code: 'NG', name: 'Nigeria', nameLocal: 'Nigeria', prefix: '+234' },
	{ code: 'NI', name: 'Nicaragua', nameLocal: 'Nicaragua', prefix: '+505' },
	{ code: 'NO', name: 'Norway', nameLocal: 'Norge, Noreg', prefix: '+47' },
	{ code: 'NP', name: 'Nepal', nameLocal: '', prefix: '+977' },
	{ code: 'NR', name: 'Nauru', nameLocal: 'Nauru', prefix: '+674' },
	{ code: 'NU', name: 'Niue', nameLocal: 'Niue', prefix: '+683' },
	{ code: 'NZ', name: 'New Zealand', nameLocal: 'New Zealand', prefix: '+64' },
	{ code: 'OM', name: 'Oman', nameLocal: 'سلطنة عُمان', prefix: '+968' },
	{ code: 'PA', name: 'Panama', nameLocal: 'Panama', prefix: '+507' },
	{ code: 'PE', name: 'Peru', nameLocal: 'Perú', prefix: '+51' },
	{ code: 'PF', name: 'French Polynesia', nameLocal: 'Polynésie française', prefix: '+689' },
	{ code: 'PG', name: 'Papua New Guinea', nameLocal: 'Papua New Guinea', prefix: '+675' },
	{ code: 'PK', name: 'Pakistan', nameLocal: 'پاکستان', prefix: '+92' },
	{ code: 'PL', name: 'Poland', nameLocal: 'Polska', prefix: '+48' },
	{ code: 'PM', name: 'Saint Pierre and Miquelon', nameLocal: 'Saint-Pierre-et-Miquelon', prefix: '+508' },
	{ code: 'PN', name: 'Pitcairn', nameLocal: 'Pitcairn', prefix: '+64' },
	{ code: 'PR', name: 'Puerto Rico', nameLocal: 'Puerto Rico', prefix: '+1' },
	{ code: 'PS', name: 'Palestine, State of', nameLocal: 'Palestinian Territory', prefix: '+970' },
	{ code: 'PT', name: 'Portugal', nameLocal: 'Portugal', prefix: '+351' },
	{ code: 'PW', name: 'Palau', nameLocal: 'Palau', prefix: '+680' },
	{ code: 'PY', name: 'Paraguay', nameLocal: 'Paraguay', prefix: '+595' },
	{ code: 'QA', name: 'Qatar', nameLocal: 'قطر', prefix: '+974' },
	{ code: 'RE', name: 'Réunion', nameLocal: 'La Réunion', prefix: '+262' },
	{ code: 'RO', name: 'Romania', nameLocal: 'România', prefix: '+40' },
	{ code: 'RS', name: 'Serbia', nameLocal: 'Србија', prefix: '+381' },
	{ code: 'RU', name: 'Russia', nameLocal: 'Россия', prefix: '+7' },
	{ code: 'RW', name: 'Rwanda', nameLocal: 'Rwanda', prefix: '+250' },
	{ code: 'SA', name: 'Saudi Arabia', nameLocal: 'السعودية', prefix: '+966' },
	{ code: 'SB', name: 'Solomon Islands', nameLocal: 'Solomon Islands', prefix: '+677' },
	{ code: 'SC', name: 'Seychelles', nameLocal: 'Seychelles', prefix: '+248' },
	{ code: 'SE', name: 'Sweden', nameLocal: 'Sverige', prefix: '+46' },
	{ code: 'SG', name: 'Singapore', nameLocal: 'Singapore', prefix: '+65' },
	{ code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha', nameLocal: 'Saint Helena', prefix: '+290' },
	{ code: 'SI', name: 'Slovenia', nameLocal: 'Slovenija', prefix: '+386' },
	{ code: 'SJ', name: 'Svalbard and Jan Mayen', nameLocal: 'Svalbard and Jan Mayen', prefix: '+4779' },
	{ code: 'SK', name: 'Slovakia', nameLocal: 'Slovensko', prefix: '+421' },
	{ code: 'SL', name: 'Sierra Leone', nameLocal: 'Sierra Leone', prefix: '+232' },
	{ code: 'SM', name: 'San Marino', nameLocal: 'San Marino', prefix: '+378' },
	{ code: 'SN', name: 'Senegal', nameLocal: 'Sénégal', prefix: '+221' },
	{ code: 'SO', name: 'Somalia', nameLocal: 'Somalia, الصومال', prefix: '+252' },
	{ code: 'SR', name: 'Suriname', nameLocal: 'Suriname', prefix: '+597' },
	{ code: 'SS', name: 'South Sudan', nameLocal: 'South Sudan', prefix: '+211' },
	{ code: 'ST', name: 'Sao Tome and Principe', nameLocal: 'São Tomé e Príncipe', prefix: '+239' },
	{ code: 'SV', name: 'El Salvador', nameLocal: 'El Salvador', prefix: '+503' },
	{ code: 'SX', name: 'Sint Maarten (Dutch part)', nameLocal: 'Sint Maarten', prefix: '+1721' },
	{ code: 'SY', name: 'Syrian Arab Republic', nameLocal: 'سوريا, Sūriyya', prefix: '+963' },
	{ code: 'TD', name: 'Chad', nameLocal: 'Tchad, تشاد', prefix: '+235' },
	{ code: 'TG', name: 'Togo', nameLocal: 'Togo', prefix: '+228' },
	{ code: 'TH', name: 'Thailand', nameLocal: 'ประเทศไทย', prefix: '+66' },
	{ code: 'TJ', name: 'Tajikistan', nameLocal: ',', prefix: '+992' },
	{ code: 'TK', name: 'Tokelau', nameLocal: 'Tokelau', prefix: '+690' },
	{ code: 'TL', name: 'Timor-Leste', nameLocal: 'Timor-Leste, Timor Lorosa\'e', prefix: '+670' },
	{ code: 'TM', name: 'Turkmenistan', nameLocal: 'Türkmenistan', prefix: '+993' },
	{ code: 'TN', name: 'Tunisia', nameLocal: 'تونس, Tunisie', prefix: '+216' },
	{ code: 'TO', name: 'Tonga', nameLocal: 'Tonga', prefix: '+676' },
	{ code: 'TR', name: 'Turkey', nameLocal: 'Türkiye', prefix: '+90' },
	{ code: 'TT', name: 'Trinidad and Tobago', nameLocal: 'Trinidad and Tobago', prefix: '+868' },
	{ code: 'TV', name: 'Tuvalu', nameLocal: 'Tuvalu', prefix: '+688' },
	{ code: 'TZ', name: 'United Republic of Tanzania', nameLocal: 'Tanzania', prefix: '+255' },
	{ code: 'UA', name: 'Ukraine', nameLocal: 'Україна', prefix: '+380' },
	{ code: 'UG', name: 'Uganda', nameLocal: 'Uganda', prefix: '+256' },
	{ code: 'US', name: 'United States of America', nameLocal: 'United States of America', prefix: '+1' },
	{ code: 'UY', name: 'Uruguay', nameLocal: 'Uruguay', prefix: '+598' },
	{ code: 'UZ', name: 'Uzbekistan', nameLocal: '', prefix: '+998' },
	{ code: 'VC', name: 'Saint Vincent and the Grenadines', nameLocal: 'Saint Vincent and the Grenadines', prefix: '+1784' },
	{ code: 'VE', name: 'Venezuela (Bolivarian Republic of)', nameLocal: 'Venezuela', prefix: '+58' },
	{ code: 'VG', name: 'Virgin Islands (British)', nameLocal: 'British Virgin Islands', prefix: '+1284' },
	{ code: 'VI', name: 'Virgin Islands (U.S.)', nameLocal: 'United States Virgin Islands', prefix: '+1340' },
	{ code: 'VN', name: 'Vietnam', nameLocal: 'Việt Nam', prefix: '+84' },
	{ code: 'VU', name: 'Vanuatu', nameLocal: 'Vanuatu', prefix: '+678' },
	{ code: 'WF', name: 'Wallis and Futuna', nameLocal: 'Wallis-et-Futuna', prefix: '+681' },
	{ code: 'WS', name: 'Samoa', nameLocal: 'Samoa', prefix: '+685' },
	{ code: 'YE', name: 'Yemen', nameLocal: 'اليَمَن', prefix: '+967' },
	{ code: 'YT', name: 'Mayotte', nameLocal: 'Mayotte', prefix: '+262' },
	{ code: 'ZA', name: 'South Africa', nameLocal: 'South Africa', prefix: '+27' },
	{ code: 'ZM', name: 'Zambia', nameLocal: 'Zambia', prefix: '+260' },
	{ code: 'ZW', name: 'Zimbabwe', nameLocal: 'Zimbabwe', prefix: '+263' },
	{ code: 'SZ', name: 'Eswatini', nameLocal: 'Swaziland', prefix: '+268' },
	{ code: 'MK', name: 'North Macedonia', nameLocal: 'Македонија', prefix: '+389' },
	{ code: 'PH', name: 'Philippines', nameLocal: 'Philippines', prefix: '+63' },
	{ code: 'NL', name: 'Netherlands', nameLocal: 'Nederland', prefix: '+31' },
	{ code: 'AE', name: 'United Arab Emirates', nameLocal: 'دولة الإمارات العربيّة المتّحدة', prefix: '+971' },
	{ code: 'MD', name: 'Republic of Moldova', nameLocal: 'Moldova, Молдавия', prefix: '+373' },
	{ code: 'GM', name: 'Gambia', nameLocal: 'The Gambia', prefix: '+220' },
	{ code: 'DO', name: 'Dominican Republic', nameLocal: 'República Dominicana', prefix: '+1' },
	{ code: 'SD', name: 'Sudan', nameLocal: 'السودان', prefix: '+249' },
	{ code: 'LA', name: 'Lao People\'s Democratic Republic', nameLocal: 'ປະຊາຊົນລາວ', prefix: '+856' },
	{ code: 'TW', name: 'Taiwan, Province of China', nameLocal: 'Taiwan', prefix: '+886' },
	{ code: 'CG', name: 'Republic of the Congo', nameLocal: 'République du Congo', prefix: '+242' },
	{ code: 'CZ', name: 'Czechia', nameLocal: 'Česká republika', prefix: '+420' },
	{ code: 'GB', name: 'United Kingdom', nameLocal: 'Great Britain', prefix: '+44' },
	{ code: 'NE', name: 'Niger', nameLocal: 'Niger', prefix: '+227' },
	{ code: 'CD', name: 'Democratic Republic of the Congo', nameLocal: 'Democratic Republic of the Congo', prefix: '+243' },
	{ code: 'BS', name: 'Commonwealth of The Bahamas', nameLocal: 'Commonwealth of The Bahamas', prefix: '+1 242' },
	{ code: 'CC', name: 'Cocos (Keeling) Islands', nameLocal: 'Pulu Kokos (Keeling)', prefix: '+61 891' },
	{ code: 'CF', name: 'Central African Republic', nameLocal: 'République centrafricaine', prefix: '+236' },
	{ code: 'CK', name: 'Cook Islands', nameLocal: 'Kūki \'Āirani', prefix: '+682' },
	{ code: 'FK', name: 'Falkland Islands', nameLocal: 'Falkland Islands', prefix: '+500' },
	{ code: 'FO', name: 'Faroe Islands', nameLocal: 'Færøerne', prefix: '+298' },
	{ code: 'IO', name: 'British Indian Ocean Territory', nameLocal: 'British Indian Ocean Territory', prefix: '+246' },
	{ code: 'KM', name: 'Comoros', nameLocal: 'Umoja wa Komori', prefix: '+269' },
	{ code: 'KY', name: 'Cayman Islands', nameLocal: 'Cayman Islands', prefix: '+1 345' },
	{ code: 'MH', name: 'Republic of the Marshall Islands', nameLocal: 'Aolepān Aorōkin Ṃajeḷ', prefix: '+692' },
	{ code: 'MP', name: 'Commonwealth of the Northern Mariana Islands', nameLocal: 'Sankattan Siha Na Islas Mariånas', prefix: '+1 670' },
	{ code: 'TC', name: 'Turks and Caicos Islands', nameLocal: 'Turks and Caicos Islands', prefix: '+1 649' },
	{ code: 'TF', name: 'French Southern and Antarctic Lands', nameLocal: 'Terres australes et antarctiques françaises', prefix: '+672' },
	{ code: 'UM', name: 'United States Minor Outlying Islands', nameLocal: 'United States Minor Outlying Islands', prefix: '+246' },
	{ code: 'VA', name: 'Holy See', nameLocal: 'Sancta Sedes', prefix: '+39' },
];

export default Countries;
