import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { formatFullName, formatMoney, formatPercentage } from '../../helpers/formatter'
import { niceDateTime, timeFromNow } from '../../helpers/datetime'

const PREFIX = 'FeedItem';

const classes = {
    item: `${PREFIX}-item`,
    title: `${PREFIX}-title`,
    titlePurple: `${PREFIX}-titlePurple`,
    titleLaunch: `${PREFIX}-titleLaunch`,
    titleDate: `${PREFIX}-titleDate`,
    titleLink: `${PREFIX}-titleLink`,
    content: `${PREFIX}-content`,
    contentCaption: `${PREFIX}-contentCaption`,
    contentDivider: `${PREFIX}-contentDivider`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.item}`]: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		fontSize: theme.typography.fontSize,
	},

    [`& .${classes.title}`]: {
		position: 'relative',
		border: `1px solid ${theme.palette.grey[300]}`,
		borderLeftWidth: 0,
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.common.white,
		fontSize: '1.1rem',
	},

    [`& .${classes.titlePurple}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: `${parseInt(theme.spacing(1), 10) - 1}px`,
		backgroundColor: theme.palette.primary.light,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderTopLeftRadius: theme.shape.borderRadius,
	},

    [`& .${classes.titleLaunch}`]: {
		float: 'right',
		padding: theme.spacing(1.5, 1),
		marginRight: theme.spacing(2),
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},

    [`& .${classes.titleDate}`]: {
		float: 'right',
		padding: theme.spacing(1.5, 1),
		marginRight: theme.spacing(1),
	},

    [`& .${classes.titleLink}`]: {
		display: 'block',
		padding: theme.spacing(1.5, 1),
		paddingLeft: theme.spacing(2),
	},

    [`& .${classes.content}`]: {
		backgroundColor: theme.palette.common.white,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderBottomRightRadius: theme.shape.borderRadius,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		zIndex: -1,
		boxShadow: theme.shadows[1],
		padding: theme.spacing(0, 1, 1),
	},

    [`& .${classes.contentCaption}`]: {
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},

    [`& .${classes.contentDivider}`]: {
		marginBottom: theme.spacing(1),
	}
}));

const FeedItem = ({ item,  hasClientLink }) => {
	return (
        <Root className={classes.item}>
			<div className={classes.title}>
				<div className={classes.titlePurple} />
				<Link className={classes.titleLaunch} to={`/portal/planner/course/${item.courseId}/group/${item.productId}`}>
					<Icon>launch</Icon>
				</Link>
				<div className={classes.titleDate} title={niceDateTime(item.orderDateTime)}>
					{timeFromNow(item.orderDateTime)}
				</div>
				<div className={classes.titleLink}>
					<Link to={`/portal/planner/course/${item.courseId}`}>{item.course}</Link>
					{' - '}
					<Link to={`/portal/planner/course/${item.courseId}/group/${item.productId}`}>{item.group}</Link>
				</div>
			</div>
			<div className={classes.content}>
				<Typography variant="caption" className={classes.contentCaption}>
					{`${item.institute} > ${item.faculty} > ${item.study}`}
				</Typography>
				<Divider className={classes.contentDivider} />
				<Typography variant="body2">
					{`${item.soldUnits}/${item.availableUnits}, ${formatMoney(item.paidPrice)}`}
					{item.discountPercentage && ` with ${formatPercentage(item.discountPercentage)} discount`}
					<br/>
					{'Cursist: '}
					{hasClientLink ? (
						<Link to={`/portal/customers/${item.clientId}`}>
							{formatFullName(item)}
						</Link>
					) : (
						formatFullName(item)
					)}
				</Typography>
			</div>
		</Root>
    );
};

FeedItem.propTypes = {
	item: PropTypes.object.isRequired,
	hasClientLink: PropTypes.bool,
};

export default (FeedItem)
