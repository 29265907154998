import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
const PREFIX = 'SheetBackground';

const classes = {
    root: `${PREFIX}-root`,
    sticker: `${PREFIX}-sticker`,
    stickerContainer: `${PREFIX}-stickerContainer`,
    textWrapper: `${PREFIX}-textWrapper`,
    text: `${PREFIX}-text`,
    textTop: `${PREFIX}-textTop`,
    textLine: `${PREFIX}-textLine`,
    textDot: `${PREFIX}-textDot`,
    textBottom: `${PREFIX}-textBottom`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		background: theme.palette.common.white,
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
	},

    [`& .${classes.sticker}`]: {
		position: 'absolute',
		width: '35%',
		top: '-26%',
		right: '-6%',
	},

    [`& .${classes.stickerContainer}`]: {
		position: 'relative',
		background: theme.palette.primary.main,
		borderRadius: '50%',
		width: '100%',
		paddingTop: '100%',
		'& > div': {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			display: 'flex',
			alignContent: 'center',
			alignItems: 'center',
			'& > div': {
				display: 'inline-block',
				textAlign: 'center',
				color: theme.palette.common.white,
				fontSize: '5em',
				padding: '20% 6% 0 60px',
			},
		},
	},

    [`& .${classes.textWrapper}`]: {
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
	},

    [`& .${classes.text}`]: {
		flex: 1,
		textAlign: 'center',
		fontSize: '6em',
		lineHeight: 0.5,
	},

    [`& .${classes.textTop}`]: {
		fontWeight: theme.typography.fontWeightMedium,
		textTransform: 'uppercase',
	},

    [`& .${classes.textLine}`]: {
		display: 'inline-block',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: '20%',
		height: 1,
		borderTop: `4px solid ${theme.palette.primary.dark}`,
	},

    [`& .${classes.textDot}`]: {
		color: '#ECAC24',
	},

    [`& .${classes.textBottom}`]: {
		marginTop: theme.spacing(1),
		fontSize: '0.8em',
		lineHeight: 1,
		letterSpacing: '0.2em',
		color: theme.palette.grey[800],
		textTransform: 'uppercase',
		fontWeight: theme.typography.fontWeightLight,
	}
}));

const statements = [
	{
		top: 'Driving home',
		bottom: 'for cursus',
	},
	{
		top: 'Een lul',
		bottom: 'Lucas',
	},
	{
		top: 'Flyers',
		bottom: 'optijd besteld?',
	},
];

const SheetBackground = ({ }) => {
	const [active, setActive] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setActive(active => (
				active < (statements.length - 1) ? active + 1 : 0
			));
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	return (
        <Root className={classes.root}>
			<div className={classes.sticker}>
				<div className={classes.stickerContainer}>
					<div>
						<div>AthenaStudies</div>
					</div>
				</div>
			</div>

				<div className={classes.text}>
					<div className={classes.textTop}>
						{statements[active].top}<span className={classes.textDot}>.</span>
					</div>
					<div className={classes.textLine} />
					<div className={classes.textBottom}>
						{statements[active].bottom}
					</div>
				</div>

		</Root>
    );
};

export default (SheetBackground)
