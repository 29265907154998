import { ButtonGroup, LinearProgress, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteEntryAttachment } from '../../../actions/declarationsActions';
import { downloadFromApi } from '../../../setup/api';
import { DeclarationContexts, useDeclarationContext } from './DeclarationContext';

const StyledRoot = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	'& .btnGroup': {
		margin: theme.spacing(0, 2, 2, 0),
	},
}));

function DeclarationEntryAttachments({ entry, loading, disabled }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const context = useDeclarationContext();
	const { attachedDocuments } = entry;

	const handleDownloadAttachment = (claimFormAttachmentId) => () => {
		downloadFromApi(`claim-form-attachment/${claimFormAttachmentId}/file`);
	};

	const handleDeleteAttachment = (claimFormAttachment) => () => {
		dispatch(deleteEntryAttachment(claimFormAttachment));
	};

	if (loading) {
		return (
			<StyledRoot>
				<LinearProgress />
			</StyledRoot>
		);
	}

	return (
		<StyledRoot>
			{ Array.isArray(attachedDocuments) && attachedDocuments.map(attachment => (
				<ButtonGroup variant="contained" color="primary" key={`attachment_group_${attachment.id}`} className="btnGroup">
					<Button
						onClick={handleDownloadAttachment(attachment.id)}
						variant="contained"
						color="default"
						startIcon={<Icon>open_in_new</Icon>}
					>
						{attachment.fileName}
					</Button>
					{!disabled && context === DeclarationContexts.PROFILE && attachedDocuments.length > 1 && (
						<Button onClick={handleDeleteAttachment(attachment)} variant="contained" color="default">
							<Icon>delete</Icon>
						</Button>
					)}
				</ButtonGroup>
			))}
			{Array.isArray(attachedDocuments) && !attachedDocuments.length && (
				<Typography paragraph>{t('declarations.attachments.noAttachments')}</Typography>
			)}
		</StyledRoot>
	);
}

DeclarationEntryAttachments.propTypes = {
	entry: PropTypes.shape({
		attachedDocuments: PropTypes.array,
	}),
	disabled: PropTypes.bool,
};

export default DeclarationEntryAttachments;
