import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import { useTranslation } from 'react-i18next';
import { getScopeEvaluation } from '../../../../actions/evaluationActions'
import withFetchData from '../../../../modules/fetchData/withFetchData'
import PageContentBlock from '../../../page/PageContentBlock'
import ApiError from '../../../util/ApiError'
import Loader from '../../../util/loader/Loader'
import { questionOrder } from '../evaluationUtils'
import EvaluationListItem from './EvaluationListItem'

const CourseGroupPageEvaluationList = ({ course, group, loading, error, data }) => {
	const { t } = useTranslation();
	const grade = useMemo(() => {
		const gradeTotal = data && questionOrder.reduce((acc, q) => acc + data[q], 0);

		return gradeTotal ? gradeTotal / questionOrder.length : 0;
	}, [data]);

	return (
		<PageContentBlock
			title={t('general.evaluations')}
			icon="star_half"
		>
			{error ? (
				<ApiError error={error} />
			) : (
				(loading || !data) ? (
					<Loader />
				) : (
					<List disablePadding={true}>
						<EvaluationListItem
							to={`/portal/planner/course/${course.id}/group/${group.id}/evaluations`}
							label={group.name}
							averageEvaluation={grade}
							evaluations={data.numberOfEvaluations}
							soldUnits={group.soldUnits}
						/>
					</List>
				)
			)}
		</PageContentBlock>
	)
};

CourseGroupPageEvaluationList.propTypes = {
	course: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
};

export default withFetchData(props => getScopeEvaluation(props.group.id))(CourseGroupPageEvaluationList)
