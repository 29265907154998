import React from 'react'
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { listsFromState } from '../../modules/lists/listsHelpers'
import { LISTS_DISCOUNT_CODE_NEW } from '../../actions/discountCodeActions'
import { formatPercentage } from '../../helpers/formatter'
import { niceDateYear } from '../../helpers/datetime'
import PageContentBlock from '../page/PageContentBlock'

const PREFIX = 'DiscountCodesNew';

const classes = {
    list: `${PREFIX}-list`
};

const StyledPageContentBlock = styled(PageContentBlock)(({ theme }) => ({
    [`& .${classes.list}`]: {
		padding: theme.spacing(2),
	}
}));

const DiscountCodesNew = ({ discountCodes, }) => {
	if(!discountCodes || discountCodes.length === 0) return null;

	return (
        <StyledPageContentBlock
			title="Your new discount codes"
			description="These are the discount codes you have just created. Do not forget to copy them."
			icon="done"
		>
			<div className={classes.list}>
				{discountCodes.map(item => item.code).join(', ')}
			</div>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Code</TableCell>
						<TableCell>Discount %</TableCell>
						<TableCell>Expires on</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{discountCodes.map(discountCode => (
						<TableRow key={discountCode.id}>
							<TableCell>
								{discountCode.code}
							</TableCell>
							<TableCell>
								{formatPercentage(discountCode.discountPercentage)}
							</TableCell>
							<TableCell>
								{niceDateYear(discountCode.expires)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</StyledPageContentBlock>
    );
}

export default (connect(state => {
	return {
		discountCodes: listsFromState(state, LISTS_DISCOUNT_CODE_NEW, 'discountCodes')
	}
})(DiscountCodesNew))
