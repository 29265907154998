import { CALL_API } from './schemas'

export const FEED = 'FEED';

export const orderEntryList = (scopeId) => ({
	[CALL_API]: {
		type: FEED,
		endpoint: `scope/${scopeId}/order-entry`
	}
});
