import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose, mapObjIndexed, values } from 'ramda';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import { withTranslation } from 'react-i18next';
import withFormData from '../../../modules/formData/withFormData'
import { composeLocationName, scopeParentsNameString } from '../../../helpers/scope'
import { dateTimePickerFunctions, getISO, niceDateDay, niceTime } from '../../../helpers/datetime'
import {
	editScopeTimeSlot,
	addScopeTimeSlot,
	addScopeLinkedTimeSlot
} from '../../../actions/scopeEditActions'
import LocationSelectDialog from '../../locationSelect/LocationSelectDialog'
import Alert from '../../util/alert/Alert'
import ScopeSelectTeacher from './ScopeSelectTeacher'

function getNowDateString(hh, mm) {
	return getISO(moment().hours(hh).minutes(mm).seconds(0));
}

const itemButtonsStyle = {
	textAlign: 'right',
};

class ScopeTimeSlotForm extends Component {
	constructor(props) {
		super(props);

		let timeSlot = {
			classRoom: props.defaultClassRoom || null,
			teacher: props.defaultTeacher || null,
			startDateTime: getNowDateString(9, 0),
			endDateTime: getNowDateString(12, 0),
			...props.timeSlot || {}
		};

		// Calculate the duration between possible start and end
		// ... because this will be shown instead of end date
		timeSlot.duration = dateTimePickerFunctions.editableDuration(
			dateTimePickerFunctions.editableDate(timeSlot.startDateTime),
			dateTimePickerFunctions.editableDate(timeSlot.endDateTime)
		);

		this.state = {
			...timeSlot,
			selectLocation: false,
		};
	}

	render() {
		const { scope, timeSlot, onCancel, onDelete, saving, error, success, t, i18n } = this.props;
		const { teacher, startDateTime, endDateTime, duration, classRoom } = this.state;
		const { selectLocation } = this.state;

		const startDateTimeObject = dateTimePickerFunctions.editableDate(startDateTime);

		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				{t('scope.course.timeslots.saved')}
			</Alert>
		);

		return (
			<form onSubmit={this.saveScope}>
				{error && (
					<Alert type={Alert.TYPE_WARNING}>
						{error.error}
						{error.errors && values(mapObjIndexed((v, key) => (
							<div key={key}>{key}: {v}</div>
						), error.errors))}
						{this.renderConflict()}
					</Alert>
				)}

				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<MobileDatePicker
							value={startDateTimeObject}
							onChange={this.changeStartDate}
							animateYearScrolling={false}
							okLabel="Pick"
							inputFormat="dd D MMMM YYYY"
							showToolbar={false}
							closeOnSelect
							componentsProps={{
								actionBar: {
									actions: ['cancel'],
								},
							}}
							renderInput={(props) => (
								<TextField
									{...props}
									label={t('general.date')}
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item md={4} xs={6}>
						<MobileTimePicker
							ampm={false}
							fullWidth
							value={startDateTimeObject}
							onChange={this.changeStartTime}
							showToolbar={false}
							renderInput={(props) => (
								<TextField
									{...props}
									label={t('general.startTime')}
								/>
							)}
						/>
					</Grid>
					<Grid item md={4} xs={6}>
						<MobileTimePicker
							ampm={false}
							value={duration}
							onChange={this.changeDuration}
							renderInput={(props) => (
								<TextField
									{...props}
									label={t('scope.course.timeslots.courseLength')}
									fullWidth
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<div className="course-dialog-timeslot-edit-preview">
							{`
								${niceDateDay(startDateTime, i18n.language)}
								${niceTime(startDateTime, i18n.language)} -
								${niceTime(endDateTime, i18n.language)}
							`}
						</div>
					</Grid>

					<Grid item sm={6} xs={12}>
						<TextField
							label={t('general.location')}
							name="location"
							fullWidth
							value={composeLocationName(classRoom)}
							onClick={this.handleSelectLocation}
							onChange={this.bindState('location')}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ScopeSelectTeacher
							scope={scope}
							onChange={this.handleTeacherChange}
							teacher={teacher} />
					</Grid>

					<Grid item xs={12} style={itemButtonsStyle}>
						{onDelete && (
							<Button
								variant="contained"
								color="secondary"
								onClick={onDelete}
								style={{marginRight: '20px'}}
							>{t('general.delete')}</Button>
						)}

						{onCancel && !saving && (
							<Button
								variant="contained"
								color="default"
								onClick={onCancel}
								style={{marginRight: '20px'}}
							>{t('general.cancel')}</Button>
						)}

						<Button
							variant="contained"
							disabled={saving}
							onClick={this.saveScope}
							color="primary"
						>
							{saving ? t('general.saving') : timeSlot ? t('scope.course.timeslots.saveTimeSlot') : t('scope.course.timeslots.addTimeSlot')}
						</Button>
					</Grid>
				</Grid>
				{selectLocation && (
					<LocationSelectDialog
						scope={scope}
						startDateTime={startDateTime}
						endDateTime={endDateTime}
						timeslotId={timeSlot?.id}
						currentRoom={classRoom}
						onClose={this.handleSelectLocationClose}
						onSelect={this.handleSelectLocationConfirm}
					/>
				)}
			</form>
		);
	}

	renderConflict(){
		const { error, onCancel, t } = this.props;
		const { startDateTime, endDateTime } = this.state;

		if(!error || !error.conflict) return null;

		const startDiff = Math.abs(moment(startDateTime).diff(moment(error.conflict.endDateTime), 'seconds'));
		const endDiff = Math.abs(moment(endDateTime).diff(moment(error.conflict.startDateTime), 'seconds'));

		return (
			<Fragment>
				{t('scope.course.timeslots.conflictingTimeSlotWarning')}
				<br/>
				<br/>
				<Paper>
					<List disablePadding>
						<ListItem
							button
							divider
							component="a"
							onClick={this.handleLinkConflict}
						>
							<ListItemText
								primary={t('scope.course.timeslots.useExistingTimeSlot')}
								secondary={(
									<span>
										<em>{t('scope.course.timeslots.linkUseExisting')}</em><br/>
										{scopeParentsNameString(error.conflict.owningCourseGroup, 'Company')}<br/>
										{niceDateDay(error.conflict.startDateTime)} {t('general.at')} {niceTime(error.conflict.startDateTime)} - {niceTime(error.conflict.endDateTime)}<br/>
										{error.conflict.classRoom ? composeLocationName(error.conflict.classRoom) : <em>{t('scope.course.timeslots.noClassroomYet')}</em>}
										{' / '}
										{error.conflict.teacher ? error.conflict.teacher.fullName : <em>{t('scope.course.timeslots.noTeacherYet')}</em>}<br/>
									</span>
								)}
							/>
							<Icon>radio_button_unchecked</Icon>
						</ListItem>
						<ListItem
							button
							divider
							component="a"
							onClick={this.handleUpdateTimesFromConflicting}
						>
							<ListItemText
								primary={t('scope.course.timeslots.createTimeSlotWithDiffTime')}
								secondary={(
									<span>
										<em>{t('scope.course.timeslots.newTimeSlotTimes')}</em><br/>
										{startDiff < endDiff
											? `${t('scope.course.timeslots.updatedStartTime')}: ${niceTime(error.conflict.endDateTime)}`
											: `${t('scope.course.timeslots.updatedEndTime')}: ${niceTime(error.conflict.startDateTime)}`
										}<br/>
										{niceDateDay(startDateTime)}
										{` ${t('general.at')} `}
										{startDiff < endDiff
											? `${niceTime(error.conflict.endDateTime)} - ${niceTime(endDateTime)}`
											: `${niceTime(startDateTime)} - ${niceTime(error.conflict.startDateTime)}`
										}<br/>
									</span>
								)}
							/>
							<Icon>radio_button_unchecked</Icon>
						</ListItem>
						<ListItem
							button
							divider
							component="a"
							onClick={onCancel}
						>
							<ListItemText
								primary={t('scope.course.timeslots.cancelNewTimeslot')}
								secondary={(
									<em>{t('scope.course.timeslots.dismissFormDesc')}</em>
								)}
							/>
							<Icon>radio_button_unchecked</Icon>
						</ListItem>
					</List>
				</Paper>
			</Fragment>
		);
	}

	saveScope = (e) => {
		if(e) e.preventDefault();

		const { timeSlot, scope, watchSubmit } = this.props;

		const slotData = {
			startDateTime: this.state.startDateTime,
			endDateTime: this.state.endDateTime,
			classRoom: this.state.classRoom,
			teacher: this.state.teacher
		};

		if(timeSlot){
			watchSubmit(editScopeTimeSlot(timeSlot.id, scope.id, slotData));
		}else if(scope){
			watchSubmit(addScopeTimeSlot(scope.id, slotData));
		}
	}

	handleLinkConflict = () => {
		const { watchSubmit, error, scope, timeSlot } = this.props;

		if(!error || !error.conflict) return;

		watchSubmit(addScopeLinkedTimeSlot(scope.id, error.conflict.id, timeSlot ? timeSlot.id : null));
	}

	handleUpdateTimesFromConflicting = () => {
		const { error, handleFormError } = this.props;
		const { startDateTime, endDateTime } = this.state;

		if(!error || !error.conflict) return null;

		const startDiff = Math.abs(moment(startDateTime).diff(moment(error.conflict.endDateTime), 'seconds'));
		const endDiff = Math.abs(moment(endDateTime).diff(moment(error.conflict.startDateTime), 'seconds'));

		if(startDiff < endDiff){
			this.setState({
				startDateTime: error.conflict.endDateTime,
				duration: dateTimePickerFunctions.editableDuration(
					dateTimePickerFunctions.editableDate(error.conflict.endDateTime),
					dateTimePickerFunctions.editableDate(endDateTime)
				),
			});
		}else{
			this.setState({
				endDateTime: error.conflict.startDateTime,
				duration: dateTimePickerFunctions.editableDuration(
					dateTimePickerFunctions.editableDate(startDateTime),
					dateTimePickerFunctions.editableDate(error.conflict.startDateTime)
				),
			});
		}

		handleFormError(null);
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.onSave();
		}
	}

	changeStartDate = (date) => {
		const startDateTime = dateTimePickerFunctions.replaceEditedDate(date, this.state.startDateTime);

		this.setState({
			startDate: date,
			startDateTime: startDateTime,
			endDateTime: this.getEndDateTime(this.state.duration, startDateTime)
		});
	}

	changeStartTime = (date) => {
		const startDateTime = dateTimePickerFunctions.replaceEditedTime(this.state.startDateTime, date);

		this.setState({
			startTime: date,
			startDateTime: startDateTime,
			endDateTime: this.getEndDateTime(this.state.duration, startDateTime)
		});
	}

	changeDuration = (duration) => {
		this.setState({
			duration: duration,
			endDateTime: this.getEndDateTime(duration)
		});
	}

	getEndDateTime = (duration, startDateTime) => {
		const startDateTimeString = startDateTime || this.state.startDateTime;
		if(!duration) return startDateTimeString;

		return dateTimePickerFunctions.getEditedEnd(startDateTimeString, duration);
	}

	bindState = (property) => {
		return (event) => {
			this.setState({
				[property]: event.target.value
			});
		}
	}

	handleSelectLocation = () => {
		this.setState({
			selectLocation: true
		});
	}

	handleSelectLocationClose = () => {
		this.setState({
			selectLocation: false
		});
	}

	handleSelectLocationConfirm = (room) => {
		this.setState({
			selectLocation: false,
			classRoom: room,
		});
	}

	handleTeacherChange = (teacher) => {
		this.setState({
			teacher: teacher,
		});
	}
}

ScopeTimeSlotForm.propTypes = {
	scope: PropTypes.object.isRequired,
	timeSlot: PropTypes.object,
	defaultClassRoom: PropTypes.object,
	defaultTeacher: PropTypes.object,
	onSave: PropTypes.func,
	onCancel: PropTypes.func,
	onDelete: PropTypes.func,
};

export default compose(
	withTranslation(),
	withFormData(),
)(ScopeTimeSlotForm)
