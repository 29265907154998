import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@mui/material/InputAdornment'
import Icon from '@mui/material/Icon'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'

const propTypes = {
	onChange: PropTypes.func.isRequired,
}

const debounce = onChange => {
	let timer
	return function () {
		clearTimeout(timer)
		timer = setTimeout(() => onChange.apply(this, arguments), 250)
	}
}

class EmployeeListSearchField extends Component {

	constructor(props) {
		super(props)

		this.debounce = debounce(props.onChange)
		this.state = {
			searchTerm: '',
		}
	}

	handleChange = e => {
		this.debounce(e.target.value)
		this.setState({
			searchTerm: e.target.value,
		})
	}

	handleReset = () => {
		this.props.onChange('')
		this.setState({
			searchTerm: ''
		})
	}

	render() {
		const { searchTerm } = this.state;

		return (
			<TextField
				fullWidth
				autoFocus
				placeholder={'Search'}
				value={searchTerm}
				onChange={this.handleChange}
				InputProps={{
					startAdornment: (
						<InputAdornment position={'start'}>
							<Icon color={'disabled'}>{'search'}</Icon>
						</InputAdornment>
					),
					endAdornment: searchTerm.length > 0 ? (
						<InputAdornment position={'end'}>
							<IconButton onClick={this.handleReset}>
								<Icon>close</Icon>
							</IconButton>
						</InputAdornment>
					) : undefined,
				}}
			/>
		)
	}
}

EmployeeListSearchField.propTypes = propTypes

export default EmployeeListSearchField
