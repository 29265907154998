import React, { Component } from 'react'
import { Routes, Route } from 'react-router-dom'
import { sortScopesOnName } from '../../helpers/scope'
import withRouter from '../../hoc/withRouter';
import Loader from '../util/loader/Loader'
import CourseGroupIndexPage from './CourseGroupIndexPage'
import CourseGroupCostsPage from './CourseGroupCostsPage'
import withScopeAndSubScopes from '../../modules/scope/withScopeAndSubScopes'
import Alert from '../util/alert/Alert'
import CourseEvaluationsPage from './evaluations/CourseEvaluationsPage'
import CourseGroupFinancialPage from './CourseGroupFinancialPage'

class CourseGroupPage extends Component {
	render() {
		const { params, loading, error, scope, subScopes } = this.props;

		if(error) return (
			<Alert type={Alert.TYPE_DANGER}>Cannot load this scope. {error.error}</Alert>
		);

		if(loading || !scope || !subScopes) return (
			<Loader />
		);

		const groupId = parseInt(params.groupId);
		const group = subScopes.find(scope => scope.id === groupId);

		const props = {
			course: scope,
			group: group,
			groups: sortScopesOnName(subScopes),
		};

        return (
        	<Routes>
				<Route path={'/'} index element={<CourseGroupIndexPage {...props} />} />
				<Route path={'financial'} element={<CourseGroupFinancialPage {...props} />} />
				<Route path={'costs'} element={<CourseGroupCostsPage {...props} />} />
				<Route path={'evaluations'} element={<CourseEvaluationsPage {...props} />} />
			</Routes>
        );
    }
}

CourseGroupPage.propTypes = {};

export default withRouter(withScopeAndSubScopes(CourseGroupPage, (props) => {
	return props.params && props.params.id;
}))
