import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ModalManager from '../../../modal/ModalManager';
import CompanyCaptionSelector from '../CompanyCaptionSelector';
import webstoreLocales from '../locales/WebstoreLocales';

const PREFIX = 'CompanyCaptionsNewDialog';

const classes = {
    selectedCaption: `${PREFIX}-selectedCaption`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.selectedCaption}`]: {
		borderLeft: `2px solid ${theme.palette.primary.main}`,
		padding: theme.spacing(0, 2),
		margin: theme.spacing(2, 0),
	}
}));

function CompanyCaptionsNewDialog({ open, handleNewCaptionClose, translations }) {
	const [key, setKey] = useState(undefined);
	const { t } = useTranslation();


	const handleSelectChange = (event, option) => {
		setKey(option?.key ? option.key : undefined);
	};

	return (
        <StyledDialog open={open} fullWidth maxWidth="sm" onClose={handleNewCaptionClose}>
			<DialogTitle>{t('manage.company.captionDialog.addCaptionOverride')}</DialogTitle>
			<DialogContent style={{ paddingBottom: '3rem' }}>
				<p>{t('manage.company.captionDialog.newCaptionDesc')}</p>
				<CompanyCaptionSelector
					handleSelectChange={handleSelectChange}
					translations={translations}
				/>
				{ typeof key === 'string' && (
					<>
						<Divider style={{ margin: '2rem 0' }} />
						<Typography variant="h6">{t('manage.company.captionDialog.selectedCaption')}</Typography>
						<div className={classes.selectedCaption}>
							<Typography variant="subtitle2">{key}</Typography>
							<Typography paragraph>{webstoreLocales[key]}</Typography>
						</div>
					</>
				) }
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={handleNewCaptionClose}
					color="default"
				>
					{t('general.close')}
				</Button>
				<Button
					component={Link}
					variant="contained"
					color="primary"
					disabled={!key}
					onClick={handleNewCaptionClose}
					to="/portal/company/captions"
					state={{
						modal: 'editCaptions',
						selectedKey: key,
					}}
				>
					{t('general.add')}
				</Button>
			</DialogActions>
		</StyledDialog>
    );
}

CompanyCaptionsNewDialog.propTypes = {
	open: PropTypes.bool,
	handleNewCaptionClose: PropTypes.func,
	translations: PropTypes.object,
};

export default CompanyCaptionsNewDialog;
