import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getGroupOrders } from '../../actions/courseGroupActions'
import { capabilities, hasScopeCapability } from '../../helpers/capabilities'
import withFetchData from '../../modules/fetchData/withFetchData'
import PageContentBlock from '../page/PageContentBlock'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import CourseGroupOccupancyList from './CourseGroupOccupancyList'
import withRouter from '../../hoc/withRouter';
import { withTranslation } from 'react-i18next'
import ModalItem from '../modal/ModalItem'
import ModalManager from '../modal/ModalManager'
import CourseGroupOccupancyClientModal from './CourseGroupOccupancyClientModal'
import CourseGroupOccupancyMailModal from './CourseGroupOccupancyMailModal'

class CourseGroupOccupancy extends Component {
	state = {
		showEmail: false,
	}

	render(){
		const { group, course, groups, location, loading, error, data, t } = this.props;
		const { showEmail } = this.state;

		let links = undefined;
		if(hasScopeCapability(group, capabilities.GroupEmailCapability)){
			links = [
				{
					onClick: this.toggleShowEmail,
					label: showEmail ? t('scope.course.occupancy.showNames') : t('scope.course.occupancy.showEmails'),
					icon: showEmail ? 'perm_identity' : 'email',
					basic: true,
				},
				{
					to: location.pathname,
					state: { modal: 'viewMail' },
					label: t('scope.course.occupancy.groupEmail'),
					icon: 'send',
				},
			];
		}

		return (
			<Fragment>
				<ModalManager>
					<ModalItem state="viewClient" component={CourseGroupOccupancyClientModal} group={group} orders={data} courseId={course.id} groups={groups} />
					<ModalItem state="viewMail" component={CourseGroupOccupancyMailModal} group={group} />
				</ModalManager>
				<PageContentBlock
					title={`${t('general.occupancy')} ${group.soldUnits}/${group.availableUnits}`}
					icon="people"
					links={links}
					linksInMenuBreakpoint="lg"
				>
					{error ? (
						<ApiError error={error} />
					) : (
						(loading || !data) ? (
							<Loader />
						) : (
							<CourseGroupOccupancyList
								orders={data}
								group={group}
								course={course}
								showEmail={showEmail}
								createClientTo={this.clientTo}
							/>
						)
					)}
				</PageContentBlock>
			</Fragment>
		);
	}

	componentDidUpdate(prevProps){
		if(this.props.group.id !== prevProps.group.id){
			this.props.refresh();
		}
	}

	toggleShowEmail = () => {
		this.setState({
			showEmail: !this.state.showEmail
		});
	}

	clientTo = (orderEntryId) => {
		const { location } = this.props;

		return {
			pathname: location.pathname,
			state: {
				modal: 'viewClient',
				orderEntryId,
			},
		};
	}
}

CourseGroupOccupancy.propTypes = {
	group: PropTypes.object.isRequired,
	course: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
}

export default withTranslation()(withRouter(withFetchData(
	(props) => getGroupOrders(props.group.id), {
		customId: () => 'CourseGroupOccupancy',
	}
)(CourseGroupOccupancy)))
