import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { fetchClientOrders } from '../../actions/clientActions'
import withFetchData from '../../modules/fetchData/withFetchData'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import ClientCourseTable from './clientCourse/ClientCourseTable'
import ClientOrderTable from './clientOrder/ClientOrderTable'
import ClientOrderModal from './clientOrderModal/ClientOrderModal'

const propTypes = {
	location: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,
}

const ClientOverviewFetchDataLayer = ({ location, client, data, loading, error }) => (
	<Fragment>
		<ModalManager>
			<ModalItem state={`viewOrder`} component={ClientOrderModal} orders={data} />
		</ModalManager>
		<Grid item xs={12}>
			<ClientCourseTable {...{ location, client, data, loading, error }}/>
		</Grid>
		<Grid item xs={12}>
			<ClientOrderTable {...{ location, client, data, loading, error }}/>
		</Grid>
	</Fragment>
)

ClientOverviewFetchDataLayer.propTypes = propTypes

export default withFetchData(props => fetchClientOrders(props.client.id))(ClientOverviewFetchDataLayer)
