import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import { useTranslation } from 'react-i18next';
import PageContentBlock from '../../../page/PageContentBlock'
import ApiError from '../../../util/ApiError'
import Loader from '../../../util/loader/Loader'
import { questionOrder } from '../evaluationUtils'
import EvaluationListItem from './EvaluationListItem'
import withFetchData from '../../../../modules/fetchData/withFetchData'
import { getSubScopeEvaluations } from '../../../../actions/evaluationActions'

const CoursePageEvaluationList = ({ course, error, loading, data }) => {
	const { t } = useTranslation();
	const totalNumberOfEvaluations = useMemo(() => {
		return data && data.reduce((acc, n) => acc + n.numberOfEvaluations, 0);
	}, [data]);

	const totalAverageEvaluation = useMemo(() => {
		return data && (data.reduce((acc, n) => {
			const gradeTotal = questionOrder.reduce((acc, q) => acc + n[q], 0);
			const grade = gradeTotal ? gradeTotal / questionOrder.length : 0;

			return acc + (grade * n.numberOfEvaluations);
		}, 0) / totalNumberOfEvaluations);
	}, [data]);

	return (
		<PageContentBlock
			title={t('general.evaluations')}
			icon="star_half"
		>
			{error ? (
				<ApiError error={error} />
			) : (
				(loading || !data) ? (
					<Loader />
				) : (
					<List disablePadding={true}>
						<EvaluationListItem
							to={`/portal/planner/course/${course.id}/evaluations`}
							label="Average evaluation"
							averageEvaluation={totalAverageEvaluation}
						/>
						{data.map(groupEvaluationResult => {
							const { scope, numberOfEvaluations } = groupEvaluationResult;
							const gradeTotal = groupEvaluationResult && questionOrder.reduce((acc, q) => acc + groupEvaluationResult[q], 0);
							const grade = gradeTotal ? gradeTotal / questionOrder.length : 0;

							return (
								<EvaluationListItem
									key={scope.id}
									to={`/portal/planner/course/${scope.parent.id}/group/${scope.id}/evaluations`}
									label={scope.name}
									soldUnits={scope.soldUnits}
									evaluations={numberOfEvaluations}
									averageEvaluation={grade}
								/>
							);
						})}
					</List>
				)
			)}
		</PageContentBlock>
	)
};

CoursePageEvaluationList.propTypes = {
	course: PropTypes.object.isRequired,
};

export default withFetchData(props => getSubScopeEvaluations(props.course.id))(CoursePageEvaluationList)
