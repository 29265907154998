const editorBaseStyleCreator = (theme) => ({
	'& .editor-text-bold': {
		fontWeight: 'bold',
	},
	'& .editor-text-italic': {
		fontStyle: 'italic',
	},
	'& .editor-text-underline': {
		textDecoration: 'underline',
	},
	'& .editor-text-strikethrough': {
		textDecoration: 'line-through',
	},
	'& .editor-text-underlineStrikethrough': {
		textDecoration: 'underline line-through',
	},
	'& .editor-link': {
		color: 'rgb(33, 111, 219)',
		textDecoration: 'none',
	},
	'& .editor-paragraph': {
		margin: 0,
		marginBottom: theme.spacing(1),
		position: 'relative',
	},
	'& .editor-paragraph:last-child': {
		marginBottom: 0,
	},
	'& .editor-quote': {
		margin: 0,
		marginLeft: 20,
		fontSize: 15,
		color: 'rgb(101, 103, 107)',
		borderLeft: '4px solid rgb(206, 208, 212)',
		paddingLeft: theme.spacing(2),
	},
	'& .editor-list-ol': {
		padding: 0,
		margin: 0,
		marginLeft: theme.spacing(2),
	},
	'& .editor-list-ul': {
		padding: 0,
		margin: 0,
		marginLeft: theme.spacing(2),
	},
	'& .editor-listitem': {
		margin: theme.spacing(1, 4),
	},
	'& .editor-nested-listitem': {
		listStyleType: 'none',
	},
});

export default editorBaseStyleCreator;
