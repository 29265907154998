import moment from 'moment'

export const sortKey = key => (a, b) => a[key] - b[key]
export const sortAlphabet = key => (a, b) => a[key].localeCompare(b[key])
export const sortByTime = key => (a, b) => moment(a[key]).isBefore(b[key]) ? -1 : 1

export const sortAgenda = (data, key, combine = true) => {

	const { upcoming, finished } = data.reduce((acc, item) => {

		if (!moment.isMoment(item[key]) && !moment.isDate(item[key])) {
			console.warn(`Not a moment nor date obj: ${key} -> ${item.toString()}`)
			return acc
		}

		acc[moment(item[key]).isBefore() ? 'upcoming' : 'finished'].push(item)
		return acc
	}, { upcoming: [], finished: [] })

	upcoming.sort(sortByTime(key))
	finished.sort(sortByTime(key)).reverse()

	return combine ? upcoming.concat(finished) : { upcoming, finished }

}
