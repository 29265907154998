import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import withFetchData from '../../../modules/fetchData/withFetchData';
import { getScopesTillGroups } from '../../../modules/scope/scopeActions';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';
import Alert from '../../util/alert/Alert';
import ScopePickerCourseListCourse from './ScopePickerCourseListCourse';

const PREFIX = 'ScopePickerCourseList';

const classes = {
    root: `${PREFIX}-root`,
    wrapper: `${PREFIX}-wrapper`,
    list: `${PREFIX}-list`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		marginTop: theme.spacing(2),
	},

    [`& .${classes.wrapper}`]: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.list}`]: {
		marginTop: theme.spacing(2),
	}
}));

const ScopePickerCourseList = ({ error, loading, study, onSelectCourse, onSelectGroup, multi, filterCourse, courses, load }) => {

	const [search, setSearch] = React.useState('');

	// Refresh list when study changes
	useEffect(() => {
		load();
	}, [study.id, load]);

	if (!onSelectCourse && !onSelectGroup) return (
		<Alert type={Alert.TYPE_WARNING}>
			Cannot select course or group because there are no actions defined.
		</Alert>
	);

	return (
        <Root className={classes.root}>
			<DialogContentText>
				Select a course {onSelectGroup ? 'and group ' : ''}from study {study.name}. This list excludes finished/closed courses. {onSelectGroup ? 'Groups which are in an administrative state cannot be picked anymore.' : ''}
			</DialogContentText>
			{error ? (
				<ApiError error={error} />
			) : (loading || !courses || !study._groups) ? (
				<Loader />
			) : (
				<div className={classes.wrapper}>
					<TextField
						fullWidth
						variant="outlined"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder={`Search course or group of ${study.name}`}
						InputProps={search === '' ? {} : {
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setSearch('')}><Icon>close</Icon></IconButton>
								</InputAdornment>
							),
						}}
					/>
					<div className={classes.list}>
						{courses.length > 0 ? (
							(filterCourse ? courses.filter(filterCourse) : courses).map(course => (
								<ScopePickerCourseListCourse
									key={course.id}
									scope={course}
									search={search}
									onSelectCourse={onSelectCourse}
									onSelectGroup={onSelectGroup}
									multi={multi}
								/>
							))
						) : (
							<Alert>Well, this is awkward. Apparently there are no transferable courses for this study...</Alert>
						)}
					</div>
				</div>
			)}
		</Root>
    );
};

ScopePickerCourseList.propTypes = {
	study: PropTypes.object.isRequired,
	onSelectCourse: PropTypes.func,
	onSelectGroup: PropTypes.func,
	multi: PropTypes.array,
	filterCourse: PropTypes.func,
};

export default withFetchData(props => {
	if (props.study._class === 'Course' && !props.study._groups) {
		return getScopesTillGroups(props.study.id);
	}

	return undefined;
}, {
	mapStateToProps: (state, props) => ({
		courses: state.entities.scopes[props.study.id]?.subScopes
			? state.entities.scopes[props.study.id].subScopes.map(id => state.entities.scopes[id])
			: [],
		study: state.entities.scopes[props.study.id], // Make sure the study is fresh from the store
	}),
})(ScopePickerCourseList);
