import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Alert from './alert/Alert';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: false,
		};
	}

	static getDerivedStateFromError(error) {
		return {
			error,
		};
	}

	render() {
		const { children, hideStack } = this.props;
		const { error } = this.state;

		if (error) return (
			<Alert type={Alert.TYPE_DANGER}>
				<p>
					Something went wrong. Please contact a developer and send a screenshot of this error!
					<em> To continue, go back to another page and refresh the window.</em>
				</p>
				<p><strong>{error.message}</strong></p>
				<p><code>{error.toString()}</code></p>
				{!hideStack && (
					<p><code>{error?.stack || ''}</code></p>
				)}
			</Alert>
		);

		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
	hideStack: PropTypes.bool,
};

export default ErrorBoundary;
