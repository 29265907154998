import { styled } from '@mui/material/styles';
import React from 'react'

const PREFIX = 'Line';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		margin: theme.spacing(1, 0),
		borderBottom: `${theme.palette.primary.light} 1px solid`,
	}
}));

const Line = ({}) => {


	return <Root className={classes.root} />;
};

export default Line
