import React from 'react'

const DEBUG = process.env.NODE_ENV !== 'production' || process.env.STAGE === 'stage';
const withDebugOnly = (WrappedComponent) => (props) => {
	if(!DEBUG) return null;

	return <WrappedComponent {...props} />;
};

export default withDebugOnly
