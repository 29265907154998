import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { fetchEmployees } from '../../actions/employeeListActions';
import { capabilities, hasScopeCapability } from '../../helpers/capabilities';
import { scopeParentsFromStoreFull } from '../../helpers/scope';
import withFetchData from '../../modules/fetchData/withFetchData';
import Loader from '../util/loader/Loader';
import PropertiesTable from '../util/PropertiesTable';

// TODO: refactor when the employee list endpoint gives more function information
// This list reflects function names from production and is more of a quick fix
// It is necessary to do it like this because the employee list endpoint gives only a list of function names
// In the future this list from the API should probably be extended with a tiny bit more information to make filters like this dynamic
const managerFuncList = [
	'FM Promo', // For DEV
	'CM/MM',
	'Marketing Manager',
	'Content Manager',
	'Operations Manager',
];

function ScopeInformationModal({ handleClose, scope, data, loading, load }) {
	const { t } = useTranslation();

	const email = useMemo(() => {
		const scopes = scopeParentsFromStoreFull(scope.id);
		const emails = scopes.map(s => s?.properties?.contactEmail).filter(Boolean).reverse();

		return emails[0];
	}, [scope]);

	const canSeeEmployees = hasScopeCapability(scope, capabilities.SeeEmployeeCapability);

	useEffect(() => {
		if (canSeeEmployees) {
			load(fetchEmployees(scope, true, false, false));
		}
	}, [scope, load, canSeeEmployees]);

	const managers = useMemo(() => {
		if (!data) return [];

		return data.filter(employee => managerFuncList.some(func => employee.function.indexOf(func) > -1));
	}, [data]);

	const items = [
		{
			label: t('scope.information.contactEmail'),
			content: email || '-',
			copy: !!email,
		},
		...(canSeeEmployees ? [
			{
				label: t('scope.information.managers'),
				content: loading ? <Loader inline small /> : managers.map((manager) => (
					<div key={manager.id}>
						<Link to={`/portal/employees/${manager.id}/view`}>{manager.fullName}</Link>
						{` - ${manager.function}`}
					</div>
				)),
				copy: false,
			},
		] : []),
	];

	return (
		<Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth>
			<DialogTitle>{t('scope.information.title')}</DialogTitle>
			<DialogContent>
				<PropertiesTable items={items} />
			</DialogContent>
		</Dialog>
	);
}

export default withFetchData(null)(ScopeInformationModal);
