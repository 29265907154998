import { Icon, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { lazy, Suspense } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { languages } from '../../../../helpers/localization';
import ModalManager from '../../../modal/ModalManager';
import Loader from '../../../util/loader/Loader';

const MarkdownViewer = lazy(() => import('../../../util/markdown/MarkdownViewer'));

const PREFIX = 'CompanyFAQEditItem';

const classes = {
    QA: `${PREFIX}-QA`,
    questionTable: `${PREFIX}-questionTable`,
    lang: `${PREFIX}-lang`,
    top: `${PREFIX}-top`,
    bottom: `${PREFIX}-bottom`,
    noTrans: `${PREFIX}-noTrans`,
    markdown: `${PREFIX}-markdown`
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`& .${classes.QA}`]: {
		color: theme.palette.grey[600],
		fontWeight: theme.typography.fontWeightMedium,
		width: 70,
	},

    [`& .${classes.questionTable}`]: {
		tableLayout: 'fixed',
		'& td': {
			border: 0,
		},
	},

    [`& .${classes.lang}`]: {
		borderBottom: 0,
		width: '100%',
	},

    [`& .${classes.top}`]: {
		paddingBottom: theme.spacing(0.5),
	},

    [`& .${classes.bottom}`]: {
		paddingTop: theme.spacing(0.5),
	},

    [`& .${classes.noTrans}`]: {
		color: theme.palette.grey[500],
	},

    [`& .${classes.markdown}`]: {
		display: 'block',
		position: 'relative',
		maxHeight: '250px',
		overflow: 'hidden',
		'& p': {
			margin: theme.spacing(1, 0),
		},
		'&:after': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '15px',
			bottom: 0,
			left: 0,
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		},
	}
}));

function CompanyFAQEditItem({ item }) {
	const { t } = useTranslation();

	return (
        <Draggable draggableId={`${item.sectionIndex}${item.itemIndex}`} index={item.itemIndex} key={`${item.sectionIndex}${item.itemIndex}`}>
			{provided => (
				<StyledTableRow ref={provided.innerRef} {...provided.draggableProps}>
					<TableCell style={{ padding: 0 }} colSpan={10}>
						<Table className={classes.questionTable}>
							<TableBody>
								<TableRow>
									<TableCell rowSpan={2} style={{ color: '#ccc', width: 70 }} {...provided.dragHandleProps}>
										<Icon>drag_indicator</Icon>
									</TableCell>
									<TableCell className={[classes.QA, classes.top].join(' ')} style={{ borderBottom: 0 }}>
										Q
									</TableCell>
									{typeof item.title === 'string' && (
										<TableCell colSpan={Object.keys(languages).length} className={classes.top}>
											<Typography>
												{item.title}
												<i className={classes.noTrans}>{` (${t('manage.company.faq.oneTextForAllLanguages')})`}</i>
											</Typography>
										</TableCell>
									)}
									{typeof item.title === 'object' && Object.keys(languages).map(lang => (
										<TableCell className={[classes.top, classes.lang].join(' ')} key={lang}>
											<Typography>
												{item.title[lang] || <i className={classes.noTrans}>{t('manage.company.faq.noTranslation')}</i>}
											</Typography>
										</TableCell>
									))}
									<TableCell rowSpan={2} style={{ width: 120, textAlign: 'right' }}>
										<IconButton
											variant="outlined"
											component={Link}
											to="/portal/company/faq"
											state={{ modal: 'editFAQItem', selectedItem: item }}
											aria-label={t('general.edit')}
											size="small"
										>
											<Icon>edit</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell className={[classes.QA, classes.bottom].join(' ')}>
										A
									</TableCell>
									{typeof item.content === 'string' && (
										<TableCell colSpan={Object.keys(languages).length} className={classes.bottom}>
											<div className={classes.markdown}>
												<Suspense fallback={<Loader />}>
													<MarkdownViewer linkTarget="_blank">{item.content}</MarkdownViewer>
												</Suspense>
											</div>
											<i className={classes.noTrans}>{`(${t('manage.company.faq.oneTextForAllLanguages')})`}</i>
										</TableCell>
									)}
									{typeof item.content === 'object' && Object.keys(languages).map(lang => (
										<TableCell className={classes.bottom} key={lang}>
											{item.content?.[lang]
												? (
													<div className={classes.markdown}>
														<Suspense fallback={<Loader />}>
															<MarkdownViewer linkTarget="_blank">{item.content?.[lang]}</MarkdownViewer>
														</Suspense>
													</div>
												)
												: <i className={classes.noTrans}>{t('manage.company.faq.noTranslation')}</i>}
										</TableCell>
									))}
								</TableRow>
							</TableBody>
						</Table>
					</TableCell>
				</StyledTableRow>
			)}
		</Draggable>
    );
}

CompanyFAQEditItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default CompanyFAQEditItem;
