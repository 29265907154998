import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import withTimeSlots from '../../../modules/timeSlots/withTimeSlots'
import { niceDateDay, niceTime } from '../../../helpers/datetime'
import { composeLocationName } from '../../../helpers/scope'
import Alert from '../../util/alert/Alert'

const ScopeTooltipScopeGroupTimeSlots = ({ timeSlots }) => {
	if(timeSlots.length === 0) return (
		<CardContent>
			<Alert type={Alert.TYPE_INFO}>No timeslots yet.</Alert>
		</CardContent>
	);

	return (
		<List disablePadding>
			{timeSlots.map((timeSlot) => (
				<ListItem key={timeSlot.id} divider>
					<ListItemText
						primary={niceDateDay(timeSlot.startDateTime)}
						secondary={[
							`${niceTime(timeSlot.startDateTime)} - ${niceTime(timeSlot.endDateTime)}`,
							composeLocationName(timeSlot.classRoom),
							timeSlot.teacher && timeSlot.teacher.fullName,
						].filter(Boolean).join(' / ')}
					/>
				</ListItem>
			))}
		</List>
	);
}

ScopeTooltipScopeGroupTimeSlots.propTypes = {
	groupId: PropTypes.number.isRequired,
};

export default withTimeSlots()(ScopeTooltipScopeGroupTimeSlots)
