import { CALL_API } from '../setup/api'

export const TASK = 'TASK'

export const getScopesTillGroupsTL = (id) => ({
	[CALL_API]: {
		type: TASK,
		endpoint: `scope/${id}/groups`,
	}
})
