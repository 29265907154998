import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React, { useCallback, useEffect, useRef } from 'react'
import withFormData from '../../modules/formData/withFormData'
import { CALL_API } from '../../setup/api'
import Alert from '../util/alert/Alert'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import { useNavigate } from 'react-router-dom';
import PartnerForm from './PartnerForm'

const apiAction = (data) => ({
	[CALL_API]: {
		type: 'PARTNER',
		endpoint: 'partner',
		body: data,
	},
});

const PartnerCreateModal = ({ handleClose, saving, error, success, watchSubmit }) => {
	const navigate = useNavigate();
	const dialogRef = useRef();

	const handleSave = useCallback((data) => {
		if(dialogRef.current){
			const body = dialogRef.current.getElementsByClassName('MuiDialog-container');
			body && body[0] && body[0].scrollTo(0, 0);
		}
		watchSubmit(apiAction(data));
	}, [watchSubmit]);

	useEffect(() => {
		if(success && success.id){
			navigate('/portal/promotion/partners/edit/', { replace: true } );
		}
	}, [success, navigate]);

	return (
		<Dialog open={true} onClose={handleClose} scroll="body" ref={dialogRef}>
			<DialogTitle>Create new partner</DialogTitle>
			<DialogContent>
				{saving && (
					<Loader sheet />
				)}
				{error && (
					<ApiError error={error} />
				)}
				{success ? (
					<Alert type={Alert.TYPE_SUCCESS}>
						Partner was successfully saved.
					</Alert>
				) : (
					<PartnerForm
						disabled={saving}
						onSave={handleSave}
					/>
				)}
			</DialogContent>
			<DialogActions/>
		</Dialog>
	);
}

export default withFormData()(PartnerCreateModal)
