/**
 * Show a notification in the bottom right of the screen
 *
 * @param message
 * @param color string one of ['default', 'success', 'warning', 'error', 'info']
 */
export const notificationShow = (message, color = 'default') => ({
	type: 'NOTIFICATION_SHOW',
	message: {
		content: message,
		color: color,
	},
});

export const notificationHide = () => ({
	type: 'NOTIFICATION_HIDE',
});

export const notificationQueue = () => ({
	type: 'NOTIFICATION_QUEUE',
});
