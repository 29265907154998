import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { parse } from 'query-string';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';

const authSelector = (state) => state.auth;

function LoginPage() {
    const auth = useSelector(authSelector);
    const location = useLocation();
    const navigate = useNavigate();
    const query = parse(location.search);

    useEffect(() => {
        if (auth.tokenValue) navigate(query.from || '/portal', { replace: true });
        // eslint-disable-next-line -- on mount
    }, []);

    useEffect(() => {
        if (auth?.tokenValue) navigate(query.from || '/portal', { replace: true });
        // eslint-disable-next-line -- after login
    }, [auth]);

    return (
        <Grid container justifyContent="center">
            <Grid item lg={3} md={4} sm={6} xs={11}>
                <LoginForm />
            </Grid>
        </Grid>
    );
}

export default LoginPage;
