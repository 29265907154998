import { createSearchParams } from '../helpers/properties';
import { getApiTypeSuccess } from '../helpers/types';
import { Schemas, CALL_API } from './schemas';
import { fetchDataAppend, fetchDataDelete, fetchDataInternalActions, fetchDataReplaceBy } from '../modules/fetchData/fetchDataActions';

const CLIENT_LIST = 'CLIENT_LIST';
const CLIENT = 'CLIENT';
const CLIENT_ORDER_LIST = 'CLIENT_ORDER_LIST';
const CLIENT_ORDER_TRANSACTION_LIST = 'CLIENT_ORDER_TRANSACTION_LIST';
const CLIENT_EDIT = 'CLIENT_EDIT';
const ALL_CLIENT_PROPERTIES = 'ALL_CLIENT_PROPERTIES';
const CLIENT_PROPERTIES = 'CLIENT_PROPERTIES';

export const fetchClients = scope => ({
	[CALL_API]: {
		type: CLIENT_LIST,
		endpoint: `scope/${scope.id}/clients`,
	},
});

export const fetchSearchClients = (scope, properties, searchTerm) => {
	const params = createSearchParams(properties);
	return {
		[CALL_API]: {
			type: CLIENT_LIST,
			endpoint: `scope/${scope.id}/clients
			?searchTerm=${searchTerm}
			${params.toString().length > 0 ? `&${params.toString()}` : ''}`,
		},
	};
};

export const fetchClient = id => ({
	[CALL_API]: {
		type: CLIENT,
		endpoint: `client/${id}`,
		schema: Schemas.CLIENT,
	},
});

export const fetchClientOrders = id => ({
	[CALL_API]: {
		type: CLIENT_ORDER_LIST,
		endpoint: `client/${id}/orders`,
	},
});

export const fetchClientOrderTransactions = id => ({
	[CALL_API]: {
		type: CLIENT_ORDER_TRANSACTION_LIST,
		endpoint: `order/${id}/transactions`,
	},
});

export const patchClientProfile = (id, data) => ({
	[CALL_API]: {
		type: CLIENT_EDIT,
		endpoint: `client/${id}`,
		method: 'PUT',
		body: data,
		schema: Schemas.CLIENT,
	},
});

export const getClientProperties = id => ({
	[CALL_API]: {
		type: CLIENT_PROPERTIES,
		endpoint: `client/${id}/properties`,
	},
});

export const CLIENT_PROPERTY_EDIT = 'CLIENT_PROPERTY_EDIT';
export const patchClientProperties = (clientId, data) => (dispatch) => dispatch({
	[CALL_API]: {
		type: CLIENT_PROPERTY_EDIT,
		endpoint: `client/${clientId}/properties`,
		method: 'PATCH',
		body: data,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(CLIENT_PROPERTY_EDIT)) {
		dispatch(fetchDataInternalActions.fetchDataSuccess('ProfileProperties', res.response.result));

		// Specifically for clients, als update the entity
		dispatch({
			type: CLIENT_PROPERTY_EDIT,
			response: {
				entities: {
					clients: {
						[clientId]: {
							properties: res.response.result,
						},
					},
				},
			},
		});
	}
	return res;
});

export const CLIENT_PROPERTY_ADD = 'CLIENT_PROPERTY_ADD';
export const addClientProperty = (property) => (dispatch) => dispatch({
	[CALL_API]: {
		type: CLIENT_PROPERTY_ADD,
		endpoint: 'client/properties',
		method: 'POST',
		body: property,
	},
}).then(res => {
	dispatch(fetchDataAppend('PropertyEditor', res?.response?.result));
});

export const CLIENT_PROPERTY_UPDATE = 'CLIENT_PROPERTY_UPDATE';
export const updateClientProperty = (property) => (dispatch) => {
	const newProperty = { ...property };
	delete newProperty.id;
	return dispatch({
		[CALL_API]: {
			type: CLIENT_PROPERTY_UPDATE,
			endpoint: `client/properties/${property.id}`,
			method: 'PUT',
			body: newProperty,
		},
	}).then(res => {
		const data = res.response.result;
		dispatch(fetchDataReplaceBy('PropertyEditor', 'id', data));
	});
};

export const CLIENT_PROPERTY_DELETE = 'CLIENT_PROPERTY_DELETE';
export const deleteClientProperty = (property) => (dispatch) => dispatch({
	[CALL_API]: {
		type: CLIENT_PROPERTY_DELETE,
		endpoint: `client/properties/${property.id}`,
		method: 'DELETE',
	},
}).then(res => {
	dispatch(fetchDataDelete('PropertyEditor', property));
	return res;
});

export const getAllClientProperties = () => ({
	[CALL_API]: {
		type: ALL_CLIENT_PROPERTIES,
		endpoint: 'client/properties',
	},
});
