import React, { Component } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CourseGroupOccupancyMailListModal from './CourseGroupOccupancyMailListModal'
import withFetchData from '../../modules/fetchData/withFetchData'
import { getGroupMail } from '../../actions/courseGroupActions'
import { niceDateTime } from '../../helpers/datetime'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'

class CourseGroupOccupancyMailList extends Component {
	state = {
		openMail: null,
	}

	render(){
		const { loading, error, data } = this.props;
		const { openMail } = this.state;

		if(error) return (
			<Alert type={Alert.TYPE_DANGER}>
				Error! Sorry, we could not load the data.
			</Alert>
		);

		if(loading) return (
			<Loader />
		);

		if(!data || data.length === 0) return (
			<Alert type={Alert.TYPE_INFO}>
				No email send to this group yet.
			</Alert>
		);

		return (
			<div>
				{openMail && (
					<CourseGroupOccupancyMailListModal
						groupEmailId={openMail.id}
						handleClose={this.handleClose}
					/>
				)}
				<List>
					{data.map(email => (
						<ListItem key={email.id}
								  button divider
								  onClick={() => this.handleShow(email)} >
							<ListItemText primary={email.subject}
										  secondary={niceDateTime(email.messageCreationDateTime)} />
						</ListItem>
					))}
				</List>
			</div>
		)
	}

	handleShow = (mail) => {
		this.setState({
			openMail: mail
		});
	}

	handleClose = () => {
		this.setState({
			openMail: null
		});
	}
}

CourseGroupOccupancyMailList.propTypes = {
	group: PropTypes.object.isRequired,
};

export default withFetchData(
	(props) => getGroupMail(props.group.id)
)(CourseGroupOccupancyMailList)
