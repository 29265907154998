import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const PREFIX = 'LiveBlockHeading';

const classes = {
    heading: `${PREFIX}-heading`,
    text: `${PREFIX}-text`,
    secondary: `${PREFIX}-secondary`
};

const StyledHeadingDiv = styled('div')(({ theme }) => ({
    [`&.${classes.heading}`]: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(2),
		height: theme.spacing(7),
	},

    [`& .${classes.text}`]: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '1.3rem',
		color: theme.palette.text.secondary,
		flex: 1,
	},

    [`& .${classes.secondary}`]: {
		textAlign: 'right',
	}
}));

const LiveBlockHeading = ({ secondary, children, }) => {
	return (
        <Fragment>
			<StyledHeadingDiv className={classes.heading}>
				<div className={classes.text}>
					{children}
				</div>
				{Boolean(secondary) && (
					<div className={classes.secondary}>
						<Typography variant="caption" color="textSecondary">
							{secondary}
						</Typography>
					</div>
				)}
			</StyledHeadingDiv>
			<Divider />
		</Fragment>
    );
}

LiveBlockHeading.propTypes = {
	children: PropTypes.node.isRequired,
	secondary: PropTypes.node,
};

export default (LiveBlockHeading)
