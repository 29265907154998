import React, { PureComponent } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { useTranslation, withTranslation } from 'react-i18next';
import withFetchData from '../../modules/fetchData/withFetchData'
import { getContracts } from '../../actions/meActions'
import { niceDateYear } from '../../helpers/datetime'
import { scopeParentsNameString } from '../../helpers/scope'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import PageContentBlock from '../page/PageContentBlock'

const ContractRow = ({ id, function: { name }, scopes, startDate }) => (
	<TableRow key={id}>
		<TableCell>#{id}</TableCell>
		<TableCell>
			{name}
			{scopes.map(scope => (
				<div key={scope.id}>
					<small><em>{scopeParentsNameString(scope)}</em></small>
				</div>
			))}
		</TableCell>
		<TableCell>{niceDateYear(startDate)}</TableCell>
	</TableRow>
)

const ContractsTable = ({ data }) => {
	const { t } = useTranslation();
	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>Id</TableCell>
					<TableCell>{t('dashboard.contracts.function')}</TableCell>
					<TableCell>{t('dashboard.contracts.since')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data.length > 0 ? (
					data.map(ContractRow)
				) : (
					<TableRow>
						<TableCell colSpan={3}>
							<em>{t('dashboard.contracts.noContractsFound')}</em>
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	)
}

class DashboardContracts extends PureComponent {
	render() {
		const { loading, error, data, t } = this.props
		if (error) return <Alert>{t('errors.dataLoadError')}</Alert>

		return (
			<PageContentBlock
				title={t('dashboard.contracts.title')}
				description={t('dashboard.contracts.description')}
				icon="work"
			>
				{(loading || !data) ? (
					<Loader/>
				) : (
					<ContractsTable data={data.filter(row => !row.endDate)}/>
				)}
			</PageContentBlock>
		)
	}
}

export default withTranslation()(withFetchData(getContracts)(DashboardContracts))
