import React from 'react'
import PageHeader from '../../components/page/PageHeader'
import PageHeaderTitle from '../../components/page/PageHeaderTitle'

const NotFound = () => {
	return(
		<PageHeader>
			<PageHeaderTitle
				headline="Page not found"
				description="Sorry, the request page could not be found. Check if the url is correct. If you got here through a link or button on portal, please get in contact with the IT department." />
		</PageHeader>
	);
}

export default NotFound
