let bufferReadCookies = undefined;
let bufferWriteCookies = undefined;

export function setCookieBuffered(read){
	bufferReadCookies = read;
	bufferWriteCookies = [];
}

export function getCookieBuffered(){
	return bufferWriteCookies;
}

export function createCookieSeconds(name, value, seconds) {
	let date = new Date();
	date.setTime(date.getTime() + (seconds * 1000));

	createCookie(name, value, date);
}

export function createCookieDays(name, value, days) {
	createCookieSeconds(name, value, days * 24 * 60 * 60);
}

export function createCookieDateString(name, value, dateString) {
	const date = new Date(dateString);

	// Take 6 hours to be sure, you never know with timezones...
	date.setTime(date.getTime() - (6 * 60 * 60 * 1000));

	createCookie(name, value, date);
}

function createCookie(name, value, date) {
	if(bufferWriteCookies !== undefined){
		bufferWriteCookies.push({
			name: name, value: value, expires: date, secure: true, sameSite: 'lax',
		});
	}else{
		let expires = date ? "; expires=" + date.toGMTString() : "";
		document.cookie = name + "=" + value + expires + "; path=/; secure; samesite=lax";
	}
}

export function readCookie(name) {
	if(bufferReadCookies){
		return bufferReadCookies[name];
	}

	if(typeof document === 'undefined' || !document.cookie) return null;

	let nameEQ = name + "=";
	let ca = document.cookie.split(';');

	for(let i=0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0)===' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}

	return null;
}

export function eraseCookie(name) {
	createCookieDays(name, "", -1);
}
