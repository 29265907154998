import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../../locales/translation_en.json';
import translationNl from '../../locales/translation_nl.json';
import translationDe from '../../locales/translation_de.json';

const defaultLanguage = 'en';

const i18nServices = {
	locales: [
		{ value: 'nl', label: 'Nederlands' },
		{ value: 'en', label: 'English' },
		{ value: 'de', label: 'Deutsch' },
	],

	initI18n(lng) {
		i18next
			// pass the i18n instance to react-i18next.
			.use(initReactI18next)
			// init i18next
			// for all options read: https://www.i18next.com/overview/configuration-options
			.init({
				lng: lng || defaultLanguage,
				fallbackLng: defaultLanguage,
				debug: process.env.NODE_ENV !== 'production',
				returnEmptyString: false,
				resources: {
					en: {
						translation: translationEn,
					},
					nl: {
						translation: translationNl,
					},
					de: {
						translation: translationDe,
					},
				},
				interpolation: {
					escapeValue: false, // not needed for react as it escapes by default
				},
			});
	},

	changeLanguage(lng) {
		if (!lng) return;

		localStorage.setItem('locale', lng);
		i18next.changeLanguage(lng);
	},

	getLanguageFromCache() {
		const lng = localStorage.getItem('locale') || defaultLanguage;

		// This prevents reading string "null" from storage which was caused by a regression - 2022-01-18
		return lng === 'null' ? defaultLanguage : lng;
	},
};

export default i18nServices;
