import moment from 'moment';
import { clone } from 'ramda';
import { SCOPE_FILTER, scopeFilterSubTypes } from './scopeFilterActions';
import { filterRangeFormat } from './scopeFilterHelpers';

const defaultScopeFilterState = {
	search: undefined,
	range: {
		start: moment().subtract(31, 'days').format(filterRangeFormat),
		end: null,
	},
	hideClosed: false,
	onlyVisible: false,
	properties: {},
};

export function scopeFilterReducerFinal(state = defaultScopeFilterState, action) {
	if (action.type !== SCOPE_FILTER) return state;
	if (action.subType === scopeFilterSubTypes.SEARCH) state = {
		...state,
		search: action.search && action.search.length > 0 ? action.search.toLowerCase() : undefined,
	};

	if (action.subType === scopeFilterSubTypes.RANGE) state = {
		...state,
		range: action.range,
	};

	if (action.subType === scopeFilterSubTypes.HIDE_CLOSED) state = {
		...state,
		hideClosed: Boolean(action.hideClosed),
	};

	if (action.subType === scopeFilterSubTypes.ONLY_VISIBLE) state = {
		...state,
		onlyVisible: Boolean(action.onlyVisible),
	};

	if (action.subType === scopeFilterSubTypes.PROPERTIES) state = {
		...state,
		properties: action.properties,
	};

	return state;
}

export const scopeFilterReducerKey = 'scopeFilter';
const scopeFilterReducer = {
	[scopeFilterReducerKey]: scopeFilterReducerFinal,
};

export default scopeFilterReducer;
