import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { clone, equals } from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { composePatchScope, editScopeTypes } from '../../../../actions/scopeEditActions';
import withFormData from '../../../../modules/formData/withFormData';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import ApiError from '../../../util/ApiError';
import CompanyPropertiesCaptionEditor from '../CompanyPropertiesCaptionEditor';
import webstoreLocales from '../locales/WebstoreLocales';

const PREFIX = 'CompanyCaptionsModal';

const classes = {
    selectedCaption: `${PREFIX}-selectedCaption`,
    modalGrid: `${PREFIX}-modalGrid`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.selectedCaption}`]: {
		borderLeft: `2px solid ${theme.palette.primary.main}`,
		padding: theme.spacing(0, 2),
		margin: theme.spacing(2, 0),
	},

    [`& .${classes.modalGrid}`]: {
		marginBottom: theme.spacing(3),
	}
}));

function CompanyCaptionsModal({ modal, handleClose, topLevelScope, saving, error, watchSubmit }) {
	const { t } = useTranslation();
	const { selectedKey } = modal;
	const { properties } = topLevelScope;
	const companyCaption = properties?.translations?.[selectedKey];
	const [caption, setCaption] = useState(companyCaption);
	const isDirty = !equals(companyCaption, caption);


	const handleSave = () => {
		const newProperties = { ...properties };
		newProperties.translations[selectedKey] = caption;

		watchSubmit(composePatchScope(editScopeTypes.COMPANY)(topLevelScope.id, {
			properties: newProperties,
		}));
	};

	const handleRevert = () => {
		setCaption(companyCaption);
	};

	const handleDelete = () => {
		if (window.confirm(t('manage.company.captionDialog.confirmPermanentRemoveCaption'))) {
			const newProperties = clone(properties);
			delete newProperties.translations[selectedKey];

			if (Object.keys(newProperties.translations).length === 0) {
				delete newProperties.translations;
			}
			watchSubmit(composePatchScope(editScopeTypes.COMPANY)(topLevelScope.id, {
				properties: newProperties,
			})).then((() => handleClose()));
		}
	};

	return (
        <StyledDialog
			open={true}
			onClose={handleClose}
			maxWidth="md"
			fullWidth
		>
			<DialogTitle>
				{t('manage.company.captionDialog.editCaption')}
			</DialogTitle>
			<DialogContent className={classes.modalGrid}>
				{error && (
					<ApiError error={error} />
				)}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h6">{t('manage.company.captionDialog.defaultCaption')}</Typography>
						<div className={classes.selectedCaption}>
							<Typography variant="subtitle2">{selectedKey}</Typography>
							<Typography paragraph>{webstoreLocales[selectedKey]}</Typography>
						</div>
						<Typography variant="h6">{t('manage.company.captionDialog.setCaptionOverride')}</Typography>
						<CompanyPropertiesCaptionEditor
							caption={caption}
							setCaption={setCaption}
							selectedKey={selectedKey}
							handleClose={handleClose}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleDelete}
					disabled={saving}
					startIcon={<Icon>delete</Icon>}
					color="secondary"
					variant="outlined"
					style={{ marginRight: 'auto' }}
				>
					{t('general.delete')}
				</Button>
				<Button
					onClick={handleRevert}
					disabled={!isDirty || saving}
					color="primary"
					startIcon={<Icon>restore</Icon>}
					variant="outlined"
				>
					{t('general.revert')}
				</Button>
				<Button onClick={handleClose} variant="contained">
					{t('general.close')}
				</Button>
				<Button
					onClick={handleSave}
					disabled={!isDirty || saving}
					color="primary"
					variant="contained"
					startIcon={<Icon>save</Icon>}
				>
					{t('general.save')}
				</Button>
			</DialogActions>
		</StyledDialog>
    );
}

export default withWorkingScope(withFormData()(CompanyCaptionsModal));
