import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import moment from 'moment'
import { reverse, range } from 'ramda'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Avatar from '@mui/material/Avatar'
import { moneySumList, moneyMinus, moneyZero } from '../../helpers/money'
import withFetchData from '../../modules/fetchData/withFetchData'
import { CALL_API } from '../../setup/api'
import Loader from '../util/loader/Loader'
import { formatLiveStatsBigMoney } from './liveStatsBlock/LiveStatsBlock'
import { subtractExtraDaysFromThisYearToFitBeginOfTheWeekLastYear } from './liveUtils'
import LiveMoneyChip from './utils/LiveMoneyChip'
import LiveWhisker from './utils/LiveWhisker'

const PREFIX = 'LiveDailyIncomeGraph';

const classes = {
    root: `${PREFIX}-root`,
    head: `${PREFIX}-head`,
    tableRow: `${PREFIX}-tableRow`,
    tableRowTotal: `${PREFIX}-tableRowTotal`,
    tableRowTop: `${PREFIX}-tableRowTop`,
    dayCell: `${PREFIX}-dayCell`,
    avatar: `${PREFIX}-avatar`,
    dayLabel: `${PREFIX}-dayLabel`,
    tableLookAhead: `${PREFIX}-tableLookAhead`
};

const StyledTable = styled(Table)(({ theme }) => ({
    [`& .${classes.tableRow}`]: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.grey[50],
		},
	},

    [`& .${classes.tableRowTotal}`]: {
		borderTop: `2px solid ${theme.palette.divider}`,
		'& td': {
			borderBottom: 'none',
		},
	},

    [`& .${classes.tableRowTop}`]: {
		'& td': {
			border: 'none',
			padding: theme.spacing(2),
			'&:last-child': {
				paddingRight: theme.spacing(2),
			},
		},
	},

    [`& .${classes.dayCell}`]: {
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
	},

    [`& .${classes.avatar}`]: {
		border: `1px solid ${theme.palette.primary.main}`,
		backgroundColor: 'transparent',
		color: theme.palette.primary.main,
		fontSize: '1em',
		width: 30,
		height: 30,
	},

    [`& .${classes.dayLabel}`]: {
		marginLeft: theme.spacing(2),
		color: theme.palette.text.secondary,
		verticalAlign: 'middle',
	},

    [`& .${classes.tableLookAhead}`]: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
		border: `1px solid ${theme.palette.divider}`,
		boxShadow: theme.shadows[1],
		'& > div': {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignContent: 'center',
			alignItems: 'center',
			fontSize: '0.9em',
			color: theme.palette.text.secondary,
            [`& .${classes.avatar}`]: {
				marginBottom: 2,
				width: 26,
				height: 26,
			},
            [`& .${classes.dayLabel}`]: {
				marginLeft: theme.spacing(1),
			},
		},
	}
}));

const apiAction = (rootScopeId) => ({
	[CALL_API]: {
		type: 'LIVE_STATS',
		endpoint: `scope/${rootScopeId}/income-forecast?begin=${moment().subtract(1, 'year').subtract(14, 'day').format('YYYY-MM-DD')}`,
	},
});

const TableCellPadding = TableCell;

function findLastYear(data, date){
	const lastDate = moment(date)
		.subtract(1, 'year')
		.subtract(subtractExtraDaysFromThisYearToFitBeginOfTheWeekLastYear, 'day');

	return data.find(item => {
		return moment(item.orderDateTime).isSame(lastDate, 'day');
	});
}

// TODO: realisedRevenue === income, key will be corrected later
const totalIncome = (list) => moneySumList(list.map(day =>
	day.realisedRevenue
));

const totalLastYearIncome = (list) => moneySumList(list.map(day =>
	day.daysAgo >= 0 ? (day.lastYear ? day.lastYear.realisedRevenue : false) : false
));

const newVersion = false;

const LiveDailyIncomeGraph = ({ loading, data, }) => {
	if(!data) return (
		<Loader sheet />
	);

	const daysLookForward = 5;

	const days = useMemo(() => {
		const dStart = moment().add(daysLookForward, 'days');
		const dWeekAgo = moment().subtract(7, 'days');

		return reverse(data.filter(item => {
			return moment(item.orderDateTime).isBetween(dWeekAgo, dStart, 'day', '(]');
		})).map(day => ({
			...day,
			daysAgo: dStart.diff(day.orderDateTime, 'day') - daysLookForward,
			lastYear: findLastYear(data, day.orderDateTime),
		}));
	}, [data]);

	const totals = useMemo(() => {
		const total = totalIncome(days);
		const totalLastYear = totalLastYearIncome(days);

		return {
			total, totalLastYear,
			diff: moneyMinus(total, totalLastYear)
		};
	}, [days]);

	const lookForward = useMemo(() => {
		return range(1, daysLookForward + 1).map(forward => {
			const forwardDay = moment().add(forward, 'days');

			return {
				daysForward: forward,
				dayName: forwardDay.format('dd'),
				lastYear: findLastYear(data, forwardDay),
			};
		});
	}, [data]);

	return (
        <StyledTable>
			<TableHead>
				<TableRow>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>Days ago</TableCellPadding>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>Last year</TableCellPadding>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>Income</TableCellPadding>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>Difference</TableCellPadding>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow className={classes.tableRowTop}>
					<TableCellPadding
                        colSpan={4}
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>
						<div className={classes.tableLookAhead}>
							{lookForward.map(day => (
								<div key={day.daysForward}>
									<div className={classes.dayCell}>
										<Avatar className={classes.avatar}>
											+{day.daysForward}
										</Avatar>
										<div className={classes.dayLabel}>
											{day.dayName}
										</div>
									</div>
									<div>
										{formatLiveStatsBigMoney(
											day.lastYear ? day.lastYear.realisedRevenue || 0 : 0
										)}
									</div>
								</div>
							))}
						</div>
					</TableCellPadding>
				</TableRow>
				{days.filter(day => day.daysAgo >= 0).map(day => {
					if(newVersion) return (
						<TableRow key={day.orderDateTime} className={classes.tableRow}>
							<TableCell title={moment(day.orderDateTime).format('dddd D MMMM YYYY')}>
								{day.daysAgo === 0
									? 'Today'
									: `${day.daysAgo} day${day.daysAgo === 1 ? '' : 's'} ago`
								}
							</TableCell>
							<TableCell title={day.lastYear ? moment(day.lastYear.orderDateTime).format('dddd D MMMM YYYY') : ''}>
								{formatLiveStatsBigMoney(
									day.lastYear ? day.lastYear.realisedRevenue || 0 : 0
								)}
							</TableCell>
							<TableCell>
								{formatLiveStatsBigMoney(day.realisedRevenue || 0)}
							</TableCell>
							<TableCell>
								<LiveMoneyChip money={moneyMinus(day.realisedRevenue, day.lastYear ? day.lastYear.realisedRevenue : 0)} />
							</TableCell>
						</TableRow>
					);

					return (
                        <TableRow key={day.orderDateTime} className={classes.tableRow}>
							<TableCellPadding
                                title={moment(day.orderDateTime).format('dddd D MMMM YYYY')}
                                classes={{
                                    root: classes.root,
                                    head: classes.head
                                }}>
								<div className={classes.dayCell}>
									<Avatar className={classes.avatar}>
										{(`${day.daysAgo}`).replace('-', '+')}
									</Avatar>
									<div className={classes.dayLabel}>
										{moment(day.orderDateTime).format('dd')}
									</div>
								</div>
							</TableCellPadding>
							<TableCellPadding
                                title={day.lastYear ? moment(day.lastYear.orderDateTime).format('dddd D MMMM YYYY') : ''}
                                classes={{
                                    root: classes.root,
                                    head: classes.head
                                }}>
								{formatLiveStatsBigMoney(
									day.lastYear ? day.lastYear.realisedRevenue || 0 : 0
								)}
							</TableCellPadding>
							<TableCellPadding
                                classes={{
                                    root: classes.root,
                                    head: classes.head
                                }}>
								{day.daysAgo >= 0 ? formatLiveStatsBigMoney(day.realisedRevenue || 0) : null}
							</TableCellPadding>
							<TableCellPadding
                                classes={{
                                    root: classes.root,
                                    head: classes.head
                                }}>
								{day.daysAgo >= 0 && (
									<LiveWhisker
										left={day.realisedRevenue || moneyZero}
										right={day.lastYear ? day.lastYear.realisedRevenue || moneyZero : moneyZero}
									/>
								)}
							</TableCellPadding>
						</TableRow>
                    );
				})}
				<TableRow className={classes.tableRowTotal}>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>
						<strong>Total</strong>
					</TableCellPadding>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>
						<strong>{formatLiveStatsBigMoney(totals.totalLastYear)}</strong>
					</TableCellPadding>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>
						<strong>{formatLiveStatsBigMoney(totals.total)}</strong>
					</TableCellPadding>
					<TableCellPadding
                        classes={{
                            root: classes.root,
                            head: classes.head
                        }}>
						<LiveMoneyChip money={totals.diff} />
					</TableCellPadding>
				</TableRow>
			</TableBody>
		</StyledTable>
    );
};

LiveDailyIncomeGraph.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default (withFetchData((props) => apiAction(props.rootScopeId), {
	autoRefreshSeconds: 60,
})(LiveDailyIncomeGraph))
