import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { getScopeContracts } from '../../../actions/scopeDataActions'
import { sortByTime } from '../../../helpers/sorts'
import { formatMoney } from '../../../helpers/formatter'
import { niceDateYear, datePassed } from '../../../helpers/datetime'
import { capabilities } from '../../../helpers/capabilities'
import FetchDataSwitch from '../../util/FetchDataSwitch'

const PREFIX = 'CourseBookletsContracts';

const classes = {
    error: `${PREFIX}-error`
};

const StyledFetchDataSwitch = styled(FetchDataSwitch)(({ theme }) => ({
    [`& .${classes.error}`]: {
		backgroundColor: theme.palette.error.main,
		color: 'white',
	}
}));

function bookletContractStatus(contract){
	if(contract.endDate && datePassed(contract.endDate, contract.dueDate)){ // endDate < dueDate
		return 'BOOKLET DENIED';
	}

	if(datePassed(contract.dueDate)){ // dueDate < NOW
		return 'BOOKLET ACCEPTED';
	}

	return 'BOOKLET IN PROGRESS';
}

const handleData = data => data.filter(contract => contract.function.capabilities.includes(capabilities.WriteBookletCapability))
.sort(sortByTime('createDateTime'))
.reverse()

const CourseBookletsContracts = ({ data, loading, error, }) => (
	<StyledFetchDataSwitch
		{...{ loading, error, data }}
		ifLoading={<Fragment/>}
		ifError={(
			<TableRow>
				<TableCell colSpan={3} className={classes.error}>
					{error && error.error}
				</TableCell>
			</TableRow>
		)}
		applyFilter={contract => contract.function.capabilities.includes(capabilities.WriteBookletCapability)}
		ifEmptyData={<Fragment/>}
	>
		{data && handleData(data).map(contract => (
			<TableRow key={contract.id}>
				<TableCell>
					<Grid container direction={'column'}>
						<Grid item><strong>Booklet:</strong></Grid>
						<Grid item>Booklet writer:</Grid>
						<Grid item>Status:</Grid>
						<Grid item>Payment:</Grid>
						<Grid item>Due date:</Grid>
					</Grid>
				</TableCell>
				<TableCell>
					<Grid container direction={'column'}>
						<Grid item><strong>{contract.scopes[0].name}</strong></Grid>
						<Grid item>{contract.employee.fullName}</Grid>
						<Grid item>{bookletContractStatus(contract)}</Grid>
						<Grid item>{formatMoney(contract.payment)}</Grid>
						<Grid item>{niceDateYear(contract.dueDate)}</Grid>
					</Grid>
				</TableCell>
			</TableRow>
		))}
	</StyledFetchDataSwitch>
)

CourseBookletsContracts.propTypes = {
	course: PropTypes.object.isRequired,
}

const WithFetchData = withFetchData(props => getScopeContracts(props.course.id))(CourseBookletsContracts)
export default (WithFetchData)
