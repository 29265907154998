import React, { Component, Fragment } from 'react'
import moment from 'moment'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import withFormData from '../../modules/formData/withFormData'
import withFetchData from '../../modules/fetchData/withFetchData'
import { Schemas } from '../../actions/schemas'
import { getDiscountCodes, invalidateDiscountCode } from '../../actions/discountCodeActions'
import { isActive } from './utils'
import Grid from '@mui/material/Grid'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import DiscountCodesForm from './DiscountCodesForm'
import DiscountCodesTable from './DiscountCodesTable'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import PageHeader from '../page/PageHeader'
import PageHeaderMenu from '../page/PageHeaderMenu'
import PageHeaderTitle from '../page/PageHeaderTitle'
import DiscountCodesNew from './DiscountCodesNew'

class DiscountCodesPage extends Component {
	state = {
		includeExpired: false,
	}

	handleSetting = prop => event => this.setState({
		[prop]: event.target.checked,
	})

	invalidateClicked = discountCode => {
		this.props.watchSubmit(invalidateDiscountCode(discountCode))
	}

	transformData = data =>
		(this.state.includeExpired ? data : data.filter(code => isActive(code.expires)))
		.sort((a, b) => moment(b.expires) - moment(a.expires))

	componentDidUpdate(prevProps) {
		if (!this.props.scope) return
		let updateRequired = !prevProps.scope
		updateRequired |= prevProps.scope && prevProps.scope.id !== this.props.scope.id
		if (updateRequired) this.props.load()
	}

	render() {
		const { data, error, loading, saving, scope } = this.props

		return (
			<Fragment>

				{/** Create Form Modal */}
				{saving && <Loader sheet/>}
				<ModalManager>
					<ModalItem state={'add-discountcode'} component={DiscountCodesForm}/>
				</ModalManager>

				{/** Header */}
				<PageHeader>
					<PageHeaderMenu items={[
						{
							name: 'New Discount Code',
							to: location.pathname,
							state: { modal: 'add-discountcode' },
							icon: 'add',
						},
					]}/>
					<PageHeaderTitle
						headline={`Discount Codes`}
						subHeading={`Below discount codes are applicable within ${scope ? scope.name : ''}`}
					/>
					<FormGroup>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<FormControlLabel
									label={'Show expired codes'}
									control={(
										<Switch
											checked={this.state.includeExpired}
											onChange={this.handleSetting('includeExpired')}
											color={'primary'}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</FormGroup>
				</PageHeader>

				{/** Wrapper */}
				<Grid container spacing={2}>
					{/** Error Interface */}
					{error && (
						<Grid item xs={12}>
                            <Alert type={Alert.TYPE_WARNING}>{error.errorCode === 403
                                ? 'Sorry, you may not view the discount codes for this scope.'
                                : error.error
                            }</Alert>
						</Grid>
					)}

					{!scope && (
						<Grid item xs={12}>
						    <Alert type={Alert.TYPE_INFO}>Select a scope fist in the selector at the top of the page.</Alert>
						</Grid>
					)}

					{/** Latest Discount Codes */}
					<Grid item xs={12}>
						<DiscountCodesNew />
					</Grid>

					{/** MAIN discountcode table */}
					<Grid item xs={12}>
						<DiscountCodesTable
							loading={loading}
							discountCodes={data}
							invalidateClicked={this.invalidateClicked}
							transformData={this.transformData}
						/>
					</Grid>
				</Grid>
			</Fragment>
		)
	}
}

const withFetchedData = withFetchData(
	props => props.scope ? getDiscountCodes(props.scope.id) : undefined, {
		customId: () => 'discountCodes',
		denormalize: [Schemas.DISCOUNTCODE],
	})(DiscountCodesPage)

export default withWorkingScope(withFormData({
	customId: () => 'discountCodes',
})(withFetchedData))
