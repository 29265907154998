import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import moment from 'moment'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import { niceDateYear } from '../../helpers/datetime'
import { formatMoneyAuto } from '../../helpers/formatter'
import withFetchData from '../../modules/fetchData/withFetchData'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { getCoursesWithDetails } from  '../../actions/dashboardActions'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import ScopeTooltip from '../scope/tooltip/ScopeTooltip'

const PREFIX = 'CoursesWithDetails';

const classes = {
    body: `${PREFIX}-body`,
    head: `${PREFIX}-head`
};

const StyledTable = styled(Table)(({ theme }) => ({
    [`& .${classes.body}`]: {
		whiteSpace: 'nowrap',
		paddingRight: theme.spacing(1),
		fontSize: '1em !important',
	},

    [`& .${classes.head}`]: {
		paddingRight: theme.spacing(1),
		fontSize: '1em !important',
	}
}));

const DenseTableCell = TableCell;

class CoursesWithDetails extends Component {
	componentDidMount() {
		if (this.props.scope) this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (!this.props.scope) return;
		let updateRequired = !prevProps.scope;
		updateRequired |= prevProps.scope && prevProps.scope.id !== this.props.scope.id;
		updateRequired |= prevProps.pagination.page !== this.props.pagination.page;
		updateRequired |= prevProps.pagination.pageSize !== this.props.pagination.pageSize;
		if (updateRequired) this.fetchData();
	}

	fetchData() {
		this.props.load(getCoursesWithDetails(
			this.props.scope.id
		));
	}

	render() {
		const { scope, loading, error, data, pagination, onPaginationChangePage, onPaginationChangePageSize } = this.props;
		const coursesWithDetails = data;

		if (!scope) return (
			<Alert type={Alert.TYPE_INFO}>Select a scope in the scope select at the top first.</Alert>
		);

		if (error) return (
			<Alert type={Alert.TYPE_WARNING}>{error.errorCode === 403 ?
				'Sorry, you may not view the teacher overview of this scope.' :
				'Error! Sorry, we could not load the data.'
			}</Alert>
		);

		if ( loading || !data ) return (
			<Loader />
		);

		return (
            <StyledTable>
				<TableHead>
					<TableRow>
						<DenseTableCell size="small">Course</DenseTableCell>
						<DenseTableCell size="small">Tutor</DenseTableCell>
						<DenseTableCell size="small">Location</DenseTableCell>
						<DenseTableCell size="small">Deadline Booklet</DenseTableCell>
						<DenseTableCell size="small">Start</DenseTableCell>
						<DenseTableCell size="small">End</DenseTableCell>
						<DenseTableCell size="small" align="right">Price</DenseTableCell>
						<DenseTableCell size="small" align="right">Duration</DenseTableCell>
						<DenseTableCell size="small" align="right">Number of groups</DenseTableCell>
						<DenseTableCell size="small" align="right">Occupancy</DenseTableCell>
						<DenseTableCell size="small" align="right">Revenue</DenseTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ coursesWithDetails && coursesWithDetails.length ?
						coursesWithDetails.map((courseWithDetails) => (
							<TableRow key={courseWithDetails.id}>
								<DenseTableCell size="small" style={{ fontWeight: 'bold', whiteSpace: 'normal' }} >
									<ScopeTooltip scopeId={courseWithDetails.id}>
										<Link to={`/portal/planner/course/${courseWithDetails.id}`}>
											{courseWithDetails.name}
										</Link>
									</ScopeTooltip>
								</DenseTableCell>
								<DenseTableCell size="small" style={{whiteSpace: 'normal' }}>{courseWithDetails.teachers}</DenseTableCell>
								<DenseTableCell size="small" style={{whiteSpace: 'normal' }}>{courseWithDetails.locations}</DenseTableCell>
								<DenseTableCell size="small">{courseWithDetails.latestBookletWriterDeadline && niceDateYear(courseWithDetails.latestBookletWriterDeadline)}</DenseTableCell>
								<DenseTableCell size="small">{courseWithDetails.courseStart && niceDateYear(courseWithDetails.courseStart)}</DenseTableCell>
								<DenseTableCell size="small">{courseWithDetails.courseEnd && niceDateYear(courseWithDetails.courseEnd)}</DenseTableCell>
								<DenseTableCell size="small" align="right">{formatMoneyAuto(courseWithDetails.price)}</DenseTableCell>
								<DenseTableCell size="small" align="right">{courseWithDetails.duration && `${moment.duration(courseWithDetails.duration).hours()}:${(moment.duration(courseWithDetails.duration).minutes()).toString().padStart(2, "0")}`}</DenseTableCell>
								<DenseTableCell size="small" align="right">{courseWithDetails.numberOfGroups}</DenseTableCell>
								<DenseTableCell size="small" align="right">{courseWithDetails.occupancy}</DenseTableCell>
								<DenseTableCell size="small" align="right">{formatMoneyAuto(courseWithDetails.revenue)}</DenseTableCell>
							</TableRow>
						))
						:
						<TableRow>
							<TableCell>No results found. Specify another scope in the scope selector above.</TableCell>
						</TableRow>

					}
				</TableBody>
				{pagination &&
				<TableFooter>
					<TableRow>
						<TablePagination
							colSpan={11}
							count={pagination.total}
							rowsPerPage={pagination.pageSize}
							page={pagination.page}
							onPageChange={onPaginationChangePage}
							onRowsPerPageChange={onPaginationChangePageSize}
							rowsPerPageOptions={[10, 25, 50, 100]}
						/>
					</TableRow>
				</TableFooter>}
			</StyledTable>
        );
	}
}

export default withWorkingScope(withFetchData(null, {
	pagination: true,
	keepData: true
})(CoursesWithDetails))
