import { mapObjIndexed } from 'ramda'
import { colors } from '@mui/material'
import { alpha } from '@mui/material/styles'

const base = {
	neutral: colors.blue,
	danger: colors.red,
	success: colors.green,
	special: colors.purple,
};

const baseFull = 300
const baseBackground = 100;
const baseOpacity = 0.5;

const baseGradientColor = colors.blue;

function createFromBase(){
	return mapObjIndexed((val) => ({
		borderColor: val[baseFull],
		backgroundColor: alpha(val[baseBackground], baseOpacity),
	}))(base);
}

function createOnlyBackgroundFromBase(){
	return mapObjIndexed((val) => ({
		borderColor: val[baseFull],
		backgroundColor: val[baseFull],
	}))(base);
}

const qualitativeColors = [
	colors.orange,
	colors.indigo,
	colors.teal,
	colors.yellow,
	colors.lightBlue,
	colors.deepOrange,
	colors.purple,
	colors.red,
];

function createBothFromColorList(colors, tint){
	return colors.map(color => ({
		borderColor: color[tint],
		backgroundColor: color[tint],
	}))
}

export const FINANCIAL_CHART = {
	line: createFromBase(),
	bar: createOnlyBackgroundFromBase(),
	gradient: [100, 300, 600, 900].map(tone => ({
		borderColor: baseGradientColor[tone],
		backgroundColor: baseGradientColor[tone],
	})),
	qualitativeScale: createBothFromColorList(qualitativeColors, 300),
}
