import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { mapObjIndexed, reduce, values, keys } from 'ramda'
import { Grid, Typography, Divider } from '@mui/material'
import withFormData from '../../modules/formData/withFormData'
import FormDataError from '../../modules/formData/FormDataError'
import { patchCourseLocation } from '../../actions/locationActions'
import { languages, standardizeKeys } from '../../helpers/localization'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import AthenaEditor from '../util/AthenaEditor'

class LocationFooterEditForm extends Component {
	constructor(props) {
		super(props);
		const { courseGroupLocationMailFooter, courseGroupLocationMailFooterEditor } = props.inputData;
		const base = reduce((obj, l) => ({ ...obj, [l]: '' }), {}, keys(languages));

		this.state = {
			courseGroupLocationMailFooter: {
				...base,
				...standardizeKeys(courseGroupLocationMailFooter),
			},
			courseGroupLocationMailFooterEditor: {
				...base,
				...standardizeKeys(courseGroupLocationMailFooterEditor),
			},
		};
	}

	componentDidMount() {
		if (this.props.bindSave) {
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.success && !prevProps.success && this.props.onSave) {
			this.props.onSave();
		}
	}

	handleChangeBind = (field) => (value, html) => {
		const { courseGroupLocationMailFooter, courseGroupLocationMailFooterEditor } = this.state;
		this.setState({
			courseGroupLocationMailFooter: {
				...courseGroupLocationMailFooter,
				[field]: html,
			},
			courseGroupLocationMailFooterEditor: {
				...courseGroupLocationMailFooterEditor,
				[field]: value,
			},
		});
	};

	handleSubmit = () => {
		const { watchSubmit, inputData } = this.props;
		const { courseGroupLocationMailFooter, courseGroupLocationMailFooterEditor } = this.state;

		watchSubmit(patchCourseLocation(inputData.id, {
			courseGroupLocationMailFooter,
			courseGroupLocationMailFooterEditor,
		}));
	};

	render() {
		const { saving, error, success } = this.props;
		const { courseGroupLocationMailFooter, courseGroupLocationMailFooterEditor } = this.state;

		if (success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Location saved.
			</Alert>
		);

		return (
			<div>
				<FormDataError error={error} showFieldErrors />
				{ saving && <Loader sheet /> }
				<Grid container spacing={3}>
					{values(mapObjIndexed((lang, key) => (
						<Grid item xs={12} key={key}>
							<Typography variant="h6" style={{ marginBottom: '1rem' }}>{lang}</Typography>
							<AthenaEditor
								value={courseGroupLocationMailFooterEditor[key] || courseGroupLocationMailFooter[key]}
								onChange={this.handleChangeBind(key)}
								output="html"
							/>
							<Divider style={{ margin: '1rem 0 0' }} />
						</Grid>
					), languages))}
				</Grid>
			</div>
		);
	}
}

LocationFooterEditForm.propTypes = {
	inputData: PropTypes.object,
};

export default withFormData()(LocationFooterEditForm);
