import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import FileDropZone from '../../util/FileDropZone';

function DeclarationEntryAttachmentForm({ disabled, control, errors, index }) {
	const { replace } = useFieldArray({
		control,
		name: `entries[${index}].attachments`,
	});

	const handleAcceptedFiles = (selectedFiles) => {
		replace(selectedFiles);
	};

	return (
		<div>
			<FormHelperText sx={{ color: 'error.main' }}>
				{errors.entries?.[index]?.attachments?.message}
			</FormHelperText>
			{!disabled && (
				<FileDropZone
					maxFiles={3}
					accept=".jpg,.jpeg,.png,.pdf"
					handleAcceptedFiles={handleAcceptedFiles}
				/>
			)}
		</div>
	);
}

DeclarationEntryAttachmentForm.propTypes = {
	errors: PropTypes.object,
	control: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	index: PropTypes.number,
};

export default DeclarationEntryAttachmentForm;
