import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BatchesItemPage from './BatchesItemPage';
import BatchesIndexPage from './BatchesIndexPage';

function BatchesPage() {
    return (
        <Routes>
            <Route path="/" index element={<BatchesIndexPage />} />
            <Route path=":id" element={<BatchesItemPage />} />
        </Routes>
    );
}

export default BatchesPage;
