import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { filter } from 'ramda'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import withFormData from '../../modules/formData/withFormData'
import { orderSwap } from '../../actions/orderActions'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import OrderOptionsSwapCourseModal from './OrderOptionsSwapCourseModal'
import OrderOptionsSwapConfirmModal from './OrderOptionsSwapConfirmModal'

class OrderOptionsSwap extends Component {
	state = {
		selectedGroupId: '',
		showSwapCourse: false,
		showConfirmSwapGroup: null,
	}

	render(){
		const { scope, groups, client, saving, error, success } = this.props;
		const { selectedGroupId, showSwapCourse, showConfirmSwapGroup } = this.state;

		let switchGroups = [];
		if(groups){
			switchGroups = filter((g) => (
				g.id !== scope.id && g.artificialStockStatus !== 'FULL'
			), groups);
		}

		return (
			<div>
				{showSwapCourse && (
					<OrderOptionsSwapCourseModal
						handleClose={this.handleSwapCourse}
						group={scope}
						client={client}
						onSelectGroup={this.handleSwapSelectGroup}
					/>
				)}
				{showConfirmSwapGroup && (
					<OrderOptionsSwapConfirmModal
						handleClose={this.handleSwapConfirmClose}
						client={client}
						fromGroup={scope}
						toGroup={showConfirmSwapGroup}
					/>
				)}
				<p>
					<strong>Move the client</strong> to another group within this course. Or swap to another course by using the other button.'
				</p>
				{saving && <Loader />}
				{error && <Alert type={Alert.TYPE_WARNING}>
					{error.error}
				</Alert>}
				{success ?
					<Alert type={Alert.TYPE_SUCCESS}>
						Action was successful, it might take a few minutes and a page refresh for the changes to be visible.
					</Alert>
					:
					(!switchGroups || switchGroups.length === 0) ?
						<Fragment>
							<Alert type={Alert.TYPE_INFO}>
								No groups found within this course to move the client to (could be that the manipulation status is set to full).
							</Alert>
							<Button
								variant="outlined"
								color="primary"
								fullWidth
								onClick={this.handleSwapCourse}
							>
								Swap to another course
							</Button>
						</Fragment>
						:
						<Grid container spacing={2}>
							<Grid item xs={12}>
								{!saving && <FormControl fullWidth>
									<Select onChange={this.handleGroupChange}
											value={selectedGroupId}
											displayEmpty
											inputProps={{
												name: 'group'
											}}>
										<MenuItem value=""
												  disabled={true}>
											<em>Select group</em>
										</MenuItem>
										{switchGroups.map((group) => {
											const full = (group.availableUnits - group.soldUnits) <= 0;
											return (
												<MenuItem key={group.id}
														  value={group.id}
														  disabled={full}>
													{group.name}{full && ' (already full)'}
												</MenuItem>
											)
										})}
									</Select>
								</FormControl>}
							</Grid>
							<Grid item xs={6}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									disabled={saving}
									onClick={this.handleSwap}
								>
									Swap
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant="outlined"
									color="primary"
									fullWidth
									onClick={this.handleSwapCourse}
								>
									Swap to another course
								</Button>
							</Grid>
						</Grid>
				}
			</div>
		)
	}

	handleGroupChange = (e) => {
		this.setState({
			selectedGroupId: e.target.value
		})
	}

	handleSwapCourse = () => {
		this.setState(state => ({
			showSwapCourse: !state.showSwapCourse,
			showConfirmSwapGroup: null,
		}));
	}

	handleSwapSelectGroup = (group) => {
		this.setState({
			showSwapCourse: false,
			showConfirmSwapGroup: group,
		});
	}

	handleSwapConfirmClose = (back = false) => {
		this.setState({
			showConfirmSwapGroup: null,
			showSwapCourse: Boolean(back),
		});
	}

	handleSwap = () => {
		const { client, scope, watchSubmit } = this.props;
		const { selectedGroupId } = this.state;

		if(!selectedGroupId || selectedGroupId === '') return;
		if(!confirm('Are your sure you want to move/swap this client? The client will NOT be automatically notified of the change.')) return;

		watchSubmit(orderSwap({
			productId: scope.id,
			clientId: client.id,
			moveId: selectedGroupId
		}));
	}
}

OrderOptionsSwap.propTypes = {
	client: PropTypes.object.isRequired,
	scope: PropTypes.object.isRequired,
	groups: PropTypes.array,
}

export default withFormData()(OrderOptionsSwap)
