import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import React, { Component } from "react";
import OrderStatuses from "../../constants/OrderStatuses";

class FilterChip extends Component {
	constructor(props) {
		super(props);
		this.handleChipClick = this.handleChipClick.bind(this);
	}

	handleChipClick() {
		const { handleChipClick, filter } = this.props;
		handleChipClick(filter)
	}

	render() {
		const { activeFilters, filter } = this.props;
		const isCurrent = activeFilters.includes(filter);

		return (
			<Grid item>
				<Chip
					label={OrderStatuses[filter]}
					deleteIcon={<Icon>{isCurrent ? 'remove' : 'add'}</Icon>}
					clickable
					onClick={this.handleChipClick}
					onDelete={this.handleChipClick}
					color={isCurrent ? 'primary' : 'default'}
					sx={{ m:  '0.25em 0 0.75em 1em' }}
				/>
			</Grid>
		);
	}
}

export default FilterChip
