import React, { lazy, Suspense } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { reduce, max } from 'ramda';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getAllScopeProperties } from '../../actions/scopeDataActions';
import { useFetchData } from '../../helpers/hooks';
import { propertyLabel, propertyValue } from '../../helpers/properties';
import { scopeParentsToBreadcrumbs, scopeParentsFromStoreFull, scopeParentsFromStore } from '../../helpers/scope';
import { hasScopeCapability, capabilities } from '../../helpers/capabilities';
import { formatMoney } from '../../helpers/formatter';
import { GROUP_STATE_CANCELLED } from '../../helpers/groupState';
import PageHeader from '../page/PageHeader';
import PageHeaderTitle from '../page/PageHeaderTitle';
import PageHeaderMenu from '../page/PageHeaderMenu';
import PageHeaderNavigation from '../page/PageHeaderNavigation';
import ModalManager from '../modal/ModalManager';
import ModalItem from '../modal/ModalItem';
import PageNoteBlock from '../page/PageNoteBlock';
import Loader from '../util/loader/Loader';
import CourseBookletReferencesPageBlock from './CourseBookletReferencesPageBlock';
import ScopeVisibilitySwitcher from '../util/ScopeVisibilitySwitcher';
import PriceLabel from '../util/PriceLabel';
import CourseSkuReferencesModal from './sku/CourseSkuReferencesModal';
import CourseGroupTable from './CourseGroupTable';
import CourseEditModal from './CourseEditModal';
import CourseAddGroupModal from './CourseAddGroupModal';
import CourseBookletsTable from './courseBooklets/CourseBookletsTable';
import CoursePageEvaluationList from './evaluations/courseLists/CoursePageEvaluationList';
import ScopeInformationModal from './ScopeInformationModal';

const LexicalEditor = lazy(() => import('../util/editor/LexicalEditor'));

const PREFIX = 'CourseIndexPage';

const classes = {
    tags: `${PREFIX}-tags`,
    visitButton: `${PREFIX}-visitButton`,
    visitIcon: `${PREFIX}-visitIcon`,
    booklets: `${PREFIX}-booklets`
};

const StyledTagsDiv = styled('div')(({ theme }) => ({
	[`&.${classes.tags}`]: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		'& > a, & > a:link, > a:visited': {
			color: theme.palette.primary.contrastText,
		},
		[`& .${classes.visitButton}`]: {
			boxShadow: 'none',
			marginRight: theme.spacing(2),
		},

		[`& .${classes.visitIcon}`]: {
			marginLeft: theme.spacing(1),
		},
	},
}));

const StyledBookletsDiv = styled('div')(({ theme }) => ({
    [`&.${classes.booklets}`]: {
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.divider}`,
		marginBottom: theme.spacing(2),
		'& > .material-icons': {
			verticalAlign: 'text-bottom',
			margin: theme.spacing(0, 1, 0, 0.5),
			color: theme.palette.error.dark,
		},
	}
}));

const Tag = styled(Chip)(({ theme }) => ({
	margin: theme.spacing(0.25, 0.5, 0.25, 0),
}));

export function validTags(course, study) {
	const courseTags = study && study.properties && study.properties.courseTags;
	if (!courseTags) return [];

	const tags = course.properties && course.properties.tags && course.properties.tags;
	if (!tags) return [];

	const courseTagsLower = courseTags.map(tag => tag.toLowerCase());

	return tags.map(tag => {
		const i = courseTagsLower.indexOf(tag.toLowerCase());
		return i > -1 ? courseTags[i] : false;
	}).filter(Boolean);
}

function validProperties(course, scopeProperties = []) {
	return scopeProperties
		.filter(scopeProp => {
			const value = course.properties?.[scopeProp.key];
			return typeof value !== 'undefined' && value !== null;
		});
}

function getHourPrice(price, groups) {
	const filteredGroups = groups.filter(group => group.state !== GROUP_STATE_CANCELLED && group.published === 'published');

	const minutes = reduce(max, 0, filteredGroups.map(group => moment.duration(group.duration).asMinutes()));

	if (minutes === 0) return 0;

	return price / (minutes / 60);
}

function getNearestEnableSkuBoolean(courseId) {
	const scope = scopeParentsFromStore(courseId, 'properties')
		.reverse()
		.find(scopeProps => typeof scopeProps?.enableSku === 'boolean');
	return scope?.enableSku || false;
}

function getWebStoreUrl(scope) {
	const parents = scopeParentsFromStoreFull(scope.parent);

	// If not at least the study, cannot build url
	if (parents.length < 4) return null;

	// eslint-disable-next-line no-mixed-operators
	const config = parents[0].properties && parents[0].properties.config || {};

	// Take the base from the company
	const base = config.baseUrl || 'https://athenastudies.nl';

	// Build the final url
	return `${base}/training/
		${parents[1].abbreviation.toLowerCase()}/
		${parents[2].abbreviation.toLowerCase()}/
		${parents[3].abbreviation.toLowerCase()}/
		${scope.id}`;
}

const CourseIndexPage = ({ course, groups, parentScope }) => {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const { data: propertyDefinitions } = useFetchData('ScopePropertiesDefinition', getAllScopeProperties);
	const menuItems = [];
    const isSkuEnabled = getNearestEnableSkuBoolean(course.id);

	if (hasScopeCapability(course, capabilities.ScopeAlterCapability)) {
		menuItems.push(
			{
				name: t('general.editValue', { value: t('scopes.course') }),
				to: location.pathname,
				state: { modal: 'edit-course' },
				icon: 'mode_edit',
			},
			{
				name: t('general.addValue', { value: t('scopes.group') }),
				to: location.pathname,
				state: { modal: 'add-group' },
				icon: 'add',
			},
		);
	}

	if (hasScopeCapability(course, capabilities.FinancialReportingCapability)) {
		menuItems.push({ name: t('scope.course.financial'), to: `/portal/planner/course/${course.id}/financial`, icon: 'euro_symbol' });
	}

	if (hasScopeCapability(course, capabilities.CreateDiscountCodeCapability)) {
		menuItems.push({
			name: t('scope.course.createProductBundle'),
			to: '/portal/promotion/bundle',
			state: {
				modal: 'add',
				id: parentScope.id,
				courseId: course.id,
			},
			icon: 'loyalty',
		});
	}

	if (hasScopeCapability(course, capabilities.ScopeAlterCapability)) {
		menuItems.push(
			{
				name: t('scope.course.evaluations'),
				to: `${location.pathname}/evaluations`,
				icon: 'star_half',
			},
			{
				name: t('scope.course.deleteCourse'),
				to: location.pathname,
				state: { modal: 'scopeDelete', scopeId: course.id },
				icon: 'delete',
			},
		);
	}

	menuItems.push(
		{
			name: t('scope.information.button'),
			to: location.pathname,
			state: { modal: 'information' },
			icon: 'info_outlined',
		},
	);

	const hourPrice = getHourPrice(course.price, groups);
	const webStoreUrl = getWebStoreUrl(course);

	const canSeeBookletsTable = hasScopeCapability(course, capabilities.CreateApplicationCapability)
		|| hasScopeCapability(course, capabilities.SeeApplicationCapability);

	return (
        <>
            <ModalManager>
				<ModalItem state="edit-course" component={CourseEditModal} course={course} />
				<ModalItem state="add-group" component={CourseAddGroupModal} course={course} />
				<ModalItem state="edit-sku-list" component={CourseSkuReferencesModal} course={course} isSkuEnabled={isSkuEnabled} />
				<ModalItem state="information" component={ScopeInformationModal} scope={course} />
			</ModalManager>
            <PageHeader>
				<PageHeaderMenu items={menuItems} />
				<PageHeaderNavigation breadcrumbs={[
					{ to: '/portal/planner/', label: 'Planner' },
					...scopeParentsToBreadcrumbs(course.parent),
					{ label: `${course.name} (${course.abbreviation})` },
				]}
				/>
				<PageHeaderTitle
					headline={(
						<>
							{course.name}
							{Boolean(hourPrice) && (
								<Tooltip
									title={t('scope.course.pricePerHourDesc')}
									aria-label={t('scope.course.pricePerHourDesc')}
								>
									<span>
										{/* To accommodate for the tooltip handling */}
										<PriceLabel smaller good={hourPrice < 10} bad={hourPrice >= 10}>
											{formatMoney(hourPrice)}
											{' '}
											p/u
										</PriceLabel>
									</span>
								</Tooltip>
							)}
							<PriceLabel smaller price={course.price} />
							<ScopeVisibilitySwitcher scope={course} />
						</>
					)}
					description={(
						<>
							<StyledTagsDiv className={classes.tags}>
								{webStoreUrl && (
									<Tooltip
										title={t('scope.course.webstoreTooltipTitle')}
										aria-label={t('scope.course.webstoreTooltipTitle')}
									>
										<Button
											component="a"
											variant="contained"
											size="small"
											color="primary"
											href={webStoreUrl}
											target="_blank"
											className={classes.visitButton}
										>
											{t('scope.course.visitInWebstore')}
											{' '}
											<Icon className={classes.visitIcon}>launch</Icon>
										</Button>
									</Tooltip>
								)}
								{validTags(course, parentScope).map(tag => (
									<Tag
                                        key={tag}
                                        label={tag}
                                    />
								))}
								{validProperties(course, propertyDefinitions).map(property => (
									<Tag
                                        key={property.key}
                                        label={(
											<>
												{propertyLabel(property, i18n.language)}
												{': '}
												{propertyValue(property, course.properties?.[property.key], i18n.language)}
											</>
										)}
                                    />
								))}
							</StyledTagsDiv>
							{course.descriptionEditor ? (
								<Suspense fallback={<Loader />}>
									<LexicalEditor
										content={course.descriptionEditor}
										isReadyOnly
									/>
								</Suspense>
							) : (
								<div
									className="richtext"
									dangerouslySetInnerHTML={{ __html: course.description }}
								/>
							)}
						</>
					)}
				/>
			</PageHeader>
            <Grid container spacing={2}>
				<Grid item xs={12}>
					<CourseGroupTable courseId={course.id} groups={groups} />
				</Grid>
			</Grid>
            <br />
            <br />
            <Grid container spacing={2}>
				{canSeeBookletsTable && (
					<Grid item md={6} xs={12}>
						<CourseBookletsTable course={course} location={location} />
					</Grid>
				)}
				<Grid item md={6} xs={12}>
					<CourseBookletReferencesPageBlock course={course} isSkuEnabled={isSkuEnabled} />
				</Grid>
				<Grid item md={6} xs={12}>
					<CoursePageEvaluationList course={course} />
				</Grid>
				<Grid item md={6} xs={12}>
					<PageNoteBlock context="scope" contextId={course.id} />
				</Grid>
			</Grid>
        </>
    );
};

CourseIndexPage.propTypes = {
	course: PropTypes.object,
	groups: PropTypes.array,
	parentScope: PropTypes.object,
};

export default CourseIndexPage;
