import Box from '@mui/material/Box';
import React, { Component, Fragment } from 'react'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import withFetchData from '../../modules/fetchData/withFetchData'
import { orderEntryList } from '../../actions/feedActions'
import { hasScopeCapability, capabilities } from '../../helpers/capabilities'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import ScopeIndicator from '../scope/indicator/ScopeIndicator'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import FeedItem from './FeedItem'

class FeedPage extends Component {
	render(){
		const { workingScope, loading, data, } = this.props;

		if(!workingScope) return (
			<Alert type={Alert.TYPE_INFO}>
				Select a scope first
			</Alert>
		);

		if(loading && !data) return <Loader />;

		const hasClientLink = hasScopeCapability(workingScope, capabilities.SeeClientDetailsCapability);

		return (
            <>
				<PageHeader>
					<PageHeaderTitle
						headline={`Feed ${workingScope.name}`}
						description={(
							<ScopeIndicator fullNames />
						)}
					/>
				</PageHeader>
				<Box sx={{ px: { md: 1 }, py: { md: 0 } }}>
					{loading && <Loader sheet />}
					{!data || data.length === 0 ? (
						<Alert type={Alert.TYPE_INFO}>
							No feed information/orders found for the selected scope.
						</Alert>
					) : (
						<Fragment>
							{data.map((item) => (
								<FeedItem
									key={item.orderEntryId}
									item={item}
									hasClientLink={hasClientLink}
								/>
							))}
						</Fragment>
					)}
				</Box>
			</>
        );
	}

	componentDidUpdate(prevProps){
		const { workingScope, refresh } = this.props;

		if(
			(!prevProps.workingScope && workingScope) ||
			(workingScope && prevProps.workingScope && workingScope.id !== prevProps.workingScope.id)
		){
			refresh();
		}
	}
}

export default (withWorkingScope(withFetchData((props) => {
	return props.workingScope ? orderEntryList(props.workingScope.id) : undefined
}, {
	autoRefreshSeconds: 120,
})(FeedPage)))
