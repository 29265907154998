import React, { Component } from 'react'
import { clone, mapObjIndexed } from 'ramda'
import Joi from 'joi-browser'

/**
 * Props passed:
 *  - validation.[field] <- preferred on error field
 *  - validationBlur:func(event) <- preferred
 *  - validationAll:func(object, ?getData:func) <- preferred on submit
 */

const defaultOptions = {

};

const errorDetailsToObject = (error, fields) => {
	// Create default error object with no errors
	const errors = mapObjIndexed(field => null, fields);

	// No errors, return defaults
	if(!error || !error.details) return errors;

	// Collect all the errors
	error.details.map(item => {
		const key = item.context.key;

		errors[key] = errors[key]
			? `${errors[key]}, ${item.message}`
			: item.message;
	});

	return errors;
};

const withValidation = (ComposedComponent, fields = {}, extraOptions) => {
	const options = {...defaultOptions, ...extraOptions};
	const defaultErrors = mapObjIndexed(field => null, fields);

	return class WithValidation extends Component {
		displayName = 'withValidation(' + (ComposedComponent.displayName || 'Unknown') + ')';
		component = null;

		constructor(props){
			super(props);

			this.state = {
				error: clone(defaultErrors)
			};
		}

		render(){
			return (
				<ComposedComponent
					{...this.props}
					validationFields={fields}
					validation={this.state.error}
					validationBlur={this.validationBlur}
					validationField={this.validationField}
					validationAll={this.validationAll}
				/>
			);
			// TODO: this ref was only used by edit-partner (HOPEFULLY!!?) comment can be removed later
			// ref={(instance) => {this.component = instance;}} />
		}

		validationBlur = (e) => {
			this.validationField(
				e.target.name,
				e.target.value
			);
		}

		validationField = (fieldName, fieldValue) => {
			if(!fieldName) return;
			if(!fields[fieldName]) return;

			const schema = {
				[fieldName]: fields[fieldName]
			};

			const result = Joi.validate(
				(fieldValue !== null && typeof fieldValue === 'object'
					? fieldValue
					: { [fieldName]: fieldValue }
				), schema, {
					allowUnknown: true,
					context: { props: this.props }
				});

			this.setState(state => ({
				error: {
					...state.error,
					...errorDetailsToObject(result.error, schema),
				}
			}));
		}

		validationAll = (getData, cb) => {
			if(!getData) throw('withValidation HOC validationAll(getData) requires the parameter');

			const data = (typeof getData === 'function') ? getData() : getData;
			const result = Joi.validate(data, fields, {
				abortEarly: false,
				allowUnknown: true,
				context: { props: this.props }
			});
			const newErrors = errorDetailsToObject(result.error, fields);

			this.setState(state => ({
				error: {
					...state.error,
					...newErrors,
				}
			}));

			if(cb) cb(Boolean(result.error), newErrors);

			return Boolean(result.error)
		}
	}
};

// Re-export Joi for easy use
withValidation.Joi = Joi;
export { Joi }

export default withValidation
