import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { Controller } from 'react-hook-form';
import { formatCourseGroupName } from '../../../helpers/scope';
import ScopeGroupSearchModal from './ScopeGroupSearchModal';

function ScopeGroupSearchField({ value, id, disabled, onChange }) {
	const [show, setShow] = useState(false);

	const toggleShow = () => {
		if (disabled) {
			return;
		}
		setShow(!show);
	};

	return (
		<>
			<TextField
				disabled={disabled}
				onClick={toggleShow}
				value={value ? formatCourseGroupName(value) : ''}
				variant="outlined"
				id={id}
				sx={{ backgroundColor: 'common.white' }}
				size="small"
				fullWidth
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton>
								<Icon>search</Icon>
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{show && (
				<ScopeGroupSearchModal
					onClose={toggleShow}
					onPick={(scope) => { onChange(scope); setShow(false); }}
				/>
			)}
		</>
	);
}

ScopeGroupSearchField.propTypes = {
	value: PropTypes.object,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default ScopeGroupSearchField;

export function ControlledScopeGroupSearchField({ disabled, name, control }) {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value } }) => (
				<ScopeGroupSearchField
					value={value}
					disabled={disabled}
					onChange={onChange}
					id={name}
				/>
			)}
		/>
	);
}

ControlledScopeGroupSearchField.propTypes = {
	name: PropTypes.string,
	disabled: PropTypes.bool,
	control: PropTypes.object,
};
