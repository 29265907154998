import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { capabilities } from '../../helpers/capabilities';
import EmployeeTooltip from '../employees/tooltip/EmployeeTooltip';
import Alert from '../util/alert/Alert';
import { niceDateDay, niceTime } from '../../helpers/datetime';
import { composeLocationName } from '../../helpers/scope';

const PREFIX = 'CourseGroupTimeSlotsList';

const classes = {
    listItemRoot: `${PREFIX}-listItemRoot`,
    linked: `${PREFIX}-linked`,
    zoomMeetingIcon: `${PREFIX}-zoomMeetingIcon`,
};

const StyledList = styled(List)(({ theme }) => ({
    [`& .${classes.listItemRoot}`]: {
        userSelect: 'text',
    },

    [`& .${classes.linked}`]: {
        display: 'inline-block',
        marginLeft: theme.spacing(2),
        color: theme.palette.text.secondary,
    },

    [`& .${classes.zoomMeetingIcon}`]: {
        fontSize: '1.3em',
        verticalAlign: '-0.1em',
        marginRight: theme.spacing(1),
    },
}));

function CourseGroupTimeSlotsList({ group, timeSlots, onTimeSlotClick, onTimeSlotZoomMeeting, onTimeSlotAttendance }) {
    const [anchorEls, setAnchorEls] = useState({});
    const { t } = useTranslation();

    const seeAttendance = group.capabilities.includes(capabilities.SeeAttendanceCapability);

    const handleClick = (id) => (e) => {
        // eslint-disable-next-line no-shadow
        setAnchorEls(anchorEls => ({
            ...anchorEls,
            [id]: e.currentTarget,
        }));
    };

    const handleClose = (id) => () => {
        // eslint-disable-next-line no-shadow
        setAnchorEls(anchorEls => ({
            ...anchorEls,
            [id]: null,
        }));
    };

    const handleClickEdit = (timeSlot) => () => {
        handleClose(timeSlot.id)();
        onTimeSlotClick(timeSlot);
    };

    const handleClickAttendance = (timeSlot) => () => {
        handleClose(timeSlot.id)();
        onTimeSlotAttendance(timeSlot);
    };

    const handleClickZoomMeeting = (timeSlot) => () => {
        handleClose(timeSlot.id)();
        onTimeSlotZoomMeeting(timeSlot);
    };

    if (timeSlots.length === 0) return (
        <Alert type={Alert.TYPE_INFO}>{t('scope.course.timeslots.noTimeslotsYet')}</Alert>
    );

    return (
        <StyledList>
            {timeSlots.map((timeSlot) => {
                const linkedCount = timeSlot.referencingCourseGroups
                    ? timeSlot.referencingCourseGroups.length - 1 : 0;

                const linkedSoldUnits = timeSlot.referencingCourseGroups
                    .map(linked => linked.soldUnits);

                return (
                    <ListItem
                        key={`timeSlot_${timeSlot.id}`}
                        divider
                        component="a"
                        classes={{
                            root: classes.listItemRoot,
                        }}
                    >
                        <ListItemText
                            primary={(
                                <>
                                    {timeSlot.zoomMeetingId && (
                                        <Tooltip title={t('scope.course.timeslots.onlineClassPlanned')}>
                                            <Icon color="primary" className={classes.zoomMeetingIcon}>live_tv</Icon>
                                        </Tooltip>
                                    )}
                                    {niceDateDay(timeSlot.startDateTime)}
                                    {linkedCount > 0 && (
                                        <span className={classes.linked}>
                                            {`${linkedCount} linked group${linkedCount > 1 ? 's' : ''}`}
                                            {` (${t('general.occupancyTotal').toLowerCase()}: ${linkedSoldUnits.reduce((tot, u) => tot + u, 0)})`}
                                        </span>
                                    )}
                                </>
                            )}
                            secondary={(
                                <span>
                                    {niceTime(timeSlot.startDateTime)}
                                    {' - '}
                                    {niceTime(timeSlot.endDateTime)}
                                    <br />
                                    {composeLocationName(timeSlot.classRoom)}
                                    <br />
                                    {timeSlot.teacher && (
                                        <EmployeeTooltip employeeId={timeSlot.teacher.id}>
                                            <span>{timeSlot.teacher.fullName}</span>
                                        </EmployeeTooltip>
                                    )}
                                </span>
                            )}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                aria-owns={anchorEls[timeSlot.id] ? `slot-options-${timeSlot.id}` : undefined}
                                aria-haspopup="true"
                                onClick={handleClick(timeSlot.id)}
                            >
                                <Icon>settings</Icon>
                            </IconButton>
                            <Menu
                                id={`slot-options-${timeSlot.id}`}
                                anchorEl={anchorEls[timeSlot.id]}
                                open={Boolean(anchorEls[timeSlot.id])}
                                onClose={handleClose(timeSlot.id)}
                                disableAutoFocusItem
                            >
                                <MenuItem onClick={handleClickEdit(timeSlot)}>
                                    <ListItemIcon><Icon color="primary">edit</Icon></ListItemIcon>
                                    {t('general.edit')}
                                </MenuItem>
                                {seeAttendance && (
                                    <MenuItem onClick={handleClickAttendance(timeSlot)}>
                                        <ListItemIcon><Icon color="primary">people_alt</Icon></ListItemIcon>
                                        Attendance
                                    </MenuItem>
                                )}
                                <MenuItem onClick={handleClickZoomMeeting(timeSlot)}>
                                    <ListItemIcon><Icon color="primary">live_tv</Icon></ListItemIcon>
                                    {t('general.onlineClass')}
                                </MenuItem>
                            </Menu>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </StyledList>
    );
}

CourseGroupTimeSlotsList.propTypes = {
    group: PropTypes.object.isRequired,
    timeSlots: PropTypes.array.isRequired,
    onTimeSlotClick: PropTypes.func.isRequired,
    onTimeSlotZoomMeeting: PropTypes.func.isRequired,
    onTimeSlotAttendance: PropTypes.func.isRequired,
};

export default (CourseGroupTimeSlotsList);
