import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom';

const ModalManager = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();

	if(!location.state) return null;

	let activeChild = null;

	React.Children.forEach(children, (child) => {
		if(location.state.modal === child.props.state){
			activeChild = child;
		}
	});

	return !!activeChild && React.cloneElement(activeChild, {
		handleClose: () => navigate(location.pathname, { state: null }),
		modal: location.state,
	});
}

ModalManager.propTypes = {
	children: PropTypes.node.isRequired
};

export default ModalManager;
