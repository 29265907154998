import { TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getContractTemplates } from '../../../../actions/functionsActions';
import withFetchData from '../../../../modules/fetchData/withFetchData';
import Alert from '../../../util/alert/Alert';
import ModalItem from '../../../modal/ModalItem';
import ModalManager from '../../../modal/ModalManager';
import PageContentBlock from '../../../page/PageContentBlock';
import EditFunctionContractTemplateModal from './EditFunctionContractTemplateModal';
import EditFunctionTemplateStatusChip from './EditFunctionTemplateStatusChip';

const validityDateFormat = (dateTime) => (
	moment(dateTime).format('DD-MM-YYYY')
);

function CompanyFunctionContractTemplates({ functionId, data, error }) {
	const { t } = useTranslation();
	return (
        <>
            <ModalManager>
				<ModalItem state="contractTemplatesEdit" component={EditFunctionContractTemplateModal} />
			</ModalManager>
            <PageContentBlock
				title={t('manage.company.function.templates.title')}
				description={t('manage.company.function.templates.description')}
				icon="lock"
				links={[{
					label: t('manage.company.function.templates.newTemplate'),
					to: `/portal/company/function/${functionId}`,
					state: {
						modal: 'contractTemplatesEdit',
						functionId,
					},
				}]}
			>
				{error && (
					<Alert type={Alert.TYPE_WARNING}>
						{t('errors.dataLoadError')}
					</Alert>
				)}
				<Table>
					<TableBody>
						{Array.isArray(data) && data.map(template => {
							const to = `/portal/company/function/${functionId}`;
							const state = {
								modal: 'contractTemplatesEdit',
								functionId,
								templateId: template.id,
							}
							return (
								<TableRow key={template.id}>
									<TableCell>
										<Typography variant="subtitle1">
											<Link to={to} state={state}>
												{template.name}
											</Link>
										</Typography>
										<Box sx={{ color: 'grey.800', fontWeight: 'light' }}>
											{(!template.validityStartDate && !template.validityEndDate)
												? t('manage.company.function.templates.noValidityDates')
												: (
													<>
														{template.validityStartDate ? (
															validityDateFormat(template.validityStartDate)
														) : t('manage.company.function.templates.noValidityStartDate')}
														{' - '}
														{template.validityEndDate ? (
															validityDateFormat(template.validityEndDate)
														) : t('manage.company.function.templates.noValidityEndDate')}
													</>
												)}
										</Box>
										{template.description}
									</TableCell>
									<TableCell style={{ textAlign: 'right', paddingRight: 0 }}>
										<EditFunctionTemplateStatusChip template={template} />
									</TableCell>
									<TableCell style={{ width: 60, paddingLeft: 0 }}>
										<IconButton
											component={Link}
											to={to}
											state={state}
										>
											<Icon>open_in_new</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</PageContentBlock>
        </>
    );
}

CompanyFunctionContractTemplates.propTypes = {
	functionId: PropTypes.string.isRequired,
};

export default withFetchData(props => getContractTemplates(props.functionId), {
	customId: () => 'ContractTemplates',
	mapEntities: 'contractTemplates',
})(CompanyFunctionContractTemplates);
