import Box from '@mui/material/Box';
import React, { Component, Fragment } from 'react'
import { compose } from 'ramda'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import PageHeader from '../../page/PageHeader'
import PageHeaderNavigation from '../../page/PageHeaderNavigation'
import PageHeaderTitle from '../../page/PageHeaderTitle'
import withFetchData from '../../../modules/fetchData/withFetchData'
import withScopeAndSubScopes from '../../../modules/scope/withScopeAndSubScopes'
import { getScopeEvaluationQuestionTranslations } from '../../../actions/evaluationActions'
import FetchDataSwitch from '../../util/FetchDataSwitch'
import { questionOrder } from './evaluationUtils'
import CourseEvaluationsRadar from './CourseEvaluationsRadar'
import CourseEvaluationsDetails from './CourseEvaluationsDetails'
import CourseEvaluationDropDowns from './CourseEvaluationDropDowns'

class CourseEvaluationsPage extends Component {
	constructor(props) {
		super(props)

		if (props.group) {
			this.state.groupView = props.group.id
		}
	}

	state = {
		view: 0,
		groupView: 'all',
		teacherView: 'all',
	}

	handleData = data => questionOrder.map(question => data[question])

	handleTabChange = (e, view) => {
		this.setState({ view })
	}

	handleDropDownChange = e => {
		this.setState({ [e.target.name]: e.target.value })
	}

	render() {
		const { course, group } = this.props;

		return (
            <>
				<PageHeader>
					<PageHeaderNavigation
						breadcrumbs={[
							{ to: `/portal/planner/`, label: 'Planner' },
							{ to: `/portal/planner/course/${course.id}`, label: group ? course.abbreviation : course.name },
							group ? {
								to: `/portal/planner/course/${course.id}/group/${group.id}`,
								label: group.name,
							} : false,
							{ label: 'Evaluations' },
						].filter(Boolean)}
					/>
					<PageHeaderTitle
						headline="Evaluations"
						subHeading={group ? group.name : course.name}
					/>
				</PageHeader>
				{this.renderContent()}
			</>
        );
	}

	renderContent(){
		const { course, subScopes, loading, error, data, } = this.props;
		const { groupView, teacherView, view } = this.state;

		return (
			<Fragment>
				<Box sx={{ height: 48, backgroundColor: '#dedede' }}>
					<Grid container justifyContent={'space-between'}>
						<Grid item>
							<Tabs
								value={view}
								indicatorColor="primary"
								textColor="primary"
								onChange={this.handleTabChange}
							>
								<Tab label={'Overview'}/>
								<Tab label={'Details'}/>
							</Tabs>
						</Grid>
						<Grid item>
							<CourseEvaluationDropDowns
								course={course}
								groupView={groupView}
								teacherView={teacherView}
								handleDropDownChange={this.handleDropDownChange}
							/>
						</Grid>
					</Grid>
				</Box>

				<FetchDataSwitch {...{ loading, error, data: [data] }}>
					{data && view === 0 && (
						<CourseEvaluationsRadar
							scopeId={groupView === 'all' ? course.id : groupView}
							teacherId={teacherView}
							questions={this.handleData(data)}
						/>
					)}
					{data && view === 1 && (
						<CourseEvaluationsDetails
							scopeId={groupView === 'all' ? course.id : groupView}
							teacherId={teacherView}
							questions={this.handleData(data)}
						/>
					)}
				</FetchDataSwitch>
			</Fragment>
		)
	}
}

export default withScopeAndSubScopes(compose(
	withFetchData(getScopeEvaluationQuestionTranslations, {
		customId: () => 'CourseEvaluationsPage',
	}),
)(CourseEvaluationsPage), (props) => props.course.id);
