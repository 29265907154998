import { styled } from '@mui/material';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import loaderGold from './loader_gold.gif';

const PREFIX = 'Loader';
const classes = {
    root: `${PREFIX}-root`,
    space: `${PREFIX}-space`,
    sheet: `${PREFIX}-sheet`,
    small: `${PREFIX}-small`,
    inline: `${PREFIX}-inline`,
    image: `${PREFIX}-image`,
    text: `${PREFIX}-text`,
    backdrop: `${PREFIX}-backdrop`,
};

const Loader = styled(({ children, takeSpace, sheet, small, inline, className, ...props }) => (
    <div
        className={classnames(classes.root, {
            [classes.space]: takeSpace,
            [classes.sheet]: sheet,
            [classes.small]: small,
            [classes.inline]: inline,
        }, className)}
        {...props}
    >
        <div className={classes.image} />
        {children && (
            <div className={classes.text}>
                <span>{children}</span>
            </div>
        )}
        <div className={classes.backdrop} />
    </div>
))(({ theme }) => ({
    '@keyframes fadeSheet': {
        from: { opacity: 0 },
        to: { opacity: 0.5 },
    },
    [`&.${classes.root}`]: {
        margin: theme.spacing(0, 1, 4, 1),
        padding: theme.spacing(2),
        [`&.${classes.space}`]: {
            marginTop: 200,
            marginBottom: 300,
            [`& .${classes.image}`]: {
                backgroundSize: 'auto 150px',
                height: 150,
            },
        },
        [`&.${classes.sheet}`]: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            [`& .${classes.backdrop}`]: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: theme.palette.common.black,
                zIndex: 100,
                animation: 'fadeSheet 0.7s ease-in-out forwards',
            },
            [`& .${classes.image}`]: {
                marginTop: 100,
                position: 'relative',
                zIndex: 200,
            },
            [`& .${classes.text}`]: {
                marginTop: 30,
                position: 'relative',
                zIndex: 200,
                '& > span': {
                    display: 'inline-block',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: theme.spacing(1, 2),
                    borderRadius: theme.shape.borderRadius,
                },
            },
        },
        [`&.${classes.small}`]: {
            minHeight: 50,
            margin: 0,
            [`& .${classes.image}`]: {
                backgroundSize: 'auto 30px',
                height: 30,
            },
        },
        [`&.${classes.inline}`]: {
            display: 'inline-block',
            padding: 0,
            minHeight: 'unset',
            margin: theme.spacing(0, 2),
            height: '1em',
            overflow: 'visible',
            verticalAlign: 'top',
            [`& .${classes.image}`]: {
                display: 'inline-block',
                float: 'left',
                width: 30,
                height: 30,
                backgroundSize: 'auto 20px',
            },
            [`& .${classes.text}`]: {
                display: 'inline-block',
            },
        },
    },
    [`& .${classes.image}`]: {
        background: `url(${loaderGold}) no-repeat center`,
        backgroundSize: 'auto 70px',
        height: 70,
    },
    [`& .${classes.text}}`]: {
        textAlign: 'center',
        fontStyle: 'italic',
        fontSize: '0.95rem',
        color: theme.palette.text.secondary,
    },
}));

Loader.propTypes = {
    children: PropTypes.node,
    takeSpace: PropTypes.bool,
    sheet: PropTypes.bool,
    small: PropTypes.bool,
    inline: PropTypes.bool,
};

export default Loader;
