import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withDialogShouldBeFullscreen from '../../../hoc/withDialogShouldBeFullscreen'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'
import ClientOrderProductTable from './ClientOrderProductsTable'
import ClientOrderTransactions from './ClientOrderTransactions'
import ClientOrderRefundOptions from './ClientOrderRefundActions'

const ClientOrderModal = ({ handleClose, modal: { orderId }, orders, fullScreen }) => {
	const order = orders && orders.find(o => o.id === orderId);

	return (
		<Dialog
			open={true}
			onClose={handleClose}
			fullScreen={fullScreen}
		>
			<DialogTitle>
				<Grid container spacing={2}>
					<Grid item>
						<Typography variant={'h5'}>
							{order ? `OrderID - ${order.id}` : ''}
						</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				{order ? (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant={'h6'}>Products</Typography>
							<ClientOrderProductTable orderEntries={order.orderEntries} totalPrice={order.totalPrice}/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant={'h6'}>Transactions</Typography>
							<ClientOrderTransactions orderId={order.id}/>
						</Grid>
						{order.status === 'PAID' && (
							<Grid item xs={12}>
								<Divider style={{ margin: '10px 0 10px 0' }}/>
								<Typography variant={'h6'}>Actions with this order</Typography>
								<ClientOrderRefundOptions orderId={order.id} totalPrice={order.totalPrice}/>
							</Grid>
						)}
					</Grid>
				) : !orders ? (
					<Loader/>
				) : (
					<Alert>Order could not be found within the loaded data. Please report this problem.</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant={'outlined'} color={'secondary'} onClick={handleClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}

ClientOrderModal.propTypes = {
	orders: PropTypes.array,
}

export default withDialogShouldBeFullscreen()(ClientOrderModal)
