import { append, drop, head } from 'ramda'

function notificationReducerFinal(state = {
	message: null,
	queue: [],
	open: false
}, action){
	switch(action.type){
		case 'NOTIFICATION_SHOW':
			return {
				...state,
				message: state.open ? state.message : action.message,
				queue: state.open ? append(action.message, state.queue) : [],
				open: !state.open,
			};
		case 'NOTIFICATION_HIDE':
			return {
				...state,
				open: false,
			};
		case 'NOTIFICATION_QUEUE':
			const message = head(state.queue)
			return {
				...state,
				message: message,
				queue: drop(1, state.queue),
				open: !!message,
			};
	}

	return state;
}

const notificationReducer = {
	notification: notificationReducerFinal,
};

export default notificationReducer
