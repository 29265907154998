import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next';
import { sortScopesOnId } from '../../../helpers/scope'
import ScopeSelect from './ScopeSelect'

const ScopeSelectorTopLevel = ({ options, selected, onSelect, onClear }) => {
	const { t } = useTranslation();
	const handleChange = (value, { action }) => {
		switch(action){
			case 'select-option':
				onSelect(value.id);
				break;
			case 'pop-value':
			case 'clear':
				onClear();
				break;
		}
	}

	return (
		<Grid item xl={2} md xs={12}>
			<ScopeSelect options={sortScopesOnId(options)}
						 onChange={handleChange}
						 value={selected}
						 placeholder={t('scope.selectCompany')}
						 autoFocus />
		</Grid>
	)
}

ScopeSelectorTopLevel.propTypes = {
	options: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired,
	onClear: PropTypes.func.isRequired,
	selected: PropTypes.object,
}

export default memo(ScopeSelectorTopLevel)
