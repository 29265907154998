import moment from 'moment'

export const filterRangeFormat = 'YYYY-MM-DD';
export const dateIsInRange = (date, range) => {
	date = moment(date);

	if(range.start){
		if(date.isBefore(range.start)) return false;
	}

	if(range.end){
		if(date.isAfter(range.end)) return false;
	}

	return true;
}
