import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm';
import { doLogin } from '../../modules/auth/authActions';
import Loader from '../util/loader/Loader';
import Alert from '../util/alert/Alert';

const PREFIX = 'LoginForm';

const classes = {
    root: `${PREFIX}-root`,
    button: `${PREFIX}-button`,
    links: `${PREFIX}-links`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		margin: theme.spacing(4, 0),
		background: '#fefefe',
		border: '1px solid #f6f6f6',
		boxShadow: theme.shadows[2],
		padding: theme.spacing(3),

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
			marginTop: theme.spacing(2),
		},

		'& > h1': {
			color: theme.palette.primary.main,
			textAlign: 'center',
			marginBottom: theme.spacing(2),
		},
	},

    [`& .${classes.button}`]: {
		marginTop: theme.spacing(2),
	},

    [`& .${classes.links}`]: {
		marginTop: theme.spacing(2),
		paddingBottom: 0,

		'& a, a:link, a:visited': {
			color: theme.palette.primary.dark,
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	}
}));

const LoginForm = ({ fields, onChangeField, handleSubmit, saving, error, validation, validationBlur, validationAll }) => {

	const { t } = useTranslation();

	return (
        <Root className={classes.root}>
			<h1>Athena Portal</h1>

			{ saving && <Loader /> }
			{
				error
				&& (
					<Alert type={Alert.TYPE_WARNING}>
						ERROR
						{' '}
						{error.error}
					</Alert>
				)
			}

			<form onSubmit={handleSubmit(() => fields, doLogin, validationAll)}>

				<TextField
					name="username"
					onChange={onChangeField}
					label={t('login.username')}
					error={!!validation.username}
					helperText={validation.username}
					onBlur={validationBlur}
					fullWidth
				/>

				<TextField
					name="password"
					type="password"
					onChange={onChangeField}
					label={t('login.password')}
					margin="normal"
					error={!!validation.password}
					helperText={validation.password}
					onBlur={validationBlur}
					fullWidth
				/>

				<div className={classes.button}>
					<Button
						variant="contained"
						type="submit"
						color="primary"
						disabled={saving}
						fullWidth
					>
						{t('login.login')}
					</Button>
				</div>

				<div className={classes.links}>
					<Link to="/lostpassword">{t('login.forgotPassword')}</Link>
				</div>
			</form>
		</Root>
    );
};

LoginForm.propTypes = {
	fields: PropTypes.object,
	onChangeField: PropTypes.func,
	handleSubmit: PropTypes.func,
	validation: PropTypes.object,
	validationBlur: PropTypes.func,
	validationAll: PropTypes.func,
};

export default withCompleteBasicForm(LoginForm, {
	username: Joi.string().trim().email({
		minDomainAtoms: 2,
	}).required(),
	password: Joi.string().min(6).required(),
});
