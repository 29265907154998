import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { clone, equals } from 'ramda';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { composePatchScope, editScopeTypes } from '../../../../actions/scopeEditActions';
import { languages } from '../../../../helpers/localization';
import withFormData from '../../../../modules/formData/withFormData';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';

const PREFIX = 'CompanyFAQSectionTitle';

const classes = {
    noTrans: `${PREFIX}-noTrans`
};

const StyledTypography = styled(Typography)(({ theme }) => ({
    [`& .${classes.noTrans}`]: {
		color: theme.palette.grey[500],
	}
}));

function CompanyFAQSectionTitle({
	section, sectionIndex, isNewSection, topLevelScope,
	watchSubmit, saving, error, collapsed, handleCollapse, providedDrag,
}) {
	const [editMode, setEditMode] = useState(isNewSection);
	const [title, setTitle] = useState(typeof section.title === 'string' ? { nl: section.title } : section.title);
	const { t } = useTranslation();


	const handleClose = () => {
		setEditMode(false);
	};

	const handleEditClick = (event) => {
		event.stopPropagation();
		setEditMode(!editMode);
	};

	const handleTitleChange = (lang) => (event) => {
		setTitle({
			...title,
			[lang]: event.target.value,
		});
	};

	const handleTitleSave = (event) => {
		event.stopPropagation();
		const properties = clone(topLevelScope.properties);
		const { faq = [] } = properties;

		if (faq[sectionIndex]) {
			faq[sectionIndex].title = title;
		} else {
			faq.push({
				items: [],
				title,
			});
			properties.faq = faq;
		}

		watchSubmit(composePatchScope(editScopeTypes.COMPANY)(topLevelScope.id, {
			properties,
		}));
	};

	const handleTitleDiscard = (event) => {
		event.stopPropagation();
		setTitle(section.title);
	};

	const isDirty = !equals(title, section.title);

	return (
        <>
            <Dialog open={editMode} onClose={handleClose} fullWidth maxWidth="md">
				<DialogTitle>{t('manage.company.faq.editTitle')}</DialogTitle>
				<DialogContent>
					{Object.keys(languages).map(lang => (
						<TextField
							variant="outlined"
							fullWidth
							onClick={(event) => event.stopPropagation()}
							onChange={handleTitleChange(lang)}
							value={title?.[lang] || ''}
							placeholder={t('manage.company.faq.placeholder')}
							disabled={saving}
							label={languages[lang]}
							InputProps={error ? ({
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip placement="top" title={t('errors.dataStoreError')}>
											<Icon color="secondary">error</Icon>
										</Tooltip>
									</InputAdornment>
								),
							}) : null}
							style={{ marginBottom: '2rem' }}
							key={lang}
						/>
					))}
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						startIcon={saving
							? <CircularProgress size={16} />
							: <Icon>save</Icon>}
						color="primary"
						disabled={!isDirty || title.length === 0}
						onClick={handleTitleSave}
					>
						{t('general.save')}
					</Button>
					<Button
						variant="contained"
						startIcon={<Icon>history</Icon>}
						color="primary"
						disabled={!isDirty || saving}
						onClick={handleTitleDiscard}
					>
						{t('general.revert')}
					</Button>
					<Button variant="contained" color="default" onClick={handleClose}>
						{t('general.close')}
					</Button>
				</DialogActions>
			</Dialog>
            <TableCell
				style={{ color: '#ccc', width: 70 }}
				{...providedDrag.dragHandleProps}
			>
				<Icon>drag_indicator</Icon>
			</TableCell>
            <TableCell style={{ width: 70 }} />
            {typeof section.title === 'string' && (
				<TableCell colSpan={Object.keys(languages).length}>
					<StyledTypography>
						<strong>{section.title}</strong>
						<i className={classes.noTrans}>{` (${t('manage.company.faq.oneTextForAllLanguages')})`}</i>
					</StyledTypography>
				</TableCell>
			)}
            {typeof section.title === 'object' && Object.keys(languages).map(lang => (
				<TableCell key={lang}>
					{section.title?.[lang]
						? <Typography><strong>{section.title?.[lang]}</strong></Typography>
						: <StyledTypography><i className={classes.noTrans}>{t('manage.company.faq.noTranslation')}</i></StyledTypography>}
				</TableCell>
			))}
            <TableCell style={{ width: 120, textAlign: 'right' }}>
				<IconButton onClick={handleCollapse}>
					<Icon>{collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
				</IconButton>
				<IconButton onClick={handleEditClick} size="small">
					<Icon>edit</Icon>
				</IconButton>
			</TableCell>
        </>
    );
}

CompanyFAQSectionTitle.propTypes = {
	section: PropTypes.object.isRequired,
	sectionIndex: PropTypes.number.isRequired,
	isNewSection: PropTypes.bool.isRequired,
	providedDrag: PropTypes.object,
	collapsed: PropTypes.bool.isRequired,
	handleCollapse: PropTypes.func,
};

export default withWorkingScope(withFormData()(CompanyFAQSectionTitle));
