import { getApiTypeSuccess } from '../../helpers/types'
import { readCookie } from '../../helpers/cookie'
import { AUTH_LOGOUT, AUTH, AUTH_ME } from './authActions'

// Hardcoded here to avoid circular dependency with API
// This is only temporary anyways till authentication-store
// does not keep token information anymore
const token = readCookie('ATHENAPORTAL-AUTH');
const defaultState = token ? {
	tokenValue: token,
	user: undefined
} : {};

export function authReducerFinal(state = defaultState, action){
	if(action.type === AUTH_LOGOUT){
		return {};
	}

	if(action.type !== getApiTypeSuccess(AUTH) && action.type !== getApiTypeSuccess(AUTH_ME)){
		return state;
	}

	if(action.type === getApiTypeSuccess(AUTH_ME)){
		return {
			...state,
			user: action.response.result
		};
	}

	if(action.response && action.response.result && action.response.result.tokenValue){
		return {
			...state,
			...action.response.result
		};
	}

	return state;
}

const authReducer = {
	auth: authReducerFinal,
};

export default authReducer
