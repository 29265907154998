import { CALL_API, Schemas } from './schemas'
import { getApiTypeSuccess } from '../helpers/types'
import { fetchDataConcat } from '../modules/fetchData/fetchDataActions'
import { notificationShow } from '../modules/notification/notificationActions'
import { listsActionComposer } from '../modules/lists/listsHelpers'

export const DISCOUNTCODE_LIST = 'DISCOUNTCODE_LIST'
export const DISCOUNTCODE_INVALIDATE = 'DISCOUNTCODE_INVALIDATE'
export const DISCOUNTCODE_CREATE = 'DISCOUNTCODE_CREATE'
export const CUSTOM_DISCOUNTCODE_CREATE = 'CUSTOM_DISCOUNTCODE_CREATE'

export const getDiscountCodes = (scopeId) => ({
	[CALL_API]: {
		type: DISCOUNTCODE_LIST,
		endpoint: `discountcode/scope/${scopeId}`,
		schema: [Schemas.DISCOUNTCODE],
	},
})

export const LISTS_DISCOUNT_CODE_NEW = 'discountCodesNew';
export const createDiscountCodes = (scopeId, data) => dispatch => {
	return dispatch(listsActionComposer({
		[CALL_API]: {
			type: DISCOUNTCODE_CREATE,
			endpoint: `discountcode/scope/${scopeId}`,
			method: 'POST',
			body: data,
			schema: [Schemas.DISCOUNTCODE],
		},
	}, LISTS_DISCOUNT_CODE_NEW)).then(res => {
		if (res.type === getApiTypeSuccess(DISCOUNTCODE_CREATE)) {
			dispatch(fetchDataConcat('discountCodes', res.response.result))
			dispatch(notificationShow('Discount code(s) has been created', 'success'))
		}
		return res
	})
}

export const createCustomDiscountCodes = (scopeId, data) => dispatch => {
	return dispatch({
		[CALL_API]: {
			type: CUSTOM_DISCOUNTCODE_CREATE,
			endpoint: `custom-discountcode/scope/${scopeId}`,
			method: 'POST',
			body: data,
			schema: [Schemas.DISCOUNTCODE],
		},
	}).then(res => {
		if (res.type === getApiTypeSuccess(CUSTOM_DISCOUNTCODE_CREATE)) {
			dispatch(fetchDataConcat('discountCodes', res.response.result))
			dispatch(notificationShow('Discount code(s) has been created', 'success'))
		}
		return res
	})
}


export const invalidateDiscountCode = discountCode => dispatch => {
	return dispatch({
		[CALL_API]: {
			type: DISCOUNTCODE_INVALIDATE,
			endpoint: `discountcode/${discountCode.id}/invalidate`,
			method: 'POST',
			schema: Schemas.DISCOUNTCODE,
		},
	}).then(res => {
		if (res.type === getApiTypeSuccess(DISCOUNTCODE_INVALIDATE)) {
			dispatch(notificationShow(`You invalidated discount code: ${discountCode.code}`, 'success'))
		}
		return res
	})
}
