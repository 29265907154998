import React from 'react'
import PropTypes from 'prop-types'
import { Schemas } from '../../../actions/schemas'
import withFormData from '../../../modules/formData/withFormData'
import { CALL_API } from '../../../setup/api'
import ApiError from '../../util/ApiError'
import HesitationButton from '../../util/HesitationButton'
import Loader from '../../util/loader/Loader'

const ApiAction = (timeSlotId) => ({
	[CALL_API]: {
		type: 'ZOOM',
		endpoint: `course-timeslot/${timeSlotId}/zoom-us-meeting`,
		schema: Schemas.TIME_SLOT,
		method: 'DELETE',
	},
});

const ZoomMeetingDelete = ({ timeSlot, joinUrl, saving, error, success, watchSubmit }) => {
	const deleteMeeting = () => {
		watchSubmit(ApiAction(timeSlot.id));
	};

	return (
        <div>
			{saving && (
				<Loader />
			)}
			<ApiError error={error} />
			<HesitationButton
				variant="outlined"
				color="secondary"
				onClick={deleteMeeting}
				disabled={saving}
				icon="delete"
				label="Delete online class"
			/>
		</div>
    );
};

ZoomMeetingDelete.propTypes = {
	timeSlot: PropTypes.object.isRequired,
};

export default withFormData()(ZoomMeetingDelete)
