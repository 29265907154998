import { styled } from '@mui/material/styles';
import classnames from 'classnames'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'ramda'
import { Link, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import { getKey, Schemas } from '../../actions/schemas'
import withFetchData from '../../modules/fetchData/withFetchData'
import withFormData from '../../modules/formData/withFormData'
import { CALL_API } from '../../setup/api'
import { formatPercentage } from '../../helpers/formatter'
import { niceDateYear } from '../../helpers/datetime'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import ResponsiveTable from '../util/ResponsiveTable'
import ModalManager from '../modal/ModalManager'

const PREFIX = 'BundleList';

const classes = {
    noWrap: `${PREFIX}-noWrap`,
    expired: `${PREFIX}-expired`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.noWrap}`]: {
		whiteSpace: 'nowrap',
	},

    [`& .${classes.expired}`]: {
		color: theme.palette.text.disabled,
	}
}));

const ApiAction = (scopeId) => ({
	[CALL_API]: {
		type: 'BUNDLE_LIST',
		endpoint: `scope/${scopeId}/athenastudies-course-bundle`,
		schema: [Schemas.COURSE_BUNDLE],
	},
});

const ApiActionInvalidate = (bundleId) => ({
	[CALL_API]: {
		type: 'BUNDLE_INVALIDATE',
		endpoint: `athenastudies-course-bundle/${bundleId}/invalidate`,
		schema: Schemas.COURSE_BUNDLE,
		body: {},
	},
});

const isActive = expires =>
	moment(expires).subtract(5, 'minutes').isAfter(moment());

const BundleList = ({ scope, loading, fetchError, data, saving, formError, load, watchSubmit }) => {
	const navigate = useNavigate();

	useEffect(() => {
		load(ApiAction(scope.id));
	}, [scope.id]);

	if(fetchError) return (
		<ApiError error={fetchError} />
	);

	if(loading || !data) return (
		<Loader />
	);

	if(data.length === 0) return (
		<Alert>There are no product bundles (active) within this scope and its sub scopes.</Alert>
	);

	const getClickState = (id) => ({
		modal: 'view',
		id,
	});

	const bindInvalidate = (bundleId) => (e) => {
		e.stopPropagation();
		watchSubmit(ApiActionInvalidate(bundleId));
	};

	return (
        <StyledPaper style={{ position: 'relative' }}>
			{saving && (
				<Loader sheet />
			)}
			{formError && (
				<ApiError error={formError} />
			)}
			<ResponsiveTable>
				<TableHead>
					<TableRow>
						<TableCell>Description</TableCell>
						<TableCell>Discount</TableCell>
						<TableCell>Expires</TableCell>
						<TableCell>Courses</TableCell>
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
				{data.map(bundle => {
					const expired = !isActive(bundle.expirationDate);

					return (
						<TableRow
							key={bundle.id}
							hover
							onClick={() => navigate('/portal/promotion/bundle', { state: getClickState(bundle.id) })}
						>
							<TableCell>
								<Link to="/portal/promotion/bundle" state={getClickState(bundle.id)} onClick={(e) => e.stopPropagation()}>
									{bundle.description}
								</Link>
							</TableCell>
							<TableCell className={expired ? classes.expired : ''}>
								{formatPercentage(bundle.discountPercentage)}
							</TableCell>
							<TableCell className={classnames(classes.noWrap, {
								[classes.expired]: expired,
							})}>
								{niceDateYear(bundle.expirationDate)}
							</TableCell>
							<TableCell className={expired ? classes.expired : ''}>
								{bundle.applicableInCourses.map(course => course.name).join(', ')}
							</TableCell>
							<TableCell align="right" padding="checkbox">
								{!expired && (
									<Tooltip title="Invalidate/disable bundle" placement="top">
										<IconButton
											onClick={bindInvalidate(bundle.id)}
											color="secondary"
										>
											<Icon>cancel</Icon>
										</IconButton>
									</Tooltip>
									)}
							</TableCell>
						</TableRow>
					)
				})}
				</TableBody>
			</ResponsiveTable>
		</StyledPaper>
    );
}

BundleList.propTypes = {
	scope: PropTypes.object.isRequired,
};

export default compose(
	withFetchData(undefined, {
		customId: () => 'BundleList',
		mapEntities: getKey(Schemas.COURSE_BUNDLE),
	}),
	withFormData(),
)(BundleList)
