import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import withDarkTheme from '../../hoc/withDarkTheme'
import ScopeSelector from '../scope/selector/ScopeSelector'

const PREFIX = 'HeaderDrawerScopes';

const classes = {
    scopeHeader: `${PREFIX}-scopeHeader`,
    drawerPaper: `${PREFIX}-drawerPaper`
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${classes.scopeHeader}`]: {
		...theme.mixins.toolbar,
		textAlign: 'center',
		color: theme.palette.text.primary,
		padding: theme.spacing(2),
	},

    [`& .${classes.drawerPaper}`]: {
		width: theme.drawerWidth,
	}
}));

const HeaderDrawerScopes = ({ open, onClose, }) => {
    return (
        <StyledDrawer variant="temporary"
				anchor="right"
				open={open}
				onClose={onClose}
				classes={{
					paper: classes.drawerPaper,
				}}
				ModalProps={{
					keepMounted: true,
				}}
			>
			<div className={classes.scopeHeader}>
				ACTIVE SCOPES
			</div>
			<div className="scope-selector-drawer">
				<ScopeSelector />
			</div>
		</StyledDrawer>
    );
};

HeaderDrawerScopes.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default withDarkTheme((HeaderDrawerScopes))
