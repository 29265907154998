import { capitalize } from '@mui/material/utils';
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import { useLocation } from 'react-router-dom';
import { niceFullDateYear } from '../../helpers/datetime'
import PageHeader from '../page/PageHeader'
import PageHeaderMenu from '../page/PageHeaderMenu'
import PageHeaderTitle from '../page/PageHeaderTitle'
import PageHeaderInfo from '../page/PageHeaderInfo'
import PageContentBlock from '../page/PageContentBlock'
import PropertiesTable from '../util/PropertiesTable'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import ProfileEditDetailsModal from './ProfileEditDetailsModal'
import ProfileEditEmailModal from './ProfileEditEmailModal'
import ProfileEditAddressModal from './ProfileEditAddressModal'
import ProfileEditBankModal from './ProfileEditBankModal'

const ProfileIndexPage = ({ user }) => {
	const { t } = useTranslation();
	const location = useLocation();
	return (
		<Fragment>
			<ModalManager>
				<ModalItem state="editDetails" component={ProfileEditDetailsModal} user={user} />
				<ModalItem state="editEmail" component={ProfileEditEmailModal} user={user} />
				<ModalItem state="editAddress" component={ProfileEditAddressModal} user={user} />
				<ModalItem state="editBank" component={ProfileEditBankModal} user={user} />
			</ModalManager>
			<PageHeader>
				<PageHeaderMenu items={[
					{ name: t('dashboard.headerInfo.editDetails'), to: location.pathname, state: { modal: 'editDetails' }, icon: 'person' },
					{ name: t('dashboard.headerInfo.editEmail'), to: location.pathname, state: { modal: 'editEmail' }, icon: 'mail' },
				]} />
				<PageHeaderTitle headline={t('dashboard.headerInfo.yourPersonalDetails')}
								 subHeading={t('dashboard.headerInfo.description')} />
				<PageHeaderInfo items={[
					{ label: t('general.name'), content: `(${user.initials}) ${user.fullName}` },
					{ label: t('employee.fields.mobilePhone'), content: user.mobilePhone },
					{ label: t('employee.fields.email'), content: user.email },
					{ label: t('employee.fields.birthday'), content: niceFullDateYear(user.birthday)},
				]} />
			</PageHeader>
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<PageContentBlock
						title={capitalize(t('general.address'))}
						links={[{
							label: t('general.editAddress'),
							to: location.pathname,
							state: { modal: 'editAddress' },
							icon: 'location_on',
							basic: true,
						}]}
					>
						<PageContentBlock.Content contentPadding>
							<PropertiesTable items={[
								{ label: t('employee.fields.street'), content: `${user.street} ${user.houseNumber}` },
								{ label: t('employee.fields.zipcode'), content: user.zipcode },
								{ label: t('employee.fields.city'), content: `${user.city}, ${user.country}` },
							]} />
						</PageContentBlock.Content>
					</PageContentBlock>
				</Grid>
				<Grid item sm={6} xs={12}>
					<PageContentBlock title={t('profile.bankAndId')}
									  links={[
										  { label: t('general.edit'), to: location.pathname, state: { modal: 'editBank' }, icon: 'payment', basic: true }
									  ]}>
						<PageContentBlock.Content contentPadding>
							<PropertiesTable items={[
								{ label: t('employee.fields.IBAN'), content: `${user.IBAN} (${user.BIC})` },
								{ label: t('employee.fields.bankPayee'), content: user.bankPayee },
								{ label: t('employee.fields.identificationNumber'), content: user.identificationNumber },
							]} />
						</PageContentBlock.Content>
					</PageContentBlock>
				</Grid>
			</Grid>
		</Fragment>
	);
}

export default connect(state => ({ user: state.auth.user }))(ProfileIndexPage);
