import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../../util/alert/Alert';
import { notificationShow } from '../../../modules/notification/notificationActions';
import { getApiTypeSuccess } from '../../../helpers/types';
import { postCourseSkuReferences } from '../../../actions/skuReferencesActions';
import { composePatchScope, editScopeTypes } from '../../../actions/scopeEditActions';
import ApiError from '../../util/ApiError';
import CourseSkuReferencesSelector from './CourseSkuReferencesSelector';
import StringListEditor from '../../util/StringListEditor';
import CourseSkuReferencesTable from './CourseSkuReferencesTable';

const courseSkuReferencesSelector = ({ fetchData }) => fetchData.CourseSkuReferences?.success;

function CourseSkuReferencesModal({ course, isSkuEnabled, handleClose }) {
    const { t } = useTranslation();
    const [deprecatedBookletReferenceCodes, setDeprecatedBookletReferenceCodes] = useState(course.bookletReferenceCodes || []);
    const [bookletReferenceCodes, setBookletReferenceCodes] = useState([]);
    const [apiError, setApiError] = useState();
    const [tab, setTab] = useState(isSkuEnabled ? 0 : 1);
    const dispatch = useDispatch();
    const savedBookletReferences = useSelector(courseSkuReferencesSelector);
    const isDirty = !equals(savedBookletReferences, bookletReferenceCodes);
    const isRefsDirty = !equals(deprecatedBookletReferenceCodes, course.bookletReferenceCodes);

    const onRemove = (index) => {
        const newRefs = [...bookletReferenceCodes];
        newRefs.splice(index, 1);
        setBookletReferenceCodes(newRefs);
    };

    useEffect(() => {
        if (savedBookletReferences) {
            setBookletReferenceCodes(savedBookletReferences);
        }
    }, [Array.isArray(savedBookletReferences)]); // eslint-disable-line

    const handleSkuReferencesSave = () => {
        dispatch(postCourseSkuReferences(course.id, bookletReferenceCodes)).then(res => {
            if (res && res.errorCode && res.errorCode !== 401) {
                setApiError(res);
            } else {
                dispatch(notificationShow(t('properties.edit.saveSuccess'), 'success'));
                handleClose();
            }
        });
    };

    const handleBookletRefsSave = () => {
        dispatch(
            composePatchScope(editScopeTypes.ATHENA_STUDIES_COURSE)(course.id, { bookletReferenceCodes: deprecatedBookletReferenceCodes }),
        ).then(res => {
            if (res && res.errorCode && res.errorCode !== 401) {
                setApiError(res);
            }
            if (res.type === getApiTypeSuccess('SCOPE_EDIT')) {
                handleClose();
            }
        });
    };

    return (
        <Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth>
            <DialogTitle>{t('scope.course.sku.dialog.title', { course: course.courseName })}</DialogTitle>
            <DialogContent>
                <ApiError error={apiError} />
                <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            <em>{t('scope.edit.physicalBookletRefDesc')}</em>
                        </Typography>
                    </Grid>

                    <Grid xs={12} item>
                        <Tabs value={tab} onChange={(event, value) => setTab(value)}>
                            <Tab
                                label={(
                                    <span>
                                        {t('scope.course.sku.title')}
                                        <Chip
                                            color={isSkuEnabled ? 'success' : 'default'}
                                            label={t('general.new')}
                                            sx={{ ml: 1, color: !isSkuEnabled ? 'grey.500' : null }}
                                        />
                                    </span>
                                )}
                                disabled={!isSkuEnabled}
                            />
                            <Tab label={(
                                <span>
                                    {t('scope.edit.physicalBookletRef')}
                                    {isSkuEnabled && (<Chip label={t('general.deprecated')} color="warning" sx={{ ml: 1 }} />)}
                                </span>
                            )}
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} sx={tab === 0 ? { display: 'block' } : { display: 'none' }}>
                        <CourseSkuReferencesTable values={bookletReferenceCodes} onRemove={onRemove} />
                        <CourseSkuReferencesSelector
                            onChange={setBookletReferenceCodes}
                            list={bookletReferenceCodes}
                        />
                    </Grid>
                    <Grid item xs={12} sx={tab === 1 ? { display: 'block' } : { display: 'none' }}>
                        {isSkuEnabled && (
                            <Box sx={{ mt: 2 }}>
                                <Alert type={Alert.TYPE_WARNING}>
                                    <em>{t('scope.edit.physicalBookletRefDescDep')}</em>
                                </Alert>
                            </Box>
                        )}
                        <StringListEditor
                            name="bookletReferenceCodes"
                            list={deprecatedBookletReferenceCodes}
                            onChange={setDeprecatedBookletReferenceCodes}
                            itemName="booklet"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {t('general.close')}
                </Button>
                {
                    tab === 0 ? (
                        <Button
                            onClick={handleSkuReferencesSave}
                            variant="contained"
                            color="primary"
                            disabled={!isDirty}
                        >
                            {t('general.save')}
                        </Button>
                    ) : (
                        <Button
                            onClick={handleBookletRefsSave}
                            variant="contained"
                            color="primary"
                            disabled={!isRefsDirty}
                        >
                            {t('general.save')}
                        </Button>
                    )
                }

            </DialogActions>
        </Dialog>
    );
}

CourseSkuReferencesModal.propTypes = {
    course: PropTypes.shape({
        bookletReferenceCodes: PropTypes.array,
        id: PropTypes.number,
        courseName: PropTypes.string,
    }),
    isSkuEnabled: PropTypes.bool,
};

export default CourseSkuReferencesModal;
