/* eslint-disable no-nested-ternary */
import { compose } from 'ramda';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import withFormData from '../../modules/formData/withFormData';
import withFetchData from '../../modules/fetchData/withFetchData';
import { Schemas, getKey } from '../../actions/schemas';
import { orderApplyDiscountCode } from '../../actions/orderActions';
import { getDiscountCodes } from '../../actions/discountCodeActions';
import { formatMoney, formatPercentage } from '../../helpers/formatter';
import { isActive } from '../discountCodes/utils';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';

class OrderOptionsApplyDiscount extends Component {
	state = {
		selectedDiscountCodeId: '',
	}

	render() {
		const { saving, formError, loading, fetchError, success, data: discountCodes, orderStatus } = this.props;
		const { selectedDiscountCodeId } = this.state;

		if (orderStatus !== 'PAID') return (
			<p><em>This order is already free or fully discounted. Cannot <strong>apply a discount code</strong>.</em></p>
		);

		const finalDiscountCodes = (discountCodes || [])
			.filter(code => code.timesApplicable - code.numberOfApplications > 0)
			.filter(code => isActive(code.expires));

		return (
			<div>
				<p><strong>Apply a discount code</strong> to this order. First make a discount code on this COURSE in order to be able to select it. If there is already a code active on the order, the most profitable for the client will be selected. The client will be automatically be paid be and not be notified. This is NOT a marketing tool.</p>
				{saving && <Loader />}
				{formError && (
					<Alert type={Alert.TYPE_WARNING}>
						{formError.error}
					</Alert>
				)}
				{fetchError && (
					<Alert type={Alert.TYPE_WARNING}>
						Error with loading the list of discount codes. {fetchError.error}
					</Alert>
				)}
				{success ? (
					<Alert type={Alert.TYPE_SUCCESS}>
						Action was successful, it might take a few minutes and a page refresh for the changes to be visible.
						<br />
						<br />
						{success.refund
							? `The client will receive the refund in about 2 business days. Total refunded: ${formatMoney(success.refund.totalAmount)}.`
							: 'A refund was not needed.'}
					</Alert>
				) : ((loading || !discountCodes) ? (
					<Loader>
						Loading discount codes...
					</Loader>
				) : ((finalDiscountCodes.length === 0) ? (
					<Alert type={Alert.TYPE_INFO}>
						No usable discount codes found.
					</Alert>
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{!saving && (
								<FormControl fullWidth>
									<Select
										onChange={this.handleDiscountCodeChange}
										value={selectedDiscountCodeId}
										displayEmpty
										inputProps={{
											name: 'discountCodes',
										}}
									>
										<MenuItem value="" disabled>
											<em>Select a discount code</em>
										</MenuItem>
										{finalDiscountCodes.map((code) => (
											<MenuItem
												key={code.id}
												value={code.id}
											>
												{`${code.code} ${formatPercentage(code.discountPercentage)} (${code.numberOfApplications}/${code.timesApplicable})`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								disabled={saving}
								onClick={this.handleApplyDiscount}
							>
								Apply discount code
							</Button>
						</Grid>
					</Grid>
				)))}
			</div>
		);
	}

	handleDiscountCodeChange = (e) => {
		this.setState({
			selectedDiscountCodeId: e.target.value,
		});
	}

	handleApplyDiscount = () => {
		const { orderEntryId, watchSubmit } = this.props;
		const { selectedDiscountCodeId } = this.state;

		if (!selectedDiscountCodeId || selectedDiscountCodeId === '') return;
		if (!confirm('Are you sure you want to apply this discount code? The client will automatically be paid back a part of the price. This cannot be undone!')) return;

		watchSubmit(orderApplyDiscountCode({
			orderEntryId: orderEntryId,
			discountCodeId: selectedDiscountCodeId,
		}));
	}
}

OrderOptionsApplyDiscount.propTypes = {
	courseId: PropTypes.number.isRequired,
	orderEntryId: PropTypes.number.isRequired,
	orderStatus: PropTypes.string.isRequired,
};

export default compose(
	withFormData({
		customId: () => 'orderOptionsApplyDiscount',
	}),
	withFetchData((props) => getDiscountCodes(props.courseId), {
		mapEntities: getKey(Schemas.DISCOUNTCODE),
	}),
)(OrderOptionsApplyDiscount);
