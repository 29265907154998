import React from 'react'
import PropTypes from 'prop-types'
import { mapObjIndexed, values } from 'ramda'
import Alert from './alert/Alert'

const ApiError = ({ error, showDetails, hideErrorOnDetails, ...rest }) => {
	if(!error) return null;

	return (
		<Alert type={Alert.TYPE_WARNING} {...rest}>
			{Boolean(!showDetails || !error.details || !hideErrorOnDetails) && error.message}
			{Boolean(showDetails && error.details) && values(mapObjIndexed((v, key) => (
				<div key={key}>{key}: {v}</div>
			), error.details))}
		</Alert>
	);
};

ApiError.propTypes = {
	error: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object,
	]),
	showDetails: PropTypes.bool,
	hideErrorOnDetails: PropTypes.bool,
};

ApiError.defaultProps = {
	error: null,
	showDetails: false,
	hideErrorOnDetails: false,
};

export default ApiError
