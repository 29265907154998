import { mapObjIndexed, pickBy } from 'ramda';
import { Schemas, CALL_API } from './schemas';
import { getApiTypeSuccess } from '../helpers/types';
import { notificationShow } from '../modules/notification/notificationActions';

const SCOPE_EDIT = 'SCOPE_EDIT';
export const SCOPE_ADD = 'SCOPE_ADD';
export const SCOPE_DELETE = 'SCOPE_DELETE';

export const editScopeTypes = {
	COMPANY: 'company',
	INSTITUTE: 'institute',
	FACULTY: 'faculty',
	COURSE: 'course',
	ATHENA_STUDIES_COURSE: 'athenastudies-course',
	ATHENA_STUDIES_COURSE_GROUP: 'athenastudies-course-group',
};

export const classEditScopeTypes = {
	Institute: editScopeTypes.INSTITUTE,
	Faculty: editScopeTypes.FACULTY,
	Course: editScopeTypes.COURSE,
	AthenaStudiesCourse: editScopeTypes.ATHENA_STUDIES_COURSE,
	AthenaStudiesCourseGroup: editScopeTypes.ATHENA_STUDIES_COURSE_GROUP,
};

const typeToTextMap = {
	[editScopeTypes.COMPANY]: 'Company',
	[editScopeTypes.INSTITUTE]: 'Institute',
	[editScopeTypes.FACULTY]: 'Faculty',
	[editScopeTypes.COURSE]: 'Study',
	[editScopeTypes.ATHENA_STUDIES_COURSE]: 'Course',
	[editScopeTypes.ATHENA_STUDIES_COURSE_GROUP]: 'Group',
};

export const composeEditScope = (type) => (id, data) => (dispatch) => dispatch({
	[CALL_API]: {
		type: SCOPE_EDIT,
		endpoint: `${type}/${id}`,
		schema: Schemas.SCOPE,
		body: data,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(SCOPE_EDIT)) {
		dispatch(notificationShow(`Saved ${typeToTextMap[type] || 'scope'} edits`, 'success'));
	}

	return res;
});

export const composePatchScope = (type) => (id, data) => (dispatch) => dispatch({
	[CALL_API]: {
		type: SCOPE_EDIT,
		endpoint: `${type}/${id}`,
		schema: Schemas.SCOPE,
		body: data,
		method: 'PATCH',
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(SCOPE_EDIT)) {
		dispatch(notificationShow(`Successfully updated ${typeToTextMap[type] || 'scope'}`, 'success'));
	}

	return res;
});

export const composeAddScope = (type) => (parentId, data) => (dispatch) => dispatch({
	[CALL_API]: {
		type: SCOPE_ADD,
		endpoint: `${type === editScopeTypes.ATHENA_STUDIES_COURSE_GROUP ? 'v2/' : ''}scope/${parentId}/${type}`,
		schema: Schemas.SCOPE,
		body: data,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(SCOPE_ADD)) {
		dispatch(notificationShow(`Added new ${typeToTextMap[type] || 'scope'}`, 'success'));
	}

	return res;
});

export const deleteScope = (scopeId, type) => (dispatch, getState) => dispatch({
	[CALL_API]: {
		type: SCOPE_DELETE,
		endpoint: `scope/${scopeId}`,
		schema: Schemas.SCOPE,
		method: 'DELETE',
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(SCOPE_DELETE)) {
		dispatch(notificationShow(`Deleted a ${typeToTextMap[type] || 'scope'}`, 'error'));

		// Get all scopes which have this scope as a sub scope
		const scopesWithScopeAsSub = pickBy((val) => val && val.subScopes && val.subScopes.find(id => id === scopeId), getState().entities.scopes);

		dispatch({
			type: 'SCOPE_DELETE_ENTITIES',
			response: { entities: { scopes: {
				// Remove the deleted scope
				[scopeId]: null,
				// Remove deleted scope from all mentions in sub scopes
				...mapObjIndexed((val) => ({
					...val,
					subScopes: val.subScopes && val.subScopes.filter(id => id !== scopeId),
				}), scopesWithScopeAsSub),
			} } },
		});
	}

	return res;
});

export const SCOPE_TIME_SLOT = 'SCOPE_TIME_SLOT';

export const addScopeTimeSlot = (scopeId, data) => (dispatch) => {
	// make sure only the teacher id is send
	if (data.teacher && data.teacher.id) {
		// eslint-disable-next-line no-param-reassign
		data.teacher = {
			id: data.teacher.id,
		};
	}

	return dispatch({
		[CALL_API]: {
			type: SCOPE_TIME_SLOT,
			endpoint: `athenastudies-course-group/${scopeId}/course-timeslot`,
			schema: Schemas.TIME_SLOT,
			body: data,
			method: 'POST',
		},
		addToGroupId: scopeId,
	}).then(res => {
		if (res.type === getApiTypeSuccess(SCOPE_TIME_SLOT)) {
			dispatch(notificationShow('Added new time slot', 'success'));
		}

		return res;
	});
};

export const addScopeLinkedTimeSlot = (scopeId, timeSlotId, previousTimeSlotId = null) => (dispatch) => dispatch({
	[CALL_API]: {
		type: SCOPE_TIME_SLOT,
		endpoint: `athenastudies-course-group/${scopeId}/course-timeslot/${timeSlotId}`,
		schema: Schemas.TIME_SLOT,
		body: {},
		method: 'POST',
	},
	addToGroupId: scopeId,
	...(previousTimeSlotId ? {
		deleteFromGroup: {
			groupId: scopeId,
			timeSlotId: previousTimeSlotId,
		},
	} : {}),
}).then(res => {
	if (res.type === getApiTypeSuccess(SCOPE_TIME_SLOT)) {
		dispatch(notificationShow('Linked time slot to group', 'success'));
	}

	return res;
});

export const editScopeTimeSlot = (timeSlotId, scopeId, data) => (dispatch) => dispatch({
	[CALL_API]: {
		type: SCOPE_TIME_SLOT,
		endpoint: `athenastudies-course-group/${scopeId}/course-timeslot`,
		schema: [Schemas.TIME_SLOT],
		body: [{
			...data,
			id: timeSlotId,
			teacher: data.teacher && { id: data.teacher.id }, // make sure only the teacher id is send
		}],
		method: 'PATCH',
	},
	replaceAtGroupId: scopeId,
}).then(res => {
	if (res.type === getApiTypeSuccess(SCOPE_TIME_SLOT)) {
		dispatch(notificationShow('Edited time slot', 'success'));
	}

	return res;
});

export const deleteScopeTimeSlot = (timeSlotId, scopeId) => (dispatch) => dispatch({
	[CALL_API]: {
		type: SCOPE_TIME_SLOT,
		endpoint: `athenastudies-course-group/${scopeId}/course-timeslot/${timeSlotId}`,
		method: 'DELETE',
	},
	deleteFromGroup: {
		groupId: scopeId,
		timeSlotId,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(SCOPE_TIME_SLOT)) {
		dispatch(notificationShow('Deleted time slot', 'error'));
	}

	return res;
});
