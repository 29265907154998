import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import { lighten } from '@mui/material/styles';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const PREFIX = 'LocationItemCard';

const classes = {
    root: `${PREFIX}-root`,
    purple: `${PREFIX}-purple`,
    left: `${PREFIX}-left`,
    right: `${PREFIX}-right`,
    title: `${PREFIX}-title`,
    description: `${PREFIX}-description`,
    unavailableText: `${PREFIX}-unavailableText`,
    check: `${PREFIX}-check`,
    meta: `${PREFIX}-meta`,
    metaValue: `${PREFIX}-metaValue`,
    metaLabel: `${PREFIX}-metaLabel`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		...theme.typography.body1,
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		border: `1px solid ${theme.palette.grey[300]}`,
		borderLeftWidth: 0,
		borderRadius: theme.shape.borderRadius,
		cursor: 'pointer',
		transition: 'background 0.3s ease-in-out, border 0.3s ease-in-out',
        [`& + .${classes.root}`]: {
			marginTop: theme.spacing(1),
		},
		'&.unavailable': {
			backgroundColor: theme.palette.grey[200],
			[`& .${classes.purple}`]: {
				backgroundColor: theme.palette.grey[500],
			},
		},
		'&.selected': {
			backgroundColor: lighten(theme.palette.primary.light, 0.8),
			cursor: 'unset',
		},
		'&:hover:not(.selected):not(.unavailable)': {
			backgroundColor: lighten(theme.palette.primary.light, 0.7),
			borderColor: theme.palette.grey[600],
		},
	},

    [`& .${classes.purple}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: `${parseInt(theme.spacing(1), 10) - 1}px`,
		backgroundColor: theme.palette.primary.light,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderTopLeftRadius: theme.shape.borderRadius,
	},

    [`& .${classes.left}`]: {
		flex: 1,
		alignSelf: 'stretch',
		marginLeft: theme.spacing(1),
		padding: theme.spacing(1),
	},

    [`& .${classes.right}`]: {
		padding: theme.spacing(1, 1, 1, 0),
		display: 'flex',
		alignItems: 'center',
	},

    [`& .${classes.title}`]: {
		fontWeight: theme.typography.fontWeightMedium,
		marginBottom: theme.spacing(0.5),
	},

    [`& .${classes.description}`]: {
		fontStyle: 'italic',
		fontSize: '0.9em',
	},

    [`& .${classes.unavailableText}`]: {
		color: theme.palette.error.main,
		fontSize: '0.8em',
		marginBottom: theme.spacing(1),
	},

    [`& .${classes.check}`]: {
		fontSize: '1.4em',
		verticalAlign: '-0.25em',
		color: theme.palette.success.dark,
		marginRight: theme.spacing(1),
	},

    [`& .${classes.meta}`]: {
		...theme.typography.body1,
		textAlign: 'center',
		padding: theme.spacing(1),
		'&.shaded': {
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.grey[300],
		},
        [`& + .${classes.meta}`]: {
			marginLeft: theme.spacing(1),
		},
	},

    [`& .${classes.metaValue}`]: {
		fontSize: '1.1em',
		fontWeight: theme.typography.fontWeightMedium,
	},

    [`& .${classes.metaLabel}`]: {
		fontSize: '1em',
	}
}));

function limitText(text, limit = 60) {
	if (typeof text !== 'string') return text;
	if (!text || text.length <= limit) return text;

	return `${text.substring(0, limit)} ...`;
}

function LocationItemCard({ title, description, meta, selected, item, onClick, unavailable }) {

	const ref = useRef();

	const handleClick = useCallback((e) => {
		if (e.type === 'keypress' && e.nativeEvent?.keyCode !== 32 && e.nativeEvent?.keyCode !== 13) return;

		if (!selected) onClick(item);
	}, [item, onClick, selected]);

	useEffect(() => {
		if (selected) {
			ref?.current?.scrollIntoView?.({
				block: 'nearest',
			});
		}
	}, [selected]);

	return (
        <Root
			className={classnames(classes.root, {
				unavailable,
				selected,
			})}
			onClick={handleClick}
			onKeyPress={handleClick}
			ref={ref}
			role="button"
			tabIndex={0}
		>
			<div className={classes.purple} />
			<div className={classes.left}>
				<div className={classes.title}>
					{selected && (
						<Icon className={classes.check}>check_box_outline</Icon>
					)}
					{title}
				</div>
				<div className={classes.description}>
					{unavailable && (
						<div className={classes.unavailableText}>
							Not available during date and time of the time slot.
						</div>
					)}
					{selected ? description : limitText(description, 80)}
				</div>
			</div>
			<div className={classes.right}>
				{meta?.map?.(metaItem => (
					<div
						className={classnames(classes.meta, {
							shaded: metaItem.shaded,
						})}
						key={metaItem.label}
					>
						<div className={classes.metaValue}>{metaItem.value}</div>
						<div className={classes.metaLabel}>{metaItem.label}</div>
					</div>
				))}
			</div>
		</Root>
    );
}

LocationItemCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.node,
	meta: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.node.isRequired,
		label: PropTypes.string.isRequired,
		shaded: PropTypes.bool,
	})),
	selected: PropTypes.bool,
	item: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	unavailable: PropTypes.bool,
};

LocationItemCard.defaultProps = {
	unavailable: false,
};

export default LocationItemCard;
