import React, { Component, Fragment } from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { withTranslation } from 'react-i18next';
import {
	GROUP_STATE_ADMINISTRATION_COMPLETE,
	GROUP_STATE_ADMINISTRATION_VALIDATED,
	GROUP_STATE_CANCELLED,
	GROUP_STATE_CLOSED,
	GROUP_STATE_RECKONING,
} from '../../../helpers/groupState'
import withFormData from '../../../modules/formData/withFormData'
import { approveFinanceGroupReportDetails } from '../../../actions/financeActions'
import Loader from '../../util/loader/Loader'
import Alert from '../../util/alert/Alert'

class FinancesGroupApproval extends Component {

	state = {
		approval: false,
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked })
	}

	handleApproveClick = () => {
		this.props.watchSubmit(approveFinanceGroupReportDetails(this.props.id))
	}

	render() {

		const {
			props: { saving, state, error, success, t },
			state: { approval },
		} = this

		if (saving) return <Loader/>

		if (state === GROUP_STATE_ADMINISTRATION_COMPLETE) return (
			<Fragment>
				{error && (
					<Alert type={Alert.TYPE_WARNING}>
						{error.error}
					</Alert>
				)}
				<FormControlLabel
					control={(
						<Checkbox
							checked={approval}
							onChange={this.handleChange('approval')}
							color={'primary'}
							value={'approval'}
							disabled={!!success}
						/>
					)}
					label={(
						<Fragment>
							I confirm that the finances shown on this page are true, complete and accurate
							<sup>1, 2</sup>
						</Fragment>
					)}
				/>
				<br/>
				<Button
					size={'large'}
					variant={'contained'}
					color={'primary'}
					disabled={!approval || !!success}
					onClick={this.handleApproveClick}
				>
					{!!success ? 'Finances approved' : `Approve Finances${approval ? '' : ' / confirm first'}`}
				</Button>
				<br/>
				<br/>
				<Typography>
					You can either: <br/>
					A) approve the above finances <br/>
					B) fix problems in de course / group page <br/>
					C) cancel the group if applicable
				</Typography>
			</Fragment>
		)

		const postCompleteStates = [
			GROUP_STATE_CLOSED,
			GROUP_STATE_CANCELLED,
			GROUP_STATE_RECKONING,
			GROUP_STATE_ADMINISTRATION_VALIDATED,
		]

		return (
			<Fragment>
				<Typography>
					Unable to approve finances while the group status
					is <em>{t(`groupStates.${state}.name`)}</em>
				</Typography>
				<Typography component={'em'}>{t(`groupStates.${state}.description`)}</Typography>
				{!postCompleteStates.includes(state) && (
					<Typography>
						Group status is supposed to be "{t(`groupStates.${GROUP_STATE_ADMINISTRATION_COMPLETE}.name`)}"
					</Typography>
				)}
			</Fragment>
		)
	}
}

export default withTranslation()(withFormData()(FinancesGroupApproval))
