import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import OrderStatuses from '../../constants/OrderStatuses';
import { ShippingStatusCaptions, ShippingStatuses } from '../../constants/ShippingStatuses';
import MobilePhoneActions from '../util/MobilePhoneActions';
import PropertiesTable from '../util/PropertiesTable';
import { formatPercentage, formatAddress } from '../../helpers/formatter';
import { hasScopeCapability, capabilities } from '../../helpers/capabilities';
import OrderOptions from '../order/OrderOptions';
import CourseGroupOccupancyClientAddressDialog from './CourseGroupOccupancyClientAddressDialog';

const CourseGroupOccupancyClientModal = ({ handleClose, modal, group, orders, courseId, groups, }) => {
	const [editAddress, setEditAddress] = useState(false);

	if (!modal || !modal.orderEntryId || !orders) return null;

	const order = orders.find(o => o.id === modal.orderEntryId);
	if (!order) return null;

	const { id, client, orderId, discountCode, shippingAddress, shippingStatus } = order;

	const properties = [
		{ label: 'Email', content: client.email },
		{ label: 'Mobile', content: (<MobilePhoneActions client={client} />) },
		{ label: 'Order ID', content: `#${orderId}-${id}` },
		{ label: 'Order status', content: OrderStatuses[order.orderStatus] || 'Unknown' },
	];

	if (group.addressMandatory) {
		const address = formatAddress(shippingAddress);
		properties.push({
			label: 'Postal address',
			content: (
				<>
                    {address.length === 0 ? <i>No shipping address</i> : address}
                    {' '}
                    {/* eslint-disable-next-line */}(<Box component="span" sx={{ color: 'link.main', cursor: 'pointer' }} onClick={() => setEditAddress(true)}>edit</Box>)
				</>
			),
		});
	}

	if (shippingStatus && shippingStatus !== ShippingStatuses.NO_SHIPPING) {
		properties.push({
			label: 'Shipping status', content: ShippingStatusCaptions[shippingStatus] || shippingStatus,
		});
	}

	if (discountCode && discountCode.discountPercentage) {
		properties.push({
			label: 'Discount', content: `${formatPercentage(discountCode.discountPercentage)}, ${discountCode.code}`,
		});
	}

	return (
		<>
			<Dialog open={true} onClose={handleClose}>
				<DialogTitle>
					{hasScopeCapability(group, capabilities.SeeEmployeeCapability) && (
						<IconButton
							component={Link}
							to={`/portal/customers/${client.id}`}
							sx={{
								float: 'right',
								mt: -1,
							}}
						>
							<Icon>open_in_new</Icon>
						</IconButton>
					)}
					{client.fullName}
				</DialogTitle>
				<DialogContent>
					<PropertiesTable items={properties} />
					<OrderOptions
						scope={group}
						groups={groups}
						client={client}
						courseId={courseId}
						orderEntryId={id}
						orderStatus={order.orderStatus}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
			{editAddress && (
				<CourseGroupOccupancyClientAddressDialog
					client={client}
					orderId={orderId}
					address={shippingAddress}
					onCancel={() => setEditAddress(false)}
				/>
			)}
		</>
	);
};

CourseGroupOccupancyClientModal.propTypes = {
	group: PropTypes.object,
	orders: PropTypes.array,
	courseId: PropTypes.number,
	groups: PropTypes.array,
};

export default (CourseGroupOccupancyClientModal);
