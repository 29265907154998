import Typography from '@mui/material/Typography';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { formatMoney } from '../../helpers/formatter';
import { niceDateYear } from '../../helpers/datetime';
import withFormData from '../../modules/formData/withFormData';
import { expireContract } from '../../actions/employeeActions';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';
import ContractViewDownloadButton from './ContractViewDownloadButton';

function* scopeParents(scope) {
	if (scope.parent) yield* scopeParents(scope.parent);
	yield scope;
}

class ContractViewModal extends Component {
	state = {
		terminateContractDialogOpen: false,
	};

	constructor(props) {
		super(props);

		this.state.endDate = props.modal.contract.endDate;
	}

	openTerminateContractDialog() {
		this.setState({
			terminateContractDialogOpen: true,
		});
	}

	closeTerminateContractDialog = () => {
		this.setState({
			terminateContractDialogOpen: false,
		});
	}

	endContract = () => {
		const { modal: { contract }, watchSubmit } = this.props;
		const { endDate } = this.state;

		if (confirm('Are you sure?')) {
			watchSubmit(expireContract(contract.id, endDate));
		}
	};

	componentDidUpdate(prevProps) {
		const { success, onExpire, modal, handleClose } = this.props;

		if (success && !prevProps.success) {
			if (onExpire) {
				onExpire(modal.contract);
			}

			handleClose();
		}
	}

	render() {
		const { handleClose, error, modal: { contract }, saving } = this.props;

		return (
			<Dialog open={true} onClose={handleClose} fullWidth scroll="body">
				<Dialog open={this.state.terminateContractDialogOpen} onClose={() => this.closeTerminateContractDialog()} scroll="body">
					<DialogTitle>Terminate</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Please specify the date at which the contract is supposed to expire. The default date is today.
							After you have specified the date, you can confirm the contract termination by clicking <em>Terminate</em>.
						</DialogContentText>
						{error && (
							<Alert type={Alert.TYPE_WARNING}>
								{error.error}
								<br />
								<br />
								<Trans i18nKey="contract.view.terminate.extraWarning" />
							</Alert>
						)}
						{saving && (
							<Loader sheet>
								<Trans i18nKey="contract.view.terminate.saving">
									Working on terminating the contract.
									<br />
									This may take a while as it requires some finance calculations.
								</Trans>
							</Loader>
						)}
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField label="Name" value={contract.employee.fullName} disabled fullWidth />
							</Grid>
							<Grid item xs={6}>
								<TextField label="Function" value={contract.function.name} disabled fullWidth />
							</Grid>
							<Grid item xs={6}>
								<TextField label="Scope" value={contract.scopes[0].name} disabled fullWidth />
							</Grid>
							{(contract.baseSalary || contract.function.baseSalary) && (
								<Grid item xs={6}>
									<TextField
										label="Tutor compensation"
										value={`${formatMoney(contract.baseSalary || contract.function.baseSalary)} - ${formatMoney(contract.bonusSalary || contract.function.bonusSalary)}`}
										disabled
										fullWidth
									/>
								</Grid>
							)}
							{(contract.turnoverPercentage || contract.function.turnoverPercentage) && (
								<Grid item xs={6}>
									<TextField
										label="Turnover percentage"
										value={`${(contract.turnoverPercentage || contract.function.turnoverPercentage)}% - ${(contract.bonusTurnoverPercentage || contract.function.bonusTurnoverPercentage)}%`}
										disabled
										fullWidth
									/>
								</Grid>
							)}
							{(contract.profitPercentage || contract.function.profitPercentage) && (
								<Grid item xs={6}>
									<TextField
										label="Gross profit percentage"
										value={`${(contract.profitPercentage || contract.function.profitPercentage)}%`}
										disabled
										fullWidth
									/>
								</Grid>
							)}
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<MobileDatePicker
									value={contract.startDate}
									inputFormat="D MMMM YYYY"
									showToolbar={false}
									disabled
									onChange={() => null}
									renderInput={(props) => (
										<TextField
											{...props}
											label="Start date"
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6}>
								<MobileDatePicker
									inputFormat="D MMMM YYYY"
									value={this.state.endDate}
									showToolbar={false}
									minDate={contract.startDate ? moment(contract.startDate) : undefined}
									onChange={(endDate) => this.setState({endDate})}
									closeOnSelect
									componentsProps={{
										actionBar: {
											actions: ['cancel'],
										},
									}}
									renderInput={(props) => (
										<TextField
											{...props}
											label="Termination date"
											fullWidth
										/>
									)}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.closeTerminateContractDialog()}>Close</Button>
						<Button onClick={() => this.endContract()} color="primary">Terminate</Button>
					</DialogActions>
				</Dialog>
				<DialogTitle>{`${contract.function.name} at ${contract.scopes[0].name}`}</DialogTitle>
				<DialogContent>
					<Typography variant="subtitle1">Basic contract information</Typography>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Name</Typography>
							{contract.employee.fullName}
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Role</Typography>
							{contract.function.name}
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Scope</Typography>
							{contract.scopes.map(scope => Array.from(scopeParents(scope))
								.map(parent => parent.abbreviation).join(' / '))
								.join(' ')}
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2">Terms</Typography>
							{`${niceDateYear(contract.startDate)} - ${contract.endDate ? niceDateYear(contract.endDate) : 'present'}`}
						</Grid>
					</Grid>
					<br />
					<Typography variant="subtitle1">Payment details</Typography>
					<Grid container spacing={1}>
						{(contract.turnoverPercentage || contract.function.turnoverPercentage) && (
							<Tooltip placement="top" title={'The compensation a manager will get, which is a percentage of the turnover on the contract scope. The first value is the basic compensation rate. The second value is the bonus compensation which will replace the basic compensation if the "Bonus evaluation threshold" is reached.'}>
								<Grid item xs={6}>
									<Typography variant="subtitle2">Turnover percentage</Typography>
									{`${contract.turnoverPercentage || contract.function.turnoverPercentage}% - ${contract.bonusTurnoverPercentage || contract.function.bonusTurnoverPercentage}%`}
								</Grid>
							</Tooltip>
						)}

						{(contract.profitPercentage || contract.function.profitPercentage) && (
							<Tooltip placeholder="top" title={'The percentage of the profit over the contract scope this employee will receive. Generally only used for contract which also receive a default monthly compensation.'}>
								<Grid item xs={6}>
									<Typography variant="subtitle2">Gross profit percentage</Typography>
									{`${contract.profitPercentage || contract.function.profitPercentage || '-'}%`}
								</Grid>
							</Tooltip>
						)}

						{(contract.baseSalary || contract.function.baseSalary) && (
							<Tooltip placement="top" title={'Compensation the tutor will receive per hour. The first value is the basic compensation. The second value is the bonus compensation which will replace the basic compensation if the "Bonus evaluation threshold" is reached.'}>
								<Grid item xs={6}>
									<Typography variant="subtitle2">Tutoring compensation</Typography>
									{`${formatMoney(contract.baseSalary || contract.function.baseSalary)} - ${formatMoney(contract.bonusSalary || contract.function.bonusSalary)}`}
								</Grid>
							</Tooltip>
						)}

						{(contract.bonusEvaluationThreshold) && (
							<Tooltip placement="top" title={'The evaluation the tutor should reach in order to receive the bonus compensation.'}>
								<Grid item xs={6}>
									<Typography variant="subtitle2">Bonus evaluation threshold</Typography>
									{contract.bonusEvaluationThreshold}
								</Grid>
							</Tooltip>
						)}

						{(contract.monthlySalary) && (
							<Grid item xs={6}>
								<Typography variant="subtitle2">Monthly salary</Typography>
								{formatMoney(contract.monthlySalary)}
							</Grid>
						)}

						{(contract.payment) && (
							<Grid item xs={6}>
								<Typography variant="subtitle2">One time payment</Typography>
								{formatMoney(contract.payment)}
							</Grid>
						)}

						{(contract.dueDate) && (
							<Grid item xs={6}>
								<Typography variant="subtitle2">Due date</Typography>
								{niceDateYear(contract.dueDate)}
							</Grid>
						)}
					</Grid>
					<br />
					<Typography variant="subtitle2">Signed</Typography>
					{`${contract.signaturePlace}, ${niceDateYear(contract.signatureDate)}`}
				</DialogContent>
				<DialogActions>
					<ContractViewDownloadButton contract={contract} />
					<Button
						onClick={() => this.openTerminateContractDialog()}
						disabled={!!contract.endDate && moment(contract.endDate).isBefore(moment())}
					>
						<Icon>delete_forever</Icon>&nbsp;
						Terminate contract
					</Button>
					<Button
						onClick={handleClose}
						variant="contained"
						color="primary"
					>
						Close view
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

ContractViewModal.propTypes = {
	onExpire: PropTypes.func,
};

export default withFormData()(ContractViewModal);
