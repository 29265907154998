import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

const DeclarationContext = createContext(null);

export const DeclarationContexts = {
	PROFILE: 'profile',
	EMPLOYEE: 'employee',
	SCOPE: 'scope',
};

function DeclarationContextProvider({ value, children }) {
	return (
		<DeclarationContext.Provider value={value}>
			{children}
		</DeclarationContext.Provider>
	);
}

DeclarationContextProvider.propTypes = {
	value: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export function useDeclarationContext() {
	return useContext(DeclarationContext);
}

export default DeclarationContextProvider;
