import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import moment from 'moment'
import { groupBy, mapObjIndexed, times, values, reduce, identity, sortBy, prop } from 'ramda'
import classNames from 'classnames'
import { CALL_API } from '../../setup/api'
import withFetchData from '../../modules/fetchData/withFetchData'
import { niceDateTime, niceTime } from '../../helpers/datetime'
import Loader from '../util/loader/Loader'
import { liveDebugMode } from './liveUtils'
import LiveBlockHeading from './LiveBlockHeading'

const PREFIX = 'LiveSalesTrendPunchCard';

const classes = {
    root: `${PREFIX}-root`,
    punchCard: `${PREFIX}-punchCard`,
    punchCardLabels: `${PREFIX}-punchCardLabels`,
    punchCardTotals: `${PREFIX}-punchCardTotals`,
    punchCardLegend: `${PREFIX}-punchCardLegend`,
    column: `${PREFIX}-column`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
		},
	},

    [`& .${classes.punchCard}`]: {
		margin: theme.spacing(2),
		marginTop: 0,
		marginBottom: 0,
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
	},

    [`& .${classes.punchCardLabels}`]: {
		margin: theme.spacing(2),
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
        [`& .${classes.column}`]: {
			'& > div': {
				border: 'none',
			},
		},
	},

    [`& .${classes.punchCardTotals}`]: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(0.5),
		marginBottom: 0,
		display: 'flex',
		flexDirection: 'row',
        [`& .${classes.column}`]: {
			'& > div': {
				border: 'none',
				display: 'block',
				color: theme.palette.text.secondary,
			},
		},
	},

    [`& .${classes.punchCardLegend}`]: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		'& > div': {
			flex: 1,
			textAlign: 'center',
			fontSize: '0.90em',
			border: `1px solid ${theme.palette.divider}`,
			padding: 1,
			'& + div': {
				marginLeft: theme.spacing(0.5),
			},
		},
	},

    [`& .${classes.column}`]: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		'& > div': {
			flex: 1,
			textAlign: 'center',
			border: `1px solid ${theme.palette.divider}`,
			display: 'flex',
			'& + div': {
				marginTop: theme.spacing(0.5),
			},
			'&.noData': {
				border: '1px solid transparent',
			},
		},
		[`& + .${classes.column}`]: {
			marginLeft: theme.spacing(0.5),
		},
		'&.labels': {
			'& > div': {
				border: 'none',
				fontSize: '0.9em',
				lineHeight: '0.9em',
				color: theme.palette.text.secondary,
				paddingLeft: theme.spacing(0.5),
			},
		},
		'&.today': {
			fontWeight: theme.typography.fontWeightMedium,
		},
	}
}));

const legendAmount = 6;

const apiAction = (rootScopeId) => ({
	[CALL_API]: {
		type: 'SALES_TREND',
		endpoint: `scope/${rootScopeId}/sales-trend?period=${liveDebugMode ? 'P6M' : 'P7D'}&interval=${liveDebugMode ? 'P1D' : 'PT1H'}`,
	},
});

function lerpColor(a, b, amount) {
	var ah = parseInt(a.replace(/#/g, ''), 16),
		ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
		bh = parseInt(b.replace(/#/g, ''), 16),
		br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
		rr = ar + amount * (br - ar),
		rg = ag + amount * (bg - ag),
		rb = ab + amount * (bb - ab);

	return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

const startColor = '#ffffff';
const endColor = '#7f397f';

const sortByDaysAgo = sortBy(prop('daysAgo'));
const sumSoldUnits = reduce((tot, item) => tot + item.soldUnits, 0);
const maxSoldUnits = reduce((acc, item) => Math.max(item.soldUnits, acc), 0);

const LiveSalesTrendPunchCard = ({  style, data, fetchCache }) => {
	if(!data) return (
		<Loader sheet />
	);

	const dEndOfDay = moment().endOf('day');
	const dNow = moment();

	data = data.map(item => ({
		...item,
		soldUnits: item.soldUnits || 0,
		timestampM: moment(item.timestamp),
	})).filter(item => (
		item.timestampM.isBefore(dEndOfDay) // TODO: remove, now result is including tomorrow 00:00
	));

	const groups = sortByDaysAgo(values(mapObjIndexed((items, day) => ({
		day: day,
		daysAgo: items && items[0] ? items[0].timestampM.diff(dNow, 'day', false) : null,
		items: items,
		totalSales: sumSoldUnits(items),
	}), groupBy(item => {
		return item.timestampM.weekday();
	})(data))));

	const max =  maxSoldUnits(data);

	return (
        <Root style={style} className={classes.root}>
			<LiveBlockHeading secondary={(
				<em>last update<br/>{niceTime(fetchCache.date)}</em>
			)}>
				Orders last 7 days
			</LiveBlockHeading>
			<div className={classes.punchCardLabels}>
				{groups.map(({ day, daysAgo }) => (
					<div className={classNames(classes.column, {
						today: daysAgo === 0,
					})} key={day}>
						<div>{moment().weekday(day).format('dd')}</div>
					</div>
				))}
				<div className={classNames(classes.column, 'labels')} />
			</div>
			<div className={classes.punchCard}>
				{groups.map(({ items, day }) => (
					<div className={classes.column} key={day}>
						{items.map(item => (
							<div
								key={item.timestamp}
								style={{
									backgroundColor: item.soldUnits === -1 ? '#FFFFFF' : lerpColor(startColor, endColor, item.soldUnits / max)
								}}
								title={`${niceDateTime(item.timestamp)} - ${item.soldUnits}`}
								className={classNames({
									noData: item.timestampM.isAfter(),
								})}
							/>
						))}
					</div>
				))}
				<div className={classNames(classes.column, 'labels')}>
					{times(identity, 12).map(key => (
						<div key={key}>
							{moment().hour(key * 2).format('HH')}:00
						</div>
					))}
				</div>
			</div>
			<div className={classes.punchCardTotals}>
				{groups.map(({ day, daysAgo, totalSales }) => (
					<div className={classNames(classes.column, {
						today: daysAgo === 0,
					})} key={day}>
						<div>{totalSales}x</div>
					</div>
				))}
				<div className={classNames(classes.column, 'labels')} />
			</div>
			<div className={classes.punchCardLegend}>
				{times(identity, legendAmount).map(key => (
					<div
						key={key}
						style={{
							backgroundColor: lerpColor(startColor, endColor, key/(legendAmount - 1))
						}}
					>
						{Math.round(key/(legendAmount - 1) * max)}
					</div>
				))}
			</div>
		</Root>
    );
}

LiveSalesTrendPunchCard.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default (withFetchData((props) => apiAction(props.rootScopeId), {
	autoRefreshSeconds: 60 * 5,
})(LiveSalesTrendPunchCard))
