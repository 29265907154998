import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import withFormData from '../../modules/formData/withFormData'
import { formatMoney } from '../../helpers/formatter'
import { orderEntryRefund } from '../../actions/orderActions'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'

class OrderOptionsRefund extends Component {
	render(){
		const { saving, error, success } = this.props;

		return (
			<div>
				<p><strong>Cancel the order</strong> and pay back the full price. The client will not take part in the course anymore. Whenever the client has more orders/places in this group, only one will be refunded at a time.</p>
				{saving && <Loader />}
				{error && <Alert type={Alert.TYPE_WARNING}>
					{error.error}
				</Alert>}
				{success ?
					<Alert type={Alert.TYPE_SUCCESS}>
						Action was successful, it might take a few minutes and a page refresh for the changes to be visible.<br/><br/>
						{
							success.refund ?
								`The client will receive the refund in about 2 business days. Total refunded: ${formatMoney(success.refund.totalAmount)}.`
								:
								'A refund was not needed.'
						}
					</Alert>
					:
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Button variant="contained"
									color="primary"
									fullWidth
									disabled={saving}
									onClick={this.handleRefund}>
								Refund 1 full order/place
							</Button>
						</Grid>
					</Grid>
				}
			</div>
		)
	}

	handleRefund = () => {
		const { orderEntryId, watchSubmit } = this.props;

		if(!confirm('Are your sure you want to fully refund this order? The client will be removed from the list and paid back. The client will NOT be automatically informed about this.')) return;

		watchSubmit(orderEntryRefund({
			orderEntryId: orderEntryId
		}));
	}
}

OrderOptionsRefund.propTypes = {
	orderEntryId: PropTypes.number.isRequired,
}

export default withFormData()(OrderOptionsRefund)
