import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getCostAccounts } from '../../actions/costActions';
import { useFetchData } from '../../helpers/hooks';
import { sortKey } from '../../helpers/sorts';

export default function CostAccountSelector({ value = -1, disabled, onChange, id, ...rest }) {
	const { data: accounts, loading, error } = useFetchData('CostAccounts', getCostAccounts);
	const { t } = useTranslation();
	return (
		<Select
			disabled={loading || disabled}
			onChange={onChange}
			value={value}
			error={error}
			id={id}
			{...rest}
		>
			<MenuItem value={-1}><em>{t('util.costAccount.select')}</em></MenuItem>
			{accounts && accounts.sort(sortKey('id')).map((account) => (
				<MenuItem
					key={account.id}
					value={account.id}
				>
					{`${account.accountNumber} - ${account.description}`}
				</MenuItem>
			))}
		</Select>
	);
}

CostAccountSelector.propTypes = {
	value: PropTypes.number,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

export function ControlledCostAccountSelector({ disabled, control, name, ...rest }) {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value } }) => (
				<CostAccountSelector disabled={disabled} value={value} onChange={onChange} id={name} {...rest} />
			)}
		/>
	);
}

ControlledCostAccountSelector.propTypes = {
	disabled: PropTypes.bool,
	control: PropTypes.object,
	name: PropTypes.string.isRequired,
};
