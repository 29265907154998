import React, { Component, Fragment } from 'react'
import Grid from '@mui/material/Grid'
import withWorkingScope from '../../../modules/scope/withWorkingScope'
import Alert from '../../util/alert/Alert'
import PageHeader from '../../page/PageHeader'
import ModalManager from '../../modal/ModalManager'
import ModalItem from '../../modal/ModalItem'
import ScopeFilter from '../filter/ScopeFilter'
import ScopeEditorModal from '../edit/ScopeEditorModal'
import PlannerForScope from './PlannerForScope'
import PlannerScrollRestoreAndUp from './PlannerScrollRestoreAndUp'
import CalendarViewModal from '../../calendar/CalendarViewModal';

class PlannerPage extends Component {
	render(){
		const { workingScope } = this.props;

		if(!workingScope || workingScope._virtual) return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Alert type={Alert.TYPE_INFO}>
						Select a suitable scope first to open the course planner. This can be done through the scope selector at the top of the page.
					</Alert>
				</Grid>
			</Grid>
		)

		return (
			<Fragment>
				<ModalManager>
					<ModalItem state="scopeEditor" component={ScopeEditorModal} />
					<ModalItem state="showCalendar" component={CalendarViewModal} />
				</ModalManager>
				<PageHeader>
					<ScopeFilter />
				</PageHeader>
				<PlannerForScope scope={workingScope} />
				<PlannerScrollRestoreAndUp scopeId={workingScope.id} />
			</Fragment>
		);
	}
}

export default withWorkingScope(PlannerPage)
