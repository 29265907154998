import moment from 'moment'

moment.updateLocale('en', {
	longDateFormat : {
		LT : 'HH:mm',
		LTS : 'HH:mm:ss',
		L : 'DD-MM-YYYY',
		LL : 'D MMMM YYYY',
		LLL : 'D MMMM YYYY HH:mm',
		LLLL : 'dddd D MMMM YYYY HH:mm',
	},
	week : {
		dow : 1, // Monday is the first day of the week.
		doy : 4,  // Used to determine first week of the year.
	},
});

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';
moment.defaultFormatUtc = 'YYYY-MM-DDTHH:mm:ss';

export const niceDateYear = (d) => {
	return moment(d).format('D MMM YYYY');
};

export const niceDateMonthYear = (d) => {
	return moment(d).format('MMMM YYYY');
};

export const niceFullDateYear = (d) => {
	return moment(d).format('D MMMM YYYY');
};

export const niceDateDay = (d, locale = 'en') => {
	const str = moment(d).locale(locale).format('dddd D MMMM');
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const niceDay = (d, locale = 'en') => {
    const str = moment(d).locale(locale).format('dddd');
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const niceDateTime = (d, locale = 'en', t) => {
	if (!d) return 'No date set';
	const at = t ? t('general.at') : 'at';
	return moment(d).locale(locale).format(`D MMM YYYY [${at}] HH:mm`);
}

export const niceTime = (d, locale = 'en') => {
	return moment(d).locale(locale).format('HH:mm');
}

export const niceHoursDuration = d => moment.duration(d).asHours() + ' hours'

export const timeFromNow = (d) => {
	return moment(d).fromNow();
}

export const dateCalendarFormat = (d) => {
	return moment(d).calendar();
}

export const daysDifferenceWithNow = (d) => {
	const days = moment(d).diff(moment(), 'days', true);
	return (days > 0 ? Math.ceil(days) : Math.floor(days));
}

export const datePassed = (dueDate, checkDate = undefined) => {
	return moment(dueDate).isBefore(moment(checkDate));
}

export const getISO = (momentObj) => {
	return momentObj.format('YYYY-MM-DDTHH:mm:ss');
}

export const getISOFromDate = (d) => {
	return moment(d).format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * @param dates: Array of moment dates
 * @returns {*} moment object or false
 */
export const getFirstUpcomingDate = dates => {
	const filtered = dates.filter(date => moment.isMoment(date) && date.isAfter())
	if (!filtered.length) return false
	if (filtered.length === 1) return filtered[0]
	return filtered.reduce((acc, date) => date.isBefore(acc) ? date : acc, filtered[0])
}

export const dateTimePickerFunctions = {
	editableViewDate: function(dateTime) {
		return moment(dateTime).format("dd D MMMM YYYY");
	},

	// Returns a format that can be given to a date picker
	editableDate: function(dateTimeString) {
		return moment(dateTimeString).toDate();
	},

	// Returns a format that can be given to a time picker as duration
	editableDuration: function(startDateTime, endDateTime) {
		const duration = moment(endDateTime);
		startDateTime = moment(startDateTime);

		duration.subtract(startDateTime.hours(), 'h');
		duration.subtract(startDateTime.minutes(), 'm');

		return duration.toDate();
	},

	// Returns end time based on start date and duration
	// (opposite of editableDuration)
	getEditedEnd: function(isoStartDateTime, duration) {
		const endDateTime = moment(isoStartDateTime);
		duration = moment(duration);

		endDateTime.add(duration.hours(), 'h');
		endDateTime.add(duration.minutes(), 'm');

		if(endDateTime.hours() > 23) endDateTime.hours(23);

		return getISO(endDateTime);
	},

	// Returns new date with time replaced by isoTime
	replaceEditedDate: function(date, isoTime) {
		const time = moment(isoTime);
		date = moment(date);

		date.hours(time.hours());
		date.minutes(time.minutes());

		return getISO(date);
	},

	// Returns new date with date replaced by isoDate
	replaceEditedTime: function(isoDate, time) {
		const date = moment(isoDate);
		time = moment(time);

		time.date(date.date());
		time.month(date.month());
		time.year(date.year());

		return getISO(time);
	},
};
