import React from 'react'
import PropTypes from 'prop-types'
import { Schemas } from '../../../actions/schemas'
import withFormData from '../../../modules/formData/withFormData'
import { CALL_API } from '../../../setup/api'
import Alert from '../../util/alert/Alert'
import ApiError from '../../util/ApiError'
import HesitationButton from '../../util/HesitationButton'
import Loader from '../../util/loader/Loader'
import PropertiesTable from '../../util/PropertiesTable'

const ApiAction = (timeSlotId) => ({
	[CALL_API]: {
		type: 'ZOOM',
		endpoint: `course-timeslot/${timeSlotId}/zoom-us-meeting/take-over`,
		schema: Schemas.TIME_SLOT,
		body: {},
	},
});

const ZoomMeetingTakeOver = ({ timeSlot, joinUrl, saving, error, success, watchSubmit }) => {
	const takeOver = () => {
		watchSubmit(ApiAction(timeSlot.id));
	};

	if(timeSlot.zoomJoinBeforeHostEnabled) return (
		<div>
			<Alert type={Alert.TYPE_WARNING}>This online class is currently in emergency take over mode. Everyone can join the class now. To be the host, join and find the "claim host" in the right bottom corner of the participants list. Use the host key below. Do this on the computer which will be used by the teacher.</Alert>
			<PropertiesTable items={[
				{ label: 'Join URL', content: <a href={joinUrl} target="_blank">{joinUrl}</a> },
				{ label: 'Host key', content: timeSlot.teacher.identificationNumber },
			]} />
		</div>
	);

	return (
        <div>
			{saving && (
				<Loader />
			)}
			<ApiError error={error} />
			<HesitationButton
				variant="outlined"
				color="secondary"
				onClick={takeOver}
				disabled={saving}
				icon="switch_video"
				label="Generate host key"
			/>
		</div>
    );
};

ZoomMeetingTakeOver.propTypes = {
	timeSlot: PropTypes.object.isRequired,
	joinUrl: PropTypes.string.isRequired,
};

export default withFormData()(ZoomMeetingTakeOver)
