import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loader from '../../util/loader/Loader';
import TrendLine from './TrendLine';

const grossProfitMarginAnnotation = {
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y',
    value: 40,
    borderColor: 'black',
    borderDash: [2, 4],
    label: {
        content: '40%',
        display: true,
        position: 'start',
        backgroundColor: 'rgba(0,0,0,0.0)',
        color: '#000000',
        xAdjust: -12,
        yAdjust: -14,
    },
};

const optionsAverageDuration = {
    plugins: {
        tooltip: {
            callbacks: {
                label: (value) => {
                    const momentValue = moment.duration(value.parsed.y);
                    return `${(momentValue.hours()).toString()
                        .padStart(2, '0')}:${(momentValue.minutes()).toString()
                        .padStart(2, '0')}`;
                },
            },
        },
    },
};

const averageEvaluationAnnotation = {
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y',
    value: 8.0,
    borderColor: 'black',
    borderDash: [2, 4],
    label: {
        content: '8,0',
        display: true,
        position: 'start',
        backgroundColor: 'rgba(0,0,0,0.0)',
        color: '#000000',
        xAdjust: -10,
        yAdjust: -2,
    },
};

function TrendLines({ aggregatedScopeReport, selectedDate, loading, hasFinancialReportingCapability }) {
    if (!aggregatedScopeReport) return <Loader />;

    const monthKey = selectedDate && moment(selectedDate).format('YYYY-MM');
    const currentValue = aggregatedScopeReport && monthKey && aggregatedScopeReport[monthKey];

    const monthLineAnnotation = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: monthKey,
        borderColor: 'black',
        borderWidth: 1,
        borderDash: [6, 6],
    };

    return (
        <>
            {/* Although we don't want to go through too much trouble to hide gross profits from FMs/UMs, */}
            {/* we don't want to put in their face either */}
            {hasFinancialReportingCapability && (
                <TrendLine
                    data={aggregatedScopeReport}
                    currentValue={currentValue}
                    loading={loading}
                    label="Gross Profit Margin"
                    valueExtractor={(report) => typeof report.grossProfitMargin === 'number' && +(report.grossProfitMargin * 100).toFixed(2)}
                    formatter={value => `${value}%`}
                    options={{ plugins: { annotation: { annotations: { grossProfitMarginAnnotation, monthLineAnnotation } } } }}
                />
            )}

            <TrendLine
                data={aggregatedScopeReport}
                currentValue={currentValue}
                loading={loading}
                label="Hourly Rate"
                valueExtractor={(report) => typeof report.hourlyRate?.amount === 'number' && +(report.hourlyRate.amount).toFixed(2)}
                options={{ plugins: { annotation: { annotations: { monthLineAnnotation } } } }}
            />

            <TrendLine
                data={aggregatedScopeReport}
                currentValue={currentValue}
                loading={loading}
                label="Given Discount Rate"
                valueExtractor={(report) => typeof report.givenDiscountRate === 'number' && +(report.givenDiscountRate * 100).toFixed(2)}
                formatter={value => `${value}%`}
                options={{ plugins: { annotation: { annotations: { monthLineAnnotation } } } }}
            />

            <TrendLine
                data={aggregatedScopeReport}
                currentValue={currentValue}
                loading={loading}
                label="Average Duration"
                valueExtractor={(report) => report.averageDuration && moment.duration(report.averageDuration)}
                formatter={value => value && `${(value.hours()).toString().padStart(2, '0')}:${(value.minutes()).toString().padStart(2, '0')}`}
                options={{ plugins: { annotation: { annotations: { monthLineAnnotation } }, ...optionsAverageDuration } }}
            />

            <TrendLine
                data={aggregatedScopeReport}
                currentValue={currentValue}
                loading={loading}
                label="Sold Units"
                valueExtractor={(report) => report.soldUnits}
                options={{ plugins: { annotation: { annotations: { monthLineAnnotation } } } }}
            />

            <TrendLine
                data={aggregatedScopeReport}
                currentValue={currentValue}
                loading={loading}
                label="Average Evaluation"
                valueExtractor={(report) => typeof report.averageEvaluation === 'number' && +(report.averageEvaluation).toFixed(2)}
                options={{ plugins: { annotation: { annotations: { averageEvaluationAnnotation, monthLineAnnotation } } } }}
            />

            <TrendLine
                data={aggregatedScopeReport}
                currentValue={currentValue}
                loading={loading}
                label="Occupancy"
                valueExtractor={(report) => typeof report.occupancy === 'number' && +(report.occupancy).toFixed(2)}
                options={{ plugins: { annotation: { annotations: { monthLineAnnotation } } } }}
            />
        </>
    );
}

TrendLines.propTypes = {
    aggregatedScopeReport: PropTypes.object,
    selectedDate: PropTypes.object,
    loading: PropTypes.bool,
    hasFinancialReportingCapability: PropTypes.bool,
};

export default TrendLines;
