import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import DeclarationStatuses from '../../../constants/DeclarationStatuses';
import Alert from '../../util/alert/Alert';
import Loader from '../../util/loader/Loader';
import { DeclarationContexts, useDeclarationContext } from './DeclarationContext';
import DeclarationForm from './DeclarationForm';
import DeclarationStatusChip from './DeclarationStatusChip';

const {
	CREATED,
	DECLINED,
	APPROVED,
	WITHDRAWN,
	SUBMITTED,
} = DeclarationStatuses;

const { PROFILE } = DeclarationContexts;

const declarationsSelector = ({ fetchData }) => fetchData.EmployeeDeclarations?.success;

function DeclarationModal({ handleClose, modal }) {
	const { t } = useTranslation();
	const [id, setId] = useState(modal.id);
	const [formState, setFormState] = useState({ isDirty: false, isSubmitting: false, isSubmitted: false });
	const { isDirty, isSubmitting, isSubmitted } = formState;
	const saveBind = useRef();
	const statusBind = useRef();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const declarations = useSelector(declarationsSelector);
	const declaration = declarations?.find(iteree => iteree.id === id);
	const context = useDeclarationContext();
	const disableEditing = declaration && declaration?.status !== CREATED;
	const disableStatusChange = declaration?.status !== SUBMITTED;

	const bindSave = (cb) => {
		saveBind.current = cb;
	};

	const bindStatus = (cb) => {
		statusBind.current = cb;
	};

	const handleSave = () => {
		// eslint-disable-next-line no-unused-expressions
		saveBind.current && saveBind.current();
	};

	const handleStatusChange = (status) => () => {
		// eslint-disable-next-line no-unused-expressions
		statusBind.current && statusBind.current(declaration.id, status);
	};

	return (
		<Dialog
			open={true}
			onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)}
			maxWidth="md"
			fullWidth
			fullScreen={fullScreen}
		>
			{isSubmitting && (
				<Loader sheet />
			)}
			<DialogTitle>
				{t('declarations.modal.title')}
				{declaration?.status && (
					<DeclarationStatusChip
						label={t(`declarations.status.${declaration.status}`)}
						status={declaration.status}
						sx={{ marginLeft: theme.spacing(2) }}
					/>
				)}
			</DialogTitle>
			<DialogContent>
				{ !isDirty && isSubmitted && (
					<Alert type={Alert.TYPE_SUCCESS}>
						{t('declarations.modal.saved')}
					</Alert>
				)}
				{context === PROFILE && id && declaration?.status === CREATED && (
					<Alert type={Alert.TYPE_WARNING}>
						{t('declarations.modal.notSubmitted')}
					</Alert>
				)}
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<DeclarationForm
							declaration={declaration}
							disabled={disableEditing}
							bindSave={bindSave}
							bindStatus={bindStatus}
							setId={setId}
							setFormState={setFormState}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				{context === DeclarationContexts.PROFILE ? (
					<>
						<Button
							onClick={handleStatusChange(SUBMITTED)}
							color="primary"
							variant="contained"
							disabled={disableEditing || !declaration || isDirty}
						>
							{t('declarations.actions.submit')}
						</Button>
						<Button
							onClick={handleStatusChange(WITHDRAWN)}
							color="secondary"
							variant="contained"
							disabled={disableEditing || !declaration || isDirty}
							sx={{ marginRight: 'auto' }}
						>
							{t('general.withdraw')}
						</Button>
						<Button onClick={handleClose}>
							{t('general.close')}
						</Button>
						<Button
							onClick={handleSave}
							color="primary"
							variant="outlined"
							disabled={disableEditing || !isDirty || isSubmitting}
							endIcon={isSubmitting && (
								<CircularProgress size={16} />
							)}
						>
							{t('general.save')}
						</Button>
					</>
				) : (
					<>
						<Button
							onClick={handleStatusChange(APPROVED)}
							color="success"
							variant="contained"
							disabled={disableStatusChange}
						>
							{t('declarations.actions.approve')}
						</Button>
						<Button
							onClick={handleStatusChange(DECLINED)}
							color="secondary"
							variant="contained"
							disabled={disableStatusChange}
							sx={{ marginRight: 'auto' }}
						>
							{t('declarations.actions.decline')}
						</Button>
						<Button onClick={handleClose}>
							{t('general.close')}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default DeclarationModal;
