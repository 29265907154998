import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAllClientProperties } from '../../actions/clientActions';
import { getAllEmployeeProperties } from '../../actions/employeeActions';
import { getAllScopeProperties } from '../../actions/scopeDataActions';
import { useFetchData } from '../../helpers/hooks';
import Loader from '../util/loader/Loader';
import PropertySelector from './PropertySelector';

const PREFIX = 'PropertySelectorTable';

const classes = {
    th: `${PREFIX}-th`,
    disabled: `${PREFIX}-disabled`
};

const StyledTable = styled(Table)(({ theme }) => ({
    [`& .${classes.th}`]: {
		padding: theme.spacing(1, 0),
	},

    [`& .${classes.disabled}`]: {
		color: theme.palette.grey[500],
	}
}));

const labelText = (property, lang) => {
	if (property.options?.label?.[lang]) {
		return property.options?.label[lang];
	}
	return property.key;
};

const actions = {
	employee: {
		id: 'EmployeePropertiesDefinition',
		action: getAllEmployeeProperties,
	},
	client: {
		id: 'ClientPropertiesDefinition',
		action: getAllClientProperties,
	},
	scope: {
		id: 'ScopePropertiesDefinition',
		action: getAllScopeProperties,
	},
};

// TODO: change handlePropChange to a onChangeDirect, this removes the custom logic from the 2 components using this
// TODO: change handlePropAdd to use onChangeDirect and find the default value here
// TODO: change handlePropDelete to use onChangeDirect
function PropertySelectorTable({
	handlePropChange,
	handlePropAdd,
	handlePropDelete,
	properties,
	context,
}) {
	const { t, i18n } = useTranslation();
	const { data: propertyDefinitions, loading } = useFetchData(actions[context].id, actions[context].action);



	if (loading || !propertyDefinitions) {
		return <Loader />;
	}

	return (
        <StyledTable padding="none" size="small">
			<TableHead>
				<TableRow>
					<TableCell className={classes.th}>{t('properties.property')}</TableCell>
					<TableCell className={classes.th}>{t('properties.value')}</TableCell>
					<TableCell className={classes.th}>{t('properties.addOrDelete')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{propertyDefinitions?.length === 0 && (
					<TableRow>
						<TableCell colSpan={3}>
							<em>{t('scope.edit.noProperties')}</em>
						</TableCell>
					</TableRow>
				)}
				{propertyDefinitions?.map(property => {
					const disabled = properties?.[property.key] === undefined || properties?.[property.key] === null;
					return (
						<TableRow key={property.key}>
							<TableCell className={disabled ? classes.disabled : ''}>{labelText(property, i18n.language)}</TableCell>
							<TableCell>
								<PropertySelector
									property={property}
									newProps={properties || {}}
									handlePropChange={handlePropChange}
									disabled={disabled}
								/>
							</TableCell>
							<TableCell width={100} style={{ textAlign: 'center' }}>
								{ disabled
									? (
										<IconButton color="primary" onClick={handlePropAdd(property)}>
											<Icon>add_circle</Icon>
										</IconButton>
									)
									: (
										<IconButton color="secondary" onClick={handlePropDelete(property.key)}>
											<Icon>delete</Icon>
										</IconButton>
									)}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</StyledTable>
    );
}

PropertySelectorTable.propTypes = {
	handlePropAdd: PropTypes.func.isRequired,
	handlePropChange: PropTypes.func.isRequired,
	handlePropDelete: PropTypes.func.isRequired,
	properties: PropTypes.object,
	context: PropTypes.string.isRequired,
};

export default PropertySelectorTable;
