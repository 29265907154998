import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import withFetchData from '../../modules/fetchData/withFetchData';
import { CALL_API } from '../../setup/api';
import Loader from '../util/loader/Loader';
import ApiError from '../util/ApiError';

const ApiAction = (scopeId) => ({
	[CALL_API]: {
		type: 'CITIES',
		endpoint: `scope/${scopeId}/cities`,
	},
});

function LocationCitySelect({ value, onChange, loading, error, data }) {
	if (error) return (
		<ApiError error={error} />
	);

	if (!data || loading) return (
		<Loader inline />
	);

	return (
		<>
			{' from '}
			<Select
				value={value || ''}
				onChange={(e) => onChange(e.target.value)}
				displayEmpty
			>
				<MenuItem value="">COURSE SCOPE</MenuItem>
				{data.map(city => (
					<MenuItem key={city} value={city}>{city}</MenuItem>
				))}
			</Select>
		</>
	);
}

LocationCitySelect.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	scopeId: PropTypes.number.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

export default compose(
	withFetchData(({ scopeId }) => ApiAction(scopeId)),
)(LocationCitySelect);
