import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import { useTranslation } from 'react-i18next';
import withSubScopesNoLoading from '../../../modules/scope/withSubScopesNoLoading'
import { formatMoney } from '../../../helpers/formatter'
import { scopeStartsIn } from '../../../helpers/scope'
import { canCancel, isCancelledOrClosed } from '../../../helpers/groupState'
import { selfOrGroupsContainsSearch } from '../planner/ScopeAthenaStudiesCourse'
import GroupOccupancy from '../../util/GroupOccupancy'
import ScopeInvisibilityIndicator from '../../util/ScopeInvisibilityIndicator'
import { reduce } from 'ramda'

const PREFIX = 'ScopePickerCourseListCourse';

const classes = {
    course: `${PREFIX}-course`,
    handle: `${PREFIX}-handle`,
    handlePurple: `${PREFIX}-handlePurple`,
    handleTitle: `${PREFIX}-handleTitle`,
    handlePriceLabel: `${PREFIX}-handlePriceLabel`,
    groups: `${PREFIX}-groups`,
    noGroups: `${PREFIX}-noGroups`,
    table: `${PREFIX}-table`,
    row: `${PREFIX}-row`,
    iconCheckbox: `${PREFIX}-iconCheckbox`,
    iconCheckboxHover: `${PREFIX}-iconCheckboxHover`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.course}`]: {
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.handle}`]: {
		position: 'relative',
		border: `1px solid ${theme.palette.grey[300]}`,
		borderLeftWidth: 0,
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.common.white,
		'&:not(.selected).selectable:hover': {
			backgroundColor: theme.palette.primary.light,
			borderColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			cursor: 'pointer',
		},
		'&.selected': {
			backgroundColor: theme.palette.primary.light,
			borderColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			cursor: 'pointer',
            [`& .${classes.iconCheckbox}`]: {
				display: 'none',
			},
            [`& .${classes.iconCheckboxHover}`]: {
				display: 'inline-block',
			},
		},
	},

    [`& .${classes.handlePurple}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: `${parseInt(theme.spacing(1), 10) - 1}px`,
		backgroundColor: theme.palette.primary.light,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderTopLeftRadius: theme.shape.borderRadius,
	},

    [`& .${classes.handleTitle}`]: {
		display: 'block',
		padding: theme.spacing(1.5, 1),
		paddingLeft: theme.spacing(2),
	},

    [`& .${classes.handlePriceLabel}`]: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		borderRadius: '8px',
		padding: `0 6px`,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},

    [`& .${classes.groups}`]: {
		backgroundColor: theme.palette.common.white,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderBottomRightRadius: theme.shape.borderRadius,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		zIndex: -1,
		boxShadow: theme.shadows[1],
	},

    [`& .${classes.noGroups}`]: {
		display: 'block',
		padding: theme.spacing(1),
		fontSize: '0.75rem',
	},

    [`& .${classes.table}`]: {
		width: '100%',
	},

    [`& .${classes.row}`]: {
		'& td': {
			padding: theme.spacing(1),
		},
		'&:not(.disabled):hover': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			cursor: 'pointer',
            [`& .${classes.iconCheckbox}`]: {
				display: 'none',
			},
            [`& .${classes.iconCheckboxHover}`]: {
				display: 'inline-block',
			},
		},
		'&.disabled': {
			backgroundColor: theme.palette.grey[200],
		},
	},

    [`& .${classes.iconCheckbox}`]: {
		verticalAlign: -7,
		marginRight: theme.spacing(1),
	},

    [`& .${classes.iconCheckboxHover}`]: {
		verticalAlign: -7,
		marginRight: theme.spacing(1),
		display: 'none',
	}
}));

function allGroupsCancelledOrClosed(subScopes){
	if(!subScopes || subScopes.length === 0) return false;

	return reduce((acc, scope) =>
		acc + (isCancelledOrClosed(scope.state) ? 0 : 1),
		0, subScopes
	) === 0;
}

function canPick(group){
	return canCancel(group.state) && group.artificialStockStatus !== 'FULL' && group.stockStatus !== 'FULL';
}

const ScopePickerCourseListCourse = ({ scope: course, search, onSelectCourse, onSelectGroup, subScopes, multi, }) => {
	const { t } = useTranslation();
	if(allGroupsCancelledOrClosed(subScopes))
		return null;

	// TODO: add range check here?

	if(search && search.length > 0 && !selfOrGroupsContainsSearch(course, subScopes, search.toLowerCase()))
		return null;

	const handleSelectGroup = (group) => {
		if(!canPick(group)) return;

		onSelectGroup(group);
	};

	const handleSelectCourse = () => {
		if(!onSelectCourse) return;

		onSelectCourse(course);
	};

	const selected = multi && multi.indexOf(course.id) > -1;

	return (
        <Root className={classes.course}>
			<div className={`${classes.handle} ${onSelectCourse ? ' selectable' : ''} ${selected ? ' selected': ''}`}>
				<div className={classes.handlePurple} />
				<div className={classes.handleTitle} onClick={() => handleSelectCourse()}>
					{Boolean(onSelectCourse) && (
						<Fragment>
							<Icon className={classes.iconCheckbox}>check_box_outline_blank</Icon>
							<Icon className={classes.iconCheckboxHover}>check_box_outline</Icon>
						</Fragment>
					)}

					{course.name}
					<span className={classes.handlePriceLabel}>{formatMoney(course.price)}</span>
					<ScopeInvisibilityIndicator scope={course} right big />
				</div>
			</div>
			{Boolean(onSelectGroup) && (
				<div className={classes.groups}>
					{subScopes.length > 0 ? (
						<table className={classes.table}>
							<tbody>
							{subScopes.map(group => (
								<tr
									key={group.id}
									className={`${classes.row} ${canPick(group) ? '' : 'disabled'}`}
									onClick={() => handleSelectGroup(group)}
								>
									<td style={{ width: '35%' }}>
										{canPick(group) && (
											<Fragment>
												<Icon className={classes.iconCheckbox}>check_box_outline_blank</Icon>
												<Icon className={classes.iconCheckboxHover}>check_box_outline</Icon>
											</Fragment>
										)}
										{group.name}
									</td>
									<td style={{ width: '15%' }}>
										<GroupOccupancy group={group} showManipulated="full" />
									</td>
									<td style={{ width: '20%' }}>
										{scopeStartsIn(group)}
									</td>
									<td
										style={{ width: '30%' }}
										title={t(`groupStates.${group.state}.description`)}
									>
										{t(`groupStates.${group.state}.name`)}
										<ScopeInvisibilityIndicator scope={group} right />
									</td>
								</tr>
							))}
							</tbody>
						</table>
					) : (
						<div className={classes.noGroups}>
							<em>There are no groups in this course.</em>
						</div>
					)}
				</div>
			)}
		</Root>
    );
}

ScopePickerCourseListCourse.propTypes = {
	scope: PropTypes.object.isRequired,
	search: PropTypes.string,
	onSelectCourse: PropTypes.func,
	onSelectGroup: PropTypes.func,
};

export default (withSubScopesNoLoading(ScopePickerCourseListCourse))
