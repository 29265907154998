import { Schemas, CALL_API, checkActionInput } from './schemas'

export const PRODUCT = 'PRODUCT';

/**
 * This will prevent these actions from dispatching when accidentally clicked on devMode
 * THIS WILL PURPOSELY CAUSE AN ERROR
 * You can always comment out a devMode in order to test its functionality.
 *
 * @type {boolean}
 */
const devMode = process.env.NODE_ENV !== 'production' || process.env.NODE_ENV === 'development';

export const orderSwap = (data) => {
	if (devMode){
		console.log('Swapping disabled in development due to PayNL shit.', data);
		return null;
	}

	checkActionInput(data, ['productId', 'clientId', 'moveId']);

	return {
		[CALL_API]: {
			type: PRODUCT,
			endpoint: `product/${data.productId}/swap/${data.clientId}/${data.moveId}`,
			body: {}
		}
	}
};

export const orderEntryRefund = (data) => {
	if (devMode) return null

	checkActionInput(data, ['orderEntryId']);

	return {
		[CALL_API]: {
			type: PRODUCT,
			endpoint: `order-entry/${data.orderEntryId}/refund?quantity=1`,
			body: {}
		}
	}
};

export const orderRefund = (data) => {
	if (devMode) return null

	checkActionInput(data, ['orderId']);

	return {
		[CALL_API]: {
			type: PRODUCT,
			endpoint: `order/${data.orderId}/refund`,
			body: {}
		}
	}
};

export const orderApplyDiscountCode = (data) => {
	if (devMode) return null

	checkActionInput(data, ['orderEntryId', 'discountCodeId']);

	return {
		[CALL_API]: {
			type: PRODUCT,
			endpoint: `order-entry/${data.orderEntryId}/apply-discountcode/${data.discountCodeId}`,
			body: {}
		}
	}
};
