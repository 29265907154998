import { compose } from 'ramda';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogContentText } from '@mui/material';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withTranslation } from 'react-i18next';
import withFormData from '../../modules/formData/withFormData';
import { scopeHasAbbreviation, scopeParentsFromStoreFull } from '../../helpers/scope';
import { formatMoney } from '../../helpers/formatter';
import { orderSwap } from '../../actions/orderActions';
import PropertiesTable from '../util/PropertiesTable';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';
import ApiError from '../util/ApiError';

const PREFIX = 'OrderOptionsSwapConfirmModal';

const classes = {
    wrapper: `${PREFIX}-wrapper`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.wrapper}`]: {
		marginTop: theme.spacing(2),
	}
}));

class OrderOptionsSwapConfirmModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			checked: false,
		};
	}

	handleClose = () => {
		this.props.handleClose();
	};

	handleBack = () => {
		this.props.handleClose(true);
	};

	handleCheckbox = (e) => {
		this.setState({
			checked: e.target.checked,
		});
	};

	handleSwap = () => {
		const { client, fromGroup, toGroup, watchSubmit } = this.props;

		watchSubmit(orderSwap({
			productId: fromGroup.id,
			clientId: client.id,
			moveId: toGroup.id,
		}));
	};

	renderContent() {
		const { error, saving, success, t } = this.props;

		if (saving) return (
			<Loader />
		);

		if (success) return (
			<DialogContent>
				<Alert type={Alert.TYPE_SUCCESS}>
					{t('order.swap.success.alert')}
				</Alert>
				<DialogContentText>
					{t('order.swap.success.description')}
				</DialogContentText>
				{success.refund && (
					<p>
						<br />
						{t('order.swap.success.refund', {
							price: formatMoney(success.refund.totalAmount),
						})}
					</p>
				)}
			</DialogContent>
		);

		const { client, fromGroup, toGroup, } = this.props;
		const { checked } = this.state;

		const fromParents = scopeParentsFromStoreFull(fromGroup.id, 'Company');
		const fromCourse = fromParents[fromParents.length - 2];

		const toParents = scopeParentsFromStoreFull(toGroup.id, 'Company');
		const toCourse = toParents[toParents.length - 2];

		const properties = [
			{
				label: t('order.swap.properties.client'),
				content: `${client.fullName} / ${client.email}`,
			},
			{
				label: t('order.swap.properties.from'),
				content: fromParents.map(parent => scopeHasAbbreviation(parent) ? parent.abbreviation : parent.name).join(' > '),
			},
			{
				label: t('order.swap.properties.to'),
				content: toParents.map(parent => scopeHasAbbreviation(parent) ? parent.abbreviation : parent.name).join(' > '),
			},
			...(fromCourse.id === toCourse.id ? [] : [
				{ label: t('order.swap.properties.from_price'), content: formatMoney(fromCourse.price) },
				{ label: t('order.swap.properties.to_price'), content: formatMoney(toCourse.price) },
			]),
		];

		let swapError = null;
		const swapNotes = [];

		if (fromCourse.price?.amount < toCourse.price?.amount) {
			swapError = t('order.swap.error.new_price_higher');
		}

		if (fromGroup.id === toGroup.id) {
			swapError = t('order.swap.error.same_group');
		}

		if (fromCourse.price?.amount > toCourse.price?.amount) {
			swapNotes.push(t('order.swap.notes.new_price_lower'));
		}

		return (
			<DialogContent>
				<DialogContentText>
					{t('order.swap.description')}
				</DialogContentText>
				{error && (
					<ApiError error={error} />
				)}
				<PropertiesTable items={properties} />
				<div className={classes.wrapper}>
					{swapNotes.length > 0 && (
						<Alert>
							{swapNotes.map((note, i) => (
								<p key={i}>{note}</p>
							))}
						</Alert>
					)}
					{swapError ? (
						<Alert type={Alert.TYPE_WARNING}>
							{swapError}
						</Alert>
					) : (
						<Grid container>
							<Grid item xs={6}>
								<FormControlLabel
									control={(
										<Checkbox
											checked={checked}
											onChange={this.handleCheckbox}
											color="secondary"
										/>
									)}
									label={t('order.swap.form.check_to_confirm')}
								/>
							</Grid>
							<Grid item xs={6}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									disabled={!checked}
									onClick={this.handleSwap}
								>
									{t('order.swap.form.confirm')}
								</Button>
							</Grid>
						</Grid>
					)}
				</div>
			</DialogContent>
		);
	}

	render() {
		const { client, success, t } = this.props;

		return (
            <StyledDialog open={true} onClose={this.handleClose} maxWidth="md" fullWidth>
				<DialogTitle>
					{t('order.swap.title', {
						name: client.fullName,
					})}
				</DialogTitle>
				{this.renderContent()}
				<DialogActions>
					{!success && (
						<Button onClick={this.handleBack} color="secondary">
							{t('order.swap.back')}
						</Button>
					)}
					<Button onClick={this.handleClose} color="secondary">
						{t('order.swap.close')}
					</Button>
				</DialogActions>
			</StyledDialog>
        );
	}
}

OrderOptionsSwapConfirmModal.propTypes = {
	handleClose: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
	fromGroup: PropTypes.object.isRequired,
	toGroup: PropTypes.object.isRequired,
};

export default compose(
	withTranslation(),

	withFormData(),
)(OrderOptionsSwapConfirmModal);
