import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { uniq, concat, without, forEachObjIndexed } from 'ramda';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import withFetchData from '../../modules/fetchData/withFetchData';
import { orderEntryList } from '../../actions/feedActions';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';
import LiveMainFeedItem from './LiveMainFeedItem';
import { liveDebugMode } from './liveUtils';

import jingleBell from './media/jingle-bell.mp3';
import soundDuitsteLeraarOnvoldoende from './media/sound-duitste-leraar-onvoldoende.mp3';
import soundWhatNoMoney from './media/sound-what-no-money.mp3';
import soundRustaaagh from './media/sound-rustaaagh.mp3';
import soundHeerlijkHeerlijkHeerlijk from './media/sound-heerlijk-heerlijk-heerlijk.mp3';

const PREFIX = 'LiveMainFeed';

const classes = {
    root: `${PREFIX}-root`,
    bottomGradient: `${PREFIX}-bottomGradient`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		position: 'relative',
		height: 'calc(100vh - 20px)',
		marginRight: theme.spacing(3),
		overflowY: 'auto',
		scrollbarWidth: 'none',
		'-ms-overflow-style': 'none',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},

    [`& .${classes.bottomGradient}`]: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: 150,
		backgroundImage: `linear-gradient(to bottom,
			rgba(0,0,0,0),
			rgba(94,33,99,0.05) 5%,
			rgba(94,33,99,0.8) 40%,
			rgba(94,33,99,1) 75%
		)`,
	}
}));

let hasHad = [];
let addOne = 0;

const LiveMainFeed = ({ error, data }) => {
	const location = useLocation();
	const soundDisabled = location.search.indexOf('soundDisabled') > -1;

	if (liveDebugMode) {
		if (addOne && data) {
			for (let i = 0; i < addOne; i += 1) {
				// eslint-disable-next-line no-param-reassign
				data = [{
					...data[0],
					orderEntryId: 10000000 + i,
					orderDateTime: moment().subtract((addOne - i - 1) * 30, 'seconds').toISOString(),
				}, ...data];
			}
		}

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			const interval = setInterval(() => {
				addOne += 1;
			}, 7000);
			return () => clearInterval(interval);
		}, []);
	}

	// Load the sounds on mount
	const sounds = useRef({});
	useEffect(() => {
        if (soundDisabled) return;

		sounds.current = {
			bell: new Audio(jingleBell),
			onvoldoende: new Audio(soundDuitsteLeraarOnvoldoende),
			noMoney: new Audio(soundWhatNoMoney),
			rustaagh: new Audio(soundRustaaagh),
			heerlijk: new Audio(soundHeerlijkHeerlijkHeerlijk),
		};

		forEachObjIndexed((sound) => {
			// eslint-disable-next-line no-param-reassign
			sound.volume = 1;
		}, sounds.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const playSound = (name) => sounds?.current?.[name]?.play?.();

	useEffect(() => {
		if (!data) return;

		const idList = data.map(item => item.orderEntryId);
		const newIds = without(hasHad, idList);

		// Check if there is a new order
		if (hasHad.length > 0 && newIds.length > 0) {
			const first = data.find(item => item.orderEntryId === newIds[0]);

			if (first && !soundDisabled) {
				if (first.discountPercentage === 1) {
					playSound('noMoney');
				} else if (first.paidPrice.amount === 0) {
					playSound('onvoldoende');
				} else if (first.paidPrice.amount >= 100) {
					playSound('rustaagh');
				} else {
					playSound('heerlijk');
				}
			}
		}

		hasHad = uniq(concat(hasHad, idList));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	if (error) return (
		<ApiError error={error} />
	);

	if (!data) return (
		<Loader />
	);

	return (
        <Root className={classes.root}>
			{data.length > 0 ? data.map((item, i) => (
				<LiveMainFeedItem
					key={item.orderEntryId}
					item={item}
					isNew={hasHad.length > 0 && !hasHad.includes(item.orderEntryId)}
					place={i + 1}
				/>
			)) : (
				<em>No feed information found...</em>
			)}
		</Root>
    );
};

LiveMainFeed.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	rootScopeId: PropTypes.number.isRequired,
};

export default (withFetchData((props) => orderEntryList(props.rootScopeId), {
	autoRefreshSeconds: liveDebugMode ? 10 : 30,
})(LiveMainFeed));
