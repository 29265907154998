import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import signedContractASSquare from './SignedContractAthenaStudiesSquare.gif';

const PREFIX = 'ApplicationFormFinished';

const classes = {
    root: `${PREFIX}-root`,
    image: `${PREFIX}-image`,
    text: `${PREFIX}-text`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		padding: theme.spacing(2),
		textAlign: 'center',
		'& a': {
			color: theme.palette.primary.contrastText,
		},
	},

    [`& .${classes.image}`]: {
		borderRadius: '50%',
		border: `4px solid ${theme.palette.primary.light}`,
	},

    [`& .${classes.text}`]: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(3),
		fontSize: '1.2em',
	}
}));

const ApplicationFormFinished = ({ application, companyName, }) => {
	const { t } = useTranslation();

	return (
		<Root className={classes.root}>
			<img src={signedContractASSquare} alt="Signed!" className={classes.image} />

			<Typography paragraph variant="body1" className={classes.text}>
				<strong>{t('application.signForm.finished.youSigned', { companyName })}</strong>
				<br />
				{t('application.signForm.finished.showUs')}
			</Typography>

			<Button variant="contained" color="primary" size="large" component="a" href="/portal">
				{t('application.signForm.finished.goToPortal')}
			</Button>
		</Root>
    );
};

ApplicationFormFinished.propTypes = {
	application: PropTypes.object.isRequired,
	companyName: PropTypes.string.isRequired,
};

export default (ApplicationFormFinished);
