import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import { capabilities, hasScopeCapability } from '../../../helpers/capabilities';
import withSubScopesNoLoading from '../../../modules/scope/withSubScopesNoLoading'
import withWorkingScope from '../../../modules/scope/withWorkingScope';
import ScopeFaculty from './ScopeFaculty'
import PlannerHeader from './PlannerHeader'

const ScopeInstitute = ({ scope, topLevelScope, subScopes, filter }) => {
	const parentList = [scope];
	if (topLevelScope && hasScopeCapability(topLevelScope, capabilities.SeeScopeCapability)) {
		parentList.unshift(topLevelScope);
	}

	if(!subScopes || subScopes.length === 0) return (
		<div>
			<PlannerHeader scopes={parentList} />
			<p style={{margin: '10px 0 40px 0'}}>
				<em>No faculties for this institute.</em>
			</p>
		</div>
	)

	return (
		<div>
			{subScopes && subScopes.map(scope => {
				if(scope._class !== 'Faculty') return null;

				return (
					<ScopeFaculty key={scope.id}
								  scope={scope}
								  parentList={parentList}
								  filter={filter} />
				);
			})}
		</div>
	);
}

ScopeInstitute.propTypes = {
	scope: PropTypes.object.isRequired,
	topLevelScope: PropTypes.object.isRequired,
	filter: PropTypes.object,
}

export default compose(
	withWorkingScope,
	withSubScopesNoLoading
)(ScopeInstitute)
