import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import { patchClientProfile } from '../../../actions/clientActions'
import withDialogShouldBeFullscreen from '../../../hoc/withDialogShouldBeFullscreen'
import AddressForm from '../../util/AddressForm'
import Alert from '../../util/alert/Alert'

const GetApiAction = (id) => (address) => {
	return patchClientProfile(id, {
		address: address,
	});
};

class ClientEditAddressModal extends Component {
	saveBinder = null;

	render(){
		const { client, handleClose, fullScreen } = this.props;

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth fullScreen={fullScreen}>
				<DialogTitle>Edit {client.name}'s address</DialogTitle>
				<DialogContent>
					<AddressForm
						inputData={client}
						action={GetApiAction(client.id)}
						bindSave={(func) => this.saveBinder = func}
						onSaved={handleClose}
					/>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Alert>Changing the customers address here does not change their postal address on previous orders. If you want to change the postal address for a specific order and client, go to the occupancy list of the group.</Alert>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button
						onClick={() => {if(this.saveBinder) this.saveBinder()}}
						color="primary"
						variant="contained"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

ClientEditAddressModal.propTypes = {
	client: PropTypes.object.isRequired
};

export default withDialogShouldBeFullscreen()(ClientEditAddressModal)
