import { clone, concat, slice, uniq, values } from 'ramda'
import { getApiTypeSuccess } from '../../helpers/types'
import { AUTH_LOGOUT } from '../auth/authActions'
import { ROOT, TOP_LEVEL_SCOPE, WORKING_SCOPE } from './scopeActions'
import { clearSavedWorkingScopes, saveWorkingScopes } from './scopeUtil'

const defaultRootScopesState = {
	// Everything returned from the root-scopes endpoint
	rootScopes: [],
	rootCapabilities: [],

	// Calculated top level scopes and the one selected
	topLevelScopes: [],
	topLevelScope: null,

	// The active working scopes
	workingScopes: [],

	// Loaded state for loader logic
	loaded: false,
};

function scopeReducerFinal(state = defaultRootScopesState, action){
	if(action.type === AUTH_LOGOUT){
		clearSavedWorkingScopes();

		return clone(defaultRootScopesState);
	}

	// Successful root scopes fetch
	if(action.type === getApiTypeSuccess(ROOT) && action.response.entities){
		const result = action.response.result;
		const scopes = action.response.entities.scopes;
		let rootCapabilities = [];
		let topLevelScopes = [];

		result.map(id => {
			// Collect capabilities
			rootCapabilities = concat(rootCapabilities, scopes[id].capabilities);

			// Collect the top level scopes
			topLevelScopes.push(getTopLevelScope(id, scopes));
		});

		const uniqTopLevelScopes = uniq(topLevelScopes);
		const topLevelScope = uniqTopLevelScopes.length === 1 ? uniqTopLevelScopes[0] : null;

		// With one rootScope, set it directly as working scope
		const workingScopes = topLevelScope ? [topLevelScope] : [];

		return {
			...state,
			rootScopes: result,
			rootCapabilities: uniq(rootCapabilities),

			topLevelScopes: uniqTopLevelScopes,
			topLevelScope: topLevelScope,

			workingScopes: autoSetWorkingScopes(workingScopes, scopes),

			loaded: true,
		};
	}

	// Top level scope actions
	if(action.type === TOP_LEVEL_SCOPE){
		// Set one and reset working scopes
		if(action.set){
			const newList = autoSetWorkingScopes([action.set], action.scopes);

			saveWorkingScopes(newList);

			return {
				...state,
				topLevelScope: action.set,
				workingScopes: newList,
			};
		}

		if(action.clear){
			saveWorkingScopes([]);

			return {
				...state,
				topLevelScope: null,
				workingScopes: [],
			};
		}
	}

	// Working scope actions
	if(action.type === WORKING_SCOPE){
		// Set one at position
		if(action.set){
			const newList = concat(
				slice(0, action.position, state.workingScopes),
				[action.set]
			);

			saveWorkingScopes(newList);

			return {
				...state,
				workingScopes: newList,
			};
		}

		// Clear from position
		if(action.clear){
			const newList = slice(0, action.clear, state.workingScopes);

			saveWorkingScopes(newList);

			return {
				...state,
				workingScopes: newList,
			};
		}

		// Set the whole working scopes list
		if(action.setList){
			saveWorkingScopes(action.setList);

			return {
				...state,
				topLevelScope: action.setList[0],
				workingScopes: action.setList,
			}
		}
	}

	return state;
}

const scopeReducer = {
	root: scopeReducerFinal,
};

export default scopeReducer


// ====================
// Internal helpers
// ====================

function getTopLevelScope(id, scopes){
	if(scopes[id].parent){
		return getTopLevelScope(scopes[id].parent, scopes);
	}else{
		return id;
	}
}

function autoSetWorkingScopes(workingScopes, scopes){
	if(workingScopes.length === 0) return workingScopes;

	const current = scopes[workingScopes[workingScopes.length - 1]];

	// Check if data for the current could be found, otherwise do nothing
	if(!current) return workingScopes;

	// Regular scope (there are subScopes, thus must be under a root)
	if(current.subScopes){
		// Only one subScope, so set it
		if(current.subScopes.length === 1){
			return autoSetWorkingScopes(concat(workingScopes, [current.subScopes[0]]), scopes);
		}

		// More subScopes, do nothing
		else {
			return workingScopes;
		}
	}

	// Maybe not loaded, maybe not under a root
	// In case of virtual it is not under a root
	else if(current._virtual){
		// Find sub scopes through parents
		const subs = values(scopes).filter(
			s => s.parent === current.id
		)

		// If one, we can auto set
		if(subs.length === 1){
			return autoSetWorkingScopes(concat(workingScopes, [subs[0].id]), scopes);
		}

		// More subs, do nothing
		return workingScopes;
	}

	// Regular scope and not loaded, do nothing
	return workingScopes;
}
