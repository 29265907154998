import Box from '@mui/material/Box';
import React, { PureComponent, Fragment } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import withFetchData from '../../../modules/fetchData/withFetchData'
import Alert from '../../util/alert/Alert'
import ScopeInstitute from './ScopeInstitute'
import ScopeFaculty from './ScopeFaculty'
import ScopeCourse from './ScopeCourse'
import ScopeAthenaStudiesCourse from './ScopeAthenaStudiesCourse'
import { getScopesTillGroups } from '../../../modules/scope/scopeActions'
import { scopeFilterReducerKey } from '../../../modules/scopeFilter/scopeFilterReducer'
import Loader from '../../util/loader/Loader'

const scopeClasses = {
	'Institute': ScopeInstitute,
	'Faculty': ScopeFaculty,
	'Course': ScopeCourse,
	'AthenaStudiesCourse': ScopeAthenaStudiesCourse
}

class PlannerForScope extends PureComponent {
	render(){
		const { scope, loading, error, filter, } = this.props;

		if(!scope || !scopeClasses[scope._class]) return (
			<Alert type={Alert.TYPE_INFO}>
				Selected scope cannot be displayed, please navigate to another scope in the scope selector at the top of the page.
			</Alert>
		);

		const ScopeComponent = scopeClasses[scope._class];
		return (
            <>
				{loading && (
					<Loader sheet={Boolean(scope._groups)} />
				)}
				{error && (
					<Alert type={Alert.TYPE_WARNING}>{error.error}</Alert>
				)}
				<Box sx={{ px: { md: 1 }, py: { md: 0 } }}>
					{Boolean(scope._groups) && (
						<ScopeComponent
							scope={scope}
							filter={filter}
						/>
					)}
				</Box>
			</>
        );
	}

	componentDidMount(){
		const { scope, load } = this.props;

		if(scopeClasses[scope._class] && !scope._groups){
			load(getScopesTillGroups(scope.id));
		}
	}

	componentDidUpdate(prevProps){
		const { scope, load } = this.props;

		if(scope.id !== prevProps.scope.id && scopeClasses[scope._class] && !scope._groups){
			load(getScopesTillGroups(scope.id));
		}
	}
}

PlannerForScope.propTypes = {
	scope: PropTypes.object.isRequired,
}

export default (withFetchData(null, {
	mapStateToProps: (state) => ({
		filter: state[scopeFilterReducerKey],
	}),
})(PlannerForScope))
