import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { withTranslation } from 'react-i18next';
import withFetchData from '../../../modules/fetchData/withFetchData'
import { getScopeApplications } from '../../../actions/scopeDataActions'
import { sortByTime } from '../../../helpers/sorts'
import { capabilities } from '../../../helpers/capabilities'
import { niceDateTime } from '../../../helpers/datetime'
import Loader from '../../util/loader/Loader'
import FetchDataSwitch from '../../util/FetchDataSwitch'

const PREFIX = 'CourseBookletsApplications';

const classes = {
    error: `${PREFIX}-error`
};

const StyledFetchDataSwitch = styled(FetchDataSwitch)(({ theme }) => ({
    [`& .${classes.error}`]: {
		backgroundColor: theme.palette.error.main,
		color: 'white',
	}
}));

const propTypes = {
	course: PropTypes.object.isRequired,
	bindRefresh: PropTypes.func,
}

const handleData = data => data
.filter(application => application.applyingAs.capabilities.includes(capabilities.WriteBookletCapability) || application.status === 'ACCEPTED')
.sort(sortByTime('createDateTime'))
.reverse()

class CourseBookletsApplications extends Component {

	componentDidMount() {
		this.props.bindRefresh && this.props.bindRefresh(this.props.refresh)
	}

	render() {
		const { loading, error, data,  t } = this.props

		return (
            <StyledFetchDataSwitch
				{...{ loading, error, data }}
				ifLoading={(
					<TableRow>
						<TableCell>
							<Loader/>
						</TableCell>
					</TableRow>
				)}
				ifError={(
					<TableRow>
						<TableCell colSpan={2} className={classes.error}>
							{error && error.error}
						</TableCell>
					</TableRow>
				)}
				applyFilter={application => application.applyingAs.capabilities.includes(capabilities.WriteBookletCapability)}
				ifEmptyData={(
					<TableRow>
						<TableCell colSpan={2}>
							<em>{t('scope.course.booklets.noBookletApplications')}</em>
						</TableCell>
					</TableRow>
				)}
			>
				<TableRow>
					<TableCell colSpan={2} variant="head">
						<em>{t('scope.course.booklets.openBookletApplications')}</em>
					</TableCell>
				</TableRow>
				{data && handleData(data).map(application => (
					<TableRow key={application.id}>
						<TableCell>
							<Grid container direction={'column'}>
								<Grid item><strong>{t('scope.course.booklets.booklet')}:</strong></Grid>
								<Grid item>{t('scope.course.booklets.bookletWriter')}:</Grid>
								<Grid item>{t('scope.course.booklets.status')}:</Grid>
								<Grid item>{t('scope.course.booklets.expires')}:</Grid>
							</Grid>
						</TableCell>
						<TableCell>
							<Grid container direction={'column'}>
								<Grid item><strong>{application.applyingIn.name}</strong></Grid>
								<Grid item>{application.fullName}</Grid>
								<Grid item>{application.status}</Grid>
								<Grid item>{niceDateTime(application.expires)}</Grid>
							</Grid>
						</TableCell>
					</TableRow>
				))}
			</StyledFetchDataSwitch>
        );
	}
}

CourseBookletsApplications.propTypes = propTypes

const WithFetchData = withFetchData(props => getScopeApplications(props.course.id))(CourseBookletsApplications)
export default withTranslation()((WithFetchData))
