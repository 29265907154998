import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { MobileDatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogActions, DialogContent, MenuItem, Select, TableCell, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { compose } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
	deleteContractTemplate,
	postContractTemplate,
	putContractTemplate,
} from '../../../../actions/functionsActions';
import ContractTemplateStatuses from '../../../../constants/ContractTemplateStatuses';
import i18nServices from '../../../../helpers/i18nServices';
import withFetchData from '../../../../modules/fetchData/withFetchData';
import withFormData from '../../../../modules/formData/withFormData';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import { downloadFromApi } from '../../../../setup/api';
import Alert from '../../../util/alert/Alert';
import ApiError from '../../../util/ApiError';
import FileDropZone from '../../../util/FileDropZone';
import Loader from '../../../util/loader/Loader';
import CompanyFunctionDocumentValidationWarnings from './CompanyFunctionDocumentValidationWarnings';
import EditFunctionTemplateStatusChip from './EditFunctionTemplateStatusChip';

const PREFIX = 'EditFunctionContractTemplateModal';

const classes = {
    template: `${PREFIX}-template`,
    alert: `${PREFIX}-alert`,
    vertAlignCenter: `${PREFIX}-vertAlignCenter`,
    documentHeader: `${PREFIX}-documentHeader`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.template}`]: {
		padding: theme.spacing(2),
	},

    [`& .${classes.alert}`]: {
		marginTop: theme.spacing(2),
	},

    [`& .${classes.vertAlignCenter}`]: {
		display: 'inline-flex',
		alignItems: 'center',
		'& .MuiIcon-root': {
			marginLeft: '0.5rem',
		},
	},

    [`& .${classes.documentHeader}`]: {
		display: 'flex',
		marginBottom: '16px',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
	}
}));

const { NEW, VALIDATED, MISSING_REQUIRED_PROPERTIES_IN_DOCUMENT } = ContractTemplateStatuses;

function EditFunctionContractTemplatesModal({ handleClose, modal, template, watchSubmit, topLevelScope, saving, error, success }) {

	const { t } = useTranslation();
	const {
		validityStartDate: initialStartDate,
		validityEndDate: initialEndDate,
		name: initialName,
		description: initialDesc,
		locale: initialLocale,
	} = template || {};
	const [documentFromDisk, setDocumentFromDisk] = useState(undefined);
	const [newDocument, toggleNewDocument] = useState(false);
	const [validityStartDate, setValidityStartDate] = useState(initialStartDate || null);
	const [validityEndDate, setValidityEndDate] = useState(initialEndDate || null);
	const [locale, setLocale] = useState(initialLocale || null);
	const [name, setName] = useState(initialName || t('manage.company.functions.editContractTemplatesModal.newContractTemplate'));
	const [description, setDescription] = useState(initialDesc || null);

	useEffect(() => {
		if (!locale && topLevelScope?.locale) {
			setLocale(topLevelScope?.locale.substring(0, 2));
		}
	}, [locale, topLevelScope]);

	const isDirty = useMemo(() => {
		if (!template) return true;
		return (
			template.name !== name
			|| template.description !== description
			|| template.validityStartDate !== validityStartDate
			|| template.validityEndDate !== validityEndDate
			|| template.locale !== locale
		);
	}, [description, locale, name, template, validityEndDate, validityStartDate]);

	const handleValidityEndDate = (date) => {
		const newDate = date?.endOf('day').format() || null;
		setValidityEndDate(newDate);
	};

	const handleValidityStartDate = (date) => {
		const newDate = date?.format() || null;
		setValidityStartDate(newDate);
	};

	const handleLocaleChange = (event) => {
		setLocale(event.target.value);
	};

	const handleAcceptedFiles = (files) => {
		setDocumentFromDisk(files.length ? files[0] : undefined);
	};

	const handleCancel = () => {
		setDocumentFromDisk(undefined);
		toggleNewDocument(false);
	};

	const handleDelete = () => {
		if (window.confirm(t('manage.company.functions.editContractTemplatesModal.confirm'))) {
			watchSubmit(deleteContractTemplate(template.id));
			handleClose();
		}
	};

	const handleSetDescription = event => {
		const { value } = event.target;
		setDescription(value.length > 0 ? value : null);
	};

	const handleSave = (e) => {
		e.preventDefault();
		window.scrollTo(0, 0);
		const data = { name, locale };
		if (documentFromDisk) data.templateDocument = documentFromDisk;
		if (validityStartDate) data.validityStartDate = validityStartDate;
		if (validityEndDate) data.validityEndDate = validityEndDate;

		if (
			!documentFromDisk
			&& (!template || template.validationStatus === NEW)
			&& !window.confirm(t('manage.company.functions.editContractTemplatesModal.missingWarning'))
		) {
			return;
		}

		if (template?.id) {
			watchSubmit(putContractTemplate(template.id, data));
		} else {
			watchSubmit(postContractTemplate(modal.functionId, data));
		}
		toggleNewDocument(false);
		setDocumentFromDisk(undefined);
	};

	return (
        <StyledDialog open={true} onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle>
				{t('manage.company.functions.editContractTemplatesModal.title')}
			</DialogTitle>
			<DialogContent>
				{error && (<ApiError error={error} showDetails />)}
				{Number.isInteger(success) && (
					<Alert type={Alert.TYPE_SUCCESS}>
						{t('manage.company.functions.editContractTemplatesModal.saved')}
					</Alert>
				)}
				<Table className={classes.template}>
					<TableBody>
						<TableRow>
							<TableCell style={{ width: '20%' }}>
								<span className={classes.vertAlignCenter}>
									{t('manage.company.functions.editContractTemplatesModal.name')}
									<Tooltip title={t('manage.company.functions.editContractTemplatesModal.nameTooltip')} placement="top">
										<Icon>info_circle</Icon>
									</Tooltip>
								</span>
							</TableCell>
							<TableCell colSpan={3}>
								<TextField value={name} onChange={event => setName(event.target.value)} fullWidth />
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{ width: '20%' }}>{t('manage.company.functions.editContractTemplatesModal.description')}</TableCell>
							<TableCell colSpan={3}>
								<TextField value={description || ''} onChange={handleSetDescription} fullWidth />
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{ width: '20%' }}>
								<span className={classes.vertAlignCenter}>
									{t('manage.company.functions.editContractTemplatesModal.validityStartDate')}
									<Tooltip
										title={t('manage.company.functions.editContractTemplatesModal.validityStartDateTooltip')}
										placement="top"
									>
										<Icon>info_circle</Icon>
									</Tooltip>
								</span>
							</TableCell>
							<TableCell style={{ minWidth: '11rem' }}>
								<MobileDatePicker
									value={validityStartDate || null}
									onChange={handleValidityStartDate}
									animateYearScrolling={false}
									okLabel="Pick"
									fullWidth
									inputFormat="dd D MMMM YYYY"
									showToolbar={false}
									closeOnSelect
									componentsProps={{
										actionBar: {
											// The actions will be the same between desktop and mobile
											actions: ['clear', 'cancel'],
										},
									}}
									renderInput={(props) => (
										<TextField
											{...props}
											label={t('general.date')}
										/>
									)}
								/>
							</TableCell>
							<TableCell style={{ width: '20%' }}>
								<span className={classes.vertAlignCenter}>
									{t('manage.company.functions.editContractTemplatesModal.validityEndDate')}
									<Tooltip
										title={t('manage.company.functions.editContractTemplatesModal.validityEndDateTooltip')}
										placement="top"
									>
										<Icon>info_circle</Icon>
									</Tooltip>
								</span>
							</TableCell>
							<TableCell style={{ minWidth: '11rem' }}>
								<MobileDatePicker
									value={validityEndDate || null}
									onChange={handleValidityEndDate}
									animateYearScrolling={false}
									okLabel="Pick"
									minDate={validityStartDate || undefined}
									inputFormat="dd D MMMM YYYY"
									showToolbar={false}
									componentsProps={{
										actionBar: {
											// The actions will be the same between desktop and mobile
											actions: ['clear', 'accept', 'cancel'],
										},
									}}
									closeOnSelect
									renderInput={(props) => (
										<TextField
											{...props}
											label={t('general.date')}
											fullWidth
										/>
									)}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								{t('manage.company.functions.editContractTemplatesModal.locale')}
							</TableCell>
							<TableCell colSpan={3}>
								<Select
									value={locale || 'en'}
									onChange={handleLocaleChange}
									autoWidth
								>
									{
										i18nServices.locales.map(lng => (
											<MenuItem value={lng.value} key={lng.value}>
												{lng.label}
											</MenuItem>
										))
									}
								</Select>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								{t('manage.company.functions.editContractTemplatesModal.contractTemplate')}
							</TableCell>
							<TableCell colSpan={3}>
								{saving && <Loader sheet />}
								{newDocument && (
									<>
										<FileDropZone
											maxFiles={1}
											accept=".docx"
											handleAcceptedFiles={handleAcceptedFiles}
										/>
										<Button
											onClick={handleCancel}
											variant="outlined"
										>
											{t('general.cancel')}
										</Button>
									</>
								)}
								{!newDocument && !saving && (
									<>
										{(!template || template.validationStatus === NEW) && (
											<Alert type={Alert.TYPE_INFO} icon="info_circle">
												{t('manage.company.functions.editContractTemplatesModal.validateAndPopulateDocument')}
											</Alert>
										)}
										{template && template.validationStatus !== NEW && (
											<>
												<div className={classes.documentHeader}>
													<div>
														<Button
															onClick={() => downloadFromApi(
																`contract-template/${template.id}/document`,
																`${template.name}.docx`,
															)}
															variant="contained"
															color="primary"
														>
															{t('manage.company.functions.editContractTemplatesModal.downloadDocument')}
														</Button>
														<EditFunctionTemplateStatusChip template={template} />
													</div>
													{template.validationStatus !== NEW && (
														<Tooltip
															title={(
																<Trans
																	i18nKey="manage.company.functions.editContractTemplatesModal.exampleTooltip"
																	values={{ functionName: template.function.name }}
																>
																	Download an example template document specific to current function
																	{' "'}
																	<i>{template.function.name}</i>
																	{'" '}
																	and your own employee details.
																</Trans>
															)}

														>
															<Button
																onClick={() => downloadFromApi(
																	`contract-template/${template.id}/example-contract`,
																	`${
																		t('manage.company.functions.editContractTemplatesModal.exampleFileName')
																	}.docx`,
																)}
																variant="contained"
																color="default"
															>
																{t('manage.company.functions.editContractTemplatesModal.downloadExampleContract')}
															</Button>
														</Tooltip>
													)}
												</div>
												<CompanyFunctionDocumentValidationWarnings template={template} />
												<Divider style={{ margin: '1rem 0' }} />
											</>
										)}
										<Button
											onClick={() => toggleNewDocument(true)}
											variant="outlined"
										>
											{t('manage.company.functions.editContractTemplatesModal.newDocument')}
										</Button>
									</>
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				{template?.id && (
					<Button
						onClick={handleDelete}
						style={{ marginRight: 'auto' }}
						variant="contained"
						color="secondary"
					>
						{t('general.delete')}
					</Button>
				)}
				<Button onClick={handleClose}>{t('general.close')}</Button>
				<Button
					onClick={handleSave}
					variant="contained"
					color="primary"
					disabled={!(isDirty || documentFromDisk)}
					endIcon={saving && (
						<CircularProgress size={16} style={{ color: 'white' }} />
					)}
				>
					{documentFromDisk
						? t('manage.company.functions.editContractTemplatesModal.validateAndSave')
						: t('general.save')}
				</Button>
			</DialogActions>
		</StyledDialog>
    );
}

EditFunctionContractTemplatesModal.propTypes = {
	template: PropTypes.object,
};

export default compose(
	withFormData({
		customId: () => 'ContractTemplateForm',
	}),
	withFetchData(null, {
		mapStateToProps: (state, props) => {
			const { modal, success } = props;
			const id = (success && Number.isInteger(success)) ? success : modal.templateId;
			return {
				template: state.entities.contractTemplates[id],
			};
		},
	}),
)(withWorkingScope(EditFunctionContractTemplatesModal));
