import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Select from 'react-select'
import { emphasize } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import ListItemText from '@mui/material/ListItemText'

const PREFIX = 'ScopeSelect';

const classes = {
    control: `${PREFIX}-control`,
    inputBase: `${PREFIX}-inputBase`,
    input: `${PREFIX}-input`,
    valueContainer: `${PREFIX}-valueContainer`,
    chip: `${PREFIX}-chip`,
    chipFocused: `${PREFIX}-chipFocused`,
    noOptionsMessage: `${PREFIX}-noOptionsMessage`,
    option: `${PREFIX}-option`,
    optionSecondary: `${PREFIX}-optionSecondary`,
    singleValue: `${PREFIX}-singleValue`,
    singleValueName: `${PREFIX}-singleValueName`,
    singleValueFunctions: `${PREFIX}-singleValueFunctions`,
    placeholder: `${PREFIX}-placeholder`,
    paper: `${PREFIX}-paper`
};

const StyledSelect = styled(Select)(({ theme }) => ({
    [`& .${classes.control}`]: {
		...theme.mixins.toolbar,
		'&:after': {
			content: '""',
			position: 'absolute',
			right: 0,
			top: 0,
			bottom: 1,
			borderRight: `1px solid ${theme.palette.divider}`,
		}
	},

    [`& .${classes.inputBase}`]: {
		flexGrow: 1,
	},

    [`& .${classes.input}`]: {
		display: 'flex',
		padding: 0,
		marginLeft: `${parseInt(theme.spacing(2), 10) - 2}px`, // Somehow a component with margin 2 is injected..
		alignItems: 'center',
		'& > div > div': {
			...(theme.palette.type === 'dark' ? {
				color: theme.palette.primary.contrastText,
			} : {}),
		},
		'& > div:first-of-type': {
			...theme.mixins.toolbar,
		},
	},

    [`& .${classes.valueContainer}`]: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
		'& > div:last-child': {
			flex: 0,
		},
	},

    [`& .${classes.chip}`]: {
		margin: theme.spacing(0.5, 0.25),
	},

    [`& .${classes.chipFocused}`]: {
		backgroundColor: emphasize(
			theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08,
		),
	},

    [`& .${classes.noOptionsMessage}`]: {
		padding: theme.spacing(1, 2),
	},

    [`& .${classes.option}`]: {
		height: 'auto',
		padding: theme.spacing(1, 2),
		whiteSpace: 'normal',
		[theme.breakpoints.down('xl')]: {
			padding: theme.spacing(1),
		},
	},

    [`& .${classes.optionSecondary}`]: {
		fontStyle: 'italic',
	},

    [`& .${classes.singleValue}`]: {
		textAlign: 'center',
		margin: '0 auto',
	},

    [`& .${classes.singleValueName}`]: {
		fontSize: 16,
		fontWeight: 700,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		padding: `1px ${theme.spacing(1.2)}`,
		borderRadius: 15
	},

    [`& .${classes.singleValueFunctions}`]: {
		display: 'block',
		fontSize: 11,
		fontStyle: 'italic',
		color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
	},

    [`& .${classes.placeholder}`]: {
		position: 'absolute',
		left: theme.spacing(2),
		fontSize: 14,
	},

    [`& .${classes.paper}`]: {
		position: 'absolute',
		zIndex: 1,
		left: 0,
		right: 0,
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.primary.dark : undefined,
	}
}));

const NoOptionsMessage = ({ selectProps, innerProps, children }) => {
	return (
		<Typography
			color="textSecondary"
			className={selectProps.classes.noOptionsMessage}
			{...innerProps}
		>
			{children}
		</Typography>
	);
};

const InputComponent = ({ forwardedRef, ...props }) => {
	return (
		<div ref={forwardedRef} {...props} />
	);
};

const inputComponent = React.forwardRef((props, ref) => <InputComponent {...props} forwardedRef={ref} />);

const Control = ({ selectProps, children, innerProps }) => {
	return (
		<TextField
			className={selectProps.classes.control}
			fullWidth
			InputProps={{
				inputComponent,
				className: selectProps.classes.inputBase,
				inputProps: {
					className: selectProps.classes.input,
					children: children,
					...innerProps,
				},
			}}
			{...selectProps.textFieldProps}
		/>
	);
};

const Option = ({ selectProps, innerRef, isFocused, isSelected, innerProps, children, data }) => {
	return (
		<MenuItem
			className={selectProps.classes.option}
			ref={innerRef}
			selected={isFocused}
			component="div"
			style={{
				fontWeight: isSelected ? 700 : 400,
			}}
			{...innerProps}
		>
			<ListItemText
				primary={children}
				secondary={data.functions && data.functions.join(', ')}
				classes={{
					secondary: selectProps.classes.optionSecondary,
				}}
			/>
		</MenuItem>
	);
};

const Placeholder = ({ selectProps, innerProps, children }) => {
	return (
		<Typography
			color="textSecondary"
			className={selectProps.classes.placeholder}
			{...innerProps}
		>
			{children}
		</Typography>
	);
};

const SingleValue = ({ selectProps, innerProps, data, isDisabled }) => {
	return (
		<Typography className={selectProps.classes.singleValue} {...innerProps}>
			<span className={selectProps.classes.singleValueName}>
				{data.abbreviation}
			</span>
			{
				data.functions && isDisabled &&
				<span className={selectProps.classes.singleValueFunctions}>
					{data.functions.join(', ')}
				</span>
			}
		</Typography>
	);
};

const ValueContainer = ({ selectProps, children }) => {
	return (
		<div className={selectProps.classes.valueContainer}>
			{children}
		</div>
	);
};

const Menu = ({ selectProps, innerProps, children }) => {
	return (
		<Paper square className={selectProps.classes.paper} {...innerProps}>
			{children}
		</Paper>
	);
};

const customComponents = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
};

const customStyles = {
	indicatorSeparator: (provided, state) => ({
		display: 'none',
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		cursor: 'pointer',
		...(state.isDisabled ? {
			display: 'none',
		}: {})
	}),
	clearIndicator: (provided) => ({
		...provided,
		cursor: 'pointer',
	}),
	loadingIndicator: (provided) => ({
		...provided,
		'&>span': {
			backgroundColor: 'rgba(236, 208, 48, 0.8)',
			height: '1.5em',
			width: '1.5em',
			marginLeft: '1.5em',
		}
	}),
};

const ScopeSelect = ({  ...props }) => {
	return (
		<StyledSelect classes={classes}
				styles={customStyles}
				components={customComponents}
				isSearchable={true}
				isClearable={true}
				getOptionLabel={(option) => `${option.name} (${option.abbreviation})`}
				getOptionValue={(option) => option.id}
				{...props} />
	);
}

ScopeSelect.propTypes = {
	options: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

export default (ScopeSelect)
