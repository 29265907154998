import PropTypes from 'prop-types';
import React from 'react';
import { getAllEmployeeProperties } from '../../actions/employeeActions';
import { useFetchData } from '../../helpers/hooks';
import PropertyFiltersMenu from '../properties/PropertyFiltersMenu';

const EmployeeListFilters = ({ handleProperty, propertiesState }) => {
	const { data } = useFetchData('EmployeePropertiesDefinition', getAllEmployeeProperties);
	return (
		<PropertyFiltersMenu
			propertiesState={propertiesState}
			propertiesDefinition={data}
			handleProperty={handleProperty}
		/>
	);
};

EmployeeListFilters.propTypes = {
	propertiesState: PropTypes.object.isRequired,
	handleProperty: PropTypes.func.isRequired,
};

export default EmployeeListFilters;
