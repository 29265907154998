import { Divider, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';
import withWorkingScope from '../../../modules/scope/withWorkingScope';
import PageHeader from '../../page/PageHeader';
import PageHeaderTitle from '../../page/PageHeaderTitle';

function CompanyDashboardPage({ topLevelScope }) {
    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <PageHeader>
                        <PageHeaderTitle
                            headline="Company dashboard"
                            subHeading={topLevelScope?.name}
                        />
                    </PageHeader>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h4">{t('manage.company.page.captions')}</Typography>
                        <Link to="/portal/company/captions">{t('manage.company.page.editCaptions')}</Link>
                        <Divider sx={{ mx: 0, my: 2 }} />
                        <Typography variant="h4">{t('manage.company.page.faq')}</Typography>
                        <Link to="/portal/company/faq">{t('manage.company.page.editFaq')}</Link>
                        <Divider sx={{ mx: 0, my: 2 }} />
                        <Typography variant="h4">{t('manage.company.page.functions')}</Typography>
                        <Link to="/portal/company/functions">{t('manage.company.page.editFunctions')}</Link>
                        <Divider sx={{ mx: 0, my: 2 }} />
                        <Typography variant="h4">{t('manage.company.page.templatesMail')}</Typography>
                        <Link to="/portal/company/templates/mail">{t('manage.company.page.editTemplatesMail')}</Link>
                        <Divider sx={{ mx: 0, my: 2 }} />
                        <Typography variant="h4">{t('manage.company.page.templatesCourseDescription')}</Typography>
                        <Link to="/portal/company/templates/course">{t('manage.company.page.editTemplatesCourseDescription')}</Link>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default withWorkingScope(CompanyDashboardPage);
