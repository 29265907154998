import { omit } from 'ramda';
import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormHelperText, FormControlLabel, Grid, Button, TextField, Icon, styled, MenuItem } from '@mui/material';
import { Trans, withTranslation } from 'react-i18next';
import { getScopeParentsPropertyCombined, scopeParentsFromStore } from '../../helpers/scope';
import withFormData from '../../modules/formData/withFormData';
import { sendGroupMail } from '../../actions/courseGroupActions';
import Loader from '../util/loader/Loader';
import Alert from '../util/alert/Alert';
import AthenaEditor from '../util/AthenaEditor';
import InfoTooltip from '../../modules/info/InfoTooltip';

const CourseGroupOccupancyMailTemplate = lazy(() => import('./CourseGroupOccupancyMailTemplate'));

const createTemplateSorter = (locale) => (a, b) => {
    if (a.language !== b.language) {
        if (a.language === locale) return -1;
        if (b.language === locale) return 1;
    }

    return a.label.localeCompare(b.label);
};

const PREFIX = 'CourseGroupOccupancyMailSend';

const classes = {
    attachment: `${PREFIX}-attachment`,
};

const StyledGrid = styled(Grid)(() => ({
    [`& .${classes.attachment}`]: {
        fontSize: '0.9em',
    },
}));

class CourseGroupOccupancyMailSend extends Component {
    constructor(props) {
        super(props);

        const locales = ['nl_NL', ...scopeParentsFromStore(props.group.id, 'locale').filter(Boolean)];
        const locale = locales[locales.length - 1].split('_')[1];
        this.templates = getScopeParentsPropertyCombined(props.group.id, 'groupMailTemplates').sort(createTemplateSorter(locale));

        this.state = {
            subject: '',
            replyto: '',
            body: '',
            bodyEditor: null,
            sendToNewCustomers: true,
        };
    }

    handleSendMail = () => {
        const { watchSubmit, group } = this.props;

        watchSubmit(sendGroupMail(group.id, omit(['bodyEditor'], this.state)));
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleCheckBoxChange = (e) => {
        this.setState({
            [e.target.name]: e.target.checked,
        });
    };

    /**
     * @param {EditorApi|null} api
     */
    bindEditorApi = (api) => {
        this.editorApi = api;
    };

    handleSelectTemplate = (e) => {
        this.setState({
            template: e.target.value || undefined,
        });
    };

    handleCloseTemplate = () => {
        this.setState({
            template: undefined,
        });
    };

    handleApplyTemplate = (state) => {
        this.editorApi?.setEditorState?.(state.bodyEditor);
        this.editorApi?.focus?.();

        this.setState({
            template: undefined,
            ...state,
        });
    };

    handleChangeBody = (raw, html) => {
        this.setState({
            body: html,
            bodyEditor: raw,
        });
    };

    render() {
        const { group, saving, error, success, t } = this.props;
        const { subject, replyto, bodyEditor, sendToNewCustomers, template } = this.state;

        if (saving) return (
            <Loader>Sending the email</Loader>
        );

        if (success) return (
            <Alert type={Alert.TYPE_SUCCESS}>
                {t('scope.course.occupancy.groupEmailSend.emailsQueued', { count: success.QueuedMessages })}
            </Alert>
        );

        return (
            <Suspense fallback={<Loader />}>
                <StyledGrid container spacing={2}>
                    <Grid item xs={12}>
                        {error ? (
                            <Alert type={Alert.TYPE_DANGER}>
                                {error.error}
                            </Alert>
                        ) : (
                            <Alert type={Alert.TYPE_INFO}>
                                <InfoTooltip infoId={5} floatRight />
                                {t('scope.course.occupancy.groupEmailSend.tooltip')}
                            </Alert>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {template && (
                            <CourseGroupOccupancyMailTemplate
                                group={group}
                                template={template}
                                editorApi={this.editorApi}
                                onClose={this.handleCloseTemplate}
                                onApplyTemplate={this.handleApplyTemplate}
                            />
                        )}
                        <TextField
                            select
                            fullWidth
                            value={template || ''}
                            onChange={this.handleSelectTemplate}
                            label={t('scope.course.occupancy.groupEmailSend.useTemplate')}
                        >
                            {this.templates.map((te, i) => (
                                <MenuItem key={i} value={te}>
                                    {`${te.label} - ${te.language}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            label={t('scope.course.occupancy.groupEmailSend.subject')}
                            name="subject"
                            fullWidth
                            value={subject}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            label={t('scope.course.occupancy.groupEmailSend.sendFromEmail')}
                            name="replyto"
                            fullWidth
                            value={replyto}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AthenaEditor
                            value={bodyEditor}
                            onChange={this.handleChangeBody}
                            output="html"
                            bindEditorApi={this.bindEditorApi}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={sendToNewCustomers}
                                    name="sendToNewCustomers"
                                    onChange={this.handleCheckBoxChange}
                                    color="secondary"
                                />
                            )}
                            label={t('scope.course.occupancy.groupEmailSend.checkboxSendToNewCustomers')}
                        />
                        <FormHelperText>
                            {sendToNewCustomers
                                ? t('scope.course.occupancy.groupEmailSend.sendToNewCustomers')
                                : t('scope.course.occupancy.groupEmailSend.dontSendToNewCustomers')}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Icon>attachment</Icon>
                            </Grid>
                            <Grid item xs sx={{ fontSize: '0.9em' }}>
                                <Trans i18nKey="scope.course.occupancy.groupEmailSend.attachmentExplainer" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={saving}
                            onClick={this.handleSendMail}
                            fullWidth
                        >
                            {saving
                                ? t('scope.course.occupancy.groupEmailSend.sending')
                                : t('scope.course.occupancy.groupEmailSend.sendGroupEmail')}
                        </Button>
                    </Grid>
                </StyledGrid>
            </Suspense>
        );
    }
}

CourseGroupOccupancyMailSend.propTypes = {
    group: PropTypes.object.isRequired,
};

export default withTranslation()(withFormData()(CourseGroupOccupancyMailSend));
