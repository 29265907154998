import React from 'react'
import { Routes, Route } from 'react-router-dom'
import NotFoundPage from '../util/NotFoundPage'
import ProfileIndexPage from './ProfileIndexPage'

const ProfilePage = () => {
	return (
		<Routes>
			<Route path="/" index element={<ProfileIndexPage />} />
			<Route element={<NotFoundPage />} />
		</Routes>
	);
}

export default ProfilePage
