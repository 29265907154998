import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

const propTypes = {
	handleSort: PropTypes.func.isRequired,
	sortDisplay: PropTypes.string.isRequired,
	sortDesc: PropTypes.bool.isRequired,
}

const headerItems = [
	{ key: 'id', name: 'Order ID' },
	{ key: 'totalPrice', name: 'Total Amount' },
	{ key: 'status', name: 'Status' },
	{ key: 'orderDateTime', name: 'Time of Order' },
]

const ClientOrderTableHeader = ({ handleSort, sortDisplay, sortDesc }) => (
	<TableRow>
		{headerItems.map(item => (
			<TableCell
				key={item.key}
				align={item.key === 'totalPrice' ? 'right' : undefined}
			>
				<TableSortLabel
					onClick={handleSort(item.key)}
					active={sortDisplay === item.key}
					direction={sortDesc ? 'desc' : 'asc'}
				>
					{item.name}
				</TableSortLabel>
			</TableCell>
		))}
		<TableCell style={{ width: '10%' }}/>
	</TableRow>
)

ClientOrderTableHeader.propTypes = propTypes

export default ClientOrderTableHeader
