import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { remove } from 'ramda'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

const PREFIX = 'StringListEditor';

const classes = {
    root: `${PREFIX}-root`,
    item: `${PREFIX}-item`,
    itemNumber: `${PREFIX}-itemNumber`,
    itemField: `${PREFIX}-itemField`,
    icon: `${PREFIX}-icon`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		marginTop: theme.spacing(2),
	},

    [`& .${classes.item}`]: {
		display: 'flex',
		alignItems: 'center',
	},

    [`& .${classes.itemNumber}`]: {
		fontWeight: theme.typography.fontWeightMedium,
	},

    [`& .${classes.itemField}`]: {
		flex: 1,
		marginLeft: theme.spacing(2),
	},

    [`& .${classes.icon}`]: {
		marginRight: theme.spacing(1),
	}
}));

const StringListEditor = ({ name, itemName, list, onChange }) => {

	const { t } = useTranslation();

	const handleChange = useCallback((e) => {
		const i = parseInt(e.target.name.replace(`${name}-`, ''));

		list[i] = e.target.value;
		onChange([...list]);
	}, [name, list, onChange]);

	const handleAdd = useCallback((e) => {
		onChange([...list, '']);
	}, [list, onChange]);

	const handleRemove = useCallback((i) => {
		onChange(remove(i, 1, list));
	}, [list, onChange]);

	return (
        <Root className={classes.root}>
			<Grid container spacing={2}>
				{list.map((item, i) => (
					<Grid item xs={12} key={i}>
						<div className={classes.item}>
							<div className={classes.itemNumber}>
								{i + 1}.
							</div>
							<TextField
								name={`${name}-${i}`}
								value={item}
								test={i}
								index={i}
								onChange={handleChange}
								className={classes.itemField}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={() => handleRemove(i)}>
												<Icon>delete</Icon>
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</div>

					</Grid>
				))}
				<Grid item xs={12}>
					<Button variant="outlined" color="primary" onClick={handleAdd} fullWidth>
						<Icon className={classes.icon}>add</Icon>
						{list.length === 0
							? t('scope.edit.addFirst', { itemName })
							: t('scope.edit.addOneMore', { itemName })
						}
					</Button>
				</Grid>
			</Grid>
		</Root>
    );
}

StringListEditor.propTypes = {
	name: PropTypes.string.isRequired,
	itemName: PropTypes.string,
	list: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func.isRequired,
}

StringListEditor.defaultProps = {
	itemName: 'item',
};

export default StringListEditor
