import moment from 'moment';
import { fetchDataAppend, fetchDataDelete, fetchDataInternalActions, fetchDataReplaceBy } from '../modules/fetchData/fetchDataActions';
import { Schemas, CALL_API } from './schemas';
import { getApiTypeSuccess } from '../helpers/types';
import { AUTH_ME, doLogout } from '../modules/auth/authActions';

export const EMPLOYEE_APPLICATIONS = 'EMPLOYEE_APPLICATIONS';
export const EMPLOYEE_CONTRACTS = 'EMPLOYEE_CONTRACTS';
export const ALL_EMPLOYEE_PROPERTIES = 'ALL_EMPLOYEE_PROPERTIES';
export const EMPLOYEE_PROPERTIES = 'EMPLOYEE_PROPERTIES';
export const EMPLOYEE_PROFILE = 'EMPLOYEE_PROFILE';

export const getApplications = (employeeId) => ({
	[CALL_API]: {
		type: EMPLOYEE_APPLICATIONS,
		endpoint: `employee/${employeeId}/applications`,
	},
});

export const getContracts = (employeeId) => ({
	[CALL_API]: {
		type: EMPLOYEE_CONTRACTS,
		endpoint: `employee/${employeeId}/contracts`,
	},
});

export const getAllEmployeeProperties = () => ({
	[CALL_API]: {
		type: ALL_EMPLOYEE_PROPERTIES,
		endpoint: 'employee/properties',
	},
});

export const getEmployeeProperties = (employeeId) => ({
	[CALL_API]: {
		type: EMPLOYEE_PROPERTIES,
		endpoint: `employee/${employeeId}/properties`,
	},
});

export const EMPLOYEE_PROPERTIES_EDIT = 'EMPLOYEE_PROPERTIES_EDIT';
export const patchEmployeeProfileProperties = (employeeId, data) => (dispatch) => dispatch({
	[CALL_API]: {
		type: EMPLOYEE_PROPERTIES_EDIT,
		endpoint: `employee/${employeeId}/properties`,
		method: 'PATCH',
		body: data,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(EMPLOYEE_PROPERTIES_EDIT)) {
		dispatch(fetchDataInternalActions.fetchDataSuccess('ProfileProperties', res.response.result));
	}
	return res;
});

export const EMPLOYEE_PROPERTY_ADD = 'EMPLOYEE_PROPERTY_ADD';
export const addEmployeeProperty = (property) => (dispatch) => dispatch({
	[CALL_API]: {
		type: EMPLOYEE_PROPERTY_ADD,
		endpoint: 'employee/properties',
		method: 'POST',
		body: property,
	},
}).then(res => {
	dispatch(fetchDataAppend('PropertyEditor', res?.response?.result));
});

export const EMPLOYEE_PROPERTY_UPDATE = 'EMPLOYEE_PROPERTY_UPDATE';
export const updateEmployeeProperty = (property) => (dispatch) => {
	const newProperty = { ...property };
	delete newProperty.id;
	return dispatch({
		[CALL_API]: {
			type: EMPLOYEE_PROPERTY_UPDATE,
			endpoint: `employee/properties/${property.id}`,
			method: 'PUT',
			body: newProperty,
		},
	}).then(res => {
		const data = res.response.result;
		dispatch(fetchDataReplaceBy('PropertyEditor', 'id', data));
	});
};

export const EMPLOYEE_PROPERTY_DELETE = 'EMPLOYEE_PROPERTY_DELETE';
export const deleteEmployeeProperty = (property) => (dispatch) => dispatch({
	[CALL_API]: {
		type: EMPLOYEE_PROPERTY_DELETE,
		endpoint: `employee/properties/${property.id}`,
		method: 'DELETE',
	},
}).then(res => {
	dispatch(fetchDataDelete('PropertyEditor', property));
	return res;
});

export const expireContract = (contractId, endDate = undefined) => {
	let url = `contract/${contractId}/expire`;

	if (endDate) {
		url += `?endDate=${moment(endDate).format('YYYY-MM-DD')}`;
	}

	return {
		[CALL_API]: {
			type: EMPLOYEE_CONTRACTS,
			endpoint: url,
			method: 'POST',
			data: {},
		},
	};
};

export const getProfile = (employeeId) => ({
	[CALL_API]: {
		type: EMPLOYEE_PROFILE,
		endpoint: `employee/${employeeId}`,
		schema: Schemas.USER,
	},
});

export const EMPLOYEE_EDIT = 'EMPLOYEE_EDIT';
export const patchEmployeeProfile = (employeeId, data) => (dispatch, getData) => {
	const isSelf = getData().auth.user && getData().auth.user.id === employeeId;

	return dispatch({
		[CALL_API]: {
			type: EMPLOYEE_EDIT,
			endpoint: `employee/${employeeId}`,
			method: 'PATCH',
			body: data,
			...(isSelf ? {} : {
				schema: Schemas.USER,
			}),
		},
	}).then(res => {
		if (res.type === getApiTypeSuccess(EMPLOYEE_EDIT) && isSelf) {
			if (data.email) {
				dispatch(doLogout());
			} else {
				dispatch({
					...res,
					type: getApiTypeSuccess(AUTH_ME),
				});
			}
		}

		return res;
	});
};
