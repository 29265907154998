import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import getDisplayName from 'react-display-name'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { getRootScopes } from './scopeActions'
import Loader from '../../components/util/loader/Loader'

const withRootProvider = (ComposedComponent) => {
	const Enhanced = connect((state) => ({
		root: state.root
	}), {
		getRootScopes
	})(class RootProvider extends PureComponent {
		static displayName = `rootProvider(${getDisplayName(ComposedComponent) || 'Unknown'})`;

		render() {
			const { root, ...rest } = this.props;

			if(!root.loaded){
				return <Loader />
			}

			return (
				<ComposedComponent
					rootCapabilities={root.rootCapabilities}
					{...rest}
				/>
			);
		}

		componentDidMount() {
			this.props.getRootScopes();
		}

		componentDidUpdate(prevProps) {
			const { root } = this.props;

			// Check if root changed and forceUpdate as such a change
			// ... implies possibly everything in the application should change/update
			if(
				root.rootCapabilities !== prevProps.root.rootCapabilities ||
				root.rootScopes !== prevProps.root.rootScopes
			){
				this.forceUpdate();
			}
		}
	});

	return hoistNonReactStatics(Enhanced, ComposedComponent);
};

export default withRootProvider
