import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import api from './api'
import rootReducer from './rootReducer'
import { initialSchemasState } from '../actions/schemas'
import { scopeMiddleware } from '../modules/scope/scopeMiddleware'
import loggerMiddleware from './loggerMiddleware'

const middleware = [
	thunk,
	api,
	scopeMiddleware,
	loggerMiddleware,
].filter(Boolean);

const finalCreateStore = compose(
	applyMiddleware(...middleware),
	typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
)(createStore);

export default function configureStore(initialState = {}) {
	return finalCreateStore(rootReducer, {
		...initialSchemasState,
		...initialState
	})
}
