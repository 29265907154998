import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import { CALL_API } from '../../../setup/api'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { formatMoneyAuto } from '../../../helpers/formatter'
import { niceDateTime } from '../../../helpers/datetime'
import ApiError from '../../util/ApiError'
import Loader from '../../util/loader/Loader'
import Alert from '../../util/alert/Alert'

const ApiAction = (batchId) => ({
	[CALL_API]: {
		type: 'BATCHES_PAYMENTS_LIST',
		endpoint: `employee-payment-batch/${batchId}/payments`,
	},
});

const BatchesPaymentsList = ({ batchId, loading, error, data, load }) => {
	useEffect(() => {
		load(ApiAction(batchId));
	}, [batchId]);

	if(error) return (
		<ApiError error={error} />
	);

	if(!data || loading) return (
		<Loader />
	);

	if(data.length === 0) return (
		<Alert>
			No payments found for this batch.
		</Alert>
	);

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Employee</TableCell>
					<TableCell>Contract</TableCell>
					<TableCell>Created</TableCell>
					<TableCell>Type</TableCell>
					<TableCell>IBAN</TableCell>
					<TableCell>Computed over</TableCell>
					<TableCell>Amount</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map(item => (
					<TableRow key={item.id}>
						<TableCell>
							<Link to={`/portal/employees/${item.employee.id}/view`}>
								{item.employee.fullName}
							</Link>
						</TableCell>
						<TableCell>
							<Link to={`/portal/employees/${item.employee.id}/view`} state={{ modal: 'viewContract', contract: item.contract }}>
								{item.contract.function.name} #{item.contract.id}
							</Link>
						</TableCell>
						<TableCell>{niceDateTime(item.created)}</TableCell>
						<TableCell>{item.type}</TableCell>
						<TableCell>{item.employee.IBAN}</TableCell>
						<TableCell>{item.computedOver}</TableCell>
						<TableCell>{formatMoneyAuto(item.amount)}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	)
};

BatchesPaymentsList.propTypes = {
	batchId: PropTypes.string.isRequired,
};

export default withFetchData()(BatchesPaymentsList)
