import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNoteForContext, deleteNoteForContext, updateNoteForContext } from '../../actions/notesActions';
import withFormData from '../../modules/formData/withFormData';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';

const LexicalEditor = lazy(() => import('../util/editor/LexicalEditor'));

function EditNotesModal({ handleClose, contextId, context, modal, watchSubmit, saving, error }) {
	const { t } = useTranslation();
	const { id: noteId, content } = modal.note || {};
	const [text, setText] = useState(content || '');
	const handleContentChange = (value) => setText(value);

	const handleSave = () => {
		let action;
		const body = { content: text };
		if (noteId) {
			action = updateNoteForContext(context, contextId, noteId, body);
		} else {
			action = addNoteForContext(context, contextId, body);
		}
		watchSubmit(action).then(res => {
			if (res.type === 'FORM_SUCCESS') {
				handleClose();
			}
		});
	};

	const handleDelete = () => {
		if (window.confirm(t('notes.confirmDeleteNote'))) {
			watchSubmit(deleteNoteForContext(context, contextId, modal.note));
			handleClose();
		}
	};

	return (
		<Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle>
				{t('notes.editNotes')}
			</DialogTitle>
			<DialogContent>
				{error && (
					<ApiError error={error} />
				)}
				{saving && (<Loader sheet />)}
				<Suspense fallback={<Loader />}>
					<LexicalEditor
						content={content}
						onChange={handleContentChange}
						allowedBlockTypes={[
							'paragraph',
							'h1',
							'h2',
							'h3',
							'ul',
							'ol',
							'quote',
						]}
						minHeight={300}
					/>
				</Suspense>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleDelete}
					disabled={saving || !noteId}
					startIcon={<Icon>delete</Icon>}
					color="secondary"
					variant="outlined"
					style={{ marginRight: 'auto' }}
				>
					{t('general.delete')}
				</Button>
				<Button onClick={handleClose} color="secondary">
					{t('general.cancel')}
				</Button>
				<Button
					onClick={handleSave}
					disabled={saving}
					color="primary"
					variant="contained"
				>
					{t('general.save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

EditNotesModal.propTypes = {
	context: PropTypes.string.isRequired,
	contextId: PropTypes.number.isRequired,
};

export default withFormData({
	customId: () => 'EditNotesForContext',
})(EditNotesModal);
