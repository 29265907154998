import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { concat, findIndex, remove } from 'ramda'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

const PREFIX = 'TagEditor';

const classes = {
    chip: `${PREFIX}-chip`,
    icon: `${PREFIX}-icon`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.chip}`]: {
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

    [`& .${classes.icon}`]: {
		marginLeft: theme.spacing(1),
	}
}));

const removeFromList = (tag, tags) => {
	const i = findIndex(
		item => item.toLowerCase() === tag.toLowerCase()
	)(tags);

	return i === -1 ? tags : remove(i, 1, tags);
}

class TagEditor extends Component {
	state = {
		tag: ''
	}

	render(){
		const { tags, addText, } = this.props;
		const { tag } = this.state;

		return (
            <StyledGrid container spacing={2}>
				<Grid item xs={12}>
					{tags.map(tag => (
						<Chip key={tag}
							  label={tag}
							  onDelete={this.handleDeleteTag(tag)}
							  className={classes.chip} />
					))}
				</Grid>
				<Grid item xs={12}>
					<TextField
						label={addText || 'Add new tag'}
						value={tag}
						onKeyDown={this.handleKeyPress}
						onChange={this.handleChangeTag}
						InputProps={{
							endAdornment: tag && tag.length > 0 ? (
								<InputAdornment position="end">
									<IconButton
										onClick={this.handleSubmit}
										color="primary"
									>
										<Icon>send</Icon>
									</IconButton>
								</InputAdornment>
							) : undefined
						}}
					/>
				</Grid>
			</StyledGrid>
        );
	}

	handleDeleteTag = tag => () => {
		this.props.onChange(
			removeFromList(tag, this.props.tags)
		);
	}

	handleAddTag = (tag) => {
		this.props.onChange(
			concat(removeFromList(tag, this.props.tags), [tag])
		);
	}

	handleKeyPress = (e) => {
		// On enter
		if(e.keyCode === 13){
			e.preventDefault();
			this.handleSubmit();
		}
	}

	handleChangeTag = (e) => {
		const { tagNameFilter } = this.props;

		this.setState({
			tag: tagNameFilter ? tagNameFilter(e.target.value) : e.target.value,
		});
	}

	handleSubmit = (e) => {
		if(e) e.preventDefault();

		if(!this.state.tag || this.state.tag.length < 1) return;

		this.handleAddTag(this.state.tag);
		this.setState({
			tag: ''
		});
	}
}

TagEditor.propTypes = {
	tags: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	addText: PropTypes.string,
	tagNameFilter: PropTypes.func,
};

export default (TagEditor)
