import React from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next';
import { editScopeTypes } from '../../../actions/scopeEditActions'
import editScope from './editScope'

const PublishedOptions = [
	{value: 'published', label: 'Published'},
	{value: 'draft', label: 'Draft'}
];

const PublishedValues = PublishedOptions.map(o => o.value);

const ScopeFacultyForm = (
	{
		saveScope, showButtons, saving, fields, parent,
		bindState, bindStateSwitch, switchCheckedFromValue
	}
) => {
	const { t } = useTranslation();
	return (
		<form onSubmit={saveScope}>
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<TextField label={t('general.name')}
							   name="name"
							   fullWidth
							   value={fields.name}
							   onChange={bindState('name')} />
				</Grid>

				<Grid item sm={6} xs={12}>
					<TextField label={t('general.abbreviation')}
							   name="abbreviation"
							   fullWidth
							   value={fields.abbreviation}
							   onChange={bindState('abbreviation')} />
				</Grid>

				<Grid item sm={6} xs={12}>
					<FormControlLabel
						label={t('scope.edit.publishedForCustomers')}
						control={
							<Switch checked={switchCheckedFromValue(PublishedValues, fields.published)}
									onChange={bindStateSwitch('published', PublishedValues)} />
						}
					/>
				</Grid>

				{showButtons && (
					<Grid item>
						<Button variant="contained" color="default"
								disabled={saving}
								onClick={saveScope}>
							{saving
								? t('general.saving')
								: parent
									? 'Add faculty'
									: 'Edit faculty'
							}
						</Button>
					</Grid>
				)}
			</Grid>
		</form>
	);
}

export default editScope(
	editScopeTypes.FACULTY,
	{
		fields: {
			name: editScope.Joi.string().required().default(''),
			abbreviation: editScope.Joi.string().required().default(''),
			published: editScope.Joi.string().valid(PublishedValues).default(PublishedValues[0]),
		},
	}
)(ScopeFacultyForm)
