import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CopyValueIconButton from './CopyValueIconButton';

const PREFIX = 'PropertiesTable';

const classes = {
    body: `${PREFIX}-body`,
    body2: `${PREFIX}-body2`
};

const StyledTable = styled(Table)(({ theme }) => ({
    [`& .${classes.body}`]: {
		fontWeight: 600,
		padding: theme.spacing(2),
		paddingLeft: 0,
		width: 'auto',
		whiteSpace: 'nowrap',
		color: theme.palette.primary.dark,
	},

    [`& .${classes.body2}`]: {
		width: '100%',
		padding: theme.spacing(2),
	}
}));

const LabelTableCell = TableCell;

const ContentTableCell = TableCell;

/**
 *
 * @param items: [{label:string, content:node},...]
 * @returns Component
 */
const PropertiesTable = ({ items }) => {
	if(items.length === 0) return null;

	return (
        <StyledTable>
			<TableBody>
				{items.map((item, key) => (
					<TableRow key={key}>
						<LabelTableCell
                            classes={{
                                body: classes.body
                            }}>{item.label}</LabelTableCell>
						<ContentTableCell
                            classes={{
                                body: classes.body2
                            }}>
							{item.content}
							{!!item.copy && (
								<CopyValueIconButton value={item.content} />
							)}
						</ContentTableCell>
					</TableRow>
				))}
			</TableBody>
		</StyledTable>
    );
}

PropertiesTable.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			content: PropTypes.node,
			copy: PropTypes.bool,
		}),
	).isRequired,
};

export default PropertiesTable
