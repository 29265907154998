import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { formatArtificialStockStatus } from '../../helpers/scope';

const PREFIX = 'GroupOccupancy';

const classes = {
    dot: `${PREFIX}-dot`,
    manipulated: `${PREFIX}-manipulated`
};

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    [`& .${classes.dot}`]: {
		display: 'inline-block',
		width: theme.spacing(2),
		height: theme.spacing(2),
		marginRight: theme.spacing(1),
		verticalAlign: -2,
		borderRadius: '50%',
		'&.red': {
			backgroundColor: theme.palette.error.main,
		},
		'&.orange': {
			backgroundColor: theme.palette.warning.main,
		},
		'&.green': {
			backgroundColor: theme.palette.success.main,
		},
	},

    [`& .${classes.manipulated}`]: {
		fontSize: '0.7rem',
		display: 'block',
	}
}));

function groupStatusClass(group) {
	switch (group.actualStockStatus || group.stockStatus) {
		case 'SUFFICIENT_PLACES':
			return 'red';
		case 'ALMOST_FULL':
			return 'orange';
		case 'FULL':
			return 'green';
		default:
			return '';
	}
}

const GroupOccupancy = ({ group, showManipulated, }) => {
	const manipulated = group.artificialStockStatus && (showManipulated !== 'full' || group.artificialStockStatus === 'FULL');

	return (
        <StyledTooltip title={manipulated ? (
			<>
				Manipulation:
				{' '}
				{formatArtificialStockStatus(group)}
			</>
		) : 'No manipulation'}
		>
			<span>
				<div className={classnames(classes.dot, groupStatusClass(group))} />
				{`${group.soldUnits}/${group.availableUnits}`}
				{ showManipulated && manipulated && (
					<em className={classes.manipulated}>
						(manipulated:
						{' '}
						{formatArtificialStockStatus(group)}
						)
					</em>
				)}
			</span>
		</StyledTooltip>
    );
};

GroupOccupancy.propTypes = {
	group: PropTypes.object.isRequired,
	showManipulated: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf(['full']),
	]),
};

export default (GroupOccupancy);
