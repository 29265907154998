import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'ramda'
import Slide from '@mui/material/Slide'
import { Link } from 'react-router-dom'
import { capabilities, hasScopeCapability } from '../../helpers/capabilities'
import withAuthProvider from '../../modules/auth/withAuthProvider'
import withRootProvider from '../../modules/scope/withRootProvider'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { disabledPortalBackground } from '../../PortalPage'
import withLiveTheme from './withLiveTheme'

const PREFIX = 'LiveSelectPage';

const classes = {
    root: `${PREFIX}-root`,
    mainLinks: `${PREFIX}-mainLinks`,
    secondaryLinks: `${PREFIX}-secondaryLinks`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		marginTop: theme.spacing(5),
	},

    [`& .${classes.mainLinks}`]: {
		'& a': {
			color: theme.palette.common.white,
		},
	},

    [`& .${classes.secondaryLinks}`]: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(8),
	}
}));

const LiveSelectPage = ({ topLevelScopes }) => {

	const userRootScopes = useSelector((state) => {
		return state.root.rootScopes
			.map(id => state.entities.scopes[id])
			.filter(scope => hasScopeCapability(scope, capabilities.SeeScopeCapability) && hasScopeCapability(scope, capabilities.ManageFinanceCapability))
	});

	useEffect(() => {
		disabledPortalBackground(true);

		return () => {
			disabledPortalBackground(false);
		};
	}, []);

	return (
        <Root className={classes.root}>
			<Container maxWidth="xs">
				<div className={classes.mainLinks}>
					<Slide in direction="down">
						<Grid container spacing={3}>
							{topLevelScopes.map(scope => (
								<Grid item xs={12} key={scope.id}>
									<Button
										component={Link}
										to={`/live/${scope.id}`}
										variant="contained"
										color="primary"
										fullWidth
									>
										{scope.name} ({scope.abbreviation})
									</Button>
								</Grid>
							))}
						</Grid>
					</Slide>
				</div>
				<div className={classes.secondaryLinks}>
					<Slide in direction="down">
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="body1" gutterBottom>
									Scopes you have a management level contract on:
								</Typography>
							</Grid>
							{userRootScopes.map(scope => (
								<Grid item xs={12} key={scope.id}>
									<Button
										component={Link}
										to={`/live/${scope.id}`}
										variant="outlined"
										color="primary"
										fullWidth
									>
										{scope.name} ({scope.abbreviation})
									</Button>
								</Grid>
							))}
						</Grid>
					</Slide>
				</div>
			</Container>
		</Root>
    );
};

export default compose(
	withLiveTheme,
	withAuthProvider,
	withRootProvider,
	withWorkingScope,
)(LiveSelectPage)
