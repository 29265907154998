import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { sortScopesOnName } from '../../helpers/scope';
import withSubScopes from '../../modules/scope/withSubScopes';

// eslint-disable-next-line
const SingleValue = ({ innerProps, data }) => (
	<div {...innerProps}>
		{data.abbreviation}
	</div>
);

const selectComponents = {
	SingleValue,
};

const selectStyles = {
	container: (baseStyles) => ({
		...baseStyles,
		display: 'inline-block',
	}),
	control: (baseStyles) => ({
		...baseStyles,
		border: 'none',
	}),
	menu: (baseStyles) => ({
		...baseStyles,
		width: 'auto',
		minWidth: 300,
	}),
	indicatorSeparator: (baseStyles) => ({
		...baseStyles,
		display: 'none',
	}),
	placeholder: (baseStyles) => ({
		...baseStyles,
		position: 'static',
		transform: 'none',
	}),
};

function MiniScopeSelect({ scope, selected, onChange, index, subScopes, loading }) {
	const options = subScopes && sortScopesOnName(subScopes);

	const handleChange = useCallback((newScope, { action }) => {
		switch (action) {
			case 'select-option':
				onChange(newScope, index);
				break;
			default:
				break;
		}
	}, [onChange, index]);

	const disabled = Boolean(scope._virtual && options && options.length === 1 && selected);

	return (
		<Select
			value={selected}
			options={options}
			isLoading={loading}
			onChange={handleChange}
			isSearchable
			getOptionLabel={(option) => `${option.name} (${option.abbreviation})`}
			getOptionValue={(option) => option.id}
			styles={selectStyles}
			components={selectComponents}
			isDisabled={disabled}
		/>
	);
}

MiniScopeSelect.propTypes = {
	scope: PropTypes.object.isRequired,
	selected: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
};

export default withSubScopes(MiniScopeSelect, (props) => `MiniScopeSelect-${props.scope.id}`);
