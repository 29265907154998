import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import PageContentBlockMenu from './PageContentBlockMenu';

const PREFIX = 'PageContentBlock';

const classes = {
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
    paperTop: `${PREFIX}-paperTop`,
    heading: `${PREFIX}-heading`,
    headingTop: `${PREFIX}-headingTop`,
    title: `${PREFIX}-title`,
    links: `${PREFIX}-links`,
    linkButton: `${PREFIX}-linkButton`,
    linkIcon: `${PREFIX}-linkIcon`,
    icon: `${PREFIX}-icon`,
    contentTop: `${PREFIX}-contentTop`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
		[`&.${classes.paper}`]: {
			[`& + .${classes.paper}`]: {
				marginTop: theme.spacing(3),
			},
		},

		[`&.${classes.paperTop}`]: {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
		},
}));

const StyledHeadingDiv = styled('div')(({ theme }) => ({
		[`&.${classes.heading}`]: {
			padding: theme.spacing(3),
		},

		[`& .${classes.headingTop}`]: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: theme.spacing(0.5),
		},

		[`& .${classes.title}`]: {
			flex: 1,
		},

		[`& .${classes.links}`]: {
			marginLeft: theme.spacing(1),
		},

		[`& .${classes.linkButton}`]: {
			color: `${theme.palette.primary.main} !important`, // TODO: remove link styling from scss
			boxShadow: 'none',
			[`& +.${classes.linkButton}`]: {
				marginLeft: theme.spacing(1),
			},
		},

		[`& .${classes.linkIcon}`]: {
			marginRight: theme.spacing(1),
		},

		[`& .${classes.icon}`]: {
			verticalAlign: -3,
			marginRight: theme.spacing(1),
		},
}));

// Content utility for easy default padding
const Content = styled(({ className, children }) => (
    <div className={classNames(className, classes.content)}>{children}</div>
))(({ theme, contentPadding }) => ({
    [`&.${classes.content}`]: {
        padding: contentPadding ? theme.spacing(3) : 0,
    },
}));

const PageContentBlock = ({ title, description, links, icon, children, contentPadding, className, onlyTopPaper, linksInMenuBreakpoint }) => {
	const finalLinks = links && links.length > 0 ? links.filter(Boolean) : null;

	const theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down(linksInMenuBreakpoint));

	const heading = (title || description || finalLinks) && (
		<StyledHeadingDiv className={classes.heading}>
			<div className={classes.headingTop}>
				{title && (
					<Typography variant="h5" className={classes.title}>
						{icon && (
							<Icon className={classes.icon}>{icon}</Icon>
						)}
						{title}
					</Typography>
				)}
				{finalLinks && (
					<div className={classes.links}>
						{finalLinks.length > 1 && isSmall ? (
							<PageContentBlockMenu links={finalLinks} />
						) : (
							finalLinks.map(link => (
								<Button
									key={link.label}
									variant={link.basic ? null : 'contained'}
									size="small"
									className={classes.linkButton}
									component={link.to ? Link : 'a'}
									disabled={link.disabled}
									to={link.to}
									state={link.state}
									onClick={link.onClick}
									color="default"
								>
									{link.icon && (
										<Icon className={classes.linkIcon}>
											{link.icon}
										</Icon>
									)}
									{link.label}
								</Button>
							))
						)}
					</div>
				)}
			</div>
			{description && (
				<Typography variant="body2">
					{description}
				</Typography>
			)}
		</StyledHeadingDiv>
	);

	if (onlyTopPaper) {
		return (
            <div>
				<StyledPaper className={classNames(classes.paperTop, className)}>
					{heading}
					<Divider />
				</StyledPaper>
				<Content contentPadding={contentPadding} className={classes.contentTop}>
					{children}
				</Content>
			</div>
        );
	}

	return (
		<StyledPaper className={classNames(classes.paper, className)}>
			<div>
				{heading}
				<Divider />
			</div>
			<Content contentPadding={contentPadding}>
				{children}
			</Content>
		</StyledPaper>
	);
};

PageContentBlock.propTypes = {
	title: PropTypes.node,
	description: PropTypes.node,
	links: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			label: PropTypes.node.isRequired,
			to: PropTypes.any, // eslint-disable-line
			icon: PropTypes.string,
			onClick: PropTypes.func,
			basic: PropTypes.bool,
			disabled: PropTypes.bool,
		}),
	])),
	icon: PropTypes.string,
	children: PropTypes.node,
	contentPadding: PropTypes.bool,
	onlyTopPaper: PropTypes.bool,
	linksInMenuBreakpoint: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
	className: PropTypes.string,
};

PageContentBlock.defaultProps = {
	linksInMenuBreakpoint: 'md',
};

PageContentBlock.Content = Content;

export default PageContentBlock;
