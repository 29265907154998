import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import withWorkingScope from '../../../modules/scope/withWorkingScope';
import ModalItem from '../../modal/ModalItem';
import ModalManager from '../../modal/ModalManager';
import PageContentBlock from '../../page/PageContentBlock';
import PageHeader from '../../page/PageHeader';
import PageHeaderTitle from '../../page/PageHeaderTitle';
import Alert from '../../util/alert/Alert';
import CompanyCaptionsModal from './captions/CompanyCaptionsModal';
import CompanyCaptionsNewDialog from './captions/CompanyCaptionsNewDialog';
import CompanyCaptionsOverrides from './CompanyCaptionsOverrides';

const CompanyCaptionsPage = ({ topLevelScope }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const { translations } = topLevelScope?.properties || {};

	const handleNewCaptionOpen = () => {
		setOpen(true);
	};
	const handleNewCaptionClose = () => {
		setOpen(false);
	};

	if (!topLevelScope) {
		return (
			<Alert>
				First select a scope in the scope selector at the top of the page.
			</Alert>
		);
	}

	return (
		<>
			<ModalManager>
				<ModalItem state="editCaptions" component={CompanyCaptionsModal} />
			</ModalManager>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderTitle
							headline={t('manage.company.captionDialog.editCompanyCaptions')}
							subHeading={(
								<Typography paragraph>
									<Trans i18nKey="manage.company.captionDialog.desc" values={{ scopeName: topLevelScope.name }}>
										Override the default captions for
										{' '}
										{topLevelScope.name}
										. If you want to override a caption that isn&apos;t showing in the overview below,
										use the search field to find a caption by its key or the
										{' '}
										<strong>English</strong>
										{' '}
										translation in the search field.
									</Trans>
								</Typography>
							)}
						/>
					</PageHeader>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageContentBlock>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem' }}>
							<Typography variant="h6">
								{t('manage.company.captionDialog.existingCaptionOverrides')}
							</Typography>
							<Button onClick={handleNewCaptionOpen} variant="contained" color="secondary">Add new caption override</Button>
						</div>
						<CompanyCaptionsOverrides
							translations={translations}
						/>
					</PageContentBlock>
				</Grid>
			</Grid>
			<CompanyCaptionsNewDialog open={open} translations={translations} handleNewCaptionClose={handleNewCaptionClose} />
		</>
	);
};

export default withWorkingScope(CompanyCaptionsPage);
