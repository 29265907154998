import { TableCell, TextField, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { languages } from '../../../helpers/localization';
import ModalManager from '../../modal/ModalManager';

const CompanyCaptionsOverrides = ({ translations }) => {
	const [value, setValue] = useState('');
	const { t } = useTranslation();

	const filteredTranslations = useMemo(
		() => Object.entries(translations || {})
			.filter(translation => translation[0].includes(value)
				|| Object.keys(translation[1]).some(lang => translation[1][lang].toLowerCase().includes(value.toLowerCase()))),
		[value, translations],
	);

	if (!translations) {
		return (
			<Typography paragraph>
				{t('manage.company.captionDialog.noCaptionOverrides')}
			</Typography>
		);
	}

	const handleSearchChange = (event) => {
		setValue(event.target.value);
	};

	return (
		<>
			<TextField
				onChange={handleSearchChange}
				placeholder={t('manage.company.captionDialog.searchCaption')}
				fullWidth
				style={{ width: '400px', margin: '0.5rem 1rem' }}
			/>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>key</TableCell>
						{Object.keys(languages).map(lang => (
							<TableCell key={lang}>{languages[lang]}</TableCell>
						))}
						<TableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredTranslations
						.map((translation, index) => (
							<TableRow key={`row_${index}`}>
								<TableCell><i>{translation[0]}</i></TableCell>
								{Object.keys(languages).map(lang => (
									<TableCell key={lang}>
										{translation[1][lang] ? translation[1][lang] : <i>{t('manage.company.captionDialog.noTranslation')}</i>}
									</TableCell>
								))}
								<TableCell>
									<IconButton
										component={Link}
										to="/portal/company/captions"
										state={{
											modal: 'editCaptions',
											selectedKey: translation[0],
										}}
									>
										<Icon>edit</Icon>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</>
	);
};

CompanyCaptionsOverrides.propTypes = {
	translations: PropTypes.object,
};

export default CompanyCaptionsOverrides;
