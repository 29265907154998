import React from 'react';
import { getAllEmployeeProperties } from '../actions/employeeActions';
import { useFetchData } from '../helpers/hooks';

const withEmployeeProperties = (ComposedComponent) => (props) => {
	const { data } = useFetchData('EmployeePropertiesDefinition', getAllEmployeeProperties);

	return <ComposedComponent properties={data} {...props} />;
};

export default withEmployeeProperties;
