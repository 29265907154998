import React from 'react'
import Alert from './alert/Alert'
import Loader from './loader/Loader'
import PropTypes from 'prop-types'

/**
 *
 * This Component can only be used while its parent is wrapped in a fetchData HOC.
 * And will render its children when all the cases in the switch Statement is false.
 *
 * @param data REQUIRED (directly from fetchData HOC)
 * @param loading REQUIRED (directly from fetchData HOC)
 * @param error REQUIRED (directly from fetchData HOC)
 * @param children REQUIRED
 *
 * @required Data Assert: {data && <React/>} somewhere in the children of this component
 * Don't forget there needs to be a statement that asserts the Data,
 * because it renders it's children in this component and can cause a bug.
 *
 * @param custom OPTIONAL (use <Fragment/> to render nothing)
 * 	ifPreLoading: 	React.Component
 * 		This will be triggered when the fetchData HOC hasn't initiated yet
 * 	ifLoading: 		React.Component
 * 		This will be triggered when Loading is set to true, so before the Data has been fetched
 * 	ifError: 		React.Component
 * 		Simply an error render node, triggered if there is an error
 * 	ifNoChildren: 	React.Component
 * 		This is the same as a case containing !data as the first line so all the children are inaccessible
 * 	ifInvalidData: 	React.Component
 * 		If there are children accessible in the Switch but the data needed to continue is invalid or otherwise falsy
 * 	ifEmptyData: 	React.Component
 * 		Simply an alert that there there are no results for the query, triggered when Array.length === 0
 *
 * 	applyFilter:	function inside an Array.filter(*) method
 *		Making this Switch more useful. Sometimes when there is a handleData method in the parent class that utilizes
 *		a filter. It might not trigger the EmptyData in this switch. Therefor you can filter the data here to trigger
 *		the EmptyData case.
 *
 * @returns React.Component
 * @constructor
 */

const propTypes = {
	data: PropTypes.any,
	loading: PropTypes.bool,
	error: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object,
	]),

	ifPreLoading: PropTypes.node,
	ifLoading: PropTypes.node,
	ifError: PropTypes.node,
	ifNoChildren: PropTypes.node,
	ifInvalidData: PropTypes.node,
	ifEmptyData: PropTypes.node,

	applyFilter: PropTypes.func,
}

const FetchDataSwitch = ({ data, loading, error, children, ...custom }) => {

	const standard = {
		loading: <Loader/>,
		error: <Alert type={Alert.TYPE_WARNING}>{error ? error.error : 'There was an error loading the data'}</Alert>,
		noData: <Alert type={Alert.TYPE_INFO}>there is no data available</Alert>,
	}

	switch (true) {
		case loading === undefined:
			return custom.ifPreLoading || custom.ifLoading || standard.loading

		case Boolean(loading):
			return custom.ifLoading || standard.loading

		case Boolean(error):
			return custom.ifError || standard.error

		case !children:
			return custom.ifNoChildren || custom.ifLoading || standard.loading

		case !data:
			return custom.ifInvalidData || custom.ifLoading || standard.loading

		case !data.length:
			return custom.ifEmptyData || standard.noData

		case custom.applyFilter && !data.filter(custom.applyFilter).length:
			return custom.ifEmptyData || standard.noData

		default:
			return children
	}
}

FetchDataSwitch.propTypes = propTypes

export default FetchDataSwitch
