import { Checkbox, FormLabel, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getActiveContractTemplates } from '../../actions/functionsActions';
import { capabilities } from '../../helpers/capabilities';
import withFetchData from '../../modules/fetchData/withFetchData';
import withRootCapabilities from '../../modules/scope/withRootCapabilities';

const PREFIX = 'ApplicationsContractTemplateSelector';

const classes = {
    list: `${PREFIX}-list`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    [`& .${classes.list}`]: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(1, 0),
		maxHeight: '140px',
		overflowY: 'auto',
		border: `1px solid ${theme.palette.grey[300]}`,
		borderRadius: '3px',
		'& .MuiListItemIcon-root': {
			minWidth: '30px',
		},
		'& .MuiButtonBase-root': {
			padding: theme.spacing(0.5, 1, 0.5, 1.5),
		},
	}
}));

function ApplicationsContractTemplateSelector({
	load,
	data: templates = [],
	loading,
	functionTemplate,
	contractTemplateIds,
	doNotApplyTemplate,
	onChangeContractTemplateIds,
	onChangeDoNotApplyTemplate,
	validation,
	hasCapability,
}) {
	const { t } = useTranslation();

	useEffect(() => {
		if (functionTemplate) {
			load(getActiveContractTemplates(functionTemplate.id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [functionTemplate, load]);

	const handleTemplateClick = (id) => () => {
		const newArr = [...contractTemplateIds];
		if (newArr.includes(id)) {
			newArr.splice(newArr.indexOf(id), 1);
		} else {
			newArr.push(id);
		}
		onChangeContractTemplateIds(newArr);
	};

	const handleNoTemplateCheckbox = () => {
		onChangeContractTemplateIds([]);
		onChangeDoNotApplyTemplate(!doNotApplyTemplate);
	};

	return (
        <StyledFormControl fullWidth disabled={!functionTemplate} error={!!validation.contractTemplateIds}>
			<FormLabel>
				{t('application.createForm.selectContractTemplates')}
				{`${loading ? ` (${t('general.loading')})` : ''}`}
			</FormLabel>
			{ templates.length > 0 && (
				<List
					dense
					className={classes.list}
				>
					{templates.map(template => (
						<ListItem key={template.id} dense button onClick={handleTemplateClick(template.id)} disabled={doNotApplyTemplate}>
							<ListItemIcon>
								<Checkbox
									edge="start"
									checked={contractTemplateIds.includes(template.id)}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': template.id }}
									color="primary"
								/>
							</ListItemIcon>
							<ListItemText id={template.id} primary={template.name} />
						</ListItem>
					))}
				</List>
			)}
			{ templates.length === 0 && (
				<Typography style={{ margin: '1rem 0', color: 'rgba(0, 0, 0, 0.38)' }}>
					<em>{t(`application.createForm.${functionTemplate ? 'noContractTemplates' : 'selectFunction'}`)}</em>
				</Typography>
			)}
			{(hasCapability(capabilities.AdministratorOverride) || functionTemplate?.paymentStrategy === 'NONE') && (
				<FormControlLabel
					control={(
						<Checkbox
							checked={doNotApplyTemplate}
							onChange={handleNoTemplateCheckbox}
							name="doNotApplyTemplate"
							color="primary"
						/>
					)}
					label={t('application.createForm.doNotApplyTemplate')}
				/>
			)}
			<FormHelperText>
				{validation.contractTemplateIds}
			</FormHelperText>
		</StyledFormControl>
    );
}

ApplicationsContractTemplateSelector.propTypes = {
	functionTemplate: PropTypes.object,
	contractTemplateIds: PropTypes.array,
	doNotApplyTemplate: PropTypes.bool,
	onChangeContractTemplateIds: PropTypes.func.isRequired,
	onChangeDoNotApplyTemplate: PropTypes.func.isRequired,
	validation: PropTypes.object,
};

export default compose(
	withRootCapabilities,
	withFetchData(null, {
		customId: () => 'applicationsContractTemplateSelector',
		mapEntities: 'contractTemplates',
	}),
)(ApplicationsContractTemplateSelector);
