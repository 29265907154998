import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';

function ScopeFilterOnlyVisible({ onlyVisible, onChange }) {
    const { t } = useTranslation();
    return (
        <FormControlLabel
            sx={{ mb: 0, mr: 4 }}
            control={(
                <Switch
                    checked={onlyVisible}
                    onChange={(e) => onChange(e.target.checked)}
                    value="onlyVisible"
                    color="primary"
                />
            )}
            label={t('scope.showOnlyPublishedCourses')}
        />
    );
}

ScopeFilterOnlyVisible.propTypes = {
    onlyVisible: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ScopeFilterOnlyVisible;
