import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const PREFIX = 'FileDropZone';

const classes = {
    dropZone: `${PREFIX}-dropZone`,
    dragOrClick: `${PREFIX}-dragOrClick`,
    active: `${PREFIX}-active`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.dropZone}`]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '150px',
		border: `2px dashed ${theme.palette.grey[600]}`,
		borderRadius: '4px',
		background: theme.palette.grey[100],
		padding: theme.spacing(2),
		margin: theme.spacing(0, 0, 2, 0),
		'&:hover, &:focus': {
			background: theme.palette.grey[200],
		},
	},

    [`&.${classes.dragOrClick}`]: {
		textAlign: 'center',
	},

    [`& .${classes.active}`]: {
		background: theme.palette.grey[300],
		borderColor: theme.palette.grey[600],
	}
}));

const renderAcceptedFileItems = (acceptedFiles) => acceptedFiles.map(file => (
	<ListItem key={file.path}>
		<ListItemAvatar>
			<Icon>description</Icon>
		</ListItemAvatar>
		<ListItemText primary={file.path} secondary={`${file.size} bytes`} />
	</ListItem>
));

const renderFileRejectionItems = (fileRejections) => fileRejections.map(({ file, errors }) => (
	<ListItem key={file.path}>
		<ListItemAvatar>
			<Icon>error_outline</Icon>
		</ListItemAvatar>
		<ListItemText
			primary={file.path}
			secondary={(
				<span>
					{errors.map(e => e.message).join(', ')}
				</span>
			)}
		/>
	</ListItem>
));

function FileDropZone({ handleAcceptedFiles, maxFiles, accept }) {
	const { t } = useTranslation();

	const onDrop = useCallback(acceptedFiles => {
		handleAcceptedFiles(acceptedFiles);
	}, [handleAcceptedFiles]);
	const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({ onDrop, accept, maxFiles });

	const renderDropZoneContent = useMemo(() => {
		if (isDragActive) {
			return (<Typography variant="subtitle2">{t('util.fileDropZone.dropHere')}</Typography>);
		}
		if (acceptedFiles.length || fileRejections.length) {
			return (
				<List dense>
					{renderAcceptedFileItems(acceptedFiles)}
					{renderFileRejectionItems(fileRejections)}
				</List>
			);
		}
		return (
            <Root className={classes.dragOrClick}>
				<Typography variant="h6">{t('util.fileDropZone.dragAndDropFile')}</Typography>
				<Typography paragraph style={{ marginBottom: '0.5rem' }}>{t('util.fileDropZone.or')}</Typography>
				<Button color="primary" variant="contained">
					{t('util.fileDropZone.clickToOpenFile')}
				</Button>
			</Root>
        );
	}, [acceptedFiles, classes.dragOrClick, fileRejections, isDragActive, t]);

	return (
		<div
			className={`${classes.dropZone} ${isDragActive ? classes.active : ''}`}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			{renderDropZoneContent}
		</div>
	);
}

FileDropZone.propTypes = {
	handleAcceptedFiles: PropTypes.func.isRequired,
	maxFiles: PropTypes.number,
	accept: PropTypes.string,
};

export default FileDropZone;
