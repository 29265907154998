import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import { scopeStartsIn } from '../../../helpers/scope'
import GroupProblemIndicator from '../../util/GroupProblemIndicator'
import GroupOccupancy from '../../util/GroupOccupancy'
import withRouter from '../../../hoc/withRouter'
import ScopeInvisibilityIndicator from '../../util/ScopeInvisibilityIndicator'
import ScopeTooltip from '../tooltip/ScopeTooltip'

const PREFIX = 'ScopeAthenaStudiesCourseGroup';

const classes = {
    row: `${PREFIX}-row`,
    navigateIcon: `${PREFIX}-navigateIcon`,
    hideOnMobile: `${PREFIX}-hideOnMobile`
};

const Root = styled('tr')(({ theme }) => ({
    [`&.${classes.row}`]: {
		'& td': {
			padding: theme.spacing(1),
		},
		'& a': {
			color: 'inherit',
			fontWeight: 'normal',
		},
		'&.draft': {
			opacity: 0.7,
			backgroundColor: theme.palette.grey[300],
		},
	},

    [`& .${classes.navigateIcon}`]: {
		float: 'right',
		cursor: 'pointer',
	},

    [`& .${classes.hideOnMobile}`]: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	}
}));

function preventClickEvent(e){
	if(!e.ctrlKey){
		e.preventDefault();
	}else{
		e.stopPropagation();
	}
}

class ScopeAthenaStudiesCourseGroup extends Component {
    render() {
    	const { scope, parentScope,  t } = this.props;

        return (
            <Root className={`${classes.row} ${scope.published}`} onClick={this.handleRowClick}>
				<ScopeTooltip scopeId={scope.id}>
					<td style={{width: '22%'}}>
						<Link to={`/portal/planner/course/${parentScope.id}/group/${scope.id}`}
							  onClick={preventClickEvent}
						>
							{scope.name}
						</Link>
						<GroupProblemIndicator group={scope} />
					</td>
				</ScopeTooltip>
				<td style={{width: '14%'}}>
					<GroupOccupancy group={scope} />
				</td>
				<td style={{width: '16%'}}
					className={classes.hideOnMobile}>
					{scope.numTimeSlots} {scope.numTimeSlots === 1 ? t('scope.planner.timeslot') : t('scope.planner.timeslots')}
				</td>
				<td style={{width: '20%'}}>
					{scopeStartsIn(scope)}
				</td>
				<td style={{width: '16%'}}
					className={classes.hideOnMobile}
					title={t(`groupStates.${scope.state}.description`)}>
					{t(`groupStates.${scope.state}.name`)}
				</td>
				<td style={{width: '12%'}}>
					<Icon className={classes.navigateIcon}>chevron_right</Icon>
					<ScopeInvisibilityIndicator scope={scope} right />
				</td>
			</Root>
        );
    }

    handleRowClick = () => {
		this.props.navigate(
			`/portal/planner/course/${this.props.parentScope.id}/group/${this.props.scope.id}`
		);
	}
}

ScopeAthenaStudiesCourseGroup.propTypes = {
	scope: PropTypes.object.isRequired,
	parentScope: PropTypes.object.isRequired,
};

export default withTranslation()((withRouter(ScopeAthenaStudiesCourseGroup)))
