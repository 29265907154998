import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { groupBy, map, indexBy, reverse, identity, memoizeWith } from 'ramda';
import moment from 'moment';
import { Bar, Line as LineChart } from 'react-chartjs-2';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import withFetchData from '../../../modules/fetchData/withFetchData';
import withWorkingScope from '../../../modules/scope/withWorkingScope';
import InfoTooltip from '../../../modules/info/InfoTooltip';
import { getCustomerRetention } from '../../../actions/dashboardActions';
import { niceDateMonthYear } from '../../../helpers/datetime';
import Loader from '../../util/loader/Loader';
import { FINANCIAL_CHART } from './financialDashboardUtils';

const PREFIX = 'CustomerRetentionMetrics';

const classes = {
    paper: `${PREFIX}-paper`,
    infoTooltip: `${PREFIX}-infoTooltip`,
};

const StyledPaper = styled(Paper)({
    [`&.${classes.paper}`]: {
        padding: 10,
    },
});

const StyledInfoTooltip = styled(InfoTooltip)(({ theme }) => ({
    [`&.${classes.infoTooltip}`]: {
        marginTop: `-${theme.spacing(1)}`,
        marginRight: `-${theme.spacing(1)}`,
    },
}));

const LINE_CHART_OPTION_DEFAULTS = {
    spanGaps: true,
    scales: {
        y: {
            ticks: {
                callback(tick) {
                    return (tick).toLocaleString();
                },
            },
        },
    },
    plugins: {
        legend: {
            reverse: true,
        },
        tooltip: {
            callbacks: {
                label: (value) => value.parsed.y.toLocaleString(),
            },
            display: true,
        },
    },
};

const STACKED_BAR_CHART_OPTIONS = {
    scales: {
        x: {
            stacked: true,
            offset: true,
            type: 'time',
            time: {
                unit: 'month',
            },
        },
        y: {
            stacked: true,
            ticks: {
                callback(tick) {
                    return tick.toLocaleString();
                },
            },
        },
    },
    plugins: {
        tooltip: {
            callbacks: {
                title: (data) => niceDateMonthYear(data[0].parsed.x),
                label: ({
                    parsed: { y },
                    datasetIndex,
                    dataset,
                }) => {
                    const factor = y / (parseFloat(dataset.data[datasetIndex + 1]) + y);
                    return `${dataset.label}: ${y} (${(factor * 100).toFixed(2)}%)`;
                },
            },
            itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
            mode: 'x',
        },
    },
};

function gradientLineColor(index, total) {
    const max = FINANCIAL_CHART.gradient.length;

    // Last one is success color
    if (index + 1 === total) {
        return FINANCIAL_CHART.bar.success;
    }

    // Calculate gradient position from the back
    let i = max - (total - 1 - index);
    if (i < 0) i = 0;

    return FINANCIAL_CHART.gradient[i];
}

const tooltipContent = {
    audience: 'Percentage of total student for the whole study who have bought a course.\n\n## Explanation\n\nThis data might not be present for all studies. It is retrieved from the government and freely available. It includes the total number of students which are subscribed in that specific study at the beginning of the year. In case you are viewing a combination of studies (e.g. university) an educated average is calculated.',
    retention: 'Number of customers acquired and retained per month. If a customer bought a course already in a previous month, it is counted as retention. If a customer did not buy a course before in that academic year, it is counted as acquisition.\n\n## Accumulated\n\nThis option will add the value of the previous months together. At the end of the academic year, the total acquisition is the same as the amount of customers that year.\n\n##Use case\n\nWith this graph you can easily see how much customers you have been able to interest for another course after their first one. Compare this with previous years for example.',
};

// Wrap month array from Sept-Aug instead of Jan-Dec
const COLLEGE_YEAR_MONTHS = moment.months().slice(8, 12).concat(moment.months().slice(0, 8));

const fragmentSwitchStyle = {
    float: 'right',
    marginTop: '-10px',
    marginBottom: '-12px',
};

function CustomerRetentionMetrics({ load, scope, customerRetention, customerRetentionByCollegeYearPerMonth, collegeYears }) {
    const [retentionChartAccumulated, setRetentionChartAccumulated] = useState(false);

    const fetchData = () => {
        load(getCustomerRetention(scope.id));
    };

    useEffect(() => {
        if (scope) {
            fetchData();
        }
    }, [scope?.id]);

    const renderRetentionChart = () => {
        const keys = [
            retentionChartAccumulated ? 'accumulatedAcquiredCustomers' : 'acquiredCustomers',
            retentionChartAccumulated ? 'accumulatedCustomers' : 'customers',
        ];

        const labels = customerRetention.map(item => item.revenueMonth);
        const datasets = [
            {
                data: customerRetention.map(item => (item[keys[0]]).toFixed(2)),
                label: 'Acquisition',
                fill: true,
                ...FINANCIAL_CHART.bar.neutral,
            },
            {
                data: customerRetention.map(item => (item[keys[1]] - item[keys[0]]).toFixed(2)),
                label: 'Retention',
                fill: true,
                ...FINANCIAL_CHART.bar.success,
            },
        ];

        return (
            <Bar
                data={{
                    labels,
                    datasets,
                }}
                options={STACKED_BAR_CHART_OPTIONS}
                height={200}
            />
        );
    };

    const handleRetentionAccumulatedChange = () => {
        setRetentionChartAccumulated(!retentionChartAccumulated);
    };

    const renderRetention = () => (
        <>
            <StyledInfoTooltip
                infoTitle="Financial - customer retention"
                infoContent={tooltipContent.retention}
                className={classes.infoTooltip}
                floatRight
            />
            <FormControlLabel
                control={(
                    <Switch
                        checked={retentionChartAccumulated}
                        onChange={handleRetentionAccumulatedChange}
                    />
                )}
                label="Accumulated"
                style={fragmentSwitchStyle}
            />
            <Typography variant="subtitle1" gutterBottom>
                Customer Retention
            </Typography>
            {!customerRetention ? <Loader /> : renderRetentionChart()}
        </>
    );

    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item lg={6} sm={12} xs={12}>
                <StyledPaper className={classes.paper}>
                    <Typography variant="subtitle1" gutterBottom>
                        Number of customers in academic year
                    </Typography>
                    {!customerRetentionByCollegeYearPerMonth
                        ? <Loader />
                        : (
                            <LineChart
                                data={{
                                    labels: COLLEGE_YEAR_MONTHS,
                                    datasets: reverse(collegeYears
                                        .map((collegeYear, collegeYearIndex) => {
                                            const values = customerRetentionByCollegeYearPerMonth[collegeYear];
                                            return {
                                                label: `${collegeYear - 2000}-${+collegeYear + 1 - 2000}`,
                                                ...gradientLineColor(collegeYearIndex, collegeYears.length),
                                                fill: false,
                                                data: COLLEGE_YEAR_MONTHS.map((month) => {
                                                    const index = moment.months().indexOf(month);
                                                    return values[index] && values[index].accumulatedAcquiredCustomers;
                                                }),
                                                hidden: collegeYearIndex < (collegeYears.length - 5),
                                            };
                                        })),
                                }}
                                options={LINE_CHART_OPTION_DEFAULTS}
                                height={200}
                            />
                        )}

                </StyledPaper>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <StyledPaper className={classes.paper}>
                    <Typography variant="subtitle1" gutterBottom>
                        Number of courses per customer
                    </Typography>
                    {!customerRetentionByCollegeYearPerMonth
                        ? <Loader /> : (
                            <LineChart
                                data={{
                                    labels: COLLEGE_YEAR_MONTHS,
                                    datasets: reverse(collegeYears
                                        .map((collegeYear, collegeYearIndex) => {
                                            const values = customerRetentionByCollegeYearPerMonth[collegeYear];
                                            return {
                                                label: `${collegeYear - 2000}-${~~collegeYear + 1 - 2000}`,
                                                ...gradientLineColor(collegeYearIndex, collegeYears.length),
                                                fill: false,
                                                data: COLLEGE_YEAR_MONTHS.map((month) => {
                                                    const index = moment.months().indexOf(month);
                                                    return values[index] && values[index].accumulatedRetention;
                                                }),
                                                hidden: collegeYearIndex < (collegeYears.length - 5),
                                            };
                                        })),
                                }}
                                options={LINE_CHART_OPTION_DEFAULTS}
                                height={200}
                            />
                        )}
                </StyledPaper>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <StyledPaper className={classes.paper}>
                    <StyledInfoTooltip
                        infoTitle="Financial - audience"
                        infoContent={tooltipContent.audience}
                        className={classes.infoTooltip}
                        floatRight
                    />
                    <Typography variant="subtitle1" gutterBottom>
                        Audience
                    </Typography>
                    {!customerRetentionByCollegeYearPerMonth
                        ? <Loader /> : (
                            <LineChart
                                data={{
                                    labels: COLLEGE_YEAR_MONTHS,
                                    datasets: reverse(collegeYears
                                        .map((collegeYear, collegeYearIndex) => {
                                            const values = customerRetentionByCollegeYearPerMonth[collegeYear];
                                            return {
                                                label: `${collegeYear - 2000}-${~~collegeYear + 1 - 2000}`,
                                                ...gradientLineColor(collegeYearIndex, collegeYears.length),
                                                fill: false,
                                                data: COLLEGE_YEAR_MONTHS.map((month) => {
                                                    const index = moment.months().indexOf(month);
                                                    return values[index] && values[index].accumulatedReach
											&& typeof values[index].accumulatedReach === 'number'
											&& +(values[index].accumulatedReach * 100).toFixed(2);
                                                }),
                                            };
                                        })),
                                }}
                                options={{
                                    ...LINE_CHART_OPTION_DEFAULTS,
                                    scales: {
                                        y: {
                                            ticks: {
                                                callback(tick) {
                                                    return `${(tick).toLocaleString()}%`;
                                                },
                                            },
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            reverse: true,
                                        },
                                        tooltip: {
                                            ...LINE_CHART_OPTION_DEFAULTS.plugins.tooltip,
                                            callbacks: {
                                                ...LINE_CHART_OPTION_DEFAULTS.plugins.tooltip.callbacks,
                                                label: (value) => `${value.parsed.y.toLocaleString()}%`,
                                            },
                                        },
                                    },
                                }}
                                height={200}
                            />
                        )}
                </StyledPaper>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <StyledPaper className={classes.paper}>
                    {renderRetention()}
                </StyledPaper>
            </Grid>

        </Grid>
    );
}

const id = 'customerRetention';
const customerRetentionByCollegeYearPerMonth = memoizeWith(identity, customerRetention => {
    const entries = customerRetention && map(
        (entries => indexBy(
            entry => moment(entry.revenueMonth).month(),
            entries,
        )),
        groupBy((entry) => moment(entry.collegeYearStart).year(), customerRetention),
    );

    return {
        entries,
        keys: entries && Object.keys(entries),
    };
});

const mapStateToProps = state => {
    const customerRetention = state.fetchData[id] && state.fetchData[id].success;
    const cached = customerRetentionByCollegeYearPerMonth(customerRetention);

    return {
        customerRetention,
        customerRetentionByCollegeYearPerMonth: cached.entries,
        collegeYears: cached.keys,
    };
};

CustomerRetentionMetrics.propTypes = {
    customerRetention: PropTypes.array,
    customerRetentionByCollegeYearPerMonth: PropTypes.object,
    collegeYears: PropTypes.array,
};

export default withWorkingScope(
    withFetchData(null, {
        customId: () => id,
        mapStateToProps,
    })(CustomerRetentionMetrics),
);
