import { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataInternalActions } from '../modules/fetchData/fetchDataActions';

/* eslint-disable react-hooks/exhaustive-deps */
export function useDidUpdateEffect(callback, deps) {
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			callback();
		} else {
			didMountRef.current = true;
		}
	}, deps);
}

export const useFetchData = (actionId, action, parameters = []) => {
	const dispatch = useDispatch();
	const dataSelector = useCallback(({ fetchData: { [actionId]: data } }) => data, [actionId]);
	const fetchData = useSelector(dataSelector) || {};
	const { loading, success, error } = fetchData;

	useEffect(() => {
		function dispatchFetchDataActions() {
			dispatch(fetchDataInternalActions.fetchDataLoading(actionId));
			const promise = dispatch(action(...parameters));
			promise.then((res) => {
				if (res && res.errorCode) {
					// Exclude 401 error and let other systems handle it
					if (res.errorCode !== 401) {
						return dispatch(fetchDataInternalActions.fetchDataError(actionId, res));
					}
				}

				const pagination = res && res.response && res.response.pagination;
				const result = res && res.response && res.response.result;
				const cache = res && res.response && res.response.cache;
				return dispatch(fetchDataInternalActions.fetchDataSuccess(actionId, result || true, pagination || false, cache || false));
			});
		}
		if (!success && !loading && action) dispatchFetchDataActions();
	}, [Array.isArray(success)]);

	return { loading, data: Array.isArray(success) ? success : [], error };
};

export function usePrevious(value) {
	const ref = useRef();
	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes
	// Return previous value before update in useEffect above
	return ref.current;
}
