import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys, pick } from 'ramda'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { useTranslation, withTranslation } from 'react-i18next';
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm'
import FormDataError from '../../modules/formData/FormDataError'
import { patchEmployeeProfile } from '../../actions/employeeActions'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'

class ProfileEditBankForm extends Component {
	render(){
		const { validation, validationBlur, fields, onChangeField, saving, success, error, t } = this.props;
		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Updated details saved.
			</Alert>
		);

		return (
			<div>
				{ saving && <Loader sheet /> }
				<FormDataError error={error} showFieldErrors />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField name="IBAN"
								   value={fields.IBAN}
								   onChange={onChangeField}
								   label={t('employee.fields.IBAN')}
								   error={!!validation['IBAN']}
								   helperText={validation['IBAN']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="BIC"
								   value={fields.BIC}
								   onChange={onChangeField}
								   label={t('employee.fields.BIC')}
								   error={!!validation['BIC']}
								   helperText={validation['BIC']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="bankPayee"
								   value={fields.bankPayee}
								   onChange={onChangeField}
								   label={t('employee.fields.bankPayee')}
								   error={!!validation['bankPayee']}
								   helperText={validation['bankPayee']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="identificationNumber"
								   value={fields.identificationNumber || ''}
								   onChange={onChangeField}
								   label={t('employee.fields.identificationNumber')}
								   error={!!validation['identificationNumber']}
								   helperText={validation['identificationNumber']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
				</Grid>
			</div>
		)
	}

	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.onSave();
		}
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields, validationFields } = this.props;

		watchSubmit(patchEmployeeProfile(
			inputData.id, pick(keys(validationFields), fields)
		));
	}
}

ProfileEditBankForm.propTypes = {
	inputData: PropTypes.object.isRequired,
};

export default withTranslation()(withCompleteBasicForm(ProfileEditBankForm, {
	IBAN: Joi.string().required(),
	BIC: Joi.string().required(),
	bankPayee: Joi.string().required(),
	identificationNumber: Joi.string().required(),
}))
