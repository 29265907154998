import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchData } from '../../helpers/hooks';
import { choiceLabelByKey, propertyLabel } from '../../helpers/properties';
import Loader from '../util/loader/Loader';

const PREFIX = 'PropertyTags';

const classes = {
    chipContainer: `${PREFIX}-chipContainer`,
    chip: `${PREFIX}-chip`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.chipContainer}`]: {
		display: 'inline-block',
	},

    [`& .${classes.chip}`]: {
		margin: theme.spacing(1, 1, 1, 0),
	}
}));

const PropertyTags = ({ actionId, properties }) => {
	const { t, i18n } = useTranslation();

	const { data: propertiesDefinition } = useFetchData(actionId);
	if (!propertiesDefinition) {
		return <Loader />;
	}

	const filteredProperties = propertiesDefinition
		.filter(prop => !!properties?.[prop.key])
		.map(prop => ({
			key: prop.key,
			options: prop.options,
			type: prop.type,
			value: properties?.[prop.key],
		}));

	if (filteredProperties.length === 0) return null;

	return (
        <Root className={classes.chipContainer}>
			{filteredProperties.map(prop => {
				if (prop.type === 'boolean') {
					return (
						<Chip
							label={
								`${propertyLabel(prop, i18n.language)}:
								${prop.value?.map(el => el ? t('general.yes') : t('general.no')).join(', ')}`
							}
							key={prop.key}
							className={classes.chip}
						/>
					);
				}
				return (
					<Chip
						key={prop.key}
						label={
							`${propertyLabel(prop, i18n.language)}:
							${prop.value?.map(el => choiceLabelByKey(el, prop.options, i18n.language)).join(', ')}`
						}
						className={classes.chip}
					/>
				);
			})}
		</Root>
    );
};

PropertyTags.propTypes = {
	actionId: PropTypes.string.isRequired,
	properties: PropTypes.object,
};

export default PropertyTags;
