import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import PageContentBlock from '../../page/PageContentBlock'
import { sortKey } from '../../../helpers/sorts'
import Alert from '../../util/alert/Alert'
import FetchDataSwitch from '../../util/FetchDataSwitch'
import ResponsiveTable from '../../util/ResponsiveTable'
import { sortByStatus } from '../utils'
import ClientOrderTableBody from './ClientOrderTableBody'
import ClientOrderTableHeader from './ClientOrderTableHeader'

const propTypes = {
	location: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,

	data: PropTypes.arrayOf(PropTypes.object),
	loading: PropTypes.bool,
	error: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object,
	]),
}

class ClientOrderTable extends Component {

	state = {
		sortDisplay: 'orderDateTime',
		sort: 'id',
		sortDesc: true,

		sortedData: undefined,
	}

	sortData = (sort) => {
		const { data } = this.props

		return sort === 'status'
			? data.sort(sortByStatus('status'))
			: data.sort(sortKey(sort))
	}

	handleSort = id => () => {
		if (this.state.sortDisplay === id)
			this.setState({ sortDesc: !this.state.sortDesc })
		else {
			const sort = id === 'orderDateTime' ? 'id' : id
			this.setState({
				sortDisplay: id,
				sort,
				sortDesc: true,
				sortedData: this.sortData(sort),
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.data && this.props.data)
			this.setState({ sortedData: this.sortData('id') })
	}

	render() {
		const {
			state: { sortDisplay, sortDesc, sortedData },
			props: { location, client, data, loading, error },
		} = this

		return (
			<PageContentBlock
				title={'Orders'}
				description={`These are the orders ${client.name} has made.`}
				icon={'shopping_cart'}
			>
				<FetchDataSwitch
					{...{ data, loading, error }}
					ifEmptyData={(
						<Alert type={Alert.TYPE_INFO}>
							{client.name} hasn't made any orders
						</Alert>
					)}
				>
					{sortedData && (
						<ResponsiveTable>
							<TableHead>
								<ClientOrderTableHeader
									{...{ sortDisplay, sortDesc }}
									handleSort={this.handleSort}
								/>
							</TableHead>
							<TableBody>
								{(sortDesc ? [...sortedData].reverse() : sortedData).map(order => (
									<ClientOrderTableBody {...{ location, order }} key={order.id}/>
								))}
							</TableBody>
						</ResponsiveTable>
					)}
				</FetchDataSwitch>
			</PageContentBlock>
		)
	}
}

ClientOrderTable.propTypes = propTypes

export default ClientOrderTable

