import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { getSubScopes } from '../../../modules/scope/scopeActions'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'
import withRouter from '../../../hoc/withRouter'
import ScopeInstituteForm from './ScopeInstituteForm'
import ScopeFacultyForm from './ScopeFacultyForm'
import ScopeCourseForm from './ScopeCourseForm'
import ScopeAthenaStudiesCourseForm from './ScopeAthenaStudiesCourseForm'
import ScopeAthenaStudiesCourseGroupForm from './ScopeAthenaStudiesCourseGroupForm'

const scopeClasses = {
	'Institute': ScopeInstituteForm,
	'Faculty': ScopeFacultyForm,
	'Course': ScopeCourseForm,
	'AthenaStudiesCourse': ScopeAthenaStudiesCourseForm,
	'AthenaStudiesCourseGroup': ScopeAthenaStudiesCourseGroupForm,
};

const scopeClassesForParent = {
	'Company': ScopeInstituteForm,
	'Institute': ScopeFacultyForm,
	'Faculty': ScopeCourseForm,
	'Course': ScopeAthenaStudiesCourseForm,
	'AthenaStudiesCourse': ScopeAthenaStudiesCourseGroupForm,
};

const scopeNames = {
	'Institute': 'institute',
	'Faculty': 'faculty',
	'Course': 'study',
	'AthenaStudiesCourse': 'course',
	'AthenaStudiesCourseGroup': 'group',
};

const scopeNamesForParent = {
	'Company': 'institute',
	'Institute': 'faculty',
	'Faculty': 'study',
	'Course': 'course',
	'AthenaStudiesCourse': 'group',
};

const dialogContentStyle = {
	minHeight: 100
};

class ScopeEditorModal extends Component {
	state = {
		saved: false,
	}

	componentDidUpdate(prevProps, prevState){
		if(this.props.parent && this.state.saved && !prevState.saved){
			if(this.props.parent._class === 'Course'){
				this.props.navigate(`/portal/planner/course/${this.state.saved}`, { replace: true });
			}
		}
	}

	render(){
		const { handleClose, scope, parent, t } = this.props;
		const { saved } = this.state;

		return (
			<Dialog
				aria-labelledby="dialog-title"
				open={true}
				maxWidth="md"
				fullWidth
				onClose={saved ? handleClose : undefined}
			>
				{this.renderContent()}
				<DialogActions>
					<Button onClick={() => handleClose()}>
						{saved ? t('general.close') : t('general.cancel')}
					</Button>
					{(scope || parent) && this.bindSave && !saved && (
						<Button
							onClick={this.bindSave}
							variant="contained"
							color="primary"
						>
							{scope
								? `${t('general.save')} ${t(`scopes.${scopeNames[scope._class]}`)}`
								: t('general.addValue', { value: t(`scopes.${scopeNamesForParent[parent._class]}`) })
							}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}

	renderContent(){
		const { loading, error, scope, parent, t } = this.props;

		if(error) return (
			<Alert classname={Alert.TYPE_WARNING}>
				{error.error}
			</Alert>
		);

		if(loading || (!scope && !parent)) return (
			<Loader sheet />
		);

		if(scope){
			const ScopeComponent = scopeClasses[scope._class];

			return (
				<Fragment>
					<DialogTitle id="dialog-title">{t('general.edit')} {scope.name}</DialogTitle>
					<DialogContent style={dialogContentStyle}>
						{ScopeComponent
							? (
								<ScopeComponent
									key={scope.id}
									scopeId={scope.id}
									bindSave={this.handleBind}
									onSaved={this.handleSaved}
								/>
							)
							: <Alert type={Alert.TYPE_INFO}>{t('scope.edit.cannotEdit')}</Alert>
						}
					</DialogContent>
				</Fragment>
			);
		}else{
			const ParentComponent = scopeClassesForParent[parent._class];

			return (
				<Fragment>
					<DialogTitle id="dialog-title">
						{t('scope.addToParent', {
							scopeName: t(`scopes.${scopeNamesForParent[parent._class]}`),
							parentName: parent.name,
						})}
					</DialogTitle>
					<DialogContent style={dialogContentStyle}>
						{ParentComponent
							? (
								<ParentComponent
									key={parent.id}
									parentId={parent.id}
									bindSave={this.handleBind}
									onSaved={this.handleSaved}
								/>
							)
							: <Alert type={Alert.TYPE_INFO}>{t('scope.edit.cannotAddScope')}</Alert>
						}
					</DialogContent>
				</Fragment>
			);
		}
	}

	handleBind = (func) => {
		this.bindSave = func;
		this.forceUpdate(); // So the buttons render
	}

	handleSaved = (result) => {
		this.setState({
			saved: result,
		});
	}
}

export default withRouter(withFetchData((props) => {
	if(!props.scope && props.modal.scopeId){
		return getSubScopes(props.modal.scopeId);
	}

	if(!props.parent && props.modal.parentId){
		return getSubScopes(props.modal.parentId);
	}

	return null;
}, {
	mapStateToProps: (state, props) => ({
		scope: props.modal.scopeId && state.entities.scopes[props.modal.scopeId],
		parent: props.modal.parentId && state.entities.scopes[props.modal.parentId],
	}),
})(withTranslation()(ScopeEditorModal)));
