/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import React from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import { getAllScopeProperties } from '../../../actions/scopeDataActions';
import { useFetchData } from '../../../helpers/hooks';
import {
	setFilterSearch, setFilterRange, setFilterHideClosed,
	setFilterOnlyVisible, setPropertiesSearch,
} from '../../../modules/scopeFilter/scopeFilterActions';
import PropertyFiltersMenu from '../../properties/PropertyFiltersMenu';
import PropertyTags from '../../properties/PropertyTags';
import ScopeFilterSearch from './ScopeFilterSearch';
import ScopeFilterRange from './ScopeFilterRange';
import ScopeFilterHideClosed from './ScopeFilterHideClosed';
import ScopeFilterOnlyVisible from './ScopeFilterOnlyVisible';
import { scopeFilterReducerKey } from '../../../modules/scopeFilter/scopeFilterReducer';

const PREFIX = 'ScopeFilter';

const classes = {
    selectionGrid: `${PREFIX}-selectionGrid`
};

const StyledGrid = styled(Grid)({
	[`& .${classes.selectionGrid}`]: {
		paddingBottom: '0 !important',
	},
});

const ScopeFilter = ({
	filter,
	setFilterSearch, setFilterRange, setFilterHideClosed, setFilterOnlyVisible, setPropertiesSearch,
}) => {
	const { data } = useFetchData('ScopePropertiesDefinition', getAllScopeProperties);
	const handleProperty = (prop, value) => {
		let clone = { ...filter.properties };
		if (!value) {
			delete clone[prop];
		} else {
			clone = { ...filter.properties, [prop]: value };
		}
		setPropertiesSearch(clone);
	};

	return (
        <StyledGrid container spacing={2}>
			<Grid item sm={6} xs={12}>
				<ScopeFilterSearch
					onChange={setFilterSearch}
					search={filter.search}
					debounce={500}
				/>
			</Grid>
			<Grid item sm={6} xs={12} className={classes.selectionGrid}>
				<FormGroup row>
					<ScopeFilterOnlyVisible
						onChange={setFilterOnlyVisible}
						onlyVisible={filter.onlyVisible}
					/>
					<ScopeFilterHideClosed
						onChange={setFilterHideClosed}
						hideClosed={filter.hideClosed}
					/>
				</FormGroup>
			</Grid>
			<Grid item xs={12}>
				<ScopeFilterRange
					onChange={setFilterRange}
					range={filter.range}
				/>
				<PropertyFiltersMenu
					handleProperty={handleProperty}
					propertiesDefinition={data}
					propertiesState={filter.properties}
				/>
				<PropertyTags actionId="ScopeProperties" properties={filter.properties} />
			</Grid>
		</StyledGrid>
    );
};

export default connect(state => ({
    filter: state[scopeFilterReducerKey],
}), {
    setFilterSearch,
    setFilterRange,
    setFilterHideClosed,
    setFilterOnlyVisible,
    setPropertiesSearch,
})(ScopeFilter);
