import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import fireworksBg from '../media/fireworks-background.png';
const PREFIX = 'LiveSplashBack';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    root: `${PREFIX}-root`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.wrapper}`]: {
		flex: 1,
		display: 'flex',
		background: '#FFFFFF',
	},

    [`&.${classes.root}`]: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(2),
		backgroundImage: `url(${fireworksBg})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		margin: theme.spacing(2),
		boxShadow: theme.shadows[2],
		borderRadius: 6,
	}
}));

const LiveSplashBack = ({ children, }) => {
	return (
        <Root className={classes.wrapper}>
			<div className={classes.root}>
				{children}
			</div>
		</Root>
    );
};

LiveSplashBack.propTypes = {
	children: PropTypes.node.isRequired,
};

export default (LiveSplashBack)
