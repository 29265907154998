import React from 'react'
import PropTypes from 'prop-types'
import { mapObjIndexed, values } from 'ramda'
import Alert from '../../components/util/alert/Alert'

/**
 * TODO: Deprecate at some point in favor of ApiError
 * @deprecated use ApiError instead
 * @param error
 * @param showFieldErrors
 * @returns {*}
 * @constructor
 */
const FormDataError = ({ error, showFieldErrors }) => {
	if(!error) return null;

	return (
		<Alert type={Alert.TYPE_WARNING} >
			{
				showFieldErrors && error.errors &&
				values(mapObjIndexed(
					(num, key) => <div key={key}>{key}: {num}</div>,
					error.errors
				))
				|| error.error
			}
		</Alert>
	);
};

FormDataError.propTypes = {
	error: PropTypes.any,
	showFieldErrors: PropTypes.bool,
};

FormDataError.defaultProps = {
	error: null,
	showFieldErrors: false,
};

export default FormDataError
