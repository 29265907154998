import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import { findIsoFromCountry, getCountrySelectOptions } from '../../helpers/countries'
import CountrySearchModal from './CountrySearchModal'

const countryOptions = getCountrySelectOptions();

class CountryField extends Component {
	constructor(props){
		super(props);

		const iso = findIsoFromCountry(props.value);
		const country = iso && countryOptions.find(country => country.value === iso);

		this.state = {
			show: false,
			country: country,
		};
	}

	componentDidUpdate(prevProps){
		if(this.props.value !== prevProps.value){
			const iso = findIsoFromCountry(this.props.value);
			const country = iso && countryOptions.find(country => country.value === iso);

			this.setState({
				country: country,
			});
		}
	}

	render(){
		const { suggestions } = this.props;
		const { show, country } = this.state;

		return (
			<Fragment>
				<TextField
					label="Country"
					value={country ? country.label : ''}
					onClick={this.toggleShow}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<Icon>search</Icon>
								</IconButton>
							</InputAdornment>
						)
					}}
					{...omit(['onClick', 'value', 'onChange', 'suggestions'], this.props)}
				/>
				{show && (
					<CountrySearchModal
						onPick={this.handlePick}
						onClose={this.toggleShow}
						suggestions={suggestions}
					/>
				)}
			</Fragment>
		);
	}

	toggleShow = () => {
		this.setState(state => ({
			show: !state.show,
		}));
	}

	handlePick = (country) => {
		const { onChange } = this.props;

		this.setState({
			show: false,
			country: country,
		});

		onChange(country.value);
	}
}

CountryField.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	suggestions: PropTypes.array,
};

export default CountryField
