import FormHelperText from '@mui/material/FormHelperText';
import Icon from '@mui/material/Icon';
import React from 'react';
import { FormLabel, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ControlledScopeGroupSearchField } from '../../scope/search/ScopeGroupSearchField';
import AmountInputField from '../../util/AmountInputField';
import { ControlledCostAccountSelector } from '../../util/CostAccountSelector';
import { ControlledValuePicker } from '../../util/SelectValueMenu';
import { DeclarationContexts, useDeclarationContext } from './DeclarationContext';
import DeclarationEntryAttachmentForm from './DeclarationEntryAttachmentForm';

const StyledGridSection = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.grey[100],
	margin: theme.spacing(0, 0, 2),
	width: '100%',
	border: `1px solid ${theme.palette.grey[500]}`,
	paddingRight: theme.spacing(2),
	borderRadius: theme.shape.borderRadius,
	'&:last-of-type': {
		marginBottom: 0,
	},
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
	display: 'block',
	marginBottom: theme.spacing(1),
	fontWeight: theme.typography.fontWeightMedium,
	color: theme.palette.common.black,
}));

function DeclarationEntryForm({ control, index, errors, register, remove }) {
	const context = useDeclarationContext();
	const { t } = useTranslation();
	const isProfileContext = context === DeclarationContexts.PROFILE;
	return (
		<StyledGridSection container spacing={2} component="section">
			<Grid item xs={12} md={6}>
				<StyledFormLabel htmlFor={`entries[${index}].accountId`}>
					{t('declarations.entry.accountId')}
				</StyledFormLabel>
				<ControlledCostAccountSelector
					control={control}
					name={`entries[${index}].accountId`}
					variant="outlined"
					sx={{ backgroundColor: 'common.white' }}
					size="small"
					fullWidth
				/>
				<FormHelperText sx={{ color: 'error.main' }}>
					{errors.entries?.[index]?.accountId?.message}
				</FormHelperText>
			</Grid>
			<Grid item xs={12} md={6}>
				<StyledFormLabel htmlFor={`entries[${index}].scope`}>
					{t('declarations.entry.courseGroup')}
				</StyledFormLabel>
				<ControlledScopeGroupSearchField
					control={control}
					name={`entries[${index}].scope`}
				/>
				<FormHelperText sx={{ color: 'error.main' }}>
					{errors.entries?.[index]?.scope?.message}
				</FormHelperText>
			</Grid>
			<Grid item xs={12}>
				<StyledFormLabel htmlFor={`entries[${index}].currency`}>
					{t('general.amount')}
				</StyledFormLabel>
				<ControlledValuePicker
					options={['EUR', 'GBP']}
					control={control}
					name={`entries[${index}].currency`}
					style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
					variant="outlined"
					size="small"
					sx={{ backgroundColor: 'common.white' }}
				/>
				<TextField
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						inputComponent: AmountInputField,
						sx: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, ml: '-1px', backgroundColor: 'common.white' },
					}}
					{...register(`entries[${index}].amount`)}
					error={!!errors.entries?.[index]?.amount}
					helperText={errors.entries?.[index]?.amount?.message}
					variant="outlined"
					size="small"
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<StyledFormLabel htmlFor={`entries[${index}].description`}>
					{t('declarations.entry.description')}
				</StyledFormLabel>
				<TextField
					{...register(`entries[${index}].description`)}
					type="text"
					placeholder={t('declarations.entry.descriptionPlaceholder')}
					fullWidth
					error={!!errors.entries?.[index]?.description}
					helperText={errors.entries?.[index]?.description?.message}
					id={`entries[${index}].description`}
					variant="outlined"
					InputProps={{
						sx: { backgroundColor: 'common.white' },
					}}
					size="small"
					multiline
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<StyledFormLabel>
					{t('declarations.entry.attachments')}
				</StyledFormLabel>
				<DeclarationEntryAttachmentForm
					control={control}
					index={index}
					errors={errors}
				/>
			</Grid>
			{isProfileContext && (
				<Grid item xs={12}>
					<Button
						color="error"
						onClick={() => remove(index)}
						startIcon={<Icon>delete</Icon>}
						sx={{ mb: 1 }}
					>
						{t('declarations.entry.deleteEntry')}
					</Button>
				</Grid>
			)}
		</StyledGridSection>
	);
}

DeclarationEntryForm.propTypes = {
	field: PropTypes.object,
	control: PropTypes.object,
	index: PropTypes.number,
	errors: PropTypes.object,
	remove: PropTypes.func,
	register: PropTypes.func,
};

export default DeclarationEntryForm;
