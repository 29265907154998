import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import PageHeader from '../page/PageHeader';
import PageHeaderTitle from '../page/PageHeaderTitle';
import PageHeaderNavigation from '../page/PageHeaderNavigation';
import PageContentBlock from '../page/PageContentBlock';
import CourseGroupCostsList from './CourseGroupCostsList';
import PageHeaderMenu from '../page/PageHeaderMenu';
import ModalManager from '../modal/ModalManager';
import ModalItem from '../modal/ModalItem';
import CourseGroupCostsAddModal from './CourseGroupCostsAddModal';
import withFetchData from '../../modules/fetchData/withFetchData';
import { getCosts } from '../../actions/costActions';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';

function CourseGroupCostsPage({ course, group, loading, error, data }) {
    const location = useLocation();
    if (error) return (
        <Alert type={Alert.TYPE_WARNING}>{error}</Alert>
    );

    if (loading || !data) return (
        <Loader />
    );

    return (
        <>
            <ModalManager>
                <ModalItem state="add-costs" component={CourseGroupCostsAddModal} group={group} />
            </ModalManager>
            <PageHeader>
                <PageHeaderMenu items={[
                    { name: 'Add costs', to: location.pathname, state: { modal: 'add-costs' }, icon: 'add' },
                ]}
                />
                <PageHeaderNavigation breadcrumbs={[
                    { to: '/portal/planner/', label: 'Planner' },
                    { to: `/portal/planner/course/${course.id}`, label: course.name },
                    { to: `/portal/planner/course/${course.id}/group/${group.id}`, label: group.name },
                    { label: 'Manage costs' },
                ]}
                />
                <PageHeaderTitle
                    headline="Manage costs"
                    subHeading={`${course.name} - ${group.name}`}
                />
            </PageHeader>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PageContentBlock>
                        <CourseGroupCostsList
                            costs={data}
                            group={group}
                        />
                    </PageContentBlock>
                </Grid>
            </Grid>
        </>
    );
}

CourseGroupCostsPage.propTypes = {
    course: PropTypes.object,
    group: PropTypes.object,
};

export default withFetchData((props) => getCosts(props.group.id), {
    customId: () => 'courseGroupCostsPage',
    mapEntities: 'scopeCosts',
})(CourseGroupCostsPage);
