import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withDialogShouldBeFullscreen from '../../../hoc/withDialogShouldBeFullscreen'
import ApplicationsCreateForm from '../../applications/ApplicationsCreateForm'

function applicationFunctionFilter(func){
	return func.hasDueDate || func.baseSalary;
}

function applicationPreSelectFunctionFilter(func){
	return func.hasDueDate;
}

class CourseBookletsCreateModal extends Component {
	saveBinder

	state = {
		saving: false,
	}

	toggleSaving = saving => this.setState({ saving })

	handleSuccess = () => {
		const { onCreateSuccess, handleClose } = this.props
		onCreateSuccess()
		handleClose()
	}

	render() {
		const { course, handleClose, fullScreen } = this.props
		return (
			<Dialog
				open={true}
				onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)}
				maxWidth="md"
				fullWidth
				fullScreen={fullScreen}
			>
				<DialogContent>

					<ApplicationsCreateForm
						workingScope={course}
						functionFilter={applicationFunctionFilter}
						preSelectFunctionFilter={applicationPreSelectFunctionFilter}
						customForm
						bindSave={fn => this.saveBinder = fn}
						toggleSaving={this.toggleSaving}
						handleSuccess={this.handleSuccess}
					/>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button
						onClick={() => this.saveBinder && this.saveBinder()}
						color="primary"
						variant="contained"
						disabled={this.state.saving}
					>
						Create application
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

CourseBookletsCreateModal.propTypes = {
	course: PropTypes.object.isRequired,
	onCreateSuccess: PropTypes.func.isRequired,
}

export default withDialogShouldBeFullscreen()(CourseBookletsCreateModal)
