import { styled, Snackbar, SnackbarContent } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux'
import { green, amber } from '@mui/material/colors'
import { notificationHide, notificationQueue } from './notificationActions'

const PREFIX = 'NotificationHandler';
const classes = {
	success: `${PREFIX}-success`,
	warning: `${PREFIX}-warning`,
	error: `${PREFIX}-error`,
	info: `${PREFIX}-info`,
}

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
	[`&.${classes.success}`]: {
		backgroundColor: green[600],
	},
	[`&.${classes.warning}`]: {
		backgroundColor: amber[700],
	},
	[`&.${classes.error}`]: {
		backgroundColor: theme.palette.error.dark,
	},
	[`&.${classes.info}`]: {
		backgroundColor: theme.palette.primary.dark,
	},
}));

const NotificationHandler = ({ open, message, notificationHide, notificationQueue }) => (
	<Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
			  open={open}
			  autoHideDuration={3000}
			  onClose={(e, reason) => {
				  if(reason !== 'clickaway') notificationHide(reason)
			  }}
			  TransitionProps={{
				  onExited: notificationQueue,
			  }}
	>
		<StyledSnackbarContent
			message={message && message.content}
			className={message && classes[message.color]}
		/>
	</Snackbar>
);

export default connect(state => {
	return {
		message: state.notification && state.notification.message,
		open: state.notification && state.notification.open,
	}
}, {
	notificationHide,
	notificationQueue,
})(NotificationHandler)
