import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import withFetchData from '../../modules/fetchData/withFetchData'
import { CALL_API } from '../../setup/api'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import { niceFullDateYear } from '../../helpers/datetime'

const ApiAction = (bundleId) => ({
	[CALL_API]: {
		type: 'BUNDLE_ORDERS',
		endpoint: `athenastudies-course-bundle/${bundleId}/order`,
	},
});

const BundleOrders = ({ loading, error, data }) => {
	if(error) return (
		<ApiError error={error} />
	);

	if(loading || !data) return (
		<Loader />
	);

	if(data.length === 0) return (
		<Alert>No orders with this bundle yet.</Alert>
	);

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Order id</TableCell>
					<TableCell>Client</TableCell>
					<TableCell>Order date</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map(order => (
					<TableRow key={order.id}>
						<TableCell>{order.id}</TableCell>
						<TableCell>{order.client.fullName}</TableCell>
						<TableCell>{niceFullDateYear(order.orderDateTime)}</TableCell>
						<TableCell align="right" padding="checkbox">
							<IconButton
								component={Link}
								to={`/portal/customers/${order.client.id}`}
								target="_blank"
							>
								<Icon>open_in_new</Icon>
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

BundleOrders.propTypes = {
	id: PropTypes.number.isRequired,
};

export default withFetchData((props) => ApiAction(props.id))(BundleOrders)
