import Grid from '@mui/material/Grid';
import React from 'react';
import { getProfile } from '../../actions/employeeActions';
import withRouter from '../../hoc/withRouter';
import withFetchData from '../../modules/fetchData/withFetchData';
import PageContentBlock from '../page/PageContentBlock';
import PageHeader from '../page/PageHeader';
import PageHeaderMenu from '../page/PageHeaderMenu';
import PageHeaderNavigation from '../page/PageHeaderNavigation';
import PageHeaderTitle from '../page/PageHeaderTitle';
import EmployeeCourseGroupLedgerOverview from './EmployeeCourseGroupLedgerOverview';
import EmployeePaymentsOverview from './EmployeePaymentsOverview';

function EmployeeFinancePage({ data, params }) {
	const employee = data || {};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PageHeader>
					<PageHeaderNavigation
						breadcrumbs={[
							{ label: 'Employees', to: '/portal/employees' },
							{ label: `${employee.name || ''}`, to: `/portal/employees/${employee.id}/view` },
							{ label: 'Finance' },
							{ label: params.month },
						]}
					/>
					<PageHeaderMenu items={[]} />
					<PageHeaderTitle headline={`${employee.fullName || ''}`} />
				</PageHeader>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PageContentBlock title="Finances" icon="attach_money">
							<EmployeeCourseGroupLedgerOverview
								params={params}
								linkToCourse
							/>
						</PageContentBlock>
					</Grid>
					<Grid item xs={12}>
						<PageContentBlock title="Payments" icon="money">
							<EmployeePaymentsOverview params={params} />
						</PageContentBlock>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default withRouter(withFetchData((props) => getProfile(props.params.employeeId), {
	mapEntities: 'users',
})(EmployeeFinancePage));
