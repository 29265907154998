import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import moment from 'moment'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next';
import { niceDateDay, niceTime } from '../../../helpers/datetime'
import ZoomMeetingRedirectHostStart from '../../course/zoomMeeting/ZoomMeetingRedirectHostStart'

const PREFIX = 'TeacherAgendaTimeSlotRow';

const classes = {
    timeSlotWrapper: `${PREFIX}-timeSlotWrapper`,
    timeSlotInfo: `${PREFIX}-timeSlotInfo`,
    timeSlotMeta: `${PREFIX}-timeSlotMeta`,
    timeSlotDate: `${PREFIX}-timeSlotDate`,
    timeSlotDateFinished: `${PREFIX}-timeSlotDateFinished`,
    timeSlotDetails: `${PREFIX}-timeSlotDetails`,
    classRoom: `${PREFIX}-classRoom`,
    noClassRoom: `${PREFIX}-noClassRoom`,
    teacher: `${PREFIX}-teacher`,
    noTeacher: `${PREFIX}-noTeacher`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.timeSlotWrapper}`]: {
		display: 'flex',
		alignItems: 'center',
		padding: '0.7em 0',
        [`& + .${classes.timeSlotWrapper}`]: {
			borderTop: theme.borderStyle,
		},
	},

    [`& .${classes.timeSlotInfo}`]: {
		flex: 1,
	},

    [`& .${classes.timeSlotMeta}`]: {

	},

    [`& .${classes.timeSlotDate}`]: {
		fontWeight: theme.typography.fontWeightBold,
	},

    [`& .${classes.timeSlotDateFinished}`]: {
		color: theme.palette.text.disabled,
	},

    [`& .${classes.timeSlotDetails}`]: {
		color: theme.palette.text.disabled,
	},

    [`& .${classes.classRoom}`]: {
		color: theme.palette.text.disabled,
	},

    [`& .${classes.noClassRoom}`]: {
		color: theme.palette.error.main,
		fontStyle: 'italic',
	},

    [`& .${classes.teacher}`]: {
		color: theme.palette.text.disabled,
	},

    [`& .${classes.noTeacher}`]: {
		color: theme.palette.error.main,
		fontStyle: 'italic',
	}
}));

const TeacherAgendaTimeSlotRow = ({ timeSlot: {
	startDateTime, endDateTime, classRoom, teacher, zoomMeetingId
}, timeSlot }) => {
	const { t, i18n } = useTranslation();
	const isFinished = moment(endDateTime).isBefore();

	return (
        <Root className={classes.timeSlotWrapper}>
			<div className={classes.timeSlotInfo}>
				<Typography className={!isFinished ? classes.timeSlotDate : classes.timeSlotDateFinished}>
					{niceDateDay(startDateTime, i18n.language)}
				</Typography>
				<Typography className={!isFinished ? classes.timeSlotDate : classes.timeSlotDateFinished}>
					{niceTime(startDateTime, i18n.language)} - {niceTime(endDateTime, i18n.language)}
				</Typography>
				<Typography className={classRoom ? classes.classRoom : classes.noClassRoom}>
					{classRoom
						? `${classRoom.name} ${classRoom.location.city} (${classRoom.location.street} ${classRoom.location.houseNumber})`
						: t('dashboard.teacherAgenda.noClassroom')}
				</Typography>
				<Typography className={teacher ? classes.teacher : classes.noTeacher}>
					{teacher ? teacher.fullName : t('dashboard.teacherAgenda.noTeacher')}
				</Typography>
			</div>
			{Boolean(zoomMeetingId) && (
				<div className={classes.timeSlotMeta}>
					<ZoomMeetingRedirectHostStart timeSlot={timeSlot} label="Start Zoom" />
				</div>
			)}
		</Root>
    );
}

TeacherAgendaTimeSlotRow.propTypes = {
	timeSlot: PropTypes.object.isRequired,
};

export default (TeacherAgendaTimeSlotRow)
