import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { withTranslation } from 'react-i18next';
import withDialogShouldBeFullscreen from '../../../hoc/withDialogShouldBeFullscreen'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { debounce } from '../../../helpers/search'
import { scopeParentsNameString, formatCourseGroupName } from '../../../helpers/scope'
import { niceDateYear } from '../../../helpers/datetime'
import { searchCourseGroups } from '../../../actions/courseGroupActions'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'

const PREFIX = 'ScopeGroupSearchModal';

const classes = {
    gutters: `${PREFIX}-gutters`,
    list: `${PREFIX}-list`,
    field: `${PREFIX}-field`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.list}`]: {
		marginLeft: `-${theme.spacing(3)}`,
		marginRight: `-${theme.spacing(3)}`,
	},

    [`& .${classes.field}`]: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	}
}));

const MobileDialog = withDialogShouldBeFullscreen()(StyledDialog);

const ListItemPadding = ListItem;

function formatSecondary(scope, t){
	return [
		scopeParentsNameString(
			scope.parent ? scope.parent.parent : null,
			'Company'
		),
		scope.startDateTime ? `started ${niceDateYear(scope.startDateTime)}` : 'no timeslots',
		t(`groupStates.${scope.state}.name`),
	].join(' / ');
}

class ScopeGroupSearchModal extends Component {
	constructor(props){
		super(props);

		this.handleLoad = debounce(this.load, 400);

		this.state = {
			search: '',
		};
	}

	render(){
		const { onClose, onPick, error, loading, data, t } = this.props;
		const { search } = this.state;

		return (
            <MobileDialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
				<DialogTitle>
					Find a course group
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Find course groups within the access of your contracts which are not financially closed yet. This means it will only find course groups which are not yet paid out.
					</DialogContentText>
					<TextField
						autoFocus
						label="Search course group"
						fullWidth
						value={search}
						onChange={this.handleChange}
						className={classes.field}
					/>
					{error && (
						<Alert type={Alert.TYPE_WARNING}>
							{error.error}
						</Alert>
					)}
					{search.length === 0 ? (
						<DialogContentText>
							<em>Enter a search term first.</em>
						</DialogContentText>
					) : (loading || !data) ? (
						<Loader />
					) : data.length === 0 ? (
						<Alert type={Alert.TYPE_INFO}>
							No course groups found for this search term within the scope groups you have access to through your contracts.
						</Alert>
					) : (
						<List className={classes.list}>
							{data.map(scope => (
								<ListItemPadding
                                    button
                                    key={scope.id}
                                    onClick={() => onPick(scope)}
                                    classes={{
                                        gutters: classes.gutters
                                    }}>
									<ListItemText
										primary={formatCourseGroupName(scope)}
										secondary={formatSecondary(scope, t)}
									/>
								</ListItemPadding>
							))}
						</List>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="secondary">
						Cancel
					</Button>
				</DialogActions>
			</MobileDialog>
        );
	}

	handleChange = (e) => {
		this.setState({
			search: e.target.value,
		});

		this.handleLoad(e.target.value);
	}

	load = (search) => {
		const { load } = this.props;

		load(searchCourseGroups(search));
	}
}

ScopeGroupSearchModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onPick: PropTypes.func.isRequired,
};

export default withTranslation()(withFetchData()(ScopeGroupSearchModal))
