import { styled } from '@mui/material/styles';
import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Alert from '../util/alert/Alert'
import BundleView from './BundleView'
import BundleOrders from './BundleOrders'

const PREFIX = 'BundleViewModal';

const classes = {
    title: `${PREFIX}-title`,
    titleText: `${PREFIX}-titleText`,
    titleMetadata: `${PREFIX}-titleMetadata`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.title}`]: {
		display: 'flex',
	},

    [`& .${classes.titleText}`]: {
		flex: 1,
		marginRight: theme.spacing(1),
	},

    [`& .${classes.titleMetadata}`]: {
		color: theme.palette.text.disabled,
		fontSize: '0.8rem',
	}
}));

const BundleViewModal = ({ modal, handleClose }) => {

	const [ viewOrders, setViewOrders ] = useState(false);

	const toggleViewOrders = () =>
		setViewOrders(viewOrders => !viewOrders);

	return (
        <StyledDialog open onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle className={classes.title}>
				<Typography variant="h6" component="span" className={classes.titleText}>
					Viewing product bundle
					<div className={classes.titleMetadata}>
						Bundle id: #{modal.id}
					</div>
				</Typography>
				<div>
					<Button variant="contained" color="default" size="small" onClick={toggleViewOrders}>
						{viewOrders ? 'View bundle' : 'View orders'}
					</Button>
				</div>
			</DialogTitle>
			<DialogContent>
				{modal.id ? (
					viewOrders ? (
						<BundleOrders id={modal.id} />
					) : (
						<BundleView id={modal.id} />
					)
				) : (
					<Alert type={Alert.TYPE_DANGER}>
						For some reason there was no bundle id provided. Contact a developer.
					</Alert>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					variant="outlined"
					color="secondary"
				>
					Close
				</Button>
			</DialogActions>
		</StyledDialog>
    );
};

export default BundleViewModal
