import { ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import Collapse from '@mui/material/Collapse';
import { concat, uniq } from 'ramda';
import NavLink from '../util/NavLink';

const PREFIX = 'HeaderMenuItem';

const classes = {
    nested: `${PREFIX}-nested`,
    icon: `${PREFIX}-icon`,
    link: `${PREFIX}-link`,
    activeLink: `${PREFIX}-activeLink`
};

const StyledList = styled(List)(({ theme }) => ({
	[`&.${classes.nested}`]: {
		paddingLeft: theme.spacing(3),
	},
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    [`& .${classes.icon}`]: {
		color: theme.palette.text.primary,
	},

    [`& .${classes.link}`]: {
		color: 'inherit',
		'&:link, &:visited': {
			color: 'inherit',
		},
	},

    [`& .${classes.activeLink}`]: {
		backgroundColor: theme.palette.primary.dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	}
}));

const HeaderMenuItem = ({ label, to, icon, children, onOpen, open, capability, capabilities, depth }) => {
	if (capability) {
		if (!capabilities) return null;

		if (Array.isArray(capability)) {
			if (uniq(concat(capabilities, capability)).length === (capabilities.length + capability.length)) return null;
		} else if (capabilities.indexOf(capability) === -1) return null;
	}

	if (!children) return (
		<StyledListItem disablePadding>
			<ListItemButton component={NavLink} to={to} className={classes.link} activeClassName={classes.activeLink}>
				{icon ? (
					<ListItemIcon><Icon className={classes.icon}>{icon}</Icon></ListItemIcon>
				) : (
					(depth || 0) > 0
						&& <ListItemIcon><Icon className={classes.icon}>folder</Icon></ListItemIcon>
				)}
				<ListItemText primary={label} />
			</ListItemButton>
		</StyledListItem>
	);

	return (
        (<>
            <StyledListItem button onClick={() => onOpen(to)}>
				{
					icon
						? (
							<ListItemIcon><Icon className={classes.icon}>{icon}</Icon></ListItemIcon>)
						: (
							(depth || 0) > 0 && <ListItemIcon><Icon className={classes.icon}>folder</Icon></ListItemIcon>
						)
				}
				<ListItemText primary={label} />
				<Icon className={classes.icon}>
					{open === to ? 'expand_less' : 'expand_more'}
				</Icon>
			</StyledListItem>
            <Collapse in={open === to} timeout="auto">
				<StyledList disablePadding className={classes.nested} dense>
					{Children.map(children, child => React.cloneElement(child, {
						capabilities,
						depth: (depth || 0) + 1,
					}))}
				</StyledList>
			</Collapse>
        </>)
    );
};

HeaderMenuItem.propTypes = {
	label: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	icon: PropTypes.string,
	capability: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
	]),
	onOpen: PropTypes.func,
	open: PropTypes.string,
	capabilities: PropTypes.array,
	depth: PropTypes.number,
	children: PropTypes.node,
};

export default (HeaderMenuItem);
