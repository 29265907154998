import { Divider, Icon, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { clone, equals } from 'ramda';
import React, { lazy, Suspense, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { composePatchScope, editScopeTypes } from '../../../../actions/scopeEditActions';
import { languages } from '../../../../helpers/localization';
import withFormData from '../../../../modules/formData/withFormData';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import Alert from '../../../util/alert/Alert';
import Loader from '../../../util/loader/Loader';

const MarkdownEditor = lazy(() => import('../../../util/markdown/MarkdownEditor'));

const CompanyFAQMarkdownEditor = ({ selectedItem, handleClose, topLevelScope, watchSubmit, saving, error }) => {
	const [activeLang, setActiveLang] = useState('nl');
	const [title, setTitle] = useState(typeof selectedItem.title === 'string' ? { nl: selectedItem.title } : selectedItem.title);
	const [content, setContent] = useState(typeof selectedItem.content === 'string' ? { nl: selectedItem.content } : selectedItem.content);
	const { t } = useTranslation();

	const isDirty = !equals(title, selectedItem.title) || !equals(content, selectedItem.content);

	const handleRevert = () => {
		setTitle(selectedItem.title);
		setContent(selectedItem.content);
	};

	const handleSave = () => {
		const { sectionIndex, itemIndex } = selectedItem;
		const properties = clone(topLevelScope.properties);
		const newFAQ = properties.faq || [];
		const { items } = newFAQ[sectionIndex];

		const currentItem = items[itemIndex];
		if (currentItem) {
			currentItem.title = title;
			currentItem.content = content;
		} else {
			items.push({ title, content });
		}
		newFAQ[sectionIndex].items = items;
		watchSubmit(composePatchScope(editScopeTypes.COMPANY)(topLevelScope.id, {
			properties,
		}));
		handleClose();
	};

	const handleTitleChange = lang => (event) => {
		setTitle({
			...title,
			[lang]: event.target.value,
		});
	};

	const handleContentChange = lang => (value) => {
		setContent({
			...content,
			[lang]: value,
		});
	};

	const handleTabChange = (event, newTab) => {
		setActiveLang(newTab);
	};

	const handleRemoveItem = useCallback(() => {
		if (window.confirm('This item will be permanently removed. Do you want to proceed?')) {
			const { sectionIndex, itemIndex } = selectedItem;
			const properties = clone(topLevelScope.properties);
			const newFAQ = properties.faq;
			newFAQ?.[sectionIndex].items.splice(itemIndex, 1);

			watchSubmit(composePatchScope(editScopeTypes.COMPANY)(topLevelScope.id, {
				properties,
			}));
			handleClose();
		}
	}, [handleClose, selectedItem, topLevelScope.id, topLevelScope.properties, watchSubmit]);

	return (
		<>
			{ error && (
				<Alert type={Alert.TYPE_DANGER}>{t('errors.dataStoreError')}</Alert>
			)}
			<Tabs
				value={activeLang}
				indicatorColor="primary"
				textColor="primary"
				onChange={handleTabChange}
				style={{ marginBottom: '1rem', borderBottom: '1px solid #e8e8e8' }}
				aria-label="disabled tabs example"
			>
				{Object.keys(languages).map(lang => (
					<Tab selected={lang === activeLang} value={lang} label={languages[lang]} key={lang} />
				))}
			</Tabs>
			<Typography variant="h6" style={{ marginBottom: '1rem' }}>{t('general.question')}</Typography>
			<TextField variant="outlined" onChange={handleTitleChange(activeLang)} value={title?.[activeLang] || ''} fullWidth disabled={saving} />
			<Divider style={{ margin: '1rem 0' }} />
			<Typography variant="h6">{t('general.answer')}</Typography>
			<Suspense fallback={<Loader />}>
				<MarkdownEditor
					content={content}
					language={activeLang}
					handleChange={handleContentChange}
					saving={saving}
				/>
			</Suspense>
			<Divider style={{ margin: '1rem 0' }} />
			<Button
				disabled={!isDirty || saving}
				color="primary"
				style={{ marginRight: '1rem' }}
				variant="contained"
				onClick={handleSave}
				startIcon={<Icon>save</Icon>}
			>
				{saving ? t('general.saving') : t('general.save')}
			</Button>
			<Button disabled={!isDirty || saving} variant="contained" color="default" onClick={handleRevert} startIcon={<Icon>history</Icon>}>
				{t('general.revert')}
			</Button>
			<Divider style={{ margin: '1rem 0' }} />
			<div>
				<Button
					color="primary"
					variant="contained"
					onClick={handleRemoveItem}
					startIcon={<Icon>delete</Icon>}
					disabled={saving}
					style={{ marginBottom: '1rem' }}
				>
					{t('manage.company.faq.removeItem')}
				</Button>
				<Button
					variant="contained"
					onClick={handleClose}
					disabled={saving}
					style={{ marginBottom: '1rem', marginLeft: '1rem' }}
					color="default"
				>
					{t('general.close')}
				</Button>
			</div>
		</>
	);
};

CompanyFAQMarkdownEditor.propTypes = {
	selectedItem: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
};

export default withWorkingScope(withFormData({
	customId: () => 'EditRootScopeProperties',
})(CompanyFAQMarkdownEditor));
