import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import React from 'react';
import PropTypes from 'prop-types';

const PREFIX = 'SmallTooltip';

const classes = {
    tooltip: `${PREFIX}-tooltip`,
    root: `${PREFIX}-root`,
    icon: `${PREFIX}-icon`
};

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    [`&.${classes.root}`]: {
		display: 'flex',
		alignItems: 'center',
        [`& .${classes.icon}`]: {
			verticalAlign: 'unset',
		},
	},

    [`& .${classes.icon}`]: {
		marginLeft: theme.spacing(1),
		verticalAlign: '-0.3em',
		color: theme.palette.primary.main,
		cursor: 'pointer',
	}
}));

function SmallTooltip({ title, children }) {
	return (
        <StyledTooltip title={title} placement="top">
			{children ? (
				<div className={classes.root}>
					{children}
					<Icon className={classes.icon}>info</Icon>
				</div>
			) : (
				<Icon className={classes.icon}>info</Icon>
			)}
		</StyledTooltip>
    );
}

SmallTooltip.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
};

export default SmallTooltip;
