import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { useTranslation } from 'react-i18next';
import { editScopeTypes } from '../../../actions/scopeEditActions'
import editScope from './editScope'

const PublishedOptions = [
	{value: 'published', label: 'Published'},
	{value: 'draft', label: 'Draft'}
];

const ArtificialStockStatusOptions = [
	{value: "null", label: "No status manipulation"},
	{value: "SUFFICIENT_PLACES", label: "Sufficient available units"},
	{value: "ALMOST_FULL", label: "Almost sold out"},
	{value: "FULL", label: "Sold out"}
];

const LocaleOptions = [
	{value: "null", label: "Use language of the company"},
	{value: "nl_NL", label: "Nederlands"},
	{value: "en_GB", label: "English"},
	{value: "de_DE", label: "Deutsch"}
];

const PublishedValues = PublishedOptions.map(o => o.value);
const ArtificialStockStatusValues = ArtificialStockStatusOptions.map(o => o.value);
const LocaleValues = LocaleOptions.map(o => o.value);

const UnitsMin = 1;
const UnitsMax = 500;

const ScopeAthenaStudiesCourseGroupForm = (
	{
		saveScope, showButtons, saving, fields, parent,
		bindState, bindStateSwitch, switchCheckedFromValue,
	}
) => {
	const { t } = useTranslation();
	return (
		<form onSubmit={saveScope}>
			<Grid container spacing={2}>
				<Grid item md={4} sm={6} xs={12}>
					<TextField label="Name"
							   name="name"
							   fullWidth
							   value={fields.name}
							   onChange={bindState('name')} />
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<TextField label="Available units"
							   name="availableUnits"
							   fullWidth
							   value={fields.availableUnits}
							   onChange={bindState('availableUnits')} />
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<FormControl fullWidth>
						<InputLabel>Stock manipulation</InputLabel>
						<Select value={fields.artificialStockStatus || 'null'}
								inputProps={{
									name: 'artificialStockStatus'
								}}
								onChange={bindState('artificialStockStatus')}>
							{ArtificialStockStatusOptions.map(status => (
								<MenuItem key={status.value} value={status.value}>
									{status.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<FormControlLabel
						label={t('scope.edit.publishedForCustomers')}
						control={
							<Switch checked={switchCheckedFromValue(PublishedValues, fields.published)}
									onChange={bindStateSwitch('published', PublishedValues)} />
						}
					/>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<FormControl fullWidth>
						<InputLabel>Language of group</InputLabel>
						<Select value={fields.locale}
								inputProps={{
									name: 'locale'
								}}
								onChange={bindState('locale')}>
							{LocaleOptions.map(locale => (
								<MenuItem key={locale.value} value={locale.value}>
									{locale.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<FormControl fullWidth>
						<FormControlLabel
							label="Require customer to fill postal address for sending booklets"
							control={
								<Switch
									checked={fields.addressMandatory}
									onChange={bindStateSwitch('addressMandatory')}
								/>
							}
						/>
						{!parent && (
							<FormHelperText error>Already made orders will NOT be asked for their postal address. This has to be done manually.</FormHelperText>
						)}
					</FormControl>
				</Grid>

				{showButtons && (
					<Grid item>
						<Button variant="contained" color="default"
								disabled={saving}
								onClick={saveScope}>
							{saving
								? t('general.saving')
								: parent
									? t('general.addValue', { scope: t('scopes.group') })
									: t('general.editValue', { scope: t('scopes.group') })
							}
						</Button>
					</Grid>
				)}
			</Grid>
		</form>
	);
}

ScopeAthenaStudiesCourseGroupForm.propTypes = {
	groupCount: PropTypes.number,
}

export default editScope(
	editScopeTypes.ATHENA_STUDIES_COURSE_GROUP,
	{
		fields: {
			name: editScope.Joi.string().required(),
			availableUnits: editScope.Joi.number().max(UnitsMax).when(editScope.Joi.ref('$props.soldUnits'), {
				is: editScope.Joi.number().exist().min(UnitsMin),
				then: editScope.Joi.number().min(editScope.Joi.ref('$props.soldUnits')),
				otherwise: editScope.Joi.number().min(UnitsMin)
			}).default(25),
			published: editScope.Joi.string().valid(PublishedValues).default(PublishedValues[0]),
			artificialStockStatus: editScope.Joi.string().valid(ArtificialStockStatusValues).allow(null).default(ArtificialStockStatusValues[0]),
			locale: editScope.Joi.string().valid(LocaleValues).allow(null).default(LocaleValues[0]),
			addressMandatory: editScope.Joi.boolean().allow(null).default(true),
		},
		validateFields: true,
		convertBeforeLoad: (scope) => {
			if(!scope) return scope;
			return {
				...scope,
				locale: !scope.locale ? 'null' : scope.locale,
			}
		},
	}
)(ScopeAthenaStudiesCourseGroupForm)
