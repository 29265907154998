import { Schemas, CALL_API } from '../../actions/schemas'

export const ROOT = 'ROOT';
export const SCOPE = 'SCOPE';
export const SCOPE_GROUP = 'SCOPE_GROUP';
export const TOP_LEVEL_SCOPE = 'TOP_LEVEL_SCOPE';
export const WORKING_SCOPE = 'WORKING_SCOPE';

export const getRootScopes = () => (dispatch, getState) => {
	const rootScopes = getState().root.rootScopes;

	if(rootScopes.length > 0) return null;

	return dispatch({
		[CALL_API]: {
			type: ROOT,
			endpoint: `root-scopes`,
			schema: [Schemas.SCOPE]
		}
	});
};

const isLoadingSubs = {};
export const getSubScopes = (id) => (dispatch) => {
	if(isLoadingSubs[id]) return;
	isLoadingSubs[id] = true;

	return dispatch({
		[CALL_API]: {
			type: SCOPE,
			endpoint: `scope/${id}?depth=1`,
			schema: Schemas.SCOPE
		}
	}).then(res => {
		isLoadingSubs[id] = false;

		return res;
	});
}

export const getScopesTillGroups = (id) => (dispatch) => {
	return dispatch({
		[CALL_API]: {
			type: SCOPE_GROUP,
			endpoint: `scope/${id}/groups`,
			schema: Schemas.SCOPE
		}
	});
}

export const topLevelScopeSelect = (scopeId) => (dispatch, getState) => {
	return dispatch({
		type: TOP_LEVEL_SCOPE,
		set: scopeId,
		scopes: getState().entities.scopes,
	});
}

export const topLevelScopeClear = () => ({
	type: TOP_LEVEL_SCOPE,
	clear: true,
});

export const workingScopeSelect = (scopeId, position) => ({
	type: WORKING_SCOPE,
	set: scopeId,
	position: position,
});

export const workingScopeClear = (position) => ({
	type: WORKING_SCOPE,
	clear: position,
});

export const workingScopeSelectParents = (parentId) => (dispatch, getState) => {
	return dispatch({
		type: WORKING_SCOPE,
		setList: collectParentList(parentId, getState().entities.scopes),
	});
};

function collectParentList(parentId, scopes){
	if(!parentId) return [];

	const parent = scopes[parentId];
	if(!parent) return [];

	return [...collectParentList(parent.parent, scopes), parentId];
}

export const workingScopeSelectList = (scopeList) => (dispatch, getState) => {
	if(scopeList.length === 0) return null;

	const scopeId = scopeList[scopeList.length - (scopeList.length > 1 ? 2 : 1)];
	const scope = getState().entities.scopes[scopeId];

	if(scope && scope._virtual) return dispatch({
		type: WORKING_SCOPE,
		setList: scopeList,
	});

	return dispatch(
		getSubScopes(scopeId)
	)?.then(() => {
		return dispatch({
			type: WORKING_SCOPE,
			setList: scopeList,
		});
	});
};
