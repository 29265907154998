import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

const PageHeaderTitle = ({ headline, subHeading, description }) => {
    return (
        <div>
			{
				headline &&
				<Typography variant="h5">
					{headline}
				</Typography>
			}
			{
				subHeading &&
				<Typography variant="subtitle1" gutterBottom>
					{subHeading}
				</Typography>
			}
			{
				description &&
				<Typography variant="body1" gutterBottom component="div">
					{description}
				</Typography>
			}
		</div>
    );
};

PageHeaderTitle.propTypes = {
	headline: PropTypes.node,
	subHeading: PropTypes.node,
	description: PropTypes.node
};

export default PageHeaderTitle
