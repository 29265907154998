import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import ScopeSelector from '../scope/selector/ScopeSelector';
import Alert from '../util/alert/Alert';
import HeaderDrawerMenu from './HeaderDrawerMenu';
import HeaderDrawerScopes from './HeaderDrawerScopes';
import HeaderDebugInfo from './HeaderDebugInfo';

const PREFIX = 'Header';

const classes = {
    flex: `${PREFIX}-flex`,
    scopeButtonIcon: `${PREFIX}-scopeButtonIcon`,
    scopeElector: `${PREFIX}-scopeElector`,
    devAlert: `${PREFIX}-devAlert`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.flex}`]: {
		flex: 1,
	},

    [`& .${classes.scopeButtonIcon}`]: {
		marginLeft: theme.spacing(1),
	},

    [`& .${classes.scopeElector}`]: {
		flex: 1,
		marginLeft: theme.drawerWidth,
	},

    [`& .${classes.devAlert}`]: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginBottom: `-${theme.spacing(2)}`,
	}
}));

const DEBUG = process.env.NODE_ENV !== 'production' || process.env.STAGE === 'stage';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			drawerMenuOpen: false,
			drawerScopesOpen: false,
		};
	}

	toggleDrawerMenu = () => {
		const { drawerMenuOpen } = this.state;
		this.setState({
			drawerMenuOpen: !drawerMenuOpen,
		});
	}

	toggleDrawerScopes = () => {
		const { drawerScopesOpen } = this.state;
		this.setState({
			drawerScopesOpen: !drawerScopesOpen,
		});
	}

	render() {
		const { children,  t } = this.props;
		const { drawerMenuOpen, drawerScopesOpen } = this.state;

		return (
            <Root>
				<AppBar position="fixed" color="default" enableColorOnDark>
					{/* Mobile menu - without scope selection */}
					<Toolbar sx={{ display: { md: 'none' } }}>
						<IconButton
							aria-label="open drawer"
							onClick={this.toggleDrawerMenu}
						>
							<Icon>menu</Icon>
						</IconButton>
						<div className={classes.flex} />
						<Button
							aria-label="open scope drawer"
							onClick={this.toggleDrawerScopes}
						>
							{t('header.selectScope')}
							{' '}
							<Icon className={classes.scopeButtonIcon}>select_all</Icon>
						</Button>
					</Toolbar>

					{/* Desktop menu - with scope selection */}
					<Toolbar sx={{ display: { xs: 'none', md: 'block' } }} disableGutters>
						<div className={classes.scopeElector}>
							<ScopeSelector />
						</div>
					</Toolbar>
				</AppBar>

				<HeaderDrawerMenu
					open={drawerMenuOpen}
					onClose={this.toggleDrawerMenu}
				>
					{DEBUG && (
						<div className={classes.devAlert}>
							<Alert type={Alert.TYPE_WARNING}>DEV PORTAL</Alert>
						</div>
					)}
					{children}
					<HeaderDebugInfo />
				</HeaderDrawerMenu>

				<HeaderDrawerScopes
					open={drawerScopesOpen}
					onClose={this.toggleDrawerScopes}
					sx={{ display: { xs: 'none', md: 'flex' } }}
				/>
			</Root>
        );
	}
}

Header.propTypes = {
	children: PropTypes.node,
};

export default withTranslation()((Header));
