import loginBg from './images/loginbg.jpg';

export default {
    html: {
        fontSize: 14,
    },
    body: {
        fontFamily: '"Open Sans", sans-serif',
        overflowY: 'scroll',
        background: `url(${loginBg}) no-repeat fixed`,
        backgroundSize: 'cover',
        '&.portal': {
            background: '#F3F3F3 no-repeat',
            backgroundSize: 'cover',
        },
    },

    'h1, h2, h3, h4, h5, h6': {
        marginTop: 0,
    },

    table: {
        borderCollapse: 'collapse',
    },

    'a, a:link, a:visited, a:hover': {
        textDecoration: 'none',
        color: '#418FA5',
        fontWeight: 600,
    },
};
