import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { getScopeEvaluationDetails } from '../../../actions/evaluationActions'
import FetchDataSwitch from '../../util/FetchDataSwitch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { ColoredGrade, questionOrder } from './evaluationUtils'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import { tableCellClasses, Typography } from '@mui/material';
import withScopeAndSubScopes from '../../../modules/scope/withScopeAndSubScopes'

const PREFIX = 'CourseEvaluationsDetails';

const classes = {
    root: `${PREFIX}-root`,
    root2: `${PREFIX}-root2`,
    header: `${PREFIX}-header`,
    oddColumn: `${PREFIX}-oddColumn`,
    scorePaper: `${PREFIX}-scorePaper`,
    scoreCell: `${PREFIX}-scoreCell`,
    scoreAveragesCell: `${PREFIX}-scoreAveragesCell`,
    scoreCommentCell: `${PREFIX}-scoreCommentCell`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`& .${classes.header}`]: {
		paddingBottom: theme.spacing(1),
		color: theme.palette.primary.main,
	},

    [`& .${classes.oddColumn}`]: {
		backgroundColor: '#f0f0f0',
	},

    [`&.${classes.scorePaper}`]: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
	},

    [`& .${classes.scoreCell}`]: {
		border: '2px solid #dedede',
		width: theme.spacing(5),
		padding: theme.spacing(0, 0.5),
	},

    [`& .${classes.scoreAveragesCell}`]: {
		border: '2px solid #dedede',
		width: theme.spacing(5),
		padding: theme.spacing(0, 0.5),
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},

    [`& .${classes.scoreCommentCell}`]: {
		borderBottom: '2px solid #dedede',
	}
}));

const QuestionCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.root}`]: {
		borderBottom: 0,
		borderTop: '2px solid #dedede',
		borderLeft: '2px solid #dedede',
		padding: theme.spacing(1, 1.5),
	},
}));

const EmptyQuestionCell = styled(TableCell)({
	[`&.${tableCellClasses.root}`]: {
		width: 0,
		borderBottom: 0,
		borderLeft: '2px solid #dedede',
		padding: 0,
	},
});

class CourseEvaluationsDetails extends Component {

	componentDidUpdate(prevProps) {
		if ((prevProps.scopeId !== this.props.scopeId) || (prevProps.teacherId !== this.props.teacherId)) {
			this.props.load(getScopeEvaluationDetails(this.props.scopeId, this.props.teacherId))
		}
	}

	handleData = data => {
		const averages = data.reduce((acc, score) => {
			questionOrder.forEach(question => acc[question] += score[question] / data.length)
			return acc
		}, questionOrder.reduce((acc, question) => {
			acc[question] = 0
			return acc
		}, {}))

		averages.average = questionOrder.reduce((acc, question) => acc + averages[question] / questionOrder.length, 0)
		averages.comments = `Average grade: ${averages.average.toFixed(1)}`
		averages.isAverageRow = 'scoreAveragesCell'
		averages.clientId = 'averages'

		return [...data, averages]
	}

	render() {
		const { loading, error, data,  questions, scope, parentScope } = this.props
		return (
            <StyledPaper className={classes.scorePaper}>
				<Typography variant={'subtitle1'} className={classes.header}>
					{parentScope.name} - {scope.name}
				</Typography>
				<FetchDataSwitch {...{ loading, error, data }}>
					{data && (
						<Table>
							<TableHead>
								{questions.map((question, questionIdx) => (
									<TableRow key={question}>
										{Array(questionIdx).fill('').map((_, emptyIdx) => (
											<EmptyQuestionCell
                                                key={emptyIdx}
                                                className={`${emptyIdx % 2 || classes.oddColumn}`}
                                            />
										))}
										<QuestionCell
                                            colSpan={questionOrder.length + 1 - questionIdx}
                                            className={`${questionIdx % 2 || classes.oddColumn}`}
                                       	>
											{question}
										</QuestionCell>
									</TableRow>
								))}
							</TableHead>
							<TableBody>
								{this.handleData(data).map((score, scoreIdx) => (
									<TableRow key={`${score.clientId}${scoreIdx}`}>
										{questionOrder.map((question, questionIdx) => (
											<TableCell
												align={'center'}
												key={question}
												className={`${classes[score.isAverageRow || 'scoreCell']} ${questionIdx % 2 || classes.oddColumn}`}
											>
												<ColoredGrade grade={score[question]}/>
											</TableCell>
										))}
										<TableCell className={classes.scoreCommentCell}>
											{score.comments}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</FetchDataSwitch>
			</StyledPaper>
        );
	}
}

CourseEvaluationsDetails.propTypes = {
	scopeId: PropTypes.number.isRequired,
	teacherId: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]).isRequired,
	questions: PropTypes.array.isRequired,
};

export default withScopeAndSubScopes(compose(
	withFetchData(props => getScopeEvaluationDetails(props.scopeId, props.teacherId), {
		customId: ({ scopeId, teacherId }) => `CourseEvaluationsDetails-${scopeId}-${teacherId}`,
	}),
)(CourseEvaluationsDetails), (props) => props.scopeId);
