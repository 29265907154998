import React, { Component, Fragment } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { getCountrySelectOptions } from '../../helpers/countries'
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen'
import Alert from './alert/Alert'

const PREFIX = 'CountrySearchModal';

const classes = {
    gutters: `${PREFIX}-gutters`,
    list: `${PREFIX}-list`,
    field: `${PREFIX}-field`
};

const StyledList = styled(List)(({ theme }) => ({
    [`&.${classes.list}`]: {
		marginLeft: `-${theme.spacing(3)}`,
		marginRight: `-${theme.spacing(3)}`,
	},

    [`& .${classes.field}`]: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	}
}));

const countryOptions = getCountrySelectOptions();
const MobileDialog = withDialogShouldBeFullscreen()(Dialog);

const ListItemPadding = ListItem;

class CountrySearchModal extends Component {
	state = {
		search: '',
	};

	render(){
		const { onClose, onPick, suggestions, } = this.props;
		const { search } = this.state;

		const searchLower = search.toLowerCase();
		const countries = search.length === 0
			? countryOptions
			: countryOptions.filter(option => (
				option.label.toLowerCase().indexOf(searchLower) > -1 ||
				option.value.toLowerCase() === searchLower
			));

		return (
			<MobileDialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
				<DialogTitle>
					Select your country
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						label="Search country"
						fullWidth
						value={search}
						onChange={this.handleChange}
						className={classes.field}
						autoComplete="none"
					/>
					{search.length === 0 ? (
						<Fragment>
							<em>Search something first</em>
							{suggestions && (
								this.renderList(countries.filter(
									country => suggestions.indexOf(country.value) > -1
								), 'Suggestion')
							)}
						</Fragment>
					) : countries.length === 0 ? (
						<Alert type={Alert.TYPE_INFO}>
							No countries found for this search term. Perhaps try the English country name.
						</Alert>
					) : (
						this.renderList(countries)
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="secondary">
						Cancel
					</Button>
				</DialogActions>
			</MobileDialog>
		);
	}

	renderList(countries, secondary = undefined){
		const { onPick, } = this.props;

		return (
            <StyledList className={classes.list}>
				{countries.map(country => (
					<ListItemPadding
                        button
                        key={country.value}
                        onClick={() => onPick(country)}
                        classes={{
                            gutters: classes.gutters
                        }}>
						<ListItemText
							primary={country.label}
							secondary={secondary}
						/>
					</ListItemPadding>
				))}
			</StyledList>
        );
	}

	handleChange = (e) => {
		this.setState({
			search: e.target.value,
		});
	}
}

CountrySearchModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onPick: PropTypes.func.isRequired,
	suggestions: PropTypes.array,
};

export default (CountrySearchModal)
