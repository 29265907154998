import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capabilities } from '../../../helpers/capabilities';
import withFetchData from '../../../modules/fetchData/withFetchData'
import withRootCapabilities from '../../../modules/scope/withRootCapabilities'
import { getTeachingCourseGroups } from '../../../actions/meActions'
import { isAfterInProgress } from '../../../helpers/groupState'
import CalendarViewModal from '../../calendar/CalendarViewModal';
import ModalManager from '../../modal/ModalManager'
import ModalItem from '../../modal/ModalItem';
import PageContentBlock from '../../page/PageContentBlock'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'
import TeacherAgendaWrapper from './TeacherAgendaWrapper'

const TeacherAgendaContainer = ({ error, loading, data, hasCapability }) => {
	const { t } = useTranslation();

	const links = useMemo(() => [{
		to: '/portal',
		state: {
			modal: 'showCalendar',
			iCalUrl: 'me/timeslots.ics',
		},
		label: t('dashboard.teacherAgenda.calendar'),
		icon: 'today',
		basic: true,
	}], [t]);

	if(error) return (
		<Alert>{t('errors.dataLoadError')}</Alert>
	);

	const hasLink = hasCapability(capabilities.SeeScopeCapability);

	return (
		<PageContentBlock
			title={t('dashboard.teacherAgenda.title')}
			description={t('dashboard.teacherAgenda.description')}
			icon="school"
			onlyTopPaper
			links={links}
		>
			{(loading || !data) ? (
				<Loader/>
			) : (
				<Fragment>
					<ModalManager>
						<ModalItem state="showCalendar" component={CalendarViewModal} />
					</ModalManager>
					<TeacherAgendaWrapper
						type="upcoming"
						groups={data.filter(group => !isAfterInProgress(group.state))}
						hasLink={hasLink}
					/>
					<TeacherAgendaWrapper
						type="finished"
						groups={data.filter(group => isAfterInProgress(group.state))}
						hasLink={hasLink}
					/>
				</Fragment>
			)}
		</PageContentBlock>
	);
};

export default withRootCapabilities(
	withFetchData(getTeachingCourseGroups)(TeacherAgendaContainer)
)
