import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { compose, mapObjIndexed, values } from 'ramda'
import React, { Component, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { niceDateYear } from '../../helpers/datetime'
import { formatPercentage } from '../../helpers/formatter'
import withRouter from '../../hoc/withRouter';
import withFetchData from '../../modules/fetchData/withFetchData'
import withFormData from '../../modules/formData/withFormData'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { CALL_API } from '../../setup/api'
import ModalItem from '../modal/ModalItem'
import ModalManager from '../modal/ModalManager'
import PageContentBlock from '../page/PageContentBlock'
import PageHeader from '../page/PageHeader'
import PageHeaderInfo from '../page/PageHeaderInfo'
import PageHeaderMenu from '../page/PageHeaderMenu'
import PageHeaderNavigation from '../page/PageHeaderNavigation'
import PageHeaderTitle from '../page/PageHeaderTitle'
import ScopeIndicator from '../scope/indicator/ScopeIndicator'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import PartnerForm from './PartnerForm'

const apiAction = (partnerId) => ({
	[CALL_API]: {
		type: 'PARTNER',
		endpoint: `partner/${partnerId}`,
	},
});

function* scopeParents(scope) {
	if (scope.parent) yield *scopeParents(scope.parent);
	yield scope;
}

const PartnerRemovalModal = withFormData()(({ handleClose, partner, saving, error, success, watchSubmit }) => {
	const navigate = useNavigate();

	const handleSubmit = useCallback(() => {
		watchSubmit({
			[CALL_API]: {
				type: 'PARTNER',
				endpoint: `/partner/${partner.id}`,
				method: 'DELETE',
			},
		});
	}, [watchSubmit, partner]);

	useEffect(() => {
		if(success){
			navigate('/portal/promotion/partners', { replace: true });
		}
	}, [success, navigate]);

	return (
		<Dialog open={true} onClose={handleClose}>
			<DialogTitle>Delete partner</DialogTitle>
			<DialogContent>
				{saving && (
					<Loader sheet />
				)}
				{partner && (
					<DialogContentText>
						Are you sure that you want to remove {partner.name}?
					</DialogContentText>
				)}
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>Cancel</Button>
				<Button color="primary" disabled={saving} onClick={handleSubmit}>Delete</Button>
			</DialogActions>
		</Dialog>
	)
});

const PartnerRemoveScopeModal = withFormData()(({ handleClose, modal, partner, refresh, saving, error, success, watchSubmit }) => {
	const { scope } = modal;

	const handleSubmit = useCallback(() => {
		watchSubmit({
			[CALL_API]: {
				type: 'PARTNER',
				endpoint: `/partner/${partner.id}/scope/${scope.id}`,
				method: 'DELETE',
			},
		});
	}, [watchSubmit, partner]);

	useEffect(() => {
		if(success){
			refresh();
			handleClose();
		}
	}, [success, refresh, handleClose]);

	return (
		<Dialog open={true} onClose={handleClose}>
			<DialogTitle>Delete scope from partner</DialogTitle>
			<DialogContent>
				{error && (
					<ApiError error={error} />
				)}
				{saving && (
					<Loader sheet />
				)}
				{scope && (
					<DialogContentText>
						Are you sure that you want to remove {scope.name} from {partner.name}?
					</DialogContentText>
				)}
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>Cancel</Button>
				<Button color="primary" onClick={handleSubmit}>Delete</Button>
			</DialogActions>
		</Dialog>
	)
});

const PartnerEditModal = withFormData()(({ handleClose, partner, refresh, saving, error, success, watchSubmit }) => {
	const formSubmit = useRef();
	const [ renderCount, setRenderCount ] = useState(0);

	function bindSubmit(cb){
		formSubmit.current = cb;
		setRenderCount(count => count + 1);
	}

	const handleSave = useCallback((data) => {
		watchSubmit({
			[CALL_API]: {
				type: 'PARTNER',
				endpoint: `partner/${partner.id}`,
				body: data,
				method: 'PUT',
			},
		});
	}, [watchSubmit, partner.id]);

	useEffect(() => {
		if(success){
			refresh();
			handleClose();
		}
	}, [success, refresh, handleClose]);

	return (
		<Dialog open={true} onClose={handleClose}>
			{partner && <DialogTitle>Edit {partner.name}</DialogTitle>}
			{partner && (
				<DialogContent>
					{saving && (
						<Loader sheet />
					)}
					{error && (
						<ApiError error={error} />
					)}
					<PartnerForm
						disabled={false}
						onSave={handleSave}
						partner={partner}
						bindSubmit={bindSubmit}
					/>
				</DialogContent>
			)}
			<DialogActions>
				<Button color="primary" onClick={handleClose}>Cancel</Button>
				<Button
					color="primary"
					disabled={!formSubmit.current}
					onClick={(e) => formSubmit.current(e)}
				>Save</Button>
			</DialogActions>
		</Dialog>
	)
});

class PartnerViewPage extends Component {
	constructor(props){
		super(props);

		this.state = {
			partnerid: props.params.partnerid,
			deleting: false,
		};
	}

    render(){
	    const { loading, error, data, refresh, saving, formError, location } = this.props;

	    if(error) return (
	    	<ApiError error={error} />
		);

	    if(loading || !data) return (
	    	<Loader />
		);

	    const partner = data;

	    const basicProperties = [
			{ label: 'Discount percentage', content: formatPercentage(partner.discountPercentage) },
			{ label: 'Revenue commission', content: formatPercentage(partner.revenuePercentage) },
			{ label: 'Expiration date', content: niceDateYear(partner.expires) },
			{ label: 'Server IP range', content: (partner.allowedIps || []).join(' - ') },
		];

	    const visualProperties = values(mapObjIndexed((v, i) => { return { label : i, content: v }}, partner.visuals));
	    const partnerProperties = basicProperties.concat(visualProperties);

        return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{saving && (
						<Loader sheet />
					)}
					{formError && (
						<ApiError error={formError} />
					)}
					<PageHeader>
						<PageHeaderMenu items={[
							{
								name: 'Edit',
								to: location.pathname,
								state: { modal: 'edit-partner' },
								icon: 'edit',
							},
							{
								name: 'Delete',
								to: location.pathname,
								state: { modal: 'delete-partner' },
								icon: 'delete',
							},
						]} />
						<PageHeaderNavigation breadcrumbs={[
							{ to: `/portal/promotion/partners`, label : 'Partners'},
							{ label: `${partner.name}`},
						]}/>
						<PageHeaderTitle headline={`Partner ${partner.name} (${partner.slug})`}/>
						<PageHeaderInfo items={partnerProperties}/>
					</PageHeader>
					<ModalManager>
						<ModalItem state={"edit-partner"} component={PartnerEditModal} partner={data} refresh={refresh} />
						<ModalItem state={"delete-partner"} component={PartnerRemovalModal} partner={data} />
						<ModalItem state={"delete-partner-scope"} component={PartnerRemoveScopeModal} partner={data} refresh={refresh} />
					</ModalManager>
					<Grid item sm={12}>
						<PageContentBlock
							title="Scopes"
							links={[
								{ label : <span>Add <ScopeIndicator levels={1}/></span>, onClick: this.onAddScope, icon : 'add', basic: false }
							]}
							description="The scopes associated with this partner."
							icon="school"
						>
							{this.renderScopes()}
						</PageContentBlock>
					</Grid>
				</Grid>
			</Grid>
        );
    }

	renderScopes(){
		const { data, location } = this.props;

		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Abbreviation</TableCell>
						<TableCell>Name</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.scopes.map((scope) => {
						const parents = Array.from(scopeParents(scope));

						return (
							<TableRow key={scope.id}>
								<TableCell>{parents.map(parent => parent.abbreviation).join(" / ")}</TableCell>
								<TableCell>{scope.name}</TableCell>
								<TableCell align="right" padding="checkbox">
									<Link
										to={location.pathname}
										state={{
											modal: 'delete-partner-scope',
											scope,
										}}
									>
										<IconButton>
											<Icon>delete</Icon>
										</IconButton>
									</Link>
								</TableCell>
							</TableRow>
						)})}
				</TableBody>
			</Table>
		);
	}

	onAddScope = () => {
		const { scope, watchSubmit } = this.props;
		const { partnerid } = this.state;

		if(!scope) return;

		watchSubmit({
			[CALL_API]: {
				type: 'PARTNER',
				endpoint: `partner/${partnerid}/scope/${scope.id}`,
				body: {},
			},
		});
	}

	componentDidUpdate(prevProps) {
		if(!prevProps.success && this.props.success){
			this.props.refresh();
		}
	}
}

export default compose(
	withRouter,
	withWorkingScope,
	withFormData(),
	withFetchData((props) => apiAction(props.params.partnerid), {
		customId: (props) => `PartnerView-${props.params.partnerid}`,
	}),
)(PartnerViewPage)
