import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import moment from 'moment'
const PREFIX = 'LiveTime';

const classes = {
    root: `${PREFIX}-root`,
    value: `${PREFIX}-value`,
    label: `${PREFIX}-label`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		textAlign: 'center',
		flex: 1,
	},

    [`& .${classes.value}`]: {
		fontSize: '3.5em',
		fontWeight: theme.typography.fontWeightMedium,
		height: '1em',
	},

    [`& .${classes.label}`]: {
		fontSize: '1.5em',
		color: theme.palette.text.primary ,
		marginTop: theme.spacing(1.5),
		textTransform: 'uppercase',
	}
}));

function getTime(){
	return moment().format('HH:mm');
}

const LiveTime = ({ }) => {
	const [time, setTime] = useState(getTime());

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(getTime());
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
        <Root className={classes.root}>
			<div className={classes.value}>{time}</div>
			<div className={classes.label}>time</div>
		</Root>
    );
}

export default (LiveTime)
