import { Input } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropertyEditorOptionChip from './PropertyEditorOptionChip';

const PREFIX = 'PropertyEditorOptions';

const classes = {
    droppable: `${PREFIX}-droppable`
};

const StyledDroppableDiv = styled('div')({
	[`&.${classes.droppable}`]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
});

const PropertyEditorOptions = ({ property, editMode, handleOrderChange, handleChoicesChange, handleChoiceDelete, handleAddChoice, forwardedRef }) => {

	const forwardedOptionsRef = useRef();
	useEffect(() => {
		forwardedOptionsRef.current = property?.options?.choices?.length || false;
	}, [property?.options?.choices?.length]);

	const handleOnDragEnd = (event) => {
		handleOrderChange(event);
	};
	const handleKeyPress = (event) => {
		if (event.nativeEvent.keyCode === 13) {
			handleAddChoice();
		}
	};
	return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId={property.key}>
				{(provided) => (
					<StyledDroppableDiv className={classes.droppable} ref={provided.innerRef} {...provided.droppableProps}>
						{property.options?.choices?.map((choice, index) => (
							<Draggable draggableId={choice?.key || choice} index={index} key={choice?.key || choice}>
								{(providedDrag) => (
									<PropertyEditorOptionChip
										choice={choice}
										editMode={editMode}
										handleChoicesChange={handleChoicesChange}
										handleChoiceDelete={handleChoiceDelete}
										providedDrag={providedDrag}
										forwardedOptionsRef={forwardedOptionsRef}
										index={index}
									/>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</StyledDroppableDiv>
				)}
			</Droppable>
			{editMode && (
				<Input
					inputRef={forwardedRef}
					onKeyPress={handleKeyPress}
					endAdornment={(
						<InputAdornment position="end">
							<IconButton
								onClick={handleAddChoice}
							>
								<Icon>add</Icon>
							</IconButton>
						</InputAdornment>
					)}
					placeholder="exampleOptionKey"
				/>
			)}
		</DragDropContext>
    );
};

PropertyEditorOptions.propTypes = {
	property: PropTypes.object,
	editMode: PropTypes.bool,
	handleChoicesChange: PropTypes.func,
	handleOrderChange: PropTypes.func,
	handleChoiceDelete: PropTypes.func,
	handleAddChoice: PropTypes.func,
	forwardedRef: PropTypes.object,
};

export default PropertyEditorOptions;
