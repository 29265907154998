export function createApiTypes(type){
	type = type.toUpperCase();

	return [
		type + '_REQUEST',
		type + '_SUCCESS',
		type + '_FAILURE'
	];
}

export function getApiTypeSuccess(type){
	return type.toUpperCase() + '_SUCCESS';
}

export function getApiLoading(type){
	return type.toLowerCase();
}
