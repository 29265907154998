import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import { CALL_API, downloadFromApi } from '../../../setup/api'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { formatMoneyAuto } from '../../../helpers/formatter'
import { niceDateTime } from '../../../helpers/datetime'
import TablePaginationActions, { StyledTablePagination } from '../../table/TablePaginationActions';
import ApiError from '../../util/ApiError'
import Loader from '../../util/loader/Loader'
import Alert from '../../util/alert/Alert'

const ApiAction = (scopeId) => ({
	[CALL_API]: {
		type: 'BATCHES_LIST',
		endpoint: `scope/${scopeId}/employee-payment-batches`,
	},
});

const BatchesList = ({ scopeId, loading, error, data, load, pagination, onPaginationChangePage, onPaginationChangePageSize }) => {
	useEffect(() => {
		load(ApiAction(scopeId));
	}, [scopeId, pagination.page, pagination.pageSize]);

	if(error) return (
		<ApiError error={error} />
	);

	if(!data || loading) return (
		<Loader />
	);

	if(data.length === 0) return (
		<Alert>
			No batches found for this company.
		</Alert>
	);

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>ID</TableCell>
					<TableCell>Created</TableCell>
					<TableCell>Amount of payments</TableCell>
					<TableCell>Total</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map(item => (
					<TableRow key={item.id}>
						<TableCell>
							<Link to={`/portal/financial/batches/${item.id}`}>{item.id}</Link>
						</TableCell>
						<TableCell>{niceDateTime(item.created)}</TableCell>
						<TableCell>{item.nbOfTxs}</TableCell>
						<TableCell>{formatMoneyAuto(item.ctrlSum)}</TableCell>
						<TableCell>
							<IconButton onClick={() => downloadFromApi(`employee-payment-batch/${item.id}.xml`, `${item.id}.xml`)}>
								<Icon>cloud_download</Icon>
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
			{ pagination && (
				<TableFooter>
					<TableRow>
						<StyledTablePagination
							count={pagination.total}
							rowsPerPage={pagination.pageSize}
							page={pagination.page}
							onPageChange={onPaginationChangePage}
							onRowsPerPageChange={onPaginationChangePageSize}
							rowsPerPageOptions={[10, 25, 50, 100, 250]}
							ActionsComponent={props => <TablePaginationActions onPaginationChangePage={onPaginationChangePage} {...props} />}
						/>
					</TableRow>
				</TableFooter>
			)}
		</Table>
	)
};

BatchesList.propTypes = {
	scopeId: PropTypes.number.isRequired,
};

export default withFetchData(null, {
	pagination: true,
	paginationPageSize: 10,
	paginationFromSearch: true,
})(BatchesList);
