import { CALL_API } from './schemas'
import { startOfMonth, format } from 'date-fns'

export const AGGREGATED_SCOPE_REPORT = 'AGGREGATED_SCOPE_REPORT';
export const CUSTOMER_RETENTION = 'CUSTOMER_RETENTION';
export const INCOME_FORECAST = 'INCOME_FORECAST';
export const TEACHERS_WITH_DETAILS = 'TEACHERS_WITH_DETAILS';
export const COURSES_WITH_DETAILS = 'COURSES_WITH_DETAILS';

export const getAggregatedScopeReport = (scopeId, begin = new Date()) => {
	return {
		[CALL_API]: {
			type: AGGREGATED_SCOPE_REPORT,
			endpoint: `scope/${scopeId}/aggregated-scope-report?begin1=${format(startOfMonth(begin), 'yyyy-MM')}&end1=${format(startOfMonth(begin), 'yyyy-MM')}`,
		}
	}
};

export const getCustomerRetention = (scopeId) => {
	return {
		[CALL_API]: {
			type: CUSTOMER_RETENTION,
			endpoint: `scope/${scopeId}/customer-retention`,
		}
	}
};

export const getIncomeForecast = (scopeId) => {
	return {
		[CALL_API]: {
			type: INCOME_FORECAST,
			endpoint: `scope/${scopeId}/income-forecast`,
		}
	}
};

export const getTeachersWithDetails = (scopeId) => {
	return {
		[CALL_API]: {
			type: TEACHERS_WITH_DETAILS,
			endpoint: `scope/${scopeId}/teachers-with-details`,
		}
	}
};

export const getCoursesWithDetails = (scopeId) => {
	return {
		[CALL_API]: {
			type: COURSES_WITH_DETAILS,
			endpoint: `scope/${scopeId}/courses-with-details`,
		}
	}
};
