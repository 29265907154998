import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import { useTranslation } from 'react-i18next';

const PREFIX = 'GroupVisibility';

const classes = {
    root: `${PREFIX}-root`,
    icon: `${PREFIX}-icon`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		whiteSpace: 'nowrap',
	},

    [`& .${classes.icon}`]: {
		marginRight: theme.spacing(1),
		verticalAlign: -5,
		fontSize: 21,
		color: theme.palette.error.main,
		'&.visible': {
			color: theme.palette.success.main,
		},
	}
}));

const GroupVisibility = ({ group, }) => {
	const visible = group.published === 'published';
	const { t } = useTranslation();

	return (
        <Root className={classes.root}>
			<Icon className={classes.icon + (visible ? ' visible' : '')}>
				{visible ? 'visibility' : 'visibility_off'}
			</Icon>
			{visible ? t('general.visible') : t('general.hidden')}
		</Root>
    );
}

GroupVisibility.propTypes = {
	group: PropTypes.object.isRequired
};

export default (GroupVisibility)
