import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi-browser';
import { keys, mapObjIndexed, pick, without, reduce } from 'ramda';
import { composeAddScope, composeEditScope } from '../../../actions/scopeEditActions';
import { getSubScopes } from '../../../modules/scope/scopeActions';
import withFormData from '../../../modules/formData/withFormData';
import withFetchData from '../../../modules/fetchData/withFetchData';
import Loader from '../../util/loader/Loader';
import Alert from '../../util/alert/Alert';
import ApiError from '../../util/ApiError';

function mutuallyExclusiveOfType(propType, ...exclusiveProps) {
	const propList = exclusiveProps.join(', or ');

	return function mutuallyExclusiveProps(props, propName, componentName, ...rest) {
		const filteredProps = Object.keys(props)
			.filter(prop => prop === propName || exclusiveProps.indexOf(prop) > -1)
			.filter(prop => props[prop] !== undefined && props[prop] !== null);

		if (filteredProps.length > 1) {
			return new Error(`A ${componentName} can only have one of these props: ${propName}, or ${propList}`);
		}

		return propType(props, propName, componentName, ...rest);
	};
}

const defaultOptions = {
	fields: {},
	validateFields: false,
	convertBeforeLoad: (scope) => (scope),
	convertBeforeSave: (fields) => (fields),
};

function defaultValueForFields(fields, props) {
	return mapObjIndexed(
		field => {
			if (!field) return '';
			if (!field._flags) return field;
			if (field._type === 'boolean') return Boolean(field._flags.default);
			if (!field._flags.default) return '';

			if (typeof field._flags.default === 'function') {
				return field._flags.default(props);
			}

			return field._flags.default;
		},
		fields,
	);
}

function scopeValueFromProps(scope, options) {
	if (!scope) return {};
	if (!options || !options.convertBeforeLoad) return scope;

	return options.convertBeforeLoad(scope);
}

const editScope = (type, extraOptions) => (ComposedComponent) => {
	const options = {
		...defaultOptions,
		...extraOptions,
	};

	const addScope = composeAddScope(type);
	const editScope = composeEditScope(type);

	class EditScope extends Component {
		// eslint-disable-next-line react/static-property-placement
		displayName = `editScope(${ComposedComponent.displayName || 'Unknown'})`;

		// eslint-disable-next-line react/static-property-placement
		static propTypes = {
			scopeId: mutuallyExclusiveOfType(PropTypes.number, 'parentId'),
			parentId: mutuallyExclusiveOfType(PropTypes.number, 'scopeId'),
			onSaved: PropTypes.func,
			bindSave: PropTypes.func,
		};

		constructor(props) {
			super(props);

			if (props.bindSave) {
				props.bindSave(this.saveScope);
			}

			this.state = {
				...defaultValueForFields(options.fields, props),
				...scopeValueFromProps(props.scope, options),
				scope: props.scope,
			};
		}

		static getDerivedStateFromProps(props, state) {
			if (state.scope !== props.scope) {
				return {
					...scopeValueFromProps(props.scope, options),
					scope: props.scope,
				};
			}

			return null;
		}

		componentDidUpdate(prevProps) {
			if (this.props.success && !prevProps.success && this.props.onSaved) {
				this.props.onSaved(this.props.success);
			}
		}

		saveScope = (e) => {
			if (e) e.preventDefault();

			if (this.props.saving) return false;

			let fields = mapObjIndexed((val) => val === 'null' ? null : val, this.state);

			// Validate the fields on the front-end if enabled
			if (options.validateFields) {
				const schema = Joi.validate(fields, options.fields, {
					abortEarly: false,
					allowUnknown: true,
				});

				if (schema.error) {
					const errors = reduce((acc, err) => ({
						...acc,
						[err.context.key]: err.message,
					}), {}, schema.error.details);

					this.props.handleFormError('Not all fields are filled correctly.', errors);
					return false;
				}
			}

			if (options.convertBeforeSave) {
				fields = options.convertBeforeSave(fields, this.props);
			}

			fields = pick(keys(options.fields), fields);

			const { scopeId, parentId, watchSubmit } = this.props;

			if (scopeId) {
				return watchSubmit(editScope(scopeId, fields));
			}
			return watchSubmit(addScope(parentId, fields));
		}

		bindState = (property, format = undefined) => (event) => {
			this.setState({
				[property]: format ? format(event.target.value) : event.target.value,
			});
		}

		bindStateDirect = (property) => (value) => {
			this.setState({
				[property]: value,
			});
		}

		bindStateSwitchArray = (property) => (event) => {
			const { checked } = event.target;
			const { value } = event.target;

			this.setState(state => {
				let list = (state[property] || []);

				// Remove first
				list = without([value], list);

				// Add again if checked (makes sure it is added once)
				if (checked) list.push(value);

				return {
					[property]: list,
				};
			});
		}

		bindStateSwitch = (property, values = undefined) => (event) => {
			let value = event.target.checked;

			if (values && values.length === 2) {
				value = value ? values[0] : values[1];
			}

			this.setState({
				[property]: value,
			});
		}

		switchCheckedFromValue = (values, value) => values && values[0] === value

		render() {
			const { loading, fetchError, formError, scope, parent, saving, success, bindSave } = this.props;

			if (success) return (
				<Alert type={Alert.TYPE_SUCCESS}>
					Scope was successfully saved.
				</Alert>
			);

			if (fetchError && !scope && !parent) return (
				<Alert classname={Alert.TYPE_WARNING}>
					{fetchError.error}
				</Alert>
			);

			if (loading || (!scope && !parent)) return (
				<Loader sheet />
			);

			return (
				<>
					{saving && <Loader sheet />}
					<ApiError error={formError} showDetails />
					<ComposedComponent
						{...this.props}
						parent={parent}
						fields={this.state}
						showButtons={!bindSave}
						saveScope={this.saveScope}
						bindState={this.bindState}
						bindStateDirect={this.bindStateDirect}
						bindStateSwitchArray={this.bindStateSwitchArray}
						bindStateSwitch={this.bindStateSwitch}
						switchCheckedFromValue={this.switchCheckedFromValue}
					/>
				</>
			);
		}
	}

	return withFormData({
		customId: () => 'editScope',
	})(withFetchData((props) => {
		// Make sure to load the data again, so the newest information is present
		if (props.scopeId) return getSubScopes(props.scopeId);
		if (props.parentId) return getSubScopes(props.parentId);

		return undefined;
	}, {
		mapStateToProps: (state, props) => {
			const scope = props.scopeId && state.entities.scopes[props.scopeId];
			const parent = props.parentId && state.entities.scopes[props.parentId];

			return {
				scope,
				parent,
				scopeParent: scope ? scope.parent && state.entities.scopes[scope.parent] : parent,
			};
		},
	})(EditScope));
};

editScope.Joi = Joi;

export default editScope;
