import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { Trans, withTranslation } from 'react-i18next';
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm'
import FormDataError from '../../modules/formData/FormDataError'
import { patchEmployeeProfile } from '../../actions/employeeActions'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'

class ProfileEditEmailForm extends Component {
	render(){
		const { validation, validationAll, fields, onChangeField, saving, success, error, t } = this.props;

		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				{t('profile.updatedDetailsSaved')}
			</Alert>
		);

		return (
			<div>
				<Alert type={Alert.TYPE_DANGER}>
					<Trans i18nKey="profile.changingEmailWarning">
						Changing the email will also change the <strong>username</strong> of the account. This new email will have to be <strong>used to login</strong> the next time!
					</Trans>
				</Alert>
				{ saving && <Loader sheet /> }
				<FormDataError error={error} showFieldErrors />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField name="email"
								   value={fields.email}
								   onChange={onChangeField}
								   label={t('employee.fields.email')}
								   error={!!validation['email']}
								   helperText={validation['email']}
								   onBlur={() => validationAll(fields)}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="emailRepeat"
								   value={fields.emailRepeat || ''}
								   onChange={onChangeField}
								   label={t('general.repeatEmail')}
								   error={!!validation['emailRepeat']}
								   helperText={validation['emailRepeat']}
								   onBlur={() => validationAll(fields)}
								   fullWidth />
					</Grid>
				</Grid>
			</div>
		)
	}

	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.onSave();
		}
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields, validationAll, handleFormError } = this.props;

		validationAll(fields, (error) => {
			if(error){
				handleFormError('Not all fields were filled correctly');
			}else{
				watchSubmit(patchEmployeeProfile(
					inputData.id, {
						email: fields.email
					}
				));
			}
		})
	}
}

ProfileEditEmailForm.propTypes = {
	inputData: PropTypes.object.isRequired,
};

export default withTranslation()(withCompleteBasicForm(ProfileEditEmailForm, {
	email: Joi.string().email({
		minDomainAtoms: 2,
	}).required(),
	emailRepeat: Joi.string().valid(Joi.ref('email')).required().options({
		language: {
			any: {
				allowOnly: 'is not the same'
			}
		}
	})
}))
