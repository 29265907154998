import { Collapse, ListItem, ListItemButton, ListItemText, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { patchEmployeeProfile } from '../../actions/employeeActions';
import i18nServices from '../../helpers/i18nServices';
import withAuthProvider from '../../modules/auth/withAuthProvider';
import withFormData from '../../modules/formData/withFormData';

import nlFlag from './flags/nl.png';
import enFlag from './flags/en.png';
import deFlag from './flags/de.png';

const PREFIX = 'HeaderLanguageSelector';

const classes = {
    localeFlag: `${PREFIX}-localeFlag`,
    nl: `${PREFIX}-nl`,
    en: `${PREFIX}-en`,
    de: `${PREFIX}-de`,
};

const LocaleFlagDiv = styled('div')(({ theme }) => ({
    [`&.${classes.localeFlag}`]: {
		display: 'inline-block',
		width: '16px',
		height: '16px',
		margin: theme.spacing(0, 1),
		backgroundSize: '16px 16px',
		verticalAlign: '-3px',
		[`&.${classes.nl}`]: {
			backgroundImage: `url(${nlFlag})`,
		},

		[`&.${classes.en}`]: {
			backgroundImage: `url(${enFlag})`,
		},

		[`&.${classes.de}`]: {
			backgroundImage: `url(${deFlag})`,
		}
	},
}));

const HeaderLanguageSelector = ({ watchSubmit, auth }) => {

	const { t, i18n } = useTranslation();
	const userLocale = auth.user.locale;
	const [open, toggleOpen] = useState(false);

	useEffect(() => {
		if (i18n.language !== userLocale) {
			i18nServices.changeLanguage(userLocale);
		}
	}, [i18n.language, userLocale]);

	const handleChange = useCallback((event) => {
		const { value } = event.target;
		watchSubmit(patchEmployeeProfile(
			auth.user.id, {
				locale: value,
			},
		)).then(() => {
			i18nServices.changeLanguage(value);
		});
	}, [auth.user.id, watchSubmit]);

	return (
        (<>
            <ListItem disablePadding>
				<ListItemButton onClick={() => toggleOpen(!open)}>
					<ListItemIcon><Icon sx={{ color: 'text.primary' }}>language</Icon></ListItemIcon>
					<ListItemText>{t('header.languageSelector.label')}</ListItemText>
					<Icon sx={{ color: 'text.primary' }}>
						{open ? 'expand_less' : 'expand_more'}
					</Icon>
				</ListItemButton>
			</ListItem>
            <Collapse in={open} timeout="auto">
				<FormControl fullWidth sx={{ py: 1, px: 2 }}>
					<Select
						labelId="language-select-label"
						id="language-select-label"
						value={i18n.language}
						label={t('header.languageSelector.label')}
						onChange={handleChange}
						autoWidth
						sx={{ fontSize: '1rem' }}
					>
						{
							i18nServices.locales.map(lng => (
								<MenuItem value={lng.value} key={lng.value}>
									<LocaleFlagDiv className={classnames(classes.localeFlag, classes[lng.value])} />
									{' '}
									{lng.label}
								</MenuItem>
							))
						}
					</Select>
				</FormControl>
			</Collapse>
        </>)
    );
};

HeaderLanguageSelector.propTypes = {
	auth: PropTypes.object.isRequired,
};

export default compose(
	withFormData(),
)(withAuthProvider(HeaderLanguageSelector));
