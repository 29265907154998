import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { last } from 'ramda'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { CALL_API } from '../../../setup/api'
import LiveStatsBlock from './LiveStatsBlock'

const apiAction = (rootScopeId) => ({
	[CALL_API]: {
		type: 'LIVE_STATS',
		endpoint: `scope/${rootScopeId}/customer-retention`,
	},
});

const LiveStatsCustomersHelped = ({ loading, data }) => {
	// const value = !data ? false : (() => {
	// 	return last(data) ? last(data).accumulatedCustomers || 0 : 0;
	// })();

	// TODO: testing comparison value by current month
	const value = useMemo(() => {
		if(!data) return false;

		const begin = moment().subtract(1, 'year').format('YYYY-MM');
		const end = moment().format('YYYY-MM');

		return {
			current: data.find(item => moment(item.revenueMonth).format('YYYY-MM') === end),
			last: data.find(item => moment(item.revenueMonth).format('YYYY-MM') === begin),
		}
	}, [data]);

	return (
		<LiveStatsBlock
			label="Students helped"
			value={value && value.current ? value.current.accumulatedCustomers : 0}
			comparisonLabel="last year"
			comparisonValue={value && value.last ? value.last.accumulatedCustomers : 0}
			comparisonOnlyShow
			comparisonCrown
		/>
	);
};

LiveStatsCustomersHelped.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default withFetchData((props) => apiAction(props.rootScopeId), {
	autoRefreshSeconds: 60,
})(LiveStatsCustomersHelped)
