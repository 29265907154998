import React, { Fragment } from 'react'
import withWorkingScope from '../../../modules/scope/withWorkingScope'
import { workingScopesNameList } from '../../../helpers/scope'
import PageHeader from '../../page/PageHeader'
import PageHeaderTitle from '../../page/PageHeaderTitle'
import Alert from '../../util/alert/Alert'
import BatchesList from './BatchesList'

const BatchesIndexPage = ({ workingScopes }) => {
	const subHeading = workingScopesNameList(workingScopes).slice(0, 1);

	return (
		<Fragment>
			<PageHeader>
				<PageHeaderTitle
					headline="SEPA batches"
					subHeading={subHeading.join(' > ')}
				/>
			</PageHeader>
			{workingScopes.length === 0 ? (
				<Alert>Select a (company) scope first.</Alert>
			) : (
				<BatchesList scopeId={workingScopes[0].id} />
			)}
		</Fragment>
	);
};

export default withWorkingScope(BatchesIndexPage)
