import * as queryString from 'query-string';
import {CALL_API, Schemas} from './schemas'
import {startOfMonth} from "date-fns";

import format from 'date-fns/format';

export const PAYMENT_LIST = 'PAYMENT_LIST';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';

export const getPayments = (scopeId, begin = new Date(), statuses = []) => {
	const query = queryString.stringify({
		begin: format(startOfMonth(begin), 'yyyy-MM'),
		end: format(startOfMonth(begin), 'yyyy-MM'),
		status: statuses,
	});

	return {
		[CALL_API]: {
			type: PAYMENT_LIST,
			endpoint: `employee-payment/scope/${scopeId}?${query}`,
			schema: Schemas.EMPLOYEE_PAYMENT_LIST,
		},
	};
};

export const setPaymentStatus = (paymentId, paymentStatus, disapprovalReason) => {
	return {
		[CALL_API]: {
			type: PAYMENT_UPDATE,
			endpoint: `employee-payment/${paymentId}`,
			schema: Schemas.EMPLOYEE_PAYMENT,
			body: {
				paymentStatus,
				disapprovalReason
			},
			method: 'PATCH',
		}
	}
};
