import { MenuItem, TableCell, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Icon from '@mui/material/Icon';
import Select from '@mui/material/Select';
import TableRow from '@mui/material/TableRow';
import { equals, move } from 'ramda';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { addClientProperty, deleteClientProperty, updateClientProperty } from '../../actions/clientActions';
import { addEmployeeProperty, deleteEmployeeProperty, updateEmployeeProperty } from '../../actions/employeeActions';
import { addScopeProperty, deleteScopeProperty, updateScopeProperty } from '../../actions/scopeDataActions';
import PropertyTypes, { propertyTypesWithChoices } from '../../constants/PropertyTypes';
import { languages } from '../../helpers/localization';
import withFormData from '../../modules/formData/withFormData';
import { notificationShow } from '../../modules/notification/notificationActions';
import PropertyEditorLabel from './PropertyEditorLabel';
import PropertyEditorOptions from './PropertyEditorOptions';
import PropertyEditorRowActions from './PropertyEditorRowActions';

function PropertyEditorRow({
	saving,
	success,
	watchSubmit,
	initialState,
	newProperty,
	context,
	deleteNewProperty,
	notificationShow: dispatchNotificationShow,
}) {
	const [property, setProperty] = useState(initialState);
	const [editMode, setEditMode] = useState(!!newProperty);
	const choiceRef = useRef();
	const didMountRef = useRef(false);
	const { t } = useTranslation();

	const isDirty = !equals(initialState, property);

	const handleKeyChange = (event) => {
		const { value } = event.target;
		setProperty({
			...property,
			key: value,
		});
	};

	const handleTypeChange = (event) => {
		const { value } = event.target;
		const options = { ...property.options };
		if (!propertyTypesWithChoices.includes(value)) {
			delete options.choices;
		}
		setProperty({
			...property,
			options,
			type: value,
		});
	};

	const handleDeleteProperty = () => {
		if (newProperty) {
			deleteNewProperty();
		} else {
			setProperty(null);
		}
	};

	const handleSave = useCallback((remove) => {
		let addAction;
		let updateAction;
		let deleteAction;
		switch (context) {
			case 'EmployeeListFilters':
				addAction = addEmployeeProperty;
				updateAction = updateEmployeeProperty;
				deleteAction = deleteEmployeeProperty;
				break;
			case 'ScopeFilter':
				addAction = addScopeProperty;
				updateAction = updateScopeProperty;
				deleteAction = deleteScopeProperty;
				break;
			case 'ClientFilter':
				addAction = addClientProperty;
				updateAction = updateClientProperty;
				deleteAction = deleteClientProperty;
				break;
			default:
				return;
		}

		if (
			property?.options?.choices?.some(choice => !choice.label
				|| Object.keys(languages).some(lang => !choice.label[lang] || !choice.label[lang].length))
		) {
			dispatchNotificationShow(t('properties.edit.missingChoiceTranslation'), 'warning');
		} else if (!property?.options?.label
			|| Object.keys(languages).some(lang => !property.options.label[lang] || !property.options.label[lang].length)) {
			dispatchNotificationShow(t('properties.edit.missingLabelTranslation'), 'warning');
		} else {
			dispatchNotificationShow(t('properties.edit.saveSuccess'), 'success');
		}

		if (typeof remove === 'boolean' && remove) {
			watchSubmit(deleteAction(initialState));
		} else if (newProperty) {
			watchSubmit(addAction(property));
		} else {
			watchSubmit(updateAction(property));
		}
		setEditMode(false);
	}, [context, dispatchNotificationShow, initialState, newProperty, property, t, watchSubmit]);

	const handleChoiceDelete = (choice) => () => {
		const newArray = [...property.options.choices];
		newArray.splice(newArray.indexOf(choice), 1);
		setProperty({
			...property,
			options: {
				...property.options,
				choices: newArray,
			},
		});
	};

	const handleRevert = () => {
		setProperty(initialState);
	};

	const handleChoicesChange = (choice) => {
		const newArray = [...property.options.choices];
		const index = newArray.findIndex(el => {
			// TODO: this check should be removed
			if (typeof el === 'string') {
				return el === choice.key;
			}
			return el.key === choice.key;
		});
		if (index > -1) newArray[index] = choice;

		setProperty({
			...property,
			options: {
				...property.options,
				choices: newArray,
			},
		});
	};

	const handleOrderChange = (event) => {
		const newArray = move(event.source.index, event.destination.index, [...property.options.choices]);
		setProperty({
			...property,
			options: {
				...property.options,
				choices: newArray,
			},
		});
	};

	const handleAddChoice = () => {
		const { value } = choiceRef.current;
		if (!value || value.length === 0) {
			return;
		}

		const newArray = [...property.options.choices || []];
		if (!newArray.includes(value)) newArray.push(value);

		setProperty({
			...property,
			options: {
				...property.options,
				choices: newArray,
			},
		});
		choiceRef.current.value = '';
	};

	const handleAddLabel = (translatableString) => {
		setProperty({
			...property,
			options: {
				...property.options,
				label: translatableString,
			},
		});
	};

	useEffect(() => {
		if (didMountRef.current && success) {
			setEditMode(false);
		} else {
			didMountRef.current = true;
		}
	}, [success, saving]);

	if (!property) {
		return (
			<TableRow>
				<TableCell colSpan={10} style={{ textAlign: 'center' }}>
					<Button startIcon={<Icon>warning</Icon>} onClick={() => handleSave(true)} variant="contained" color="secondary" disabled={saving}>
						Permanently delete
					</Button>
					<span style={{ margin: '0 2rem' }}>or</span>
					<Button onClick={handleRevert} variant="outlined" disabled={saving}>Revert</Button>
				</TableCell>
			</TableRow>
		);
	}

	return (
		<TableRow>
			<TableCell style={{ width: '20%' }}>
				{editMode
					? <TextField value={property.key} placeholder="examplePropertyKey" onChange={handleKeyChange} disabled={saving} />
					: property.key}
			</TableCell>
			<TableCell style={{ width: '15%' }}>
				{editMode ? (
					<Select
						onChange={handleTypeChange}
						color="primary"
						variant="outlined"
						value={property.type}
						disabled={saving}
					>
						{[
							PropertyTypes.TEXT,
							PropertyTypes.MULTI_TEXT,
							PropertyTypes.NUMBER,
							PropertyTypes.SELECT,
							PropertyTypes.MULTI_SELECT,
							PropertyTypes.BOOLEAN,
						].map(choice => (
							<MenuItem value={choice} key={choice}>{choice}</MenuItem>
						))}
					</Select>
				) : property.type}
			</TableCell>
			<TableCell>
				<PropertyEditorLabel
					property={property}
					handleAddLabel={handleAddLabel}
					editMode={editMode}
				/>
			</TableCell>
			<TableCell>
				{propertyTypesWithChoices.includes(property.type) && (
					<PropertyEditorOptions
						property={property}
						editMode={editMode}
						handleChoicesChange={handleChoicesChange}
						handleOrderChange={handleOrderChange}
						handleChoiceDelete={handleChoiceDelete}
						handleAddChoice={handleAddChoice}
						forwardedRef={choiceRef}
					/>
				)}
			</TableCell>
			<TableCell style={{ width: '10rem', textAlign: 'right' }}>
				<PropertyEditorRowActions
					saving={saving}
					disabled={property?.key.length === 0}
					isDirty={isDirty}
					handleRevert={handleRevert}
					handleSave={handleSave}
					setEditMode={setEditMode}
					editMode={editMode}
					newProperty={newProperty}
					handleDeleteProperty={handleDeleteProperty}
				/>
			</TableCell>
		</TableRow>
	);
}

PropertyEditorRow.propTypes = {
	initialState: PropTypes.object,
	context: PropTypes.string,
	newProperty: PropTypes.bool,
	deleteNewProperty: PropTypes.func,
	notificationShow: PropTypes.func,
};

export default withFormData()(connect(null, {
	notificationShow,
})(PropertyEditorRow));
