import { TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { niceDateTime } from '../../../helpers/datetime';
import DeclarationStatusChip from './DeclarationStatusChip';

function DeclarationsTable({ declarations, pagination, location, onPaginationChangePage, onPaginationChangePageSize }) {
	const { t, i18n } = useTranslation();

	return (
		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>{t('declarations.list.status')}</TableCell>
					<TableCell sx={{ whiteSpace: 'nowrap' }}>{t('declarations.list.employeeName')}</TableCell>
					<TableCell>{t('declarations.list.creation')}</TableCell>
					<TableCell>{t('declarations.list.lastUpdated')}</TableCell>
					<TableCell>{t('declarations.list.comments')}</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{declarations.length === 0 ? (
					<TableRow>
						<TableCell colSpan={100}>{t('declarations.list.zeroDeclarations')}</TableCell>
					</TableRow>
				) : declarations.map(declaration => (
					<TableRow key={declaration.id}>
						<TableCell>
							<DeclarationStatusChip label={t(`declarations.status.${declaration.status}`)} status={declaration.status} />
						</TableCell>
						<TableCell>{declaration.createdBy.fullName}</TableCell>
						<TableCell sx={{ whiteSpace: 'nowrap' }}>
							<Tooltip title={niceDateTime(declaration.creation, i18n.language, t)} placement="top">
								<span>
									{moment(declaration.creation).format('DD-MM-YYYY')}
								</span>
							</Tooltip>
						</TableCell>
						<TableCell sx={{ whiteSpace: 'nowrap' }}>
							<Tooltip title={niceDateTime(declaration.lastUpdated, i18n.language, t)} placement="top">
								<span>
									{moment(declaration.lastUpdated).format('DD-MM-YYYY')}
								</span>
							</Tooltip>
						</TableCell>
						<TableCell>
							<Tooltip
								title={<Typography paragraph>{declaration.comments}</Typography>}
								placement="bottom-start"
							>
								<Box
									component="div"
									sx={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}
								>
									{declaration.comments}
								</Box>
							</Tooltip>
						</TableCell>
						<TableCell>
							<IconButton
								component={Link}
								to={location.pathname}
								state={{ modal: 'editDeclarations', id: declaration.id }}
								sx={(theme) => ({ padding: theme.spacing(1) })}
							>
								<Icon>chevron_right</Icon>
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
			{pagination
				&& (
					<TableFooter>
						<TableRow>
							<TablePagination
								colSpan={6}
								count={pagination.total}
								rowsPerPage={pagination.pageSize}
								page={pagination.page}
								onPageChange={onPaginationChangePage}
								onRowsPerPageChange={onPaginationChangePageSize}
								rowsPerPageOptions={[10, 25, 50, 100]}
							/>
						</TableRow>
					</TableFooter>
				)}
		</Table>
	);
}

DeclarationsTable.propTypes = {
	declarations: PropTypes.array,
	location: PropTypes.object.isRequired,
	pagination: PropTypes.object,
};

export default DeclarationsTable;
