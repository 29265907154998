import { Icon } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';

import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';

const PREFIX = 'YearMonthPicker';

const classes = {
    dateBtn: `${PREFIX}-dateBtn`,
    dayWrapper: `${PREFIX}-dayWrapper`,
    day: `${PREFIX}-day`,
    customDayHighlight: `${PREFIX}-customDayHighlight`,
    nonCurrentMonthDay: `${PREFIX}-nonCurrentMonthDay`,
    highlightNonCurrentMonthDay: `${PREFIX}-highlightNonCurrentMonthDay`,
    highlight: `${PREFIX}-highlight`,
    firstHighlight: `${PREFIX}-firstHighlight`,
    endHighlight: `${PREFIX}-endHighlight`
};

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    [`& .${classes.dateBtn}`]: {
		marginRight: theme.spacing(1),
	},

    [`& .${classes.dayWrapper}`]: {
		position: 'relative',
	},

    [`& .${classes.day}`]: {
		width: 36,
		height: 36,
		fontSize: theme.typography.caption.fontSize,
		margin: '0 2px',
		color: 'inherit',
	},

    [`& .${classes.customDayHighlight}`]: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: '2px',
		right: '2px',
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: '50%',
	},

    [`& .${classes.nonCurrentMonthDay}`]: {
		color: theme.palette.text.disabled,
	},

    [`& .${classes.highlightNonCurrentMonthDay}`]: {
		color: '#676767',
	},

    [`& .${classes.highlight}`]: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
	},

    [`& .${classes.firstHighlight}`]: {
		extend: 'highlight',
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%',
	},

    [`& .${classes.endHighlight}`]: {
		extend: 'highlight',
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%',
	}
}));

function YearMonthPicker(props) {
	const { selectedDate: selectedDateProp, onChange, minDate, maxDate } = props;

	const [selectedDate, setSelectedDate] = useState(subMonths(startOfMonth(new Date()), 1));

	useEffect(() => {
		if (selectedDateProp) {
			setSelectedDate(selectedDateProp);
		}
	}, [selectedDateProp]);

	const formatSelectLabel = (date, invalidLabel) => {
		if (date === null) {
			return '';
		}

		if (date instanceof moment) {
			// eslint-disable-next-line no-param-reassign
			date = date.toDate();
		}

		return date && isValid(date)
			? `${format(startOfMonth(date), 'MMMM yyyy')}`
			: invalidLabel;
	};

	const convertDate = (date) => {
		if (!date || !date.isValid()) {
			return undefined;
		}
		if (date instanceof moment) {
			// eslint-disable-next-line no-param-reassign
			date = date.toDate();
		}
		return startOfMonth(date);
	};
	const handleChange = (date) => {
		const dataToUse = convertDate(date);
		setSelectedDate(dataToUse);
	};

	const handleMonthChange = (date) => {
		const dataToUse = convertDate(date);
		if (onChange) onChange(dataToUse);
	};

	const renderWrappedDay = useCallback((date, selectedDates, pickersDayProps) => {
		const { outsideCurrentMonth } = pickersDayProps;
		if (date instanceof moment) {
			// eslint-disable-next-line no-param-reassign
			date = date.toDate();
		}
		let selectedDateParam = selectedDates?.[0];
		if (selectedDateParam instanceof moment) {
			// eslint-disable-next-line no-param-reassign
			selectedDateParam = selectedDateParam.toDate();
		}

		const start = startOfMonth(selectedDateParam);
		const end = endOfMonth(selectedDateParam);

		const dayIsBetween = isWithinInterval(date, { start, end });
		const isFirstDay = isSameDay(date, start);
		const isLastDay = isSameDay(date, end);

		const wrapperClassName = classNames({
			[classes.highlight]: dayIsBetween,
			[classes.firstHighlight]: isFirstDay,
			[classes.endHighlight]: isLastDay,
		});

		const dayClassName = classNames(classes.day, {
			[classes.nonCurrentMonthDay]: outsideCurrentMonth,
			[classes.highlightNonCurrentMonthDay]: outsideCurrentMonth && dayIsBetween,
		});

		const handleButtonClick = () => {
			handleChange(date);
		};
		const formattedDate = format(date, 'd');
		return (
			<div className={wrapperClassName} key={formattedDate}>
				<IconButton className={dayClassName} onClick={handleButtonClick}>
					<span>
						{' '}
						{formattedDate}
						{' '}
					</span>
				</IconButton>
			</div>
		);
	}, [classes]);

	return (
        <MobileDatePicker
			views={['year', 'month']}
			openTo="year"
			minDate={minDate}
			maxDate={maxDate}
			value={selectedDate}
			showToolbar={false}
			inputFormat="MMMM YYYY"
			renderDay={renderWrappedDay}
			onChange={handleChange}
			onMonthChange={handleMonthChange}
			labelFunc={formatSelectLabel}
			closeOnSelect
			componentsProps={{
				actionBar: {
					actions: ['cancel'],
				},
			}}
			renderInput={(textFieldProps) => (
				<TextField
					{...textFieldProps}
					label="Period"
					InputProps={{
						endAdornment: (
							<StyledInputAdornment position="end">
								<Icon>edit</Icon>
							</StyledInputAdornment>
						),
					}}
				/>
			)}
		/>
    );
}

YearMonthPicker.propTypes = {
	selectedDate: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	maxDate: PropTypes.object,
	minDate: PropTypes.object,
};

export default YearMonthPicker;
