import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    IconButton,
    Icon,
    Box, Typography, TextField, DialogContentText,
} from '@mui/material';
import { groupBy, mapObjIndexed, omit } from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    CourseDescriptionTemplateSections,
    CourseDescriptionTemplateVariables,
} from '../../../constants/Templates';
import {
    getScopeParentsPropertyCombined,
    scopeParentsFromStore,
    scopeParentsFromStoreFull,
} from '../../../helpers/scope';
import { createEditorState, getTemplatesParams, replaceTemplateParams } from '../../../helpers/templates';

const createTemplateSorter = (locale) => (a, b) => {
    if (a.language !== b.language) {
        if (a.language === locale) return -1;
        if (b.language === locale) return 1;
    }

    return a.label.localeCompare(b.label);
};

function formatLabel(param) {
    return param.replaceAll('_', ' ');
}

function findAndPrefillParams(template, course) {
    const params = getTemplatesParams(template.content);

    function setParam(field, value) {
        if (Object.prototype.hasOwnProperty.call(params, field)) params[field] = value;
    }

    const parents = scopeParentsFromStoreFull(course.parent);
    const study = parents.find(scope => scope._class === 'Course');
    const institute = parents.find(scope => scope._class === 'Institute');

    setParam(CourseDescriptionTemplateVariables.INSTITUTE_NAME, institute.name?.trim?.());
    setParam(CourseDescriptionTemplateVariables.STUDY_NAME, study.name?.trim?.());
    setParam(CourseDescriptionTemplateVariables.COURSE_NAME, course.name?.trim?.());

    return params;
}

function ScopeDescriptionTemplatesDialog({ scope, onClose, onChangeContent, editorApi }) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState({});

    const selectTemplate = useCallback((template) => {
        setSelected((s) => ({
            ...s,
            [template.section]: {
                ...template,
                params: findAndPrefillParams(template, scope),
            },
        }));
    }, [scope]);

    const clearTemplate = useCallback((section) => {
        setSelected((s) => (
            omit([section], s)
        ));
    }, []);

    const makeHandleChange = useCallback((section) => (e) => {
        setSelected(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                params: {
                    ...prev[section].params,
                    [e.target.name]: e.target.value,
                },
            },
        }));
    }, []);

    const templates = useMemo(() => {
        const locales = ['nl_NL', ...scopeParentsFromStore(scope.id, 'locale').filter(Boolean)];
        const locale = locales[locales.length - 1].split('_')[1];
        return getScopeParentsPropertyCombined(scope.id, 'courseDescriptionTemplates').sort(createTemplateSorter(locale));
    }, [scope.id]);

    const groupedTemplates = useMemo(() => (
        groupBy((template) => template.section, templates)
    ), [templates]);

    const handleSet = useCallback(async () => {
        const { nodes, html } = await createEditorState(editorApi, Object.values(selected).filter(Boolean).map((template) => (
            replaceTemplateParams(template.content || undefined, template.params)
        )).join('\n\n'));

        onChangeContent(nodes, html);
    }, [onChangeContent, selected, editorApi]);

    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <DialogTitle>
                {t('scope.edit.template.title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('scope.edit.template.description')}
                </DialogContentText>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    {CourseDescriptionTemplateSections.map(section => (
                        <Grid item sm xs={12} key={section}>
                            <Box sx={{ fontWeight: 'fontWeightBold', height: 40, display: 'flex', alignItems: 'center' }}>
                                {!!selected[section] && (
                                    <IconButton onClick={() => clearTemplate(section)} size="small">
                                        <Icon>arrow_back</Icon>
                                    </IconButton>
                                )}
                                {section}
                            </Box>
                            {selected[section] ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2">
                                            {`${selected[section].label} - ${selected[section].language}`}
                                        </Typography>
                                    </Grid>
                                    {Object.values(selected[section].params).length === 0 && (
                                        <Grid item xs={12}>
                                            <em>{t('scope.edit.template.noParams')}</em>
                                        </Grid>
                                    )}
                                    {Object.values(mapObjIndexed((value, param) => (
                                        <Grid item xs={12} key={param}>
                                            <TextField
                                                fullWidth
                                                name={param}
                                                value={value || ''}
                                                label={formatLabel(param)}
                                                onChange={makeHandleChange(section)}
                                            />
                                        </Grid>
                                    ), selected[section].params))}
                                </Grid>
                            ) : (
                                <List dense>
                                    {groupedTemplates[section]?.map((template, i) => (
                                        <ListItemButton
                                            key={i}
                                            onClick={() => selectTemplate(template)}
                                            sx={{
                                                '& .hover': { display: 'none' },
                                                '&:hover .none': { display: 'none' },
                                                '&:hover .hover': { display: 'inline-block' },
                                            }}
                                        >
                                            <ListItemIcon sx={{ minWidth: 40 }}>
                                                <Icon className="none">radio_button_unchecked</Icon>
                                                <Icon className="hover">radio_button_checked</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={template.label} />
                                        </ListItemButton>
                                    ))}
                                </List>

                            )}
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error">
                    {t('general.close')}
                </Button>
                <Button onClick={handleSet} variant="contained" color="primary" disabled={Object.keys(selected).length === 0}>
                    {t('scope.edit.template.set')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ScopeDescriptionTemplatesDialog.propTypes = {
    scope: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onChangeContent: PropTypes.func.isRequired,
    editorApi: PropTypes.object.isRequired,
};

export default ScopeDescriptionTemplatesDialog;
