import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import withFormData from '../../modules/formData/withFormData'
import { classEditScopeTypes, composePatchScope } from '../../actions/scopeEditActions'
import Loader from './loader/Loader'

const PREFIX = 'ScopeVisibilitySwitcher';

const classes = {
    icon: `${PREFIX}-icon`
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    [`&.${classes.icon}`]: {
		color: theme.palette.error.main,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: `-${theme.spacing(3)}`,
		marginBottom: `-${theme.spacing(2)}`,
		fontSize: '1em',
		verticalAlign: '-0.2em',
		'&.visible': {
			color: theme.palette.success.main,
		},
		'&.noMargins': {
			marginTop: 0,
			marginBottom: 0,
		},
	}
}));

const ScopeVisibilitySwitcher = ({ scope, noMargins, saving, watchSubmit, }) => {
	const visible = scope.published === 'published';
	const iconClasses = classNames(classes.icon, {
		visible: Boolean(visible),
		noMargins: noMargins,
	});

	if(saving) return (
		<Loader inline />
	);

	const type = classEditScopeTypes[scope._class];

	const handleClick = () => watchSubmit(composePatchScope(type)(scope.id, {
		published: visible ? 'draft' : 'published'
	}));

    return (
        <StyledIconButton onClick={handleClick} className={iconClasses}>
			<Icon>
				{visible ? 'visibility' : 'visibility_off'}
			</Icon>
		</StyledIconButton>
    );
};

ScopeVisibilitySwitcher.propTypes = {
	scope: PropTypes.object.isRequired,
	noMargins: PropTypes.bool,
};

ScopeVisibilitySwitcher.defaultProps = {
	noMargins: false,
};

export default (withFormData()(ScopeVisibilitySwitcher))
