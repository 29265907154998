export const infoShowId = (id) => ({
	type: 'INFO_SHOW_ID',
	id,
});

export const infoShowSlug = (slug) => ({
	type: 'INFO_SHOW_SLUG',
	slug,
});

export const infoShow = (title, content) => ({
	type: 'INFO_SHOW',
	title,
	content,
});

export const infoHide = () => ({
	type: 'INFO_HIDE',
});
