// New group
export const GROUP_STATE_CREATED = 'CREATED';
// Teacher assigned, time slots, ready to take place
export const GROUP_STATE_READY = 'READY';
// First time slot passed
export const GROUP_STATE_IN_PROGRESS = 'IN_PROGRESS';
// Last time slot passed, time for evaluations and finalizing costs
export const GROUP_STATE_FINISHED = 'FINISHED';
// Will not take place anymore - FINAL STATE
export const GROUP_STATE_CANCELLED = 'CANCELLED';
// Evaluations, costs, etc filled, waiting for final validating
export const GROUP_STATE_ADMINISTRATION_COMPLETE = 'ADMINISTRATION_COMPLETE';
// Every checked by a person or automatically
export const GROUP_STATE_ADMINISTRATION_VALIDATED = 'ADMINISTRATION_VALIDATED';
// Financial accounting is about to take place
export const GROUP_STATE_RECKONING = 'RECKONING';
// Paid out, everything is done for this group - FINAL STATE
export const GROUP_STATE_CLOSED = 'CLOSED';

export const canCancel = (state) => {
	switch (state){
		case GROUP_STATE_CREATED:
		case GROUP_STATE_READY:
		case GROUP_STATE_IN_PROGRESS:
		case GROUP_STATE_FINISHED:
			return true;
		default:
			return false;
	}
}

export const canEvaluate = (state) => {
	switch (state){
		case GROUP_STATE_FINISHED:
		case GROUP_STATE_ADMINISTRATION_COMPLETE:
			return true;
		default:
			return false;
	}
}

export const isCancelledOrClosed = (state) => {
	switch (state){
		case GROUP_STATE_CANCELLED:
		case GROUP_STATE_CLOSED:
			return true;
		default:
			return false;
	}
}

export const isAfterInProgress = (state) => {
	switch (state){
		case GROUP_STATE_CREATED:
		case GROUP_STATE_READY:
		case GROUP_STATE_IN_PROGRESS:
			return false;
		default:
			return true;
	}
}

export const canHaveTasks = state => {
	switch (state) {
		case GROUP_STATE_CANCELLED:
		case GROUP_STATE_RECKONING:
		case GROUP_STATE_CLOSED:
		case GROUP_STATE_ADMINISTRATION_VALIDATED:
			return false
		default:
			return true
	}
}

export const canHaveEvaluations = state => {
	switch (state) {
		case GROUP_STATE_ADMINISTRATION_COMPLETE:
		case GROUP_STATE_ADMINISTRATION_VALIDATED:
		case GROUP_STATE_RECKONING:
		case GROUP_STATE_CLOSED:
		case GROUP_STATE_FINISHED:
		case GROUP_STATE_IN_PROGRESS:
			return true
		default:
			return false
	}
}
