import { useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Line as LineChart } from 'react-chartjs-2'
import { formatMoneyDirect } from '../../../helpers/formatter'
import Loader from '../../util/loader/Loader'
import { FINANCIAL_CHART } from './financialDashboardUtils'

const RevenueChart = ({ aggregatedScopeReport, selectedDate, currency }) => {
	const theme = useTheme();
	const wideScreen = useMediaQuery(theme.breakpoints.up('md'));
	const finalOptions = useMemo(() => ({
		spanGaps: true,
        elements: {
            line: {
                tension: .25 // disables bezier curves
            }
        },
		scales: {
			x: {
				type: 'time',
				time: {
					unit: 'month'
				}
			},
			y: {
				ticks: {
					callback: function(tick) {
						return formatMoneyDirect(tick, currency, true);
					}
				}
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: (value) => formatMoneyDirect(value.parsed.y, currency, true),
				},
				mode: 'x',
				display: true,
				position: 'nearest',
			},
			annotation: {
				annotations: [
					{
						type: 'line',
						mode: 'vertical',
						scaleID: 'x',
						value: moment(selectedDate)
							.format('YYYY-MM'),
						borderColor: "red",
						label: {
							display: true,
							content: moment(selectedDate)
								.format('MMMM YYYY'),
							position: "start",
							yAdjust: -5,
						}
					}
				]
			},
		},
	}), [selectedDate, currency]);

	// Do only show loader at first load, because I like the way how the charts update dynamically
	// better than having the loader appear all the time.
	if (!aggregatedScopeReport) return <Loader />;

	const aggregatedScopeReportMonths = Object.keys(aggregatedScopeReport);
	const aggregatedScopeReportValues = Object.values(aggregatedScopeReport)

	const datasets = [
		{
			...FINANCIAL_CHART.line.neutral,
			fill: true,
			label: 'Net Revenue',
			data : aggregatedScopeReportValues
				.map(report => report.netRevenue.amount)
		},
		{
			...FINANCIAL_CHART.line.success,
			fill: true,
			label: 'Gross Profit',
			data : aggregatedScopeReportValues
				.map(report => report.grossProfit.amount)
		},
	];

	return (
		<LineChart
			data={{
				labels: aggregatedScopeReportMonths,
				datasets: datasets,
			}}
			options={finalOptions}
			height={wideScreen ? 60 : 100}
			width={150}
		/>
	);
}

RevenueChart.propTypes = {
	aggregatedScopeReport: PropTypes.object,
	selectedDate: PropTypes.any,
	currency: PropTypes.string,
}

export default RevenueChart
