import React, { Fragment } from 'react'
import Grid from '@mui/material/Grid'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { workingScopesNameString } from '../../helpers/scope'
import { capabilities, hasScopeCapability } from '../../helpers/capabilities'
import Alert from '../util/alert/Alert'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import PageHeaderMenu from '../page/PageHeaderMenu'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import BundleList from './BundleList'
import BundleAddModal from './BundleAddModal'
import BundleViewModal from './BundleViewModal'

const BundlePage = ({ workingScopes, workingScope }) => {
	if(!workingScope) return (
		<Alert>Select a scope first.</Alert>
	);

	const menuItems = hasScopeCapability(workingScope, capabilities.CreateDiscountCodeCapability) ? [
		{
			name: workingScope._class === 'Course' ? 'Create new product bundle' : 'Select a study to create a bundle',
			to: '/portal/promotion/bundle',
			state: {
				modal: 'add',
				id: workingScope.id,
			},
			icon: 'add',
			disabled: workingScope._class !== 'Course',
		},
	] : [];

	return (
		<Fragment>
			<ModalManager>
				<ModalItem state="add" component={BundleAddModal} />
				<ModalItem state="view" component={BundleViewModal} />
			</ModalManager>
			<PageHeader>
				<PageHeaderMenu items={menuItems} />
				<PageHeaderTitle
					headline="Product bundles"
					description={`${workingScopesNameString(workingScopes)}`}
				/>
			</PageHeader>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<BundleList scope={workingScope} />
				</Grid>
			</Grid>
		</Fragment>
	);
}

export default withWorkingScope(BundlePage)
