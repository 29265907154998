import { compose } from 'ramda';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from '../../hoc/withRouter';
import { doLogout } from '../../modules/auth/authActions'

class LogoutPage extends Component {
	render(){
		return (
			<div>
				<h1>Uitloggen</h1>
				<p>Bezig met uitloggen, een moment a.u.b.</p>
			</div>
		)
	}

	componentDidMount(){
		const { auth, navigate, doLogout } = this.props;

		if(auth.tokenValue){
			doLogout();
		}else{
			navigate('/login', { replace: true });
		}
	}

	componentDidUpdate(prevProps){
		const { auth, navigate } = this.props;

		if(prevProps.auth !== auth && auth){
			if(!auth.tokenValue){
				navigate('/login', { navigate: true });
			}
		}
	}
}

LogoutPage.propTypes = {
	doLogout: PropTypes.func,
	navigate: PropTypes.func,
};

export default compose(
	connect(state => ({
		auth: state.auth
	}), {
		doLogout
	}),
	withRouter,
)(LogoutPage)
