import { combineReducers } from 'redux'

import entities from './entities'

import authReducer from '../modules/auth/authReducer'
import scopeReducer from '../modules/scope/scopeReducer'
import notificationReducer from '../modules/notification/notificationReducer'
import formDataReducer from '../modules/formData/formDataReducer'
import scopeFilterReducer from '../modules/scopeFilter/scopeFilterReducer'
import listsReducer from '../modules/lists/listsReducer'
import fetchDataReducer from '../modules/fetchData/fetchDataReducer'
import infoReducer from '../modules/info/infoReducer'
import timeSlotsReducer from '../modules/timeSlots/timeSlotsReducer'

let reducers = {
	entities,
	...authReducer,
	...scopeReducer,
	...notificationReducer,
	...formDataReducer,
	...fetchDataReducer,
	...scopeFilterReducer,
	...infoReducer,
	...listsReducer,
	...timeSlotsReducer,
};

export default combineReducers(reducers)
