import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { withTranslation } from 'react-i18next';
import { filterRangeFormat } from '../../modules/scopeFilter/scopeFilterHelpers'

const PREFIX = 'RangePicker';

const classes = {
    pickerGrid: `${PREFIX}-pickerGrid`,
    highlight: `${PREFIX}-highlight`,
    firstHighlight: `${PREFIX}-firstHighlight`,
    endHighlight: `${PREFIX}-endHighlight`,
    day: `${PREFIX}-day`,
    nonCurrentMonthDay: `${PREFIX}-nonCurrentMonthDay`,
    highlightNonCurrentMonthDay: `${PREFIX}-highlightNonCurrentMonthDay`,
    pickerRoot: `${PREFIX}-pickerRoot`
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.pickerGrid}`]: {
		overflow: 'hidden',
	},

    [`& .${classes.highlight}`]: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
	},

    [`& .${classes.firstHighlight}`]: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderTopLeftRadius: '50%',
		borderBottomLeftRadius: '50%',
		'& > *': {
			background: theme.palette.primary.dark,
		}
	},

    [`& .${classes.endHighlight}`]: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderTopRightRadius: '50%',
		borderBottomRightRadius: '50%',
		'& > *': {
			background: theme.palette.primary.dark,
		}
	},

    [`& .${classes.day}`]: {
		width: 36,
		height: 36,
		fontSize: theme.typography.caption.fontSize,
		margin: '0 2px',
		color: 'inherit',
	},

    [`& .${classes.nonCurrentMonthDay}`]: {
		color: theme.palette.text.disabled,
	},

    [`& .${classes.highlightNonCurrentMonthDay}`]: {
		color: theme.palette.grey[500],
	},

    [`& .${classes.pickerRoot}`]: {
		margin: theme.spacing(1, 0),
	}
}));


class RangePicker extends Component {
	renderWrappedDay = (isFirst, otherDate) => (date, selectedDates, pickersDayProps) => {
		const { } = this.props;
		const { outsideCurrentMonth } = pickersDayProps;

		const isSameDay = date.isSame(selectedDates[0], 'day');
		const isOtherDay = otherDate ? date.isSame(otherDate, 'day') : false;
		const dayIsInRange = otherDate ?
			isFirst ? date.isBetween(selectedDates[0], otherDate, null, '[]') : date.isBetween(otherDate, selectedDates[0], null, '[]')
			:
			isFirst ? date.isSameOrAfter(selectedDates[0], 'day') : date.isSameOrBefore(selectedDates[0], 'day');

		const wrapperClassName = classNames({
			[classes.highlight]: dayIsInRange,
			[classes.firstHighlight]: (isFirst && isSameDay) || (!isFirst && isOtherDay),
			[classes.endHighlight]: (!isFirst && isSameDay) || (isFirst && isOtherDay),
		});

		const dayClassName = classNames(classes.day, {
			[classes.nonCurrentMonthDay]: outsideCurrentMonth,
			[classes.highlightNonCurrentMonthDay]: outsideCurrentMonth && dayIsInRange,
		});

		const handleClick = () => {
			if (isFirst) {
				this.handleChangeStart(date);
			} else {
				this.handleChangeEnd(date);
			}
		}

		return (
			<div className={wrapperClassName} key={date.format('D')}>
				<IconButton className={dayClassName} onClick={handleClick}>
					<span> {date.format('D')} </span>
				</IconButton>
			</div>
		);
	}

    render() {
		const { range,  t } = this.props;

		const start = range.start ? moment(range.start, filterRangeFormat) : null;
		const end = range.end ? moment(range.end, filterRangeFormat) : null;

        return (
            <StyledGrid container spacing={2}>
				<Grid item sm={6} xs={12} className={classes.pickerGrid}>
					{start && (
						<CalendarPicker
							onChange={() => { return true }}
							date={start}
							renderDay={this.renderWrappedDay(true, end)}
							maxDate={end ? moment(end).subtract(1, 'day') : undefined}
							classes={{ root: classes.pickerRoot }}
						/>
					)}
					<FormControlLabel
						control={
							<Switch
								checked={!range.start}
								onChange={this.handleToggleStart}
								value="noStart"
								color="primary"
							/>
						}
						label={t('dateTime.noStartDate')}
					/>
				</Grid>
				<Grid item sm={6} xs={12} className={classes.pickerGrid}>
					{end && (
						<CalendarPicker
							date={end}
							onChange={this.handleChangeEnd}
							renderDay={this.renderWrappedDay(false, start)}
							minDate={start ? moment(start).add(1, 'day') : undefined}
						/>
					)}
					<FormControlLabel
						control={
							<Switch
								checked={!range.end}
								onChange={this.handleToggleEnd}
								value="noEnd"
								color="primary"
							/>
						}
						label={t('dateTime.noEndDate')}
					/>
				</Grid>
			</StyledGrid>
        );
    }

    handleChangeStart = (date) => {
		console.log('handleChangeStart');
		this.props.onChange({
			...this.props.range,
			start: moment(date).format(filterRangeFormat)
		});
	}

	handleChangeEnd = (date) => {
		this.props.onChange({
			...this.props.range,
			end: moment(date).format(filterRangeFormat)
		});
	}

	handleToggleStart = (e) => {
		this.props.onChange({
			...this.props.range,
			start: e.target.checked ? null : moment().subtract(31, 'days').format(filterRangeFormat)
		});
	}

	handleToggleEnd = (e) => {
		this.props.onChange({
			...this.props.range,
			end: e.target.checked ? null : moment().format(filterRangeFormat)
		});
	}
}

RangePicker.propTypes = {
	range: PropTypes.shape({
		start: PropTypes.string,
		end: PropTypes.string,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default withTranslation()((RangePicker))
