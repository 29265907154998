import { accordionClasses, accordionDetailsClasses, accordionSummaryClasses, styled } from '@mui/material';
import React, { Component } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import { fetchClientOrderTransactions } from '../../../actions/clientActions'
import withFetchData from '../../../modules/fetchData/withFetchData'
import { niceDateTime } from '../../../helpers/datetime'
import { sortByTime } from '../../../helpers/sorts'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'
import { parseStatus, PENDING, StatusChip, transactionStatusStyle } from '../utils'

const StyledAccordion = styled(Accordion)({
	[`&.${accordionClasses.root}`]: {
		boxShadow: 'none',
		margin: '8px 0 8px 0',
		'&:before': {
			height: 0,
		},
	},
})

const StyledAccordionSummary = styled(AccordionSummary)({
	[`&.${accordionSummaryClasses.root}`]: {
		backgroundColor: '#efefef',
		border: '1.5px solid #d9d9d9',
		borderRadius: 4,
		height: '4em',
	},
	[`& .${accordionSummaryClasses.expanded}`]: {
		height: '3em',
	},
})

const StyledAccordionDetails = styled(AccordionDetails)({
	[`&.${accordionDetailsClasses.root}`]: {
		border: '1.5px solid #d9d9d9',
		borderTop: 'none',
		margin: `0 8px 0 8px`,
		paddingBottom: 8,
	},
})

const StyledGrid = styled(Grid)({
	display: 'flex',
	justifyContent: 'flex-end',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.link.main,
	fontWeight: theme.typography.fontWeightBold,
	fontSize: theme.typography.fontSize * 1.2,
}))

class ClientOrderTransactions extends Component {

	noTransaction = <Alert type={Alert.TYPE_INFO}>There are no transactions for this order</Alert>

	handleData = data => data.sort(sortByTime('statusDateTime')).reverse()

	render() {
		const { loading, error, data } = this.props

		if (error) return (
			<Alert type={Alert.TYPE_WARNING}>
				{error.errorCode === 403 ? 'You do not have the permission to view the transactions on this order.' : error.error}
			</Alert>
		)
		if (loading || loading === undefined || !data) return <Loader/>
		if (!data.length) return this.noTransaction


		return this.handleData(data)
		.map(({ id, transactionId, state, identifierPublic, creationDateTime, statusDateTime, paymentOption }, i) => {

			const transactionDetails = [
				{ key: 'transId', name: 'TransactionID', value: transactionId },
				{ key: 'idPub', name: 'Identifier', value: identifierPublic },
				{ key: 'created', name: 'Created', value: niceDateTime(creationDateTime) },
				{ key: 'update', name: 'Last updated', value: niceDateTime(statusDateTime) },
				{ key: 'state', name: 'Payment Status', value: state },
				{ key: 'paymentType', name: 'Payment type', value: paymentOption ? paymentOption.name : 'Unknown' },
			]

			return (
				<StyledAccordion key={id} defaultExpanded={!i}>
					<StyledAccordionSummary expandIcon={<Icon>expand_more</Icon>}>
						<Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
							<Grid item md={4}>
								<StyledTypography>
									{transactionId}
								</StyledTypography>
							</Grid>
							<Grid item md={4}>
								<Typography align={'right'}>{niceDateTime(creationDateTime)}</Typography>
							</Grid>
							<StyledGrid item md={4}>
								<StatusChip
									label={parseStatus(state)}
									className={transactionStatusStyle[state]}
									variant={transactionStatusStyle[state] === PENDING ? 'outlined' : 'default'}
								/>
							</StyledGrid>
						</Grid>
					</StyledAccordionSummary>
					<StyledAccordionDetails>
						<Table size="small">
							<TableBody>
								{transactionDetails.map(detail => (
									<TableRow key={detail.key} style={{ height: 25 }}>
										<TableCell style={{ border: 'none' }} variant={'head'}>
											{detail.name}:
										</TableCell>
										<TableCell style={{ border: 'none' }}>
											{detail.value}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>

					</StyledAccordionDetails>
				</StyledAccordion>
			)
		})
	}
}

export default withFetchData(props => fetchClientOrderTransactions(props.orderId))(ClientOrderTransactions)
