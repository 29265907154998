// We are not linting this file for now as it requires quite some changes and is not worth the risk.
/*
 eslint-disable
 */

import { normalize } from 'normalizr';
import fetch from 'isomorphic-fetch';
import { parse } from 'query-string';
import { createCookieDateString, readCookie, eraseCookie, createCookieDays } from '../helpers/cookie';
import { createApiTypes } from '../helpers/types';
import { AUTH_LOGOUT } from '../modules/auth/authActions';

const API_ROOT = process.env.API_URL || '/api/';

const auth_header_token = 'X-ACCESS-TOKEN';
export const auth_cookie = 'ATHENAPORTAL-AUTH';

let auth_token = readCookie(auth_cookie);

export const hasAuthToken = () => {
	return !!auth_token;
};

function readAuthData(body){
	if (body.tokenValue && body.expires) {
		auth_token = body.tokenValue;

		createCookieDateString(auth_cookie, auth_token, body.expires);
	}
}

function loadUrlToken(){
	const query = parse(document.location.search);

	if(query.authtoken){
		// If there is a token given, replace the current one (if present)
		createCookieDays(auth_cookie, query.authtoken, 30);
		auth_token = query.authtoken;

		// Remove it from the path
		document.location.replace(document.location.pathname);
	}
}

if (process.env.NODE_ENV !== 'test') {
	loadUrlToken();
}

function logoutAuthHeaders() {
	auth_token = null;

	eraseCookie(auth_cookie);
}

function getFileNameFromResponseHeader(req, filename) {
	let name = (req.getResponseHeader('content-disposition') || `attachment; filename=${filename}`).split("; ")[1].split("=")[1];
	const first = name.slice(0, 1);
	const last = name.slice(-1);
	if (first === '"' && last === '"') {
		name = name.slice(1, -1);
	}
	return name;
}

export function downloadFromApi(endpoint, filename = undefined) {
	const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;
	const req = new XMLHttpRequest();
	req.open('GET', fullUrl);
	req.setRequestHeader(auth_header_token, auth_token);
	req.responseType = 'blob';

	req.onload = function(event) {
		const blob = req.response;
		const link = document.createElement('a');

		link.href = window.URL.createObjectURL(blob);
		link.download = filename ? filename : getFileNameFromResponseHeader(req, filename);
		link.click();
	};

	req.send();
}

export function redirectFromApi(endpoint){
	document.location.href = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;
}

function callApi(endpoint, schema, method = 'GET', body = null, pagination, settings = {
	headers: {},
	multipart: false,
}) {
	const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint;
	const options = {
		method: method.toUpperCase(),
		headers: {
			...settings.headers,
		},
	};

	if (pagination) {
		const start = pagination.page * pagination.pageSize;

		options.headers['Range'] = start + '-' + (start + pagination.pageSize);
		options.headers['Range-Unit'] = 'items';
	}

	if (body) {
		if (options.method === undefined || options.method === 'GET') {
			options.method = 'POST';
		}

		if (settings.multipart) {
			const formData = new FormData();

			Object.keys(body).forEach(key => {
				formData.set(key, body[key]);
			});

			options.body = formData;
		} else {
			options.body = JSON.stringify(body);
			options.headers['Content-Type'] = 'application/json; charset=utf-8';
		}
	}

	if (auth_token) {
		options.headers[auth_header_token] = auth_token;
	}

	return fetch(fullUrl, options)
		.then(response => {
			// Try to parse in json
			return response.json()
				.then(json => ({ json, response }))
				.catch(error => {
					return ({ json: null, response });
				});
		})
		.then(({ json, response }) => {
			// Response checks
			if (!response.ok) {
				return Promise.reject({
					...json,
					errorCode: response.status,
				});
			}

			if(!json) return {};

			// Authentication
			readAuthData(json);

			// Create final data
			let data = json;

			// Normalizing with and without scheme
			if (schema) {
				data = Object.assign({},
					normalize(data, schema)
				);
			} else {
				data = Object.assign({}, {
					result: data,
				});
			}

			// Add pagination results
			if (pagination) {
				const [start, end, size]
					= response.headers.get('content-range').split(/[-\/]/).map(Number);

				// Change the page if it exceeded the total data
				// The final component can see this change and act on it
				data.pagination = {
					page: start > size
						? size <= pagination.pageSize ? 0 : Math.ceil(size / pagination.pageSize) - 1
						: Math.floor(start / pagination.pageSize),
					pageSize: pagination.pageSize,
					total: size,
				};
			}

			data.cache = {
				date: response.headers.get('date'),
				control: response.headers.get('cache-control'),
			};

			return data;
		})
		.catch((error) => {
			if (!error.message && error.errorCode) return Promise.reject(error);

			// Having a message but not an errorCode means it is not an API server error
			// ... so handle differently
			return Promise.reject({
				error: `${error.message}. This is possibly a local problem. Wait or try again later.`,
				errorCode: 502,
			});
		});
}

export const CALL_API = Symbol('Call API');

export default store => next => action => {
	// Catch logout
	if (action.type === AUTH_LOGOUT) {
		logoutAuthHeaders();
		return next(action);
	}

	// Get the api request data
	const callAPI = action[CALL_API];
	if (typeof callAPI === 'undefined') {
		return next(action);
	}

	const { endpoint, schema, type, method, body, pagination, settings } = callAPI;

	function nextWith(data) {
		const finalAction = Object.assign({}, action, data);
		delete finalAction[CALL_API];
		return finalAction;
	}

	const [requestType, successType, failureType] = createApiTypes(type);
	next(nextWith({ type: requestType }));

	return callApi(endpoint, schema, method, body, pagination, settings).then(
		response => next(nextWith({
			response,
			type: successType,
		})),
		error => {
			// Dispatch logout success if status is 401 to let the store know
			if (error && error.errorCode === 401) {
				logoutAuthHeaders();
				next({
					type: AUTH_LOGOUT,
					autoLogout: true,
				});
			}

			const message = error.error || (
				error.errorCode === 500 ? 'Internal server error' : 'Something went wrong...'
			);

			return next(nextWith({
				...error,
				type: failureType,
				message: message,
				details: error.requestErrors,
				code: error.errorCode,
				uuid: error.uuid,
				error: message, // TODO: deprecate
				errors: error.requestErrors, // TODO: deprecate
				errorCode: error.errorCode, // TODO: deprecate
			}));
		},
	);
};
