import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys, mapObjIndexed, values } from 'ramda'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import FormHelperText from '@mui/material/FormHelperText'
import withFormData from '../../modules/formData/withFormData'
import FormDataError from '../../modules/formData/FormDataError'
import withValidation, { Joi } from '../../hoc/withValidation'
import withSimpleState from '../../hoc/withSimpleState'
import { patchRoom } from '../../actions/locationActions'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'

const paymentUnitOptions = {
	PER_HOUR: 'Pay/price per hour',
	PER_DAY: 'Pay/price per day',
	FREE: 'Free',
};

class RoomEditForm extends Component {
	render(){
		const { validation, validationBlur, fields, onChangeField, saving, success, error } = this.props;

		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Room saved.
			</Alert>
		);

		return (
			<div>
				{ saving && <Loader sheet /> }
				<Grid container spacing={3}>
					{
						error &&
						<Grid item xs={12}>
							<FormDataError error={error} showFieldErrors />
						</Grid>
					}
					<Grid item xs={12} md={6}>
						<TextField name="name"
								   value={fields.name}
								   onChange={onChangeField}
								   label="Name"
								   error={!!validation['name']}
								   helperText={validation['name']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="seats"
								   value={fields.seats || 0}
								   onChange={onChangeField}
								   label="Amount of seats"
								   error={!!validation['seats']}
								   helperText={validation['seats']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<FormControl fullWidth error={!!validation['paymentUnit']}>
							<InputLabel htmlFor="paymentUnit">Price</InputLabel>
							<Select value={fields.paymentUnit}
									onChange={onChangeField}
									onBlur={validationBlur}
									input={<Input name="paymentUnit" id="paymentUnit" />}>
								{values(mapObjIndexed((label, key) => (
									<MenuItem value={key} key={key}>{label}</MenuItem>
								), paymentUnitOptions))}
							</Select>
							<FormHelperText>{validation['paymentUnit']}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6}>
						{
							fields.paymentUnit !== 'FREE' &&
							<TextField name="price"
									   value={fields.price}
									   onChange={onChangeField}
									   label="Price"
									   error={!!validation['price']}
									   helperText={validation['price']}
									   onBlur={validationBlur}
									   fullWidth />
						}
					</Grid>
				</Grid>
			</div>
		)
	}

	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.onSave();
		}
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields, currencyUnit } = this.props;

		watchSubmit(patchRoom(
			inputData.id, {
				name: fields.name,
				seats: fields.seats,
				paymentUnit: fields.paymentUnit,
				price: {
					amount: fields.paymentUnit === 'FREE' ? 0 : fields.price,
					currency: currencyUnit,
				},
			}
		));
	}
}

RoomEditForm.propTypes = {
	currencyUnit: PropTypes.string.isRequired,
};

export default withFormData({
	customId: () => 'RoomEditForm'
})(
	withValidation(
		withSimpleState(RoomEditForm, {
			convertOnChange: (field, val) => {
				if(field === 'price') return val.replace(',', '.');

				return val;
			}
		}),
		{
			name: Joi.string().required(),
			seats: Joi.number().required(),
			paymentUnit: Joi.string().allow(keys(paymentUnitOptions)).required(),
			price: Joi.number().precision(2).required(),
		}
	)
)
