import { Popover } from '@mui/material';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import contractTemplateStatuses from '../../../../constants/ContractTemplateStatuses';
import Alert from '../../../util/alert/Alert';

function CompanyFunctionDocumentValidationWarnings({ template }) {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handlePopoverClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{template?.validationStatus === contractTemplateStatuses.MISSING_SIGNATURE_PLACEHOLDER && (
				<Alert type={Alert.TYPE_WARNING}>
					{t('manage.company.functions.editContractTemplatesModal.missingPlaceholder')}
					<br />
					<code>{template.signatureTemplateProperty}</code>
					<br />
					<br />
					{t('manage.company.functions.editContractTemplatesModal.downloadPopulatedDocument')}
				</Alert>
			)}
			{template?.validationStatus === contractTemplateStatuses.MISSING_REQUIRED_PROPERTIES_IN_DOCUMENT && (
				<Alert type={Alert.TYPE_WARNING}>
					<Typography variant="subtitle2">
						{t('manage.company.functions.editContractTemplatesModal.missingTemplateProperties')}
						{' '}
						<IconButton onClick={handlePopoverClick}>
							<Icon>info_circle</Icon>
						</IconButton>
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handlePopoverClose}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'center',
								horizontal: 'left',
							}}
						>
							<Typography variant="subtitle2" style={{ margin: '1rem' }}>
								{t('manage.company.functions.editContractTemplatesModal.requiredTemplateProperties')}
							</Typography>
							<ul>
								{template.requiredTemplateProperties.map(prop => (
									<li key={prop}><Typography paragraph>{prop}</Typography></li>
								))}
							</ul>
						</Popover>
					</Typography>
					{ template.missingPropertyNames?.length > 0 && (
						<>
							<Typography paragraph>{t('manage.company.functions.editContractTemplatesModal.missingTemplatePropertyList')}</Typography>
							<ul>
								{template?.missingPropertyNames?.map(prop => (
									<li key={prop}>{prop}</li>
								))}
							</ul>
						</>
					)}
					<Typography paragraph>
						{t('manage.company.functions.editContractTemplatesModal.downloadPopulatedDocument')}
					</Typography>
				</Alert>
			)}
		</>
	);
}

CompanyFunctionDocumentValidationWarnings.propTypes = {
	template: PropTypes.object,
};

export default CompanyFunctionDocumentValidationWarnings;
