import React, { useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

const HesitationButton = ({ onClick, label, icon, children, ...props }) => {
	const [ clicked, setClicked ] = useState(false);
	const buttonRef = useRef();

	const handleClick = (e) => {
		if(clicked){
			onClick(e);
			setClicked(false);
		} else {
			setClicked(true);
		}

		if(buttonRef.current) buttonRef.current.blur();
	};

	const handleCancel = () => {
		setClicked(false);
	}

    return (
        <>
			<Button
				onClick={handleClick}
				buttonRef={buttonRef}
				{...props}
			>
				{clicked ? (
					<Fragment>
						<Icon sx={{ mr: 1 }}>warning</Icon>
						Confirm action
					</Fragment>
				) : (
					children ? children : (
						<Fragment>
							{icon && (
								<Icon sx={{ mr: 1 }}>{icon}</Icon>
							)}
							{label}
						</Fragment>
					)
				)}

			</Button>
			{clicked && (
				<IconButton onClick={handleCancel} sx={{ ml: 1, my: '-6px' }}>
					<Tooltip title="cancel">
						<Icon>close</Icon>
					</Tooltip>
				</IconButton>
			)}
		</>
    );
};

HesitationButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	label: PropTypes.string,
	icon: PropTypes.string,
	children: PropTypes.node,
};

export default HesitationButton;
