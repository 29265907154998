import { Checkbox, Dialog, DialogActions, DialogContent, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { putFunctionCapabilities } from '../../../../actions/functionsActions';
import withFormData from '../../../../modules/formData/withFormData';
import ApiError from '../../../util/ApiError';
import Loader from '../../../util/loader/Loader';

const PREFIX = 'EditFunctionCapabilitiesModal';

const classes = {
    list: `${PREFIX}-list`
};

const StyledDialog = styled(Dialog)({
	[`& .${classes.list}`]: {
		'& .MuiCheckbox-root': {
			padding: '4px',
		},
	},
});

function EditFunctionCapabilitiesModal({ handleClose, modal, watchSubmit, saving, success, error }) {

	const { t, i18n } = useTranslation();
	const [capabilities, setCapabilities] = useState(modal.capabilities);

	useEffect(() => {
		if (success) {
			handleClose();
		}
	}, [handleClose, success]);

	const handleToggle = (capability, checked) => () => {
		setCapabilities({ ...capabilities, [capability]: checked });
	};

	const handleSave = (e) => {
		e.preventDefault();
		window.scrollTo(0, 0);

		watchSubmit(putFunctionCapabilities(modal.id, capabilities));
	};

	return (
        <StyledDialog open={true} onClose={handleClose} maxWidth="md" fullWidth scroll="body">
			<DialogTitle>
				{t('manage.company.functions.editCapabilitiesModal.title')}
			</DialogTitle>
			<DialogContent>
				{saving && <Loader sheet />}
				{error && <ApiError error={error} />}
				<List className={classes.list}>
					{modal.capabilities && Object.keys(modal.capabilities)?.sort().map((capability, index) => (
						<ListItem key={capability} role={undefined} dense button onClick={handleToggle(capability, !capabilities[capability])}>
							<ListItemIcon>
								<Checkbox
									edge="start"
									checked={capabilities[capability] || false}
									tabIndex={-1}
									disableRipple
									color="secondary"
									inputProps={{
										'aria-labelledby': `label_${index}`,
									}}
								/>
							</ListItemIcon>
							<ListItemText
								id={`label_${index}`}
								primary={capability}
								secondary={
									i18n.exists(`capabilities.descriptions.${capability}`)
										? t(`capabilities.descriptions.${capability}`)
										: ''
								}
							/>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{t('general.close')}</Button>
				<Button onClick={handleSave} variant="contained" color="primary">{t('general.save')}</Button>
			</DialogActions>
		</StyledDialog>
    );
}

export default withFormData()(EditFunctionCapabilitiesModal);
