import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { lighten } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import ModalManager from '../modal/ModalManager';
import PageContentBlock from '../page/PageContentBlock';
import CourseSkuReferences from './sku/CourseSkuReferences';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'inline-block',
    width: `calc(100% - ${theme.spacing(4)})`,
    background: lighten(theme.palette.warning.main, 0.8),
    margin: theme.spacing(0, 2, 2),
    borderRadius: theme.borderRadius,
}));

function CourseBookletReferencesPageBlock({ course, isSkuEnabled }) {
    const { t } = useTranslation();
    const location = useLocation();
    const BookletBox = isSkuEnabled ? StyledBox : Box;

    return (
        <PageContentBlock
            title={t('scope.course.sku.pageBlock.title')}
            icon="menu_book"
            links={[{
                label: t('general.edit'),
                to: location.pathname,
                state: { modal: 'edit-sku-list' },
            }]}
        >
            {isSkuEnabled && (
                <CourseSkuReferences course={course} />
            )}
            {course.bookletReferenceCodes?.length > 0 && (
                <BookletBox sx={{ p: 2 }}>
                    {isSkuEnabled && (
                        <Typography variant="h6">
                            {t('scope.edit.physicalBookletRef')}
                            {' '}
                            <Tooltip title={t('scope.edit.physicalBookletRefDescDep')}>
                                <Chip label={t('general.deprecated')} color="warning" />
                            </Tooltip>
                        </Typography>
                    )}
                    <Typography paragraph><i>{t('scope.course.bookletsForCourse', { count: course.bookletReferenceCodes.length })}</i></Typography>
                    <div>{course.bookletReferenceCodes.map(refCode => <Chip label={refCode} key={refCode} sx={{ mr: 1 }} />)}</div>
                </BookletBox>
            )}
        </PageContentBlock>
    );
}

CourseBookletReferencesPageBlock.propTypes = {
    course: PropTypes.shape({
        bookletReferenceCodes: PropTypes.array,
        id: PropTypes.number,
    }),
    isSkuEnabled: PropTypes.bool,
};

export default CourseBookletReferencesPageBlock;
