import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ScopeAthenaStudiesCourseGroupForm from '../scope/edit/ScopeAthenaStudiesCourseGroupForm'

const dialogContentStyle = {
	minHeight: 100
};

class CourseGroupEditModal extends Component {
	bindSave = null;

	render(){
		const { group, handleClose } = this.props;

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth>
				<DialogTitle>Edit {group.name}</DialogTitle>
				<DialogContent style={dialogContentStyle}>
					<ScopeAthenaStudiesCourseGroupForm
						scopeId={group.id}
						bindSave={this.handleBind}
						onSaved={handleClose}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					{this.bindSave && (
						<Button
							onClick={this.bindSave}
							color="primary"
							variant="contained"
						>
							Save group
						</Button>
					)}
				</DialogActions>
			</Dialog>
		)
	}

	handleBind = (func) => {
		this.bindSave = func;
		this.forceUpdate(); // So the buttons render
	}
}

export default CourseGroupEditModal
