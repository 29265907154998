import { Schemas, CALL_API } from './schemas'
import { GROUP_STATE_ADMINISTRATION_VALIDATED } from '../helpers/groupState'

export const FINANCE = 'FINANCE';

export const getFinanceScopeReport = (scopeId) => ({
	[CALL_API]: {
		type: FINANCE,
		endpoint: `finance/${scopeId}/report`,
		schema: Schemas.FINANCE_SCOPE_REPORT
	}
});

export const getFinanceScopeReportDetails = (scopeId) => ({
	[CALL_API]: {
		type: FINANCE,
		endpoint: `finance/${scopeId}/detailed-report/v2`,
	}
});

export const approveFinanceGroupReportDetails = groupId => ({
	[CALL_API]: {
		type: FINANCE,
		method: 'PATCH',
		endpoint: `athenastudies-course-group/${groupId}`,
		body: {
			state: GROUP_STATE_ADMINISTRATION_VALIDATED
		}
	}
})
