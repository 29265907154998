import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Tooltip, IconButton, Icon } from '@mui/material';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CourseDescriptionTemplateSections, CourseDescriptionTemplateVariables } from '../../../../constants/Templates';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import ModalItem from '../../../modal/ModalItem';
import ModalManager from '../../../modal/ModalManager';
import PageContentBlock from '../../../page/PageContentBlock';
import PageHeader from '../../../page/PageHeader';
import PageHeaderMenu from '../../../page/PageHeaderMenu';
import PageHeaderTitle from '../../../page/PageHeaderTitle';
import Alert from '../../../util/alert/Alert';
import EditTemplateModal from './EditTemplateModal';

function CompanyTemplatesCourseDescriptionPage({ workingScope }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleEdit = useCallback((template, index) => {
        navigate('/portal/company/templates/course', {
            state: {
                modal: 'templateEdit',
                templateIndex: index,
            },
        });
    }, [navigate]);

    if (!workingScope) return (
        <Alert>{t('manage.company.templates.selectCompanyFirst')}</Alert>
    );

    const templates = workingScope.properties?.courseDescriptionTemplates;

    return (
        <>
            <ModalManager>
                <ModalItem
                    state="templateEdit"
                    component={EditTemplateModal}
                    workingScope={workingScope}
                    templateVariables={CourseDescriptionTemplateVariables}
                    sections={CourseDescriptionTemplateSections}
                    propertiesKey="courseDescriptionTemplates"
                />
            </ModalManager>
            <PageHeader>
                <PageHeaderMenu
                    items={[{
                        name: t('manage.company.templates.newTemplate'),
                        to: '/portal/company/templates/course',
                        state: {
                            modal: 'templateEdit',
                            template: null,
                        },
                    }]}
                />
                <PageHeaderTitle
                    headline={t('manage.company.templates.course.editTemplates')}
                    subHeading={(
                        <Typography paragraph>
                            <Trans i18nKey="manage.company.templates.course.editTemplatesDesc" values={{ scopeName: workingScope.name }}>
                                {'Templates for '}
                                {workingScope.name}
                            </Trans>
                        </Typography>
                    )}
                />
                {workingScope._class !== 'Company' && (
                    <Alert type={Alert.TYPE_DANGER}>
                        {t('manage.company.templates.warnThisNotCompany')}
                    </Alert>
                )}
            </PageHeader>
            <PageContentBlock>
                {templates && Array.isArray(templates) && templates.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('manage.company.templates.field.label')}</TableCell>
                                <TableCell>{t('manage.company.templates.field.language')}</TableCell>
                                <TableCell>{t('manage.company.templates.field.section')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templates.map((template, i) => (
                                <TableRow key={i}>
                                    <TableCell>{template.label}</TableCell>
                                    <TableCell>{template.language}</TableCell>
                                    <TableCell>{template.section}</TableCell>
                                    <TableCell sx={{ py: 0 }}>
                                        <Tooltip title={t('general.edit')}>
                                            <IconButton
                                                edge="end"
                                                onClick={() => handleEdit(template, i)}
                                            >
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Alert>{t('manage.company.templates.noTemplatesYet')}</Alert>
                )}
            </PageContentBlock>
        </>
    );
}

export default withWorkingScope(CompanyTemplatesCourseDescriptionPage);
