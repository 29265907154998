import { styled } from '@mui/material/styles';
import classNames from 'classnames'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const PREFIX = 'LiveActionBlur';

const classes = {
    root: `${PREFIX}-root`,
    content: `${PREFIX}-content`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'none',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'rgba(255, 255, 255, 0)',
		transition: 'background 0.2s ease-in-out, backdrop-filter 0.4s ease-in-out',
		'&.visible': {
			display: 'flex',
		},
		'&.animation': {
			background: 'rgba(255, 255, 255, 0.3)',
			backdropFilter: 'blur(4px)',
		},
	},

    [`& .${classes.content}`]: {
		...theme.typography.body1,
		padding: theme.spacing(4),
	}
}));

const LiveActionBlur = ({ text }) => {

	const [showText, setShowText] = useState(false);
	const [animation, setAnimation] = useState(false);
	const timerRef = useRef();

	useEffect(() => {
		if(!text) return;

		setShowText(text);

		setTimeout(() => {
			setAnimation(true);

			clearTimeout(timerRef.current);
			timerRef.current = setTimeout(() => {
				setShowText(false);
				setAnimation(false);
			}, 2000);
		}, 100);
	}, [text, setShowText, setAnimation]);

    return (
        <Root className={classNames(classes.root, {
        	visible: Boolean(showText),
			animation: Boolean(animation),
		})}>
			<div className={classes.content}>
				{showText}
			</div>
		</Root>
    );
};

LiveActionBlur.propTypes = {
	text: PropTypes.node,
};

export default LiveActionBlur
