import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { chain } from 'ramda'
import Grid from '@mui/material/Grid'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { ShippingStatusCaptions } from '../../../constants/ShippingStatuses'
import { sortAlphabet, sortByTime, sortKey } from '../../../helpers/sorts'
import { niceDateYear } from '../../../helpers/datetime'
import { formatMoney, formatPercentage } from '../../../helpers/formatter'
import PageContentBlock from '../../page/PageContentBlock'
import FetchDataSwitch from '../../util/FetchDataSwitch'
import Alert from '../../util/alert/Alert'
import ResponsiveTable from '../../util/ResponsiveTable'
import { orderStatusStyle, parseStatus, PENDING, sortByStatus, StatusChip } from '../utils'
import ClientCourseTableHeader from './ClientCourseTableHeader'

const propTypes = {
	location: PropTypes.object.isRequired,
	client: PropTypes.object.isRequired,

	data: PropTypes.arrayOf(PropTypes.object),
	loading: PropTypes.bool,
	error: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object,
	]),
}

class ClientCourseTable extends Component {

	state = {
		sortDisplay: 'startDate',
		sort: 'startDate',
		sortDesc: true,

		data: undefined,
		sortedData: undefined,
	}

	handleData = () => chain(order => order.orderEntries.map(entry => ({
		order,
		key: entry.id,
		orderId: order.id,
		groupId: entry.product.id,
		courseId: entry.product.parent.id,
		groupName: entry.product.name,
		startDate: entry.product.courseStart,
		courseName: entry.product.parent.name,
		evaluation: entry.product.evaluation,
		totalAmount: entry.paidPrice,
		orderStatus: order.status,
		discountCode: entry.discountCode,
		originalUnitPrice: entry.originalUnitPrice,
		shippingStatus: entry.shippingStatus,
	})))(this.props.data)


	sortData = sort => {
		const { data } = this.state

		switch (sort) {
			case 'courseName':
			case 'groupName':
				return data.sort(sortAlphabet(sort))

			case 'startDate':
				return data.sort(sortByTime(sort))

			case 'orderStatus':
				return data.sort(sortByStatus('orderStatus'))

			default:
				return data.sort(sortKey(sort))
		}
	}

	handleSort = id => () => {
		if (this.state.sortDisplay === id)
			this.setState({ sortDesc: !this.state.sortDesc })
		else {
			const sort = id === 'orderDateTime' ? 'id' : id
			this.setState({
				sortDisplay: id,
				sort,
				sortDesc: true,
				sortedData: this.sortData(sort),
			})
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.data && this.props.data) this.setState({ data: this.handleData() })
		if (!prevState.data && this.state.data) this.setState({ sortedData: this.sortData('id') })
	}

	render() {
		const {
			state: { sortDisplay, sortDesc, sortedData },
			props: { location: { pathname }, client, data, loading, error },
		} = this

		return (
			<PageContentBlock
				title={'Courses'}
				description={`These are the courses ${client.name} has ordered.`}
				icon={'shopping_cart'}
			>
				<FetchDataSwitch
					{...{ data, loading, error }}
					ifEmptyData={(
						<Alert type={Alert.TYPE_INFO}>
							{client.name} hasn't ordered any courses
						</Alert>
					)}
				>
					{sortedData && (
						<ResponsiveTable>
							<TableHead>
								<ClientCourseTableHeader
									{...{ sortDisplay, sortDesc }}
									handleSort={this.handleSort}
								/>
							</TableHead>
							<TableBody>
								{(sortDesc ? [...sortedData].reverse() : sortedData)
								.map(({ key, order, orderId, courseId, courseName, groupId, groupName, startDate, evaluation, totalAmount, orderStatus, discountCode, originalUnitPrice, shippingStatus }) => (
									<TableRow key={key}>
										<TableCell>
											<Link to={pathname} state={{ modal: 'viewOrder', orderId }}>
												{orderId}
											</Link>
										</TableCell>
										<TableCell>
											<Link to={`/portal/planner/course/${courseId}`}>
												{courseName}
											</Link>
										</TableCell>
										<TableCell>
											<Link to={`/portal/planner/course/${courseId}/group/${groupId}`}>
												{groupName}
											</Link>
										</TableCell>
										<TableCell>{startDate ? niceDateYear(startDate) : ''}</TableCell>
										<TableCell>{evaluation}</TableCell>
										{discountCode ? (
												<TableCell align={'right'}>
													<Grid container direction={'column'} justifyContent={'center'}>
														<Grid item>
															<span style={{ fontSize: '0.9em' }}>{discountCode.code} &mdash; </span>
															<span style={{ textDecoration: 'line-through' }}>{formatMoney(originalUnitPrice)}</span>
														</Grid>
														<Grid item>
															<span style={{ fontSize: '0.9em' }}>-{formatPercentage(discountCode.discountPercentage)} &mdash; </span>
															<span><strong>{formatMoney(totalAmount)}</strong></span>
														</Grid>
													</Grid>
												</TableCell>
											) : (
												<TableCell align={'right'}>
													<strong>{formatMoney(totalAmount)}</strong>
												</TableCell>
											)}
										<TableCell>
											<StatusChip
												style={{ color: 'white' }}
												component={Link}
												to={pathname}
												state={{ modal: 'viewOrder', order }}
												label={parseStatus(orderStatus)}
												className={orderStatusStyle[orderStatus]}
												variant={orderStatusStyle[orderStatus] === PENDING ? 'outlined' : 'default'}
											/>
										</TableCell>
										<TableCell>
											{shippingStatus && ShippingStatusCaptions[shippingStatus]}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</ResponsiveTable>
					)}
				</FetchDataSwitch>
			</PageContentBlock>
		)
	}
}

ClientCourseTable.propTypes = propTypes

export default ClientCourseTable
