import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton, Tooltip, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) => ({
	color: `${theme.palette.grey[600]} !important`,
}));

function BatchCardIcon({ type = 'generic', to, state, tooltip = '', disabled, onClick, children }) {
	const iconButtonProps = to ? { component: StyledLink, to, state } : { onClick };
	const showTooltip = tooltip.length > 0 && !disabled;

	const Root = showTooltip ? {
		Component: Tooltip,
		Props: { title: tooltip, placement: 'top' },
	} : {
		Component: Fragment,
	};

	return (
		<Root.Component {...Root.Props}>
			<IconButton
				className={`batchIcon-${type}`}
				sx={{ width: 36, height: 36 }}
				disabled={disabled}
				{...iconButtonProps}
			>
				<Icon>{children}</Icon>
			</IconButton>
		</Root.Component>
	);
}

BatchCardIcon.propTypes = {
	type: PropTypes.string,
	onClick: PropTypes.func,
	to: PropTypes.string,
	state: PropTypes.object,
	tooltip: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.node,
};

export default BatchCardIcon;
