import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { prop, reverse, sortBy } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getNotesForContext } from '../../actions/notesActions';
import withFetchData from '../../modules/fetchData/withFetchData';
import ModalManager from '../modal/ModalManager';
import ModalItem from '../modal/ModalItem';
import Alert from '../util/alert/Alert';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';
import EditNotesModal from './EditNotesModal';
import Note from './Note';

function Notes({ data: notes, loading, error, context, contextId }) {
	const { t } = useTranslation();

	if (loading) {
		return (
			<Loader />
		);
	}

	const sortByLastUpdated = sortBy(prop('lastUpdated'));
	const sortedNotes = notes ? reverse(sortByLastUpdated(notes)) : [];

	return (
        <>
            <ModalManager>
				<ModalItem state="editNotes" component={EditNotesModal} context={context} contextId={contextId} />
			</ModalManager>
            <Box sx={{ p: 2 }}>
				{ sortedNotes?.map(note => (
					<Note note={note} key={note.id} />
				))}
				{!error && notes?.length === 0 && (
					<Alert type={Alert.TYPE_INFO}>{t('notes.startNewNote')}</Alert>
				)}
				{error && (
					<ApiError error={error} />
				)}
			</Box>
        </>
    );
}

Notes.propTypes = {
	context: PropTypes.string.isRequired,
	contextId: PropTypes.number.isRequired,
};

export default withFetchData(props => getNotesForContext(props.context, props.contextId), {
	customId: () => 'NotesEditor',
})(Notes);
