import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getClaimFormsByEmployee } from '../../../actions/declarationsActions';
import withFetchData from '../../../modules/fetchData/withFetchData';
import ModalItem from '../../modal/ModalItem';
import ModalManager from '../../modal/ModalManager';
import PageContentBlock from '../../page/PageContentBlock';
import DeclarationContextProvider, { DeclarationContexts } from './DeclarationContext';
import DeclarationsTable from './DeclarationsTable';
import DeclarationModal from './DeclarationModal';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';

function DeclarationsPageBlock({ location, id, data, loading, error, pagination, load, onPaginationChangePage, onPaginationChangePageSize }) {
	const { t } = useTranslation();

	useEffect(() => {
		if (id) {
			load(getClaimFormsByEmployee(id));
		}
	}, [id, load, pagination.page, pagination.pageSize]);

	return (
		<DeclarationContextProvider value={DeclarationContexts.EMPLOYEE}>
			<ModalManager>
				<ModalItem state="editDeclarations" component={DeclarationModal} location={location} />
			</ModalManager>
			<PageContentBlock
				title={t('declarations.page-block.title')}
				icon="receipt"
			>
				{loading && (
					<Loader sheet />
				)}
				{error && (
					<ApiError error={error} />
				)}
				{Array.isArray(data) && (
					<DeclarationsTable
						declarations={data}
						location={location}
						pagination={pagination}
						onPaginationChangePage={onPaginationChangePage}
						onPaginationChangePageSize={onPaginationChangePageSize}
					/>
				)}
			</PageContentBlock>
		</DeclarationContextProvider>
	);
}

DeclarationsPageBlock.propTypes = {
	location: PropTypes.object,
	id: PropTypes.number,
};

export default withFetchData(null, {
	customId: () => 'EmployeeDeclarations',
	pagination: true,
	paginationPageSize: 10,
	keepData: true,
})(DeclarationsPageBlock);
