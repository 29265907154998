import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { niceDateTime } from '../../helpers/datetime';
import ModalManager from '../modal/ModalManager';
import Loader from '../util/loader/Loader';

const LexicalEditor = lazy(() => import('../util/editor/LexicalEditor'));

const PREFIX = 'Note';

const classes = {
    modifiedBy: `${PREFIX}-modifiedBy`,
    collapsed: `${PREFIX}-collapsed`,
    overlay: `${PREFIX}-overlay`,
    text: `${PREFIX}-text`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.modifiedBy}`]: {
		textAlign: 'right',
		color: theme.palette.grey[600],
		marginBottom: theme.spacing(0.5),
		fontStyle: 'italic',
		fontSize: '0.85rem',
	},

    [`& .${classes.collapsed}`]: {
		position: 'relative',
		'&:after': {
			content: '""',
			display: 'block',
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
			height: '12rem',
			width: '100%',
			position: 'absolute',
			bottom: '100%',
			left: 0,
		},
	},

    [`& .${classes.overlay}`]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		position: 'absolute',
		zIndex: 10,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		opacity: 0,
		background: 'transparent',
		transition: 'all 0.3s ease-in',
		'&:hover': {
			opacity: 1,
			background: 'rgba(255,255,255,0.75)',
			transition: 'all 0.2s ease-in',
		},
	},

    [`& .${classes.text}`]: {
		display: 'flex',
		alignItems: 'center',
	}
}));

const DEFAULT_COLLAPSED_HEIGHT = 250;

function Note({ note }) {
	const { t, i18n } = useTranslation();
	const location = useLocation();

	const markdownRef = useRef();
	const { content, lastModifiedBy, lastUpdated } = note || {};

	const [enableCollapse, setEnableCollapse] = useState(false);

	useLayoutEffect(() => {
		if (content && markdownRef.current) {
			setEnableCollapse(markdownRef.current.getBoundingClientRect().height > DEFAULT_COLLAPSED_HEIGHT);
		}
	}, [content]);

	const [opened, setOpened] = useState(enableCollapse);

	useEffect(() => {
		if (typeof enableCollapse === 'boolean') {
			setOpened(!enableCollapse);
		}
	}, [enableCollapse]);

	return (
        <Root>
			{ lastModifiedBy && (
				<div className={classes.modifiedBy}>
					{t('notes.lastUpdated')}
					{' '}
					{niceDateTime(lastUpdated, i18n.language, t)}
					{' '}
					{t('notes.lastModifiedBy')}
					{' '}
					{lastModifiedBy.fullName}
				</div>
			)}
			<Collapse in={opened} timeout="auto" collapsedSize={enableCollapse ? DEFAULT_COLLAPSED_HEIGHT : 'auto'} style={{ position: 'relative' }}>
				<div ref={markdownRef}>
					<Suspense fallback={<Loader />}>
						<LexicalEditor
							content={content}
							isReadyOnly
						/>
					</Suspense>
				</div>
				<Link
					to={location.pathname}
					state={{
						modal: 'editNotes',
						note,
					}}
					className={classes.overlay}
					aria-label={t('notes.editNotes')}
				>
					<span className={classes.text}>
						{t('notes.editNotes')}
						{' '}
						<Icon style={{ marginLeft: '0.5em' }}>edit</Icon>
					</span>
				</Link>
			</Collapse>
			<div style={{ textAlign: 'center' }} className={!opened ? classes.collapsed : null}>
				{enableCollapse && (
					<IconButton
						onClick={() => setOpened(!opened)}
						aria-label={opened ? t('notes.collapse') : t('notes.expand')}
					>
						<Icon>{opened ? 'expand_less' : 'expand_more'}</Icon>
					</IconButton>
				)}
			</div>
			<Divider style={{ margin: '1rem 0' }} />
		</Root>
    );
}

Note.propTypes = {
	note: PropTypes.object.isRequired,
};

export default Note;
