import { Checkbox, Input, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import propertyTypes from '../../constants/PropertyTypes';
import { choiceLabel, choiceLabelByKey } from '../../helpers/properties';

const PREFIX = 'PropertySelector';

const classes = {
    property: `${PREFIX}-property`,
    propertyKey: `${PREFIX}-propertyKey`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    [`&.${classes.property}`]: {
		display: 'inline-block',
		padding: theme.spacing(1),
		minWidth: '200px',
	},

    [`& .${classes.propertyKey}`]: {
		marginRight: theme.spacing(1),
	}
}));

const autocompleteEmptyOptions = [];
const autocompleteRenderTags = (value, getTagProps) => value.map((option, index) => (
	<Chip label={option} {...getTagProps({ index })} />
));
const autocompleteRenderInput = (params) => (
	<TextField {...params} />
);
const autocompleteGetOptionLabel = (option) => (
	typeof option === 'string' ? option : option.title || option.inputValue
);

const PropertySelector = ({ property, newProps, handlePropChange, disabled }) => {

	const { t, i18n } = useTranslation();
	const propertyValue = newProps[property.key];

	const handleTextBlur = useCallback((event) => {
		if (event.target.value) {
			handlePropChange(property.key)(event, event.target.value.trim());
		}
	}, [handlePropChange, property]);

	const handleChangeMultiText = useCallback((event, newValue) => {
		if (typeof newValue === 'string') {
			return handlePropChange(property.key)(event, [newValue]);
		}

		if (newValue && newValue.inputValue) {
			// New value created
			return handlePropChange(property.key)(event, [...propertyValue, newValue.inputValue]);
		}

		// Clean potential objects (from when "Add ..." in the dropdown is clicked)
		return handlePropChange(property.key)(event, newValue?.map?.(option => option.inputValue || option));
	}, [handlePropChange, propertyValue, property]);

	const multiSelectValue = Array.isArray(newProps[property.key]) ? newProps[property.key] : [];

	let Component = <span>Property not supported</span>;
	switch (property.type) {
		case propertyTypes.TEXT:
			Component = (
				<TextField
					disabled={disabled}
					onChange={handlePropChange(property.key)}
					onBlur={handleTextBlur}
					value={newProps[property.key] || ''}
				/>
			);
			break;
		case propertyTypes.MULTI_TEXT:
			Component = (
				<Autocomplete
					multiple
					freeSolo
					selectOnFocus
					clearOnBlur
					handleHomeEndKeys
					options={autocompleteEmptyOptions}
					value={propertyValue || []}
					onChange={handleChangeMultiText}
					disabled={disabled}
					renderTags={autocompleteRenderTags}
					renderInput={autocompleteRenderInput}
					getOptionLabel={autocompleteGetOptionLabel}
					filterOptions={(options, params) => {
						// We have no options for now, but need to show the "add" option
						if (params.inputValue !== '') return [{
							inputValue: params.inputValue,
							title: t('properties.addOption', {
								option: params.inputValue,
							}),
						}];

						return options;
					}}
				/>
			);
			break;
		case propertyTypes.NUMBER:
			Component = (
				<TextField
					type="number"
					disabled={disabled}
					onChange={handlePropChange(property.key)}
					value={newProps[property.key] || ''}
				/>
			);
			break;
		case propertyTypes.SELECT:
			Component = (
				<Select
					onChange={handlePropChange(property.key)}
					color="primary"
					value={newProps[property.key] || 'empty'}
					variant="outlined"
					input={<Input id={`html-for-${property.key}`} />}
					disabled={disabled}
				>
					<MenuItem value="empty" disabled><i>Empty</i></MenuItem>
					{property.options?.choices?.map(choice => (
						<MenuItem value={choice?.key || choice} key={choice?.key || choice}>
							{choiceLabel(choice, i18n.language)}
						</MenuItem>
					))}
				</Select>
			);
			break;
		case propertyTypes.MULTI_SELECT:
			Component = (
				<Select
					onChange={handlePropChange(property.key)}
					color="primary"
					value={multiSelectValue}
					variant="outlined"
					renderValue={(selected) => selected.map(key => choiceLabelByKey(key, property?.options, i18n.language)).join(', ')}
					input={<Input id={`html-for-${property.key}`} />}
					disabled={disabled}
					multiple
				>
					{property.options?.choices?.map(choice => (
						<MenuItem value={choice?.key || choice} key={choice?.key || choice}>
							<ListItemIcon>
								<Checkbox checked={multiSelectValue.indexOf(choice?.key || choice) > -1} color="secondary" />
							</ListItemIcon>
							<ListItemText primary={choiceLabel(choice, i18n.language)} />
						</MenuItem>
					))}
				</Select>
			);
			break;
		case propertyTypes.BOOLEAN:
			Component = (
				<Switch
					checked={!!newProps[property.key]}
					onChange={handlePropChange(property.key)}
					color="primary"
					disabled={disabled}
				/>
			);
			break;
		default:
			break;
	}

	return (
        <StyledFormControl className={classes.property}>
			{Component}
		</StyledFormControl>
    );
};

PropertySelector.propTypes = {
	property: PropTypes.object,
	newProps: PropTypes.object,
	handlePropChange: PropTypes.func,
	disabled: PropTypes.bool.isRequired,
};

export default PropertySelector;
