import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { useTranslation } from 'react-i18next';
import ScopeTimeSlotForm from '../scope/edit/ScopeTimeSlotForm'

const CourseGroupTimeSlotsAddModal = ({ handleClose, group, timeSlots }) => {
	const { t } = useTranslation();
	let defaultClassRoom = null;
	let defaultTeacher = null;

	if(timeSlots && timeSlots.length > 0){
		defaultClassRoom = timeSlots[0].classRoom;
		defaultTeacher = timeSlots[0].teacher;
	}

	return (
		<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth>
			<DialogTitle>
				{t('scope.course.timeslots.addTimeSlot')}
			</DialogTitle>
			<DialogContent>
				<ScopeTimeSlotForm
					scope={group}
					defaultClassRoom={defaultClassRoom}
					defaultTeacher={defaultTeacher}
					onSave={handleClose}
					onCancel={handleClose}
				/>
			</DialogContent>
		</Dialog>
	);
}

CourseGroupTimeSlotsAddModal.propTypes = {
	group: PropTypes.object,
	timeSlots: PropTypes.array,
}

export default CourseGroupTimeSlotsAddModal
