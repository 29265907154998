import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { formatLiveStatsBigMoney } from '../liveStatsBlock/LiveStatsBlock';
import LiveSplashBack from './LiveSplashBack';

import glitter from '../media/glitter-gold-slow.gif';
import milli from '../media/milli.mp3';

const PREFIX = 'LiveSplashMil';

const classes = {
    root: `${PREFIX}-root`,
    content: `${PREFIX}-content`,
    title: `${PREFIX}-title`,
    million: `${PREFIX}-million`,
    party: `${PREFIX}-party`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000,
		display: 'flex',
	},

    [`& .${classes.content}`]: {
		position: 'relative',
		zIndex: 20,
		textAlign: 'center',
	},

    [`& .${classes.title}`]: {
		fontFamily: 'Museo, arial, sans-serif',
		fontSize: '7rem',
		color: theme.palette.grey[50],
		lineHeight: '7rem',
	},

    [`& .${classes.million}`]: {
		fontFamily: 'Museo, arial, sans-serif',
		fontSize: '9rem',
		fontWeight: 900,
		background: '-webkit-linear-gradient(#F7E61B, #977C13)',
		'-webkit-background-clip': 'text',
		textFillColor: 'transparent',
	},

    [`& .${classes.party}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: `url(${glitter}) repeat`,
		zIndex: 10,
		backgroundSize: 'auto 100%',
		// maskImage: `url(${require('../media/glitter-gold-slow.gif')})`,
		// maskMode: 'alpha',
		// backgroundColor: '#bfd8d5',
		// backgroundBlendMode: 'color-dodge',
	}
}));

function LiveSplashMil({  value }) {
	useEffect(() => {
		const milliAudio = new Audio(milli);
		milliAudio.addEventListener('canplay', () => {
			milliAudio.play();
		});
	}, []);

	return (
        <Root className={classes.root}>
			<LiveSplashBack>
				<div className={classes.party} />
				<div className={classes.content}>
					<div className={classes.title}>
						JAZEKERS
					</div>
					<div className={classes.million}>
						{formatLiveStatsBigMoney(value || { amount: 1000000 })}
					</div>
				</div>
			</LiveSplashBack>
		</Root>
    );
}

LiveSplashMil.propTypes = {
	value: PropTypes.object,
};

export default (LiveSplashMil);
