import { Trans } from 'react-i18next';
import React from 'react'
import { identity, memoizeWith, sort } from 'ramda'
import { dateCalendarFormat, daysDifferenceWithNow, niceDateYear } from './datetime'
import store from '../store'
import { workingScopeSelectParents } from '../modules/scope/scopeActions'

export const scopeParentsToBreadcrumbs = (
	parentId,
	linkToAndSetSelector = '/portal/planner',
	showClasses = ['Institute', 'Course'],
	field = 'abbreviation'
) => {
	if(!parentId){
		return [];
	}

	const parent = store.getState().entities.scopes[parentId];
	if(!parent){
		return [];
	}

	if(!showClasses || showClasses.indexOf(parent._class) > -1){
		return [...scopeParentsToBreadcrumbs(parent.parent, linkToAndSetSelector, showClasses, field), {
			label: parent[field],
			...linkToAndSetSelector ? {
				to: linkToAndSetSelector,
				onClick: () => store.dispatch(workingScopeSelectParents(parent.id)),
			} : {}
		}];
	}

	return scopeParentsToBreadcrumbs(parent.parent, linkToAndSetSelector, showClasses, field);
}

export const scopeParentsFromStore = (parentId, field = 'abbreviation', stopAtClass = false) => {
	if(!parentId) return [];

	const parent = store.getState().entities.scopes[parentId];
	if(!parent) return [];
	if(stopAtClass && parent._class === stopAtClass) return [];

	return [
		...scopeParentsFromStore(parent.parent, field, stopAtClass),
		field === 'abbreviation' && !scopeHasAbbreviation(parent) ? parent['name'] : parent[field],
	];
}

export const scopeParentsFromStoreFull = (parentId, stopAtClass = false) => {
	if(!parentId) return [];

	const parent = store.getState().entities.scopes[parentId];
	if(!parent) return [];
	if(stopAtClass && parent._class === stopAtClass) return [];

	return [
		...scopeParentsFromStoreFull(parent.parent, stopAtClass),
		parent,
	];
}

export const getScopeParentsPropertyCombined = memoizeWith(
    (scopeId, property, stopAtClass = false) => `${scopeId}/${property}/${stopAtClass}`,
    (scopeId, property, stopAtClass = false) => {
        const scopes = scopeParentsFromStoreFull(scopeId, stopAtClass);
        return scopes.map(scope => scope.properties?.[property]).filter(Boolean).flat();
    },
);

export const scopeHasAbbreviation = (scope) => {
	return scope._class !== 'AthenaStudiesCourse' && scope._class !== 'AthenaStudiesCourseGroup';
}

export const scopeParentsNameList = (scope, stopAtClass = false, depth = 0) => {
	if(!scope || stopAtClass === scope._class) return [];

	return scopeParentsNameList(scope.parent, stopAtClass, depth + 1).concat(
		depth === 0 || !scopeHasAbbreviation(scope) ? scope.name : scope.abbreviation
	);
}

export const scopeParentsNameString = (scope, stopAtClass = false) => {
	return scopeParentsNameList(scope, stopAtClass).join(' > ');
}

function getScopeOrProperty(scope, valueGetter){
	if(!valueGetter) return scope;

	return typeof valueGetter === 'string'
		? scope[valueGetter]
		: valueGetter(scope);
}

export const scopeParentsInclusiveList = (scope, includeClasses = ['Study', 'Institute'], valueGetter = false) => {
	if(!scope) return [];

	return scopeParentsInclusiveList(scope.parent, includeClasses, valueGetter).concat(
		includeClasses.indexOf(scope._class) === -1 ? [] : getScopeOrProperty(scope, valueGetter)
	);
}

export const scopeParentsList = (scope, valueGetter = false) => {
	if (!scope) return [];

	return scopeParentsList(scope.parent, valueGetter).concat(
		getScopeOrProperty(scope, valueGetter),
	);
};

export const workingScopesNameList = (workingScopes) => {
	if(!workingScopes) return [];

	return workingScopes.map((scope, i) => (
		i < workingScopes.length - 1 && scopeHasAbbreviation(scope) ? scope.abbreviation : scope.name
	));
}

export const workingScopesNameString = (workingScopes) => {
	return workingScopesNameList(workingScopes).join(' > ');
}

export const scopeStartsIn = (scopeCourseGroup) => {
	if(!scopeCourseGroup.courseStart) return '';

	if(daysDifferenceWithNow(scopeCourseGroup.courseStart) < -4) return (
		<em>{niceDateYear(scopeCourseGroup.courseStart)}</em>
	);

	return dateCalendarFormat(scopeCourseGroup.courseStart);
}

export const formatCourseGroupName = (group) => {
	if(!group.parent) return group.name;

	return `${group.parent.name} - ${group.name}`
}

export const formatCourseType = (courseType) => {
	switch(courseType){
		case 'Basiscursus':
			return 'Regular course';
		case 'Stoomcursus':
			return 'Crash course';
	}

	return 'unknown';
}

export function formatArtificialStockStatus(group) {
	if(!group) return null;

	switch(group.artificialStockStatus){
		case null:
			return <Trans i18nKey="scope.group.manipulation.noManipulationStatus" />
		case 'SUFFICIENT_PLACES':
			return <Trans i18nKey="scope.group.manipulation.enoughPlaces" />
		case 'ALMOST_FULL':
			return <Trans i18nKey="scope.group.manipulation.almostFull" />
		case 'FULL':
			return <Trans i18nKey="scope.group.manipulation.full" />
	}

	return '';
}

export const composeLocationName = (room, location = undefined) => {
	if(!room) return '';
	if(room.location) location = room.location;
	if(!location) return room.name;

	return `${location.name} ${room.name} (${location.street} ${location.houseNumber})`;
}

export const sortScopesOnName = (scopes) => {
	if(!scopes) return scopes;

	return sort((a, b) => a.name.localeCompare(b.name, undefined, {
		numeric: true,
		sensitivity: 'base',
	}), scopes);
};

export const sortScopesOnId = (scopes) => {
	if(!scopes) return scopes;

	return sort((a, b) => a.id - b.id, scopes);
}
