import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withFormData from '../../modules/formData/withFormData'
import { niceDateDay, niceTime } from '../../helpers/datetime'
import { composeLocationName } from '../../helpers/scope'
import { deleteScopeTimeSlot } from '../../actions/scopeEditActions'
import PropertiesTable from '../util/PropertiesTable'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'

class CourseGroupTimeSlotsDeleteModal extends Component {
	render(){
		const { handleClose, timeSlot, saving, error } = this.props;
		const items = [];

		if(timeSlot){
			items.push(
				{label: 'Date', content: niceDateDay(timeSlot.startDateTime)},
				{label: 'Time', content: `${niceTime(timeSlot.startDateTime)} - ${niceTime(timeSlot.endDateTime)}`},
			);

			if(timeSlot.teacher) items.push({
				label: 'Teacher',
				content: timeSlot.teacher.fullName
			});

			if(timeSlot.classRoom) items.push({
				label: 'Location',
				content: composeLocationName(timeSlot.classRoom)
			})
		}

        return (
            <Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    Delete time slot
                </DialogTitle>
                <DialogContent>
					{error && (
						<Alert type={Alert.TYPE_WARNING}>{error.error}</Alert>
					)}
					{timeSlot ? (
						saving ? (
							<Loader>Working on removing the time slot.</Loader>
						):(
							<PropertiesTable items={items}/>
						)
					):(
						<Alert type={Alert.TYPE_WARNING}>
							Apparently there is no time slot selected. Try again from the time slot list.
						</Alert>
					)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
					{timeSlot && (
						<Button
							onClick={this.handleDelete}
							variant="contained"
							color="secondary"
						>Delete</Button>
					)}
                </DialogActions>
            </Dialog>
        );
    }

    componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success){
			this.props.handleClose();
		}
	}

    handleDelete = () => {
		const { watchSubmit, group, timeSlot } = this.props;

		watchSubmit(deleteScopeTimeSlot(timeSlot.id, group.id));
	}
}

CourseGroupTimeSlotsDeleteModal.propTypes = {
	group: PropTypes.object.isRequired,
	timeSlot: PropTypes.object,
}

export default withFormData()(CourseGroupTimeSlotsDeleteModal)
