import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const BirthdayDateFormat = (dateTime) => moment(dateTime).format('YYYY-MM-DD');

function formatFullName(application) {
	const middleName = (application.middleName ? `${application.middleName} ` : '');
	return `${application.name} (${application.initials}) ${middleName}${application.surname}`;
}

function formatId(application, t) {
	const vatText = t('application.signForm.summary.vatId');

	if (!application.vatId) return application.identificationNumber;
	if (!application.identificationNumber) return `${vatText} ${application.vatId}`;

	return `${application.identificationNumber} / ${vatText} ${application.vatId}`;
}

const ApplicationFormContractSummary = ({ application }) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6} md={4}>
				<Typography variant="caption">{t('application.signForm.summary.name')}</Typography>
				<Typography variant="subtitle1">{formatFullName(application)}</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<Typography variant="caption">{t('application.signForm.summary.email')}</Typography>
				<Typography variant="subtitle1">{application.email}</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<Typography variant="caption">{t('application.signForm.summary.mobilePhone')}</Typography>
				<Typography variant="subtitle1">{application.mobilePhone}</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<Typography variant="caption">{t('application.signForm.summary.birthday')}</Typography>
				<Typography variant="subtitle1">{BirthdayDateFormat(application.birthday)}</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<Typography variant="caption">{t('application.signForm.summary.address')}</Typography>
				<Typography variant="subtitle1">{`${application.street} ${application.houseNumber}`}</Typography>
				<Typography variant="subtitle1">{`${application.zipcode}, ${application.city}, ${application.country}`}</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<Typography variant="caption">{t('application.signForm.summary.identificationNumber')}</Typography>
				<Typography variant="subtitle1">{formatId(application, t)}</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<Typography variant="caption">{t('application.signForm.summary.IBAN')}</Typography>
				<Typography variant="subtitle1">{application.IBAN}</Typography>
				<Typography variant="subtitle1">{`(BIC: ${application.BIC})`}</Typography>
				<Typography variant="subtitle1">{application.bankPayee}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="caption">{t('application.signForm.summary.contractForFunctionOf')}</Typography>
				<Typography variant="subtitle1">{application.applyingAs.name}</Typography>
			</Grid>
		</Grid>
	);
};

ApplicationFormContractSummary.propTypes = {
	application: PropTypes.object.isRequired,
};

export default ApplicationFormContractSummary;
