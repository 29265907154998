import React, { Component, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment';
import Slide from '@mui/material/Slide';
import Icon from '@mui/material/Icon';
import withRouter from '../../hoc/withRouter';
import NotificationHandler from '../../modules/notification/NotificationHandler';
import { notificationShow } from '../../modules/notification/notificationActions';
import withAuthProvider from '../../modules/auth/withAuthProvider';
import withRootProvider from '../../modules/scope/withRootProvider';
import { disabledPortalBackground } from '../../PortalPage';
import LiveSheetMain from './LiveSheetMain';
import withLiveTheme from './withLiveTheme';
import SheetBackground from './SheetBackground';
import { liveCoreInterval, liveDebugMode, liveVersion, liveRootScopeId } from './liveUtils';
import LiveSheetEndOfDay from './LiveSheetEndOfDay';

const PREFIX = 'LivePage';

const classes = {
    root: `${PREFIX}-root`,
    refresh: `${PREFIX}-refresh`,
    refreshIcon: `${PREFIX}-refreshIcon`,
    version: `${PREFIX}-version`,
};

const Root = styled('div')(({ theme }) => ({
		[`&.${classes.root}`]: {
			'& > div': {
				backgroundColor: theme.palette.background.default,
				display: 'flex',
				position: 'fixed',
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
			},
		},
}));

const StyledRefreshDiv = styled('div')(({ theme }) => ({
	[`&.${classes.refresh}`]: {
		position: 'absolute',
		right: 0,
		bottom: 0,
	},

	[`& .${classes.refreshIcon}`]: {
		display: 'block',
		color: theme.palette.grey[400],
	},
}));

const StyledVersionDiv = styled('div')(({ theme }) => ({
    [`&.${classes.version}`]: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		paddingLeft: 3,
		fontSize: '0.8em',
		color: theme.palette.grey[400],
	}
}));

const intervalSheet = liveCoreInterval;
const intervalBackground = liveCoreInterval / 10;

const partyTimeSettings = {
	hour: 18,
	minute: 0,
	duration: 30,
};

class LivePage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: true,
			partyTime: false,
			partyTimeShow: 0,
		};
	}

	render() {
		const { params } = this.props;
		const { show, partyTime, partyTimeShow } = this.state;

		const rootScopeId = parseInt((params && params.rootScopeId) || liveRootScopeId);

		return(
			<Fragment>
				<Root className={classes.root}>
					<SheetBackground />
					<Slide in={show} timeout={500}>
						<div>
							<LiveSheetMain rootScopeId={rootScopeId} />
						</div>
					</Slide>
					<Slide
						in={Boolean(partyTime && partyTimeShow)}
						timeout={500}
						mountOnEnter={!partyTime}
						unmountOnExit={!partyTime}
					>
						<div>
							<LiveSheetEndOfDay partyTimeSettings={partyTimeSettings} rootScopeId={rootScopeId} />
						</div>
					</Slide>
				</Root>
				<NotificationHandler />
				<StyledRefreshDiv className={classes.refresh}>
					<Icon
						onClick={() => document.location.reload()}
						fontSize="small"
						className={classes.refreshIcon}
					>
						refresh
					</Icon>
				</StyledRefreshDiv>
				<StyledVersionDiv className={classes.version}>
					v{liveVersion}
				</StyledVersionDiv>
			</Fragment>
		);
	}

	componentDidMount(){
		disabledPortalBackground(true);
		document.body.style.overflowY = 'hidden';

		if(this.props.location.search.indexOf('enableSheet') > -1){
			this.setState({
				show: true,
			});
			this.timeout = setTimeout(this.onHide, intervalSheet * 1000);
		}

		this.endOfDayInterval = setInterval(this.onIsEndOfDay, 60 * 1000);
		this.onIsEndOfDay();

		if(liveDebugMode){
			this.interval = setInterval(this.onTestNotification, 30000);
		}
	}

	componentWillUnmount(){
		disabledPortalBackground(false);
		document.body.style.overflowY = 'scroll';

		clearTimeout(this.timeout);
		clearInterval(this.interval);
		clearInterval(this.endOfDayInterval);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.location.search.indexOf('noPartyTime') === -1 && this.props.location.search.indexOf('noPartyTime') > -1){
			this.onIsEndOfDay();
		}
	}

	onHide = () => {
		this.setState({show: false});
		this.timeout = setTimeout(this.onShow, intervalBackground * 1000);
	}

	onShow = () => {
		this.setState({show: true});
		this.timeout = setTimeout(this.onHide, intervalSheet * 1000);
	}

	onTestNotification = () => {
		const { dispatch } = this.props;

		dispatch(notificationShow('Tom Reckman just bought SexySteamCourse B from UVA > SexyStudy', 'success'))
	}

	isPartyTime = () => {
		const partyTime = moment().hour(partyTimeSettings.hour).minute(partyTimeSettings.minute);
		const diff = moment().diff(partyTime, 'minute');

		const ignoreParty = this.props.location.search.indexOf('noPartyTime') > -1;

		return !ignoreParty && diff >= 0 && diff <= partyTimeSettings.duration
	}

	onIsEndOfDay = () => {
		const { partyTime, partyTimeShow } = this.state;

		if(partyTime){
			// Show party 2 minutes, normal 1 minute
			this.setState({
				partyTimeShow: partyTimeShow >= 2 ? 0 : partyTimeShow + 1,
			});
		}

		// It is party time
		if(this.isPartyTime()){
			if(!partyTime){
				this.setState({
					partyTime: true,
					partyTimeShow: 1,
				});
			}
		}

		// Back to normal
		else{
			if(partyTime){
				this.setState({
					partyTime: false,
					partyTimeShow: 0,
				});
			}
		}
	}
}

export default compose(
	withLiveTheme,
	withRouter,
	withAuthProvider,
	withRootProvider,
	connect(),
)(LivePage)
