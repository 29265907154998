import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { niceDateMonthYear } from '../../../helpers/datetime';
import { formatMoneyDirect } from '../../../helpers/formatter'
import Loader from '../../util/loader/Loader'
import { FINANCIAL_CHART } from './financialDashboardUtils'

const chartOptions = (reportValues, currency) => ({
	// spanGaps: true,
	scales: {
		x: {
			stacked: true,
			offset: true,
			type: 'time',
			time: {
				unit: 'month'
			}
		},
		y: {
			stacked: true,
			ticks: {
				stepSize: 10,
				callback: function(tick) {
					return tick && (tick).toLocaleString() + "%";
				}
			}
		}
	},
	plugins: {
		tooltip: {
			callbacks: {
				title: (data) => niceDateMonthYear(data[0].parsed.x),
				label: ({
					parsed: { y },
					datasetIndex,
					dataset: { label },
				}) => {
					const index = datasetIndex + 1;
					const totalCosts = reportValues[index] && reportValues[index].totalCosts && reportValues[index].totalCosts.amount;
					const actualValue = y && Math.round((y * totalCosts / 100 * (label === 'Gross Profit' ? -1 : 1)))
						.toLocaleString();

					return `${label}: ${formatMoneyDirect(actualValue, currency, true)} (${y.toFixed(1)}%)`;
				}
			},
			itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
			mode: 'x',
			position: 'nearest',
		},
	}
});

const percentageOfTotal = (data, field, modifier = 1) => {
	return data.map(report =>
		+(report[field].amount * 100 * modifier / report.totalCosts.amount).toFixed(2)
	);
}

const CostBreakdown = ({ aggregatedScopeReport, currency }) => {
	const finalChartOptions = useMemo(() => {
		return chartOptions(aggregatedScopeReport && Object.values(aggregatedScopeReport), currency);
	}, [currency, aggregatedScopeReport]);

	if(!aggregatedScopeReport) return <Loader />;

	const aggregatedScopeReportMonths = Object.keys(aggregatedScopeReport);
	const aggregatedScopeReportValues = Object.values(aggregatedScopeReport)

	const datasets = [
		{
			...FINANCIAL_CHART.qualitativeScale[7],
			hidden: true,
			fill: false,
			pointStyle: 'line',
			pointBorderWidth: 2,
			pointRadius: 6,
			showLine: false,
			type: 'line',
			label: 'Gross Profit',
			data: percentageOfTotal(aggregatedScopeReportValues, 'grossProfit', -1),
		},
		{
			...FINANCIAL_CHART.qualitativeScale[0],
			fill: true,
			label: 'Revenue Commissions',
			data: percentageOfTotal(aggregatedScopeReportValues, 'revenueSharingCosts'),
		},
		{
			...FINANCIAL_CHART.qualitativeScale[1],
			fill: true,
			label: 'Tutor Compensations',
			data: percentageOfTotal(aggregatedScopeReportValues, 'teacherCosts'),
		},
		{
			...FINANCIAL_CHART.qualitativeScale[2],
			fill: true,
			label: 'Location Expenses',
			data: percentageOfTotal(aggregatedScopeReportValues, 'locationCosts'),
		},
		{
			...FINANCIAL_CHART.qualitativeScale[3],
			fill: true,
			label: 'Partner Commissions',
			data: percentageOfTotal(aggregatedScopeReportValues, 'partnerCosts'),
		},
		{
			...FINANCIAL_CHART.qualitativeScale[4],
			fill: true,
			label: 'Booklet Printing',
			data: percentageOfTotal(aggregatedScopeReportValues, 'bookletPrintingExpenses'),
		},
		{
			...FINANCIAL_CHART.qualitativeScale[5],
			fill: true,
			label: 'Booklet Writer',
			data: percentageOfTotal(aggregatedScopeReportValues, 'bookletWriterCosts'),
		},
		{
			...FINANCIAL_CHART.qualitativeScale[6],
			fill: true,
			label: 'Other Expenses',
			data: percentageOfTotal(aggregatedScopeReportValues, 'otherCosts'),
		},
	];

	return (
		<Bar
			data={{
				labels: aggregatedScopeReportMonths,
				datasets: datasets,
			}}
			options={finalChartOptions}
			height={300}
		/>
	);
}

CostBreakdown.propTypes = {
	aggregatedScopeReport: PropTypes.object,
	currency: PropTypes.string,
};

export default CostBreakdown
