import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { useTranslation } from 'react-i18next';
import { filterRangeFormat } from '../../../modules/scopeFilter/scopeFilterHelpers';
import RangePicker from '../../util/RangePicker';

const PREFIX = 'ScopeFilterRange';

const classes = {
    button: `${PREFIX}-button`,
    buttonArrow: `${PREFIX}-buttonArrow`,
    buttonIcon: `${PREFIX}-buttonIcon`,
    paper: `${PREFIX}-paper`
};

const StyledButton = styled(Button)(({ theme }) => ({
	[`&.${classes.button}`]: {
		marginRight: theme.spacing(1),
	},

	[`& .${classes.buttonArrow}`]: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		fontSize: 22,
	},

	[`& .${classes.buttonIcon}`]: {
		marginLeft: theme.spacing(3),
	},
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.paper}`]: {
		[theme.breakpoints.down('md')]: {
			margin: theme.spacing(1, 0),
			padding: theme.spacing(1, 0),
			width: `calc(100% - ${theme.spacing(2)})`,
			maxWidth: `calc(100% - ${theme.spacing(2)})`,
		},
	}
}));

function ScopeFilterRange({ range: oRange, onChange }) {
	const [open, setOpen] = useState(false);
	const [range, setRange] = useState(oRange);

	const { t } = useTranslation();
	const theme = useTheme();
	const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		setRange(oRange);
	}, [oRange]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setRange(oRange);
	};

	const handleChange = (range) => {
		setRange(range);
	};

	const handleSelect = () => {
		onChange(range);
		handleClose();
	};

	return (
        (<>
            <StyledButton
				variant="outlined"
				color="default"
				className={classes.button}
				onClick={handleOpen}
				endIcon={<Icon className={classes.buttonIcon}>date_range</Icon>}
			>
				<span>{oRange.start ? moment(oRange.start, filterRangeFormat).format('D MMM YYYY') : '---'}</span>
				<Icon className={classes.buttonArrow}>arrow_right_alt</Icon>
				<span>{oRange.end ? moment(oRange.end, filterRangeFormat).format('D MMM YYYY') : '---'}</span>
			</StyledButton>
            { open && (
				<StyledDialog
					open={open}
					onClose={handleClose}
					maxWidth={false}
					fullWidth={isMobileWidth}
					classes={{ paper: classes.paper }}
				>
					<DialogTitle>{t('scope.selectRangeToViewCourses')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{t('scope.selectRangeToViewCoursesDesc')}</DialogContentText>
						<RangePicker
							range={range}
							onChange={handleChange}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary">
							{t('general.cancel')}
						</Button>
						<Button onClick={handleSelect} color="primary" variant="contained">
							{t('scope.selectRange')}
						</Button>
					</DialogActions>
				</StyledDialog>
			)}
        </>)
    );
}

ScopeFilterRange.propTypes = {
	range: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default ScopeFilterRange;
