import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { keys, pick } from 'ramda';
import React, { Component } from 'react';
import { patchClientProfile } from '../../../actions/clientActions';
import completeBasicForm, { Joi } from '../../../hoc/withCompleteBasicForm';
import Alert from '../../util/alert/Alert';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';
import PhoneField from '../../util/PhoneField';

const minDate = new Date();
const maxDate = new Date();

minDate.setFullYear(minDate.getFullYear() - 70);
minDate.setHours(0, 0, 0, 0);
maxDate.setFullYear(maxDate.getFullYear() - 15);
maxDate.setHours(0, 0, 0, 0);

const momentMinDate = moment(minDate);
const momentMaxDate = moment(maxDate);

class ClientEditDetailsForm extends Component {
	componentDidMount() {
		const { bindSave } = this.props;

		if (bindSave) {
			bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps) {
		const { success, onSave } = this.props;

		if (success && !prevProps.success && onSave) {
			onSave();
		}
	}

	handleChangeBirthday = (date) => {
		const { onChangeFieldDirect, validationField } = this.props;

		if (date?.isValid()) {
			onChangeFieldDirect('birthday', date.format());
		}
		validationField('birthday', date?.format());
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields, validationFields, validationAll, handleFormError } = this.props;

		validationAll(fields, (hasError) => {
			if (hasError) return handleFormError('Not all fields were filled correctly.');

			return watchSubmit(patchClientProfile(
				inputData.id, pick(keys(validationFields), fields),
			));
		});
	}

	render() {
		const { validation, validationBlur, fields, onChangeField, saving, success, error } = this.props;

		if (success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Updated details saved.
			</Alert>
		);

		return (
			<Box py={2}>
				{ saving && <Loader sheet /> }
				<ApiError error={error} showDetails hideErrorOnDetails />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField
							name="name"
							value={fields.name}
							onChange={onChangeField}
							label="First name"
							error={!!validation.name}
							helperText={validation.name}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="surname"
							value={fields.surname}
							onChange={onChangeField}
							label="Surname"
							error={!!validation.surname}
							helperText={validation.surname}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<PhoneField
							name="mobilePhone"
							value={fields.mobilePhone}
							onChange={onChangeField}
							label="Mobile phone"
							error={!!validation.mobilePhone}
							helperText={validation.mobilePhone}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<DatePicker
							value={fields.birthday}
							inputFormat="DD-MM-YYYY"
							showToolbar={false}
							minDate={momentMinDate}
							maxDate={momentMaxDate}
							onChange={this.handleChangeBirthday}
							openTo="year"
							renderInput={(props) => (
								<TextField
									{...props}
									label="Date of birth"
									helperText={validation.birthday}
									error={!!validation.birthday}
									fullWidth
									required
								/>
							)}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

ClientEditDetailsForm.propTypes = {
	inputData: PropTypes.object.isRequired,
};

export default completeBasicForm(ClientEditDetailsForm, {
	name: Joi.string().required(),
	surname: Joi.string().required(),
	mobilePhone: Joi.string().required(),
	birthday: Joi.date().allow(null),
});
