import { chipClasses, Box, Icon, styled, Table, TableCell, TableBody, TableRow, Chip } from '@mui/material';
import moment from 'moment'
import React, { Fragment, useRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import domToImage from 'dom-to-image'
import withFetchData from '../../modules/fetchData/withFetchData'
import { CALL_API } from '../../setup/api'
import { scopeParentsInclusiveList } from '../../helpers/scope'
import { formatMoney } from '../../helpers/formatter'
import { niceTime } from '../../helpers/datetime'
import Loader from '../util/loader/Loader'
import LiveActionBlur from './LiveActionBlur'
import { liveDebugMode } from './liveUtils'
import LiveBlockHeading from './LiveBlockHeading'
import crown from './media/crown.png'
import crown_silver from './media/crown_silver.png'
import crown_bronze from './media/crown_bronze.png'

const PREFIX = 'LiveTopStudiesTable';

const classes = {
	cell: `${PREFIX}-cell`,
	place: `${PREFIX}-place`,
	row: `${PREFIX}-row`,
	content: `${PREFIX}-content`,
	contentSecondary: `${PREFIX}-contentSecondary`,
	number: `${PREFIX}-number`,
};

const StyledTable = styled(Table)(({ theme }) => ({
	[`& .${classes.cell}`]: {
		display: 'flex',
		height: theme.spacing(7),
		paddingLeft: theme.spacing(2),
		alignItems: 'center',
		'&.first': {
			fontSize: '1.2em',
			fontWeight: theme.typography.fontWeightMedium,
		},
		'&:last-child': {
			paddingRight: theme.spacing(2),
		},
	},

	[`& .${classes.place}`]: {
		color: theme.palette.text.secondary,
		width: 40,
		marginRight: theme.spacing(2),
		fontSize: '1.3em',
		textAlign: 'center',
		'&.crown': {
			backgroundImage: `url(${crown})`,
			backgroundSize: '40px 40px',
			width: 40,
			height: 40,
			'&.silver': {
				backgroundImage: `url(${crown_silver})`,
			},
			'&.bronze': {
				backgroundImage: `url(${crown_bronze})`,
			},
		},
	},

	[`& .${classes.row}`]: {
		'&:last-child': {
			'& td': {
				borderBottom: 'none',
			},
		},
	},

	[`& .${classes.contentSecondary}`]: {
		display: 'inline-block',
		marginLeft: theme.spacing(2),
		color: theme.palette.text.secondary,
	},
}));

const apiAction = (rootScopeId) => ({
	[CALL_API]: {
		type: 'TOP_STUDIES',
		endpoint: `scope/${rootScopeId}/courses/income?period=${liveDebugMode ? 'P2Y' : 'P1D'}&maxResults=15`,
	},
});

const StyledChip = styled(Chip)(({ theme }) => ({
	[`&.${chipClasses.root}`]: {
		height: 28,
		backgroundColor: theme.palette.grey[100],
	},
	[`& .${chipClasses.label}`]: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
}));

const LiveTopStudiesTable = ({ loading, data, fetchCache, }) => {
	const tableRef = useRef();
	const [copied, setCopied] = useState(false);

	const handleImage = useCallback(() => {
		if(!tableRef.current) return;

		setCopied(false);

		// No direct copy support, download
		if(!navigator.clipboard || typeof ClipboardItem === 'undefined'){
			domToImage.toPng(tableRef.current, {
				bgcolor: '#FFFFFF',
			}).then((dataUrl) => {
				const link = document.createElement('a');

				link.download = `topStudiesToday${moment().format('YYYY-MM-DD_hh_mm')}.png`;
				link.href = dataUrl;
				link.click();
			});
		}

		// Otherwise, direct copy
		else{
			domToImage.toBlob(tableRef.current, {
				bgcolor: '#FFFFFF',
			}).then((dataUrl) => {
				navigator.clipboard.write([new ClipboardItem({ 'image/png': dataUrl })]);
				setCopied('Copied to clipboard!');
			})
		}
	}, [setCopied]);

	if(!data) return (
		<Loader sheet />
	);

	return (
        <Fragment>
			<LiveBlockHeading secondary={(
				<em>last update<br/>{niceTime(fetchCache.date)}</em>
			)}>
				Top studies today
				<Icon
					onClick={handleImage}
					sx={{ ml: 1, cursor: 'pointer', verticalAlign: 'sub' }}
				>
					photo camera
				</Icon>
			</LiveBlockHeading>
			<LiveActionBlur text={copied} />
			<StyledTable size="small" ref={tableRef}>
				<TableBody>
					{data.map((item, i) => {
						return (
                            <TableRow key={item.scopeId} className={classes.row}>
								<TableCell className={classNames(classes.cell, {
									first: i === 0,
								})}>
									<div className={classNames(classes.place, {
										crown: i <= 2,
										silver: i === 1,
										bronze: i === 2,
									})}>
										{i > 2 ? i + 1 : ''}
									</div>
									<Box sx={{ flex: 1 }}>
										{item.scope.name}
										<div className={classes.contentSecondary}>
											{scopeParentsInclusiveList(item.scope, ['Institute'], 'abbreviation').join(' > ')}
										</div>
									</Box>
									<Box sx={{ ml: 2 }}>
										<StyledChip
                                            label={formatMoney(item.income)}
                                            variant="outlined"
                                        />
									</Box>
								</TableCell>
							</TableRow>
                        );
					})}
				</TableBody>
			</StyledTable>
		</Fragment>
    );
};

LiveTopStudiesTable.propTypes = {
	rootScopeId: PropTypes.number.isRequired,
};

export default (withFetchData((props) => apiAction(props.rootScopeId), {
	autoRefreshSeconds: 60 * 5,
})(LiveTopStudiesTable))
