import { styled } from '@mui/material/styles';
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Chip from '@mui/material/Chip'
import withFetchData from '../../modules/fetchData/withFetchData'
import { CALL_API } from '../../setup/api'
import { getKey, Schemas } from '../../actions/schemas'
import { formatPercentage } from '../../helpers/formatter'
import { niceDateYear } from '../../helpers/datetime'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import PropertiesTable from '../util/PropertiesTable'
import BundleViewStudyList from './BundleViewStudyList'
import BundleAddCourseDialog from './BundleAddCourseDialog'

const PREFIX = 'BundleView';

const classes = {
    chip: `${PREFIX}-chip`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.chip}`]: {
		marginLeft: theme.spacing(1),
	}
}));

const ApiAction = (bundleId) => ({
	[CALL_API]: {
		type: 'BUNDLE_VIEW',
		endpoint: `athenastudies-course-bundle/${bundleId}`,
		schema: Schemas.COURSE_BUNDLE,
	},
});

const isActive = expires =>
	moment(expires).subtract(5, 'minutes').isAfter(moment());

const firstCourseStudy = bundle => {
	if(!bundle.applicableInCourses) return null;
	if(!bundle.applicableInCourses[0]) return null;

	return bundle.applicableInCourses[0].parent;
}

const BundleView = ({ error, loading, data }) => {

	const [ showAddModal, setShowAddModel ] = useState(false);

	function toggleAddModal(){
		setShowAddModel(val => !val);
	}

	if(error) return (
		<ApiError error={error} />
	);

	if(loading || !data) return (
		<Loader />
	);

	const properties = [
		{ label: 'Description', content: data.description },
		{ label: 'Discount', content: formatPercentage(data.discountPercentage) },
		{ label: 'Expires', content: (
			<Fragment>
				{niceDateYear(data.expirationDate)}
				{!isActive(data.expirationDate) && (
					<Chip label="expired" className={classes.chip} color="secondary" variant="outlined" />
				)}
			</Fragment>
		) },
	];

	// This assumes a bundle can only contain courses from one study
	// This is however not a hard requirement in the model
	const study = firstCourseStudy(data);

	return (
        <Root>
			<PropertiesTable items={properties} />
			<BundleViewStudyList
				courses={data.applicableInCourses}
				bundleId={data.id}
				onAddClick={toggleAddModal}
			/>
			{!Boolean(study) && (
				<Alert>
					No study from the linked courses could be found. Cannot add new courses.
				</Alert>
			)}
			{Boolean(showAddModal && study) && (
				<BundleAddCourseDialog
					onClose={toggleAddModal}
					study={study}
					bundle={data}
				/>
			)}
		</Root>
    );
};

BundleView.propTypes = {
	id: PropTypes.number.isRequired,
};

export default withFetchData((props) => ApiAction(props.id), {
	mapEntities: getKey(Schemas.COURSE_BUNDLE),
})(BundleView)
