import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { choiceLabel } from '../../helpers/properties';
import TranslatableStringEditor from '../util/TranslatableStringEditor';

function PropertyEditorOptionChip({ choice, index, editMode, handleChoicesChange, handleChoiceDelete, providedDrag, forwardedOptionsRef }) {
	const [open, setOpen] = useState(false);

	const { i18n } = useTranslation();

	const handleOpen = () => {
		setOpen(true);
	};

	useEffect(() => {
		if (
			Number.isInteger(forwardedOptionsRef.current)
			&& forwardedOptionsRef.current === index
		) {
			handleOpen();
		}
	}, [forwardedOptionsRef, index]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleLabelConfirm = (translatableString) => () => {
		handleChoicesChange({
			key: choice?.key || choice,
			label: translatableString,
		});
		handleClose();
	};

	return (
        <>
            {open && (
				<TranslatableStringEditor
					handleConfirm={handleLabelConfirm}
					name={choice?.key || choice}
					translatableString={choice?.label || choice}
					onDelete={handleChoiceDelete(choice)}
					onClose={handleClose}
				/>
			)}
            <Chip
				label={choiceLabel(choice, i18n.language)}
				icon={<Icon {...providedDrag.dragHandleProps}>drag_indicator</Icon>}
				onDelete={handleOpen}
				deleteIcon={<Icon>edit</Icon>}
				color="primary"
				disabled={!editMode}
				sx={{ mb: 1 }}
				ref={providedDrag.innerRef}
				{...providedDrag.draggableProps}
			/>
        </>
    );
}

PropertyEditorOptionChip.propTypes = {
	choice: PropTypes.oneOfType([
		PropTypes.shape({
			key: PropTypes.string,
			label: PropTypes.object,
		}),
		PropTypes.string,
	]).isRequired,
	editMode: PropTypes.bool.isRequired,
	handleChoicesChange: PropTypes.func.isRequired,
	handleChoiceDelete: PropTypes.func.isRequired,
	providedDrag: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	forwardedOptionsRef: PropTypes.object.isRequired,
	error: PropTypes.object,
	saving: PropTypes.bool,
};

export default PropertyEditorOptionChip;
