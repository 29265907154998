import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { getNumberFormatSeparators } from '../../helpers/formatter';

const EvaluationInputField = React.forwardRef((props, ref) => {
	const { onChange, ...other } = props;
	const { i18n } = useTranslation();
	const numberSeparators = useMemo(() => getNumberFormatSeparators(i18n.language), [i18n.language]);

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: !Number.isNaN(values.value) && values.value !== '' ? parseFloat(values.value) : null,
					},
				});
			}}
			min={1}
			max={10}
			isNumericString
			isAllowed={({ floatValue }) => (floatValue <= 10 && floatValue >= 1) || floatValue === undefined}
			{...numberSeparators}
		/>
	);
});

EvaluationInputField.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default EvaluationInputField;
