import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import CourseGroupOccupancyMailList from './CourseGroupOccupancyMailList'
import CourseGroupOccupancyMailSend from './CourseGroupOccupancyMailSend'

const CourseGroupOccupancyMailModal = ({ group, handleClose }) => {
	return (
		<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="lg" fullWidth>
			<DialogTitle>
				Group email
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item md={5} xs={12}>
						<CourseGroupOccupancyMailList group={group} />
					</Grid>
					<Grid item md={7} xs={12}>
						<CourseGroupOccupancyMailSend group={group} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CourseGroupOccupancyMailModal
