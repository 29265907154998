import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { formatMoney } from '../../helpers/formatter'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import PageHeaderMenu from '../page/PageHeaderMenu'
import PageHeaderNavigation from '../page/PageHeaderNavigation'
import PageHeaderInfo from '../page/PageHeaderInfo'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import CourseGroupEditModal from './CourseGroupEditModal'
import CourseGroupEvaluateModal from './CourseGroupEvaluateModal'
import CourseGroupCancelModal from './CourseGroupCancelModal'
import { niceDateTime } from '../../helpers/datetime'
import { scopeParentsToBreadcrumbs, formatArtificialStockStatus } from '../../helpers/scope'
import { hasScopeCapability, capabilities } from '../../helpers/capabilities'
import GroupOccupancy from '../util/GroupOccupancy'
import GroupVisibility from '../util/GroupVisibility'
import GroupProblemIndicator from '../util/GroupProblemIndicator'
import ScopeVisibilitySwitcher from '../util/ScopeVisibilitySwitcher'
import Alert from '../util/alert/Alert'
import CourseGroupTimeSlots from './CourseGroupTimeSlots'
import CourseGroupOccupancy from './CourseGroupOccupancy'
import CourseGroupPageEvaluationList from './evaluations/courseLists/CourseGroupPageEvaluationList'
import ScopeInformationModal from './ScopeInformationModal';

const CourseGroupIndexPage = ({ course, group, groups }) => {
	const { t } = useTranslation();
	const location = useLocation();
	if(!course || !group) return (
		<Alert type={Alert.TYPE_INFO}>
			{t('scope.group.groupDeleted')}
		</Alert>
	);

	const menuItems = [];

	if(hasScopeCapability(course, capabilities.ScopeAlterCapability)){
		menuItems.push({ name: t('scope.group.editGroup'), to: location.pathname, state: { modal: 'edit-group' }, icon: 'mode_edit' });
	}

	if(hasScopeCapability(course, capabilities.FinancialReportingCapability)){
		menuItems.push({ name: t('scope.group.financial'), to: `${location.pathname}/financial`, icon: 'account_balance' })
	}

	if(hasScopeCapability(course, capabilities.ScopeAlterCapability)){
		menuItems.push(
			{ name: t('scope.group.evaluate'), to: location.pathname, state: { modal: 'evaluate' }, icon: 'star_border' },
			{ name: t('scope.group.evaluations'), to: `${location.pathname}/evaluations`, icon: 'star_half' },
			{ name: t('scope.group.manageCosts'), to: `${location.pathname}/costs`, icon: 'euro_symbol' },
			{ name: t('scope.group.cancelGroup'), to: location.pathname, state: { modal: 'cancel' }, icon: 'cancel' },
			{
				name: t('scope.group.deleteGroup'),
				to: location.pathname,
				state: {
					modal: 'scopeDelete', scopeId: group.id, customSuccessUrl: `/portal/planner/course/${course.id}`,
				},
				icon: 'delete',
			},
		);
	}

	menuItems.push(
		{
			name: t('scope.information.button'),
			to: location.pathname,
			state: { modal: 'information' },
			icon: 'info_outlined',
		},
	);

	const evaluation = group.evaluation;

	return (
		<>
			<ModalManager>
				<ModalItem state="edit-group" component={CourseGroupEditModal} group={group} />
				<ModalItem state="evaluate" component={CourseGroupEvaluateModal} group={group} />
				<ModalItem state="cancel" component={CourseGroupCancelModal} group={group} />
				<ModalItem state="information" component={ScopeInformationModal} scope={group} />
			</ModalManager>
			<PageHeader>
				<PageHeaderMenu items={menuItems} />
				<PageHeaderNavigation breadcrumbs={[
					{ to: `/portal/planner/`, label: 'Planner' },
					...scopeParentsToBreadcrumbs(group.parent),
					{ to: `/portal/planner/course/${course.id}`, label: course.name },
					{ label: group.name },
				]} />
				<PageHeaderTitle headline={
					<>
						{group.name}
						<ScopeVisibilitySwitcher scope={group} />
						<GroupProblemIndicator group={group} bigger />
					</>
				}
								 subHeading={course.name} />
				<PageHeaderInfo
					items={[
						{ label: t('general.price'), content: formatMoney(group.price) },
						{ label: t('general.occupancy'), content: <GroupOccupancy group={group} /> },
						{ label: t('general.startDate'), content: niceDateTime(group.courseStart) },
						{ label: t('general.timeslots'), content: group.numTimeSlots },
						{ label: t('general.status'), content: t(`groupStates.${group.state}.name`) },
						{ label: t('general.manipulation'), content: formatArtificialStockStatus(group) },
						{ label: t('general.visibility'), content: <GroupVisibility group={group} /> },
						{ label: t('general.postalAddress'), content: group.addressMandatory ? t('scope.group.addressEnabled') : t('scope.group.addressNotEnabled') },
					]}
					info={group.evaluation && (
						<Trans i18nKey="scope.group.groupEvaluation" values={{ evaluation }}>
							This group has been evaluated with a <strong>{{evaluation}}</strong>.
						</Trans>
					)}
					warning={group.state === 'CANCELLED' && (
						<Trans i18nKey="scope.group.groupCancelledWarning">
							This group has been <strong>cancelled</strong>.
						</Trans>
					)}
				/>
			</PageHeader>
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<CourseGroupTimeSlots group={group} />
						</Grid>
						<Grid item xs={12}>
							<CourseGroupPageEvaluationList group={group} course={course} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={6} xs={12}>
					<CourseGroupOccupancy group={group} course={course} groups={groups} />
				</Grid>
			</Grid>
		</>
	);
}

CourseGroupIndexPage.propTypes = {
	course: PropTypes.object,
	group: PropTypes.object,
};

export default CourseGroupIndexPage
