import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import withFormData from '../../modules/formData/withFormData'
import { deleteCost } from '../../actions/costActions'
import { niceDateYear } from '../../helpers/datetime'
import { formatMoney, formatFullName } from '../../helpers/formatter'
import Alert from '../util/alert/Alert'

const formatAccount = (account) => account.accountNumber + ' - ' + account.description;
const CutLast = (date) => date.substr(0, date.lastIndexOf(':'));

const CourseGroupCostsList = ({ costs, group, watchSubmit }) => {
	if(!costs || costs.length === 0) return (
		<Alert type={Alert.TYPE_INFO}>No costs yet on this group.</Alert>
	);

	const handleDelete = (cost) => {
		if(!confirm('Are you sure you want to remove "' + cost.description + '" with costs ' + formatMoney(cost.amount) + '?')) return;

		watchSubmit(deleteCost(
			group.id, cost.account.id, cost.id, 'courseGroupCostsPage'
		));
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Kosten</TableCell>
					<TableCell>Type</TableCell>
					<TableCell>Beschrijving</TableCell>
					<TableCell>Datum</TableCell>
					<TableCell>Toegevoegd door</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
			{costs && costs.map((cost) => (
				<TableRow key={cost.id}>
					<TableCell>{formatMoney(cost.amount)}</TableCell>
					<TableCell>{cost.account && formatAccount(cost.account)}</TableCell>
					<TableCell>{cost.description}</TableCell>
					<TableCell>{niceDateYear(CutLast(cost.creation))}</TableCell>
					<TableCell>{cost.createdBy ? formatFullName(cost.createdBy) : 'onbekend'}</TableCell>
					<TableCell style={{textAlign: 'right'}}>
						<IconButton onClick={() => handleDelete(cost)} color="secondary">
							<Icon>delete</Icon>
						</IconButton>
					</TableCell>
				</TableRow>
			))}
			</TableBody>
		</Table>
	);
}

CourseGroupCostsList.propTypes = {
	costs: PropTypes.array,
	group: PropTypes.object.isRequired,
};

export default withFormData()(CourseGroupCostsList)
