import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import withScopeAndSubScopes from '../../../modules/scope/withScopeAndSubScopes'
import { scopeParentsFromStore } from '../../../helpers/scope'
import Loader from '../../util/loader/Loader'
import ApiError from '../../util/ApiError'
import ScopeTooltipScopeDefault from './ScopeTooltipScopeDefault'
import ScopeTooltipScopeGroup from './ScopeTooltipScopeGroup'
import ScopeTooltipScopeCourse from './ScopeTooltipScopeCourse'

const PREFIX = 'ScopeTooltipCard';

const classes = {
    card: `${PREFIX}-card`,
    loader: `${PREFIX}-loader`
};

const StyledCard = styled(Card)(({ theme }) => ({
    [`&.${classes.card}`]: {
		width: 350,
	},

    [`& .${classes.loader}`]: {
		height: 250,
	}
}));

function scopeLinkTo(scope){
	if(scope._class === 'AthenaStudiesCourseGroup'){
		return `/portal/planner/course/${scope.parent}/group/${scope.id}`;
	}

	if(scope._class === 'AthenaStudiesCourse'){
		return `/portal/planner/course/${scope.id}`;
	}

	return null;
}

function getComponent(scope){
	switch (scope._class){
		case 'AthenaStudiesCourseGroup': return ScopeTooltipScopeGroup;
		case 'AthenaStudiesCourse': return ScopeTooltipScopeCourse;
		default: return ScopeTooltipScopeDefault;
	}
}

function stopPropagation(e){
	e.stopPropagation();
	e.preventDefault();

	return false;
}

const ScopeTooltipCard = ({ scopeId, loading, error, scope, subScopes, parentScope, }) => {
	const [ activeTab, setActiveTab ] = useState(0);
	const onTabChange = (e, value) => setActiveTab(value);

	if(error) return (
		<ApiError error={error} />
	);

	if(loading || !scope) return (
		<Loader className={classes.loader} />
	);

	const isGroup = scope._class === 'AthenaStudiesCourseGroup';
	const scopeTo = scopeLinkTo(scope);
	const title = isGroup ? `${parentScope.name} - ${scope.name}` : scope.name;
	const parentList = scopeParentsFromStore(
		isGroup ? parentScope.parent : scope.parent,
		'abbreviation', 'Company'
	);

	const Component = getComponent(scope);

	return (
        <StyledCard className={classes.card} onClick={stopPropagation}>
			<CardHeader
				title={title}
				subheader={parentList.join(' > ')}
				action={scopeTo && (
					<IconButton component={Link} to={scopeTo}>
						<Icon>arrow_forward</Icon>
					</IconButton>
				)}
				titleTypographyProps={{
					variant: 'body2',
				}}
				subheaderTypographyProps={{
					variant: 'body2',
				}}
			/>
			<Component
				scope={scope}
				subScopes={subScopes}
				parentScope={parentScope}
				activeTab={activeTab}
				onTabChange={onTabChange}
			/>
		</StyledCard>
    );
}

ScopeTooltipCard.propTypes = {
	scopeId: PropTypes.number.isRequired,
};

export default (withScopeAndSubScopes(ScopeTooltipCard, (props) => props.scopeId))
