import { Icon, ButtonGroup } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { omit } from 'ramda';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { editScopeTypes } from '../../../actions/scopeEditActions';
import { getMoneyCurrencySymbol } from '../../../helpers/formatter';
import { returnDefaultValue } from '../../../helpers/properties';
import PropertySelectorTable from '../../properties/PropertySelectorTable';
import AthenaEditor from '../../util/AthenaEditor';
import InfoTooltip from '../../../modules/info/InfoTooltip';
import editScope from './editScope';
import ScopeDescriptionTemplatesDialog from './ScopeDescriptionTemplatesDialog';

const PREFIX = 'ScopeAthenaStudiesCourseForm';

const classes = {
    root: `${PREFIX}-root`,
    label: `${PREFIX}-label`
};

const Root = styled('form')(({ theme }) => ({
    [`&.${classes.root}`]: {
		marginRight: theme.spacing(3),
	},

    [`& .${classes.label}`]: {
		marginLeft: `-${theme.spacing(1)}`,
	}
}));

const PublishedOptions = [
	{ value: 'published', label: 'Published' },
	{ value: 'draft', label: 'Draft' },
];

const PublishedValues = PublishedOptions.map(o => o.value);

function formatNumber(n) {
	return n ? n.replace(',', '.') : n;
}

function ScopeAthenaStudiesCourseForm({
	saveScope, showButtons, saving, fields, parent,
	bindState, bindStateSwitch, bindStateDirect,
	switchCheckedFromValue, scopeParent,
	success, formError, scope,
}) {
	const scrollRef = useRef(null);
	const { t } = useTranslation();
    const [showTemplateSelector, setShowTemplateSelector] = useState(false);

    const handleCloseTemplateSelector = useCallback(() => {
        setShowTemplateSelector(false);
    }, []);

    const editorApiRef = useRef(null);
    const bindEditorApi = useCallback((api) => {
        editorApiRef.current = api;
    }, []);

    const handleSetContent = useCallback((raw, html) => {
        editorApiRef.current?.setEditorState?.(raw);
        editorApiRef.current?.focus?.();

        bindStateDirect('descriptionEditor')(raw);
        bindStateDirect('description')(html);

        setShowTemplateSelector(false);
    }, [bindStateDirect]);

	const handlePropChange = useCallback(propKey => (event, directValue) => {
		const { value, checked } = event.target;
		let valueToUse = value;
		if (event.target.type === 'checkbox') {
			valueToUse = checked;
		}
		if (Array.isArray(directValue) || (event.target.type === 'text' && directValue)) {
			valueToUse = directValue;
		}
		if (event.target.type === 'number') {
			valueToUse = parseFloat(value);
		}

		bindStateDirect('properties')({
			...fields.properties,
			[propKey]: valueToUse,
		});
	}, [bindStateDirect, fields.properties]);

	const handlePropAdd = prop => () => {
		bindStateDirect('properties')({
			...fields.properties,
			[prop.key]: returnDefaultValue(prop),
		});
	};

	const handlePropDelete = propKey => () => {
		const properties = { ...fields.properties };
		delete properties[propKey];
		bindStateDirect('properties')(properties);
	};

	const handleChangeDescription = useCallback((raw, html) => {
		bindStateDirect('descriptionEditor')(raw);
		bindStateDirect('description')(html);
	}, [bindStateDirect]);

	useEffect(() => {
		if (success || formError) {
			scrollRef.current?.scrollIntoView?.({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [success, formError]);

	return (
        <Root onSubmit={saveScope}>
			<div ref={scrollRef} />
			<Grid container spacing={2}>
				<Grid item md={4} sm={6} xs={12}>
					<TextField
						label={t('general.name')}
						name="name"
						fullWidth
						value={fields.name}
						onChange={bindState('name')}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InfoTooltip infoSlug="course_name" title={t('scope.edit.courseNameInfo')} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<TextField
						label={t('general.price')}
						name="price"
						fullWidth
						value={fields.price}
						onChange={bindState('price', formatNumber)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									{getMoneyCurrencySymbol(scopeParent.currencyUnit)}
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									<InfoTooltip infoSlug="course_price" title="Price explanation" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<FormControlLabel
						label={t('scope.edit.publishedForCustomers')}
						control={(
							<Switch
								checked={switchCheckedFromValue(PublishedValues, fields.published)}
								onChange={bindStateSwitch('published', PublishedValues)}
							/>
						)}
					/>
				</Grid>

				<Grid item xs={12}>
					<h4>
						{t('scope.edit.description')}
						{' '}
						<InfoTooltip infoSlug="course_description" title="Cursus beschrijving" />
					</h4>
					<AthenaEditor
						value={fields.descriptionEditor || fields.description}
						onChange={handleChangeDescription}
						output="html"
                        bindEditorApi={bindEditorApi}
                        toolbarComponents={(
                            <ButtonGroup color="default">
                                <Button
                                    onClick={() => setShowTemplateSelector(true)}
                                    className={classes.btn}
                                    startIcon={<Icon>grid_view</Icon>}
                                    size="medium"
                                >
                                    Select templates
                                </Button>
                            </ButtonGroup>
                        )}
					/>
				</Grid>

				<Grid item xs={12}>
					<h4>{t('scope.edit.properties')}</h4>
					<Typography variant="body2" style={{ marginBottom: '1rem' }}>
						<em>{t('scope.edit.selectProperties')}</em>
					</Typography>
					<FormGroup row>
						<PropertySelectorTable
							handlePropAdd={handlePropAdd}
							handlePropChange={handlePropChange}
							handlePropDelete={handlePropDelete}
							properties={fields.properties}
							context="scope"
						/>
					</FormGroup>
				</Grid>

				{showButtons && (
					<Grid item>
						<Button
							variant="contained"
							color="default"
							disabled={saving}
							onClick={saveScope}
						>
							{saving && t('general.saving')}
							{!saving && parent
								? t('general.addValue', { value: t('scopes.course') })
								: t('general.editValue', { value: t('scopes.course') })}
						</Button>
					</Grid>
				)}
			</Grid>
            {showTemplateSelector && (
                <ScopeDescriptionTemplatesDialog
                    scope={scope}
                    onClose={handleCloseTemplateSelector}
                    onChangeContent={handleSetContent}
                    editorApi={editorApiRef.current}
                />
            )}
		</Root>
    );
}

export default editScope(
	editScopeTypes.ATHENA_STUDIES_COURSE,
	{
		fields: {
			name: editScope.Joi.string().required().default(''),
			published: editScope.Joi.string().valid(PublishedValues).default(PublishedValues[0]),
			price: editScope.Joi.number().required().default(0.00),
			description: editScope.Joi.string().required().default(''),
			descriptionEditor: editScope.Joi.string().default(null),
			properties: editScope.Joi.object().default({}).allow(null),
		},
		validateFields: true,
		convertBeforeLoad: (scope) => {
			if (!scope) return scope;
			return {
				...scope,
				descriptionEditor: scope.properties ? (scope.properties.descriptionEditor || null) : null,
				price: scope.price.amount,
			};
		},
		convertBeforeSave: (fields, props) => omit(['descriptionEditor'], {
			...fields,
			price: {
				amount: fields.price,
				currency: props.scopeParent.currencyUnit,
			},
			properties: {
				...fields.properties,
				descriptionEditor: fields.descriptionEditor || null,
			},
		}),
	},
)(ScopeAthenaStudiesCourseForm);
