import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { reverse } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { scopeParentsFromStoreFull } from '../../helpers/scope'
import { getApiTypeSuccess } from '../../helpers/types'
import { fetchDataAppend } from '../../modules/fetchData/fetchDataActions'
import withFormData from '../../modules/formData/withFormData'
import { CALL_API } from '../../setup/api'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'

/**
 * @param scopeId
 * @param data object:{street:null,houseNumber:null,zipcode:null,city:null,country:null,name:"",rooms:[]}
 */
const apiAction = (scopeId, data) => (dispatch) => {
	return dispatch({
		[CALL_API]: {
			type: 'LOCATION',
			endpoint: `scope/${scopeId}/course-location`,
			body: data,
		},
	}).then(res => {
		if(res.type === getApiTypeSuccess('LOCATION')){
			dispatch(fetchDataAppend('locationSelectDialog', res?.response?.result));
		}

		return res;
	});
}

const findCityFromScope = (scopeId) => {
	return reverse(scopeParentsFromStoreFull(scopeId, 'Company'))
		.filter(scope => Boolean(scope.city))?.[0]?.city || '';
}

const LocationSelectLocationCreate = ({ scope, onClose, onSelect, saving, error, success, watchSubmit }) => {
	const [data, setData] = useState({
		name: '',
		street: '',
		houseNumber: '',
		zipcode: '',
	});

	const handleChange = useCallback((e) => {
		const name = e.target.name;
		const value = e.target.value;

		setData(data => ({
			...data,
			[name]: value,
		}));
	}, [setData]);

	const handleSave = useCallback(() => {
		watchSubmit(apiAction(scope.id, data));
	}, [scope, data, watchSubmit]);

	useEffect(() => {
		if(success){
			onSelect(success);
			onClose();
		}
	}, [success, onClose, onSelect]);

	return (
		<>
			{saving && (
				<Loader sheet />
			)}
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="body1" paragraph>
						Make sure the location <strong>does not exist</strong> already! The existence of double locations can cause unexpected problems.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Name"
						name="name"
						value={data.name}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Street"
						name="street"
						value={data.street}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="House number"
						name="houseNumber"
						value={data.houseNumber}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Zipcode"
						name="zipcode"
						value={data.zipcode}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						disabled
						label="City"
						name="city"
						value={findCityFromScope(scope.id)}
						helperText="This follows the scope of this course"
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box mb={4}>
						{error && (
							<ApiError error={error} />
						)}
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Button fullWidth variant="outlined" onClick={onClose}>
									Cancel
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button fullWidth variant="contained" color="primary" onClick={handleSave}>
									Save
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

LocationSelectLocationCreate.propTypes = {
	scope: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default withFormData()(LocationSelectLocationCreate)
