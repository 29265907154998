import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import withSubScopesNoLoading from '../../../modules/scope/withSubScopesNoLoading'
import ScopeAthenaStudiesCourse from './ScopeAthenaStudiesCourse'
import PlannerHeader from './PlannerHeader'

const PREFIX = 'ScopeCourse';

const classes = {
    notFound: `${PREFIX}-notFound`
};

const Root = styled('div')({
	[`& .${classes.notFound}`]: {
		margin: '10px 0 40px 0',
	},
});

const ScopeCourse = ({ scope, subScopes, parentList, filter, }) => {
	return (
        <Root>
			<PlannerHeader scopes={(parentList || []).concat([scope])} />
			<div>
				{subScopes && subScopes.map(scope => {
					if(scope._class !== 'AthenaStudiesCourse') return null;

					return (
						<ScopeAthenaStudiesCourse key={scope.id}
												  scope={scope}
												  filter={filter} />
					);
				})}
				{
					subScopes && subScopes.length === 0 &&
					<p className={classes.notFound}>
						<em>No courses for this study yet or visible (try changing the filter).</em>
					</p>
				}
			</div>
		</Root>
    );
}

ScopeCourse.propTypes = {
	scope: PropTypes.object.isRequired,
	parentList: PropTypes.array,
	filter: PropTypes.object,
}

export default (withSubScopesNoLoading(ScopeCourse))
