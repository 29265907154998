import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import { niceDateYear } from '../../helpers/datetime'
import withFetchData from '../../modules/fetchData/withFetchData'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { getTeachersWithDetails } from '../../actions/dashboardActions'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'

class TeachersWithDetails extends Component {
	componentDidMount() {
		if (this.props.scope) this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (!this.props.scope) return;
		let updateRequired = !prevProps.scope;
		updateRequired |= prevProps.scope.id !== this.props.scope.id;
		updateRequired |= prevProps.pagination.page !== this.props.pagination.page;
		updateRequired |= prevProps.pagination.pageSize !== this.props.pagination.pageSize;
		if (updateRequired) this.fetchData();
	}

	fetchData() {
		this.props.load(getTeachersWithDetails(
			this.props.scope.id
		));
	}

	render() {
		const { scope, loading, error, data, pagination, onPaginationChangePage, onPaginationChangePageSize } = this.props;
		const employeesWithDetails = data;

		if (!scope) return (
			<Alert type={Alert.TYPE_INFO}>Select a scope in the scope select at the top first.</Alert>
		);

		if (error) return (
			<Alert type={Alert.TYPE_WARNING}>{error.errorCode === 403 ?
				'Sorry, you may not view the teacher overview of this scope.' :
				'Error! Sorry, we could not load the data.'
			}</Alert>
		);

		if ( loading || !data ) return (
			<Loader />
		);

		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell size="small">Name</TableCell>
						<TableCell size="small">Taught courses</TableCell>
						<TableCell size="small">Average evaluation</TableCell>
						<TableCell size="small">Contract expiration</TableCell>
						<TableCell size="small">Phone Number</TableCell>
						<TableCell size="small">Email</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ employeesWithDetails && employeesWithDetails.length ?
						employeesWithDetails.map((employeeWithDetails) => (
							<TableRow key={employeeWithDetails.employeeId}>
								<TableCell size="small" style={{ fontWeight: 'bold' }}>
									<Link to={`/portal/employees/${employeeWithDetails.employeeId}/view`}>
										{employeeWithDetails.fullName}
									</Link>
								</TableCell>
								<TableCell size="small" style={{ width: '30%'}}>{employeeWithDetails.taughtCourses}</TableCell>
								<TableCell size="small" style={{ whiteSpace : 'nowrap'}}>{employeeWithDetails.averageEvaluation}</TableCell>
								<TableCell size="small" style={{ whiteSpace : 'nowrap'}}>{employeeWithDetails.contractEnd && niceDateYear(employeeWithDetails.contractEnd)}</TableCell>
								<TableCell size="small" style={{ whiteSpace : 'nowrap'}}>{employeeWithDetails.mobilePhone}</TableCell>
								<TableCell size="small" style={{ whiteSpace : 'nowrap'}}>{employeeWithDetails.email}</TableCell>
							</TableRow>
						))
						:
						<TableRow>
							<TableCell>No results found. Specify another scope in the scope selector above.</TableCell>
						</TableRow>

					}
				</TableBody>
				{pagination &&
				<TableFooter>
					<TableRow>
						<TablePagination
							colSpan={6}
							count={pagination.total}
							rowsPerPage={pagination.pageSize}
							page={pagination.page}
							onPageChange={onPaginationChangePage}
							onRowsPerPageChange={onPaginationChangePageSize}
							rowsPerPageOptions={[10, 25, 50, 100]}
						/>
					</TableRow>
				</TableFooter>}
			</Table>
		)
	}
}

export default withWorkingScope(withFetchData(null, {
	pagination: true,
	keepData: true
})(TeachersWithDetails))
