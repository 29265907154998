import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { formatMoney, formatMoneyAuto } from '../../helpers/formatter'
import { formatMoneySumCurrencyGrouped } from '../../helpers/money'
import { CALL_API } from '../../setup/api'
import withFetchData from '../../modules/fetchData/withFetchData'
import { Link } from 'react-router-dom'

class LedgerEntryType {
	static EMPLOYEE_COMMISSION = "EMPLOYEE_COMMISSION";
	static TEACHER_SALARY = "TEACHER_SALARY";
	static BOOKLET_WRITER_EXPENSE = "BOOKLET_WRITER_EXPENSE";
}

const ReadableTypes = {
	[LedgerEntryType.EMPLOYEE_COMMISSION] : "Revenue Commission",
	[LedgerEntryType.TEACHER_SALARY] : "Tutoring",
	[LedgerEntryType.BOOKLET_WRITER_EXPENSE] : "Booklet"
};


class EmployeeCourseGroupLedgerOverview extends Component {

	getDetails(entry) {
		switch (entry.type) {
			case LedgerEntryType.EMPLOYEE_COMMISSION:
				return `${entry.contract.turnoverPercentage || entry.contract.function.turnoverPercentage}% - ${entry.contract.bonusTurnoverPercentage || entry.contract.function.bonusTurnoverPercentage}%`
			case LedgerEntryType.TEACHER_SALARY:
				return `${formatMoney(entry.contract.baseSalary || entry.contract.function.baseSalary)} - ${formatMoney(entry.contract.bonusSalary || entry.contract.function.bonusSalary)}`
			default:
				return '-';
		}
	}

	render() {
		const { linkToCourse } = this.props;
		const courseGroupLedgerSummary = this.props.data || [];

		return (
            <Table>
				<TableHead>
					<TableRow>
						<TableCell size="small">Course</TableCell>
						<TableCell size="small">Month</TableCell>
						<TableCell size="small">Type</TableCell>
						<TableCell size="small">Details</TableCell>
						<TableCell size="small" align="right">Amount</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{courseGroupLedgerSummary.map((entry) => (
						<TableRow key={`${entry.contract.id}-${entry.scope.id}-${entry.computedOver}-${entry.type}`}>
							<TableCell size="small">
								{
									linkToCourse ?
										<Link to={`/portal/planner/course/${entry.scope.id}`}>{entry.scope.name}</Link>
										:
										entry.scope.name
								}
							</TableCell>
							<TableCell size="small" style={{ whiteSpace :'nowrap'}}>{entry.computedOver}</TableCell>
							<TableCell size="small" style={{ whiteSpace :'nowrap'}}>{ReadableTypes[entry.type]}</TableCell>
							<TableCell size="small" style={{ whiteSpace :'nowrap'}}>{this.getDetails(entry)}</TableCell>
							<TableCell size="small"  style={{ whiteSpace :'nowrap'}} align="right">{formatMoneyAuto(entry.amount)}</TableCell>
						</TableRow>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell size="small">Total</TableCell>
						<TableCell size="small" />
						<TableCell size="small" />
						<TableCell size="small" />
						<TableCell size="small" style={{ whiteSpace :'nowrap'}} align="right">
							{formatMoneySumCurrencyGrouped(courseGroupLedgerSummary.map(a => a.amount))}
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
        );
	}
}

const getLedgerSummaryEntries = (employeeId, month) => ({
	[CALL_API]: {
		type: "EMPLOYEE_LEDGER_SUMMARY_ENTRIES",
		endpoint: `employee/${employeeId}/course-group-ledger-summary?term=${month}`
	}
});

EmployeeCourseGroupLedgerOverview.propTypes = {
	linkToCourse: PropTypes.bool
}

export default (withFetchData((props) => {
	return getLedgerSummaryEntries(props.params.employeeId, props.params.month);
})(EmployeeCourseGroupLedgerOverview));
