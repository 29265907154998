import { useMediaQuery, useTheme, Box } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import withFetchData from '../../../modules/fetchData/withFetchData';
import withScopeAndSubScopes from '../../../modules/scope/withScopeAndSubScopes';
import { getScopeEvaluation } from '../../../actions/evaluationActions';
import FetchDataSwitch from '../../util/FetchDataSwitch';
import PageContentBlock from '../../page/PageContentBlock';
import { FINANCIAL_CHART } from '../../financial/dashboard/financialDashboardUtils';
import { questionOrder } from './evaluationUtils';

const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scale: {
        ticks: {
            beginAtZero: true,
            max: 10,
            stepSize: 2,
            callback(tick) {
                return tick.toFixed(1);
            },
        },
        pointLabels: {
            fontSize: 14,
            fontFamily: 'Open Sans',
        },
    },
    plugins: {
        legend: {
            display: false,
            reverse: true,
        },
        tooltip: {
            filter: tooltipItem => tooltipItem.datasetIndex === 1,
            callbacks: {
                label: ({ parsed: { y }, dataset: { label } }) => `${label}: ${y.toFixed(1)}`,
            },
        },
    },
};

function labelToMultiLine(question) {
    return question
        .split(' ')
        .reduce((acc, word, idx, src) => {
            acc.lineLength += word.length;
            acc.line.push(word);
            if (acc.lineLength > question.length / 4) {
                acc.lineLength = 0;
                acc.label.push(acc.line.join(' '));
                acc.line = [];
            } else if (idx === src.length - 1) {
                acc.label.push(acc.line.join(' '));
            }
            return acc;
        }, {
            lineLength: 0,
            line: [],
            label: [],
        }).label;
}

function CourseEvaluationsRadar({ scopeId, teacherId, load, data, loading, error, questions, scope, parentScope }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const width = isMobile ? `calc(100vw - ${theme.spacing(4)})` : `calc(100vw - 240px - ${theme.spacing(4)})`;

    useEffect(() => {
        if (scopeId && teacherId) load(getScopeEvaluation(scopeId, teacherId));
    }, [load, scopeId, teacherId]);

    return (
        <FetchDataSwitch {...{ data: [data], loading, error }}>
            {data && (
                <PageContentBlock
                    title={`${parentScope.name} - ${scope.name}`}
                    description={`Average: ${(questionOrder.reduce((a, q) => a + data[q], 0) / questionOrder.length).toFixed(1)}`}
                    contentPadding
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100vh',
                            maxHeight: 800,
                            width,
                        }}
                    >
                        <Radar
                            options={chartOptions}
                            data={{
                                labels: questions.map(labelToMultiLine),
                                datasets: [
                                    {
                                        label: 'Passing score',
                                        data: [7.99, 7.99, 7.99, 7.99, 7.99],
                                        fill: false,
                                        ...FINANCIAL_CHART.line.success,
                                        borderWidth: 1,
                                        pointRadius: 0,
                                    },
                                    {
                                        label: 'Score',
                                        data: questionOrder.map(question => data[question]),
                                        ...FINANCIAL_CHART.line.special,
                                        pointHitRadius: 10,
                                    },
                                ],
                            }}
                        />
                    </Box>
                </PageContentBlock>
            )}
        </FetchDataSwitch>
    );
}

CourseEvaluationsRadar.propTypes = {
    scopeId: PropTypes.number.isRequired,
    teacherId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    questions: PropTypes.array.isRequired,
    parentScope: PropTypes.shape({
        name: PropTypes.string,
    }),
};

export default withScopeAndSubScopes(
    withFetchData(null, {
        customId: ({ scopeId, teacherId }) => `CourseEvaluationsRadar-${scopeId}-${teacherId}`,
    })(CourseEvaluationsRadar),
    props => props.scopeId,
);
