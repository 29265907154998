import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '@mui/material/Icon'
import { formatMoneyDirect } from '../../../helpers/formatter'
import { PropTypesMoney } from '../../../helpers/money'
import Loader from '../../util/loader/Loader'
import crown_shadow from '../media/crown_shadow.png'
import glitterGold from '../media/glitter-gold.gif'

const PREFIX = 'LiveStatsBlock';

const classes = {
    root: `${PREFIX}-root`,
    value: `${PREFIX}-value`,
    label: `${PREFIX}-label`,
    comparison: `${PREFIX}-comparison`,
    comparisonLabel: `${PREFIX}-comparisonLabel`,
    comparisonValue: `${PREFIX}-comparisonValue`,
    comparisonIcon: `${PREFIX}-comparisonIcon`,
    valueNumber: `${PREFIX}-valueNumber`,
    comparisonCrown: `${PREFIX}-comparisonCrown`,
    comparisonGlitter: `${PREFIX}-comparisonGlitter`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		textAlign: 'center',
		padding: theme.spacing(1, 2),
		paddingTop: 0,
		'&.crown': {
            [`& .${classes.valueNumber}`]: {
				color: theme.palette.primary.light,
			},
		},
	},

    [`& .${classes.value}`]: {
		fontSize: '3.5em',
		fontWeight: theme.typography.fontWeightMedium,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& > div': {
			height: '1em',
		},
	},

    [`& .${classes.label}`]: {
		marginTop: theme.spacing(1.5),
		fontSize: '1.5em',
		color: theme.palette.text.primary,
		textTransform: 'uppercase',
	},

    [`& .${classes.comparison}`]: {
		fontSize: '0.4em',
		marginLeft: theme.spacing(1),
	},

    [`& .${classes.comparisonLabel}`]: {
		fontSize: '0.85em',
		color: theme.palette.text.secondary,
	},

    [`& .${classes.comparisonValue}`]: {
		marginTop: '-0.3em',
		'&.positive': {
			color: theme.palette.success.main,
		},
		'&.negative': {
			color: theme.palette.error.main,
		},
		'&.smaller': {
			fontSize: '0.85em',
		},
	},

    [`& .${classes.comparisonIcon}`]: {
		fontSize: 'inherit',
		fontWeight: theme.typography.fontWeightMedium,
		verticalAlign: '-0.15em',
	},

    [`& .${classes.valueNumber}`]: {
		position: 'relative',
	},

    [`& .${classes.comparisonCrown}`]: {
		position: 'absolute',
		top: -30,
		left: -30,
		transform: 'rotate(-25deg)',
		backgroundImage: `url(${crown_shadow})`,
		backgroundSize: '70px 70px',
		width: 70,
		height: 70,
	},

    [`& .${classes.comparisonGlitter}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundImage: `url(${glitterGold})`,
		backgroundRepeat: 'repeat',
	}
}));

const formatLiveStatsDefault = (value) => {
	return value.toLocaleString(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
}

export const formatLiveStatsBigMoney = (money) => {
	return formatMoneyDirect(money.amount || 0, money.currency, true);
}

export const formatLiveStatsPercentage = (factor) => {
	return `${formatLiveStatsDefault(factor * 100)}%`;
}

const LiveStatsBlock = ({  label, value, formatValue, comparisonLabel, comparisonValue, comparisonOnlyShow, comparisonCrown, ...rest }) => {
	const showCrown = comparisonCrown
		&& comparisonValue !== false
		&& comparisonValue !== undefined
		&& (value.amount !== undefined ? value.amount > comparisonValue.amount : value > comparisonValue);

	const showGlitter = showCrown
		&& comparisonValue !== null
		&& (value.amount !== undefined
			? (comparisonValue.amount === 0 ? false : (value.amount / comparisonValue.amount) > 2)
			: (value / comparisonValue) > 2);

	return (
        <Root className={classNames(classes.root, {
			crown: showCrown,
		})} {...rest}>
			{showGlitter && (
				<div className={classes.comparisonGlitter} />
			)}
			{value === false ? (
				<Loader inline small />
			) : (
				<div className={classes.value}>
					<div className={classes.valueNumber}>
						{formatValue ? formatValue(value) : formatLiveStatsDefault(value)}
						{showCrown && (
							<div className={classes.comparisonCrown} />
						)}
					</div>
					{(comparisonValue !== false && comparisonValue !== undefined) && (
						<div className={classes.comparison}>
							{comparisonLabel && (
								<div className={classes.comparisonLabel}>
									{comparisonLabel}
								</div>
							)}
							{comparisonOnlyShow ? (
								<div className={classNames(classes.comparisonValue, 'smaller')}>
									{formatValue ? formatValue(comparisonValue) : formatLiveStatsDefault(comparisonValue)}
								</div>
							) : (
								<div className={`${classes.comparisonValue} ${(value / comparisonValue) > 1 ? 'positive' : 'negative'}`}>
									{((value / comparisonValue) > 1) ? (
										<Icon className={classes.comparisonIcon}>arrow_upward</Icon>
									) : (
										<Icon className={classes.comparisonIcon}>arrow_downward</Icon>
									)}
									{formatLiveStatsPercentage(Math.abs((value / comparisonValue) - 1))}
								</div>
							)}
						</div>
					)}
				</div>
			)}
			<div className={classes.label}>{label}</div>
		</Root>
    );
}

LiveStatsBlock.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool,
		PropTypesMoney,
	]).isRequired,
	formatValue: PropTypes.func,
	comparisonLabel: PropTypes.string,
	comparisonValue: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool,
		PropTypesMoney,
	]),
	comparisonOnlyShow: PropTypes.bool,
	comparisonCrown: PropTypes.bool,
};

LiveStatsBlock.defaultProps = {
	comparisonOnlyShow: false,
};

export default (LiveStatsBlock)
