import React from 'react'
import { styled } from '@mui/material/styles';
import withFetchData from '../../modules/fetchData/withFetchData'
import { CALL_API } from '../../setup/api'
import withDebugOnly from '../../hoc/withDebugOnly'
import { niceDateTime, timeFromNow } from '../../helpers/datetime'

const PREFIX = 'HeaderDebugInfo';

const classes = {
    debugInfo: `${PREFIX}-debugInfo`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.debugInfo}`]: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(1),
		fontSize: '0.85em',
		color: theme.palette.grey[400],
		fontStyle: 'italic',
	}
}));

const action = () => ({
	[CALL_API]: {
		type: 'VERSION',
		endpoint: 'version',
	},
});

const HeaderDebugInfo = ({ data, }) => {
	if(!data) return null;

	return (
        <Root className={classes.debugInfo}>
			<strong>API information</strong><br/>
			Version: {data['Implementation-Version']}<br/>
			Build: {timeFromNow(data['Build-Timestamp'])}<br/>
			Date: {niceDateTime(data['Build-Timestamp'])}<br/>
			Branch: {data['Git-Branch']}<br/>
		</Root>
    );
}

export default
withDebugOnly(
	(
		withFetchData(action)(
			HeaderDebugInfo
		)))
