import { Divider, ListItem, ListItemText } from '@mui/material';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteClaimFormEntry, getClaimFormEntries } from '../../../actions/declarationsActions';
import { niceDateDay } from '../../../helpers/datetime';
import { formatMoney } from '../../../helpers/formatter';
import { usePrevious } from '../../../helpers/hooks';
import withFetchData from '../../../modules/fetchData/withFetchData';
import ApiError from '../../util/ApiError';
import Loader from '../../util/loader/Loader';
import { DeclarationContexts, useDeclarationContext } from './DeclarationContext';
import DeclarationEntryAttachments from './DeclarationEntryAttachments';

function DeclarationEntries({ load, loading, data: entries, error, disabled, declaration = {} }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const context = useDeclarationContext();
	const isProfileContext = context === DeclarationContexts.PROFILE;
	const prevTotalAmount = usePrevious(declaration.totalAmount);

	useEffect(() => {
		if (prevTotalAmount?.amount !== declaration.totalAmount?.amount) {
			load(getClaimFormEntries(declaration.id));
		}
	}, [prevTotalAmount, declaration.totalAmount?.amount, load, declaration.id]);

	if (loading) {
		return (<Loader />);
	}

	const handleEntryDelete = (entry) => () => {
		if (window.confirm(t('declarations.entry.deleteConfirm'))) {
			dispatch(deleteClaimFormEntry(entry));
		}
	};

	if (error) {
		return (<ApiError error={error} />);
	}

	if (!entries) {
		return null;
	}

	return (
		<List sx={{ mb: 2 }}>
			{Array.isArray(entries) && (
				<>
					{entries.length === 0 && (
						<ListItem>
							<ListItemText primary={t('declarations.entries.noEntries')} />
						</ListItem>
					)}
					{entries.map(entry => (
						<div key={`entry_list_${entry.id}`}>
							<ListItem sx={{ paddingRight: 12 }}>
								<ListItemText
									primary={`${formatMoney(entry.costItem.amount)} - ${entry.costItem.account.description}`}
									secondary={
										`${entry.costItem.description} -
										${t('declarations.list.lastUpdated')}: ${niceDateDay(entry.costItem.lastUpdated)}`
									}
								/>
								<ListItemSecondaryAction>
									{isProfileContext && entries.length > 1 && (
										<IconButton
											onClick={handleEntryDelete(entry)}
											color="secondary"
											disabled={disabled}
										>
											<Icon>delete</Icon>
										</IconButton>
									)}
								</ListItemSecondaryAction>
							</ListItem>
							<DeclarationEntryAttachments entry={entry} />
							<Divider />
						</div>
					))}
				</>
			)}
		</List>
	);
}

DeclarationEntries.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	declaration: PropTypes.object,
	disabled: PropTypes.bool,
};

export default withFetchData(null, {
	customId: () => 'EmployeeDeclarationEntries',
})(DeclarationEntries);
