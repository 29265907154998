import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    Grid,
    TextField,
    MenuItem,
    Typography,
    Box,
} from '@mui/material';
import { clone, mapObjIndexed } from 'ramda';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { composePatchScope, editScopeTypes } from '../../../../actions/scopeEditActions';
import { getTemplatesParams } from '../../../../helpers/templates';
import withFormData from '../../../../modules/formData/withFormData';
import Alert from '../../../util/alert/Alert';
import ApiError from '../../../util/ApiError';
import LexicalEditor from '../../../util/editor/LexicalEditor';
import Loader from '../../../util/loader/Loader';

const languages = ['NL', 'EN'];

function hasVariable(params, field) {
    return Object.prototype.hasOwnProperty.call(params, field);
}

function EditTemplateModal({ modal, handleClose, workingScope, templateVariables, sections, propertiesKey, watchSubmit, saving, error, success }) {
    const { t } = useTranslation();
    const [validationError, setValidationError] = useState(null);

    const templateIndex = modal?.templateIndex;
    const templates = workingScope?.properties?.[propertiesKey];
    const template = (templateIndex || templateIndex === 0) ? templates[templateIndex] : undefined;

    const [data, setData] = useState({
        language: 'NL',
        ...template,
    });

    const handleChangeField = useCallback((e) => {
        setData(d => ({
            ...d,
            [e.target.name]: e.target.value,
        }));
    }, []);

    const handleChangeEditor = useCallback((content, md) => {
        setData(d => ({
            ...d,
            content: md,
        }));
    }, []);

    const handleSave = useCallback(() => {
        setValidationError(null);
        const properties = clone(workingScope.properties);

        if (!data.label) {
            setValidationError('Label is a required field.');
            return;
        }

        if (sections && !data.section) {
            setValidationError('Section is a required field.');
            return;
        }

        if (!data.content) {
            setValidationError('Template should have content.');
            return;
        }

        if (!template) {
            // New template
            properties[propertiesKey] = [
                ...(properties[propertiesKey] || []),
                {
                    ...data,
                },
            ];
        } else {
            // Existing template
            properties[propertiesKey][templateIndex] = {
                ...data,
            };
        }

        watchSubmit(composePatchScope(editScopeTypes.COMPANY)(workingScope.id, {
            properties,
        }));
    }, [data, watchSubmit, workingScope, template, propertiesKey, templateIndex, sections]);

    const handleDelete = useCallback(() => {
        const properties = clone(workingScope.properties);

        if (!template) return;

        properties[propertiesKey].splice(templateIndex, 1);

        watchSubmit(composePatchScope(editScopeTypes.COMPANY)(workingScope.id, {
            properties,
        }));
    }, [watchSubmit, workingScope, template, propertiesKey, templateIndex]);

    const foundParams = getTemplatesParams(data.content, data.subject);

    if (!workingScope) return null;

    return (
        <Dialog open={true} maxWidth="lg" fullWidth scroll="body">
            {saving && (
                <Loader sheet />
            )}
            {!success && (
                <DialogTitle>
                    {template ? (
                        t('manage.company.templates.editModal.title')
                    ) : (
                        t('manage.company.templates.editModal.titleAdd')
                    )}
                </DialogTitle>
            )}
            {success ? (
                <DialogContent>
                    <Alert type={Alert.TYPE_SUCCESS}>
                        {t('manage.company.templates.editModal.success')}
                    </Alert>
                </DialogContent>
            ) : (
                <DialogContent>
                    {error && (
                        <ApiError error={error} />
                    )}
                    {validationError && (
                        <Alert type={Alert.TYPE_WARNING}>
                            {validationError}
                        </Alert>
                    )}
                    <Typography variant="body1" paragraph>
                        {t('manage.company.templates.editModal.description')}
                    </Typography>
                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={t('manage.company.templates.field.label')}
                                        value={data?.label || ''}
                                        name="label"
                                        onChange={handleChangeField}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={t('manage.company.templates.field.language')}
                                        value={data?.language || 'NL'}
                                        name="language"
                                        onChange={handleChangeField}
                                        fullWidth
                                        select
                                        required
                                    >
                                        {languages.map((l) => (
                                            <MenuItem value={l} key={l}>{l}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {!sections && (
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={t('manage.company.templates.field.subject')}
                                            value={data?.subject || ''}
                                            name="subject"
                                            onChange={handleChangeField}
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                {!sections && (
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label={t('manage.company.templates.field.replyTo')}
                                            value={data?.replyTo || ''}
                                            name="replyTo"
                                            onChange={handleChangeField}
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                {sections && sections.length > 0 && (
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('manage.company.templates.field.section')}
                                            value={data?.section || ''}
                                            name="section"
                                            onChange={handleChangeField}
                                            fullWidth
                                            select
                                            required
                                        >
                                            {sections.map((l) => (
                                                <MenuItem value={l} key={l}>{l}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <LexicalEditor
                                        content={template?.content}
                                        onChange={handleChangeEditor}
                                        output="markdown"
                                        allowedBlockTypes={['paragraph', 'ul', 'ol']}
                                        minHeight={150}
                                        disableAutoFocus
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2">
                                {t('manage.company.templates.editModal.variablesBuiltIn')}
                            </Typography>
                            {Object.values(mapObjIndexed((v) => (
                                <Box key={v} sx={hasVariable(foundParams, v) ? { fontWeight: 'bold' } : {}}>
                                    {`{${v}}`}
                                </Box>
                            ), templateVariables || {}))}
                            <Typography variant="subtitle2" sx={{ mt: 2 }}>
                                {t('manage.company.templates.editModal.variablesFound')}
                            </Typography>
                            {Object.keys(foundParams).filter((v) => !templateVariables[v]).map((v) => (
                                <Box key={v} sx={{ fontWeight: 'bold' }}>
                                    {`{${v}}`}
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
            <DialogActions sx={{ p: 3 }}>
                {!success && !!template && (
                    <Box sx={{ flex: 1 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                        >
                            {t('general.delete')}
                        </Button>
                    </Box>
                )}
                <Button onClick={handleClose}>{t('general.close')}</Button>
                {!success && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        {t('general.save')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

EditTemplateModal.propTypes = {
    templateVariables: PropTypes.object.isRequired,
    sections: PropTypes.array,
    propertiesKey: PropTypes.string.isRequired,
};

export default withFormData({
    customId: ({ workingScope }) => `EditScopeProperties-${workingScope?.id}`,
})(EditTemplateModal);
