import { useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { map, indexBy, prop, path } from 'ramda';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper/Paper';
import { formatMoneyDirect } from '../../../helpers/formatter';
import withFetchData from '../../../modules/fetchData/withFetchData';
import withWorkingScope from '../../../modules/scope/withWorkingScope';
import { niceDateYear } from '../../../helpers/datetime';
import { getIncomeForecast } from '../../../actions/dashboardActions';
import Loader from '../../util/loader/Loader';
import { FINANCIAL_CHART } from './financialDashboardUtils';

const chartOptions = {
    spanGaps: true,
    elements: {
        line: {
            tension: .25
        }
    },
    annotation: {
        annotations: [
            {
                type: 'line',
                mode: 'vertical',
                scaleID: 'x',
                value: new Date(),
                borderColor: 'red',
                label: {
                    content: 'Today',
                    display: true,
                    position: 'start',
                },
            },
        ],
    },
};

function IncomePrognosis({ scope, load, currency, incomePrognosis, filteredValues, keys }) {
    const theme = useTheme();
    const wideScreen = useMediaQuery(theme.breakpoints.up('md'));
    const fetchData = useCallback(() => {
        load(getIncomeForecast(scope.id));
    }, [load, scope.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData, scope.id]);

    const finalChartOptions = useMemo(() => ({
        ...chartOptions,
        plugins: {
            tooltip: {
                callbacks: {
                    title: (value) => niceDateYear(value[0].parsed.x),
                    label: (value) => value.parsed.y ? formatMoneyDirect(value.parsed.y, currency, true) : 'no data',
                },
                mode: 'index',
                position: 'average',
            },
        },
        scales: {
            x: {
                type: 'time',
            },
            y: {
                position: 'left',
                ticks: {
                    callback(tick) {
                        return formatMoneyDirect(tick, currency, true);
                    },
                },
            },
            secondAxis: {
                position: 'right',
                ticks: {
                    callback(tick) {
                        return formatMoneyDirect(tick, currency, true);
                    },
                },
            },
        },
    }), [currency]);

    const renderChart = useCallback(() => {
        // Do only show loader at first load, because I like the way how the charts update dynamically
        // better than having the loader appear all the time.
        if (!incomePrognosis) return <Loader />;

        const datasets = [
            {
                label: 'Accumulated Revenue',
                data: map(path(['accumulatedRevenue', 'amount']), filteredValues),
                ...FINANCIAL_CHART.line.neutral,
                type: 'line',
            },
            {
                label: 'Projected Revenue',
                data: map(path(['accumulatedProjectedRevenue', 'amount']), filteredValues),
                ...FINANCIAL_CHART.line.success,
                fill: false,
                type: 'line',
            },
            {
                label: 'Revenue',
                data: map(path(['realisedRevenue', 'amount']), filteredValues),
                ...FINANCIAL_CHART.bar.neutral,
                yAxisID: 'secondAxis',
            },
            {
                label: 'Prognosis',
                data: map(path(['projectedRevenue', 'amount']), filteredValues),
                ...FINANCIAL_CHART.bar.special,
                yAxisID: 'secondAxis',
            },
        ];

        return (
            <Bar
                data={{
                    labels: keys,
                    datasets,
                }}
                options={finalChartOptions}
                height={wideScreen ? 60 : 100}
                width={150}
            />
        );
    }, [finalChartOptions, incomePrognosis, filteredValues, keys, wideScreen]);

    return (
        <Paper style={{ padding: 10 }}>
            <Typography variant="subtitle1" gutterBottom>
                Income Prognosis
            </Typography>
            {renderChart()}
        </Paper>
    );
}

IncomePrognosis.propTypes = {
    currency: PropTypes.string,
    incomePrognosis: PropTypes.array,
    filteredValues: PropTypes.array,
    keys: PropTypes.array,
};

const id = 'incomePrognosis';
const mapStateToProps = state => {
    const incomePrognosis = state.fetchData[id] && state.fetchData[id].success;

    const filteredValues = incomePrognosis
		&& incomePrognosis.slice(incomePrognosis.length - 121, incomePrognosis.length);
    const groupedValues = filteredValues && indexBy(prop('orderDateTime'), filteredValues);
    const keys = groupedValues && Object.keys(groupedValues);

    return {
        incomePrognosis, filteredValues, keys,
    };
};

export default withWorkingScope(
    withFetchData(null, {
        customId: () => id,
        mapStateToProps,
    })(IncomePrognosis),
);
