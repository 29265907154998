import { MobileDatePicker } from '@mui/x-date-pickers';
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import { Schemas } from '../../actions/schemas'
import { CALL_API } from '../../setup/api'
import withFormData from '../../modules/formData/withFormData'
import withScopeAndSubScopes from '../../modules/scope/withScopeAndSubScopes'
import { fetchDataAppend } from '../../modules/fetchData/fetchDataActions'
import { getISO } from '../../helpers/datetime'
import { getApiTypeSuccess } from '../../helpers/types'
import withValidation, { Joi } from '../../hoc/withValidation'
import Loader from '../util/loader/Loader'
import ApiError from '../util/ApiError'
import Alert from '../util/alert/Alert'
import ScopePickerCourseList from '../scope/picker/ScopePickerCourseList'

const ApiAction = (data) => (dispatch) => {
	return dispatch({
		[CALL_API]: {
			type: 'BUNDLE_ADD_FORM',
			endpoint: `athenastudies-course-bundle`,
			schema: Schemas.COURSE_BUNDLE,
			body: data,
		},
	}).then(res => {
		if(res.type === getApiTypeSuccess('BUNDLE_ADD_FORM')){
			dispatch(fetchDataAppend(
				'BundleList',
				res && res.response && res.response.result,
				true
			));
		}

		return res;
	});
};

const today = moment();

const BundleAddForm = ({ scope, subScopes, watchSubmit, saving, error, success, validationAll, onValidationError, validation, handleFormError, refFormSubmit, setSavable, courseId }) => {
	const [description, setDescription] = useState('');
	const [expires, setExpires] = useState(moment().add(2, 'month').toISOString());
	const [discountPercentage, setDiscountPercentage] = useState(0);
	const [courses, setCourses] = useState(courseId ? [courseId] : []);

	const scroll = useRef(null);

	useEffect(() => {
		setSavable(!success);
	}, [success]);

	function toggleCourse(id){
		if(courses.indexOf(id) > -1){
			setCourses(courses.filter(c => c !== id));
		}else{
			setCourses([...courses, id]);
		}
	}

	const handleSubmit = (e) => {
		if(e) e.preventDefault();

		if(scroll.current){
			scroll.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}

		if(courses.length <= 1){
			return handleFormError('You must select at least two courses for this bundle. Later you can still add more courses or remove some.');
		}

		const data = {
			description,
			expires: moment(expires).toISOString(true),
			discountPercentage,
			courses,
		};

		validationAll(data, (containsError, errors) => {
			if (containsError) {
				if (onValidationError) onValidationError(errors)

				handleFormError('Not all fields were filled correctly.')
				return
			}

			watchSubmit(ApiAction({
				...data,
				discountPercentage: data.discountPercentage / 100,
			}));
		});
	};

	refFormSubmit.current = handleSubmit;

	const handleChangeExpireDate = date => setExpires(getISO(moment(date)));
	const handleChangeDescription = e => setDescription(e.target.value);
	const handleChangeDiscount = e => setDiscountPercentage(e.target.value);

	if(!scope) return (
		<Loader />
	);

	if(success) return (
		<Alert type={Alert.TYPE_SUCCESS}>
			The bundle was successfully added.
		</Alert>
	);

	if(scope._class !== 'Course') return (
		<Alert type={Alert.TYPE_WARNING}>
			The selected scope is not a study. Can only add bundles on studies.
		</Alert>
	);

	return (
		<form onSubmit={handleSubmit}>
			<div ref={scroll} />

			{saving && (
				<Loader />
			)}

			<Grid container spacing={2}>
				{error && (
					<Grid item xs={12}>
						<ApiError error={error} />
					</Grid>
				)}

				<Grid item sm={12}>
					<Grid container spacing={2}>
						<Grid item md={4} xs={12}>
							<TextField
								label="Title"
								placeholder="Name of this bundle visible to customers"
								required
								fullWidth
								value={description}
								onChange={handleChangeDescription}
								error={Boolean(validation['description'])}
								helperText={validation['description']}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<TextField
								label="Discount percentage"
								required
								fullWidth
								value={discountPercentage}
								onChange={handleChangeDiscount}
								error={Boolean(validation['discountPercentage'])}
								helperText={validation['discountPercentage']}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<MobileDatePicker
								inputFormat={'dd D MMMM YYYY'}
								okLabel={'Choose'}
								cancelLabel={'Cancel'}
								mode={'landscape'}
								showToolbar={false}
								value={moment(expires, moment.ISO_8601).toDate()}
								minDate={today}
								onChange={handleChangeExpireDate}
								closeOnSelect
								componentsProps={{
									actionBar: {
										actions: ['cancel'],
									},
								}}
								renderInput={(props) => (
									<TextField
										{...props}
										label={'Bundle applicable until'}
										required
										fullWidth
									/>
								)}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={12}>
					<br />
					<Divider />
				</Grid>
				<Grid item md={12} sm={12}>
					{Boolean(validation['courses']) && (
						<Alert>{validation['courses']}</Alert>
					)}
					<ScopePickerCourseList
						study={scope}
						onSelectCourse={(course) => toggleCourse(course.id)}
						multi={courses}
					/>
				</Grid>
			</Grid>
		</form>
	);
}

BundleAddForm.propTypes = {
	id: PropTypes.number.isRequired,
	courseId: PropTypes.number,
	refFormSubmit: PropTypes.object,
	setSavable: PropTypes.func,
};

export default withScopeAndSubScopes(withValidation(withFormData({
	customId: () => 'BundleAddForm',
})(BundleAddForm), {
	description: Joi.string().required(),
	discountPercentage: Joi.number().integer().min(1).max(100).required(),
	expires: Joi.string().required(),
	courses: Joi.array().min(1).required(),
}))
