import { styled } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm';
import { requestLostPassword } from '../../modules/auth/authActions';
import Loader from '../util/loader/Loader';
import Alert from '../util/alert/Alert';

const Root = styled('div')(({ theme }) => ({
	margin: theme.spacing(4, 0),
	background: '#fefefe',
	border: '1px solid #f6f6f6',
	boxShadow: theme.shadows[2],
	padding: theme.spacing(3),

	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
	},

	'& > h1': {
		color: theme.palette.primary.main,
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},
}));

const LostPasswordForm = ({ fields, onChangeField, handleSubmit, saving, error, success, validation, validationBlur, validationAll }) => {
	if (success) return (
		<Root>
			<Alert type={Alert.TYPE_SUCCESS}>
				If this email exists in our systems, a mail will be send shortly. Follow the instruction in the email. If you don't receive an email, please check your spam folder.
			</Alert>
		</Root>
	);

	return (
		<Root>
			{ saving && <Loader sheet /> }
			{
				error &&
				<Alert type={Alert.TYPE_WARNING}>
					ERROR {error.error}
				</Alert>
			}
			<form onSubmit={handleSubmit(() => fields, requestLostPassword, validationAll)}>
				<p>Request a new password for your account. You will receive an email with further instructions.</p>

				<TextField label="E-mail"
						   name="email"
						   fullWidth
						   value={fields.email}
						   onChange={onChangeField}
						   error={!!validation['email']}
						   helperText={validation['email']}
						   onBlur={validationBlur} />

				<br/><br/>
				<Button variant="contained"
						type="submit"
						color="primary"
						fullWidth
						disabled={saving}>
					{saving ? 'Requesting...' : 'Request'}
				</Button>
			</form>
		</Root>
	);
}

export default withCompleteBasicForm(LostPasswordForm, {
	email: Joi.string().email({
		minDomainAtoms: 2,
	}).required(),
})
