import { compose } from 'ramda';
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import withFetchData from '../../modules/fetchData/withFetchData'
import { getSubScopes } from '../../modules/scope/scopeActions'
import withFormData from '../../modules/formData/withFormData'
import FormDataError from '../../modules/formData/FormDataError'
import { scopeParentsToBreadcrumbs } from '../../helpers/scope'
import { deleteScope } from '../../actions/scopeEditActions'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import withRouter from '../../hoc/withRouter'
import PropertiesTable from '../util/PropertiesTable'

const scopeNames = {
	'Institute': 'institute',
	'Faculty': 'faculty',
	'Course': 'study',
	'AthenaStudiesCourse': 'course',
	'AthenaStudiesCourseGroup': 'group',
};

class ScopeDeleteModal extends Component {
	render(){
		const { data: scope, success } = this.props;

		return (
			<Dialog
				aria-labelledby="dialog-title"
				open={true}
				maxWidth="sm"
				fullWidth
				onClose={this.handleClose}
			>
				{this.renderContent()}
				<DialogActions>
					<Button onClick={this.handleClose}>
						{success ? 'Close' : 'Cancel' }
					</Button>
					{!success && (
						<Button
							onClick={this.handleDelete}
							variant="contained"
							color="secondary"
							disabled={!Boolean(scope)}
						>
							Delete {scope && scopeNames[scope._class]}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}

	renderContent(){
		const { fetchError, loading, data: scope, saving, formError, success } = this.props;

		if(fetchError) return (
			<Alert type={Alert.TYPE_WARNING}>
				{fetchError.error}
			</Alert>
		);

		if(success) return (
			<DialogContent>
				<Alert type={Alert.TYPE_SUCCESS}>
					Scope was successfully deleted. You might have to refresh the page in order to update the data in your browser.
				</Alert>
				<p>Navigate to the <Link to="/portal/planner">planner</Link>.</p>
			</DialogContent>
		);

		if(loading || !scope) return (
			<Loader />
		);

		return (
			<Fragment>
				{saving && <Loader sheet />}
				<DialogTitle id="dialog-title">Delete{scope && ` ${scopeNames[scope._class]}`} {scope.name}</DialogTitle>
				<DialogContent>
					<FormDataError error={formError} />
					Are you sure you want to <strong>try</strong> to delete this scope? In case there is any important data within this scope like orders or contract, the system will prevent deletion nevertheless.
				</DialogContent>
				<DialogContent>
					<PropertiesTable items={[
						{ label: 'Name', content: scope.name },
						{ label: 'Type', content: scopeNames[scope._class] },
						{ label: 'Parents', content: scopeParentsToBreadcrumbs(scope.id, null, null).map(s => s.label).join(' / ') },
					]} />
				</DialogContent>
			</Fragment>
		)
	}

	handleDelete = () => {
		const { watchSubmit, data: scope } = this.props;

		watchSubmit(deleteScope(scope.id, scope._class));
	}

	handleClose = () => {
		const { handleClose, navigate, success, modal } = this.props;

		if(success){
			if(modal && modal.customSuccessUrl){
				navigate(modal.customSuccessUrl, { replace: true });
			}else{
				navigate('/portal/planner', { replace: true });
			}
		}else{
			handleClose();
		}
	}
}

ScopeDeleteModal.propTypes = {
	scopeId: PropTypes.number,
	customSuccessUrl: PropTypes.string,
}

export default compose(
	withRouter,
	withFetchData((props) => {
		if(props.scopeId || props.modal.scopeId){
			return getSubScopes(props.scopeId || props.modal.scopeId);
		}
		return null;
	}, {
		mapEntities: 'scopes',
		customId: () => 'ScopeDeleteModal',
	}),
	withFormData(),
)(ScopeDeleteModal);
