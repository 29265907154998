import React, { Component } from 'react'
import { connect } from 'react-redux'
import getDisplayName from 'react-display-name'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {
	workingScopeClear,
	workingScopeSelect,
	topLevelScopeSelect,
	topLevelScopeClear,
	workingScopeSelectList
} from './scopeActions'

const cachedWorkingScopes = {input: null, output: null};
const getScopes = (state) => {
	if(state.root.workingScopes !== cachedWorkingScopes.input){
		cachedWorkingScopes.input = state.root.workingScopes;
		cachedWorkingScopes.output = state.root.workingScopes.map(
			id => state.entities.scopes[id]
		).filter(Boolean);
	}else{
		const test = state.root.workingScopes.map(
			id => state.entities.scopes[id]
		).filter(Boolean);

		test.forEach((scope, i) => {
			if(scope !== cachedWorkingScopes.output[i]){
				cachedWorkingScopes.output = test;
			}
		});
	}

	return cachedWorkingScopes.output;
}

const cachedTopLevelScopes = {input: null, output: null};
const getTopLevelScopes = (state) => {
	if(state.root.topLevelScopes !== cachedTopLevelScopes.input){
		cachedTopLevelScopes.input = state.root.topLevelScopes;
		cachedTopLevelScopes.output = state.root.topLevelScopes.map(
			id => state.entities.scopes[id]
		);
	}else{
		const test = state.root.topLevelScopes.map(
			id => state.entities.scopes[id]
		);

		test.forEach((scope, i) => {
			if(scope !== cachedTopLevelScopes.output[i]){
				cachedTopLevelScopes.output = test;
			}
		});
	}

	return cachedTopLevelScopes.output;
}

const workingScope = (WrappedComponent, useKey) => {
	const Enhanced = connect((state) => {
		const scopes = getScopes(state);

		return {
			workingScopes: scopes,
			// TODO: might need null/false here depending on the loading state?
			// nothing loaded was null, nothing selected was false
			workingScope: scopes.length > 0 ? scopes[scopes.length -1] : null,
			topLevelScopes: getTopLevelScopes(state),
			topLevelScope: state.root.topLevelScope && state.entities.scopes[state.root.topLevelScope],
		};
	}, {
		workingScopeSelect,
		workingScopeSelectList,
		workingScopeClear,
		topLevelScopeSelect,
		topLevelScopeClear,
	})(class WorkingScope extends Component {
		static displayName = `withWorkingScope(${getDisplayName(WrappedComponent) || 'Unknown'})`;

		render(){
			const { workingScopes, workingScope, topLevelScopes, topLevelScope, workingScopeSelect, workingScopeSelectList, workingScopeClear, topLevelScopeSelect, topLevelScopeClear } = this.props;

			const props = {
				scope: workingScope, // TODO: probably deprecate in favor of more clear key below
				workingScope: workingScope,
				workingScopes: workingScopes,
				topLevelScopes: topLevelScopes,
				topLevelScope: topLevelScope,
				workingScopeSelect: workingScopeSelect,
				workingScopeSelectList: workingScopeSelectList,
				workingScopeClear: workingScopeClear,
				topLevelScopeSelect: topLevelScopeSelect,
				topLevelScopeClear: topLevelScopeClear,
			};

			if(useKey){
				props.key = workingScope ? workingScope.id : '0';
			}

			return (
				<WrappedComponent {...this.props} {...props} />
			);
		}
	});

	return hoistNonReactStatics(Enhanced, WrappedComponent);
}

export default workingScope
