import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import withFetchData from '../../modules/fetchData/withFetchData'
import {niceDateYear} from '../../helpers/datetime'
import { formatMoney } from '../../helpers/formatter'
import {getContracts} from '../../actions/employeeActions'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import ContractViewModal from './ContractViewModal'

function *scopeParents(scope) {
	if (scope.parent) yield *scopeParents(scope.parent)
	yield scope;
}

const contractRate = (contract) => {
	if(contract.payment) return formatMoney(contract.payment);

	if(contract.turnoverPercentage || contract.function.turnoverPercentage){
		return `${contract.turnoverPercentage || contract.function.turnoverPercentage}%
		 / ${contract.bonusTurnoverPercentage || contract.function.bonusTurnoverPercentage}%`
	}

	if(contract.profitPercentage)
		return `${contract.profitPercentage}%`;

	return `${formatMoney(contract.baseSalary || contract.function.baseSalary)} / ${formatMoney(contract.bonusSalary || contract.function.bonusSalary)}`;
}

class EmployeeContracts extends Component {
	state = {
		employee: {},
		contracts: [],
		applications: []
	}

	render() {
		const { location, loading, error, data } = this.props;
		const contracts = data;

		if (loading) {
			return <Loader/>
		}

		if (error) return (
			<Alert>Error! Sorry, we could not load the data.</Alert>
		);

		return (
			<Fragment>
				<ModalManager>
					<ModalItem state="viewContract" component={ContractViewModal} onExpire={() => this.props.refresh()}/>
				</ModalManager>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell size="small">Function</TableCell>
							<TableCell size="small">Start</TableCell>
							<TableCell size="small">End</TableCell>
							<TableCell size="small">Rate</TableCell>
							<TableCell size="small">Scope</TableCell>
							<TableCell padding="checkbox"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{ contracts && contracts.length ?
							contracts.map((contract) => (
								<TableRow key={contract.id}>
									<TableCell size="small">{contract.function.name}</TableCell>
									<TableCell size="small" style={{ whiteSpace : 'nowrap'}}>{niceDateYear(contract.startDate)}</TableCell>
									<TableCell size="small" style={{ whiteSpace : 'nowrap'}}>{contract.endDate ? niceDateYear(contract.endDate) : '-'}</TableCell>
									<TableCell size="small" style={{ whiteSpace : 'nowrap'}}>
										{contractRate(contract)}
									</TableCell>
									<TableCell>
										{contract.scopes.map((functionScope) => Array.from(scopeParents(functionScope))
											.map(scope => scope.abbreviation).join(" / "))}
									</TableCell>
									<TableCell padding="checkbox" align="right">
										<IconButton component={Link} to={location.pathname} state={{ modal: 'viewContract', contract }}>
											<Icon>keyboard_arrow_right</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							))
							:
							<TableRow>
								<TableCell>No results</TableCell>
							</TableRow>

						}
					</TableBody>
				</Table>
			</Fragment>
		)
	}

}


export default withFetchData((props) => {
	return getContracts(props.params.employeeId)
})(EmployeeContracts)
