import React, { Component } from 'react'
import { mergeAll, clone, mapObjIndexed } from 'ramda'

const defaultOptions = {
	fields: [],
	cleanOnSuccess: false,
	convertOnChange: null,
};

const withSimpleState = (ComposedComponent, extraOptions) => {
	const options = {...defaultOptions, ...extraOptions};
	const defaultFields = mergeAll(
		options.fields.map(
			field => ({[field]: ''})
		)
	);

	const getDefaultFields = (props) => {
		if(props.inputData) return clone(props.inputData);

		if((!extraOptions || !extraOptions.fields) && props.validationFields){
			return mapObjIndexed(
				field => field._flags.default || '',
				props.validationFields
			);
		}

		return clone(defaultFields);
	};

	return class extends Component {
		displayName = 'withSimpleState(' + (ComposedComponent.displayName || 'Unknown') + ')';

		constructor(props){
			super(props);

			this.state = getDefaultFields(props);
		}

		render() {
			return <ComposedComponent fields={this.state}
									  onChangeField={this.handleChange}
									  onChangeFieldDirect={this.handleChangeField}
									  {...this.props} />;
		}

		componentDidUpdate(prevProps){
			if(options.cleanOnSuccess && !prevProps.success && this.props.success){
				this.setState(getDefaultFields(this.props));
			}
		}

		handleChangeField = (field, value) => {
			const { validationFields } = this.props;

			if(validationFields[field] && validationFields[field]._flags.trim){
				value = value.trim();
			}

			this.setState({
				[field]:
					options.convertOnChange
						? options.convertOnChange(field, value)
						: value
			});
		}

		handleChange = (e) => {
			this.handleChangeField(e.target.name, e.target.value);
		}
	}
};

export default withSimpleState
