import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { infoShow, infoShowId, infoShowSlug } from './infoActions';

class InfoTooltip extends Component {
	handleClick = () => {
		const {
			infoId,
			infoSlug,
			infoTitle,
			infoContent,
			infoShowId: dispatchInfoShowId,
			infoShowSlug: dispatchInfoShowSlug,
			infoShow: dispatchInfoShow,
		} = this.props;

		if (infoId) {
			dispatchInfoShowId(infoId);
		} else if (infoSlug) {
			dispatchInfoShowSlug(infoSlug);
		} else if (infoTitle && infoContent) {
			dispatchInfoShow(infoTitle, infoContent);
		}
	};

	render() {
		const { title, floatRight, className } = this.props;

		return (
			<IconButton
				title={title}
				color="primary"
				onClick={this.handleClick}
				sx={floatRight ? {
					float: 'right',
					ml: 1,
					mt: 1,
				} : {}}
				className={className}
			>
				<Icon>info_outline</Icon>
			</IconButton>
		);
	}
}

InfoTooltip.propTypes = {
	infoId: PropTypes.number,
	infoSlug: PropTypes.string,
	title: PropTypes.string,
	floatRight: PropTypes.bool,
	infoTitle: PropTypes.string,
	infoContent: PropTypes.string,
	className: PropTypes.string,
	infoShowId: PropTypes.func,
	infoShowSlug: PropTypes.func,
	infoShow: PropTypes.func,
};

export default connect(null, {
	infoShowId,
	infoShowSlug,
	infoShow,
})(InfoTooltip);
