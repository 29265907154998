import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const EditFunctionNumberTextField = ({ field, fields, onChangeFieldDirect, validation }) => {
	const handleChangeNumber = (event) => {
		const { value, name } = event.target;
		// eslint-disable-next-line no-param-reassign
		const valueToUse = !Number.isNaN(value) && value !== '' ? parseFloat(value) : null;
		onChangeFieldDirect(name, valueToUse);
	};

	return (
		<TextField
			type="number"
			InputLabelProps={{
				shrink: true,
			}}
			name={field}
			value={fields[field] ?? ''}
			onChange={handleChangeNumber}
			inputProps={{
				min: 0,
				max: 10,
			}}
			error={!!validation[field]}
			helperText={validation[field]}
			style={{ width: '150px' }}
		/>
	);
};

EditFunctionNumberTextField.propTypes = {
	field: PropTypes.string.isRequired,
	fields: PropTypes.object.isRequired,
	onChangeFieldDirect: PropTypes.func.isRequired,
};

export default EditFunctionNumberTextField;
