import * as queryString from 'query-string';

export function updateRouteWithSearch(navigate, param) {
	const searchFromRouter = queryString.parse(window.location.search, { parseNumbers: true });
	const newSearch = { ...searchFromRouter, ...param };

	navigate(`${window.location.pathname}?${queryString.stringify(newSearch)}`);
}

export function clearRouteSearch(navigate, location) {
	if (!location) return;

	navigate(location.pathname);
}
