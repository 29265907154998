import React, { PureComponent } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { reduce } from 'ramda';
import withSubScopesNoLoading from '../../../modules/scope/withSubScopesNoLoading';
import { isCancelledOrClosed } from '../../../helpers/groupState';
import { formatMoney } from '../../../helpers/formatter';
import { dateIsInRange } from '../../../modules/scopeFilter/scopeFilterHelpers';
import ScopeInvisibilityIndicator from '../../util/ScopeInvisibilityIndicator';
import ScopeAthenaStudiesCourseGroup from './ScopeAthenaStudiesCourseGroup';

const PREFIX = 'ScopeAthenaStudiesCourse';

const classes = {
    course: `${PREFIX}-course`,
    handle: `${PREFIX}-handle`,
    handlePurple: `${PREFIX}-handlePurple`,
    handleLink: `${PREFIX}-handleLink`,
    groups: `${PREFIX}-groups`,
    table: `${PREFIX}-table`,
    noGroups: `${PREFIX}-noGroups`,
    priceLabel: `${PREFIX}-priceLabel`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.course}`]: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		fontSize: theme.typography.fontSize,
		'& a': {
			color: 'inherit',
		},
		'&.draft': {
            [`& .${classes.handle}`]: {
				opacity: 0.7,
				backgroundColor: theme.palette.grey[300],
				borderBottomColor: theme.palette.grey[400],
			},
            [`& .${classes.groups}`]: {
				opacity: 0.7,
				backgroundColor: theme.palette.grey[300],
				'& .draft': {
					opacity: 1, // Reset opacity to prevent double opacity on the group
				},
			},
		},
	},

    [`& .${classes.handle}`]: {
		position: 'relative',
		border: `1px solid ${theme.palette.grey[300]}`,
		borderLeftWidth: 0,
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.common.white,
	},

    [`& .${classes.handlePurple}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: `${parseInt(theme.spacing(1), 10) - 1}px`,
		backgroundColor: theme.palette.primary.light,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderTopLeftRadius: theme.shape.borderRadius,
	},

    [`& .${classes.handleLink}`]: {
		display: 'block',
		padding: theme.spacing(1.5, 1),
		paddingLeft: theme.spacing(2),
	},

    [`& .${classes.groups}`]: {
		backgroundColor: theme.palette.common.white,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderBottomRightRadius: theme.shape.borderRadius,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		zIndex: -1,
		boxShadow: theme.shadows[1],
	},

    [`& .${classes.table}`]: {
		width: '100%',
	},

    [`& .${classes.noGroups}`]: {
		display: 'block',
		padding: theme.spacing(1),
		fontSize: '0.75rem',
	},

    [`& .${classes.priceLabel}`]: {
		display: 'inline-block',
		marginLeft: theme.spacing(1),
		borderRadius: '8px',
		padding: '0 6px',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	}
}));

export function allGroupsCancelledOrClosed(subScopes) {
	if (!subScopes || subScopes.length === 0) return true;

	return reduce((acc, scope) => acc + (isCancelledOrClosed(scope.state) ? 0 : 1),
		0, subScopes) === 0;
}

function anyGroupInRange(subScopes, range) {
	if (!subScopes || subScopes.length === 0) return true;

	return reduce((acc, group) => {
		// In case there are timeslots, check only those
		if (group.numTimeSlots > 0) {
			if (dateIsInRange(group.courseStart, range)) return acc + 1;
			if (dateIsInRange(group.courseEnd, range)) return acc + 1;

			return acc;
		}

		// Without timeslots, if not cancelled/closed, show
		if (!isCancelledOrClosed(group.state)) {
			return acc + 1;
		}

		// Without any other data available, check if scope created date is in range
		return acc + (dateIsInRange(group.created, range) ? 1 : 0);
	}, 0, subScopes) > 0; // Show if acc ends up > 0
}

function scopeContainsSearch(scope, search) {
	return scope.name.toLowerCase().indexOf(search) > -1
		|| scope.abbreviation.toLowerCase().indexOf(search) > -1
		|| (scope.description && scope.description.toLowerCase().indexOf(search) > -1);
}

export function selfOrGroupsContainsSearch(scope, subScopes, search) {
	// If scope contains search, show
	if (scopeContainsSearch(scope, search)) return true;

	// If any group contains search, show
	return reduce((acc, group) => acc + (scopeContainsSearch(group, search) ? 1 : 0), 0, subScopes || []) > 0;
}

class ScopeAthenaStudiesCourse extends PureComponent {
	render() {
		const { scope, subScopes,  filter, t } = this.props;

		if (filter.hideClosed && allGroupsCancelledOrClosed(subScopes)) return null;

		if (filter.onlyVisible && scope.published !== 'published') return null;

		if (!!filter.properties && Object.keys(filter.properties).length > 0) {
			const match = Object.keys(filter.properties).every(propKey => {
				const value = filter.properties[propKey];
				if (Array.isArray(value)) {
					return value.includes(scope.properties?.[propKey]);
				}
				return !value;
			});
			if (!match) return null;
		}

		if (filter.search && !selfOrGroupsContainsSearch(scope, subScopes, filter.search)) return null;

		if (!anyGroupInRange(subScopes, filter.range)) return null;

		return (
            <Root className={`${classes.course} ${scope.published}`}>
				<div className={classes.handle}>
					<div className={classes.handlePurple} />
					<Link className={classes.handleLink} to={`/portal/planner/course/${scope.id}`}>
						{scope.name}
						<span className={classes.priceLabel}>{formatMoney(scope.price)}</span>
						<ScopeInvisibilityIndicator scope={scope} right big />
					</Link>
				</div>
				<div className={classes.groups}>
					{subScopes && subScopes.length > 0 ? (
						<table className={classes.table}>
							<tbody>
								{/* eslint-disable-next-line array-callback-return,consistent-return */}
								{subScopes.map((subScope) => {
									if (subScope._class === 'AthenaStudiesCourseGroup') return (
										<ScopeAthenaStudiesCourseGroup
											key={subScope.id}
											scope={subScope}
											parentScope={scope}
										/>
									);
								})}
							</tbody>
						</table>
					) : (
						<em className={classes.noGroups}>
							{t('scope.planner.noGroups')}
						</em>
					)}
				</div>
			</Root>
        );
	}
}

ScopeAthenaStudiesCourse.propTypes = {
	scope: PropTypes.object.isRequired,
	filter: PropTypes.object,
};

export default (withTranslation()(withSubScopesNoLoading(ScopeAthenaStudiesCourse)));
