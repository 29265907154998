import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import LocationItemCard from './LocationItemCard'
import LocationSelectRoomCreate from './LocationSelectRoomCreate'

const PREFIX = 'LocationSelectRoom';

const classes = {
    noRooms: `${PREFIX}-noRooms`,
    header: `${PREFIX}-header`,
    search: `${PREFIX}-search`,
    icon: `${PREFIX}-icon`,
    topItem: `${PREFIX}-topItem`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.noRooms}`]: {
		fontStyle: 'italic',
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.header}`]: {
		display: 'flex',
		marginBottom: theme.spacing(2),
	},

    [`& .${classes.search}`]: {
		flex: 1,
	},

    [`& .${classes.icon}`]: {
		marginRight: theme.spacing(1),
	},

    [`& .${classes.topItem}`]: {
		...theme.typography.body2,
		marginBottom: theme.spacing(3),
		'& > div': {
			marginTop: theme.spacing(0.5),
		},
	}
}));

const getPaymentUnit = (room) => {
	switch(room.paymentUnit){
		case 'FREE':
			return 'Free';
		case 'PER_HOUR':
			return 'Hourly';
		case 'PER_DAY':
			return 'Daily';
		default:
			return room.paymentUnit;
	}
}

const makeMeta = (room) => [
	{
		label: room.paymentUnit === 'FREE' ? '' : getPaymentUnit(room),
		value: room.paymentUnit === 'FREE' ? 'Free' : room.price?.formatted,
	},
	{
		label: 'Seats',
		value: room.seats ?? 'unknown',
		shaded: true,
	},
];

const createIsRoomInFilter = (q) => (room) => {
	q = q.toLowerCase();

	return room.name.toLowerCase().indexOf(q) > -1
		|| room.description.toLowerCase().indexOf(q) > -1;
};

const LocationSelectRoom = ({ scope, rooms, location, selected, onSelect }) => {

	const [q, setQ] = useState('');
	const [editing, setEditing] = useState(false);

	const isSelectedInList = Boolean(selected && rooms && rooms.find(room => room.id === selected.id));

	const handleChangeSearch = useCallback((e) => {
		setQ(e.target.value);
	}, [setQ]);

	const handleClearSearch = useCallback(() => {
		setQ('');
	}, [setQ]);

	const handleSelect = useCallback((room) => {
		onSelect({
			...room,
			location: location,
		});
	}, [onSelect, location]);

	const handleCreate = useCallback(() => {
		setEditing(true);
	}, [setEditing]);

	const handleClose = useCallback(() => {
		setEditing(false);
	}, [setEditing]);

	const filteredRooms = useMemo(() => {
		return (!q || q.length === 0) ? rooms : rooms.filter(createIsRoomInFilter(q));
	}, [rooms, q]);

	if(!rooms && selected) return (
        <Root>
			<div className={classes.noRooms}>
				Selected location is not within the city, hence could not load any rooms. Please select the city of the current location in case you want to selected another room from this location.
			</div>
			<LocationItemCard
				title={selected.name}
				description={selected.description}
				selected
				item={selected}
				meta={makeMeta(selected)}
			/>
		</Root>
    );

	if(!rooms) return (
		<div className={classes.noRooms}>
			Select a location first.
		</div>
	)

	return (
		<div>
			<Dialog open={editing} maxWidth="sm" fullWidth onClose={handleClose} scroll="body">
				<DialogTitle>Create new room</DialogTitle>
				<DialogContent>
					<LocationSelectRoomCreate
						scope={scope}
						location={location}
						rooms={rooms}
						onClose={handleClose}
						onSelect={onSelect}
					/>
				</DialogContent>
			</Dialog>
			<div className={classes.header}>
				<div className={classes.search}>
					<TextField
						variant="outlined"
						size="small"
						placeholder="Search room"
						value={q}
						onChange={handleChangeSearch}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleClearSearch} edge="end" disabled={q.length === 0}>
										<Icon>close</Icon>
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</div>
				<Button onClick={handleCreate}>
					<Icon className={classes.icon}>add</Icon> add new
				</Button>
			</div>
			<div>
				{Boolean(selected) && !isSelectedInList && (
					<div className={classes.topItem}>
						Currently selected item is not in the selected location
						<LocationItemCard
							title={selected.name}
							description={selected.description}
							selected
							item={selected}
							meta={makeMeta(selected)}
						/>
					</div>
				)}
				{filteredRooms.length > 0 ? filteredRooms.map(room => (
					<LocationItemCard
						key={room.id}
						title={room.name}
						description={room.description}
						selected={room.id === selected?.id}
						item={room}
						onClick={handleSelect}
						meta={makeMeta(room)}
						unavailable={!room.available}
					/>
				)) : (
					<div className={classes.noRooms}>
						{q.length > 0 ? 'No rooms found matching your search.' : 'There are no rooms for this location yet.'}
					</div>
				)}
			</div>
		</div>
	);
};

LocationSelectRoom.propTypes = {
	scope: PropTypes.object,
	rooms: PropTypes.array,
	location: PropTypes.object,
	selected: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
};

export default LocationSelectRoom
