import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

const PageContentBlockMenu = ({ links }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const menuName = `content-block-menu-${links.map(link => link.to).join('-').replace(/\\s/g, '')}`;

    return (
        <>
            <Button aria-controls={menuName} aria-haspopup="true" onClick={handleClick}>
				<Icon>more_vert</Icon>
			</Button>
            <Menu
				id={menuName}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{links.map(link => (
					<MenuItem
						key={link.label}
						component={link.to ? Link : 'a'}
						disabled={link.disabled}
						to={link.to}
						state={link.state}
						onClick={link.onClick}
						sx={{ color: 'primary.main' }}
					>
						{link.icon && (
							<Icon sx={{ mr: 1 }}>
								{link.icon}
							</Icon>
						)}
						{link.label}
					</MenuItem>
				))}
			</Menu>
        </>
    );
};

PageContentBlockMenu.propTypes = {
	links: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.node.isRequired,
			to: PropTypes.string,
			state: PropTypes.object,
			icon: PropTypes.string,
			onClick: PropTypes.func,
			basic: PropTypes.bool,
			disabled: PropTypes.bool,
	})),
};

export default PageContentBlockMenu
