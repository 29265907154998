import React, { Component } from 'react'
import Grid from '@mui/material/Grid'
import LostPasswordForm from './LostPasswordForm'

class LostPasswordPage extends Component {
	render() {
		return (
			<Grid container justifyContent="center">
				<Grid item lg={3} md={4} sm={6} xs={11}>
					<LostPasswordForm/>
				</Grid>
			</Grid>
		)
	}
}

export default LostPasswordPage
