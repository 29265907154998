import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { compose } from 'ramda'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import { getGroupMail } from '../../actions/courseGroupActions'
import { niceDateTime } from '../../helpers/datetime'
import withRouter from '../../hoc/withRouter';
import withWorkingScope from '../../modules/scope/withWorkingScope'
import withFetchData from '../../modules/fetchData/withFetchData'
import { workingScopesNameString, scopeParentsNameString } from '../../helpers/scope'
import CourseGroupOccupancyMailListModal from '../course/CourseGroupOccupancyMailListModal'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import ResponsiveTable from '../util/ResponsiveTable'
import ScopeTooltip from '../scope/tooltip/ScopeTooltip'

class EmailOverviewPage extends Component {
	render() {
		const { workingScopes, loading, error, data, workingScope, params, navigate, pagination, onPaginationChangePage, onPaginationChangePageSize } = this.props;

		if(workingScopes.length === 0) return (
			<Alert>
				Select a scope first.
			</Alert>
		)

		const groupEmailId = params.id && parseInt(params.id, 10);

		return (
			<Fragment>
				{groupEmailId && (
					<CourseGroupOccupancyMailListModal
						groupEmailId={groupEmailId}
						handleClose={() => navigate('/portal/email/overview', { replace: true })}
					/>
				)}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PageHeader>
							<PageHeaderTitle
								headline="Group email overview"
								subHeading={`Viewing group emails of: ${workingScopesNameString(workingScopes)}`}
							/>
						</PageHeader>
					</Grid>
					<Grid item xs={12}>
						<Paper>
							{error ? (
								<Alert type={Alert.TYPE_WARNING}>
									{error.error}
								</Alert>
							) : loading || !data ? (
								<Loader />
							) : (
								<ResponsiveTable>
									<TableHead>
										<TableRow>
											<TableCell>Subject</TableCell>
											<TableCell>Date</TableCell>
											<TableCell>Study and course</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.map(email => (
											<TableRow key={email.id}>
												<TableCell style={{ fontWeight: 'bold' }}>
													<Link to={`/portal/email/overview/${email.id}`}>
														{email.subject}
													</Link>
												</TableCell>
												<TableCell>{niceDateTime(email.messageCreationDateTime)}</TableCell>
												<TableCell>
													<ScopeTooltip scopeId={email.athenaStudiesCourseGroup.id}>
														<span>{scopeParentsNameString(email.athenaStudiesCourseGroup, workingScope._class)}</span>
													</ScopeTooltip>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
									{pagination && (
										<TableFooter>
											<TableRow>
												<TablePagination
													colSpan={6}
													count={pagination.total}
													rowsPerPage={pagination.pageSize}
													page={pagination.page}
													onPageChange={onPaginationChangePage}
													onRowsPerPageChange={onPaginationChangePageSize}
													rowsPerPageOptions={[10, 25, 50, 100]}
												/>
											</TableRow>
										</TableFooter>
									)}
								</ResponsiveTable>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Fragment>
		);
	}

	componentDidUpdate(prevProps){
		const { workingScope, pagination, refresh } = this.props;

		if(workingScope){
			if(!prevProps.workingScope || workingScope.id !== prevProps.workingScope.id){
				refresh();
			}
			else if(prevProps.pagination.page !== pagination.page || prevProps.pagination.pageSize !== pagination.pageSize){
				refresh();
			}
		}
	}
}

export default compose(
	withWorkingScope,
	withRouter,
	withFetchData(
		(props) => props.workingScope ? getGroupMail(props.workingScope.id) : undefined,
		{
			pagination: true,
			keepData: true,
		})
)(EmailOverviewPage)
