import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Icon from '@mui/material/Icon';
import ModalManager from '../../modal/ModalManager';
import Scopes from '../../../constants/Scopes';

const PREFIX = 'PlannerScopeActionDialog';

const classes = {
    avatarNegative: `${PREFIX}-avatarNegative`,
    metadata: `${PREFIX}-metadata`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.avatarNegative}`]: {
		backgroundColor: theme.palette.secondary.light,
	},

    [`& .${classes.metadata}`]: {
		color: theme.palette.text.disabled,
		fontSize: '0.8rem',
		paddingBottom: theme.spacing(1),
		marginTop: `-${theme.spacing(1)}`,
	}
}));

const { COMPANY, INSTITUTE, FACULTY, COURSE } = Scopes;

const childForScope = (scope) => {
	if (!scope || !scope._class) return '';

	switch (scope._class) {
		case COMPANY:
			return 'institute';
		case INSTITUTE:
			return 'faculty';
		case FACULTY:
			return 'study';
		case COURSE:
			return 'course';
		default:
			return '';
	}
};

const PlannerScopeActionDialog = ({ scope,  ...other }) => {
	if (!scope) return null;

	return (
        <StyledDialog aria-labelledby="simple-dialog-title" scroll="body" {...other} open={true}>
			<DialogTitle id="simple-dialog-title">
				{`Actions for ${scope.name}`}
			</DialogTitle>
			<DialogContent className={classes.metadata}>
				{`Scope id: #${scope.id}`}
			</DialogContent>
			<div>
				<List>
					{scope._class !== COMPANY && (
						<ListItem component={Link} button to="/portal/planner" state={{ modal: 'scopeEditor', scopeId: scope.id }} onClick={other.onClose}>
							<ListItemAvatar>
								<Avatar>
									<Icon>edit</Icon>
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Edit this scope" />
						</ListItem>
					)}
					<ListItem component={Link} button to="/portal/planner" state={{ modal: 'scopeEditor', parentId: scope.id }} onClick={other.onClose}>
						<ListItemAvatar>
							<Avatar>
								<Icon>add</Icon>
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={`Add a ${childForScope(scope)} to this scope`} />
					</ListItem>
					{scope._class === COURSE && (
						<ListItem component={Link} button to="/portal/planner" state={{ modal: 'showCalendar', iCalUrl: `scope/${scope.id}/calendar.ics` }} onClick={other.onClose}>
							<ListItemAvatar>
								<Avatar>
									<Icon>today</Icon>
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="View calendar" />
						</ListItem>
					)}
					{scope._class === COURSE && (
						<ListItem component={Link} button to="/portal/promotion/bundle" state={{ modal: 'add', id: scope.id }} onClick={other.onClose}>
							<ListItemAvatar>
								<Avatar>
									<Icon>loyalty</Icon>
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Create a product bundle" />
						</ListItem>
					)}
					{scope._class !== COMPANY && (
						<ListItem component={Link} button to="/portal/planner" state={{ modal: 'scopeDelete', scopeId: scope.id }} onClick={other.onClose}>
							<ListItemAvatar>
								<Avatar>
									<Icon>delete</Icon>
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Delete this scope" />
						</ListItem>
					)}
					<ListItem button onClick={other.onClose}>
						<ListItemAvatar>
							<Avatar className={classes.avatarNegative}>
								<Icon>close</Icon>
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="Close" />
					</ListItem>
				</List>
			</div>
		</StyledDialog>
    );
};

PlannerScopeActionDialog.propTypes = {
	scope: PropTypes.object,
	onClose: PropTypes.func.isRequired,
};

export default (PlannerScopeActionDialog);
