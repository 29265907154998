// ===========================
// Main internal actions
// ===========================
const fetchDataLoading = (id) => ({
	type: 'FETCH_DATA_LOADING',
	fetchDataId: id
});

const fetchDataError = (id, error) => ({
	type: 'FETCH_DATA_ERROR',
	fetchDataId: id,
	error: error
});

const fetchDataSuccess = (id, res, pagination, cache = undefined) => ({
	type: 'FETCH_DATA_SUCCESS',
	fetchDataId: id,
	result: res,
	pagination,
	cache,
});

const fetchDataClear = (id) => ({
	type: 'FETCH_DATA_CLEAR',
	fetchDataId: id
});

// ===========================
// Helper actions which can manipulate the
// ... results for a given fetchDataId
// ===========================
export const fetchDataAppend = (id, res, prepend = false) => ({
	type: 'FETCH_DATA_APPEND',
	fetchDataId: id,
	result: res,
	prepend: prepend,
});

export const fetchDataConcat = (id, res) => ({
	type: 'FETCH_DATA_CONCAT',
	fetchDataId: id,
	result: res
});

export const fetchDataDelete = (id, res) => ({
	type: 'FETCH_DATA_DELETE',
	fetchDataId: id,
	result: res
});

// For a given {id} within fetchData,
// it will find {findByField} in the list with the same value as that field has in {item},
// and replaces that item with {item},
// if {mergeField} is provided, it will only replace that field within the item,
// if {pathToList} is provided the fetchData{id} will be assumed to be an object and the given path is used to find the list
// ... I know, this is not the nicest implementation...
export const fetchDataReplaceBy = (id, findByField, item, mergeField = undefined, pathToList = undefined) => ({
	type: 'FETCH_DATA_REPLACE',
	fetchDataId: id,
	findByField: findByField,
	replaceItem: item,
	mergeField: mergeField,
	pathToList: pathToList,
});

// ===========================
// Internal page related actions
// ===========================
const fetchDataPage = (id, page) => ({
	type: 'FETCH_DATA_PAGE',
	fetchDataId: id,
	page: page
});

const fetchDataPageSize = (id, pageSize) => ({
	type: 'FETCH_DATA_PAGE_SIZE',
	fetchDataId: id,
	pageSize: pageSize
});

export const fetchDataInternalActions = {
	fetchDataLoading,
	fetchDataError,
	fetchDataSuccess,
	fetchDataClear,
	fetchDataPage,
	fetchDataPageSize,
}
