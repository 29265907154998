import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { sort } from 'ramda'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import withFetchData from '../../modules/fetchData/withFetchData'
import { workingScopesNameString } from '../../helpers/scope'
import { getScopeStudies } from '../../actions/scopeDataActions'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import ResponsiveTable from '../util/ResponsiveTable'

function sortMethod(a, b){
	const parentCompare = a.parent.parent.abbreviation.localeCompare(b.parent.parent.abbreviation);

	return parentCompare === 0
		? a.name.localeCompare(b.name)
		: parentCompare;
}

function getParentIdList(scope){
	if(!scope) return [];

	return [...getParentIdList(scope.parent), scope.id];
}

function getParentEmail(study){
	const company = study.parent.parent.parent;

	return company.properties && company.properties.contactEmail || 'info@athenastudies.nl';
}

class StudiesOverviewPage extends Component {
	render() {
		const { workingScopes, loading, error, data, workingScopeSelectList } = this.props;

		if(workingScopes.length === 0) return (
			<Alert>
				Select a scope first.
			</Alert>
		)

		return (
			<Fragment>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PageHeader>
							<PageHeaderTitle
								headline="Studies overview"
								subHeading={`Viewing studies of: ${workingScopesNameString(workingScopes)}`}
							/>
						</PageHeader>
					</Grid>
					<Grid item xs={12}>
						<Paper>
							{error ? (
								<Alert type={Alert.TYPE_WARNING}>
									{error.error}
								</Alert>
							) : loading || !data ? (
								<Loader />
							) : (
								<ResponsiveTable>
									<TableHead>
										<TableRow>
											<TableCell>Study name</TableCell>
											<TableCell>University</TableCell>
											<TableCell>Contact email</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{sort(sortMethod, data).map(study => (
											<TableRow key={study.id}>
												<TableCell style={{ fontWeight: 'bold' }}>
													<Link
														to={`/portal/planner`}
														onClick={() => workingScopeSelectList(getParentIdList(study))}
													>
														{study.name}
													</Link>
												</TableCell>
												<TableCell>{study.parent.parent.abbreviation}</TableCell>
												<TableCell>{study.properties && study.properties.contactEmail
													? study.properties.contactEmail
													: <em><small>Falls back to {getParentEmail(study)}</small></em>
												}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</ResponsiveTable>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Fragment>
		);
	}

	componentDidUpdate(prevProps){
		const { workingScope, refresh } = this.props;

		if(workingScope && (!prevProps.workingScope || workingScope.id !== prevProps.workingScope.id)){
			refresh();
		}
	}
}

export default withWorkingScope(withFetchData((props) =>
	props.workingScope ? getScopeStudies(props.workingScope.id) : undefined
)(StudiesOverviewPage))
