import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getProfile } from '../../actions/employeeActions';
import { capabilities } from '../../helpers/capabilities';
import withRouter from '../../hoc/withRouter';
import useAuthUser from '../../modules/auth/useAuthUser';
import withFetchData from '../../modules/fetchData/withFetchData';
import useRootCapabilities from '../../modules/scope/useRootCapabilities';
import DeclarationsPageBlock from '../financial/declarations/DeclarationsPageBlock';
import ModalItem from '../modal/ModalItem';
import ModalManager from '../modal/ModalManager';
import PageContentBlock from '../page/PageContentBlock';
import PageHeader from '../page/PageHeader';
import PageHeaderInfo from '../page/PageHeaderInfo';
import PageHeaderMenu from '../page/PageHeaderMenu';
import PageHeaderNavigation from '../page/PageHeaderNavigation';
import PageHeaderTitle from '../page/PageHeaderTitle';
import PageNoteBlock from '../page/PageNoteBlock';
import ProfileEditAddressModal from '../profile/ProfileEditAddressModal';
import ProfileEditBankModal from '../profile/ProfileEditBankModal';
import ProfileEditDetailsModal from '../profile/ProfileEditDetailsModal';
import ProfileEditEmailModal from '../profile/ProfileEditEmailModal';
import Alert from '../util/alert/Alert';
import Loader from '../util/loader/Loader';
import MobilePhoneActions from '../util/MobilePhoneActions';
import EmployeeApplications from './EmployeeApplications';
import EmployeeContracts from './EmployeeContracts';
import EmployeeLedgerSummary from './EmployeeLedgerSummary';
import PropertyPageHeaderInfo from '../properties/PropertyPageHeaderInfo';

function EmployeeOverviewPage({ loading, error, data: employee, location, params }) {
	const { hasCapability } = useRootCapabilities();

	const user = useAuthUser();
	const isSelf = employee?.id === user?.id;

	const { t } = useTranslation();
	if (error) return (
		<Alert>
			{error.errorCode === 403
				? 'Sorry, you may not view the details of this employee.'
				: 'Error! Sorry, we could not load the data.'}
		</Alert>
	);

	if (loading || !employee) return (
		<Loader />
	);

	return (
		<>
			<ModalManager>
				<ModalItem state="editDetails" component={ProfileEditDetailsModal} user={employee} />
				<ModalItem state="editEmail" component={ProfileEditEmailModal} user={employee} />
				<ModalItem state="editAddress" component={ProfileEditAddressModal} user={employee} />
				<ModalItem state="editBank" component={ProfileEditBankModal} user={employee} />
			</ModalManager>

			<PageHeader>
				<PageHeaderNavigation
					breadcrumbs={[
						{ label: 'Employees', to: '/portal/employees' },
						{ label: employee.name },
					]}
				/>
				<PageHeaderMenu
					items={[
						{ name: 'Edit details', to: location.pathname, state: { modal: 'editDetails' }, icon: 'person' },
						{ name: 'Edit address', to: location.pathname, state: { modal: 'editAddress' }, icon: 'location_on' },
						{ name: 'Edit email', to: location.pathname, state: { modal: 'editEmail' }, icon: 'mail' },
						{ name: 'Edit bank and ID', to: location.pathname, state: { modal: 'editBank' }, icon: 'payment' },
					]}
				/>
				<PageHeaderTitle headline={employee.fullName} />
				<PageHeaderInfo
					items={[
						{ label: 'Name', content: employee.fullName },
						{ label: 'Mobile', content: (<MobilePhoneActions client={employee} isEmployee />) },
						{ label: 'Email', content: employee.email },
						{ label: 'IBAN', content: `${employee.IBAN}` },
						{ label: 'Citizen service number', content: ` ${employee.identificationNumber}` },
						{
							label: 'Address',
							content: `${employee.street} ${employee.houseNumber}, ${employee.zipcode}, ${employee.city}, ${employee.country}`,
						},
					]}
				/>
				<div>
					<Button
						variant="contained"
						color="primary"
						component={Link}
						to={location.pathname}
						state={{ modal: 'editProperties' }}
						style={{ float: 'right', color: 'white' }}
					>
						Edit properties
					</Button>
					<Typography variant="subtitle1">{t('properties.title')}</Typography>
				</div>
				<PropertyPageHeaderInfo
					user={employee}
					context="employee"
				/>
			</PageHeader>

			<Grid container spacing={2}>
				{(hasCapability(capabilities.ManageEmployeesCapability) || isSelf) && (
					<Grid item xs={12}>
						<PageContentBlock
							title="Contracts"
							description="Active contracts for this employee"
							icon="school"
						>
							<EmployeeContracts params={params} location={location} />
						</PageContentBlock>
					</Grid>
				)}
				{hasCapability(capabilities.ApproveOrDeclineClaimForm) && (
					<Grid item xs={12}>
						<DeclarationsPageBlock location={location} id={employee.id} />
					</Grid>
				)}
				<Grid item xs={6}>
					{(hasCapability(capabilities.SeeEmployeeFinance) || isSelf) && (
						<PageContentBlock title="Financial overview" icon="school">
							<EmployeeLedgerSummary params={params} />
						</PageContentBlock>
					)}
				</Grid>
				<Grid item md={6} xs={12}>
					{(hasCapability(capabilities.ManageEmployeesCapability) || isSelf) && (
						<PageContentBlock
							title="Open applications"
							icon="school"
							links={[{
								label: 'New application',
								to: '/portal/applications/create',
								state: { employee },
							}]}
						>
							<EmployeeApplications params={params} />
						</PageContentBlock>
					)}
				</Grid>
				<Grid item md={6} xs={12}>
					<PageNoteBlock context="employee" contextId={employee.id} />
				</Grid>
			</Grid>
		</>
	);
}

export default withRouter(withFetchData((props) => getProfile(props.params.employeeId), {
	mapEntities: 'users',
})(EmployeeOverviewPage));
