import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'

const PREFIX = 'PageHeaderNavigation';

const classes = {
    navigation: `${PREFIX}-navigation`,
    breadcrumb: `${PREFIX}-breadcrumb`,
    backButton: `${PREFIX}-backButton`,
    iconLeft: `${PREFIX}-iconLeft`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.navigation}`]: {
		marginBottom: theme.spacing(1),
	},

    [`& .${classes.breadcrumb}`]: {
		display: 'inline-block',
		marginRight: theme.spacing(1),
		[`&+.${classes.breadcrumb}`]: {
			'&:before': {
				content: "'chevron_right'",
				fontFamily: 'Material Icons',
				verticalAlign: 'bottom',
				display: 'inline-block',
				marginRight: theme.spacing(1),
			},
		},
	},

    [`& .${classes.backButton}`]: {
		marginRight: theme.spacing(3),
	},

    [`& .${classes.iconLeft}`]: {
		marginRight: theme.spacing(1)
	}
}));

const PageHeaderNavigation = ({ breadcrumbs, backUrl, }) => {
    return (
        <Root className={classes.navigation}>
			{
				backUrl &&
				<Button component={Link}
							to={backUrl}
							className={classes.backButton} size="small">
					<Icon className={classes.iconLeft}>chevron_left</Icon> back
				</Button>
			}
			{
				breadcrumbs &&
				breadcrumbs.map((item, key) => (
					<div key={key} className={classes.breadcrumb}>
						{
							item.to &&
							<Link to={item.to} key={key} onClick={item.onClick}>
								{item.label}
							</Link> ||
							item.label
						}
					</div>
				))
			}
		</Root>
    );
};

PageHeaderNavigation.propTypes = {
	breadcrumbs: PropTypes.array,
	backUrl: PropTypes.string
};

export default (PageHeaderNavigation)
