import { dialogClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { stringify } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withFetchData from '../../modules/fetchData/withFetchData';
import { CALL_API } from '../../setup/api';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';
import LocationCitySelect from './LocationCitySelect';
import LocationSelectLocation from './LocationSelectLocation';
import LocationSelectRoom from './LocationSelectRoom';

const StyledDialog = styled(Dialog)(() => ({
    [`& .${dialogClasses.paper}`]: {
		minHeight: '90vh',
	}
}));

const apiAction = (scopeId, params) => ({
	[CALL_API]: {
		type: 'LOCATION',
		endpoint: `scope/${scopeId}/course-location?${stringify(params, {
			skipNull: true,
			skipEmptyString: true,
		})}`,
	},
});

function LocationSelectDialog({ scope, currentRoom, onClose, onSelect, startDateTime, endDateTime, timeslotId, loading, error, data, load }) {
	const [selectedLocation, setSelectedLocation] = useState(currentRoom ? currentRoom.location : null);
	const [selectedRoom, setSelectedRoom] = useState(currentRoom || null);
	const [city, setCity] = useState(null);

	const availableRooms = selectedLocation && data?.find?.(location => location.id === selectedLocation.id)?.rooms;

	const handleClear = useCallback(() => {
		onSelect(null);
	}, [onSelect]);

	const handleChangeCity = useCallback((newCity) => {
		setCity(newCity || null);
	}, [setCity]);

	const handleSelectLocation = useCallback((location) => {
		setSelectedLocation(location);
		setSelectedRoom(null);
	}, [setSelectedLocation, setSelectedRoom]);

	const handleSelectRoom = useCallback((room) => {
		setSelectedRoom(room);
	}, [setSelectedRoom]);

	const handleSave = useCallback(() => {
		onSelect(selectedRoom);
	}, [onSelect, selectedRoom]);

	useEffect(() => {
		load(apiAction(scope.id, {
			city, startDateTime, endDateTime, timeslotId,
		}));
	}, [load, scope.id, city, startDateTime, endDateTime, timeslotId]);

	return (
        <StyledDialog open={true} maxWidth="md" fullWidth>
			<DialogTitle>
				Select location and room
				<LocationCitySelect
					scopeId={scope.id}
					value={city}
					onChange={handleChangeCity}
				/>
			</DialogTitle>
			<DialogContent>
				{error && (
					<ApiError error={error} />
				)}
				{loading && (
					<Loader sheet />
				)}
				{Boolean(data) && (
					<Grid container spacing={2}>
						<Grid item sm={6} xs={12}>
							<LocationSelectLocation
								scope={scope}
								locations={data}
								selected={selectedLocation}
								onSelect={handleSelectLocation}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<LocationSelectRoom
								scope={scope}
								location={selectedLocation}
								rooms={availableRooms}
								selected={selectedRoom}
								onSelect={handleSelectRoom}
							/>
						</Grid>
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>
					Cancel
				</Button>
				{(selectedLocation || selectedRoom) && (
					<Button color="secondary" onClick={handleClear}>
						Clear location
					</Button>
				)}
				<Button variant="contained" color="primary" onClick={handleSave} disabled={!selectedLocation || !selectedRoom}>
					Pick room
				</Button>
			</DialogActions>
		</StyledDialog>
    );
}

LocationSelectDialog.propTypes = {
	scope: PropTypes.object.isRequired,
	currentRoom: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	startDateTime: PropTypes.string,
	endDateTime: PropTypes.string,
	timeslotId: PropTypes.number,
};

export default withFetchData(null, {
	customId: () => 'locationSelectDialog',
})(LocationSelectDialog);
