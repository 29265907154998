export const hasScopeCapability = (scope, capability) => scope
	&& scope.capabilities
	&& scope.capabilities.indexOf(capability) > -1;

export const capabilities = {
	ScopeAlterCapability: 'ScopeAlterCapability',
	SeeApplicationCapability: 'SeeApplicationCapability',
	SeeScopeCapability: 'SeeScopeCapability',
	AddSubScopeCapability: 'AddSubScopeCapability',
	SeePartnerCapability: 'SeePartnerCapability',
	CreateApplicationCapability: 'CreateApplicationCapability',
	SeeDiscountsCapability: 'SeeDiscountsCapability',
	ManageFinanceCapability: 'ManageFinanceCapability',
	CanTutorCapability: 'CanTutorCapability',
	ManageEmployeesCapability: 'ManageEmployeesCapability',
	GroupEmailCapability: 'GroupEmailCapability',
	CreatePartnerCapability: 'CreatePartnerCapability',
	SeeEmployeeCapability: 'SeeEmployeeCapability',
	SeeEmployeeFinance: 'SeeEmployeeFinance',
	CreateDiscountCodeCapability: 'CreateDiscountCodeCapability',
	SeeFinanceCapability: 'SeeFinanceCapability',
	SeePrognosisCapability: 'SeePrognosisCapability',
	RefundCapability: 'RefundCapability',
	ProductSwapCapability: 'ProductSwapCapability',
	AuthorizeEmployeePaymentCapability: 'AuthorizeEmployeePayment',
	FinancialReportingCapability: 'FinancialReportingCapability',
	SeeClientDetailsCapability: 'SeeClientDetailsCapability',
	AdministratorOverride: 'AdministratorOverride',
	WriteBookletCapability: 'WriteBookletCapability',
	EditClientDetailsCapability: 'EditClientDetailsCapability',
	ApproveOrDeclineClaimForm: 'ApproveOrDeclineClaimForm',
	CreateClaimForm: 'CreateClaimForm',
	SeeAttendanceCapability: 'SeeAttendanceCapability',
	ManageAttendanceCapability: 'ManageAttendanceCapability',
};
