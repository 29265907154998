import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import withDarkTheme from '../../hoc/withDarkTheme'
import HeaderHomeLink from './HeaderHomeLink'

const PREFIX = 'HeaderDrawerMenu';

const classes = {
    toolbar: `${PREFIX}-toolbar`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    spacer: `${PREFIX}-spacer`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.toolbar}`]: theme.mixins.toolbar,

    [`& .${classes.drawerPaper}`]: {
		width: theme.drawerWidth,
	},

    [`& .${classes.spacer}`]: {
		width: theme.drawerWidth,
	}
}));

const HeaderDrawerMenu = ({ open, onClose, children, }) => {
	return (
        <Root>
			<Drawer variant="temporary"
					anchor="left"
					open={open}
					onClose={onClose}
					classes={{
						paper: classes.drawerPaper,
					}}
					sx={{ display: { md: 'none' } }}
					ModalProps={{
						keepMounted: true,
					}}>
				<HeaderHomeLink className={classes.toolbar} />
				{children}
			</Drawer>
			<Box sx={{ display: { xs: 'none', md: 'block' } }}>
				<div className={classes.spacer} />
				<Drawer variant="permanent"
						open
						classes={{
							paper: classes.drawerPaper,
						}}>
					<HeaderHomeLink className={classes.toolbar} />
					{children}
				</Drawer>
			</Box>
		</Root>
    );
}

HeaderDrawerMenu.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};

export default withDarkTheme((HeaderDrawerMenu))
