import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import withFormData from "../../../modules/formData/withFormData"
import withValidation, { Joi } from '../../../hoc/withValidation'
import { scopeParentsNameString } from '../../../helpers/scope'
import {setPaymentStatus} from "../../../actions/payment"
import { paymentStatus } from '../../../helpers/payment'
import Alert from '../../util/alert/Alert'

const rejectReasons = [
	{id: 0, text: 'Reimbursement is higher than agreed upon', subText: 'The contract term or percentage may be incorrect. If this is the case, take proper action so that future payments will be correct.', custom: false},
	{id: 1, text: 'Reimbursement is lower than agreed upon', subText: 'Contract term is not correct, or courses are missing evaluation, location or teacher information. Take proper action so that future payments will be correct.', custom: false},
	{id: 2, text: 'Reimbursement occurs multiple times in the batch', subText: 'This is usually due to incorrectly administrated product bundles or courses shared across studies. Take proper action so that the bundle will eventually be marked as such which will prevent further incorrect payments.', custom: false},
	{id: 3, text: 'Compensation is waived due to breach of contract / unilateral termination', subText: '', custom: false},
	{id: 4, text: 'Other (please specify)', subText: '', custom: true},
];

class RejectPaymentForm extends Component {
	static propTypes = {
		handleClose: PropTypes.func.isRequired,
		payment: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			reason: '',
			custom: false,
			customReason: '',
			error: false,
		}
	}

	render() {
		const { validation, validationBlur, payment } = this.props;

		return (
			<form onSubmit={this.handleSubmit}>
				<Dialog open={true} onClose={this.props.handleClose}>
					<DialogTitle>Rejecting payment to {payment.employee.fullName}</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
							    <FormControl fullWidth>
									<InputLabel>Payment ID</InputLabel>
									<Input disabled value={payment.id}/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
							    <FormControl fullWidth>
									<InputLabel>Payment Scope</InputLabel>
									<Input disabled value={scopeParentsNameString(payment.scope)} />
								</FormControl>
							</Grid>
							<Grid item xs={12}>
							    <FormControl fullWidth>
									<InputLabel>Amount</InputLabel>
									<Input disabled value={payment.amount.amount} type="number"/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth
											 required
											 error={!!validation['reason']}
								>
									<InputLabel>Reject reason</InputLabel>
									<Select value={this.state.reason}
											inputProps={{name: 'reason'}}
											name="reason"
											onChange={this.handleSelectChange}
											onBlur={validationBlur}
									>
										{rejectReasons.map(reason => {
											return (
												<MenuItem value={reason.id} key={reason.id}>
													{reason.text}
												</MenuItem>
											)
										})}
									</Select>
									<FormHelperText>
										{validation['reason']}
									</FormHelperText>
								</FormControl>
							</Grid>
							{this.state.reasonSubtext && this.state.reasonSubtext.length > 0 &&
							<Grid item xs={12}>
								<Alert type={Alert.TYPE_INFO}>
									One of the reasons could be:<br/>
									{this.state.reasonSubtext}
								</Alert>
							</Grid>
							}
							{this.state.custom &&
							<Grid item xs={12}>
								<FormControl fullWidth
											 required
											 error={!!validation['customReason']}
								>
									<InputLabel>Custom reject reason</InputLabel>
									<Input fullWidth
										   name="customReason"
										   placeholder="Other (please specify)"
										   value={this.state.customReason}
										   onBlur={validationBlur}
										   onChange={this.bindState('customReason')}
									/>
									<FormHelperText>
										{validation['customReason']}
									</FormHelperText>
								</FormControl>
							</Grid>
							}
							{this.state.error &&
							<Grid item xs={12}>
								<Alert type={Alert.TYPE_DANGER}>{this.state.error}</Alert>
							</Grid>
							}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.handleClose}>Cancel</Button>
						<Button color="primary"
								type="submit"
								onClick={this.handleSubmit}
						>Reject</Button>
					</DialogActions>
				</Dialog>
			</form>
		);
	}

	bindState = (property) => {
		return (event) => {
			this.setState({
				[property]: event.target.value,
			})
		}
	};

	handleSelectChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
			custom: rejectReasons[event.target.value].custom,
			reasonSubtext: rejectReasons[event.target.value].subText,
			customReason: rejectReasons[event.target.value].custom ? '' : rejectReasons[event.target.value].text,
		});
	};

	handleSubmit = event => {
		event.preventDefault();

		return this.props.validationAll(this.state, (error) => {
			if (error) {
				if (this.props.onValidationError) {
					this.props.onValidationError(error);
				}
				console.warn("Validation errors, preventing API call", error);
				return;
			}

			return this.props.watchSubmit(
				setPaymentStatus(
					this.props.payment.id,
					paymentStatus.DISAPPROVED,
					this.state.customReason
				)
			).then(this.props.handleClose);
		})
	};
}

export default withFormData({
	customId: () => 'rejectPaymentForm',
})(withValidation(RejectPaymentForm, {
	reason: Joi.number().integer().min(0).required().label('Reject reason'),
	custom: Joi.boolean(),
	customReason: Joi.string().required().min(3).label('Custom reject reason')
		.when('custom', {is: false, then: Joi.allow('')})
}))
