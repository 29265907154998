import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { compose } from 'ramda';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen';
import PageHeader from '../page/PageHeader';
import PageHeaderTitle from '../page/PageHeaderTitle';
import PropertyEditorForm from './PropertyEditorForm';

class PropertyEditorPage extends Component {
	saveBinder = null;

	constructor(props) {
		super(props);
		this.state = {
			context: 'EmployeeListFilters',
		};

		this.onTabChange = this.onTabChange.bind(this);
	}

	onTabChange(event, tab) {
		this.setState({
			context: tab,
		});
	}

	render() {
		const { t, } = this.props;
		const { context } = this.state;

		return (
            <>
                <PageHeader>
					<PageHeaderTitle
						headline={t('properties.edit.title')}
						description={t('properties.edit.desc')}
					/>
				</PageHeader>
                <Grid container spacing={2}>
					<Grid item xs={12}>
						<Paper sx={{ p: 2}}>
							<Tabs
								value={context}
								variant="fullWidth"
								indicatorColor="primary"
								textColor="inherit"
								onChange={this.onTabChange}
								sx={{ borderBottom: '1px solid #ccc', mb: 2 }}
							>
								<Tab value="EmployeeListFilters" label={t('properties.edit.employeeListFilters')} />
								<Tab value="ClientFilter" label={t('properties.edit.clientFilter')} />
								<Tab value="ScopeFilter" label={t('properties.edit.scopeFilter')} />
							</Tabs>
							<PropertyEditorForm
								context={context}
								/* eslint-disable-next-line no-return-assign */
								bindSave={(func) => this.saveBinder = func}
							/>
						</Paper>
					</Grid>
				</Grid>
            </>
        );
	}
}

export default compose(

	withTranslation(),
	withDialogShouldBeFullscreen(),
)(PropertyEditorPage);
