import { schema } from 'normalizr'

// Define all entities and basic relations
const user = new schema.Entity('users');
const scope = new schema.Entity('scopes');
const employee = user;
const contract = new schema.Entity('contracts');
const contractFunction = new schema.Entity('functions');
const employeePayment = new schema.Entity('employeePayments');
const financeScopeReport = new schema.Entity('financeScopeReports', undefined, {
	idAttribute: report => report.scope.id,
});
const scopeCost = new schema.Entity('scopeCosts');
const courseLocation = new schema.Entity('courseLocations');
const room = new schema.Entity('rooms');
const discountCode = new schema.Entity('discountCodes');
const client = new schema.Entity('clients');
const companyFunction = new schema.Entity('companyFunctions');
const contractTemplate = new schema.Entity('contractTemplates');
const timeSlot = new schema.Entity('timeSlots');
const courseBundle = new schema.Entity('courseBundles');
const functionCapabilitiesSchemaCreator = (functionId) => new schema.Entity('functionCapabilities', undefined, {
	idAttribute: () => functionId,
	processStrategy: (entity => ({
		id: functionId,
		capabilities: entity,
	})),
});

// Define scope circular relation
scope.define({
	subScopes: [scope],
	parent: scope
});

contract.define({
	employee,
	scopes: [scope],
	'function': contractFunction
});

courseLocation.define({
	rooms: [room]
});

// Export used entities
export const Schemas = {
	USER: user,
	SCOPE: scope,
	// INIT: {
	// 	me: user,
	// 	rootScopes: [scope],
	// 	//shoppingBasket: null // Placeholder to clarify that this will be in the return
	// },
	CONTRACT: contract,
	EMPLOYEE_PAYMENT: employeePayment,
	EMPLOYEE_PAYMENT_LIST: [employeePayment],
	FINANCE_SCOPE_REPORT: financeScopeReport,
	SCOPE_COST: scopeCost,
	COURSE_LOCATION: courseLocation,
	ROOM: room,
	DISCOUNTCODE: discountCode,
	CLIENT: client,
	COMPANYFUNCTION: companyFunction,
	FUNCTIONCAPABILITIES: functionCapabilitiesSchemaCreator,
	TIME_SLOT: timeSlot,
	COURSE_BUNDLE: courseBundle,
	CONTRACT_TEMPLATE: contractTemplate,
};

// Export initial schema, used by the store
export const initialSchemasState = {
	entities: {
		users: {},
		scopes: {},
		contracts: {},
		functions: {},
		employeePayments: {},
		financeScopeReports: {},
		scopeCosts: {},
		courseLocations: {},
		rooms: {},
		discountCodes: {},
		clients: {},
		timeSlots: {},
		courseBundles: {},
		companyFunctions: {},
		functionCapabilities: {},
		contractTemplates: {},
	}
};

// Utility to get the state key from a schema
export const getKey = (schema) => {
	if(Array.isArray(schema)) return schema[0].key;
	return schema.key;
};

// Utility to check data input on key presence
export const checkActionInput = (data, keys) => {
	if(process.env.NODE_ENV === 'production') return;

	const missing = keys.filter(key => {
		return !data[key]
	});

	if(missing.length > 0){
		let callerName;
		try { throw new Error(); }
		catch (e) {
			let re = /(\w+)@|at (\w+) \(/g, st = e.stack, m;
			re.exec(st); m = re.exec(st);
			callerName = m[1] || m[2];
		}

		throw new Error(`Action "${callerName}" called with missing data, keys: ${missing.join(', ')}`)
	}
};

// Re-export api symbol for easier action creation
export { CALL_API } from '../setup/api'
