import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ModalManager from '../modal/ModalManager';
import Notes from '../notes/Notes';
import PageContentBlock from './PageContentBlock';

function PageNoteBlock({ context, contextId }) {
	const location = useLocation();
	const { t } = useTranslation();
	return (
		<PageContentBlock
			title={t('notes.title')}
			icon="notes"
			links={[{
				label: t('notes.newNote'),
				to: location.pathname,
				state: { modal: 'editNotes' },
			}]}
		>
			<Notes
				context={context}
				contextId={contextId}
			/>
		</PageContentBlock>
	);
}

PageNoteBlock.propTypes = {
	context: PropTypes.string.isRequired,
	contextId: PropTypes.number.isRequired,
};

export default PageNoteBlock;
