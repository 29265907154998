import Grid from '@mui/material/Grid';
import React from 'react';
import { useParams } from 'react-router-dom';
import Alert from '../util/alert/Alert';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPasswordPage() {
	const params = useParams();

	return (
		<Grid container justifyContent="center">
			<Grid item lg={3} md={4} sm={6} xs={11}>
				{params.token ? (
					<ResetPasswordForm resetCode={params.token} />
				) : (
					<Alert type={Alert.TYPE_WARNING}>
						The provided URL is not a valid one. Please try clicking the link in the email again.
					</Alert>
				)}
			</Grid>
		</Grid>
	);
}

export default ResetPasswordPage;
