import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import propertyTypes, { propertyTypesWithChoices } from '../../constants/PropertyTypes';
import Loader from '../util/loader/Loader';
import PropertyFilter from './PropertyFilter';

const PREFIX = 'PropertyFiltersMenu';

const classes = {
    button: `${PREFIX}-button`,
    filterMenu: `${PREFIX}-filterMenu`
};

const StyledMenu = styled(Menu)(({ theme }) => ({
    [`&.${classes.filterMenu}`]: {
		'& .MuiPopover-paper': {
			border: '1px solid #ccc',
			minWidth: '200px',
		},
		'& .MuiListItemIcon-root': {
			minWidth: '35px',
		},
		'& .MuiList-root': {
			paddingTop: 0,
		},
		'& .MuiButtonBase-root': {
			padding: theme.spacing(0.25, 1.5),
		},
		'& .MuiListSubheader-root': {
			lineHeight: '2.5rem',
		},
	}
}));

const PropertyFiltersMenu = ({ propertiesDefinition, handleProperty, propertiesState }) => {

	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState();
	if (!propertiesDefinition) {
		return <Loader />;
	}

	const handleFilterToggle = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
        (<>
            <Button
				startIcon={<Icon>filter_list</Icon>}
				endIcon={<Icon>{`keyboard_arrow_${anchorEl ? 'up' : 'down'}`}</Icon>}
				onClick={handleFilterToggle}
				variant="outlined"
				color="default"
				sx={{ mr: 1 }}
			>
				{t('general.filters')}
			</Button>
            <StyledMenu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				keepMounted
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				className={classes.filterMenu}
				onClose={handleClose}
			>
				<List dense>
					{propertiesDefinition
						.filter(property => [...propertyTypesWithChoices, propertyTypes.BOOLEAN].includes(property.type))
						.map(property => (
							<PropertyFilter
								handleProperty={handleProperty}
								propertyKey={property.key}
								propertyValue={propertiesState[property.key]}
								propertiesDefinition={propertiesDefinition}
								key={property.key}
							/>
						))}
				</List>
			</StyledMenu>
        </>)
    );
};

PropertyFiltersMenu.propTypes = {
	propertiesDefinition: PropTypes.array,
	handleProperty: PropTypes.func.isRequired,
	propertiesState: PropTypes.object,
};

export default PropertyFiltersMenu;
