import { getApiTypeSuccess } from '../helpers/types';
import { fetchDataAppend, fetchDataDelete, fetchDataReplaceBy } from '../modules/fetchData/fetchDataActions';
import { notificationShow } from '../modules/notification/notificationActions';
import { CALL_API } from '../setup/api';

const NOTES_FOR_CONTEXT = 'NOTES_FOR_CONTEXT';
export const NOTES_FOR_CONTEXT_ADD = 'NOTES_FOR_CONTEXT_ADD';
export const NOTES_FOR_CONTEXT_UPDATE = 'NOTES_FOR_CONTEXT_UPDATE';
export const NOTES_FOR_CONTEXT_DELETE = 'NOTES_FOR_CONTEXT_DELETE';

export const getNotesForContext = (context, id) => ({
	[CALL_API]: {
		type: NOTES_FOR_CONTEXT,
		endpoint: `${context}/${id}/notes`,
	},
});

export const addNoteForContext = (context, contextId, body) => (dispatch) => dispatch({
	[CALL_API]: {
		type: NOTES_FOR_CONTEXT_ADD,
		endpoint: `${context}/${contextId}/notes`,
		method: 'POST',
		body,
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(NOTES_FOR_CONTEXT_ADD)) {
		dispatch(fetchDataAppend('NotesEditor', res.response?.result));
	}
});

export const updateNoteForContext = (context, contextId, noteId, body) => (dispatch) => dispatch({
	[CALL_API]: {
		type: NOTES_FOR_CONTEXT_UPDATE,
		endpoint: `${context}/${contextId}/notes/${noteId}`,
		method: 'PUT',
		body,
	},
}).then(res => {
	const data = res.response.result;
	dispatch(fetchDataReplaceBy('NotesEditor', 'id', data));
});

export const deleteNoteForContext = (context, contextId, note) => (dispatch) => dispatch({
	[CALL_API]: {
		type: NOTES_FOR_CONTEXT_DELETE,
		endpoint: `${context}/${contextId}/notes/${note.id}`,
		method: 'DELETE',
	},
}).then(res => {
	if (res.type === getApiTypeSuccess(NOTES_FOR_CONTEXT_DELETE)) {
		dispatch(fetchDataDelete('NotesEditor', note));
		dispatch(notificationShow('Deleted note', 'success'));
	}
	return res;
});
