import {
	Collapse,
	TableCell,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { clone } from 'ramda';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { composePatchScope, editScopeTypes } from '../../../../actions/scopeEditActions';
import withFormData from '../../../../modules/formData/withFormData';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import ModalManager from '../../../modal/ModalManager';
import CompanyFAQEditItem from './CompanyFAQEditItem';
import CompanyFAQSectionTitle from './CompanyFAQSectionTitle';

const PREFIX = 'CompanyFAQSection';

const classes = {
    summary: `${PREFIX}-summary`,
    dragging: `${PREFIX}-dragging`
};

const StyledTableBody = styled(TableBody)(() => ({
    [`& .${classes.summary}`]: {
		background: 'rgba(0,0,0,0.05)',
	},

    [`& .${classes.dragging}`]: {
		background: 'rgba(0,0,0,0.04)',
		boxShadow: '0 0 5px rgba(0,0,0,0.3)',
	}
}));

const defaultNewItem = {
	title: {
		en: 'New title',
		nl: 'Nieuwe titel',
	},
	content: {
		en: 'Enter your markdown text here',
		nl: 'Vul hier markdown-tekst in',
	},
};

const CompanyFAQSection = ({ section, sectionIndex, setAddNewSection, isNewSection, topLevelScope, watchSubmit, saving, error }) => {

	const { t } = useTranslation();
	const [collapsed, setCollapsed] = useState(false);

	const handleRemoveSection = useCallback(() => {
		if (isNewSection) {
			setAddNewSection(false);
		} else if (window.confirm(t('manage.company.faq.confirmPermanentRemoveSection'))) {
			const properties = clone(topLevelScope.properties);
			const { faq } = properties;
			faq?.splice(sectionIndex, 1);

			if (faq?.length === 0) {
				delete properties.faq;
			}

			watchSubmit(composePatchScope(editScopeTypes.COMPANY)(topLevelScope.id, {
				properties,
			}));
		}
	}, [isNewSection, sectionIndex, setAddNewSection, t, topLevelScope.id, topLevelScope.properties, watchSubmit]);

	const handleCollapse = () => {
		setCollapsed(!collapsed);
	};

	return (
        <Draggable draggableId={`section_${sectionIndex}`} index={sectionIndex} key={sectionIndex} isDragDisabled={isNewSection}>
			{(providedDrag, snapshot) => (
				<StyledTableBody ref={providedDrag.innerRef} {...providedDrag.draggableProps} className={snapshot.isDragging ? classes.dragging : ''}>
					<TableRow className={classes.summary}>
						<CompanyFAQSectionTitle
							section={section}
							sectionIndex={sectionIndex}
							isNewSection={isNewSection}
							collapsed={collapsed}
							handleCollapse={handleCollapse}
							providedDrag={providedDrag}
						/>
					</TableRow>
					<TableRow>
						<TableCell colSpan={10} style={{ padding: 0, borderBottom: 0 }}>
							<Collapse in={!collapsed} timeout="auto" unmountOnExit>
								<Table style={{ tableLayout: 'fixed', width: '100%' }} size="small">
									<Droppable droppableId={`section_${sectionIndex}`} type="ITEMS">
										{provided => (
											<TableBody ref={provided.innerRef} {...provided.droppableProps}>
												{section.items?.map((item, itemIndex) => (
													<CompanyFAQEditItem
														item={{ ...item, sectionIndex, itemIndex }}
														key={itemIndex}
													/>
												))}
												{provided.placeholder}
												{section.items?.length === 0 && (
													<TableRow>
														<TableCell colSpan={10}>{t('manage.company.faq.addItemToSection')}</TableCell>
													</TableRow>
												)}
												<TableRow>
													<TableCell colSpan={10} style={{ textAlign: 'center' }}>
														{error && (
															<Typography color="secondary">{t('errors.dataStoreError')}</Typography>
														)}
														<Button
															variant="contained"
															color="primary"
															component={Link}
															startIcon={<Icon>add</Icon>}
															to="/portal/company/faq"
															state={{
																modal: 'editFAQItem',
																selectedItem: {
																	...defaultNewItem,
																	sectionIndex,
																	itemIndex: section.items?.length || 0,
																	newItem: true,
																},
															}}
															style={{ color: 'white' }}
															disabled={isNewSection || saving}
														>
															{t('manage.company.faq.addNewItemToSection')}
														</Button>
														<Button
															variant="contained"
															color="secondary"
															onClick={handleRemoveSection}
															startIcon={<Icon>delete</Icon>}
															disabled={saving}
															style={{ float: 'right' }}
														>
															{saving ? t('manage.company.faq.removingSection') : t('manage.company.faq.removeSection')}
														</Button>
													</TableCell>
												</TableRow>
											</TableBody>
										)}
									</Droppable>
								</Table>
							</Collapse>
						</TableCell>
					</TableRow>
				</StyledTableBody>
			)}
		</Draggable>
    );
};

CompanyFAQSection.propTypes = {
	section: PropTypes.object.isRequired,
	sectionIndex: PropTypes.number.isRequired,
	setAddNewSection: PropTypes.func.isRequired,
	isNewSection: PropTypes.bool.isRequired,
};

export default withWorkingScope(withFormData()(CompanyFAQSection));
