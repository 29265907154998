import React, { Component } from 'react'
import { connect } from 'react-redux'
import getDisplayName from 'react-display-name'
import hoistNonReactStatics from 'hoist-non-react-statics'

/**
 * withRootCapabilities HOC, provides union of root capabilities
 * and tools to check them
 * Injects: {capabilities, hasCapability(capability)}
 *
 * @class withRootCapabilities
 * @param WrappedComponent
 * @returns Component
 */
const withRootCapabilities = (WrappedComponent) => {
	const Enhanced = connect(state => ({
		root: state.root
	}))(class RootCapabilities extends Component {
		static displayName = `rootCapabilities(${getDisplayName(WrappedComponent) || 'Unknown'})`;

		render(){
			const { root } = this.props;

			return (
				<WrappedComponent rootCapabilities={root.rootCapabilities}
								  hasCapability={this.hasCapability}
								  {...this.props} />
			);
		}

		hasCapability = (capability) => {
			const { rootCapabilities } = this.props.root;

			return rootCapabilities && rootCapabilities.indexOf(capability) > -1
		}
	});

	return hoistNonReactStatics(Enhanced, WrappedComponent);
};

export default withRootCapabilities
