import { styled } from '@mui/material';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)({
    wordBreak: 'break-word',
});

function CourseSkuReferencesTable({ values = [], onRemove }) {
    const { t } = useTranslation();
    return (
        <Table size="small" sx={{ my: 2 }}>
            <TableHead>
                <TableRow>
                    <TableCell>{t('scope.course.sku.table.sku')}</TableCell>
                    <TableCell>{t('scope.course.sku.table.productName')}</TableCell>
                    <TableCell>{t('scope.course.sku.table.courseName')}</TableCell>
                    <TableCell>{t('scope.course.sku.table.summaryType')}</TableCell>
                    {onRemove && (<TableCell sx={{ width: '4em' }} />)}
                </TableRow>
            </TableHead>
            <TableBody>
                {values.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={4}>
                            {t('scope.course.sku.table.noSavedRefs')}
                        </TableCell>
                    </TableRow>
                )}
                {values.map((reference, i) => (
                    <TableRow key={reference.sku}>
                        <StyledTableCell>{reference.sku}</StyledTableCell>
                        <StyledTableCell>{reference.productName}</StyledTableCell>
                        <StyledTableCell>{reference.courseName}</StyledTableCell>
                        <StyledTableCell>{reference.summaryType}</StyledTableCell>
                        {onRemove && (
                            <TableCell sx={{ width: '4em' }}>
                                <IconButton onClick={() => onRemove(i)} color="secondary">
                                    <Icon>delete</Icon>
                                </IconButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

CourseSkuReferencesTable.propTypes = {
    values: PropTypes.array,
    onRemove: PropTypes.func,
};

export default CourseSkuReferencesTable;
