import { styled } from '@mui/material/styles';
import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { niceDateYear } from '../../helpers/datetime'
import { LOCKED, possibleTasks } from './utils'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'

const PREFIX = 'TaskListGroup';

const classes = {
    handleBar: `${PREFIX}-handleBar`,
    handleLink: `${PREFIX}-handleLink`,
    task: `${PREFIX}-task`
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(2),
	fontSize: theme.typography.fontSize,
	borderRadius: theme.shape.borderRadius,

    [`& .${classes.handleBar}`]: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		width: theme.spacing(7 / 8),
		backgroundColor: theme.palette.primary.light,
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderTopLeftRadius: theme.shape.borderRadius,
		'&.purple': {
			width: theme.spacing(7 / 8),
			backgroundColor: theme.palette.primary.light,
		},
		'&.danger': {
			width: theme.spacing((7 * 2) / 8),
			backgroundColor: theme.palette.error.main,
		},
	},

    [`& .${classes.handleLink}`]: {
		display: 'block',
		padding: theme.spacing(1.5, 1),
		paddingLeft: theme.spacing(2),
		'&.top': {
			paddingBottom: theme.spacing(1 / 3),
		},
		'&.middle': {
			paddingTop: theme.spacing(1 / 3),
			paddingBottom: theme.spacing(1 / 3),
		},
		'&.bottom': {
			paddingTop: theme.spacing(1 / 3),
		},
	},

    [`& .${classes.task}`]: {
		paddingLeft: theme.spacing(1),
	}
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
	height: 65 / 2 * 3,
	position: 'relative',
	border: `1px solid ${theme.palette.grey[300]}`,
	borderLeftWidth: 0,
	borderRadius: theme.shape.borderRadius,
	backgroundColor: theme.palette.common.white,
}))

const getDeadline = group => {
	switch (true) {
		case group.betweenDeadlines:
			return (
				<Typography color={'error'}>
					<b>
						Deadline passed {group.deadline.fromNow()} &nbsp;-&nbsp; {niceDateYear(group.deadline)}
						&nbsp;&nbsp;|&nbsp;&nbsp;
						next deadline {group.nextDeadline.fromNow()} &nbsp;-&nbsp; {niceDateYear(group.nextDeadline)}
					</b>
				</Typography>
			)

		case group.deadlinePassed:
			return (
				<Typography color={'error'}>
					<b>Deadline passed {group.deadline.fromNow()} &nbsp;-&nbsp; {niceDateYear(group.deadline)}</b>
				</Typography>
			)

		case Boolean(group.deadline):
			return (
				<Typography color={'error'}>
					Deadline {group.deadline.fromNow()} &nbsp;-&nbsp; {niceDateYear(group.deadline)}
				</Typography>
			)

		default:
			return (
				<Typography>
					Created {group.created.fromNow()} &nbsp;-&nbsp; {niceDateYear(group.created)}
				</Typography>
			)
	}

}

const ScopePathMapper = courseLink => scope => {
	if (scope._class === 'AthenaStudiesCourseGroup') return (
		<Link key={scope.id} to={courseLink}>{scope.name}</Link>
	)

	return (
		<span key={scope.id}>
			{scope.name}
			<span style={{ fontWeight: 800, padding: '0 12px' }}>{'>'}</span>
		</span>
	)
}

const TaskListGroup = ({ group }) => {

	const courseLink = `/portal/planner/course/${group.id_AthenaStudiesCourse}/group/${group.id}`

	return (
        <StyledAccordion>
			<StyledAccordionSummary expandIcon={<Icon>keyboard_arrow_down</Icon>}>
				<div className={`${classes.handleBar} ${group.deadlinePassed ? 'danger' : 'purple'}`}/>
				<Grid container justifyContent={'space-between'} alignItems={'center'}>
					<Grid item>
						<Grid container direction={'column'}>
							<Grid item className={`${classes.handleLink} top`}>
								<Typography
									variant={'subtitle1'}><b>{possibleTasks[group.picked].parsed}</b></Typography>
							</Grid>
							<Grid item className={`${classes.handleLink} middle`}>
								{group.parents.map(ScopePathMapper(courseLink))}
							</Grid>
							<Grid item className={`${classes.handleLink} bottom`}>
								{getDeadline(group)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container>
							{group.artificialStockStatus === 'FULL' && (

								<Grid item>
									<Tooltip
										title="Manipulated group, stock status is set to full"
										aria-label="Manipulated group, stock status is set to full"
									>
										<Icon color={'error'}>people_outline</Icon>
									</Tooltip>
								</Grid>
							)}
							{group.picked === 'audit' && (

								<Grid item>
									<Button
										component={Link}
										to={`${courseLink}/financial`}
										color={'primary'}
										variant={'text'}
									>
										Financial page <Icon fontSize={'small'}>call_made</Icon>
									</Button>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>

			</StyledAccordionSummary>

			<AccordionDetails>
				<Grid container direction={'column'}>
					{Object.values(possibleTasks).map(task => (
						<Grid item key={task.name}>
							<Grid container>
								<Grid item>
									{(() => {
										switch (group.progress[task.name]) {
											case true:
												return <Icon color={'primary'}>done</Icon>

											case LOCKED:
												return <Icon color={'action'}>lock</Icon>

											default:
												return <Icon color={'secondary'}>warning</Icon>
										}
									})()}
								</Grid>
								<Grid item className={classes.task}>{task.parsed}</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
			</AccordionDetails>

		</StyledAccordion>
    );
}

export default (TaskListGroup)
