export const ShippingStatuses = {
    NO_SHIPPING: 'NO_SHIPPING',
    PENDING: 'PENDING',
    SHIPPING: 'SHIPPING',
    EXPORT_FAILED: 'EXPORT_FAILED',
};

export const ShippingStatusCaptions = {
    NO_SHIPPING: 'No shipping',
    PENDING: 'Pending',
    SHIPPING: 'Shipping',
    EXPORT_FAILED: 'Export failed!',
};
