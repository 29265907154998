import { chipClasses, tableCellClasses, tableRowClasses } from '@mui/material';
import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import CardActions from '@mui/material/CardActions'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Chip from '@mui/material/Chip'
import { formatMoney } from '../../../helpers/formatter'
import { validTags } from '../../course/CourseIndexPage'
import ScopeVisibilitySwitcher from '../../util/ScopeVisibilitySwitcher'
import ScopeTooltipTabsBar from './ScopeTooltipTabsBar'
import ScopeTooltipScopeCourseGroups from './ScopeTooltipScopeCourseGroups'

const Tag = styled(Chip)(({ theme }) => ({
	[`&.${chipClasses.root}`]: {
		marginRight: theme.spacing(1),
		height: theme.spacing(3),
	},
}));

const TableTitleCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.root}`]: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	[`&.${tableCellClasses.sizeSmall}`]: {
		padding: theme.spacing(0.25, 2),
	},
}));

const TableDenseCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.sizeSmall}`]: {
        padding: theme.spacing(0.25, 2),
	},
}));

const TableDenseRow = styled(TableRow)(({ theme }) => ({
	[`&.${tableRowClasses.root}`]: {
		height: theme.spacing(5),
	},
}));

const ScopeTooltipScopeCourse = ({ scope, subScopes, parentScope, activeTab, onTabChange }) => {
	const tags = validTags(scope, parentScope);

	return (
        <Fragment>
			<ScopeTooltipTabsBar
				tabs={['Information', `Groups (${subScopes ? subScopes.length : 0})`]}
				activeTab={activeTab}
				onTabChange={onTabChange}
			/>
			{activeTab === 0 ? (
				<Fragment>
					<Table size="small">
						<TableBody>
							<TableDenseRow>
								<TableTitleCell>Price</TableTitleCell>
								<TableDenseCell>{formatMoney(scope.price)}</TableDenseCell>
							</TableDenseRow>
							<TableDenseRow>
								<TableTitleCell>Tags</TableTitleCell>
								<TableDenseCell>
									{tags.length > 0 ? tags.map(tag => (
										<Tag
                                            key={tag}
                                            label={tag}
                                        />
									)) : (
										<em>No tags</em>
									)}
								</TableDenseCell>
							</TableDenseRow>
						</TableBody>
					</Table>
				</Fragment>
			) : (
				<ScopeTooltipScopeCourseGroups groups={subScopes} />
			)}
			{activeTab === 0 && (
				<CardActions>
					<ScopeVisibilitySwitcher scope={scope} noMargins />
				</CardActions>
			)}
		</Fragment>
    );
}

export default ScopeTooltipScopeCourse
