import React, { Component } from 'react'
import { find } from 'ramda'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen'
import withFormData from '../../modules/formData/withFormData'
import FormDataError from '../../modules/formData/FormDataError'
import { deleteRoom } from '../../actions/locationActions'
import Loader from '../util/loader/Loader'

class RoomDeleteModal extends Component {
	render(){
		const { rooms, modal, handleClose, fullScreen, saving, success, error } = this.props;
		const room = rooms && find(r => r.id === modal.roomId)(rooms);

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth fullScreen={fullScreen}>
				{saving && <Loader sheet />}
				<DialogTitle>Delete room {room.name}</DialogTitle>
				<DialogContent>
					{
						success &&
						<Alert type={Alert.TYPE_SUCCESS}>
							Room deleted.
						</Alert>
					}
					<FormDataError error={error} />
					<p>Confirm to delete the room. This cannot be reverted.</p>
					<p>Note that rooms which were or are used in one or more timeslots cannot be deleted anymore.</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="default">
						{success ? 'Close' : 'Cancel'}
					</Button>
					{
						!success &&
						<Button onClick={this.handleDelete}
								color="secondary"
								variant="contained">
							Delete
						</Button>
					}
				</DialogActions>
			</Dialog>
		)
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.handleClose();
		}
	}

	handleDelete = () => {
		const { rooms, modal, watchSubmit } = this.props;
		const room = rooms && find(r => r.id === modal.roomId)(rooms);

		if(!room) return null;

		watchSubmit(deleteRoom(room.id));
	}
}

export default withDialogShouldBeFullscreen()(withFormData()(RoomDeleteModal))
