import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import classNames from 'classnames'
import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import { hasScopeCapability, capabilities } from '../../../helpers/capabilities'
import ModalManager from '../../modal/ModalManager'
import withRouter from '../../../hoc/withRouter'
import PlannerScopeActionDialog from './PlannerScopeActionDialog'

const PREFIX = 'PlannerHeader';

const classes = {
    label: `${PREFIX}-label`,
    colorSecondary: `${PREFIX}-colorSecondary`,
    deleteIconOutlinedColorSecondary: `${PREFIX}-deleteIconOutlinedColorSecondary`,
    label2: `${PREFIX}-label2`,
    tooltip: `${PREFIX}-tooltip`,
    root: `${PREFIX}-root`,
    item: `${PREFIX}-item`,
    divider: `${PREFIX}-divider`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(1.5),
	},

    [`& .${classes.item}`]: {
		display: 'inline-block',
		[`&+.${classes.item}`]: {
			'&:before': {
				content: "'/'",
				fontSize: 20,
				verticalAlign: 'middle',
				display: 'inline-block',
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2),
			},
		},
	},

    [`& .${classes.divider}`]: {
		marginBottom: theme.spacing(1.5),
	}
}));

const ChipEdit = styled(Chip)(({ theme }) => ({
	[`& .${classes.label}`]: {
		fontSize: 14,
		fontWeight: 700,
		maxWidth: 300,
		overflow: 'hidden',
	},
	[`& .${classes.colorSecondary}`]: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
	[`& .${classes.deleteIconOutlinedColorSecondary}`]: {
		fontSize: 20,
		color: theme.palette.error.main,
	},
}));

const ChipAdd = styled(Chip)(({ theme }) => ({
	[`& .${classes.label2}`]: {
		fontSize: theme.typography.fontSize,
	},
}));

const NotPublishedAvatarTooltip = styled(({  ...rest }) => (
	<Tooltip {...rest}
			 title="Scope not published in webstore"
			 className={classNames(rest.className, classes.tooltip)}
	>
		<Avatar><Icon>visibility_off</Icon></Avatar>
	</Tooltip>
))(({ theme }) => ({
    [`&.${classes.tooltip}`]: {
		backgroundColor: `${theme.palette.error.main} !important`,
		'&>span': {
			fontSize: 20,
		},
	}
}))

class PlannerHeader extends Component {
	state = {
		activeScope: null,
	}

	render(){
		const { scopes,  t } = this.props;
		const { activeScope } = this.state;

		const last = scopes.length > 0 ? scopes[scopes.length - 1] : null;

		return (
            <Root className={classes.root}>
				<Divider className={classes.divider} />
				{scopes.map((scope, i) => {
					const canEdit = hasScopeCapability(scope, capabilities.ScopeAlterCapability);
					const notPublished = scope.published !== 'published';

					return (
                        <div className={classes.item} key={scope.id}>
							<ChipEdit
                                label={i === (scopes.length - 1) ? scope.name : scope.abbreviation}
                                title={scope.name}
                                color={notPublished ? 'secondary' : undefined}
                                avatar={notPublished ? <NotPublishedAvatarTooltip
                                    classes={{
                                        tooltip: classes.tooltip
                                    }} /> : undefined}
                                onDelete={canEdit ? () => this.handleSelect(scope) : undefined}
                                deleteIcon={canEdit ? <Icon>expand_more</Icon> : undefined}
                                variant="outlined"
                                classes={{
                                    label: classes.label,
                                    colorSecondary: classes.colorSecondary,
                                    deleteIconOutlinedColorSecondary: classes.deleteIconOutlinedColorSecondary
                                }} />
						</div>
                    );
				})}
				{last && last._class === 'Course' && hasScopeCapability(last, capabilities.ScopeAlterCapability) && (
					<div className={classes.item}>
						<ChipAdd
                            key="new"
                            label={t('scope.planner.newCourse')}
                            onClick={this.handleClickLast}
                            onDelete={this.handleClickLast}
                            deleteIcon={<Icon>add</Icon>}
                            color="primary"
                            variant="outlined"
                            classes={{
                                label: classes.label2
                            }} />
					</div>
				)}
				<PlannerScopeActionDialog
					onClose={this.handleClose}
					scope={activeScope}
				/>
			</Root>
        );
	}

	handleSelect = (scope) => {
		this.setState({
			activeScope: scope
		})
	}

	handleClose = () => {
		this.setState({
			activeScope: null
		});
	}

	handleClickLast = () => {
		const { scopes, navigate } = this.props;
		const last = scopes.length > 0 ? scopes[scopes.length - 1] : null;

		if(!last) return;

		navigate('/portal/planner', {
			state: {
				modal: 'scopeEditor',
				parentId: last.id,
			},
		});
	}
}

PlannerHeader.propTypes = {
	scopes: PropTypes.array.isRequired,
}

export default withTranslation()((withRouter(PlannerHeader)))
