/* eslint-disable no-restricted-globals */
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isJSON from '../../helpers/contentType';
import { notificationShow } from '../../modules/notification/notificationActions';
import Alert from './alert/Alert';
import Loader from './loader/Loader';

const LexicalEditor = lazy(() => import('./editor/LexicalEditor'));

function AthenaEditor({ value, onChange, output, bindEditorApi, toolbarComponents }) {
    const [showLexical, setShowLexical] = useState(!value || value.length === 0 || isJSON(value));
    const { t } = useTranslation();
    const ref = useRef();
    const handleClick = () => {
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(ref.current);
            range.select();
        } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(ref.current);
            selection.removeAllRanges();
            selection.addRange(range);
        }

        try {
            document.execCommand('copy');
            notificationShow(t('lexical.copiedToClipboard'), 'success');
        } catch (err) {
            alert(t('lexical.unsupportedBrowser'));
        }
        setShowLexical(true);
    };
    if (!showLexical) {
        return (
            <>
                <Alert type={Alert.TYPE_WARNING}>
                    <div>{t('lexical.conversionWarning')}</div>
                    <Button variant="contained" color="primary" onClick={handleClick} style={{ margin: '1rem 0' }}>
                        {t('lexical.copyAndOpen')}
                    </Button>
                </Alert>
                <div dangerouslySetInnerHTML={{ __html: value }} ref={ref} />
            </>
        );
    }
    return (
        <Suspense fallback={<Loader />}>
            <LexicalEditor
                content={value}
                onChange={onChange}
                allowedBlockTypes={['paragraph', 'ul', 'ol']}
                output={output}
                minHeight={150}
                disableAutoFocus
                bindEditorApi={bindEditorApi}
                toolbarComponents={toolbarComponents}
            />
        </Suspense>
    );
}

AthenaEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    output: PropTypes.oneOf(['html']),
    bindEditorApi: PropTypes.func,
    toolbarComponents: PropTypes.node,
};

export default AthenaEditor;
