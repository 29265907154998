import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Fab from '@mui/material/Fab'
import Icon from '@mui/material/Icon'
import Zoom from '@mui/material/Zoom'
const PREFIX = 'PlannerScrollRestoreAndUp';

const classes = {
    fab: `${PREFIX}-fab`
};

const StyledZoom = styled(Zoom)(({ theme }) => ({
    [`& .${classes.fab}`]: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: theme.zIndex.snackbar + 1, // Appear above the snackbar anchor
	}
}));

let scrollY = 0;
let scrollScopeId = null;

class PlannerScrollRestoreAndUp extends Component {
	state = {
		scrollY: 0,
	};

    render() {
    	const { } = this.props;

        return (
            <StyledZoom in={this.state.scrollY > window.innerHeight / 2} unmountOnExit>
				<Fab
					size="medium"
					color="primary"
					className={classes.fab}
					onClick={this.scrollReset}
				>
					<Icon>arrow_upward</Icon>
				</Fab>
			</StyledZoom>
        );
    }

	componentDidMount(){
    	if(scrollY > 0){
    		if(this.props.scopeId === scrollScopeId){
				window.scrollTo(0, scrollY);
			}else{
    			window.scrollTo(0, 0);
			}
		}

		window.addEventListener('scroll', this.scrollChange);
	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.scrollChange);
	}

	scrollChange = () => {
		scrollY = window.scrollY;
		scrollScopeId = this.props.scopeId;

		this.setState({
			scrollY: scrollY,
		});
	}

	scrollReset = () => {
		window.scrollTo(0, 0);
	}
}

PlannerScrollRestoreAndUp.propTypes = {
	scopeId: PropTypes.number,
};

export default (PlannerScrollRestoreAndUp)
