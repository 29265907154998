import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import { GROUP_STATE_CANCELLED, GROUP_STATE_FINISHED } from '../../helpers/groupState'

const PREFIX = 'GetGroupProblems';

const classes = {
    icon: `${PREFIX}-icon`
};

const StyledIcon = styled(Icon)(({ theme }) => ({
    [`&.${classes.icon}`]: {
		display: 'inline-block',
		marginLeft: '5px',
		color: theme.palette.error.main,
	}
}));

export const GetGroupProblems = (group) => {
	const problems = [];

	if(group.numTimeSlots === 0)
		problems.push('This group has no timeslots');

	if(!group.timeSlotsHaveTeacher)
		problems.push('One or more timeslots are missing a teacher!');

	if(!group.timeSlotsHaveClassRoom)
		problems.push('One or more timeslots are missing a location!');

	if(group.state === GROUP_STATE_FINISHED && !group.evaluation)
		problems.push('Evaluation has not been filled in!');

	return problems;
}

const GroupProblemIndicator = ({ group, bigger }) => {
	if(group.state === GROUP_STATE_CANCELLED) return null;

	const problems = GetGroupProblems(group);
	if(problems.length === 0) return null;

	return (
        <Tooltip
			title={(
				<Fragment>
					{problems.map((problem, key) => (
						<div key={key}>{problem}</div>
					))}
				</Fragment>
			)}
			placement="top"
		>
			<StyledIcon className={classes.icon} style={{
				verticalAlign: bigger ? '-3px' : '-4px',
				fontSize: bigger ? '24px' : '20px',
			}}>&#xE002;</StyledIcon>
		</Tooltip>
    );
};

GroupProblemIndicator.propTypes = {
	group: PropTypes.object.isRequired,
	bigger: PropTypes.bool,
};

export default (GroupProblemIndicator)
