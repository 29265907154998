import React, { Component, Fragment } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { formatMoneyAuto } from '../../helpers/formatter'
import { formatMoneySumCurrencyGrouped } from '../../helpers/money'
import withFetchData from '../../modules/fetchData/withFetchData'
import { niceDateYear } from '../../helpers/datetime'
import { CALL_API, Schemas } from '../../actions/schemas'
import ModalManager from '../modal/ModalManager'
import ModalItem from '../modal/ModalItem'
import RejectPaymentForm from '../financial/batchCheck/RejectPaymentForm'
import EmployeePaymentStatusManipulation from './EmployeePaymentStatusManipulation'

const CollapsibleTableCell = styled(TableCell)(({ theme }) => ({
	[theme.breakpoints.down('lg')]: {
		display: 'none',
	},
}));

class PaymentType {
	static REVENUE_SHARE = 'REVENUE_SHARE';
	static PROFIT_SHARE = 'PROFIT_SHARE';
	static TEACHER = 'TEACHER';
	static BOOKLET_WRITER = 'BOOKLET_WRITER';
	static COMPENSATION = 'COMPENSATION';
}

const ReadablePaymentTypes = {
	[PaymentType.REVENUE_SHARE]: "Revenue Commission",
	[PaymentType.PROFIT_SHARE]: "Profit Commission",
	[PaymentType.TEACHER]: "Tutoring",
	[PaymentType.BOOKLET_WRITER]: "Booklet",
	[PaymentType.COMPENSATION]: "Compensation",
};

function *scopeParents(scope) {
	if (scope.parent) yield *scopeParents(scope.parent)
	yield scope;
}

const RejectPaymentModal = ({handleClose, modal, onCrudUpdate}) => (
	<RejectPaymentForm payment={modal.payment} handleClose={handleClose} onCrudUpdate={onCrudUpdate}/>
)

class EmployeePaymentsOverview extends Component {
	render() {
		const { } = this.props;
		const payments = this.props.data || [];
		return (
            <>
				<ModalManager>
					<ModalItem state={"reject-payment"} component={RejectPaymentModal}/>
				</ModalManager>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell size="small">Course</TableCell>
							<TableCell size="small">Type</TableCell>
							<CollapsibleTableCell size="small">Month</CollapsibleTableCell>
							<CollapsibleTableCell size="small">Batch ID</CollapsibleTableCell>
							<TableCell size="small">Details</TableCell>
							<CollapsibleTableCell size="small">Created</CollapsibleTableCell>
							<TableCell size="small" align="right">Amount</TableCell>
							<TableCell size="small"/>
						</TableRow>
					</TableHead>
					<TableBody>
						{payments.map((entry) => (
							<TableRow key={entry.id}>
								<TableCell size="small">
									{Array.from(scopeParents(entry.scope)).map(scope => scope.abbreviation).join(" / ")}
								</TableCell>
								<TableCell size="small" style={{ whiteSpace :'nowrap'}}>
									{ReadablePaymentTypes[entry.type]}
								</TableCell>
								<CollapsibleTableCell size="small" sx={{ whiteSpace :'nowrap'}}>
									{entry.computedOver}
								</CollapsibleTableCell>
								<CollapsibleTableCell size="small" sx={{ whiteSpace :'nowrap'}}>
									{entry.batch ? entry.batch.id : <em>none</em>}
								</CollapsibleTableCell>
								<TableCell size="small" sx={{ whiteSpace :'nowrap', textTransform: 'capitalize'}}>
									{entry.status.replace("_", " ").toLowerCase()}
								</TableCell>
								<CollapsibleTableCell size="small" style={{ whiteSpace :'nowrap'}}>
									{niceDateYear(entry.created)}
								</CollapsibleTableCell>
								<TableCell size="small"  style={{ whiteSpace :'nowrap'}} align="right">
									{formatMoneyAuto(entry.amount)}
								</TableCell>
								<TableCell size="small" align="right">
									<EmployeePaymentStatusManipulation payment={entry}/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell size="small">Total</TableCell>
							<TableCell size="small"/>
							<CollapsibleTableCell size="small" />
							<CollapsibleTableCell size="small" />
							<TableCell size="small"/>
							<CollapsibleTableCell size="small" />
							<TableCell size="small" style={{ whiteSpace :'nowrap'}} align="right">
								{formatMoneySumCurrencyGrouped(payments.map(a => a.amount))}
							</TableCell>
							<TableCell size="small" align="right"/>
						</TableRow>
					</TableFooter>
				</Table>
			</>
        );
	}

}

const getEmployeePayments = (employeeId, month) => ({
	[CALL_API]: {
		type: "EMPLOYEE_PAYMENTS",
		endpoint: `employee/${employeeId}/employee-payments?term=${month}`,
		schema: [Schemas.EMPLOYEE_PAYMENT],
	}
});

export default (withFetchData((props) => {
	return getEmployeePayments(props.params.employeeId, props.params.month);
}, {
	denormalize: [Schemas.EMPLOYEE_PAYMENT],
})(EmployeePaymentsOverview))
