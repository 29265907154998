import { styled } from '@mui/material/styles';
import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListSubheader from '@mui/material/ListSubheader'
import Button from '@mui/material/Button'
import withFormData from '../../modules/formData/withFormData'
import { CALL_API } from '../../setup/api'
import { Schemas } from '../../actions/schemas'
import { scopeParentsNameString } from '../../helpers/scope'
import ScopeTooltip from '../scope/tooltip/ScopeTooltip'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import ApiError from '../util/ApiError'

const PREFIX = 'BundleViewStudyList';

const classes = {
    root: `${PREFIX}-root`,
    button: `${PREFIX}-button`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
		marginTop: theme.spacing(1),
	},

    [`& .${classes.button}`]: {
		marginTop: theme.spacing(1),
	}
}));

const ApiActionDisassociate = (bundleId, courseId) => ({
	[CALL_API]: {
		type: 'BUNDLE_DISASSOCIATE',
		endpoint: `athenastudies-course-bundle/${bundleId}/athenastudies-course/${courseId}`,
		schema: Schemas.COURSE_BUNDLE,
		method: 'DELETE',
	},
});

const BundleViewStudyList = ({ courses, bundleId, onAddClick, saving, error, watchSubmit }) => {

	const disassociate = (bundleId, courseId) => {
		watchSubmit(ApiActionDisassociate(bundleId, courseId));
	};

	return (
        <Root className={classes.root}>
			{saving && (
				<Loader sheet />
			)}
			{courses.length === 0 && (
				<Alert>
					There are no courses in this bundle yet.
				</Alert>
			)}
			{error && (
				<ApiError error={error} />
			)}
			<List
				subheader={
					<ListSubheader>
						Courses in this bundle
					</ListSubheader>
				}
				disablePadding
			>
				{courses.map(course => (
					<ListItem key={course.id} button component="a" target="_blank" href={`/portal/planner/course/${course.id}`}>
						<ScopeTooltip scopeId={course.id} enterDelay={1000}>
							<ListItemText
								primary={course.name}
								secondary={scopeParentsNameString(course.parent)}
							/>
						</ScopeTooltip>
						{courses.length > 2 && (
							<ListItemSecondaryAction>
								<Tooltip title="Remove course from bundle" placement="top">
									<IconButton
										onClick={() => disassociate(bundleId, course.id)}
										color="secondary"
										edge="end"
									>
										<Icon>cancel</Icon>
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						)}
					</ListItem>
				))}
			</List>
			<Button
				variant="outlined"
				color="primary"
				fullWidth
				onClick={onAddClick}
				className={classes.button}
			>
				Add new courses
			</Button>
		</Root>
    );
};

BundleViewStudyList.propTypes = {
	bundleId: PropTypes.number.isRequired,
	courses: PropTypes.array.isRequired,
	onAddClick: PropTypes.func.isRequired,
};

export default withFormData()(BundleViewStudyList)
