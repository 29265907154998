import { Switch, TableCell, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createFunction, putFunction } from '../../../../actions/functionsActions';
import withCompleteBasicForm, { Joi } from '../../../../hoc/withCompleteBasicForm';
import FormDataError from '../../../../modules/formData/FormDataError';
import EvaluationInputField from '../../../util/EvaluationInputField';
import Loader from '../../../util/loader/Loader';
import SelectValueMenu from '../../../util/SelectValueMenu';
import SmallTooltip from '../../../util/SmallTooltip';
import EditFunctionCurrencyAmountInput from './EditFunctionCurrencyAmountInput';
import EditFunctionNumberTextField from './EditFunctionNumberTextField';
import EditFunctionPercentageTextField from './EditFunctionPercentageTextField';
import EditFunctionSwitch from './EditFunctionSwitch';
import PaymentStrategySelect, { PaymentStrategies } from './PaymentStrategySelect';

const PREFIX = 'EditFunctionForm';

const classes = {
    rightAlign: `${PREFIX}-rightAlign`
};

const Root = styled('div')({
	[`& .${classes.rightAlign}`]: {
		textAlign: 'right',
	},
});

function EditFunctionForm({
	onChangeField,
	onChangeFieldDirect,
	fields,
	functionId,
	companyId,
	refFormSubmit,
	watchSubmit,
	saving,
	validation,
	validationAll,
	handleFormError,
	handleClose,
	error,
}) {
	const { t } = useTranslation();
	const ref = useRef();

	// eslint-disable-next-line no-param-reassign
	refFormSubmit.current = () => {
		validationAll(fields, (hasError) => {
			if (hasError) return handleFormError(t('errors.notAllFieldsFilledCorrectly'));
			const action = functionId ? putFunction(functionId, fields) : createFunction(companyId, fields);
			return watchSubmit(action)
				.then(res => {
					if (res.type === 'FORM_SUCCESS') {
						handleClose();
					} else {
						ref?.current?.scrollIntoView?.({
							behavior: 'smooth',
							block: 'start',
						});
					}
				});
		});
	};

	const handleClear = (key) => () => {
		onChangeFieldDirect(key, null);
	};

	const handleClearMultiple = (keys) => () => {
		keys.forEach(key => handleClear(key)());
	};

	const onChangeReimbursement = (event) => {
		const { value } = event.target;
		onChangeFieldDirect('reimbursementType', value.length > 0 ? value : null);
		onChangeFieldDirect('hasDueDate', event.target.value === 'BOOKLET_WRITER');
	};

	return (
        <Root ref={ref}>
			{saving && <Loader sheet />}
			<FormDataError error={error} showFieldErrors />
			<Table size="small">
				<TableBody>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.name')}</TableCell>
						<TableCell colSpan={3}>
							<TextField
								value={fields.name || ''}
								placeholder={t('general.name')}
								onChange={onChangeField}
								name="name"
								error={!!validation.name}
								helperText={validation.name}
								fullWidth
							/>
						</TableCell>
						<TableCell>
							<SmallTooltip title={t('manage.company.functions.fields.named_desc')}>
								{t('manage.company.functions.editModal.visible')}
								{': '}
								<Switch
									checked={fields.named || false}
									label={t('manage.company.functions.fields.named')}
									onChange={() => onChangeFieldDirect('named', !fields.named)}
									name="named"
								/>
							</SmallTooltip>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.baseSalary')}</TableCell>
						<TableCell style={{ whiteSpace: 'nowrap' }}>
							<EditFunctionCurrencyAmountInput
								field="baseSalary"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
							/>
						</TableCell>
						<TableCell>{t('manage.company.functions.fields.bonusSalary')}</TableCell>
						<TableCell style={{ whiteSpace: 'nowrap' }}>
							<EditFunctionCurrencyAmountInput
								field="bonusSalary"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
								validation={validation}
							/>
						</TableCell>
						<TableCell className={classes.rightAlign}>
							<Button onClick={handleClearMultiple(['bonusSalary', 'baseSalary'])}>
								{t('manage.company.functions.editModal.clear')}
							</Button>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.turnoverPercentage')}</TableCell>
						<TableCell>
							<EditFunctionPercentageTextField
								field="turnoverPercentage"
								fields={fields}
								onChangeField={onChangeField}
							/>
						</TableCell>
						<TableCell>{t('manage.company.functions.fields.bonusTurnoverPercentage')}</TableCell>
						<TableCell>
							<EditFunctionPercentageTextField
								field="bonusTurnoverPercentage"
								fields={fields}
								onChangeField={onChangeField}
							/>
						</TableCell>
						<TableCell />
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.bonusEvaluationThreshold')}</TableCell>
						<TableCell colSpan={10}>
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								value={fields.bonusEvaluationThreshold}
								name="bonusEvaluationThreshold"
								InputProps={{
									inputComponent: EvaluationInputField,
								}}
								onChange={onChangeField}
								style={{ width: '150px' }}
								error={!!validation.bonusEvaluationThreshold}
								helperText={validation.bonusEvaluationThreshold}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.profitPercentage')}</TableCell>
						<TableCell colSpan={10}>
							<EditFunctionPercentageTextField
								field="profitPercentage"
								fields={fields}
								onChangeField={onChangeField}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.reimbursementType')}</TableCell>
						<TableCell colSpan={10}>
							<SelectValueMenu
								value={fields.reimbursementType || ''}
								options={['COMPENSATION', 'BOOKLET_WRITER', 'TEACHER']}
								onChange={onChangeReimbursement}
								name="reimbursementType"
								allowEmpty
								sx={{ width: '150px' }}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.hasSinglePayment')}</TableCell>
						<TableCell colSpan={10}>
							<EditFunctionSwitch
								field="hasSinglePayment"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.maximumFee')}</TableCell>
						<TableCell>
							<EditFunctionCurrencyAmountInput
								field="maximumFee"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
								disabled={!fields.hasSinglePayment || !fields.reimbursementType}
							/>
						</TableCell>
						<TableCell className={classes.rightAlign} colSpan={10}>
							<Button
								onClick={handleClear('maximumFee')}
								disabled={!fields.hasSinglePayment || !fields.reimbursementType}
							>
								{t('manage.company.functions.editModal.clear')}
							</Button>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.hasDueDate')}</TableCell>
						<TableCell colSpan={10}>
							<EditFunctionSwitch
								field="hasDueDate"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
								disabled={!fields.hasSinglePayment || !fields.reimbursementType}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.hasMonthlySalary')}</TableCell>
						<TableCell colSpan={10}>
							<EditFunctionSwitch
								field="hasMonthlySalary"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.monthlySalary')}</TableCell>
						<TableCell colSpan={10}>
							<EditFunctionCurrencyAmountInput
								field="monthlySalary"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
								disabled={!fields.hasMonthlySalary}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<SmallTooltip title={t('manage.company.functions.tooltip.paymentStrategy')}>
								{t('manage.company.functions.fields.paymentStrategy')}
							</SmallTooltip>
						</TableCell>
						<TableCell colSpan={10}>
							<PaymentStrategySelect
								name="paymentStrategy"
								value={fields.paymentStrategy}
								onChangeDirect={onChangeFieldDirect}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<SmallTooltip title={t('manage.company.functions.fields.visibleInApply_desc')}>
								{t('manage.company.functions.fields.visibleInApply')}
							</SmallTooltip>
						</TableCell>
						<TableCell colSpan={10}>
							<EditFunctionSwitch
								field="visibleInApply"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>{t('manage.company.functions.fields.rank')}</TableCell>
						<TableCell colSpan={10}>
							{/* TODO: to be replaced with capabilities */}
							<EditFunctionNumberTextField
								field="rank"
								fields={fields}
								onChangeFieldDirect={onChangeFieldDirect}
								validation={validation}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Root>
    );
}

EditFunctionForm.propTypes = {
	functionId: PropTypes.string,
	companyId: PropTypes.number,
	refFormSubmit: PropTypes.object,
};

export default withCompleteBasicForm(EditFunctionForm, {
	name: Joi.string().min(1).required(),
	rank: Joi.number(),
	bonusEvaluationThreshold: Joi.when('bonusSalary', {
		is: Joi.object().keys({
			amount: Joi.number(),
		}).required(),
		then: Joi.number().min(1).max(10).required(),
	}),
	paymentStrategy: Joi.string().default(PaymentStrategies[0]),
	named: Joi.boolean().default(true),
	visibleInApply: Joi.boolean().default(true),
});
