import React, { Component, Fragment } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { workingScopesNameString } from '../../helpers/scope'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import TeachersWithDetails from './TeachersWithDetails'

class TeacherDashboardPage extends Component {
	render() {
		const { workingScopes } = this.props;

		return (
			<Fragment>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<PageHeader>
							<PageHeaderTitle
								headline="Teacher Overview"
								subHeading={workingScopesNameString(workingScopes)}
							/>
						</PageHeader>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={12} sm={12}>
						<Paper>
							<TeachersWithDetails/>
						</Paper>
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}

export default withWorkingScope(TeacherDashboardPage)
