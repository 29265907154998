import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import withFetchData from '../../../modules/fetchData/withFetchData'
import { getScopeTeachers } from '../../../actions/courseGroupActions'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'

const optionLabel = (option) => option.fullName;
const optionSelected = (option, value) => option.id === value.id;

const ScopeSelectTeacher = ({ onChange, teacher, loading, error, data }) => {
	const { t } = useTranslation();
    const autocompleteInput = useCallback((params) => (
    	<TextField
			{...params}
			label={t('general.teacher')}
		/>
	), []);

    const handleChange = useCallback((e, newValue) => {
    	onChange(newValue || null);
	}, [onChange]);

	if(error) return (
    	<Alert type={Alert.TYPE_WARNING}>
			{error.error}
		</Alert>
	);

	if(loading || !data) return (
		<Loader />
	);

	// Add selected teacher to the list if not in it to prevent deselecting of fired teachers for example
	if(teacher && !data.find(t => t.id === teacher.id)){
		data = data.concat(teacher);
	}

	if(data.length === 0) return (
		<Alert type={Alert.TYPE_INFO}>
			{t('scope.edit.noTeachersAvailable')}
		</Alert>
	);

	return (
		<>
			<Autocomplete
				options={data}
				getOptionLabel={optionLabel}
				clearOnBlur
				clearOnEscape
				renderInput={autocompleteInput}
				value={teacher}
				isOptionEqualToValue={optionSelected}
				onChange={handleChange}
			/>
		</>
    );
};

ScopeSelectTeacher.propTypes = {
	scope: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	teacher: PropTypes.object,
};

export default withFetchData((props) => {
	return getScopeTeachers(props.scope.id);
})(ScopeSelectTeacher)
