import { MobileDatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { find, pick, keys, compose } from 'ramda';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { withTranslation } from 'react-i18next';
import withRouter from '../../hoc/withRouter';
import withValidation, { Joi } from '../../hoc/withValidation';
import withFetchData from '../../modules/fetchData/withFetchData';
import withFormData from '../../modules/formData/withFormData';
import InfoTooltip from '../../modules/info/InfoTooltip';
import { getCompanyFunctionList } from '../../actions/applyActions';
import { hasScopeCapability, capabilities } from '../../helpers/capabilities';
import { CALL_API } from '../../setup/api';
import PaymentStrategySelect, { PaymentStrategies } from '../manage/company/functions/PaymentStrategySelect';
import PageHeader from '../page/PageHeader';
import PageHeaderMenu from '../page/PageHeaderMenu';
import PageHeaderTitle from '../page/PageHeaderTitle';
import ApiError from '../util/ApiError';
import Loader from '../util/loader/Loader';
import Alert from '../util/alert/Alert';
import ApplicationsContractTemplateSelector from './ApplicationsContractTemplateSelector';

const PREFIX = 'ApplicationsCreateForm';

const classes = {
    section: `${PREFIX}-section`,
    sectionTitle: `${PREFIX}-sectionTitle`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.section}`]: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderTop: `2px solid ${theme.palette.primary.light}`,
	},

    [`& .${classes.sectionTitle}`]: {
		fontWeight: 700,
		'& > span': {
			fontStyle: 'italic',
			fontWeight: 'normal',
			color: '#999999',
		},
	}
}));

const ApiAction = (data) => ({
	[CALL_API]: {
		type: 'APPLY_CREATE',
		endpoint: 'apply',
		body: data,
	},
});

const getFunctionTemplate = (data, functionTemplateId) => {
	if (!data) return null;
	if (!functionTemplateId) return null;

	return find((t) => t.id === functionTemplateId)(data);
};

class ApplicationsCreateForm extends Component {
	constructor(props) {
		super(props);

		const employee = props.location && props.location.state && props.location.state.employee;

		this.state = {
			name: employee ? employee.name || '' : '',
			middleName: employee ? employee.middleName || '' : '',
			surname: employee ? employee.surname || '' : '',
			email: employee ? employee.email || '' : '',

			startDate: moment().startOf('day').format(),
			endDate: null,

			baseSalary: '',
			bonusSalary: '',
			turnoverPercentage: '',
			bonusTurnoverPercentage: '',
			profitPercentage: '',

			dueDate: null,
			payment: '',
			monthlySalary: '',

			paymentStrategy: '',

			functionTemplateId: '',
			contractTemplateIds: [],
			doNotApplyTemplate: false,
		};
	}

	componentDidMount() {
		const { workingScope, load, bindSave } = this.props;

		if (workingScope) {
			load(getCompanyFunctionList(workingScope.id));
		}

		bindSave?.(this.createApply);
	}

	componentDidUpdate(prevProps) {
		const { workingScope, load, data, preSelectFunctionFilter, saving, toggleSaving, success, handleSuccess, navigate } = this.props;

		if (workingScope && (!prevProps.workingScope || prevProps.workingScope.id !== workingScope.id)) {
			load(getCompanyFunctionList(workingScope.id));
		}

		if (!prevProps.data && data) {
			let filteredData = this.handleFunctionTemplates(data);

			if (preSelectFunctionFilter) {
				filteredData = filteredData.filter(preSelectFunctionFilter);
			}

			if (filteredData.length === 1) {
				this.bindStateSelect('functionTemplateId')(null, filteredData[0].id);
			}
		}

		if (!saving && prevProps.saving) {
			toggleSaving?.(false);
		}

		if (success && !prevProps.success) {
			if (handleSuccess) {
				handleSuccess();
			} else {
				navigate('/portal/applications');
			}
		}
	}

	handleFunctionTemplates = functionTemplates => {
		if (!functionTemplates) return [];

		const { functionFilter } = this.props;
		if (functionFilter) return functionTemplates.filter(functionFilter);

		return functionTemplates;
	}

	onEndDateToggle = () => {
		this.setState(state => ({
			endDate: state.endDate === null ? new Date() : null,
		}));
	};

	bindState = (property) => (event) => {
		this.setState({
			[property]: event.target.value,
		});
	};

	bindStateDirect = (property) => (value) => {
		this.setState({
			[property]: value,
		});
	};

	bindStateDate = (property) => (date) => {
		if (!date || !date.isValid()) {
			return;
		}
		this.setState({
			[property]: date.format(),
		}, () => {
			const { validationField } = this.props;
			const { state } = this;

			validationField(property, state[property]);
		});
	};

	bindStateSelect = (property) => (event, customValue = undefined) => {
		this.setState({
			[property]: event ? event.target.value : customValue,
		}, () => {
			const { data } = this.props;
			const { functionTemplateId } = this.state;
			const ft = getFunctionTemplate(data, functionTemplateId);
			if (!ft || property !== 'functionTemplateId') return;

			this.setState({
				baseSalary: ft.baseSalary?.amount || '',
				bonusSalary: ft.bonusSalary?.amount || '',
				turnoverPercentage: ft.turnoverPercentage || '',
				bonusTurnoverPercentage: ft.bonusTurnoverPercentage || '',
				profitPercentage: ft.profitPercentage || '',
				hasSinglePayment: ft.hasSinglePayment,
				hasDueDate: ft.hasDueDate,
				maximumFee: ft.maximumFee ? ft.maximumFee.amount : null,
				payment: '',
				monthlySalary: ft.monthlySalary?.amount || '',
				paymentStrategy: ft.paymentStrategy || PaymentStrategies[0],
				contractTemplateIds: [],
				doNotApplyTemplate: false,
			});
		});
	};

	createApply = () => {
		const { workingScope, validationAll, onValidationError, handleFormError, validation, watchSubmit, toggleSaving } = this.props;
		const { baseSalary, bonusSalary, payment, monthlySalary } = this.state;

		if (!workingScope) return;

		validationAll(this.state, (error, errors) => {
			if (error) {
				if (onValidationError) onValidationError(errors);
				window.scrollTo(0, 0);
				return handleFormError('Not all fields were filled correctly.');
			}

			watchSubmit(ApiAction(
				pick(keys(validation), {
					...this.state,
					scopeId: workingScope.id,
					baseSalary: baseSalary ? {
						amount: baseSalary,
						currency: workingScope.currencyUnit,
					} : null,
					bonusSalary: bonusSalary ? {
						amount: bonusSalary,
						currency: workingScope.currencyUnit,
					} : null,
					payment: payment ? {
						amount: payment,
						currency: workingScope.currencyUnit,
					} : null,
					monthlySalary: monthlySalary ? {
						amount: monthlySalary,
						currency: workingScope.currencyUnit,
					} : null,
				}),
			));

			return toggleSaving?.(true);
		});
	};

	renderFunctionSpecifics = () => {
		const { validation, validationBlur, data, bindSave, t } = this.props;
		const {
			functionTemplateId, baseSalary, bonusSalary, turnoverPercentage,
			bonusTurnoverPercentage, profitPercentage, dueDate, payment, monthlySalary, paymentStrategy,
		} = this.state;
		const ft = getFunctionTemplate(data, functionTemplateId);

		if (!ft) return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Alert type={Alert.TYPE_INFO}>
						Please select a function first.
					</Alert>
				</Grid>
			</Grid>
		);

		return (
			<Grid container spacing={2}>
				{ft.baseSalary !== null && (
					<Grid item xs={6}>
						<FormControl
							error={!!validation.baseSalary}
							fullWidth
							required
						>
							<InputLabel>Base hourly salary (teacher)</InputLabel>
							<Input
								value={baseSalary}
								type="number"
								name="baseSalary"
								onBlur={validationBlur}
								onChange={this.bindState('baseSalary')}
							/>
							<FormHelperText>
								{validation.baseSalary}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{ft.bonusSalary !== null && (
					<Grid item xs={6}>
						<FormControl
							error={!!validation.bonusSalary}
							fullWidth
							required
						>
							<InputLabel>Bonus hourly salary (teacher)</InputLabel>
							<Input
								value={bonusSalary}
								type="number"
								name="bonusSalary"
								onBlur={validationBlur}
								onChange={this.bindState('bonusSalary')}
							/>
							<FormHelperText>
								{validation.bonusSalary}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{ft.turnoverPercentage !== null && (
					<Grid item xs={6}>
						<FormControl
							error={!!validation.turnoverPercentage}
							fullWidth
							required
						>
							<InputLabel>Turnover percentage</InputLabel>
							<Input
								value={turnoverPercentage}
								type="number"
								name="turnoverPercentage"
								onBlur={validationBlur}
								onChange={this.bindState('turnoverPercentage')}
							/>
							<FormHelperText>
								{validation.turnoverPercentage}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{ft.bonusTurnoverPercentage !== null && (
					<Grid item xs={6}>
						<FormControl
							error={!!validation.bonusTurnoverPercentage}
							fullWidth
							required
						>
							<InputLabel>Bonus turnover percentage</InputLabel>
							<Input
								value={bonusTurnoverPercentage}
								type="number"
								name="bonusTurnoverPercentage"
								onBlur={validationBlur}
								onChange={this.bindState('bonusTurnoverPercentage')}
							/>
							<FormHelperText>
								{validation.bonusTurnoverPercentage}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{ft.profitPercentage !== null && (
					<Grid item xs={6}>
						<FormControl
							error={!!validation.profitPercentage}
							fullWidth
							required
						>
							<InputLabel>Profit percentage</InputLabel>
							<Input
								value={profitPercentage}
								name="profitPercentage"
								onBlur={validationBlur}
								onChange={this.bindState('profitPercentage')}
							/>
							<FormHelperText>
								{validation.profitPercentage}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{ft.hasDueDate && (
					<Grid item xs={6}>
						<MobileDatePicker
							value={dueDate}
							inputFormat="DD-MM-YYYY"
							showToolbar={false}
							showTodayButton
							onChange={this.bindStateDate('dueDate')}
							closeOnSelect
							componentsProps={{
								actionBar: {
									actions: ['cancel'],
								},
							}}
							renderInput={(props) => (
								<TextField
									{...props}
									helperText={validation.dueDate}
									error={Boolean(validation.dueDate)}
									label="Due date"
									name="dueDate"
									required
									fullWidth
									onBlur={validationBlur}
								/>
							)}
						/>
					</Grid>
				)}
				{ft.hasSinglePayment && (
					<Grid item xs={6}>
						<FormControl
							error={!!validation.payment}
							fullWidth
							required
						>
							<InputLabel>Payment</InputLabel>
							<Input
								value={payment}
								type="number"
								name="payment"
								onBlur={validationBlur}
								onChange={this.bindState('payment')}
								endAdornment={(
									<InputAdornment position="end">
										<InfoTooltip infoId={6} />
									</InputAdornment>
								)}
							/>
							<FormHelperText>
								{validation.payment}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				{ft.hasMonthlySalary && (
					<Grid item xs={6}>
						<FormControl
							error={!!validation.monthlySalary}
							fullWidth
							required
						>
							<InputLabel>Monthly salary</InputLabel>
							<Input
								value={monthlySalary}
								type="number"
								name="monthlySalary"
								onBlur={validationBlur}
								onChange={this.bindState('monthlySalary')}
							/>
							<FormHelperText>
								{validation.monthlySalary}
							</FormHelperText>
						</FormControl>
					</Grid>
				)}
				<Grid item xs={6}>
					<PaymentStrategySelect
						label={t('manage.company.functions.fields.paymentStrategy')}
						onChangeDirect={(name, val) => this.bindStateDirect(name)(val)}
						name="paymentStrategy"
						value={paymentStrategy}
						fullWidth
					/>
				</Grid>
				{!bindSave && (
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							onClick={this.createApply}
						>
							Create application
						</Button>
					</Grid>
				)}
			</Grid>
		);
	};

	render() {
		const { workingScope, data: functionTemplates,  validation, validationBlur, customForm, location, formError, saving } = this.props;
		const { functionTemplateId, contractTemplateIds, doNotApplyTemplate, name, surname, middleName, email, startDate, endDate } = this.state;

		if (!workingScope) return (
			<Alert type={Alert.TYPE_INFO}>Select a scope first.</Alert>
		);

		if (!hasScopeCapability(workingScope, capabilities.CreateApplicationCapability)) return (
			<Alert type={Alert.TYPE_WARNING}>You do not have the permission to create an application on this scope.</Alert>
		);

		const ft = getFunctionTemplate(functionTemplates, functionTemplateId);

		const backLinkTo = location && location.state && location.state.employee
			? `/portal/employees/${location.state.employee.id}/view`
			: '/portal/applications';

		return (
            <>
                <Grid container>
					<Grid item xs={12}>
						<PageHeader>
							<PageHeaderMenu
								items={[
									!customForm && { name: 'Back', icon: 'arrow_left', to: backLinkTo },
								]}
							/>
							<PageHeaderTitle
								headline="Create new Application"
								subHeading={`Create a new application with the details below for the scope ${workingScope.name}.`}
							/>
						</PageHeader>
					</Grid>
				</Grid>
                {saving ? (
					<Loader>Creating application...</Loader>
				) : (
					<form>
						<ApiError error={formError} />
						<StyledPaper className={classes.section}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className={classes.sectionTitle}>
										Function data
									</div>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth required>
										<InputLabel>{`Function ${functionTemplates ? '' : ' (loading)'}`}</InputLabel>
										<Select
											value={functionTemplateId || ''}
											onChange={this.bindStateSelect('functionTemplateId')}
										>
											{this.handleFunctionTemplates(functionTemplates).map(func => (
												<MenuItem key={func.id} value={func.id}>{func.name}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<TextField
										label={customForm ? 'Custom scope' : 'Scope (change at the top)'}
										value={workingScope.name ? workingScope.name : ''}
										disabled
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={6}>
									<ApplicationsContractTemplateSelector
										functionTemplate={ft}
										onChangeContractTemplateIds={this.bindStateDirect('contractTemplateIds')}
										onChangeDoNotApplyTemplate={this.bindStateDirect('doNotApplyTemplate')}
										contractTemplateIds={contractTemplateIds}
										doNotApplyTemplate={doNotApplyTemplate}
										validation={validation}
									/>
								</Grid>
							</Grid>
						</StyledPaper>
						<StyledPaper className={classes.section}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className={classes.sectionTitle}>
										Employee data
										<br />
										<span>For existing employees: use the same E-Mail.</span>
									</div>
								</Grid>
								<Grid item xs={6}>
									<FormControl
										error={!!validation.name}
										fullWidth
										required
									>
										<InputLabel>Name</InputLabel>
										<Input
											value={name}
											name="fname"
											autoComplete="off"
											onBlur={validationBlur}
											onChange={this.bindState('name')}
										/>
										<FormHelperText>
											{validation.name}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl
										error={!!validation.surname}
										fullWidth
										required
									>
										<InputLabel>Surname</InputLabel>
										<Input
											value={surname}
											name="lname"
											autoComplete="off"
											onBlur={validationBlur}
											onChange={this.bindState('surname')}
										/>
										<FormHelperText>
											{validation.surname}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl
										error={!!validation.middleName}
										fullWidth
									>
										<InputLabel>Middle name</InputLabel>
										<Input
											value={middleName}
											name="mname"
											autoComplete="off"
											onBlur={validationBlur}
											onChange={this.bindState('middleName')}
										/>
										<FormHelperText>
											{validation.middleName}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl
										error={!!validation.email}
										fullWidth
										required
									>
										<InputLabel>E-Mail</InputLabel>
										<Input
											value={email}
											name="email"
											autoComplete="off"
											onBlur={validationBlur}
											type="email"
											onChange={this.bindState('email')}
										/>
										<FormHelperText>
											{validation.email}
										</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
						</StyledPaper>
						<StyledPaper className={classes.section}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className={classes.sectionTitle}>
										Contract dates
									</div>
								</Grid>
								<Grid item xs={6}>
									<MobileDatePicker
										value={startDate}
										onChange={this.bindStateDate('startDate')}
										inputFormat="DD-MM-YYYY"
										showToolbar={false}
										showTodayButton
										closeOnSelect
										componentsProps={{
											actionBar: {
												actions: ['cancel'],
											},
										}}
										renderInput={(props) => (
											<TextField
												{...props}
												label="Contract start date"
												required
												fullWidth
											/>
										)}
									/>
								</Grid>
								{endDate !== null && (
									<Grid item xs={6}>
										<MobileDatePicker
											value={endDate}
											onChange={this.bindStateDate('endDate')}
											inputFormat="DD-MM-YYYY"
											showToolbar={false}
											showTodayButton
											minDate={startDate}
											closeOnSelect
											componentsProps={{
												actionBar: {
													actions: ['cancel'],
												},
											}}
											renderInput={(props) => (
												<TextField
													{...props}
													name="endDate"
													label="Contract end date"
													required
													fullWidth
													error={Boolean(props.error)}
													helperText={props.error && 'End date should not be before start date'}
												/>
											)}
										/>
									</Grid>
								)}
								<Grid item xs={6}>
									<FormControlLabel
										label="Contract has ending date"
										control={(
											<Switch
												checked={(endDate !== null)}
												onChange={this.onEndDateToggle}
												color="primary"
											/>
										)}
									/>
								</Grid>
							</Grid>
						</StyledPaper>
						<StyledPaper className={classes.section}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className={classes.sectionTitle}>
										Specific function agreements
										<br />
										<span>Normally it is not allowed to deviate from the standard value.</span>
									</div>
								</Grid>
							</Grid>
							{this.renderFunctionSpecifics()}
						</StyledPaper>
					</form>
				)}
            </>
        );
	}
}

ApplicationsCreateForm.propTypes = {
	workingScope: PropTypes.object,
	functionFilter: PropTypes.func,
	preSelectFunctionFilter: PropTypes.func,
	customForm: PropTypes.bool,
	bindSave: PropTypes.func,
	toggleSaving: PropTypes.func,
	handleSuccess: PropTypes.func,
	navigate: PropTypes.func,
};

export default compose(
	withTranslation(),
	withRouter,
	withFormData(),
	withFetchData(null, {
		customId: () => 'applicationsCreateForm',
	}),
)(withValidation(ApplicationsCreateForm, {
	name: Joi.string().required(),
	middleName: Joi.string().allow(''),
	surname: Joi.string().required(),
	email: Joi.string().email().required(),

	scopeId: Joi.number(),
	functionTemplateId: Joi.number().required().label('Function'),
	doNotApplyTemplate: Joi.boolean().required(),
	contractTemplateIds: Joi.when('doNotApplyTemplate', {
		is: false,
		then: Joi.array().items(Joi.number()).min(1),
	}),

	startDate: Joi.date().required(),
	endDate: Joi.date().allow(null),

	baseSalary: Joi.number().allow(''),
	bonusSalary: Joi.number().allow(''),
	turnoverPercentage: Joi.number().allow(''),
	bonusTurnoverPercentage: Joi.number().allow(''),
	profitPercentage: Joi.number().allow(''),

	dueDate: Joi.when('hasDueDate', {
		is: true,
		then: Joi.date().required(),
	}),
	payment: Joi.when('hasSinglePayment', {
		is: true,
		then: Joi.number().min(0).max(Joi.ref('maximumFee')).required(),
	}),
	monthlySalary: Joi.when('hasMonthlySalary', {
		is: true,
		then: Joi.number().min(0).max(5000).required(),
	}),
	paymentStrategy: Joi.string(),
}));
