import { without } from 'ramda'
import { getApiTypeSuccess } from '../../helpers/types'
import { SCOPE_TIME_SLOT } from '../../actions/scopeEditActions'
import { TIME_SLOTS } from './timeSlotsActions'

const defaultState = {};

export function timeSlotsReducerFinal(state = defaultState, action){
	if(action.type === getApiTypeSuccess(TIME_SLOTS) && action.groupId && action.response){
		return {
			...state,
			[action.groupId]: action.response.result,
		};
	}

	// Time slot CRUD actions side effect handling
	if(action.type === getApiTypeSuccess(SCOPE_TIME_SLOT) && action.response){

		if(action.addToGroupId){
			state = {
				...state,
				[action.addToGroupId]: [
					...state[action.addToGroupId],
					action.response.result,
				],
			};
		}

		if(action.replaceAtGroupId){
			state = {
				...state,
				[action.replaceAtGroupId]: action.response.result,
			};
		}

		if(action.deleteFromGroup){
			const d = action.deleteFromGroup;
			state = {
				...state,
				[d.groupId]: state[d.groupId]
					? without([d.timeSlotId], state[d.groupId])
					: undefined,
			};
		}

	}

	return state;
}

const timeSlotsReducer = {
	groupTimeSlotsMapping: timeSlotsReducerFinal,
};

export default timeSlotsReducer
