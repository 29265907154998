import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import withDialogShouldBeFullscreen from '../../hoc/withDialogShouldBeFullscreen'
import RoomEditForm from './RoomEditForm'
import { find } from 'ramda'

class RoomEditModal extends Component {
	saveBinder = null;

	render(){
		const { rooms, currencyUnit, modal, handleClose, fullScreen } = this.props;
		const room = rooms && find(r => r.id === modal.roomId)(rooms);

		return (
			<Dialog open={true} onClose={(e, reason) => reason !== 'backdropClick' && handleClose(e, reason)} maxWidth="md" fullWidth fullScreen={fullScreen}>
				<DialogTitle>Edit {room.name}</DialogTitle>
				<DialogContent>
					<RoomEditForm
						inputData={{
							...room,
							price: room.price.amount,
						}}
						bindSave={(func) => this.saveBinder = func}
						onSave={handleClose}
						currencyUnit={currencyUnit}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={() => {if(this.saveBinder) this.saveBinder()}}
							color="primary"
							variant="contained">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default withDialogShouldBeFullscreen()(RoomEditModal)
