function fallbackCopyTextToClipboard(text, cb) {
	const textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		if(document.execCommand('copy')) {
			cb(true);
		}else{
			console.error('Could not copy fallback to clipboard 2');
		}
	} catch (err) {
		console.error('Could not copy fallback to clipboard');
	}

	document.body.removeChild(textArea);
}

export function copyTextToClipboard(text, cb) {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text, cb);
		return;
	}

	navigator.clipboard.writeText(text).then(function() {
		cb(true);
	}, function() {
		console.error('Could not copy text to clipboard');
	});
}
