import { chipClasses } from '@mui/material';
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'ramda'
import moment from 'moment'
import Chip from '@mui/material/Chip'
import withRouter from '../../hoc/withRouter'
import withFetchData from '../../modules/fetchData/withFetchData'
import { orderEntryList } from '../../actions/feedActions'
import { formatMoney, formatPercentage } from '../../helpers/formatter'
import Alert from '../util/alert/Alert'
import Loader from '../util/loader/Loader'
import fireworksBg from './media/fireworks-background.png';

const PREFIX = 'LiveSheetEndOfDay';

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    root: `${PREFIX}-root`,
    closeButton: `${PREFIX}-closeButton`,
    loading: `${PREFIX}-loading`,
    order: `${PREFIX}-order`,
    heading: `${PREFIX}-heading`,
    title: `${PREFIX}-title`,
    titleSub: `${PREFIX}-titleSub`,
    orderDate: `${PREFIX}-orderDate`,
    orderMeta: `${PREFIX}-orderMeta`
};

const StyledWrapper = styled('div')(({ theme }) => ({
    [`&.${classes.wrapper}`]: {
		flex: 1,
		display: 'flex',
		background: '#FFFFFF',
	},

    [`&.${classes.root}`]: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(2),
		backgroundImage: `url(${fireworksBg})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		// backgroundColor: '#ba9d14',
		// backgroundBlendMode: 'color-dodge',
		margin: theme.spacing(2),
		boxShadow: theme.shadows[2],
		borderRadius: 6,
	},

    [`& .${classes.closeButton}`]: {
		...theme.typography.body1,
		position: 'absolute',
		right: 30,
		top: 30,
		'& .material-icons': {
			marginRight: theme.spacing(1),
		},
	},

    [`& .${classes.loading}`]: {
		'& .loader-image': {
			width: 100,
		},
	},

    [`& .${classes.order}`]: {
		textAlign: 'center',
		color: '#FFFFFF',
		width: '60%',
		fontSize: '1rem',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.7rem',
		},
	},

    [`& .${classes.heading}`]: {
		marginBottom: theme.spacing(7),
		fontSize: '5em',
		fontWeight: theme.typography.fontWeightMedium,
		fontFamily: 'Museo, arial, sans-serif',
		letterSpacing: '0.1em',
		background: '-webkit-linear-gradient(#F7E61B, #977C13)',
		'-webkit-background-clip': 'text',
		textFillColor: 'transparent',
	},

    [`& .${classes.title}`]: {
		...theme.typography.h3,
		fontSize: '5em',
		color: '#FFFFFF',
	},

    [`& .${classes.titleSub}`]: {
		...theme.typography.h4,
		fontSize: '2.5em',
		fontWeight: theme.typography.fontWeightLight,
		color: '#FFFFFF',
	},

    [`& .${classes.orderDate}`]: {
		marginTop: theme.spacing(3),
	},

    [`& .${classes.orderMeta}`]: {
		marginTop: theme.spacing(1),
		fontSize: '2em',
	}
}));

const Label = styled(Chip)(({ theme }) => ({
	[`&.${chipClasses.root}`]: {
		height: '2.5rem',
		[`$ + .${chipClasses.root}`]: {
			marginLeft: theme.spacing(1),
		},
	},
	[`& .${chipClasses.label}`]: {
		fontSize: '1.4rem',
	},
	[`&.${chipClasses.colorSecondary}`]: {
		backgroundColor: theme.palette.grey[300],
		color: theme.palette.secondary.dark,
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

const diffSort = (a, b) => b.diff - a.diff;

class LiveSheetEndOfDay extends Component {
	render(){
		const { location, navigate } = this.props;

		return (
			<StyledWrapper className={classes.wrapper}>
				<div className={classes.root}>
					{this.renderContent()}
				</div>
				<Button
					variant="contained"
					color="secondary"
					className={classes.closeButton}
					size="large"
					onClick={() => navigate(`${location.pathname}?${location.search}&noPartyTime`)}
				>
					<Icon>close</Icon> Close winner screen
				</Button>
			</StyledWrapper>
		);
	}

	renderContent(){
		const { partyTimeSettings, loading, data, } = this.props;

		if(loading || !data) return (
			<Loader className={classes.loading} />
		);

		const time = moment().hour(partyTimeSettings.hour).minute(partyTimeSettings.minute);
		const last = data
			.map(item => ({
				...item,
				diff: moment(item.orderDateTime).diff(time, 'second'),
			}))
			.filter(item => item.diff <= 0)
			.sort(diffSort)[0];

		return last ? (
			<div className={classes.order}>
				<div className={classes.heading}>
					And the winner is...!
				</div>
				<div className={classes.title}>
					{last.institute.split(' ').map(part => part.substr(0, 1)).join('')} / {last.study}
				</div>
				<div className={classes.titleSub}>
					{last.course} - {last.group}
				</div>
				<div className={classes.orderDate}>
					{moment(last.orderDateTime).format('D MMM YYYY [at] HH:mm:ss')}
				</div>
				<div className={classes.orderMeta}>
					<Label
                        label={`${last.soldUnits}/${last.availableUnits}`}
                        color={(last.availableUnits - last.soldUnits) <= 2 ? 'secondary' : undefined}
                    />
					<Label
                        label={formatMoney(last.paidPrice)}
                        color={last.paidPrice === 0 ? 'secondary' : undefined}
                    />
					{last.discountPercentage && (
						<Label
                            label={`${formatPercentage(last.discountPercentage)} discount`}
                            color="secondary"
                        />
					)}
				</div>
			</div>
		) : (
			<Alert>
				Hmm, there seems to be no last order.
			</Alert>
		);
	}
}

LiveSheetEndOfDay.propTypes = {
	partyTimeSettings: PropTypes.object.isRequired,
	rootScopeId: PropTypes.number.isRequired,
};

export default compose(
	withRouter,
	withFetchData((props) => orderEntryList(props.rootScopeId))
)(LiveSheetEndOfDay)
