import React, { Component } from 'react'
import moment from 'moment'
import { chain } from 'ramda'
import { withTranslation } from 'react-i18next';
import withFetchData from '../../modules/fetchData/withFetchData'
import withWorkingScope from '../../modules/scope/withWorkingScope'
import { getScopesTillGroupsTL } from '../../actions/taskListActions'
import { canHaveTasks } from '../../helpers/groupState'
import { sortByTime } from '../../helpers/sorts'
import FetchDataSwitch from '../util/FetchDataSwitch'
import { defineTask } from './utils'
import TaskListGroup from './TaskListGroup'
import Alert from '../util/alert/Alert'
import Grid from '@mui/material/Grid'
import PageHeader from '../page/PageHeader'
import PageHeaderTitle from '../page/PageHeaderTitle'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { possibleTasks } from './utils'
import Badge from '@mui/material/Badge'
import TablePagination from '@mui/material/TablePagination'
import Table from '@mui/material/Table'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'

class TaskListPage extends Component {

	/**
	 * @type {{summary: {}, filter: string, data: Array, count: {}}}
	 */
	state = {
		data: [],
		summary: {},
		count: [],
		filter: 'all',
		page: 0,
		rowsPerPage: 25,
	}

	componentDidMount() {
		const { workingScope, load } = this.props;
		if (workingScope && workingScope._class !== 'Company' && !workingScope._virtual) {
			load(getScopesTillGroupsTL(workingScope.id));
		}
	}

	componentDidUpdate(prevProps) {
		const
			{ workingScope: currentScope, load } = this.props,
			prevScope = prevProps.workingScope;

		if (currentScope && (!prevScope || currentScope.id !== prevScope.id) && currentScope._class !== 'Company' && !currentScope._virtual) {
			load(getScopesTillGroupsTL(currentScope.id));
		}

		const
			currentData = this.props.data,
			prevData = prevProps.data;

		if (!prevData && currentData || prevData !== currentData) {
			this.handleData(currentData);
		}
	}

	handleData = scope => {

		/**
		 * Start Recursive functions
		 **/
		const scopeInfo = scope => ({
			_class: scope._class,
			id: scope.id,
			name: scope._class.includes('AthenaStudiesCourse') ? scope.name : scope.abbreviation,
		})


		const getGroups = scope => {
			if (scope._class !== 'AthenaStudiesCourseGroup')
				return chain(subScope => getGroups({
					...subScope,
					[`id_${scope._class}`]: scope.id,
					parents: scope.parents
						? [...scope.parents, scopeInfo(subScope)]
						: [scopeInfo(scope), scopeInfo(subScope)],
				}), scope.subScopes).filter(group => group.picked && canHaveTasks(group.state))

			/**
			 * if (scope._class === 'AthenaStudiesCourseGroup')
			 */
			if (!canHaveTasks(scope.state)) return { picked: false }

			const group = { ...scope, ...defineTask(scope) }
			group.created = moment(group.created)
			group.firstStartDate = moment(group.courseStart)
			group.lastEndDate = moment(group.courseEnd)
			group.nextDeadline = false

			switch (group.picked) {
				case 'evaluation':
					group.deadline = group.lastEndDate.clone().endOf('month')
					break

				case 'teacher':
				case 'location':
					group.deadline = group.firstStartDate.clone().subtract(5, 'days')
					group.nextDeadline = group.firstStartDate.clone().subtract(2, 'days')
					break

				case 'audit':
					group.deadline = moment().endOf('day')
					break

				default:
					group.deadline = false
			}

			group.deadlinePassed = group.deadline && group.deadline.isBefore()
			group.betweenDeadlines = group.nextDeadline && moment().isBetween(group.deadline, group.nextDeadline)

			return group
		}
		/**
		 * END recursive functions
		 **/

		const reduce = getGroups(scope).reduce((acc, group, idx, src) => {
			if (group.deadline) acc.hasDeadline.push(group)
			else acc.other.push(group)

			if (acc.count[group.picked] === undefined) acc.count[group.picked] = 1
			else acc.count[group.picked]++

			if (src.length - 1 === idx) {
				// sorting
				acc.hasDeadline.sort(sortByTime('deadline'))
				acc.other.sort(sortByTime('created'))
			}

			return acc
		}, {
			count: {},
			hasDeadline: [],
			other: [],
		})

		this.setState({
			data: reduce.hasDeadline.concat(reduce.other),
			count: Object.entries(reduce.count)
			.sort(([a], [b]) => possibleTasks[b].priority - possibleTasks[a].priority),
		})
	}

	handleTabComponentValueChange = (event, value) => {
		this.setState({ filter: value })
	}

	handleChangePage = (event, page) => {
		this.setState({ page })
	}

	handleChangeRowsPerPage = event => {
		this.setState({ page: 0, rowsPerPage: event.target.value })
	}

	render() {
		const {
			state: { data, count, filter, page, rowsPerPage },
			props: { loading, error, workingScope, t },
		} = this;

		const filteredData = data.filter(filter === 'all' ? e => e : e => e.picked === filter)

		return (
			<Grid container spacing={2}>

				<Grid item xs={12}>
					<PageHeader>
						<PageHeaderTitle
							headline="Task List"
							subHeading={`The tasks within ${workingScope ? workingScope.name : ''}`}
						/>
					</PageHeader>
				</Grid>

				<Grid item xs={12}>
					{(!workingScope || workingScope._class === 'Company' || workingScope._virtual) ? (
						<Alert type={Alert.TYPE_INFO}>
							{workingScope?._virtual ? t('taskList.error.cannotDisplayVirtual') : t('taskList.error.cannotDisplayScope')}
						</Alert>
					) : (
						<FetchDataSwitch {...{ data, loading, error }}>
							{data && (
								<Grid container>

									<AppBar position={'sticky'} style={{ top: 64, zIndex: 1000 }} enableColorOnDark>
										<Tabs variant={'fullWidth'}
											  value={filter}
											  onChange={this.handleTabComponentValueChange}
											  indicatorColor="secondary" textColor="inherit"
										>
											<Tab value={'all'} label={(
												<Badge color="secondary" badgeContent={data.length}>
													All
												</Badge>
											)}/>

											{count.map(([key, num]) => (
												<Tab key={key} value={key} label={(
													<Badge color="secondary" badgeContent={num}>
														{possibleTasks[key].parsed}
													</Badge>
												)}/>
											))}

										</Tabs>

									</AppBar>

									{filteredData.filter((_, idx) => idx >= page * rowsPerPage && idx < (page + 1) * rowsPerPage)
									.map(group => (
										<Grid item sm={12} key={group.id} style={{ marginBottom: -8 * 2 }}>
											<TaskListGroup group={group}/>
										</Grid>
									))}

									<Table>
										<TableFooter>
											<TableRow>
												<TablePagination
													rowsPerPageOptions={[10, 25, 50, 100]}
													colSpan={3}
													count={filteredData.length}
													rowsPerPage={rowsPerPage}
													page={page}
													SelectProps={{
														native: true,
													}}
													onPageChange={this.handleChangePage}
													onRowsPerPageChange={this.handleChangeRowsPerPage}
												/>
											</TableRow>
										</TableFooter>
									</Table>

								</Grid>
							)}
						</FetchDataSwitch>
					)}
				</Grid>
			</Grid>
		)
	}

}

export default withWorkingScope(withFetchData()(withTranslation()(TaskListPage)));
