import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'
import Alert from '../../util/alert/Alert'
import ZoomMeetingDelete from './ZoomMeetingDelete'
import ZoomMeetingRedirectHostStart from './ZoomMeetingRedirectHostStart'
import ZoomMeetingTakeOver from './ZoomMeetingTakeOver'

const PREFIX = 'ZoomMeeting';

const classes = {
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    textBlock: `${PREFIX}-textBlock`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.textBlock}`]: {
		...theme.typography.body1,
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(3),
	}
}));

const OptionsBlock = styled(({ className, title, description, children, color }) => (
	<div className={classNames(className, classes.root, color)}>
		<div className={classes.title}>
			{title}
		</div>
		<div className={classes.content}>
			{description && (
				<Typography variant="body2" paragraph color="textSecondary">
					{description}
				</Typography>
			)}
			{children}
		</div>
	</div>
))(({ theme }) => ({
	[`&.${classes.root}`]: {
		position: 'relative',
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius,
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(4),
		'&.info': {
			borderColor: theme.palette.info.main,
		},
		'&.warning': {
			borderColor: theme.palette.warning.main,
		},
		'&.danger': {
			borderColor: theme.palette.error.main,
		},
	},

	[`& .${classes.title}`]: {
		...theme.typography.body1,
		position: 'absolute',
		top: 0,
		left: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1),
		transform: 'translateY(-50%)',
	},

	[`& .${classes.content}`]: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
	}
}));

const createJoinUrl = (meetingId) => {
	return `https://athenastudies.zoom.us/j/${meetingId}`;
};

const ZoomMeeting = ({ timeSlot, }) => {
	if(!timeSlot.zoomMeetingId) return (
		<Alert>This time slot does not have a Zoom meeting planned.</Alert>
	);

    return (
        <Root>
			<div className={classes.textBlock}>
				<p>The tutor can start an Online class as Host:</p>
				<ul>
					<li>from the Teachers Agenda after logging into <a href="https://portal.athenastudies.nl" target="_blank">portal.athenastudies.nl</a></li>
					<li>from the Upcoming Meetings overview after logging into <a href="https://athenastudies.zoom.us" target="_blank">athenastudies.zoom.us</a></li>
					<li>by clicking on the link in the automatically sent e-mail for each Online class</li>
				</ul>
			</div>

			{timeSlot.zoomHostId !== timeSlot.teacher.zoomAccountId && (
				<Alert type={Alert.TYPE_DANGER} icon="error">
					<strong>Watch out!</strong> The host of this meeting does not seem to match the current tutor for this timeslot.
					As a result, the tutor may not have received an email with startup instructions and <strong>will not</strong> be able to start the meeting from the Zoom website.
					The teacher <strong>will</strong> however be able to start the meeting through the Teachers Agenda in Portal.
					Alternatively, if it's more than 24 hours prior to the course, the meeting can be deleted and rescheduled with the buttons below.
				</Alert>
			)}

			{timeSlot.zoomStatus !== 'LICENSE_ASSIGNED' && timeSlot.zoomStatus !== 'INVITES_SENT' && <Alert type={Alert.TYPE_INFO} icon="schedule">License is not yet assigned to meeting host. Not all Zoom features will be available yet. The license will be automatically assigned at most 1 hour prior to the course.</Alert>}
			{timeSlot.zoomStatus === 'LICENSE_ASSIGNED' || timeSlot.zoomStatus === 'INVITES_SENT' && <Alert type={Alert.TYPE_SUCCESS} icon="check">License is assigned to meeting host. All Zoom features are enabled.</Alert>}

			{timeSlot.zoomStatus !== 'INVITES_SENT' && <Alert type={Alert.TYPE_INFO} icon="schedule">Meeting invites have not been sent to students yet. This will automatically happen when the license gets assigned to the host.</Alert>}
			{timeSlot.zoomStatus === 'INVITES_SENT' && <Alert type={Alert.TYPE_SUCCESS} icon="check">Meeting invites have been sent to students.</Alert>}

			<OptionsBlock
                title="Join as Host"
                description={(
					<Fragment>
						By clicking this button, you can start or join the Online class as Host.
						This is useful when the tutor is unable to start or join the Online class or when it’s necessary to check if the Online class is running smoothly without interrupting the tutor.
					</Fragment>
				)}
            >
				<ZoomMeetingRedirectHostStart timeSlot={timeSlot} />
			</OptionsBlock>

			{(timeSlot.zoomJoinUrl && (<OptionsBlock
                title="Join link for students"
                description={(<Fragment>
					Students will receive a personal participation link by e-mail for each Online class 24 hours before the start of the timeslot.
					Students can also log into <em>My AthenaStudies</em> to join the Online class from there.
					Please point students to these two options if they try to get access to their online class.
					As a last resort, the below Join link can be shared. It is however important to know that by joining through this link, a customer will first have to fill in a registration form.
					Therefore, the alternative options are always preferred.
				</Fragment>)}
                color="warning"
            >
				<div>
					<a href={timeSlot.zoomJoinUrl} target="_blank">{timeSlot.zoomJoinUrl}</a>
				</div>
				{timeSlot.zoomPasscode && <div>
					<strong>Passcode: </strong>
					<span>{timeSlot.zoomPasscode}</span>
				</div>}
			</OptionsBlock>))}

			<OptionsBlock
                title="Generate host key"
                color="danger"
                description={(<Fragment>
					If the above methods do not work, this option can be used to generate a host key which can be used to get Host privileges while joining the Zoom meeting as a regular participant.
					It also enables join before host so that participants can join the meeting without the host being present.
				</Fragment>)}
            >
				<ZoomMeetingTakeOver timeSlot={timeSlot} joinUrl={createJoinUrl(timeSlot.zoomMeetingId)} />
			</OptionsBlock>

			<OptionsBlock
                title="Delete online class"
                color="danger"
                description={(<Fragment>
					By clicking this button, you can delete this Online class.

					Replacing this Online class with a new Online class afterwards will generate new participation links for students and a new starting link for the (new) tutor.
					If you do this within 24 before the start of the new Online class, make sure to refer the students to My AthenaStudies to join the Online class from there because the instructions that will have been sent automatically will become incorrect.
				</Fragment>)}
            >
				<ZoomMeetingDelete timeSlot={timeSlot} />
			</OptionsBlock>
		</Root>
    );
};

ZoomMeeting.propTypes = {
	timeSlot: PropTypes.object.isRequired,
};

export default (ZoomMeeting)
