import React from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '@mui/material/Icon'
import { darken, lighten } from '@mui/material/styles'

const PREFIX = 'AlertFinal';

const classes = {
    alert: `${PREFIX}-alert`,
    icon: `${PREFIX}-icon`
};

const getAlertColor = color => ({
	backgroundColor: lighten(color, 0.3),
	color: darken(color, 0.7),
})

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.alert}`]: {
		padding: theme.spacing(1, 2),
		marginBottom: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
		border: 'none',
		'& p': {
			marginTop: theme.spacing(0.25),
		},
		'& p:last-child': {
			marginBottom: theme.spacing(0.5),
		},
		'&.noMargin': {
			margin: 0,
		},
		'&.withIcon': {
			display: 'flex',
			alignContent: 'center',
			alignItems: 'center',
			'& > div': {
				flex: 1,
			},
			[theme.breakpoints.down('sm')]: {
				paddingLeft: theme.spacing(1),
			},
		},
		'&.info': {
			...getAlertColor(theme.palette.info.main),
			'& a': {
				color: 'inherit',
			},
			[`& .${classes.icon}`]: {
				color: theme.palette.info.main,
			},
		},
		'&.warning': {
			...getAlertColor(theme.palette.warning.main),
			[`& .${classes.icon}`]: {
				color: theme.palette.warning.main,
			},
		},
		'&.danger': {
			...getAlertColor(theme.palette.error.main),
			[`& .${classes.icon}`]: {
				color: theme.palette.error.main,
			},
		},
		'&.success': {
			...getAlertColor(theme.palette.success.main),
			[`& .${classes.icon}`]: {
				color: theme.palette.success.main,
			},
		},
	},

    [`& .${classes.icon}`]: {
		fontSize: '3em',
		marginRight: theme.spacing(1),
	}
}));

const Alert = ({ type, noMargin, icon, children, className }) => {

	return (
        <Root className={classNames(classes.alert, className, {
			[type || 'info']: true,
			noMargin: noMargin,
			withIcon: Boolean(icon),
		})}>
			{Boolean(icon) ? (
				<>
					<Icon className={classes.icon}>{icon}</Icon>
					<div>{children}</div>
				</>
			) : children}
		</Root>
    );
}

Alert.propTypes = {
	type: PropTypes.oneOf(['info', 'warning', 'danger', 'success']),
	noMargin: PropTypes.bool,
	withIcon: PropTypes.string,
	children: PropTypes.PropTypes.node.isRequired,
};

Alert.defaultTypes = {
	noMargin: false,
};

const AlertFinal = Alert;

AlertFinal.TYPE_INFO = 'info';
AlertFinal.TYPE_WARNING = 'warning';
AlertFinal.TYPE_DANGER = 'danger';
AlertFinal.TYPE_SUCCESS = 'success';

export default AlertFinal
