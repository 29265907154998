import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { mapObjIndexed } from 'ramda';
import React, { useCallback } from 'react';
import { parse } from 'query-string';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { languages } from '../../../helpers/localization';
import Alert from '../../util/alert/Alert';
import ApplicationForm from './ApplicationForm';

const PREFIX = 'ApplicationPage';

const classes = {
    container: `${PREFIX}-container`,
    paper: `${PREFIX}-paper`,
    language: `${PREFIX}-language`,
    warning: `${PREFIX}-warning`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.container}`]: {
		maxWidth: 1024,
		margin: '16px auto',
		padding: theme.spacing(1),
	},

    [`& .${classes.paper}`]: {
		position: 'relative',
		padding: theme.spacing(2),
	},

    [`& .${classes.language}`]: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			position: 'static',
			textAlign: 'right',
		},
	},

    [`& .${classes.warning}`]: {
		margin: theme.spacing(8, 0, 2, 0),
	}
}));

const ApplicationPage = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const { hash } = parse(location.search);

	const handleChangeLanguage = useCallback((e) => {
		i18n.changeLanguage(e.target.value);
	}, [i18n.changeLanguage]);

	return (
        <Root className={classes.container}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<div className={classes.language}>
							<FormControl>
								<InputLabel>{t('application.signForm.language')}</InputLabel>
								<Select
									value={i18n.language}
									onChange={handleChangeLanguage}
								>
									{Object.values(mapObjIndexed((label, locale) => (
										<MenuItem value={locale} key={locale}>{label}</MenuItem>
									), languages))}
								</Select>
							</FormControl>
						</div>
						{hash ? (
							<ApplicationForm hash={hash} />
						) : (
							<Alert type={Alert.TYPE_WARNING} className={classes.warning} icon="info">
								{t('application.signForm.error.hash')}
							</Alert>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Root>
    );
};

export default ApplicationPage;
