import { stringify } from 'query-string';
import { createSearchParams } from '../helpers/properties';
import { CALL_API } from '../setup/api';

const EMPLOYEE_LIST = 'EMPLOYEE_LIST';

// eslint-disable-next-line import/prefer-default-export
export function fetchEmployees(
	scope,
	traverseUp, traverseDown, includeInactive,
	properties = [],
	searchTerm,
) {
	const params = createSearchParams(properties);
	return {
		[CALL_API]: {
			type: EMPLOYEE_LIST,
			endpoint: `scope/${scope.id}/employee/v3?${stringify({
				traverseUp,
				traverseDown,
				includeInactive,
				searchTerm: searchTerm || undefined,
			})}${params.toString().length > 0 ? `&${params.toString()}` : ''}`,
			pagination: {
				page: 0,
				pageSize: 200,
			},
		},
	};
}
