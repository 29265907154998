import { TableCell } from '@mui/material';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { clone, move } from 'ramda';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { composePatchScope, editScopeTypes } from '../../../../actions/scopeEditActions';
import { languages } from '../../../../helpers/localization';
import withFormData from '../../../../modules/formData/withFormData';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import Loader from '../../../util/loader/Loader';
import CompanyFAQSection from './CompanyFAQSection';

const defaultSection = {
	title: {
		en: 'New title',
		nl: 'Nieuwe titel',
	},
	items: [],
};

const CompanyFAQTable = ({ topLevelScope, watchSubmit, saving }) => {
	const { t } = useTranslation();
	const faq = useMemo(() => topLevelScope?.properties?.faq || [], [topLevelScope?.properties?.faq]);
	const [addNewSection, setAddNewSection] = useState(false);
	const handleAddSection = () => {
		setAddNewSection(!addNewSection);
	};

	useEffect(() => {
		setAddNewSection(false);
	}, [faq.length]);

	const faqArr = [...faq];

	if (addNewSection) {
		faqArr.push(defaultSection);
	}

	const handleSectionOrderChange = useCallback(event => {
		const properties = clone(topLevelScope.properties);
		const { destination, source } = event;
		if (event.type === 'ITEMS') {
			const sourceSec = parseInt(source.droppableId.split('_')[1], 10);
			const destSection = parseInt(destination.droppableId.split('_')[1], 10);

			if (sourceSec !== destSection) {
				// Move to other section
				const item = properties.faq[sourceSec].items[event.source.index];
				properties.faq[sourceSec].items.splice(event.source.index, 1); // Remove item from source section

				if (properties.faq.length === destSection) {
					// New section
					properties.faq.push({ ...defaultSection, items: [item] });
				} else {
					properties.faq[destSection].items.splice(event.destination.index, 0, item); // Add item to destination section
				}
			} else {
				// Moved within same section
				properties.faq[destSection].items = move(event.source.index, event.destination.index, properties.faq[destSection].items);
			}
		} else {
			// Move section
			properties.faq = move(event.source.index, event.destination.index, [...faq]);
		}

		watchSubmit(composePatchScope(editScopeTypes.COMPANY)(topLevelScope.id, {
			properties,
		}));
	}, [faq, topLevelScope.id, topLevelScope.properties, watchSubmit]);

	return (
		<>
			{ saving && <Loader sheet /> }
			<DragDropContext onDragEnd={handleSectionOrderChange}>
				<Droppable droppableId="sections">
					{(provided) => (
						<Table style={{ tableLayout: 'fixed' }} size="small" ref={provided.innerRef} {...provided.droppableProps}>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: 70 }} />
									<TableCell style={{ width: 70 }}>Q/A</TableCell>
									{Object.keys(languages).map(lang => (
										<TableCell style={{ width: '100%' }} key={lang}>{languages[lang]}</TableCell>
									))}
									<TableCell style={{ width: 120 }} />
								</TableRow>
							</TableHead>
							<>
								{faqArr?.length === 0 && (
									<TableBody>
										<TableRow>
											<TableCell colSpan={10}>{t('manage.company.faq.empty')}</TableCell>
										</TableRow>
									</TableBody>
								)}

								{faqArr?.map((section, sectionIndex) => (
									<CompanyFAQSection
										faq={faq}
										section={section}
										sectionIndex={sectionIndex}
										setAddNewSection={setAddNewSection}
										isNewSection={sectionIndex === faq.length}
										key={sectionIndex}
									/>
								))}
								{provided.placeholder}
							</>
						</Table>
					)}
				</Droppable>
			</DragDropContext>
			{!addNewSection && (
				<Button style={{ margin: '1rem' }} variant="contained" color="primary" startIcon={<Icon>add</Icon>} onClick={handleAddSection}>
					{t('manage.company.faq.addNewSection')}
				</Button>
			)}
		</>
	);
};

export default withWorkingScope(withFormData()(CompanyFAQTable));
