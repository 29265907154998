import { cardContentClasses, tableCellClasses, tableRowClasses } from '@mui/material';
import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../../../helpers/formatter'
import { niceDateTime } from '../../../helpers/datetime'
import ScopeVisibilitySwitcher from '../../util/ScopeVisibilitySwitcher'
import GroupOccupancy from '../../util/GroupOccupancy'
import GroupProblemIndicator from '../../util/GroupProblemIndicator'
import Alert from '../../util/alert/Alert'
import ScopeTooltipTabsBar from './ScopeTooltipTabsBar'
import ScopeTooltipScopeGroupTimeSlots from './ScopeTooltipScopeGroupTimeSlots'

const TableTitleCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.root}`]: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	[`&.${tableCellClasses.sizeSmall}`]: {
		padding: theme.spacing(0.25, 2),
	},
}));

const TableDenseCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.sizeSmall}`]: {
		padding: theme.spacing(0.25, 2),
	},
}));

const TableDenseRow = styled(TableRow)(({ theme }) => ({
	[`&.${tableRowClasses.root}`]: {
		height: theme.spacing(5),
	},
}));

const CardContentNoBottom = styled(CardContent)(({ theme }) => ({
	[`&.${cardContentClasses.root}`]: {
		paddingBottom: theme.spacing(1),
	},
}));

const ScopeTooltipScopeGroup = ({ scope, activeTab, onTabChange }) => {
	const { t } = useTranslation();
	return (
        <>
			<ScopeTooltipTabsBar
				tabs={['Information', `Timeslots (${scope.numTimeSlots})`]}
				activeTab={activeTab}
				onTabChange={onTabChange}
			/>
			{activeTab === 0 ? (
				<Fragment>
					{scope.evaluation && (
						<CardContentNoBottom>
							<Alert noMargin>
								This group has been evaluated with a <strong>{scope.evaluation}</strong>.
							</Alert>
						</CardContentNoBottom>
					)}
					{scope.state === 'CANCELLED' && (
						<CardContentNoBottom>
							<Alert type={Alert.TYPE_WARNING} noMargin>
								This group has been <strong>cancelled</strong>.
							</Alert>
						</CardContentNoBottom>
					)}
					<Table size="small">
						<TableBody>
							<TableDenseRow>
								<TableTitleCell>Price</TableTitleCell>
								<TableDenseCell>{formatMoney(scope.price)}</TableDenseCell>
							</TableDenseRow>
							<TableDenseRow>
								<TableTitleCell>Occupancy</TableTitleCell>
								<TableDenseCell><GroupOccupancy group={scope} showManipulated /></TableDenseCell>
							</TableDenseRow>
							<TableDenseRow>
								<TableTitleCell>Start date</TableTitleCell>
								<TableDenseCell>{niceDateTime(scope.courseStart)}</TableDenseCell>
							</TableDenseRow>
							<TableDenseRow>
								<TableTitleCell>Status</TableTitleCell>
								<TableDenseCell>{t(`groupStates.${scope.state}.name`)}</TableDenseCell>
							</TableDenseRow>
						</TableBody>
					</Table>
				</Fragment>
			) : (
				<ScopeTooltipScopeGroupTimeSlots groupId={scope.id} />
			)}
			{activeTab === 0 && (
				<CardActions>
					<ScopeVisibilitySwitcher scope={scope} noMargins />
					<GroupProblemIndicator group={scope} />
				</CardActions>
			)}
		</>
    );
}

export default ScopeTooltipScopeGroup
