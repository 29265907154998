import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import withWorkingScope from '../../../../modules/scope/withWorkingScope';
import ModalItem from '../../../modal/ModalItem';
import ModalManager from '../../../modal/ModalManager';
import PageContentBlock from '../../../page/PageContentBlock';
import PageHeader from '../../../page/PageHeader';
import PageHeaderTitle from '../../../page/PageHeaderTitle';
import Alert from '../../../util/alert/Alert';
import CompanyFAQItemModal from './CompanyFAQItemModal';
import CompanyFAQTable from './CompanyFAQTable';

const CompanyFAQPage = ({ topLevelScope }) => {
	const { t } = useTranslation();

	if (!topLevelScope) {
		return (
			<Alert>
				First select a scope in the scope selector at the top of the page.
			</Alert>
		);
	}

	return (
		<>
			<ModalManager>
				<ModalItem state="editFAQItem" component={CompanyFAQItemModal} />
			</ModalManager>
			<PageHeader>
				<PageHeaderTitle headline={t('manage.company.faq.title', { name: topLevelScope.name })} />
			</PageHeader>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PageContentBlock>
						<CompanyFAQTable />
					</PageContentBlock>
				</Grid>
			</Grid>
		</>
	);
};

export default withWorkingScope(CompanyFAQPage);
