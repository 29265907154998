import { Schemas, CALL_API } from './schemas'
import { fetchDataAppend, fetchDataDelete } from '../modules/fetchData/fetchDataActions'
import { getApiTypeSuccess } from '../helpers/types'

export const COST = 'COST';

export const getCostAccounts = () => ({
	[CALL_API]: {
		type: COST,
		endpoint: `financial-accounts`
	}
});

export const getCosts = (scopeId) => ({
	[CALL_API]: {
		type: COST,
		endpoint: `scope/${scopeId}/cost`,
		schema: [Schemas.SCOPE_COST]
	}
});

/**
 * Create new costs for a scope and account
 *
 * @param scopeId
 * @param accountId
 * @param data object:{ float:amount, string:description, object|null:paidTo }
 * @param fetchDataId
 */
export const postCost = (scopeId, accountId, data, fetchDataId) => (dispatch) => {
	return dispatch({
		[CALL_API]: {
			type: COST,
			endpoint: `scope/${scopeId}/account/${accountId}/cost`,
			schema: Schemas.SCOPE_COST,
			body: data,
		}
	}).then(res => {
		if(res.type === getApiTypeSuccess(COST)){
			dispatch(fetchDataAppend(
				fetchDataId,
				res && res.response && res.response.result
			));
		}

		return res;
	});
};

export const deleteCost = (scopeId, accountId, costId, fetchDataId) => (dispatch) => {
	return dispatch({
		[CALL_API]: {
			type: COST,
			endpoint: `scope/${scopeId}/account/${accountId}/cost/${costId}`,
			method: 'DELETE'
		}
	}).then(res => {
		dispatch(fetchDataDelete(fetchDataId, costId));

		return res;
	});
};
