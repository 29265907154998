import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { choiceLabel, propertyLabel } from '../../helpers/properties';

const PropertyFilter = ({ propertyKey, propertyValue, propertiesDefinition, handleProperty }) => {
	const property = propertiesDefinition?.find(prop => prop.key === propertyKey) || {};
	const { t, i18n } = useTranslation();

	const handlePropertyChange = (prop, value) => () => {
		let array = propertyValue || [];
		const index = array.indexOf(value);
		if (index > -1) {
			array.splice(index, 1);
		} else {
			array.push(value);
		}
		array = array.length !== 0 ? array : undefined;

		handleProperty(prop, array);
	};

	if (property.type === 'boolean') {
		return (
			<>
				<ListSubheader>{propertyLabel(property, i18n.language)}</ListSubheader>
				<ListItem dense button onClick={handlePropertyChange(property.key, true)}>
					<ListItemIcon>
						<Checkbox
							edge="start"
							checked={(propertyValue || []).indexOf(true) > -1}
							tabIndex={-1}
							disableRipple
							inputProps={{ 'aria-labelledby': property.key }}
							size="small"
							color="secondary"
						/>
					</ListItemIcon>
					<ListItemText id={property.key} primary={t('general.yes')} />
				</ListItem>
				<ListItem dense button onClick={handlePropertyChange(property.key, false)}>
					<ListItemIcon>
						<Checkbox
							edge="start"
							checked={(propertyValue || []).indexOf(false) > -1}
							tabIndex={-1}
							disableRipple
							inputProps={{ 'aria-labelledby': property.key }}
							size="small"
							color="secondary"
						/>
					</ListItemIcon>
					<ListItemText id={property.key} primary={t('general.no')} />
				</ListItem>
			</>
		);
	}
	return (
		<>
			<ListSubheader>{propertyLabel(property, i18n.language)}</ListSubheader>
			{property.options?.choices?.map(choice => {
				const key = choice?.key || choice;
				return (
					<ListItem
						dense
						button
						onClick={handlePropertyChange(property.key, key)}
						key={key}
					>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={(propertyValue || []).indexOf(key) > -1}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': key }}
								size="small"
								color="secondary"
							/>
						</ListItemIcon>
						<ListItemText id={key} primary={choiceLabel(choice, i18n.language)} />
					</ListItem>
				);
			})}
		</>
	);
};

PropertyFilter.propTypes = {
	propertyKey: PropTypes.string.isRequired,
	propertyValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.string]),
	propertiesDefinition: PropTypes.array.isRequired,
	handleProperty: PropTypes.func.isRequired,
};

export default PropertyFilter;
