import { InputAdornment, Link, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	SELECTION_CHANGE_COMMAND,
	$getSelection,
	$isRangeSelection,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { useTranslation } from 'react-i18next';
import getSelectedNode from '../helpers/selection';

const PREFIX = 'FloatingLinkEditor';

const classes = {
    linkEditor: `${PREFIX}-linkEditor`,
    linkInput: `${PREFIX}-linkInput`
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.linkEditor}`]: {
		position: 'fixed',
		zIndex: '100000',
		top: '-10000px',
		left: '-10000px',
		marginTop: theme.spacing(0.5),
		opacity: '0',
		padding: '0.5rem 1rem',
		transition: 'opacity 0.5s',
	},

    [`& .${classes.linkInput}`]: {
		display: 'flex',
		alignItems: 'center',
		minWidth: '300px',
		'& a': {
			color: 'rgb(33, 111, 219)',
			textDecoration: 'none',
			display: 'inline-block',
			minWidth: '225px',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			marginRight: '30px',
			textOverflow: 'ellipsis',
			'&:hover': {
				textDecoration: 'underline',
			},
		},
	}
}));

function positionEditorElement(linkEditor, rect) {
	if (rect === null) {
		/* eslint-disable no-param-reassign */
		linkEditor.style.opacity = 0;
		linkEditor.style.top = '-1000px';
		linkEditor.style.left = '-1000px';
	} else {
		linkEditor.style.opacity = 1;
		linkEditor.style.top = `${rect.top + rect.height + 10}px`;
		linkEditor.style.left = `${
			Math.min(rect.left, window.innerWidth - linkEditor.offsetWidth)
		}px`;
		/* eslint-enable no-param-reassign */
	}
}

function FloatingLinkEditor({ editor }) {
	const { t } = useTranslation();
	const linkEditorRef = useRef(null);
	const mouseDownRef = useRef(false);
	const [linkUrl, setLinkUrl] = useState('');
	const [prevLinkUrl, setPrevLinkUrl] = useState('');
	const [isEditMode, setEditMode] = useState(false);
	const [lastSelection, setLastSelection] = useState(null);


	const updateLinkEditor = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const parent = node.getParent();
			if ($isLinkNode(parent)) {
				setLinkUrl(parent.getURL());
			} else if ($isLinkNode(node)) {
				setLinkUrl(node.getURL());
			} else {
				setLinkUrl('');
			}
		}
		const linkEditorElem = linkEditorRef.current;
		const nativeSelection = window.getSelection();
		const { activeElement } = document;

		if (linkEditorElem === null) {
			return;
		}

		const rootElement = editor.getRootElement();
		if (
			selection !== null
			&& !nativeSelection.isCollapsed
			&& rootElement !== null
			&& rootElement.contains(nativeSelection.anchorNode)
		) {
			const domRange = nativeSelection.getRangeAt(0);
			let rect;
			if (nativeSelection.anchorNode === rootElement) {
				let inner = rootElement;
				while (inner.firstElementChild != null) {
					inner = inner.firstElementChild;
				}
				rect = inner.getBoundingClientRect();
			} else {
				rect = domRange.getBoundingClientRect();
			}

			if (!mouseDownRef.current) {
				positionEditorElement(linkEditorElem, rect);
			}
			setLastSelection(selection);
		} else if (!activeElement || activeElement.className !== 'link-input') {
			positionEditorElement(linkEditorElem, null);
			setLastSelection(null);
			setEditMode(false);
			setLinkUrl('');
		}
	}, [editor]);

	useEffect(() => mergeRegister(
		editor.registerUpdateListener(({ editorState }) => {
			editorState.read(() => {
				updateLinkEditor();
			});
		}),

		editor.registerCommand(
			SELECTION_CHANGE_COMMAND,
			() => {
				updateLinkEditor();
				return true;
			},
			1,
		),
	), [editor, updateLinkEditor]);

	useEffect(() => {
		editor.getEditorState().read(() => {
			// updateLinkEditor();
		});
	}, [editor, updateLinkEditor]);

	const handleLinkChange = (event) => {
		setLinkUrl(event.target.value);
	};

	const handleEdit = () => {
		setEditMode(true);
		setPrevLinkUrl(linkUrl);
	};

	const handleSetUrl = (e) => {
		e.preventDefault();
		if (lastSelection !== null) {
			if (linkUrl !== '') {
				editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
			}
			setEditMode(false);
		}
	};

	const handleEditKeyDown = (e) => {
		e.stopPropagation();
		if (e.key === 'Enter') {
			handleSetUrl(e);
		} else if (e.key === 'Escape') {
			e.preventDefault();
			setEditMode(false);
			setLinkUrl(prevLinkUrl);
		}
	};

	return (
        <StyledPaper ref={linkEditorRef} className={classes.linkEditor}>
			{isEditMode ? (
				<TextField
					name="link-input"
					value={linkUrl}
					onChange={handleLinkChange}
					autoFocus
					onKeyDown={handleEditKeyDown}
					InputProps={{
						inputProps: {
							className: classes.linkInput,
						},
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									type="button"
									tabIndex={0}
									onMouseDown={(event) => event.preventDefault()}
									onClick={handleSetUrl}
									size="small"
								>
									<Icon fontSize="small" color="primary">save</Icon>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			) : (
				<div className={classes.linkInput}>
					<Icon style={{ marginRight: '1rem' }}>open_in_new</Icon>
					<Link href={linkUrl} target="_blank" rel="noopener noreferrer">
						{linkUrl}
					</Link>
					<IconButton
						type="button"
						tabIndex={0}
						onMouseDown={(event) => event.preventDefault()}
						onClick={handleEdit}
						aria-label={t('lexical.editLink')}
						size="small"
					>
						<Icon fontSize="small">edit</Icon>
					</IconButton>
				</div>
			)}
		</StyledPaper>
    );
}

FloatingLinkEditor.propTypes = {
	editor: PropTypes.object.isRequired,
};

export default FloatingLinkEditor;
