import { fetchDataAppend, fetchDataDelete, fetchDataReplaceBy } from '../modules/fetchData/fetchDataActions';
import { CALL_API } from '../setup/api';

export const SCOPE_DATA = 'SCOPE_DATA';
export const CONTRACTS = 'CONTRACTS';
export const APPLICATIONS = 'APPLICATIONS';
export const PROPERTIES = 'PROPERTIES';

export const getScopeContracts = scopeId => ({
	[CALL_API]: {
		type: CONTRACTS,
		endpoint: `scope/${scopeId}/contracts`,
	},
});

export const getScopeApplications = scopeId => ({
	[CALL_API]: {
		type: APPLICATIONS,
		endpoint: `scope/${scopeId}/apply`,
	},
});

export const getScopeStudies = scopeId => ({
	[CALL_API]: {
		type: SCOPE_DATA,
		endpoint: `scope/${scopeId}/courses`,
	},
});

export const getAllScopeProperties = () => ({
	[CALL_API]: {
		type: PROPERTIES,
		endpoint: 'athenastudies-course/properties',
	},
});

export const addScopeProperty = (property) => (dispatch) => dispatch({
	[CALL_API]: {
		type: PROPERTIES,
		endpoint: 'athenastudies-course/properties',
		method: 'POST',
		body: property,
	},
}).then(res => {
	dispatch(fetchDataAppend('PropertyEditor', res?.response?.result));
});

export const updateScopeProperty = (property) => (dispatch) => {
	const newProperty = { ...property };
	delete newProperty.id;
	return dispatch({
		[CALL_API]: {
			type: PROPERTIES,
			endpoint: `athenastudies-course/properties/${property.id}`,
			method: 'PUT',
			body: newProperty,
		},
	}).then(res => {
		const data = res.response.result;
		dispatch(fetchDataReplaceBy('PropertyEditor', 'id', data));
	});
};

export const deleteScopeProperty = (property) => (dispatch) => dispatch({
	[CALL_API]: {
		type: PROPERTIES,
		endpoint: `athenastudies-course/properties/${property.id}`,
		method: 'DELETE',
	},
}).then(res => {
	dispatch(fetchDataDelete('PropertyEditor', property));
	return res;
});
