import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import HeaderLanguageSelector from './HeaderLanguageSelector';
import HeaderMenuItem from './HeaderMenuItem';
import { capabilities } from '../../helpers/capabilities';

const HeaderMenu = ({ rootCapabilities }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const menuMain = useMemo(() => [
		<HeaderMenuItem label={t('header.menuItem.planner')} to="/portal/planner" icon="date_range" capability={capabilities.SeeScopeCapability} />,
		<HeaderMenuItem label={t('header.menuItem.taskList')} to="/portal/tasks" icon="alarm" capability={capabilities.SeeScopeCapability} />,
		<HeaderMenuItem
			label={t('header.menuItem.employees')}
			to="/portal/employees"
			icon="people"
			capability={capabilities.SeeEmployeeCapability}
		/>,
		<HeaderMenuItem
			label={t('header.menuItem.customers')}
			to="/portal/customers"
			icon="people_outline"
			capability={capabilities.SeeClientDetailsCapability}
		/>,
	], [t]);

	const menuSub = useMemo(() => [
		<HeaderMenuItem label={t('header.menuItem.feed')} to="/portal/feed" icon="rss_feed" capability={capabilities.SeeScopeCapability} />,
		<HeaderMenuItem
			label={t('header.menuItem.promotional')}
			to="/portal/promotion"
			icon="grade"
			capability={[capabilities.SeeDiscountsCapability, capabilities.SeePartnerCapability]}
		>
			<HeaderMenuItem label={t('header.menuItem.discounts')} to="/portal/promotion/discount" capability={capabilities.SeeDiscountsCapability} />
			<HeaderMenuItem label={t('header.menuItem.bundles')} to="/portal/promotion/bundle" capability={capabilities.SeeDiscountsCapability} />
			<HeaderMenuItem label={t('header.menuItem.partners')} to="/portal/promotion/partners" capability={capabilities.SeePartnerCapability} />
		</HeaderMenuItem>,
		<HeaderMenuItem
			label={t('header.menuItem.financial')}
			to="/portal/financial"
			icon="euro_symbol"
			capability={[capabilities.SeeFinanceCapability, capabilities.AuthorizeEmployeePaymentCapability]}
		>
			<HeaderMenuItem label={t('header.menuItem.dashboard')} to="/portal/financial/dashboard" capability={capabilities.SeeFinanceCapability} />
			<HeaderMenuItem
				label={t('header.menuItem.batchCheck')}
				to="/portal/financial/batch"
				capability={capabilities.AuthorizeEmployeePaymentCapability}
			/>
			<HeaderMenuItem label={t('header.menuItem.batches')} to="/portal/financial/batches" capability={capabilities.ManageFinanceCapability} />
			<HeaderMenuItem
				label={t('header.menuItem.declarations')}
				to="/portal/financial/declarations"
				capability={capabilities.ApproveOrDeclineClaimForm}
			/>
		</HeaderMenuItem>,
		<HeaderMenuItem
			label={t('header.menuItem.manage')}
			to="/portal/manage"
			icon="settings"
			capability={[capabilities.SeeScopeCapability, capabilities.SeeEmployeeCapability]}
		>
			<HeaderMenuItem label={t('header.menuItem.courses')} to="/portal/course/dashboard" capability={capabilities.SeeScopeCapability} />
			<HeaderMenuItem label={t('header.menuItem.teachers')} to="/portal/teacher/dashboard" capability={capabilities.SeeEmployeeCapability} />
			<HeaderMenuItem label={t('header.menuItem.locations')} to="/portal/location" capability={capabilities.SeeScopeCapability} />
			<HeaderMenuItem label={t('header.menuItem.studies')} to="/portal/studies/overview" capability={capabilities.SeeScopeCapability} />
			<HeaderMenuItem label={t('header.menuItem.groupEmail')} to="/portal/email/overview" capability={capabilities.GroupEmailCapability} />
			<HeaderMenuItem label={t('header.menuItem.properties')} to="/portal/properties" capability={capabilities.AdministratorOverride} />
			<HeaderMenuItem label={t('header.menuItem.company')} to="/portal/company/dashboard" capability={capabilities.AdministratorOverride} />
		</HeaderMenuItem>,
	], [t]);

	const menuBottom = useMemo(() => [
		<HeaderMenuItem to="/logout" icon="exit_to_app" label={t('header.menuItem.logout')} />,
	], [t]);

	const getInitialState = () => menuSub.reduce((subOpen, item) => {
		if (!subOpen && item.props.children) {
			// TODO: replace match
			return item.props.children.reduce((match, child) => {
				if (!match && matchPath({
					path: child.props.to,
					end: true,
				}, location.pathname)) return item.props.to;
				return match;
			}, null);
		}
		return subOpen;
	}, null);

	const [subOpen, setSubOpen] = useState(getInitialState());

	const handleSubOpen = useCallback((item) => {
		setSubOpen(subOpen === item ? null : item);
	}, [subOpen]);

	return (
		<>
			<List>
				{menuMain.map(item => React.cloneElement(item, {
					key: item.props.to,
					open: subOpen,
					onOpen: handleSubOpen,
					capabilities: rootCapabilities,
				}))}
			</List>
			<Divider />
			<List dense>
				{menuSub.map(item => React.cloneElement(item, {
					key: item.props.to,
					open: subOpen,
					onOpen: handleSubOpen,
					capabilities: rootCapabilities,
				}))}
			</List>
			<Divider />
			<List dense>
				<HeaderLanguageSelector />
			</List>
			<Divider />
			<List dense>
				{menuBottom.map(item => React.cloneElement(item, {
					key: item.props.to,
					open: subOpen,
					onOpen: handleSubOpen,
					capabilities: rootCapabilities,
				}))}
			</List>
		</>
	);
};

HeaderMenu.propTypes = {
	rootCapabilities: PropTypes.array.isRequired,
};

export default HeaderMenu;
