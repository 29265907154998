import { chipClasses, ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import { getGradeColorClass } from '../evaluationUtils'

const PREFIX = 'EvaluationListItem';

const classes = {
    root: `${PREFIX}-root`,
    label: `${PREFIX}-label`
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
    [`&.${classes.root}`]: {
		height: 20,
		padding: 0,
		verticalAlign: 'text-bottom',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		'&.danger': {
			borderColor: theme.palette.error.main,
			color: theme.palette.error.main,
		},
		'&.warning': {
			borderColor: theme.palette.warning.main,
			color: theme.palette.warning.main,
		},
		[`&+.${classes.root}`]: {
			marginLeft: 0,
		},
	},

    [`& .${classes.label}`]: {
		padding: theme.spacing(0, 1),
	}
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	[`&.${chipClasses.root}`]: {
		height: 20,
		padding: 0,
		verticalAlign: 'text-bottom',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		'&.danger': {
			borderColor: theme.palette.error.main,
			color: theme.palette.error.main,
		},
		'&.warning': {
			borderColor: theme.palette.warning.main,
			color: theme.palette.warning.main,
		},
		[`&+.${chipClasses.root}`]: {
			marginLeft: 0,
		},
	},
	[`& .${chipClasses.label}`]: {
		padding: theme.spacing(0, 1),
	},
}));

const EvaluationListItem = ({ to, label, evaluations, soldUnits, averageEvaluation }) => {
	return (
        <StyledListItem disablePadding>
			<ListItemButton component={Link} to={to}>
				<ListItemText
					primary={(
						<>
							{label}
							{ typeof evaluations !== "undefined" &&  typeof soldUnits !== "undefined" && (
								<CustomChip
                                    label={`${evaluations}/${soldUnits}`}
                                    variant="outlined"
                                />
							)}
						</>
					)}
				/>
			</ListItemButton>
			<ListItemSecondaryAction>
				{!!averageEvaluation && <CustomChip
                    label={averageEvaluation.toFixed(1)}
                    className={getGradeColorClass(averageEvaluation)}
                    variant="outlined"
                />}
			</ListItemSecondaryAction>
		</StyledListItem>
    );
};

EvaluationListItem.propTypes = {
	to: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	evaluations: PropTypes.number,
	soldUnits: PropTypes.number,
	averageEvaluation: PropTypes.number,
};

export default EvaluationListItem
