import React, { useCallback } from 'react'
import { styled } from '@mui/material/styles';
import { omit } from 'ramda'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next';
import { editScopeTypes } from '../../../actions/scopeEditActions'
import TagEditor from '../../util/TagEditor'
import AthenaEditor from '../../util/AthenaEditor'
import editScope from './editScope'

const PREFIX = 'ScopeCourseForm';

const classes = {
    paper: `${PREFIX}-paper`,
};

const Root = styled('form')(({ theme }) => ({
    [`& .${classes.paper}`]: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.default,
	}
}));

const PublishedOptions = [
	{value: 'published', label: 'Published'},
	{value: 'draft', label: 'Draft'}
];

// t('scope.edit.useLangOfCompany')
const LocaleOptions = [
	{value: "null", i18nLabel: "scope.edit.useLangOfCompany"},
	{value: "nl_NL", label: "Nederlands"},
	{value: "en_GB", label: "English"},
	{value: "de_DE", label: "Deutsch"}
];

const PublishedValues = PublishedOptions.map(o => o.value);
const LocaleValues = LocaleOptions.map(o => o.value);

function noComma(value){
	return value ? value.replace(',', '') : value;
}

const ScopeCourseForm = ({
    saveScope, showButtons, saving, fields, parent,
    bindState, bindStateSwitch, bindStateDirect,
    switchCheckedFromValue,
}) => {
	const { t } = useTranslation();

	const handleChangePageDescription = useCallback((raw, html) => {
		bindStateDirect('pageDescription')(html);
		bindStateDirect('pageDescriptionEditor')(raw);
	}, [bindStateDirect]);

	return (
        <Root onSubmit={saveScope}>
			<Grid container spacing={2}>
				<Grid item md={4} sm={6} xs={12}>
					<TextField label={t('general.name')}
							   name="name"
							   fullWidth
							   value={fields.name}
							   onChange={bindState('name')} />
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<TextField label={t('general.abbreviation')}
							   name="abbreviation"
							   fullWidth
							   value={fields.abbreviation}
							   onChange={bindState('abbreviation')} />
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<FormControlLabel
						label={t('scope.edit.publishedForCustomers')}
						control={
							<Switch checked={switchCheckedFromValue(PublishedValues, fields.published)}
									onChange={bindStateSwitch('published', PublishedValues)} />
						}
					/>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<FormControl fullWidth>
						<InputLabel>{t('scope.edit.languageOfStudy')}</InputLabel>
						<Select value={fields.locale}
								inputProps={{
									name: 'locale'
								}}
								onChange={bindState('locale')}>
							{LocaleOptions.map(locale => (
								<MenuItem key={locale.value} value={locale.value}>
									{ locale.i18nLabel ? t(locale.i18nLabel) : locale.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<TextField label={t('scope.edit.contactEmail')}
							   name="contactEmail"
							   fullWidth
							   value={fields.contactEmail}
							   onChange={bindState('contactEmail')} />
				</Grid>

				<Grid item xs={12}>
					<FormControlLabel label={t('scope.edit.optionalWebstorePageDesc')}
									  control={
										  <Switch checked={fields.showPageDescription || false}
												  onChange={bindStateSwitch('showPageDescription')} />
									  } />
					{fields.showPageDescription && (
						<AthenaEditor
							value={fields.pageDescriptionEditor || fields.pageDescription}
							onChange={handleChangePageDescription}
							output="html"
						/>
					)}
				</Grid>

				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<strong>{t('scope.edit.studyKeywords')}</strong>
						<p>{t('scope.edit.studyKeywordsDesc')}</p>
						<TagEditor
							tags={fields.keywords}
							onChange={bindStateDirect('keywords')}
							addText={t('scope.edit.addNewKeyword')}
						/>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<strong>{t('scope.edit.courseTags')}</strong>
						<p>{t('scope.edit.courseTagsDesc')}</p>
						<TagEditor
							tags={fields.courseTags}
							onChange={bindStateDirect('courseTags')}
							addText={t('scope.edit.addNewCourseTags')}
							tagNameFilter={noComma}
						/>
					</Paper>
				</Grid>

				{showButtons && (
					<Grid item>
						<Button variant="contained" color="default"
								disabled={saving}
								onClick={saveScope}>
							{saving
								? t('general.saving')
								: parent
									? t('scope.edit.addStudy')
									: t('scope.edit.editStudy')
							}
						</Button>
					</Grid>
				)}
			</Grid>
		</Root>
    );
}

export default (
	editScope(
		editScopeTypes.COURSE,
		{
			fields: {
				name: editScope.Joi.string().required().default(''),
				abbreviation: editScope.Joi.string().required().default(''),
				published: editScope.Joi.string().valid(PublishedValues).default(PublishedValues[0]),
				locale: editScope.Joi.string().valid(LocaleValues).default(LocaleValues[0]),
				contactEmail: editScope.Joi.string().default(''),
				pageDescription: editScope.Joi.string().default(''),
				pageDescriptionEditor: editScope.Joi.string().default(null),
				keywords: editScope.Joi.string().default([]),
				courseTags: editScope.Joi.string().default([]),
				properties: editScope.Joi.object().default({}),
			},
			convertBeforeLoad: (scope) => {
				if(!scope) return scope;
				return {
					...scope,
					locale: !scope.locale ? 'null' : scope.locale,
					contactEmail: !scope.properties || !scope.properties.contactEmail ? '' : scope.properties.contactEmail,
					showPageDescription: scope.properties && !!scope.properties.pageDescription,
					pageDescription: !scope.properties || !scope.properties.pageDescription ? '' : scope.properties.pageDescription,
					pageDescriptionEditor: scope.properties && scope.properties.pageDescriptionEditor,
					keywords: !scope.properties || !scope.properties.keywords ? [] : scope.properties.keywords,
					courseTags: !scope.properties || !scope.properties.courseTags ? [] : scope.properties.courseTags,
				}
			},
			convertBeforeSave: (fields) => {
				return omit(['contactEmail', 'pageDescription', 'pageDescriptionEditor', 'showPageDescription', 'keywords', 'courseTags'], {
					...fields,
					properties: {
						...fields.properties,
						contactEmail: fields.contactEmail === '' ? null : fields.contactEmail,
						pageDescription: fields.showPageDescription ? fields.pageDescription : null,
						pageDescriptionEditor: fields.showPageDescription ? fields.pageDescriptionEditor : null,
						keywords: fields.keywords ? fields.keywords : [],
						courseTags: fields.courseTags ? fields.courseTags : [],
					},
				})
			},
		}
	)(ScopeCourseForm)
)
