import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../util/loader/Loader';

function PropertyEditorRowActions({
	saving,
	disabled,
	isDirty,
	handleRevert,
	handleSave,
	setEditMode,
	editMode,
	newProperty,
	handleDeleteProperty,
}) {
	if (saving) {
		return <Loader small />;
	} if (isDirty) {
		return (
			<>
				<IconButton onClick={handleRevert}>
					<Icon>history</Icon>
				</IconButton>
				<IconButton onClick={handleSave} color="primary" disabled={disabled}>
					<Icon>save</Icon>
				</IconButton>
			</>
		);
	}
	return (
		<>
			<IconButton onClick={() => setEditMode(!editMode)} disabled={newProperty || saving}>
				<Icon>edit</Icon>
			</IconButton>
			<IconButton color="secondary" onClick={handleDeleteProperty} disabled={saving}>
				<Icon>delete_outline</Icon>
			</IconButton>
		</>
	);
}

PropertyEditorRowActions.propTypes = {
	saving: PropTypes.bool,
	disabled: PropTypes.bool,
	isDirty: PropTypes.bool,
	handleRevert: PropTypes.func,
	handleSave: PropTypes.func,
	setEditMode: PropTypes.func,
	editMode: PropTypes.bool,
	newProperty: PropTypes.bool,
	handleDeleteProperty: PropTypes.func,
};

export default PropertyEditorRowActions;
