import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import withFormData from '../../modules/formData/withFormData'
import withValidation, { Joi } from '../../hoc/withValidation'
import withSimpleState from '../../hoc/withSimpleState'
import { patchCourseLocation } from '../../actions/locationActions'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'

class LocationEditForm extends Component {
	render(){
		const { validation, validationBlur, fields, onChangeField, saving, success } = this.props;

		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Location saved.
			</Alert>
		);

		return (
			<div>
				{ saving && <Loader sheet /> }
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField name="name"
								   value={fields.name}
								   onChange={onChangeField}
								   label="Name"
								   error={!!validation['name']}
								   helperText={validation['name']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="street"
								   value={fields.street}
								   onChange={onChangeField}
								   label="Street"
								   error={!!validation['street']}
								   helperText={validation['street']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="houseNumber"
								   value={fields.houseNumber}
								   onChange={onChangeField}
								   label="House number"
								   error={!!validation['houseNumber']}
								   helperText={validation['houseNumber']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="zipcode"
								   value={fields.zipcode}
								   onChange={onChangeField}
								   label="Zipcode"
								   error={!!validation['zipcode']}
								   helperText={validation['zipcode']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
				</Grid>
			</div>
		)
	}

	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.onSave();
		}
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields } = this.props;

		watchSubmit(patchCourseLocation(
			inputData.id, {
				name: fields.name,
				street: fields.street,
				houseNumber: fields.houseNumber,
				zipcode: fields.zipcode,
			}
		));
	}
}

LocationEditForm.propTypes = {

};

export default withFormData({
	customId: () => 'locationEditForm',
})(
	withValidation(
		withSimpleState(LocationEditForm),
		{
			name: Joi.string().required(),
			street: Joi.string().required(),
			houseNumber: Joi.string().required(),
			zipcode: Joi.string().required(),
		}
	)
)
