import { Icon, IconButton, styled, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { copyTextToClipboard } from '../../helpers/clipboard';
import { notificationShow } from '../../modules/notification/notificationActions';

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
	margin: theme.spacing(-1.5, 0, -1, 1),
}));

function CopyValueIconButton({ value }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClick = useCallback(() => {
		copyTextToClipboard(value, () => {
			dispatch(notificationShow(t('general.copy.success'), 'success'));
		});
	}, [value, dispatch, t]);

	return (
		<Tooltip title={t('general.copy.tooltip')} placement="top" arrow>
			<IconButtonStyled onClick={handleClick} size="small">
				<Icon>content_paste</Icon>
			</IconButtonStyled>
		</Tooltip>
	);
}

CopyValueIconButton.propTypes = {
	value: PropTypes.string.isRequired,
};

export default CopyValueIconButton;
