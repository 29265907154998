import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { withTranslation } from 'react-i18next';
import { capabilities, hasScopeCapability } from '../../../helpers/capabilities'
import ModalManager from '../../modal/ModalManager'
import ModalItem from '../../modal/ModalItem'
import PageContentBlock from '../../page/PageContentBlock'
import CourseBookletsCreateModal from './CourseBookletsCreateModal'
import CourseBookletsApplications from './CourseBookletsApplications'
import CourseBookletsContracts from './CourseBookletsContracts'

class CourseBookletsTable extends Component {
	refreshBinder

	onCreateSuccess = () => {
		this.refreshBinder && this.refreshBinder()
	}

	render() {
		const { onCreateSuccess, props: { t, course, location: { pathname } } } = this

		return (
			<Fragment>

				<ModalManager>
					<ModalItem
						state={'bookletApplicationForm'}
						component={CourseBookletsCreateModal}
						{...{ course, onCreateSuccess }}
					/>
				</ModalManager>

				<PageContentBlock
					title={t('scope.course.booklets.title')}
					icon="chrome_reader_mode"
					links={[
						hasScopeCapability(course, capabilities.ManageEmployeesCapability) && {
							label: t('scope.course.booklets.newApplication'),
							to: pathname,
							state: { modal: 'bookletApplicationForm' },
						},
					]}
				>
					<Table>
						<TableBody>
							<CourseBookletsContracts course={course}/>
							<CourseBookletsApplications
								bindRefresh={fn => this.refreshBinder = fn}
								course={course}
							/>
						</TableBody>
					</Table>
				</PageContentBlock>

			</Fragment>
		)
	}
}

CourseBookletsTable.propTypes = {
	course: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
}

export default withTranslation()(CourseBookletsTable)
