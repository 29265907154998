import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useDispatch } from 'react-redux';
import { approveDeclineClaimForm, createClaimForm, editClaimForm } from '../../../actions/declarationsActions';
import DeclarationStatuses from '../../../constants/DeclarationStatuses';
import Alert from '../../util/alert/Alert';
import ApiError from '../../util/ApiError';
import { DeclarationContexts, useDeclarationContext } from './DeclarationContext';
import DeclarationEntries from './DeclarationEntries';
import DeclarationEntryForm from './DeclarationEntryForm';

const { CREATED, APPROVED, DECLINED } = DeclarationStatuses;
const { PROFILE } = DeclarationContexts;

const DEFAULT_ENTRY = { currency: 'EUR', accountId: -1, description: '', attachments: [] };

function DeclarationForm({ declaration, bindSave, bindStatus, setId, setFormState, disabled }) {
	const { t } = useTranslation();
	const context = useDeclarationContext();
	const [apiError, setApiError] = useState();
	const declarationEntrySchema = useMemo(() => Joi.object({
		amount: Joi.number().required().label(t('declarations.form.entry.amount')),
		currency: Joi.string().required().label(t('declarations.form.entry.currency')),
		description: Joi.string().required().label(t('declarations.form.entry.description')),
		accountId: Joi.number().positive().allow(0).required()
			.label(t('declarations.form.entry.accountId')),
		scope: Joi.object().required().label(t('declarations.form.entry.scope')),
		attachments: Joi.array().min(1).label(t('declarations.form.entry.attachments')),
	}), [t]);

	const customValidation = (value, { prefs }) => {
		// Finding an id means the form was successfully saved with an entry
		const { id } = prefs.context || {};
		if (!id && value.length < 1) {
			throw new Error(t('declarations.form.minOneEntry'));
		}
		return value;
	};

	const declarationSchema = useMemo(() => Joi.object({
		comments: Joi.string().required().label(t('declarations.form.comments')),
		status: Joi.string().required().label(t('declarations.form.status')),
		entries: Joi.array().items(declarationEntrySchema).custom(customValidation).label(t('declarations.form.entries')),
	}), [declarationEntrySchema, t]);

	const dispatch = useDispatch();
	const {
		control,
		register,
		handleSubmit,
		reset,
		clearErrors,
		formState: {
			errors,
			isDirty,
			isSubmitting,
			isSubmitted,
		},
	} = useForm({
		resolver: joiResolver(declarationSchema),
		context: declaration,
		defaultValues: {
			comments: '',
			status: CREATED,
			entries: [],
		},
	});

	const onInvalid = (err) => console.error(err);

	const onSave = async (data) => {
		const parsed = { ...data };
		const action = declaration?.id ? editClaimForm : createClaimForm;
		parsed.entries = new Blob([JSON.stringify(parsed.entries.map(entry => ({
			description: entry.description,
			accountId: entry.accountId,
			scopeId: entry.scope.id,
			amount: {
				amount: entry.amount,
				currency: entry.currency,
			},
			attachmentKeys: entry.attachments.map(attachment => {
				parsed[attachment.name] = attachment;
				return attachment.name;
			}),
		})))], { type: 'application/json' });
		dispatch(action(parsed, declaration?.id)).then(res => {
			if (res && res.errorCode && res.errorCode !== 401) {
				setApiError(res);
			} else if (res?.response?.result) {
				setId(res.response.result.id);
			}
		});
	};

	const onStatusChange = (id, status) => {
		const action = [APPROVED, DECLINED].includes(status) ? approveDeclineClaimForm : editClaimForm;
		dispatch(action({ status }, id)).then((res) => {
			if (res && res.errorCode && res.errorCode !== 401) {
				setApiError(res);
			}
		});
	};

	useEffect(() => {
		setFormState({ isDirty, isSubmitting, isSubmitted });
	}, [setFormState, isDirty, isSubmitting, isSubmitted]);

	useEffect(() => {
		bindSave(handleSubmit(onSave, onInvalid));
		bindStatus((id, status) => onStatusChange(id, status));
	}, [onSave]);

	const { fields, prepend, remove } = useFieldArray({
		control,
		name: 'entries',
	});

	useEffect(() => {
		const { comments, status = CREATED } = declaration || {};
		reset({ comments, status, entries: [] }, { keepIsSubmitted: true, keepSubmitCount: true });
		// For new declaration, prefill with a default entry
		if (!declaration) prepend(DEFAULT_ENTRY);
	}, [declaration, reset]);

	const handleAddEntry = () => {
		clearErrors('entries');
		prepend({
			currency: 'EUR',
			accountId: -1,
			attachments: [],
		});
	};

	return (
		<form>
			<ApiError error={apiError} />
			<Controller
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						control={control}
						label={t('declarations.comments')}
						error={!!errors.comments}
						helperText={errors.comments?.message}
						value={value}
						onChange={onChange}
						multiline
						fullWidth
						disabled={disabled}
						variant="outlined"
						sx={{ my: 2 }}
					/>
				)}
				name="comments"
			/>
			<Typography variant="h5" sx={{ mb: 1 }}>
				{t('declarations.entries.header')}
			</Typography>
			<DeclarationEntries declaration={declaration} disabled={disabled} />
			{!disabled && context === PROFILE && (
				<Button
					color="primary"
					variant="contained"
					onClick={handleAddEntry}
					disabled={disabled}
					sx={{ marginBottom: 2 }}
				>
					{t('declarations.entry.addEntry')}
				</Button>
			)}
			{errors?.entries?.message && (
				<Alert type={Alert.TYPE_WARNING}>
					{errors.entries.message}
				</Alert>
			)}
			{fields.map((field, index) => (
				<DeclarationEntryForm
					register={register}
					control={control}
					field={field}
					index={index}
					errors={errors}
					remove={remove}
					key={field.id}
				/>
			))}
		</form>
	);
}

DeclarationForm.propTypes = {
	declaration: PropTypes.object,
	bindSave: PropTypes.func.isRequired,
	bindStatus: PropTypes.func.isRequired,
	setId: PropTypes.func.isRequired,
	setFormState: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default DeclarationForm;
