import React, { Component } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import { withTranslation } from 'react-i18next';
import { debounce } from '../../../helpers/search'

const PREFIX = 'ScopeFilterSearch';

const classes = {
    circularProgress: `${PREFIX}-circularProgress`
};

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    [`& .${classes.circularProgress}`]: {
		marginRight: theme.spacing(1.75),
	}
}));

class ScopeFilterInput extends Component {
    constructor(props){
    	super(props);

    	this.debounce = debounce(
    		props.onChange,
			props.debounce
		);

    	this.state = {
    		search: props.search || '',
			searching: false,
		}
	}

	render(){
		const {  t } = this.props;
		const { search, searching } = this.state;

		return (
            <TextField
				placeholder={t('scope.searchCoursesAndGroups')}
				value={search}
				onChange={this.handleChange}
				fullWidth
				InputProps={{
					endAdornment: searching || search.length > 0 ? (
						<StyledInputAdornment position="end">
							{searching ? (
								<CircularProgress size={20} color="secondary" className={classes.circularProgress} />
							) : (search.length > 0 ? (
								<IconButton onClick={this.handleClear}>
									<Icon>close</Icon>
								</IconButton>
							) : '')}
						</StyledInputAdornment>
					) : undefined
				}}
			/>
        );
	}

	componentDidUpdate(prevProps){
		if(this.props.onChange !== prevProps.onChange || this.props.debounce !== prevProps.debounce){
			this.debounce = debounce(
				this.props.onChange,
				this.props.debounce
			);
		}

		if(this.props.search !== prevProps.search){
			this.setState({
				searching: false,
			});
		}
	}

	handleChange = (e) => {
		this.setState({
			search: e.target.value,
			searching: true,
		});

		this.debounce(e.target.value);
	}

	handleClear = () => {
		this.props.onChange('');
		this.setState({
			search: '',
		});
	}
}

ScopeFilterInput.propTypes = {
	search: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	debounce: PropTypes.number,
};

ScopeFilterInput.defaultProps = {
	debounce: 500,
}

export default withTranslation()((ScopeFilterInput))
