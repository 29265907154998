import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { patchClientProfile } from '../../../actions/clientActions'
import completeBasicForm, { Joi } from '../../../hoc/withCompleteBasicForm'
import Alert from '../../util/alert/Alert'
import Loader from '../../util/loader/Loader'
import ApiError from '../../util/ApiError'

class ClientEditEmailForm extends Component {

	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.onSave();
		}
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields, validationAll, handleFormError } = this.props;

		validationAll(fields, (error) => {
			if(error){
				handleFormError('Not all fields were filled correctly');
			}else{
				watchSubmit(patchClientProfile(
					inputData.id, {
						email: fields.email
					}
				));
			}
		})
	}

	render(){
		const { validation, validationAll, fields, onChangeField, saving, success, error } = this.props;

		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Updated details saved.
			</Alert>
		);

		return (
			<Fragment>
				<Alert type={Alert.TYPE_DANGER}>
					Changing the email will also change the <strong>username</strong> of this customer account.
					The customer will have to use this new email <strong>to login</strong> the next time
					and will <strong>not</strong> be notified automatically of this change. Make sure to let the customer know.
				</Alert>
				{ saving && <Loader sheet /> }
				<ApiError error={error} showDetails hideErrorOnDetails />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField
							name="email"
							value={fields.email}
							onChange={onChangeField}
							label="Email"
							error={!!validation['email']}
							helperText={validation['email']}
							onBlur={() => validationAll(fields)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="emailRepeat"
							value={fields.emailRepeat || ''}
							onChange={onChangeField}
							label="Repeat email"
							error={!!validation['emailRepeat']}
							helperText={validation['emailRepeat']}
							onBlur={() => validationAll(fields)}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Fragment>
		)
	}
}

ClientEditEmailForm.propTypes = {
	inputData: PropTypes.object.isRequired,
};

export default completeBasicForm(ClientEditEmailForm, {
	email: Joi.string().email({
		minDomainAtoms: 2,
	}).required(),
	emailRepeat: Joi.string().valid(Joi.ref('email')).required().options({
		language: {
			any: {
				allowOnly: 'is not the same'
			}
		}
	})
})
