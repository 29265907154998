import { Schemas, CALL_API } from './schemas'

export const APPLY = 'APPLY';

export const getCompanyFunctionList = (scopeId) => ({
	[CALL_API]: {
		type: APPLY,
		endpoint: `company/${scopeId}/functions`
	}
});
