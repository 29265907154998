import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';

function ScopeFilterHideClosed({ hideClosed, onChange }) {
    const { t } = useTranslation();
    return (
        <FormControlLabel
            sx={{ mb: 0 }}
            control={(
                <Switch
                    checked={hideClosed}
                    onChange={(e) => onChange(e.target.checked)}
                    value="hideClosed"
                    color="secondary"
                />
            )}
            label={t('scope.hideCancelledAndClosed')}
        />
    );
}

ScopeFilterHideClosed.propTypes = {
    hideClosed: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ScopeFilterHideClosed;
