import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { workingScopesNameList } from '../../../helpers/scope'
import withRouter from '../../../hoc/withRouter';
import withWorkingScope from '../../../modules/scope/withWorkingScope'
import PageHeader from '../../page/PageHeader'
import PageHeaderTitle from '../../page/PageHeaderTitle'
import BatchesPaymentsList from './BatchesPaymentsList'

const BatchesItemPage = ({ workingScopes, params }) => {
	return (
		<Fragment>
			<PageHeader>
				<PageHeaderTitle
					headline="SEPA batches"
					subHeading={(
						<Fragment>
							<Link to={`/portal/financial/batches`}>
								{workingScopesNameList(workingScopes)[0]}
							</Link>
							{' > '}
							batch {params.id}
						</Fragment>
					)}
				/>
			</PageHeader>
			<BatchesPaymentsList batchId={params.id} />
		</Fragment>
	);
};

export default withRouter(withWorkingScope(BatchesItemPage));
