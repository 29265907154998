import { MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { scopeParentsFromStoreFull } from '../../helpers/scope'
import { getApiTypeSuccess } from '../../helpers/types'
import { fetchDataReplaceBy } from '../../modules/fetchData/fetchDataActions'
import withFormData from '../../modules/formData/withFormData'
import { CALL_API } from '../../setup/api'
import ApiError from '../util/ApiError'
import Loader from '../util/loader/Loader'

/**
 * @param scopeId
 * @param locationId
 * @param data object:{name:"",description:null,price:null,seats:0,paymentUnit:null,own:false,furniture:{projector:false,whiteboard:false}}
 * @param rooms
 */
const apiAction = (scopeId, locationId, data, rooms) => (dispatch) => {
	const companyScope = scopeParentsFromStoreFull(scopeId)[0];

	return dispatch({
		[CALL_API]: {
			type: 'LOCATION_ROOM',
			endpoint: `course-location/${locationId}/room`,
			body: {
				...data,
				price: (data.price && data.paymentUnit !== 'FREE') ? {
					amount: data.price,
					currency: companyScope?.currencyUnit || 'EUR',
				} : null,
			},
		},
	}).then(res => {
		if(res.type === getApiTypeSuccess('LOCATION_ROOM')){
			dispatch(fetchDataReplaceBy('locationSelectDialog', 'id', {
				...res?.response?.result?.location,
				rooms: [...rooms, res?.response?.result],
			}, 'rooms'));
		}

		return res;
	});
}

const LocationSelectRoomCreate = ({ scope, location, rooms, onClose, onSelect, saving, error, success, watchSubmit }) => {
	const [data, setData] = useState({
		name: '',
		description: '',
		paymentUnit: 'FREE',
		price: '',
		seats: '',
	});

	const handleChange = useCallback((e) => {
		const name = e.target.name;
		const value = e.target.value;

		setData(data => ({
			...data,
			[name]: value,
		}));
	}, [setData]);

	const handleSave = useCallback(() => {
		watchSubmit(apiAction(scope.id, location.id, data, rooms));
	}, [scope, location, data, rooms, watchSubmit]);

	useEffect(() => {
		if(success){
			onSelect(success);
			onClose();
		}
	}, [success, onClose, onSelect]);

	return (
		<>
			{saving && (
				<Loader sheet />
			)}
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="body1" paragraph>
						Make sure the room <strong>does not exist</strong> already! The existence of double room can cause unexpected problems.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Name"
						name="name"
						value={data.name}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Description"
						name="description"
						value={data.description}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						fullWidth
						label="Price pay per"
						name="paymentUnit"
						value={data.paymentUnit}
						onChange={handleChange}
						select
					>
						<MenuItem value="FREE">Free</MenuItem>
						<MenuItem value="PER_HOUR">Hourly</MenuItem>
						<MenuItem value="PER_DAY">Daily</MenuItem>
					</TextField>
				</Grid>
				<Grid item xs={6}>
					{data.paymentUnit !== 'FREE' && (
						<TextField
							fullWidth
							label="Price"
							name="price"
							type="number"
							value={data.price}
							onChange={handleChange}
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label="Amount of seats"
						name="seats"
						type="number"
						value={data.seats}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box mb={4}>
						{error && (
							<ApiError error={error} />
						)}
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Button fullWidth variant="outlined" onClick={onClose}>
									Cancel
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button fullWidth variant="contained" color="primary" onClick={handleSave}>
									Save
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

LocationSelectRoomCreate.propTypes = {
	scope: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	rooms: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default withFormData()(LocationSelectRoomCreate)
