export const SCOPE_FILTER = 'SCOPE_FILTER';

export const scopeFilterSubTypes = {
	SEARCH: 'SEARCH',
	RANGE: 'RANGE',
	HIDE_CLOSED: 'HIDE_CLOSED',
	ONLY_VISIBLE: 'ONLY_VISIBLE',
	PROPERTIES: 'PROPERTIES',
};

export const setFilterSearch = (search) => ({
	type: SCOPE_FILTER,
	subType: scopeFilterSubTypes.SEARCH,
	search,
});

export const setFilterRange = (range) => ({
	type: SCOPE_FILTER,
	subType: scopeFilterSubTypes.RANGE,
	range,
});

export const setFilterHideClosed = (hideClosed) => ({
	type: SCOPE_FILTER,
	subType: scopeFilterSubTypes.HIDE_CLOSED,
	hideClosed,
});

export const setFilterOnlyVisible = (onlyVisible) => ({
	type: SCOPE_FILTER,
	subType: scopeFilterSubTypes.ONLY_VISIBLE,
	onlyVisible,
});

export const setPropertiesSearch = (properties) => ({
	type: SCOPE_FILTER,
	subType: scopeFilterSubTypes.PROPERTIES,
	properties,
});
