import { GROUP_STATE_ADMINISTRATION_COMPLETE, GROUP_STATE_FINISHED } from '../../helpers/groupState'

export const LOCKED = 'locked'
export const possibleTasks = {
	timeSlot: {
		name: 'timeSlot',
		parsed: 'Add timeslot(s) \n or cancel group',
		priority: 4,
		defaultValue: true,
	},
	teacher: {
		name: 'teacher',
		parsed: 'Add teacher to timeslot(s)',
		priority: 3,
		defaultValue: LOCKED,
	},
	location: {
		name: 'location',
		parsed: 'Add location to timeslot(s)',
		priority: 2,
		defaultValue: LOCKED,
	},
	evaluation: {
		name: 'evaluation',
		parsed: 'Evaluate course group',
		priority: 1,
		defaultValue: LOCKED,
	},
	audit: {
		name: 'audit',
		parsed: 'Check and approve finances',
		priority: 0,
		defaultValue: LOCKED,
	},
}

/**
 * @param group
 * @return {*}
 */
export const defineTask = group => {
	const defaultValues = Object.values(possibleTasks).reduce((acc, task) => {
		acc[task.name] = task.defaultValue
		return acc
	}, {})

	if (!group.numTimeSlots) return {
		picked: possibleTasks.timeSlot.name,
		progress: {
			...defaultValues,
			timeSlot: false,
			location: LOCKED,
			teacher: LOCKED,
		},
	}

	const teacher = group.timeSlotsHaveTeacher
	const location = group.timeSlotsHaveClassRoom

	if (group.state === GROUP_STATE_FINISHED) return {
		picked: possibleTasks.evaluation.name,
		progress: {
			...defaultValues,
			evaluation: false,
			teacher,
			location,
		},
	}

	if (group.state === GROUP_STATE_ADMINISTRATION_COMPLETE) return {
		picked: possibleTasks.audit.name,
		progress: {
			...defaultValues,
			evaluation: true,
			audit: false,
			teacher,
			location,
		},
	}

	if (teacher && location) return { picked: false }

	return {
		picked: !teacher ? possibleTasks.teacher.name : possibleTasks.location.name,
		progress: {
			...defaultValues,
			teacher,
			location,
		},
	}
}
