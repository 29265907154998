
export const formatNumber = (number, fractionDigits = 2) => {
	if(!number || typeof number !== 'number') return number;

	return number.toLocaleString(undefined, {
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	});
}

export const getNumberFormatSeparators = (language) => {
	switch (language) {
		case 'en':
			return {
				decimalSeparator: '.',
				thousandSeparator: ',',
			};
		default:
			return {
				decimalSeparator: ',',
				thousandSeparator: '.',
			};
	}
};

export const getMoneyCurrencySymbol = (currency) => {
	switch(currency){
		case 'EUR':
			return '€';
		case 'GBP':
			return '£';
		default:
			return currency || '€';
	}
}

export const formatMoneyDirect = (amount, currency, rounded = false) => {
	if(amount < 0){
		// Put the minus in front of the symbol
		return `-${getMoneyCurrencySymbol(currency)}${formatNumber(Math.abs(amount), rounded ? 0 : 2)}`;
	}else{
		return `${getMoneyCurrencySymbol(currency)}${formatNumber(amount, rounded ? 0 : 2)}`;
	}
}

// TODO: These functions should probably be rewritten as it abs the value
//  this looses important data in unintended situations
//  doing abs was intended for when the component wants to add negative styling without a minus by itself
//  however this behaviour should not be the default and be explicitly requested by a using a specific function
export const formatMoney = (money) => {
	if(!money) return 0;

	return formatMoneyDirect(Math.abs(money.amount), money.currency);
}

export const formatMoneyNeg = (money) => {
	if(!money) return 0;

	return `-${formatMoneyDirect(Math.abs(money.amount), money.currency)}`;
}

export const formatMoneyAuto = (money) => {
	if(!money) return 0;

	return money.amount < 0 ? formatMoneyNeg(money) : formatMoney(money)
}

export const formatPercentage = (factor) => {
	if(!factor) return '0%';
	return (factor * 100).toFixed(0) + '%';
}

export const formatFullName = (user) => {
	return user.name + ' ' +
		(user.middleName ? user.middleName + ' ' : '') +
		user.surname;
}

export const formatAddress = (client) => {
	if(!client) return '';

	return [
		client.street ? `${client.street} ${client.houseNumber}${client.houseNumberExtension || ''}` : false,
		client.zipcode,
		client.city,
		client.country,
	].filter(Boolean).join(', ');
}
