import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { keys, pick } from 'ramda'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import withCompleteBasicForm, { Joi } from '../../hoc/withCompleteBasicForm'
import FormDataError from '../../modules/formData/FormDataError'
import { patchEmployeeProfile } from '../../actions/employeeActions'
import Loader from '../util/loader/Loader'
import Alert from '../util/alert/Alert'
import CountryField from '../util/CountryField'

class ProfileEditAddressForm extends Component {
	render(){
		const { validation, validationBlur, fields, onChangeField, onChangeFieldDirect, saving, success, error } = this.props;

		if(success) return (
			<Alert type={Alert.TYPE_SUCCESS}>
				Updated details saved.
			</Alert>
		);

		return (
			<div>
				{ saving && <Loader sheet /> }
				<FormDataError error={error} showFieldErrors />
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<TextField name="street"
								   value={fields.street}
								   onChange={onChangeField}
								   label="Street"
								   error={!!validation['street']}
								   helperText={validation['street']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="houseNumber"
								   value={fields.houseNumber}
								   onChange={onChangeField}
								   label="House number"
								   error={!!validation['houseNumber']}
								   helperText={validation['houseNumber']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="zipcode"
								   value={fields.zipcode}
								   onChange={onChangeField}
								   label="Zipcode"
								   error={!!validation['zipcode']}
								   helperText={validation['zipcode']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField name="city"
								   value={fields.city}
								   onChange={onChangeField}
								   label="City"
								   error={!!validation['city']}
								   helperText={validation['city']}
								   onBlur={validationBlur}
								   fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<CountryField
							value={fields.country}
							onChange={(iso) => onChangeFieldDirect('country', iso)}
							error={!!validation['country']}
							helperText={validation['country']}
							onBlur={validationBlur}
							fullWidth
						/>
					</Grid>
				</Grid>
			</div>
		)
	}

	componentDidMount(){
		if(this.props.bindSave){
			this.props.bindSave(this.handleSubmit);
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.success && !prevProps.success && this.props.onSave){
			this.props.onSave();
		}
	}

	handleSubmit = () => {
		const { watchSubmit, inputData, fields, validationFields, validationAll, handleFormError } = this.props;

		validationAll(fields, (hasError) => {
			if(hasError) return handleFormError('Not all fields were filled correctly.');

			watchSubmit(patchEmployeeProfile(
				inputData.id, {
					address: pick(keys(validationFields), fields)
				}
			));
		});
	}
}

ProfileEditAddressForm.propTypes = {
	inputData: PropTypes.object.isRequired,
};

export default withCompleteBasicForm(ProfileEditAddressForm, {
	street: Joi.string().required(),
	houseNumber: Joi.string().required(),
	zipcode: Joi.string().required(),
	city: Joi.string().required(),
	country: Joi.string().required(),
})
